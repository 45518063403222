import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import ConstExprView from './ConstExprView';
import PatternWithValuesAndColors from './PatternWithValuesAndColors';
import GeneratorInfo from './GeneratorInfo';
import GeneratorDeclarationError from './GeneratorDeclarationError';

//import './css/custom.css';
const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  variableIcon: {
    color: '#52c41a',
  },
  variableCompIcon: {
    color: '#52c41a',
    fontWeight: '800',
    borderColor: '#52c41a',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  instanceInfoCompIcon: {
    color: '#e58ee9',
    fontWeight: '800',
    borderColor: '#e58ee9',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  intermediateEdit: {
    borderStyle: 'dashed',
    borderColor: 'rgb(32, 150, 243)',
    marginTop: 5,
    marginBottom: 5,
    //marginLeft: 20,
    marginLeft: -8,
    padding:5,
    borderLeft:'solid'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  constExprNodesWrapper: {
    borderLeft:'solid rgba(0, 0, 0, 0.54)',
    marginLeft: 10,
    marginTop:5,
    paddingRight:10,
    width: '100%',
  },
  // Generator Declaration
  generatorDeclarationError: {
    color: 'red',
  },
  generatorDeclarationErrorneousDeleteIcon: {
    color: '#ff00001a',
    '&&:hover, &&:active': {
      color: 'red',
    },
  },
  generatorDeclarationValidDeleteIcon: {
    color: 'rgb(171 218 255)',
  },
  generatorDeclarationErrorneousChip: {
    color: 'red',
    border: 'none',
    //border: '1px solid red',
    '&&:hover, &&:active, &&:focus': {
      backgroundColor: '#ff00001a',
    },
  },
  generatorDeclarationValidChip: {
    border: 'none',
  },
  generatorDeclarationIsolationBorder: {
    // border: '1px dashed',
    // borderRadius: 10,
    // padding: 7,
    // marginBottom: 5,
  },
  // generatorDeclarationIsolationBorderValid: {
  //   borderColor: theme.palette.primary.main,
  // },
  generatorDeclarationIsolationBorderError: {
    //borderColor: 'red',
  },
  generatorDeclarationIconWrapperStyle: {
    display: 'table-cell',
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 10
  },
  patternWithValuesAndColorsWrapper: {
    fontStyle: 'italic',
    marginLeft: 9,
    wordBreak: 'break-all',
  },
  declarationLabelWrapper: {
    marginLeft: 9,
  },
});

//export class TargetIntermediateComponentView extends React.PureComponent {
export class TargetIntermediateComponentView extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.compactViewMode !== nextProps.compactViewMode) {
      return true;
    }
    if(this.props.compactViewEnabled !== nextProps.compactViewEnabled) {
      return true;
    }
    if(this.props.intermediate !== nextProps.intermediate) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {

    const { classes } = this.props;
    const {
      intermediate, intermediateIndex, patternTypeColors, compactViewEnabled, compactViewMode,
    } = this.props;

    // Additional of the target intermediate - Edit
    var intermediateViewConstExprList = intermediate.constExprIds.map((constExprId, constExprIndex) => {
      let constExpr = intermediate.constExprs[constExprId];
      return (
        <ConstExprView
          key={'targetIntermediateConstExprView_' + constExpr.id }
          currMappingProjectId={this.props.currMappingProjectId}
          constExpr={constExpr}
          constExprId={constExprId}
          constExprIndex={constExprIndex}
          patternTypeColors={patternTypeColors}
          compactViewMode={compactViewMode}
          compactViewEnabled={this.props.compactViewEnabled}
        />
      );
    });

    const instanceGeneratorDeclarationErrorMsg =
      intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        intermediate.targetEntity.instanceGeneratorDeclaration.definitionId !== undefined ?
        (
          intermediate.targetEntity.instanceGeneratorDeclaration.definitionId === null ?
          'Missing Generator Definition' :
          ''
        ) :
        ''
      ) :
      '';
    const lastInstanceGeneratorDeclarationArgErrorIndex =
      intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        intermediate.targetEntity.instanceGeneratorDeclaration.args !== undefined &&
        intermediate.targetEntity.instanceGeneratorDeclaration.args.length > 0 ?
        intermediate.targetEntity.instanceGeneratorDeclaration.args.findIndex(
          obj => (
            intermediate.targetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'literal' &&
            intermediate.targetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'preflabel'
          ) ?
          obj.error_type_text !== '' :
          (
            obj.name.toLowerCase() !== 'language' ?
            obj.error_type_text !== '' :
            false
          )
        ) :
        -1
      ) :
      -1;
    const instanceGeneratorDeclarationArgsErrorMsg =
      lastInstanceGeneratorDeclarationArgErrorIndex !== -1 ?
      intermediate.targetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].name + ': ' +
      intermediate.targetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].error_type_text :
      '';

    const instanceGeneratorDeclarationErrorStyle =
      instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
      classes.generatorDeclarationError :
      '';

    return (
      <div className={classes.intermediateEdit}>
        <div style={{display:'flex'}}>
          <div style={{display:'flex'}}>
            <div className={classes.divIconStyleWrapper}>
              <div className={classes.divGreyOutlinedIconStyle}
                   style={{
                     borderColor: intermediate.targetEntity.error_text !== undefined ? 'red' : 'unset',
                     backgroundColor: intermediate.targetEntity.error_text !== undefined ? '#ff000052' : 'unset',
                   }}
              />
            </div>
            <div>
              <div style={{display:'flex', overflow: 'hidden', minWidth: 300}}>
                <b style={{minWidth: 90, color: intermediate.targetEntity.error_text !== undefined ? 'red' : 'unset'}}>
                  Target Entity:
                </b>
                <div style={{display:'flex', flexWrap: 'wrap', marginTop:-4}}>
                  {
                    intermediate.targetEntity.item !== '' ?
                    intermediate.targetEntity.item.map((itemObj, index) => (
                      // Without the `key`, React will fire a key warning
                      <React.Fragment key={'targetIntermediate_View_' + intermediateIndex + '_' + index}>
                        <div style={{padding:5}}>{itemObj.label}{intermediate.targetEntity.item.length-1 !== index ? ',' : ''}</div>
                      </React.Fragment>
                    )) :
                    ''
                  }
                </div>
              </div>

              {/* Variable / Generator Declaration Instance/Label*/}
              <div>

                {/*Display the Variable*/}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideVariable !== undefined ?
                        compactViewMode.hideVariable :
                        false
                      ) :
                      false
                    ) :
                    true
                  }
                >
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'table-row'}}
                         hidden={
                           intermediate.targetEntity.variable === undefined ?
                           true :
                           (
                             intermediate.targetEntity.variable.value === '' ?
                             true :
                             false
                           )
                         }>
                      <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                        <Tooltip title="Variable"
                                 placement="left"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                            <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                          </div>
                        </Tooltip>
                      </div>
                      <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}
                           hidden={
                             intermediate.targetEntity.variable !== undefined ?
                             !intermediate.targetEntity.variable.global :
                             true
                           }
                      >
                        <Tooltip title="Global"
                                 placement="left"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                        </Tooltip>
                      </div>

                      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                        <div className={classes.declarationLabelWrapper}>
                          {
                            intermediate.targetEntity.variable !== undefined ?
                            intermediate.targetEntity.variable.value :
                            ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>

                {/* Display the instance Info */}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideInstanceInfo !== undefined ?
                        compactViewMode.hideInstanceInfo :
                        false
                      ) :
                      false
                    ) :
                    true
                  }
                >
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'table-row'}}
                         hidden={
                           intermediate.targetEntity.instanceInfo === undefined ?
                           true :
                           (
                             intermediate.targetEntity.instanceInfo.constant === '' &&
                             intermediate.targetEntity.instanceInfo.description === '' &&
                             intermediate.targetEntity.instanceInfo.language === '' ?
                             true :
                             false
                           )
                         }>
                      <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                        <Tooltip title="Instance Info"
                                 placement="left"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                            <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                          </div>
                        </Tooltip>
                      </div>
                      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                        {
                          intermediate.targetEntity.instanceInfo !== undefined ?
                          (
                            intermediate.targetEntity.instanceInfo.constant !== undefined &&
                            intermediate.targetEntity.instanceInfo.constant !== '' ?
                            <div
                              className={classes.declarationLabelWrapper}
                              style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                maxWidth: 148,
                              }}
                            >
                              <Tooltip
                                title={intermediate.targetEntity.instanceInfo.constant}
                                placement="bottom-start"
                                enterDelay={800}
                                leaveDelay={200}
                              >
                                <span>
                                  {
                                    intermediate.targetEntity.instanceInfo !== undefined ?
                                    'Constant: ' + intermediate.targetEntity.instanceInfo.constant :
                                    ''
                                  }
                                </span>
                              </Tooltip>
                            </div> :
                            ''
                          ) :
                          ''
                        }
                        {
                          intermediate.targetEntity.instanceInfo !== undefined ?
                          (
                            intermediate.targetEntity.instanceInfo.description !== undefined &&
                            intermediate.targetEntity.instanceInfo.description !== '' ?
                            <div
                              className={classes.declarationLabelWrapper}
                              style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                maxWidth: 148,
                              }}
                            >
                              <Tooltip
                                title={intermediate.targetEntity.instanceInfo.description}
                                placement="bottom-start"
                                enterDelay={800}
                                leaveDelay={200}
                              >
                                <span>
                                  {
                                    intermediate.targetEntity.instanceInfo !== undefined ?
                                    'Description: ' + intermediate.targetEntity.instanceInfo.description :
                                    ''
                                  }
                                </span>
                              </Tooltip>
                            </div> :
                            ''
                          ) :
                          ''
                        }
                        <div
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxWidth: 148,
                          }}
                          hidden={
                            intermediate.targetEntity.instanceInfo !== undefined ?
                            (
                              intermediate.targetEntity.instanceInfo.language !== undefined ?
                              intermediate.targetEntity.instanceInfo.language.value === '' :
                              true
                            ) :
                            true
                          }
                          className={classes.declarationLabelWrapper}
                        >
                          {
                            intermediate.targetEntity.instanceInfo !== undefined ?
                            (
                              intermediate.targetEntity.instanceInfo.language !== undefined ?
                              'Language: ' +
                              (
                                intermediate.targetEntity.instanceInfo.language.label !== intermediate.targetEntity.instanceInfo.language.value ?
                                intermediate.targetEntity.instanceInfo.language.label + ' (' + intermediate.targetEntity.instanceInfo.language.value + ')' :
                                intermediate.targetEntity.instanceInfo.language.value
                              ) :
                              ''
                            )
                             :
                            ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>

                {/* Display Instance Generator Declaration */}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideInstanceGenerator !== undefined ?
                        compactViewMode.hideInstanceGenerator :
                        true
                      ) :
                      true
                    ) :
                    true
                  }
                >
                  <div className={instanceGeneratorDeclarationErrorStyle}>
                    <div style={{display: 'flex', width: 'fit-content'}}>
                      <div hidden={intermediate.targetEntity.instanceGeneratorDeclaration === undefined}
                           className={classes.generatorDeclarationIconWrapperStyle}>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <GeneratorInfo
                                title="Instance Generator"
                                patternTypeColors={patternTypeColors}
                                />
                            </React.Fragment>
                          }
                          placement="left"
                          enterDelay={500}
                          leaveDelay={200}
                        >
                         <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                       </Tooltip>
                      </div>
                      <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                        <div className={classes.declarationLabelWrapper}>
                          {
                            intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
                            intermediate.targetEntity.instanceGeneratorDeclaration.name :
                            ''
                          }
                        </div>
                        <div className={classes.patternWithValuesAndColorsWrapper}>
                          {
                          intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
                          <PatternWithValuesAndColors declaration={intermediate.targetEntity.instanceGeneratorDeclaration}
                                                      patternTypeColors={ patternTypeColors}/> :
                          ''
                          }
                        </div>
                      </div>
                      <GeneratorDeclarationError
                        generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                        errorMessage={
                          instanceGeneratorDeclarationErrorMsg !== '' ?
                           instanceGeneratorDeclarationErrorMsg :
                           (
                             instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                             instanceGeneratorDeclarationArgsErrorMsg :
                             ''
                           )
                         }
                      />
                    </div>
                  </div>
                </Collapse>

                {/* Display label Generator Declarations */}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideLabelGenerator !== undefined ?
                        compactViewMode.hideLabelGenerator :
                        true
                      ) :
                      true
                    ) :
                    true
                  }
                >
                  <div hidden={intermediate.targetEntity.labelGeneratorDeclarations === undefined ? true :
                         (intermediate.targetEntity.labelGeneratorDeclarations.length === 0 ? true : false)}>
                    <div style={{display: 'table-row'}}>
                      {
                        intermediate.targetEntity.labelGeneratorDeclarations !== undefined ?
                        intermediate.targetEntity.labelGeneratorDeclarations.map((labelDeclaration) => {
                          const labelGeneratorDeclarationErrorMsg =
                            labelDeclaration.definitionId !== undefined ?
                            (
                              labelDeclaration.definitionId === null ?
                              'Missing Generator Definition' :
                              ''
                            ) :
                            '';
                          const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                            obj => (
                              obj.name.toLowerCase() !== 'language' ?
                              obj.error_type_text !== '' :
                              false
                            )
                          );
                          const labelGeneratorDeclarationArgsErrorMsg =
                            lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                            labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                            labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                            '';

                          const labelGeneratorDeclarationErrorStyle =
                            labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                            classes.generatorDeclarationError :
                            '';
                          return (
                            <div
                              key={labelDeclaration.id}
                              className={labelGeneratorDeclarationErrorStyle}
                              style={{display: 'flex', width: 'fit-content'}}
                            >
                              <div className={classes.generatorDeclarationIconWrapperStyle}>
                                <Tooltip
                                  title={
                                    <React.Fragment>
                                      <GeneratorInfo
                                        title="Label Generator"
                                        patternTypeColors={patternTypeColors}
                                        />
                                    </React.Fragment>
                                  }
                                  placement="left"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                                </Tooltip>
                              </div>
                              <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                                <div className={classes.declarationLabelWrapper}>
                                  {labelDeclaration.name}
                                </div>
                                <div className={classes.patternWithValuesAndColorsWrapper}>
                                  {
                                  <PatternWithValuesAndColors declaration={labelDeclaration}
                                                              patternTypeColors={ patternTypeColors}/>
                                  }
                                </div>
                              </div>
                              <GeneratorDeclarationError
                                generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                                errorMessage={
                                  labelGeneratorDeclarationErrorMsg !== '' ?
                                   labelGeneratorDeclarationErrorMsg :
                                   (
                                     labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                     labelGeneratorDeclarationArgsErrorMsg :
                                     ''
                                   )
                                 }
                              />
                            </div>
                          )
                        }) :
                        ''
                      }
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
          <div className={classes.constExprNodesWrapper} style={{marginLeft:10}} hidden={intermediateViewConstExprList.length <= 0}>
            {intermediateViewConstExprList}
          </div>

        </div>
        <div style={{display:'flex', overflow: 'hidden'}}>
          <div className={classes.divIconStyleWrapper}>
            <ArrowDownwardIcon style={{color: intermediate.targetRelation.error_text !== undefined ? 'red' : 'unset'}}/>
          </div>
          <div style={{display:'flex'}}>
            <b style={{minWidth: 90, color: intermediate.targetRelation.error_text !== undefined ? 'red' : 'unset'}}>
              Target Relation:
            </b>
            <div style={{display:'flex', padding: 5, marginTop:-4}}>
              {intermediate.targetRelation.item ? intermediate.targetRelation.item.label : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TargetIntermediateComponentView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TargetIntermediateComponentView);
