import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();
const rootContextPath = configuration.rootContextPath;

export class OnlineUserPresenceService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }
  
  async userParticipatesMappingProject(data) {
     return await axiosInstance(rootContextPath + '/userPresence/userParticipatesMappingProject', {
      method: 'post',
      data: data,
    });
  }

  async userLeavesMappingProject(data) {
     return await axiosInstance(rootContextPath + '/userPresence/userLeavesMappingProject', {
      method: 'post',
      data: data,
    });
  }

  async cleanUpAndGetUsersInMappingProjectById(data) {
     return await axiosInstance(rootContextPath + '/userPresence/cleanUpAndGetUsersInMappingProjectById', {
      method: 'post',
      data: data,
    });
  }

  // Focus Related
  async userGainsFocusAtObjectInMappingProject(data) {
     return await axiosInstance(rootContextPath + '/userPresence/userGainsFocusAtObjectInMappingProject', {
      method: 'post',
      data: data,
    });
  }

  async userLosesFocusFromObjectInMappingProject(data) {
     return await axiosInstance(rootContextPath + '/userPresence/userLosesFocusFromObjectInMappingProject', {
      method: 'post',
      data: data,
    });
  }

  async retrieveFocusMapForMappingProjectById(data) {
     return await axiosInstance(rootContextPath + '/userPresence/retrieveFocusMapForMappingProjectById', {
      method: 'post',
      data: data,
    });
  }
}
