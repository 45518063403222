import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

const filter = createFilterOptions();

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
    //minHeight: '80vh',
    //maxHeight: '80vh',
  },
  card: {
    margin: 10,
    width: '50%'
  },
  singleCard: {
    margin: 10,
    width: '100%'
  },
  formRoot: {
    padding: 0,
    background: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
});

export class InstanceInfoDialog extends React.Component {

  state = {
    test: '',
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.instanceInfo !== nextProps.instanceInfo) {
      return true;
    }
    if(this.props.languages !== nextProps.languages) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleTest = props => event => {
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, onToggleFullScreen, onLeave, saveNow, shakeNow,
      instanceInfo, languages,
      handleInputChange, handleSave, handleInputSelectChange,
      //handleSaveCallBack,
    } = this.props;

    console.log('InstanceInfoDialog Rendered');

    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="generator-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="xl"
        scroll={'paper'}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : ''
          }
        }}
      >
        {/*
          onKeyUp={(e) => {
            const ENTER = 13;
            if (e.keyCode === ENTER) {
              handleSaveCallBack({instanceInfo: instanceInfo});
            }
          }}
        */}
        <DialogTitle style={{paddingBottom: 0}}>
          Instance Info
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onLeave({actionType: 'close'})}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Please either fill in the form.
          </DialogContentText>
      	  <Card>
        		<CardContent>
        		  <form ref={form => this.declarationForm = form}
                    autoComplete="off"
                    noValidate
                    className={classes.formRoot}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete="off"
                      label="Constant"
                      multiline
                      maxRows={3}
                      margin="dense"
                      value={instanceInfo.constant}
                      onChange={handleInputChange({fieldName: 'constant'})}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField autoComplete="off"
                               label="Description"
                               multiline
                               maxRows={6}
                               margin="dense"
                               value={instanceInfo.description}
                               onChange={handleInputChange({fieldName: 'description'})}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      options={languages}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // Suggest the creation of a new value
                        if(params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            label: `Add "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      getOptionSelected={
                        (option, value) => option.value === value.value
                      }
                      getOptionLabel={
                        lang =>
                          lang !== undefined && lang !== ''?
                          (
                            lang.label !== lang.value ?
                            (lang.label + ' (' + lang.value + ')') :
                            lang.value
                          ) :
                          ''
                      }
                      onChange={handleInputSelectChange({fieldName: 'language'})}
                      renderInput={
                        params =>
                          <TextField
                            {...params}
                            label="Language"
                            style={{ width: 300 }}
                          />
                      }
                      value={
                        instanceInfo.language !== undefined ?
                        instanceInfo.language :
                        null
                      }
                      disableClearable={false}
                    />
                  </FormControl>
                </div>
              </form>
        		</CardContent>
      	  </Card>
        </DialogContent>
        <DialogActions style= {{}}>
          <div style={{flexGrow: 1, paddingLeft: 15, paddingRight: 15}}>
            <Button onClick={onLeave({actionType: 'remove'})} color="primary">
              Remove
            </Button>
          </div>
          <div style={{paddingRight: 15}}>
            <Button
              onClick={handleSave({instanceInfo: instanceInfo})}
              color={!saveNow ? 'primary' : 'secondary'}
            >
              <span className={shakeNow ? 'rumble' : ''}>save</span>
            </Button>
          </div>
          <div style={{paddingRight: 15}}>
            <Button onClick={onLeave({actionType: 'close'})} color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

InstanceInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InstanceInfoDialog);
