import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
//import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SwipeableViews from 'react-swipeable-views';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Snackbar from '@material-ui/core/Snackbar';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Container, Draggable } from "react-smooth-dnd";
import amber from '@material-ui/core/colors/amber';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import configuration from '../configuration.json';

const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)

const styles = theme => ({

  dialogContentTextStyle: {
    margin: '8px 8px 0 8px',
  },
  multiCardWrapper: {
    display: 'flex',
    //overflowY: 'auto',
  },
  card: {
    margin: 10,
    width: '50%'
  },
  singleCard: {
    margin: 10,
    width: '100%',
    minHeight: 300
  },
  formRoot: {
    padding: 0,
    background: 'none',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  argFormBase: {
    minWidth: 265,
  },
  toolBarRoot: {
    margin: -16
  },
  highlight: theme.palette.type === 'light' ?
    {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    } : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
  actions: {
    color: theme.palette.text.secondary,
    //marginRight: 60,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  snackbarWarning: {
    backgroundColor: amber[700],
    width: 460,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  actionForm: {
    marginLeft: -24
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  lightHighlight: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 3,
    paddingLeft: 5,
    paddingRight: 5,
  },
  dragHandleColor: {
    color: theme.palette.grey[600],
  },
  // Custom Dialog (div that looks like dialog) CSS
  customDialogWrapper: {
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  customDialogBase: {
    maxWidth: 960,
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'column',
    margin: 3,
    position: 'relative',
    overflowY: 'auto',
    boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    borderRadius: 4,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#fff',
  },
  secondaryCode: {
    color: '#c7254e',
    backgroundColor: '#f9f2f4',
    borderRadius: 4,
    padding: '2px 4px',
    fontSize: '90%',
  },
});

const EvenSwitch = withStyles({
  switchBase: {
    color: '#2196f3',
    '&$checked': {
      color: '#2196f3',
    },
    '&$checked + $track': {
      backgroundColor: '#000',
    },
    '&$disabled': {
      color: '#bdbdbd',
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

let EditToolbar = props => {
  const {
    deleteMode, handleDeleteSelected, numSelected, commentsLength, classes
  } = props;

  return (
    <Toolbar
      className={classNames(classes.toolBarRoot,{
        [classes.highlight]: deleteMode,
      })}
    >
      <div>
        {deleteMode ? (
          <Typography color="inherit">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography color="textPrimary" variant="h6">
            Comments
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {deleteMode ?
          (
            <Tooltip title="Delete Selected">
              <div>
                <IconButton aria-label="delete-comment(s)"
                            onClick={handleDeleteSelected}
                            disabled={numSelected === 0}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) :
          ''
        }
      </div>
    </Toolbar>
  );
};

EditToolbar = withStyles(styles)(EditToolbar);

export class CommentDialog extends React.Component {

  state = {
    deleteMode: false,
    selected: [], // Comments
    snackbarShown: false,
    advancedExpanded: false,
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      if(this.props.dialogOpen) {
        this.setState({
          deleteMode: false,
          selected: [],
        });
      }
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.tabIndex !== nextProps.tabIndex) {
      return true;
    }
    if(this.props.currComment !== nextProps.currComment) {
      return true;
    }
    if(this.props.comments !== nextProps.comments) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleDeleteModeChange = event => {
    if(event === undefined) {
      this.setState({
        deleteMode: false,
        selected: [],
      });
    }
    else {
      this.setState({
        deleteMode: event.target.checked,
        selected: [],
      });
    }
  };

  isCommentSelected = id =>
    this.state.selected.findIndex(
      obj => obj.id === id
    ) !== -1;

  handleCommentCheckBoxChange = comment => event => {
    let array = this.state.selected.slice(); // Create a copy
    let index = this.state.selected.findIndex(
      obj => obj.id === comment.id
    );
    // Select Action
    if(event.target.checked) {
      if(index === -1) {
        array.push(comment);
        this.setState({
          selected: array
        });
      }
    }
    // De-select Action
    else {
      // Remove the de-selected item
      if(index !== -1) {
        array.splice(index, 1);
        this.setState({
          selected: array
        });
      }
    }
  };

  handleDeleteSelected = () => {
    this.handleSnackbarOpen();
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleConfirmDeleteSelectedComments = async () => {
    // Callback to delete the selected comments
    this.props.handleDeleteComments({commentsToBeDeleted: this.state.selected});
    this.setState({
      snackbarShown: false,
      deleteMode: false,
      delected: [],
    });
  }

  handleCancelDeleteSelectedComments = () => {
    this.setState({ snackbarShown: false });
  }

  handleAdvancedExpandedChange = (event, isExpanded) => {
    this.setState({
        advancedExpanded: isExpanded,
    });
  }

  // props: {comment: Object}
  getCommentForm = (props) => {

    const { classes, handleCommentInputChange, currUser } = this.props;

    let comment = Object.assign({}, props.comment); // Copy

    return (
      <form ref={form => this.commentForm = form}
            autoComplete="off"
            noValidate
            className={classes.formRoot}>
        <div style={{display: 'flex', flexDirection: 'column'}}>


          <FormControl className={classes.formControl}>
            <TextField required={true}
                       error={comment.error_rationale_text !== '' && comment.error_rationale_text !== undefined}
                       helperText={comment.error_rationale_text}
                       autoComplete="off"
                       label="Rationale"
                       placeholder="Enter some text"
                       margin="dense"
                       value={comment.rationale}
                       onChange={handleCommentInputChange({fieldName: 'rationale'})}
            />
          </FormControl>
          <div>
            <Accordion expanded={this.state.advancedExpanded}
                            onChange={this.handleAdvancedExpandedChange}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" className={classes.primaryColor}>More</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{overflowY: 'auto', width: '100%', height: 432, marginTop:-14}}>
                  {/*It was decided not to have ant type field in the comment form (it will always appear empty in the X3ML)*/}
                  {/*
                  <div style={{width: '100%'}}>
                    <TextField
                      label="Type"
                      placeholder="Enter some text"
                      value={comment.type}
                      onChange={handleCommentInputChange({fieldName: 'type'})}
                      margin="normal"
                      style={{width: '100%'}}
                    />
                  </div>
                  */}
                  <div style={{width: '100%'}}>
                    <TextField
                      label="Alternatives"
                      placeholder="Enter some text"
                      value={comment.alternatives}
                      onChange={handleCommentInputChange({fieldName: 'alternatives'})}
                      margin="normal"
                      style={{width: '100%'}}
                    />
                  </div>
                  <div style={{width: '100%'}}>
                    <TextField
                      label="Typical Mistakes"
                      placeholder="Enter some text"
                      value={comment.typicalMistakes}
                      onChange={handleCommentInputChange({fieldName: 'typicalMistakes'})}
                      margin="normal"
                      style={{width: '100%'}}
                    />
                  </div>
                  <div style={{width: '100%'}}>
                    <TextField
                      label="Local Habits"
                      placeholder="Enter some text"
                      value={comment.localHabits}
                      onChange={handleCommentInputChange({fieldName: 'localHabits'})}
                      margin="normal"
                      style={{width: '100%'}}
                    />
                  </div>
                  <div style={{width: '100%'}}>
                    <TextField
                      label="Link to Cook Book"
                      placeholder="Enter some text"
                      value={comment.linkToCookBook}
                      onChange={handleCommentInputChange({fieldName: 'linkToCookBook'})}
                      margin="normal"
                      style={{width: '100%'}}
                    />
                  </div>
                  <div style={{width: '100%'}}>
                    <TextField
                      label="Source Example"
                      placeholder="Enter some text"
                      value={comment.exampleSource}
                      onChange={handleCommentInputChange({fieldName: 'exampleSource'})}
                      margin="normal"
                      style={{width: '100%'}}
                    />
                  </div>
                  <div style={{width: '100%'}}>
                    <TextField
                      label="Target Example"
                      placeholder="Enter some text"
                      value={comment.exampleTarget}
                      onChange={handleCommentInputChange({fieldName: 'exampleTarget'})}
                      margin="normal"
                      style={{width: '100%'}}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </form>
    );
  }

  showFormToAddNewComment = () => {
    this.setState({
      advancedExpanded: false,
    }, () => {
      // Resetting current comment
      this.props.resetComment();
      // Showing the form with all fields filled in according to the selected comment
      this.props.showCommentForm();
    });
  }

  // props: {comment: <Object>, index: <integer>}
  selectComment = props => () => {
    // Single select action
    if(!this.state.deleteMode) {
      this.setState({
        advancedExpanded: false,
      }, () => {
        // Setting current comment
        this.props.selectComment({comment: props.comment, index: props.index});
        // Showing the form with all fields been reset
        this.props.showCommentForm();
      });
    }
  }

  // props: {comment: <Object>}
  saveComment = props => async () => {
    this.saveCommentCallBack(props);
  }

  saveCommentCallBack = async props => {
    let comment = Object.assign({}, props.comment); // Copy
    // Flag denoting whether to proceed with submitting based on the form's validation
    let proceed = this.props.handleValidateCommentForm({comment});
    if(proceed) {
      // Save
      await this.props.handleSaveComment({
        comment: comment,
      });
      // Go Back to the previous page (list of comments)
      this.props.handleChangeCommentTabIndex(0);
    }
  }

  getGhostParent = () => {
    //return ReactDOM.findDOMNode(document.getElementsByClassName('commentsFather'));
    var element = ReactDOM.findDOMNode(this.refs.commentsFather);
    console.log(element)
    return element;
    // return ReactDOM.findDOMNode(this.refs.commentsFather);
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, onToggleFullScreen,
      comments, currComment,
      handleChangeCommentTabIndex, onLeave, onLeaveCallBack, tabIndex,
      handleCommentInputChange,
      saveNow, shakeNow, onDragDrop,
    } = this.props;

    console.log('CommentDialog Rendered');
    return (
      <div
        hidden={!dialogOpen}
        className={classes.customDialogWrapper}
        onKeyUp={(e) => {
          const ENTER = 13;
          if (e.keyCode === ENTER) {
            if(this.props.tabIndex === 0) {
              if(!this.state.deleteMode) {
                this.onLeaveCallBack('close');
              }
            }
            else { //tabIndex === 1}
              this.saveCommentCallBack({comment: currComment});
            }
          }
        }}
      >
        <div
          hidden={!dialogOpen}
          className={classes.customDialogBase}
        >

          <DialogTitle style={{paddingBottom: 0}}>
            Comments Management
            <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
              <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                       placement="bottom"
                       enterDelay={500}
                       leaveDelay={200}>
                <IconButton className={classes.button}
                            aria-label="maximize"
                            onClick={onToggleFullScreen}
                            style={{marginRight: -10}}>
                  <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                  <CropSquareIcon hidden={fullScreen}/>
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Close"
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  className={classes.button}
                  aria-label="close"
                  onClick={onLeave('close')}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </DialogTitle>

          <DialogContent>

            <SwipeableViews
              index={tabIndex}
              onChangeIndex={handleChangeCommentTabIndex}
              animateHeight={false}
            >

              {/* #################### Comments List ####################*/}
              <div>
                <DialogContentText className={classes.dialogContentTextStyle}>
                  Please select some comment to edit or press the "Plus" button to add a new one. Don't forget to save before closing this dialog.
                  <code className={classes.secondaryCode}>
                    Also have in mind that the first comment of this link is displayed at the header of this link or mapping (in case of the domain).
                  </code>
                </DialogContentText>
                <div className={classes.multiCardWrapper}>
                  <Card className={classes.singleCard}>
                    <CardContent>
                      <EditToolbar deleteMode={this.state.deleteMode}
                                   handleDeleteSelected={this.handleDeleteSelected}
                                   selected={this.state.selected}
                                   numSelected={this.state.selected.length}
                                   commentsLength={comments.length}
                      />
                      {
                        comments.length > 0 ?
                        <List component="div" disablePadding>
                        <Container
                          dragHandleSelector=".drag-handle-comment"
                          lockAxis="y"
                          onDrop={onDragDrop}
                          getGhostParent={this.getGhostParent}
                          style={{overflowY: 'auto', height: 'calc(100vh - 480px)', marginTnop: 16}}
                        >
                        {/*
                          getGhostParent={this.getGhostParent}*
                        */}
                          {
                            comments.map((comment, index) => {
                              const isSelected = this.isCommentSelected(comment.id);
                              return (
                                <Draggable key={'commentComponent_' + comment.id}>
                                {/*className={'dragFixClass'}*/}
                                  <div style={{display: 'flex'}}>
                                    <div style={{margin: 'auto'}}>
                                      <DragHandleIcon className={classNames(classes.dragHandleColor, 'drag-handle-comment')} />
                                    </div>
                                    <ListItem
                                      role={undefined}
                                      dense
                                      button={!this.state.deleteMode}
                                      onClick={this.selectComment({comment: comment, index: index})}
                                    >

                                      <ListItemIcon>
                                        <Zoom in={this.state.deleteMode}>
                                          <Checkbox checked={isSelected}
                                                   hidden={!this.state.deleteMode}
                                                   onChange={this.handleCommentCheckBoxChange(comment)}/>
                                        </Zoom>
                                      </ListItemIcon>
                                      <ListItemAvatar>
                                        <Tooltip
                                          title={
                                            comment.lastUpdatePerson.firstname +
                                            ' ' +
                                            comment.lastUpdatePerson.lastname
                                          }
                                          placement="bottom"
                                          enterDelay={500}
                                          leaveDelay={200}
                                        >
                                          <Avatar
                                            alt="user-avatar"
                                            src={
                                              comment.lastUpdatePerson !== null && comment.lastUpdatePerson !== undefined ?
                                              avatarRootContextPath + comment.lastUpdatePerson.avatarImageRelativePath :
                                              ''
                                            }
                                          />
                                        </Tooltip>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={comment.rationale}
                                        secondary={
                                          new Date(comment.lastUpdateDate) instanceof Date && !isNaN(new Date(comment.lastUpdateDate)) ?
                                          'Last modified on ' +
                                          (
                                            new Date(comment.lastUpdateDate).toLocaleDateString()  +
                                            ', at ' +
                                            new Date(comment.lastUpdateDate).toLocaleTimeString()
                                          ) :
                                          comment.lastUpdateDate
                                        }
                                      />
                                    </ListItem>
                                  </div>
                                  <Divider />
                                </Draggable>
                              )}
                            )
                          }
                        </Container>
                        </List> :
                        <div style={{fontStyle: 'italic', margin: 8}}>There are no comments attached yet</div>
                      }
                      <Fade in={!this.state.deleteMode}>
                        <div style={{zIndex: 1, paddingRight: 54}} align="right">
                          <Tooltip title="Add new comment" placement="left-end">
                            <Fab color="primary"
                                 aria-label="add-new-comment"
                                 onClick={this.showFormToAddNewComment}
                                 className={classes.fab}
                                 style={{position: 'absolute', bottom: 24, right: 60}}>
                              <AddIcon />
                            </Fab>
                          </Tooltip>
                        </div>
                      </Fade>
                      <Fade in={this.state.deleteMode}>
                        <div style={{zIndex: 1, paddingRight: 54}} align="right">
                          <Tooltip title="Delete Selected" placement="left-end">
                            <span>
                              <Fab color="secondary"
                                   disabled={this.state.selected.length === 0}
                                   aria-label="delete-comment(s)"
                                   onClick={this.handleDeleteSelected}
                                   className={classes.fab}
                                   style={{position: 'absolute', bottom: 24, right: 60}}>
                                <DeleteIcon />
                              </Fab>
                            </span>
                          </Tooltip>
                        </div>
                      </Fade>
                    </CardContent>
                  </Card>
                </div>
              </div>

              {/* #################### Comment - Add Or Edit Form ####################*/}
              <div>
                <DialogContentText className={classes.dialogContentTextStyle}>
                  Please fill in the Comment Form. Click on <span className={classes.lightHighlight}>'More'</span> to see further optional fields.
                </DialogContentText>
                <div className={classes.multiCardWrapper}>
              	  <Card className={classes.singleCard}>
                		<CardContent>
                      <div style={{display: 'flex'}}>
                        <div style={{margin: 'auto', marginLeft: 0, marginRight: 16}}>
                          {
                            currComment.lastUpdateDate !== undefined && currComment.lastUpdateDate !== '' ?
                            <Tooltip
                              title={
                                currComment.lastUpdatePerson.firstname +
                                ' ' +
                                currComment.lastUpdatePerson.lastname
                              }
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <Avatar
                                alt="user-avatar"
                                src={
                                  currComment.lastUpdatePerson !== null && currComment.lastUpdatePerson !== undefined ?
                                  avatarRootContextPath + currComment.lastUpdatePerson.avatarImageRelativePath :
                                  ''
                                }
                              />
                            </Tooltip> :
                            ''
                          }
                        </div>
                        <div style={{marginTop: -8}}>
                          <Typography color="textPrimary" variant="h6" style={{paddingTop: 12}}>
                            {
                              currComment.lastUpdateDate !== undefined && currComment.lastUpdateDate !== '' ?
                              (
                                'Last modified on ' +
                                (
                                  new Date(currComment.lastUpdateDate) instanceof Date && !isNaN(new Date(currComment.lastUpdateDate)) ?
                                  (
                                    (
                                      new Date(currComment.lastUpdateDate).toLocaleDateString()  +
                                      ', at ' +
                                      new Date(currComment.lastUpdateDate).toLocaleTimeString()
                                    ) +
                                    ', by ' +
                                    (currComment.lastUpdatePerson.firstname + ' ' + currComment.lastUpdatePerson.lastname)
                                  ) :
                                  currComment.lastUpdateDate
                                )
                              ) :
                              'New Comment (never been saved)'
                            }
                          </Typography>
                        </div>
                        {/*<div className={classes.spacer} />*/}
                      </div>
                		  {this.getCommentForm({comment: currComment})}
                		</CardContent>
              	  </Card>
                </div>
              </div>

            </SwipeableViews>

            {/* Used as parent for the draggable items to avoid transitional issues*/}
            <div ref="commentsFather"/>

          </DialogContent>
          <DialogActions style={{paddingBottom: 14, paddingTop: 0}}>
            <div style={{display: 'flex', flexGrow: 1}}>
              <Zoom in={tabIndex === 1}>
                <Tooltip title="Go Back to List of Comments"
                         placement="right"
                         enterDelay={500}
                         leaveDelay={200}>
                  <Fab color="secondary"
                       size="small"
                       aria-label="go-back-to-label"
                       onClick={onLeave('goBack')}>
                    <ArrowBackIcon />
                  </Fab>
                </Tooltip>
              </Zoom>
              <Zoom in={tabIndex === 0}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.deleteMode}
                      onChange={this.handleDeleteModeChange}
                      value={this.state.deleteMode}
                      color="secondary"
                      disabled={comments.length < 1}
                    />
                  }
                  label="Delete Mode"
                  classes={{root: classes.actionForm}}
                />
              </Zoom>
            </div>
            <div style={{paddingRight: 15}}>
              <Zoom in={tabIndex === 1}>
                <Button
                  hidden={tabIndex !== 1}
                  disabled={currComment === undefined || currComment === ''}
                  onClick={this.saveComment({comment: currComment})}
                  color={!saveNow ? 'primary' : 'secondary'}
                >
                  <span className={shakeNow ? 'rumble' : ''}>Save Comment</span>
                </Button>
              </Zoom>
              <Button onClick={onLeave('close')} color="primary">
                Close
              </Button>
            </div>
          </DialogActions>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.snackbarShown}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: classes.snackbarWarning,
              }
            }}
            message={
              <div className={classes.snackbarMessageContainer}>
                <div className={classes.snackbarMessageText}>
                  <WarningIcon className={classNames(classes.largeSnackbarIcon, classes.snackbarIconVariant)} />
                </div>
                <div className={classes.snackbarMessageText}>
                  {
                    this.state.selected.length > 1 ?
                    'Are you sure you want to delete these ' + this.state.selected.length + ' comments' :
                    'Are you sure you want to delete this comment?'
                  }
                </div>
              </div>}
            action={[
              <Button key="confirmDeleteSelectedComments" color="secondary" size="small" onClick={this.handleConfirmDeleteSelectedComments}>
                <b>Yes Delete {this.state.selected.length > 1 ?'Them' : 'It'
                }</b>
              </Button>,
              <Button key="cancelDeleteSelectedComments" color="secondary" size="small" onClick={this.handleCancelDeleteSelectedComments}>
                <b>Cancel</b>
              </Button>,
            ]}
          />

        {/*
        </Dialog>
        */}
        </div>
      </div>
    )
  }
}

CommentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CommentDialog);
