import React from 'react';
import classNames from 'classnames';
//import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AutocompleteCustom from '.././Autocomplete';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import amber from '@material-ui/core/colors/amber';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PatternWithValuesAndColors from './PatternWithValuesAndColors';


const styles = theme => ({
  dialogCustomPaper: {
    overflow: 'hidden',
  },
  dialogCustomPaper80PercentHeight: {
    overflow: 'hidden',
  },
  dialogContentTextStyle: {
    margin: '8px 8px 0 8px',
  },
  dialogContentTextStyleTense: {
    margin: '8px 8px 0 8px',
    color: 'red',
  },
  multiCardWrapper: {
    display: 'flex',
    //overflowY: 'auto',
  },
  card: {
    margin: 10,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  singleCard: {
    margin: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formRoot: {
    padding: 0,
    background: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  argFormBase: {
    minWidth: 265,
  },
  toggleButtonPrimarySelected: {
    backgroundColor: '#2196f3 !important',
    color: '#fff !important',
  },
  toggleButtonRoot: {
    height: 35,
  },
  toolBarRoot: {
    margin: -16
  },
  highlight: theme.palette.type === 'light' ?
    {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    } : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  snackbarWarning: {
    backgroundColor: amber[700],
    width: 460,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  errorColor: {
    color: 'red'
  },
  bottomFixFab: {
    position: 'absolute',
    bottom: 30,
    //width: 70,
    zIndex: 1,
    paddingRight: 14,
  }
});

const filter = createFilterOptions();

let EditToolbar = props => {
  const {
    deleteModeActive, handleDeleteSelected, numSelected, classes
  } = props;

  return (
    <Toolbar
      className={classNames(classes.toolBarRoot,{
        [classes.highlight]: deleteModeActive,
      })}
    >
      <div>
        {deleteModeActive ? (
          <Typography color="inherit" variant="body1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography color="textPrimary">
            Label Generator Declarations
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {deleteModeActive ?
          (
            <Tooltip title="Delete Selected">
              <div>
                <IconButton aria-label="Delete"
                            onClick={handleDeleteSelected}
                            disabled={numSelected === 0}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) : ''
        }
      </div>
    </Toolbar>
  );
};

EditToolbar = withStyles(styles)(EditToolbar);

export class GeneratorDialog extends React.Component {

  state = {
    labelDeclarationListDeleteModeActive: false,
    labelDeclarationsSelected: [],
    snackbarShown: false
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.areGeneratorTabsDisabled !== nextProps.areGeneratorTabsDisabled) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.tabValue !== nextProps.tabValue) {
      return true;
    }
    if(this.props.tabIndex !== nextProps.tabIndex) {
      return true;
    }
    if(this.props.instanceGeneratorDefinitions !== nextProps.instanceGeneratorDefinitions) {
      return true;
    }
    if(this.props.labelGeneratorDefinitions !== nextProps.labelGeneratorDefinitions) {
      return true;
    }
    if(this.props.currInstanceGeneratorDeclaration !== nextProps.currInstanceGeneratorDeclaration) {
      return true;
    }
    if(this.props.labelGeneratorDeclarations !== nextProps.labelGeneratorDeclarations) {
      return true;
    }
    if(this.props.currLabelGeneratorDeclaration !== nextProps.currLabelGeneratorDeclaration) {
      return true;
    }
    if(this.props.instanceGeneratorDefinitionSelected !== nextProps.instanceGeneratorDefinitionSelected) {
      return true;
    }
    if(this.props.labelGeneratorDefinitionSelected !== nextProps.labelGeneratorDefinitionSelected) {
      return true;
    }
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }
    if(this.props.showFixMissingDefinitionErrorView !== nextProps.showFixMissingDefinitionErrorView) {
      return true;
    }
    if(this.props.theSystemIsUpdatingGeneratorDeclarations !== nextProps.theSystemIsUpdatingGeneratorDeclarations) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleDeleteLabelDeclarationListModeChange = event => {
    if(event === undefined) {
      this.setState({
        labelDeclarationListDeleteModeActive: false,
        labelDeclarationsSelected: [],
      });
    }
    else {
      this.setState({
        labelDeclarationListDeleteModeActive: event.target.checked,
        labelDeclarationsSelected: [],
      });
    }
  };

  /*
  handleToggleLabelDeclarationsEditMode = (event, modeName) => {
    if(modeName !== null ) {
      if(modeName === 'edit' ) {
        this.setState({ labelDeclarationListEditMode: true });
        this.setState({ labelDeclarationListModeName: 'edit' });
      }
      else { //if(editModeName == 'normal' ) {
        this.setState({ labelDeclarationListEditMode: false });
        this.setState({ labelDeclarationListModeName: 'normal' });

        // Deselect all selected rows (if there are any)
        this.setState({ labelDeclarationsSelected: [] });
      }
    }
  };
  */
  isLabelDeclarationSelected = id =>
    this.state.labelDeclarationsSelected.findIndex(
      obj => obj.id === id
    ) !== -1;

  handleDeclarationCheckBoxChange = declaration => event => {
    let array = this.state.labelDeclarationsSelected.slice(); // Create a copy
    let index = this.state.labelDeclarationsSelected.findIndex(
      obj => obj.id === declaration.id
    );
    // Select Action
    if(event.target.checked) {
      if(index === -1) {
        array.push(declaration);
        this.setState({
          labelDeclarationsSelected: array
        });
      }
    }
    // De-select Action
    else {
      // Remove the de-selected item
      if(index !== -1) {
        array.splice(index, 1);
        this.setState({
          labelDeclarationsSelected: array
        });
      }
    }
  };

  handleDeleteSelected = () => {
    console.log('this.state:');
    console.log(this.state);
    this.handleSnackbarOpen();
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleConfirmDeleteSelectedGeneratorDeclarations = async  () => {
    // Callback to delete the selected declarations
    this.props.handleDeleteLabelGeneratorDeclarations({labelGeneratorDeclarationsToBeDeleted: this.state.labelDeclarationsSelected});
    this.setState({
      snackbarShown: false,
      labelDeclarationListDeleteModeActive: false,
      labelDeclarationsSelected: [],
    });
  }

  handleCancelDeleteSelectedGeneratorDeclarations = () => {
    this.setState({ snackbarShown: false });
  }

  // props: {generatorDefinition: Object, generatorType: 'instance | label'}
  getGeneratorDefinitionPrefixNamespaceForm = props => {
    console.log('getGeneratorDefinitionPrefixNamespaceForm');
    const { classes, handleDefinitionFieldChange} = this.props;
    if(props.generatorDefinition.prefix !== undefined) {

      return (
        <form ref={form => this.definitionPrefNamespaceForm = form}
              autoComplete="off"
              noValidate
              className={classes.formRoot}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <FormControl className={classes.formControl} style={{paddingBottom: 25, marginTop: -16}}>
              <InputLabel style={{fontWeight: 'bold'}}>{'Prefix: ' + props.generatorDefinition.prefix}</InputLabel>
            </FormControl>

            <FormControl className={classes.formControl}>
            {
              <TextField 
                required
                error={props.generatorDefinition.error_namespace_text !== '' && props.generatorDefinition.error_namespace_text !== undefined}
                helperText={props.generatorDefinition.error_namespace_text}
                autoComplete="off"
                label="Value"
                placeholder="Please enter some namespace"
                margin="dense"
                value={props.generatorDefinition.namespace ? props.generatorDefinition.namespace : ''}
                onChange={
                  handleDefinitionFieldChange({
                    fieldName: 'namespace', 
                    generatorType: props.generatorType, 
                    generatorDefinition: props.generatorDefinition
                  })
                }
              />
            }
            </FormControl>
          </div>
        </form>
      );
    }
  }

  // handleAutocompleteChange = (props) => (event, value) => {
  //   // Create a synthetic event to pass to the existing handleArgChange function
  //   value.label=value.value;
  //   const syntheticEvent = {
  //     target: {
  //       value: value !== null ? value : ''
  //     }
  //   };
  //   // Call the existing handleArgChange function with the synthetic event
  //   this.props.handleArgChange(props)(syntheticEvent);
  // }

  handleAutocompleteChange = (props) => (event, value) => {
    // Handle the case where value is null (when clear button is clicked)
    // let newValue = 
    //   value ? 
    //   { ...value, label: value.label || value.value } : 
    //   { label: '', value: '' };

    // If value is a string (custom value added with pressing enter without selecting it)
    let newValue = 
      value ? 
      (
        typeof value === 'string' ?
        {value: value, label: value} :
        value
      ) :
      { label: '', value: '' };

    if(newValue.newValueAdded) {
      delete newValue.newValueAdded;
    }

    const syntheticEvent = {
      target: {
        value: newValue
      }
    };
    this.props.handleArgChange(props)(syntheticEvent);
    console.log('syntheticEvent:');
    console.log(syntheticEvent);
  }

  // props: {generatorDeclaration: Object, generatorType: 'instance | label'}
  getGeneratorDeclarationForm = props => {
    const { classes, handleArgChange, handleToggleFixMissingDefinitionErrorView} = this.props;
    if(props.generatorDeclaration !== undefined && props.generatorDeclaration !== '') {
      // Use type here // Second thoughts: no change is needed, nor type is needed (I can remove it)
      if(props.generatorDeclaration.name.toLowerCase() === 'uuid') {
        return (
          <div className={classes.argFormBase}>
            <Typography color="textSecondary">
              {props.generatorDeclaration.name}
            </Typography>
            <Typography style={{maxWidth: 300, paddingTop: 10, fontStyle: 'italic'}} color="textSecondary">
              The UUID generator needs no further configuration
            </Typography>
          </div>
        );
      }
      else {
        const declarationOptions = [
          {label: "Constant", value:'constant'},
          {label: "Xpath", value:'xpath'},
          {label: "Position", value:'position'}
        ];
        const buildInAndLanguageDeclarationOptions = [
          {label: "Constant", value:'constant'},
          {label: "Xpath", value:'xpath'}
        ];
        let generatorArgForms = props.generatorDeclaration.args.map((arg, index) => {
          let typeItem = arg.type !== '' && arg.type !== undefined ? {label: arg.type.charAt(0).toUpperCase() + arg.type.slice(1), value: arg.type} : '';
          let fieldRequired = !(
            (
              props.generatorDeclaration.name.toLowerCase() === 'literal' ||
              props.generatorDeclaration.name.toLowerCase() === 'preflabel' ||
              props.generatorType === 'label'
            ) &&
            arg.name.toLowerCase() === String('language').toLowerCase()
          ) &&
          arg.type !== 'position';
          // let useFullOptionList = !(
          //   (props.generatorDeclaration.name.toLowerCase() === 'literal' || props.generatorDeclaration.name.toLowerCase() === 'preflabel') &&
          //   arg.name.toLowerCase() === String('language').toLowerCase()
          // );
          let useFullOptionList = !(
            (
              props.generatorDeclaration.name.toLowerCase() === 'literal' ||
              props.generatorDeclaration.name.toLowerCase() === 'preflabel' ||
              props.generatorType === 'label'
            ) &&
            arg.name.toLowerCase() === String('language').toLowerCase()
          );

          return (
            <form key={'arg_' + arg.name + '_' + index}
                  ref={form => this.declarationForm = form}
                  autoComplete="off"
                  noValidate
                  className={classes.formRoot}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <FormControl className={classes.formControl} style={{paddingBottom: 25, marginTop:-16}}>
                  <InputLabel style={{fontWeight: 'bold'}}>{'Argument #' + (index+1) + ': ' + arg.name}</InputLabel>
                </FormControl>
                <FormControl className={classes.formControl}
                             hidden={arg.type === undefined}
                             error={
                               fieldRequired &&
                               arg.error_type_text !== '' && arg.error_type_text !== undefined
                             }>
                  <AutocompleteCustom label={'Select Type'}
                                placeholder={'Please select type'}
                                options={useFullOptionList ? declarationOptions : buildInAndLanguageDeclarationOptions}
                                value={typeItem}
                                onChange={handleArgChange({fieldName: 'type', arg: arg, generatorType: props.generatorType, generatorDeclarationName: props.generatorDeclaration.name})}
                                error={
                                  fieldRequired &&
                                  arg.error_type_text !== '' && arg.error_type_text !== undefined
                                }
                                errorText={arg.error_type_text}
                                isMulti={false}
                                async={false}/>
                </FormControl>
                <FormControl className={classes.formControl}>
                  {
                    arg.type === 'xpath' ?

                    <Autocomplete
                    // value={arg.value}
                      value={
                        // In case that this is not a <label, Value> object then,
                        // Search for it inside the options (case of not a custom value) and if not found
                        // construct a synthetic <label, Value> object out of the string value 
                        // Otherwise if it is a <label, Value> object, then simply return it
                        !this.isLabelValueObject(arg.value) ?
                        this.props.xpaths.find(option => option.value === arg.value) || {label: arg.value, value: arg.value} :
                        arg.value
                      }
                      onChange={
                        this.handleAutocompleteChange({
                          fieldName: 'value', 
                          arg: arg, 
                          generatorType: props.generatorType, 
                          generatorDeclarationName: props.generatorDeclaration.name,
                        })
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            value: params.inputValue,
                            label: params.inputValue,
                            newValueAdded: true,
                          });
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={this.props.xpaths}
                      getOptionLabel={(option) => {
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.label !== undefined ? option.label : '';
                      }}
                      getOptionSelected={
                        (option, value) => {
                          return ((value !== '' || value !== undefined) ? option.value === value.value : false)
                        }
                      }
                      renderOption={(option) => option.newValueAdded ? `Add "${option.label}"` : option.label}
                      // style={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          label="Select or add your own XPath" 
                          variant="standard" 
                          required={fieldRequired || arg.type !== ''}
                          error={(fieldRequired || arg.type !== '') && arg.error_value_text !== '' && arg.error_value_text !== undefined}
                          helperText={arg.error_value_text}
                          // onKeyDown={(event) => {
                          //   if (event.key === 'Enter') {
                          //     // Prevent default form submission on Enter key press
                          //     event.preventDefault();
                    
                          //     // Retrieve the input value
                          //     const inputValue = event.target.value;
                    
                          //     if (inputValue) {
                          //       // Check if input value exists in the options
                          //       const existingOption = this.props.xpaths.find((option) => option.label === inputValue);
                    
                          //       if (existingOption) {
                          //         // If the input matches an existing option, select it
                          //         this.handleAutocompleteChange({
                          //           fieldName: 'value',
                          //           arg: arg,
                          //           generatorType: props.generatorType,
                          //           generatorDeclarationName: props.generatorDeclaration.name,
                          //         })(null, existingOption);
                          //       } 
                          //       else {
                          //         // If it doesn't match any existing option, create a new value and select it
                          //         const newOption = { label: inputValue, value: inputValue, newValueAdded: true };
                    
                          //         this.handleAutocompleteChange({
                          //           fieldName: 'value',
                          //           arg: arg,
                          //           generatorType: props.generatorType,
                          //           generatorDeclarationName: props.generatorDeclaration.name,
                          //         })(null, newOption);
                          //       }
                          //     }
                          //   }
                          // }}
                        />
                      )}
                    /> :


                    // <AutocompleteCustom 
                    //   label={'Choose XPath'}
                    //   placeholder={'Please select or enter some XPath'}
                    //   options={this.retrieveXpaths()}
                    //   value={arg.value}
                    //   onChange={
                    //     handleArgChange({
                    //       fieldName: 'value', 
                    //       arg: arg, 
                    //       generatorType: props.generatorType, 
                    //       generatorDeclarationName: props.generatorDeclaration.name
                    //     })
                    //   }
                    //   error={
                    //     fieldRequired &&
                    //     arg.error_type_text !== '' && arg.error_type_text !== undefined
                    //   }
                    //   errorText={arg.error_type_text}
                    //   isMulti={false}
                    //   async={false}
                    // /> :
                    <TextField 
                      id={'value_' + index}
                      required = {fieldRequired || arg.type !== ''}
                      disabled={arg.type === '' || arg.type === 'position'}
                      error={
                        (fieldRequired || arg.type !== '') && arg.error_value_text !== '' && arg.error_value_text !== undefined
                      }
                      helperText={arg.error_value_text}
                      autoComplete="off"
                      label="Value"
                      placeholder="Please enter some value"
                      margin="dense"
                      value={arg.value}
                      onChange={
                        handleArgChange({
                          fieldName: 'value', 
                          arg: arg, 
                          generatorType: props.generatorType, 
                          generatorDeclarationName: props.generatorDeclaration.name
                        })
                      }
                    />
                  }
                  {/* <TextField
                    id={'value_' + index}
                    required = {fieldRequired || arg.type !== ''}
                    disabled={arg.type === '' || arg.type === 'position'}
                    error={
                      (fieldRequired || arg.type !== '') && arg.error_value_text !== '' && arg.error_value_text !== undefined
                    }
                    helperText={arg.error_value_text}
                    autoComplete="off"
                    label="Value"
                    placeholder="Please enter some value"
                    margin="dense"
                    value={arg.value}
                    onChange={
                      handleArgChange({
                        fieldName: 'value', 
                        arg: arg, 
                        generatorType: props.generatorType, 
                        generatorDeclarationName: props.generatorDeclaration.name
                      })
                    }
                  /> */}
                </FormControl>
                <Divider />
              </div>
            </form>
          );
        })

        let missingDefError = (props.generatorDeclaration.definitionId === undefined || props.generatorDeclaration.definitionId) === null ?
          <div>
            <div className={classes.errorColor}>Missing Generator Definition: You should associate this declaration with some definition</div>
            <div style={{width:'100%', textAlign: 'right'}}>
              <Button onClick={handleToggleFixMissingDefinitionErrorView({show: true})}
                      color="secondary"
                      size="small">
                Steps To Resolve Issue
              </Button>
            </div>
          </div> :
        ''

        return (
          <div className={classes.argFormBase}>
            {missingDefError}
            <Typography color="textSecondary">
              {props.generatorDeclaration.name}
            </Typography>
            {generatorArgForms}
          </div>
        );
      }
    }
    else {
      return (
        <div className={classes.argFormBase} style={{maxWidth: 300}}>
          <Typography color="textSecondary">
            Please select a generator Definition from the list on the left panel
          </Typography>
        </div>
      );
    }
  }

  // Label Generator Related
  showFormToAddNewLabel = () => {
    // Resetting current label declaration
    this.props.resetLabelGeneratorDeclaration();
    // Showing the form with all fields filled in according to the selected declaration
    this.props.showLabelGeneratorForm();
  }

  // props: {labelGeneratorDeclaration: <Object>, index: <integer>}
  selectLabelGeneratorDeclaration = props => () => {
    // Single select action
    if(!this.state.labelDeclarationListDeleteModeActive) {
      // Setting current label declaration
      this.props.selectLabelGeneratorDeclaration({labelGeneratorDeclaration: props.labelGeneratorDeclaration, index: props.index});
      // Showing the form with all fields been reset
      this.props.showLabelGeneratorForm();
    }
  }

  // props: {labelGeneratorDeclaration: <Object>, generatorDefinition: <Object>, type: 'instance | label'}
  saveLabelGeneratorDeclaration = props => () => {
    this.saveLabelGeneratorDeclarationCallBack(props);
  }

  // props: {labelGeneratorDeclaration: <Object>, generatorDefinition: <Object>, type: 'instance | label'}
  saveLabelGeneratorDeclarationCallBack = async props => {
    let newDeclaration = Object.assign({}, props.labelGeneratorDeclaration); // Copy
    // Flag denoting whether to proceed with submitting based on the form's validation
    let proceed = false;
    if(newDeclaration.args !== undefined) {
      for (const arg of newDeclaration.args) {
        if(this.isLabelValueObject(arg.value)) {
          arg.value = arg.value.value
        }
        if(arg.name.toLowerCase() !== 'language' &&
           arg.type.toLowerCase() !== 'constant' &&
           arg.type.toLowerCase() !== 'xpath' &&
           arg.type.toLowerCase() !== 'position') {
          arg.error_type_text = 'This Value is not allowed';
        }
      }
      proceed = true;
    }

    if(proceed) {
      if(newDeclaration.args.length > 0) {
        proceed = this.props.handleValidateDeclarationForm({generatorDeclaration: newDeclaration, capType: 'Label'});
      }
      else {
        proceed = true;
      }
    }
    // if(proceed) {
    //   // Definition validation
    //   proceed = this.definitionPrefNamespaceForm !== null && this.definitionPrefNamespaceForm !== undefined ?
    //             this.definitionPrefNamespaceForm.checkValidity() :
    //             true;
    // }
    // Namespace validation
    let nameSpaceProceed = true;
    // If it has the prefix property
    if(props.generatorDefinition.prefix) {
      if(this.isGeneratorDefinitionNameSpaceFriendly(props.generatorDefinition)) {
        // Error Check and selection in the list
        nameSpaceProceed = this.props.handleDefinitionFieldChangeCallBack({
          fieldName: 'namespace', 
          generatorType: props.type, 
          generatorDefinition: props.generatorDefinition, 
          event: {
            target: {
              value: props.generatorDefinition.namespace
            }
          }
        });
      }
    }
    
    // Persisting
    if(proceed && nameSpaceProceed) {
      // Save Definition
      await this.props.handleUpdateNamespaceEveryWhere({
        prefix: props.generatorDefinition.prefix,
        namespace: props.generatorDefinition.namespace,
        afterCallBack: {
          function: this.props.handleSaveLabelGeneratorDeclaration,
          props: {
            labelGeneratorDeclaration: newDeclaration,
            labelGeneratorDefinition: props.generatorDefinition,
          }
        }
      });

      // Go Back to the previous page (declaration list)
      this.props.handleChangeGeneratorTabIndex(1);
    }
    // show error
    else {
      this.props.showErrorSnackBar({
        msg: 'The "Definition Form" is not valid. Please make sure that there are not any fields with errors',
        iconSize: 'large'
      });
    }

  }

  isGeneratorDefinitionNameSpaceFriendly = generatorDefinition => {
    // Check if the name property exists and matches any of the specified values
    const nameIsNamespaceFriendly = ['uuid', 'literal', 'preflabel'].includes(generatorDefinition.name?.toLowerCase());
  
    // Check if the custom property is true, default to false if it doesn't exist
    const customIsNamespaceFriendly = generatorDefinition.custom === true;
  
    // Determine if the generatorDefinition is namespaceFriendly
    return !(nameIsNamespaceFriendly || customIsNamespaceFriendly);
  }

  // Helper function determining whether a variable is an object of the form: {label: <String>, value: >String>}
  isLabelValueObject = (obj) => {
    return (
      obj !== null &&
      typeof obj === 'object' &&
      typeof obj.label === 'string' &&
      typeof obj.value === 'string'
    );
  }

  // props: {instanceGeneratorDeclaration: <Object>, generatorDefinition: <Object>, type: 'instance | label'}
  saveInstanceGeneratorDeclaration = props => async () => {
    this.saveInstanceGeneratorDeclarationCallBack(props);
  }
  saveInstanceGeneratorDeclarationCallBack = async props => {
    // Flag denoting whether to proceed with submitting based on the form's validation
    let proceed = false;

    if(props.instanceGeneratorDeclaration.args !== undefined) {

      for (const arg of props.instanceGeneratorDeclaration.args) {
        // If the value of the arg is a <label, value> object then change it to a single string value;
        if(this.isLabelValueObject(arg.value)) {
          arg.value = arg.value.value
        }
        if(props.instanceGeneratorDeclaration.name.toLowerCase() !== 'literal' &&
           props.instanceGeneratorDeclaration.name.toLowerCase() !== 'preflabel' &&
           arg.type.toLowerCase() !== 'constant' &&
           arg.type.toLowerCase() !== 'xpath' &&
           arg.type.toLowerCase() !== 'position') {
          arg.error_type_text = 'This Value is not allowed';
        }
      }

      if(props.instanceGeneratorDeclaration.args.length > 0) {
        proceed = this.props.handleValidateDeclarationForm({generatorDeclaration: props.instanceGeneratorDeclaration, capType: 'Instance'});
      }
      else {
        proceed = true;
      }
    }
    else {
      if(props.instanceGeneratorDeclaration.name.toLowerCase() === 'uuid') {
        proceed = true;
      }
      else {
        proceed = false;
      }
    }

    // if(proceed) {
    //   // Definition validation
    //   if(props.instanceGeneratorDeclaration.name.toLowerCase() !== 'uuid') {
    //     proceed = this.definitionPrefNamespaceForm !== null && this.definitionPrefNamespaceForm !== undefined ?
    //               this.definitionPrefNamespaceForm.checkValidity() :
    //               true;
    //   }
    // }

    let nameSpaceProceed = true;
    // If it has the prefix property
    if(props.generatorDefinition.prefix) {
      if(this.isGeneratorDefinitionNameSpaceFriendly(props.generatorDefinition)) {
        // Error Check and selection in the list
        nameSpaceProceed = this.props.handleDefinitionFieldChangeCallBack({
          fieldName: 'namespace', 
          generatorType: props.type, 
          generatorDefinition: props.generatorDefinition, 
          event: {
            target: {
              value: props.generatorDefinition.namespace
            }
          }
        });
      }
    }
    

    if(proceed && nameSpaceProceed) {
      // Save Definition
      this.props.handleUpdateNamespaceEveryWhere({
        prefix: props.generatorDefinition.prefix,
        namespace: props.generatorDefinition.namespace,
        afterCallBack: {
          function: this.props.handleSaveInstanceGeneratorDeclaration,
          props: {
            instanceGeneratorDeclaration: props.instanceGeneratorDeclaration,
            instanceGeneratorDefinition: props.generatorDefinition,
          }
        }
      });

      // Close Dialog
      this.props.onLeave({actionType: 'close'});
    }
    else {
      // this.props.handleDefinitionFieldChangeCallBack({fieldName: 'namespace', generatorType: props.type, generatorDefinition: props.generatorDefinition})
      this.props.showErrorSnackBar({
        msg: 'The "Declaration Form" is not valid. Please make sure that there are not any fields with errors',
        iconSize: 'large'
      });
    }
  }

  render() {
    const {
      classes, dialogOpen, areGeneratorTabsDisabled, fullScreen, onToggleFullScreen, selectDefinitionGenerator,
      instanceGeneratorDefinitions, instanceGeneratorDefinitionSelected, currInstanceGeneratorDeclaration,
      labelGeneratorDefinitions, labelGeneratorDefinitionSelected, labelGeneratorDeclarations, currLabelGeneratorDeclaration,
      handleGeneratorTabChange, handleChangeGeneratorTabIndex, onLeave, tabValue, tabIndex,
      patternTypeColors, saveNow, shakeNow, showFixMissingDefinitionErrorView, handleToggleFixMissingDefinitionErrorView,
      handleSetGeneratorDefinitionforDeclarationsOfName, theSystemIsUpdatingGeneratorDeclarations, 
    } = this.props;

    console.log('GeneratorDialog Rendered');

    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="generator-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="md"
        scroll={'paper'}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : classes.dialogCustomPaper80PercentHeight
          }
        }}
        // onKeyUp={(e) => {
        //   const ENTER = 13;
        //   if (e.keyCode === ENTER) {
        //     if(this.props.tabIndex === 0) {
        //       if(currInstanceGeneratorDeclaration !== undefined && currInstanceGeneratorDeclaration !== '') {
        //         this.saveInstanceGeneratorDeclarationCallBack({
        //           instanceGeneratorDeclaration: currInstanceGeneratorDeclaration,
        //           generatorDefinition: instanceGeneratorDefinitionSelected,
        //           type: 'instance'
        //         });
        //       }
        //     }
        //     else { //tabIndex === 1}
        //       if(!this.state.labelDeclarationListDeleteModeActive) {
        //         if(currLabelGeneratorDeclaration !== undefined && currLabelGeneratorDeclaration !== '') {
        //           this.saveLabelGeneratorDeclarationCallBack({
        //             labelGeneratorDeclaration: currLabelGeneratorDeclaration,
        //             generatorDefinition: labelGeneratorDefinitionSelected,
        //             type: 'label'
        //           });
        //         }
        //       }
        //     }
        //   }
        // }}
      >
        <DialogTitle style={{paddingBottom: 0}}>
          Generator
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onLeave({actionType: 'close'})}>
                          {/*padding: 'unset'*/}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Divider style={{marginTop: 15, marginLeft: -24, marginRight: -24}}/>
        </DialogTitle>
        <Fade in={theSystemIsUpdatingGeneratorDeclarations} unmountOnExit>
          <LinearProgress />
        </Fade>
        <DialogContent style={{overflow: 'hidden'}}>

          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleGeneratorTabChange}>
              <Tab label="Instance"  disabled={areGeneratorTabsDisabled}/>
              <Tab label="Label" disabled={areGeneratorTabsDisabled}/>
            </Tabs>
          </AppBar>

          <SwipeableViews index={tabIndex}
                          onChangeIndex={handleChangeGeneratorTabIndex}
                          >

            {/* #################### Instance Generator ####################*/}
            <div>
              {
                showFixMissingDefinitionErrorView ?
                <DialogContentText className={classes.dialogContentTextStyleTense}>
                    Please select some definition to assosiate this declaration with.
                </DialogContentText> :
                <DialogContentText className={classes.dialogContentTextStyle}>
                    Please select some definition and then fill in the respective form. Don't forget to save before closing this dialog.
                </DialogContentText>
              }
              <div className={classes.multiCardWrapper}>
                <Card className={showFixMissingDefinitionErrorView ? classes.singleCard : classes.card}>
                  <CardContent>
                    <Typography color="textPrimary">
                      Definitions
                    </Typography>
                    <div style={{width: '100%', maxHeight: !fullScreen ? 'calc(100vh - 366px)' : 'calc(100vh - 300px)', overflowY: 'auto'}}>
                      <List>
                        {instanceGeneratorDefinitions.map((instanceGeneratorDefinition, index) => (
                          <div key={instanceGeneratorDefinition.name}>
                            <ListItem
                              role={undefined} dense button
                              onClick={
                                !showFixMissingDefinitionErrorView ?
                                selectDefinitionGenerator({generatorDefinition: instanceGeneratorDefinition, index: index, type: 'instance', capType: 'Instance'}) :
                                handleSetGeneratorDefinitionforDeclarationsOfName({generatorDefinition: instanceGeneratorDefinition, generatorType: 'instance'})
                              }
                            >
                              <Radio
                                checked={
                                  instanceGeneratorDefinitionSelected.name !== undefined ?
                                  instanceGeneratorDefinitionSelected.name === instanceGeneratorDefinition.name :
                                  false
                                 }
                              />
                              <ListItemText
                                primary={instanceGeneratorDefinition.name}
                                secondary={
                                  <React.Fragment>
                                    <span hidden={instanceGeneratorDefinition.prefix === undefined}>
                                      {'Prefix: ' + instanceGeneratorDefinition.prefix}<br/>
                                    </span>
                                    <span hidden={instanceGeneratorDefinition.pattern === undefined}>
                                      {'Pattern: ' + instanceGeneratorDefinition.pattern}<br/>
                                    </span>
                                    <span>
                                      {
                                        instanceGeneratorDefinition.shorten !== undefined ?
                                        (instanceGeneratorDefinition.shorten || instanceGeneratorDefinition.shorten === 'yes' || instanceGeneratorDefinition.shorten === 1 ? 'Shorten' : '') :
                                        ''
                                      }
                                      {
                                        instanceGeneratorDefinition.shorten !== undefined && instanceGeneratorDefinition.uuid !== undefined ?
                                        (
                                          (instanceGeneratorDefinition.uuid || instanceGeneratorDefinition.uuid === 'yes' || instanceGeneratorDefinition.uuid === 1) &&
                                          (instanceGeneratorDefinition.shorten || instanceGeneratorDefinition.shorten === 'yes' || instanceGeneratorDefinition.shorten === 1) ?
                                          ', ' :
                                          ''
                                        ) :
                                        ''
                                      }
                                      {
                                        instanceGeneratorDefinition.uuid !== undefined ?
                                        (instanceGeneratorDefinition.uuid || instanceGeneratorDefinition.uuid === 'yes' || instanceGeneratorDefinition.uuid === 1 ? 'UUID' : '') :
                                        ''
                                      }
                                    </span>
                                    <span hidden={instanceGeneratorDefinition.description === undefined} style={{fontStyle: 'italic'}}>
                                      {instanceGeneratorDefinition.description}<br/>
                                    </span>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider />
                          </div>
                        ))}
                      </List>
                    </div>
                  </CardContent>
                </Card>
                <Slide direction="left" in={!showFixMissingDefinitionErrorView} mountOnEnter unmountOnExit>
                  <div className={classes.card}>
                    <Card style={{flexGrow: 1}}>
                      <CardContent>
                        <Typography
                          className={
                            classNames(
                              classes.pos,
                              (currInstanceGeneratorDeclaration !== undefined && currInstanceGeneratorDeclaration !== '') ?
                              (
                                currInstanceGeneratorDeclaration.definitionId === undefined ||
                                currInstanceGeneratorDeclaration.definitionId === null ?
                                classes.errorColor :
                                ''
                              ) :
                              ''
                            )
                          }
                          color="textPrimary" style={{paddingBottom: 10}}
                        >
                          Instance Declaration
                        </Typography>
                        <div style={{width: '100%', maxHeight: !fullScreen ? 'calc(100vh - 560px)' : 'calc(100vh - 494px)', overflowY: 'auto'}}>
                          {
                            this.getGeneratorDeclarationForm({
                              generatorDeclaration: currInstanceGeneratorDeclaration, 
                              generatorType: 'instance'
                            })
                          }
                        </div>
                      </CardContent>
                    </Card>
                    <Card hidden={instanceGeneratorDefinitionSelected.prefix === undefined} style={{marginTop: 20}}>
                      <CardContent>
                        <Typography color="textPrimary" style={{paddingBottom: 10}}>
                          Definition namespace
                        </Typography>
                        {this.getGeneratorDefinitionPrefixNamespaceForm({generatorDefinition: instanceGeneratorDefinitionSelected, generatorType: 'instance'})}
                      </CardContent>
                    </Card>
                  </div>
                </Slide>
              </div>
            </div>

            {/* #################### Label Generator ####################*/}
            <div>
              <DialogContentText className={classes.dialogContentTextStyle}>
                Please select some declaration to edit or press the "Plus" button (at the bottom) to add a new declaration. Don't forget to save before closing this dialog.
              </DialogContentText>
              <div className={classes.multiCardWrapper}>
                <Card className={classes.singleCard}>
                  <CardContent>
                    <EditToolbar deleteModeActive={this.state.labelDeclarationListDeleteModeActive}
                                     handleDeleteSelected={this.handleDeleteSelected}
                                     labelDeclarationsSelected={this.state.labelDeclarationsSelected}
                                     numSelected={this.state.labelDeclarationsSelected.length}
                    />
                    <div style={{width: '100%', maxHeight: !fullScreen ? 'calc(100vh - 390px)' : 'calc(100vh - 272px)', overflowY: 'auto'}}>
                      <List>
                        {labelGeneratorDeclarations.map((labelGeneratorDeclaration, index) => {
                          const isSelected = this.isLabelDeclarationSelected(labelGeneratorDeclaration.id);
                          return (
                            <div key={labelGeneratorDeclaration.id}>
                              <ListItem role={undefined} dense button={!this.state.labelDeclarationListDeleteModeActive}
                                        onClick={this.selectLabelGeneratorDeclaration({labelGeneratorDeclaration: labelGeneratorDeclaration, index: index})}>
                                <ListItemIcon>
                                  <Zoom in={this.state.labelDeclarationListDeleteModeActive}>
                                    <Checkbox checked={isSelected}
                                             hidden={!this.state.labelDeclarationListDeleteModeActive}
                                             onChange={this.handleDeclarationCheckBoxChange(labelGeneratorDeclaration)}/>
                                  </Zoom>
                                </ListItemIcon>
                                <ListItemText disableTypography
                                              primary={labelGeneratorDeclaration.name}
                                              secondary={
                                                <React.Fragment>
                                                  <span style={{fontStyle: 'italic'}}>
                                                    <PatternWithValuesAndColors declaration={labelGeneratorDeclaration}
                                                                                patternTypeColors={ patternTypeColors}/>
                                                  </span>
                                                  <span>
                                                    {
                                                      labelGeneratorDeclaration.shorten !== undefined ?
                                                      (labelGeneratorDeclaration.shorten || labelGeneratorDeclaration.shorten === 'yes' || labelGeneratorDeclaration.shorten === 1 ? 'Shorten' : '') :
                                                      ''
                                                    }
                                                    {
                                                      labelGeneratorDeclaration.shorten !== undefined && labelGeneratorDeclaration.uuid !== undefined ?
                                                      (
                                                        (labelGeneratorDeclaration.uuid || labelGeneratorDeclaration.uuid === 'yes' || labelGeneratorDeclaration.uuid === 1) &&
                                                        (labelGeneratorDeclaration.shorten || labelGeneratorDeclaration.shorten === 'yes' || labelGeneratorDeclaration.shorten === 1) ?
                                                        ', ' :
                                                        ''
                                                      ) :
                                                      ''
                                                    }
                                                    {
                                                      labelGeneratorDeclaration.uuid !== undefined ?
                                                      (labelGeneratorDeclaration.uuid || labelGeneratorDeclaration.uuid === 'yes' || labelGeneratorDeclaration.uuid === 1 ? 'UUID' : '') :
                                                      ''
                                                    }
                                                  </span>
                                                  <span hidden={labelGeneratorDeclaration.description === undefined} style={{fontStyle: 'italic'}}>
                                                    {labelGeneratorDeclaration.description}<br/>
                                                  </span>
                                                </React.Fragment>
                                              }/>
                              </ListItem>
                              <Divider />
                            </div>
                          )}
                        )}
                      </List>
                      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Fade in={!this.state.labelDeclarationListDeleteModeActive}>
                          <div className={classes.bottomFixFab}>
                            <Tooltip title="Declare new Label Generator" placement="left">
                              <Fab
                                color="primary"
                                aria-label="add-label-generator"
                                onClick={this.showFormToAddNewLabel}
                              >
                                <AddIcon />
                              </Fab>
                            </Tooltip>
                          </div>
                        </Fade>
                        <Fade in={this.state.labelDeclarationListDeleteModeActive}>
                          <div className={classes.bottomFixFab}>
                            <Tooltip title="Delete Selected" placement="left">
                              <span>
                                <Fab
                                  color="secondary"
                                  disabled={this.state.labelDeclarationsSelected.length === 0}
                                  aria-label="delete-label-generator(s)"
                                  onClick={this.handleDeleteSelected}
                                >
                                  <DeleteIcon />
                                </Fab>
                              </span>
                            </Tooltip>
                          </div>
                        </Fade>
                      </div>
                    </div>


                  </CardContent>
                </Card>
              </div>
            </div>

            {/* #################### Label Generator - Add Or Edit Form ####################*/}
            <div>
              {
                showFixMissingDefinitionErrorView ?
                <DialogContentText className={classes.dialogContentTextStyleTense}>
                    Please select some definition to assosiate this declaration with.
                </DialogContentText> :
                <DialogContentText className={classes.dialogContentTextStyle}>
                    Please select some definition and then fill in the respective form. Don't forget to save before closing this dialog.
                </DialogContentText>
              }
              <div className={classes.multiCardWrapper}>
                <Card className={showFixMissingDefinitionErrorView ? classes.singleCard : classes.card}>
                  <CardContent>
                    <Typography color="textPrimary">
                      Definitions
                    </Typography>
                    <div style={{width: '100%', maxHeight: !fullScreen ? 'calc(100vh - 366px)' : 'calc(100vh - 300px)', overflowY: 'auto'}}>
                      <List>
                        {labelGeneratorDefinitions.map((labelGeneratorDefinition, index) => (
                          <div key={labelGeneratorDefinition.name}>
                            <ListItem role={undefined} dense button
                                      onClick={
                                        !showFixMissingDefinitionErrorView ?
                                        selectDefinitionGenerator({generatorDefinition: labelGeneratorDefinition, index: index, type: 'label', capType: 'Label'}) :
                                        handleSetGeneratorDefinitionforDeclarationsOfName({generatorDefinition: labelGeneratorDefinition, generatorType: 'label'})
                                      }
                            >
                              <Radio checked={labelGeneratorDefinitionSelected.name !== undefined ?
                                              labelGeneratorDefinitionSelected.name === labelGeneratorDefinition.name :
                                              false
                                             }
                              />
                              <ListItemText primary={labelGeneratorDefinition.name}
                                            secondary={
                                              <React.Fragment>
                                                <span hidden={labelGeneratorDefinition.prefix === undefined}>
                                                  {'Prefix: ' + labelGeneratorDefinition.prefix}<br/>
                                                </span>
                                                <span hidden={labelGeneratorDefinition.pattern === undefined}>
                                                  {'Pattern: ' + labelGeneratorDefinition.pattern}<br/>
                                                </span>
                                                <span>
                                                  {
                                                    labelGeneratorDefinition.shorten !== undefined ?
                                                    (labelGeneratorDefinition.shorten || labelGeneratorDefinition.shorten === 'yes' || labelGeneratorDefinition.shorten === 1 ? 'Shorten' : '') :
                                                    ''
                                                  }
                                                  {
                                                    labelGeneratorDefinition.shorten !== undefined && labelGeneratorDefinition.uuid !== undefined ?
                                                    (
                                                      (labelGeneratorDefinition.uuid || labelGeneratorDefinition.uuid === 'yes' || labelGeneratorDefinition.uuid === 1) &&
                                                      (labelGeneratorDefinition.shorten || labelGeneratorDefinition.shorten === 'yes' || labelGeneratorDefinition.shorten === 1) ?
                                                      ', ' :
                                                      ''
                                                    ) :
                                                    ''
                                                  }
                                                  {
                                                    labelGeneratorDefinition.uuid !== undefined ?
                                                    (labelGeneratorDefinition.uuid || labelGeneratorDefinition.uuid === 'yes' || labelGeneratorDefinition.uuid === 1 ? 'UUID' : '') :
                                                    ''
                                                  }
                                                </span>
                                                <span hidden={labelGeneratorDefinition.description === undefined} style={{fontStyle: 'italic'}}>
                                                  {labelGeneratorDefinition.description}<br/>
                                                </span>
                                              </React.Fragment>
                                            }/>
                            </ListItem>
                            <Divider />
                          </div>
                        ))}
                      </List>
                    </div>
                  </CardContent>
                </Card>
                <Slide direction="left" in={!showFixMissingDefinitionErrorView} mountOnEnter unmountOnExit>
                  <div className={classes.card}>
                	  <Card style={{flexGrow: 1}}>
                  		<CardContent>
                        <Typography className={
                                      classNames(
                                        classes.pos,
                                        (currLabelGeneratorDeclaration !== undefined && currLabelGeneratorDeclaration !== '') ?
                                        (
                                          currLabelGeneratorDeclaration.definitionId === undefined ||
                                          currLabelGeneratorDeclaration.definitionId === null ?
                                          classes.errorColor :
                                          ''
                                        ) :
                                        ''
                                      )
                                    }
                                    color="textPrimary" style={{paddingBottom: 10}}>
                  			Label Declaration
                  		  </Typography>
                        <div style={{width: '100%', maxHeight: !fullScreen ? 'calc(100vh - 530px)' : 'calc(100vh - 466px)', overflowY: 'auto'}}>
                  		    {
                            this.getGeneratorDeclarationForm({
                              generatorDeclaration: currLabelGeneratorDeclaration, 
                              generatorType: 'label'
                            })
                          }
                        </div>
                  		</CardContent>
                	  </Card>
                	  <Card hidden={labelGeneratorDefinitionSelected.prefix === undefined} style={{marginTop: 20}}>
                  		<CardContent>
                  		  <Typography color="textPrimary" style={{paddingBottom: 10}}>
                  			Definition namespace
                  		  </Typography>
                  		  {this.getGeneratorDefinitionPrefixNamespaceForm({generatorDefinition: labelGeneratorDefinitionSelected, generatorType: 'label'})}
                  		</CardContent>
                	  </Card>
                	</div>
                </Slide>
              </div>
            </div>

          </SwipeableViews>

        </DialogContent>
        <DialogActions style={{marginBottom: 10}}>
          <div style={{display: 'flex', flexGrow: 1, paddingLeft: 15}}>
            <Zoom in={tabIndex === 2 && !showFixMissingDefinitionErrorView}>
              <Tooltip title="Go Back to List of Declarations"
                       placement="right"
                       enterDelay={500}
                       leaveDelay={200}>
                <Fab color="secondary"
                     size="small"
                     aria-label="go-back-to-label"
                     onClick={onLeave({actionType: 'goBack'})}>
                  <ArrowBackIcon />
                </Fab>
              </Tooltip>
            </Zoom>
            <Zoom in={tabIndex === 1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.labelDeclarationListDeleteModeActive}
                    onChange={this.handleDeleteLabelDeclarationListModeChange}
                    value={this.state.labelDeclarationListDeleteModeActive}
                    color="secondary"
                  />
                }
                label="Delete Mode"
              />
            </Zoom>
          </div>
          <div style={{paddingRight: 15, display: 'flex'}}>
            <Zoom in={tabIndex === 0 && !showFixMissingDefinitionErrorView}>
              <Button
                hidden={tabIndex !== 0}
                onClick={this.saveInstanceGeneratorDeclaration({
                  instanceGeneratorDeclaration: currInstanceGeneratorDeclaration,
                  generatorDefinition: instanceGeneratorDefinitionSelected,
                  type: 'instance'
                })}
                disabled={currInstanceGeneratorDeclaration === undefined || currInstanceGeneratorDeclaration === ''}
                color={!saveNow ? 'primary' : 'secondary'}
              >
                <span className={shakeNow ? 'rumble' : ''}>Save Instance Generator {instanceGeneratorDefinitionSelected.prefix !== undefined ? '& Namespace' : ''}</span>
              </Button>
            </Zoom>
            <Zoom in={tabIndex === 2  && !showFixMissingDefinitionErrorView}>
              <Button
                hidden={tabIndex !== 2}
                disabled={currLabelGeneratorDeclaration === undefined || currLabelGeneratorDeclaration === ''}
                onClick={this.saveLabelGeneratorDeclaration({
                  labelGeneratorDeclaration: currLabelGeneratorDeclaration, generatorDefinition: labelGeneratorDefinitionSelected,
                  type: 'label'
                })}
                color={!saveNow ? 'primary' : 'secondary'}
              >
                <span className={shakeNow ? 'rumble' : ''}>Save Label Generator {labelGeneratorDefinitionSelected.prefix !== undefined ? '& Namespace' : ''}</span>
              </Button>
            </Zoom>
            <div style={{width: 94}}>
              <Zoom in={showFixMissingDefinitionErrorView} style={{position: 'absolute'}}>
                <Button onClick={handleToggleFixMissingDefinitionErrorView({show: false})} color="primary">
                  cancel Fix
                </Button>
              </Zoom>
              <Zoom in={!showFixMissingDefinitionErrorView} style={{position: 'absolute', marginTop: tabIndex === 1 ? -18 : 0}}>
                <Button onClick={onLeave({actionType: 'close'})} color="primary">
                  Close
                </Button>
              </Zoom>
            </div>
          </div>
        </DialogActions>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.snackbarShown}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
              root: classes.snackbarWarning,
            }
          }}
          message={
            <div className={classes.snackbarMessageContainer}>
              <div className={classes.snackbarMessageText}>
                <WarningIcon className={classNames(classes.largeSnackbarIcon, classes.snackbarIconVariant)} />
              </div>
              <div className={classes.snackbarMessageText}>
                {
                  this.state.labelDeclarationsSelected.length > 1 ?
                  'Are you sure you want to delete these ' + this.state.labelDeclarationsSelected.length + ' selected label generator declarations?' :
                  'Are you sure you want to delete this label generator declaration?'
                }
              </div>
            </div>}
          action={[
            <Button key="confirmDeleteSelectedDeclarations" color="secondary" size="small" onClick={this.handleConfirmDeleteSelectedGeneratorDeclarations}>
              <b>Yes Delete {this.state.labelDeclarationsSelected.length > 1 ?'Them' : 'It'
              }</b>
            </Button>,
            <Button key="cancelDeleteSelectedDeclarations" color="secondary" size="small" onClick={this.handleCancelmDeleteSelectedGeneratorDeclarations}>
              <b>Cancel</b>
            </Button>,
          ]}
        />

      </Dialog>
    )
  }
}

GeneratorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneratorDialog);
