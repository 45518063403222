import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Badge from '@material-ui/core/Badge';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CodeIcon from '@material-ui/icons/Code';
import LinkIcon from '@material-ui/icons/Link';
import MessageIcon from '@material-ui/icons/Message';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import SourceIntermediateComponentView from './SourceIntermediateComponentView';
import TargetIntermediateComponentView from './TargetIntermediateComponentView';
import SourceIntermediateComponentEdit from './SourceIntermediateComponentEdit';
import TargetIntermediateComponentEdit from './TargetIntermediateComponentEdit';
import ConstExprEdit from './ConstExprEdit';
import ConstExprView from './ConstExprView';
import PatternWithValuesAndColors from './PatternWithValuesAndColors';
import GeneratorInfo from './GeneratorInfo';
import GeneratorTypesTooltip from './GeneratorTypesTooltip';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { ReasonerService } from '.././services/ReasonerService';
import { XpathService } from '.././services/XpathService';

//import 'codemirror/lib/codemirror.css';
//import 'codemirror/theme/material.css';
import { Controlled as CodeMirror } from 'react-codemirror2';
import ConfirmSnackbar from '.././snackbar/ConfirmationSnackbar';
import GeneratorDeclarationError from './GeneratorDeclarationError';

const reasonerService = new ReasonerService();
const xpathService = new XpathService();
import configuration from '../configuration.json';

const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)
const filter = createFilterOptions();

require('.././codemirror/autorefresh.ext');
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/addon/edit/matchtags');

//import './css/custom.css';

const styles = theme => ({
  root: {
    width: '100%',
  },
  linkPanelRoot: {
    marginLeft: 10,
    marginRight: 10,
  },
  panelWhenExpanded: {
    margin: '16px 10px !important'
  },
  card: {
    height: '100%',
    overflow: 'visible'
  },
  headerCellLeft: {
    paddingRight: 8,
  },
  headerCellRight: {
    paddingLeft: 8,
  },
  pos: {
    marginBottom: 12,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  shrinkedLinkButton: {
    fontSize: 14,
    height: 21,
    textTransform:'unset',
    whiteSpace: 'nowrap',
    padding: '0px 2px',
    margin: '0px -2px',
    lineHeight: 'unset',
    justifyContent: 'unset',
    minWidth: 'unset'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  expansionPanelSummary: {
    padding: 'unset',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    //color:'#2096f3',
    color: theme.palette.grey[600],
  },
  expandIcon: {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 24px)',
    width: 48,
  },
  hiddenIcon: {
    position: 'absolute',
    top: 0,
    width: 48,
    display: 'none',
  },
  editSummaryStyle: {
    //cursor: 'pointer',
  },
  viewSummaryStyle: {
    cursor: 'auto !important',
    userSelect: 'auto',
  },
  variableIcon: {
    color: '#52c41a',
  },
  variableCompIcon: {
    color: '#52c41a',
    fontWeight: '800',
    borderColor: '#52c41a',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  instanceInfoCompIcon: {
    color: '#e58ee9',
    fontWeight: '800',
    borderColor: '#e58ee9',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  expPanelSumRootDir: {
    display:'flex',
    flexDirection: 'column',
    width:'100%'
  },
  intermediateNodesWrapper: {
    width: '100%',
  },
  constExprNodesWrapper: {
    borderLeft:'solid rgba(0, 0, 0, 0.54)',
    marginLeft: 10,
    marginTop:5,
    paddingRight:10,
    width: '100%',
  },
  autocompleteFormControl: {
    width: '100%',
    display: 'flex'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
  },
  divOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
  },
  itemToDeleteHighlighted: {
    outline: '3px dashed #f50057',
  },
  negationCondition: {
    color: '#f50057',
    paddingRight: 5,
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: -8,
    userSelect: 'none',
  },
  negationConditionEdit: {
    paddingRight: 2,
  },
  conditionList: {
    paddingLeft: 5,
    //borderLeft: 'solid rgba(0, 0, 0, 0.54)',
    display: 'table-cell',
  },
  annotationOrAnd: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: 5,
    fontWeight: 'bold',
    color: '#f50057',
  },
  conditionListWrapper: {
    marginLeft: 45
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  twoColumnGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 5,
    padding: 5,
  },

  // Generator Declaration
  generatorDeclarationError: {
    color: 'red',
  },
  generatorDeclarationErrorneousDeleteIcon: {
    color: '#ff00001a',
    '&&:hover, &&:active': {
      color: 'red',
    },
  },
  generatorDeclarationValidDeleteIcon: {
    color: 'rgb(171 218 255)',
  },
  generatorDeclarationErrorneousChip: {
    color: 'red',
    border: 'none',
    //border: '1px solid red',
    '&&:hover, &&:active, &&:focus': {
      backgroundColor: '#ff00001a',
    },
  },
  generatorDeclarationValidChip: {
    border: 'none',
  },
  generatorDeclarationIsolationBorder: {
    // border: '1px dashed',
    // borderRadius: 10,
    // padding: 7,
    // marginBottom: 5,
  },
  // generatorDeclarationIsolationBorderValid: {
  //   borderColor: theme.palette.primary.main,
  // },
  generatorDeclarationIsolationBorderError: {
    //borderColor: 'red',
  },
  generatorDeclarationIconWrapperStyle: {
    display: 'table-cell',
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 10
  },
  patternWithValuesAndColorsWrapper: {
    fontStyle: 'italic',
    marginLeft: 9,
    wordBreak: 'break-all',
  },
  declarationLabelWrapper: {
    marginLeft: 9,
  },
  niceBadgeStyle: {
    right: -3,
    top: 9,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
  twoLinesSmallChip: {
    height: 44,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  threeLinesSmallChip: {
    height: 64,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  littleFocusedAvatar: {
    marginTop: -5,
    width:32,
    height:32
  },
  shrinkedSummary: {
    margin: 'unset',
    marginTop: 5,
  },
  errorLabelButton: {
    fontSize: 12,
    lineHeight: 'unset',
    color: theme.palette.primary.main,
  },
  dragHandleColor: {
    color: theme.palette.grey[600],
  },
  fontAwesomeIcon: {
    fontSize: 16,
    width: 16,
  },
  balloonCommentContentStyle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 2,
    color: theme.palette.primary.contrastText,
  },
  balloonLinkCommentStyle: {
    position: 'absolute',
    marginTop: -3,
    backgroundColor: theme.palette.primary.main,
		border: '1px solid #ccc',
		borderRadius: 5,
		boxShadow: '0 0 20px rgba(0,0,0,0.2)',
    fontStyle: 'italic',
    paddingLeft: 6,
    paddingRight: 5,
    right: 50,
    maxWidth: 'calc(50% - 110px)',
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    '&::before': {
      content: `''`,
	    position: 'absolute',
      borderStyle: 'solid',
			left: 15,
    },
    '&::after': {
      content: `''`,
	    position: 'absolute',
      borderStyle: 'solid',
			left: 16,
    },
  },
  balloonArrow: {
    '&::before': {
      borderWidth: '8px 10px 0 1px',
      borderColor: '#ccc transparent transparent transparent',
      bottom: -8,
    },
    '&::after': {
      borderWidth: '7px 9px 0 0',
      borderColor: '#fff transparent transparent transparent',
      borderTopColor: theme.palette.primary.main,
      bottom: -7,
    },
  },
  headerCommentToEditHighlighted: {
    backgroundColor: theme.palette.primary.dark,
    '&::after': {
      borderTopColor: theme.palette.primary.dark,
    },
  },
  outerLinkCommentStyle: {
    fontStyle: 'italic',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 9,
    paddingLeft: 6,
    paddingRight: 5,
    backgroundColor: '#b3a3a3',
    borderWidth:2,
    position: 'absolute',
    right: 50,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 'calc(50% - 110px)',
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
    fontWeight: 'normal',
  },
  // Complex Icons style
  letter: {
    position: 'absolute',
    top: '64.5%', // Position vertically
    left: '54%', // Position horizontally
    transform: 'translate(-50%, -50%)', // Center the letter
    fontSize: '0.53rem', // Adjust the font size of the letter "L"
    color: 'white', // Set the color of the letter "L" to white
  },
  svgIcon: {
    fontSize: 18, // Adjust the font size of the SVG icon
  },
  '@keyframes highlightAnimation': {
    '0%': {
      transform: 'rotate(0deg)',
      background: 'none',
    },
    '50%': {
      transform: 'rotate(0deg)',
      background: theme.palette.primary.light,
    },
    '100%': {
      transform: 'rotate(0deg)',
      background: 'none',
    },
  },
  highlightAnimation: {
    animation: '$highlightAnimation 2s ease 700ms', // Apply the animation to the highlighted class
  },
});

const LinkCopyPaste = ({ classes }) => {
  return (
    <div >
      <FileCopyIcon style={{ fontSize: 18 }} className={classes.svgIcon}/>
      <div className={classes.letter}>L</div>
    </div>
  );
};

const LinkCopyPasteComplexIcon = withStyles(styles)(LinkCopyPaste);

export class LinkComponent extends React.Component {
//export class LinkComponent extends React.Component {

  state = {
    targetRelationIsLoading: false,
    targetEntityIsLoading: false,
    sourceNodeOptions: [],
    sourceRelationOptions:[],
    targetEntityOptions: [],
    targetRelationOptions:[],
    sourceSM: 6,
    targetSM: 6,
    confirmImportLinkFragmentCodeSnackbarShown: false,
    confirmCloseFragmentCodeSnackbarShown: false,
    hasBeenRendered: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    /*
    if(this.props.visible !== nextProps.visible) {
      return true;
    }*/
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.canEdit !== nextProps.canEdit) {
      return true;
    }
    if(this.props.domainSourceNode !== nextProps.domainSourceNode) {
      return true;
    }
    if(this.props.isLinkExpanded !== nextProps.isLinkExpanded) {
      return true;
    }
    if(this.props.linkIndex !== nextProps.linkIndex) {
      return true;
    }
    if(this.props.mappingIndex !== nextProps.mappingIndex) {
      return true;
    }
    if(this.props.isMappingInUse !== nextProps.isMappingInUse) {
      return true;
    }
    if(this.props.leaveEverywhereMoreSpace !== nextProps.leaveEverywhereMoreSpace) {
      return true;
    }
    if(this.props.isTheLastOne !== nextProps.isTheLastOne) {
      return true;
    }
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.displayFirstCommentOnHeader !== nextProps.displayFirstCommentOnHeader) {
      return true;
    }
    if(this.props.compactViewMode !== nextProps.compactViewMode) {
      return true;
    }
    if(this.props.compactViewEnabled !== nextProps.compactViewEnabled) {
      return true;
    }
    if(this.props.focusedObjectIdUserMap !== nextProps.focusedObjectIdUserMap) {
      return true;
    }
    if(this.props.link !== nextProps.link) {
      return true;
    }
    if(this.props.deletingLinkId !== nextProps.deletingLinkId) {
      return true;
    }
    if(this.props.mappingOrLinkToSpotAndHighlight !== nextProps.mappingOrLinkToSpotAndHighlight) {
      return true;
    }
    else {
      return false;
    }
  }

  /*
  // Run only once like constructor
  componentDidMount()  {
    this.props.storeLinkRef(this.props.link.id);
  }
  */

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    setTimeout( () => {
      this.setState({hasBeenRendered: true});
    }, 300);
    this.props.handleHoldRefRecord({
      refRecType: 'linkRefRecordMap',
      ref: this.ref,
      id: this.props.link.id
    });
  }

  componentWillUnmount() {
    this.props.handleRemoveRefRecord({
      refRecType: 'linkRefRecordMap',
      ref: this.ref,
      id: this.props.link.id
    });
  }

  // ******************************************************************
  // ****************************** Link ******************************
  // ******************************************************************

  handleRetrieveXpathOptions = async (paramObj) => {
    if(this.props.domainSourceNode.item !== '') {
      // Notifying for loading data
      this.setState({[paramObj.name + 'IsLoading']: true}, function() {
        // Resetting option list
        this.setState({[paramObj.name + 'Options']: []});
      });
      // Async Wait
      try {
        // Getting the selected item
        let res = await xpathService.getXpaths({
          id: this.props.currMappingProjectId,
          type: 'source',
          xpath: this.props.domainSourceNode !== '' ? this.props.domainSourceNode.item.value : ''//[paramObj.name].item.value ? [paramObj.name].item.value : ''
        });

        if(res.data !== false) {
          // Creating option list
          this.setState({[paramObj.name + 'Options']: res.data.response.map(xpath => ({
            value: xpath.value,
            label: xpath.value,//xpath.label,
            //full: xpath.label,
          }))
        }, function () {
            // Notifying that loading data is over
            this.setState({[paramObj.name + 'IsLoading']: false});
          });
        }
        else {
          // Resetting option list
          this.setState({[paramObj.name + 'Options']: []}, function() {
            // Notifying that loading data is over
            this.setState({[paramObj.name + 'IsLoading']: false});
          });
        }
      }
      catch (e) {
        console.error('Failure while retrieving XPaths!');
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
        // Resetting option list
        this.setState({[paramObj.name + 'Options']: []}, function() {
          // Notifying that loading data is over
          this.setState({[paramObj.name + 'IsLoading']: false});
        });
      } // catch - ends

    } // If there is value selected for the respective targetRelation - Ends
    else {
      this.setState({
        [paramObj.name + 'Options']: []
      });
    }
  };

  // paramObj: {name: 'targetRelation | targetEntity'}
  handleRetrieveOptions = paramObj => async () => {
    //console.log('this.props.link.targetEntity');
    //console.log(this.props.link.targetEntity);
    if(paramObj.name === 'targetRelation') {
      // Since this is the link's target relation, the params of the service
      // needs the selection made at the domain's target entity.
      // This is passed as a parameter from the parent.
      // This param is named 'domainTargetEntity'.

      // If there is value selected for the respective targetEntity (this is an array of classes)
      if(this.props.domainTargetEntity.item !== '') {
        // Notifying for loading data
        this.setState({targetRelationIsLoading: true}, function() {
          // Resetting option list
          this.setState({targetRelationOptions: []});
        });

        // Async Wait
        try {
          // Getting the selected items (case Array of Items)
          let selectedClasses = [];
          this.props.domainTargetEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
          let res = await reasonerService.getProperties({
            id: this.props.currMappingProjectId,
            type: 'target',
            request: selectedClasses
          });

          if(res.data.response[0].options !== undefined) {
            // Creating option list
            this.setState({targetRelationOptions: res.data.response}, function () {
              // Notifying that loading data is over
              this.setState({targetRelationIsLoading: false});
            });
          }
          else {
            // Resetting option list
            this.setState({targetRelationOptions: []}, function() {
              // Notifying that loading data is over
              this.setState({targetRelationIsLoading: false});
            });
          }
        }
        catch (e) {
          console.error('Failure while retrieving target relation properties!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
          // Resetting option list
          this.setState({targetRelationOptions: []}, function() {
            // Notifying that loading data is over
            this.setState({targetRelationIsLoading: false});
          });
        } // catch - ends

      } // If there is value selected for the respective targetRelation - Ends
      else {
        this.setState({
          targetRelationOptions: []
        });
      }
    } // if(paramObj.name === 'targetRelation') - ends

    else if(paramObj.name === 'targetEntity') {
      // Two caces:
      //  1. load based on "Target Relation" (when this is there is none selected),
      //  2. Load all classes (when there is at least one selected),
      // Case load based on "Target Relation"
      if(this.props.link.targetEntity.item.length === 0) {
        // In the case of the target entity the required param of the
        // service to be called (in case that there are not intermediates) is
        // the value of the target relation of this link. In case that there are
        // intermediates defined, then this is passed from the parent as param
        // named 'previousRelation'
        var previousRelation = {item:''};
        if(this.props.link.targetIntermediateIds.length === 0) {
          previousRelation = this.props.link.targetRelation;
        }
        else { // There are intermediates
          previousRelation = this.props.link.targetIntermediates[this.props.link.targetIntermediateIds[this.props.link.targetIntermediateIds.length-1]].targetRelation;
        }
        if(previousRelation.item.value !== undefined) {
          // Notifying for loading data
          this.setState({targetEntityIsLoading: true}, function() {
            // Resetting option list
            this.setState({targetEntityOptions: []});
          });

          // Async Wait
          try {
            let res = await reasonerService.getObjects({
              id: this.props.currMappingProjectId,
              type: 'target',
              //property: previousRelation.item.full ? previousRelation.item.full : ''
              property: previousRelation.item.full ? previousRelation.item.full : previousRelation.item.value
            });

            if(res.data.response[0].options !== undefined) {
              // Creating option list
              this.setState({targetEntityOptions: res.data.response}, function () {
                // Notifying that loading data is over
                this.setState({targetEntityIsLoading: false});
              });
            }
            else {
              // Resetting option list
              this.setState({targetEntityOptions: []}, function() {
                // Notifying that loading data is over
                this.setState({targetEntityIsLoading: false});
              });
            }
          }
          catch (e) {
            console.error('Failure while retrieving target entity properties!');
            if(e.response !== undefined) {
              console.error(e.response.status);
            }
            // Resetting option list
            this.setState({targetEntityOptions: []}, function() {
              // Notifying that loading data is over
              this.setState({targetEntityIsLoading: false});
            });
          } // catch - ends
        } // If there is value selected for the respective targetRelation - Ends
        else {
          this.setState({
            targetEntityOptions: []
          });
        }
      }
      // Case load all classes (there is already one selected)
      else {
        this.setState({targetEntityOptions: this.props.allClasses});
      }
    } // if(paramObj.name === 'targetEntity') - Ends
    else {
      this.setState({
        targetEntityOptions: []
      });
    }
  };

  // props: {status:'mouseEnter | mouseLeave}
  handleHoverDeleteLink = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({isHovered: hovered});
    //this.forceUpdate();
  }

  // Changes CSS when hovering over the balloon comment displayed on the header
  // props: {status:'mouseEnter | mouseLeave}
  handleHoverEditFirstComment = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({commentIsHovered: hovered});
  }

  // Fragment Code Related
  handleShowConfirmationForApplyingFragmentCodeChanges = () => {
    this.setState({
      confirmImportFragmentCodeSnackbarShown: true
    });
  }

  // Close Confirmation snackbar for Domain
  handleCloseImportFragmentCodeSnackbar = () => {
    this.setState({
      confirmImportFragmentCodeSnackbarShown : false,
    });
  }

  // Close Confirmation snackbar for Domain
  // props: {mappingId: mappingId}
  handleApplyCodeFragmentChanges = props => () => {
    this.setState({
      confirmImportFragmentCodeSnackbarShown : false,
    }, function() {
      // Callback to apply the changes on the parent
      this.props.importX3ml(props);
    });
  }

  // Confirmation when the fragment code has changed and user tries to close the editor

  // props: {mappingId: <Text>, linkId: <Text>, showCode: <Boolean>}
  handleConfirmOrToggleShowCode = props => event => {
    event.stopPropagation();
    if(!props.showCode) { // Case where toggle --> Close
      const isCodeChanged = this.props.isFragmentCodeChanged(props);
      if(isCodeChanged) {
        this.setState({
          confirmCloseFragmentCodeSnackbarShown: true
        });
      }
      else {
        this.props.handleToggleShowCodeCallBack(props);
      }
    }
    else {
      this.props.handleToggleShowCodeCallBack(props);
    }
  }

  // Close Confirmation snackbar for Domain
  handleCloseConfirmCloseFragmentCodeSnackbar = () => {
    this.setState({
      confirmCloseFragmentCodeSnackbarShown : false,
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>}
  handleConfirmCloseFragmentCode = props => () => {
    this.setState({
      confirmCloseFragmentCodeSnackbarShown : false,
    }, function() {
      this.props.handleToggleShowCodeCallBack(props);
    });
  }


  // Due to development
  printState = () => {
    console.log(this.state)
  }

  render() {
    const { classes } = this.props;
    const { targetEntityOptions, targetRelationOptions, sourceRelationOptions, sourceNodeOptions,
            targetRelationIsLoading, targetEntityIsLoading, sourceRelationIsLoading, sourceNodeIsLoading
          } = this.state;
    const { link, mappingId, isMappingInUse, domainSourceNode, linkIndex, mappingIndex, isLinkExpanded, leaveEverywhereMoreSpace, allClasses, canEdit,
            isTheLastOne, handleExpandChange, handleInputChange, handleInputChangeButton, handleAddIntermediate,
            handleAddConstExpr, handleUseGenerator, handleGoToGeneratorDeclarationForm,
            handleDirectDeleteInstanceGeneratorDeclaration, handleDirectDeleteLabelGeneratorDeclarations,
            mappingBackgroundColors, patternTypeColors,
            handleUseVariable, handleDirectDeleteVariable, handleUseNamedgraph,
            handleUseCondition, handleGoToConditionForm, handleDirectDeleteCondition,
            handleCodeMirrorInputChange, handleUseComment,
            handleUseInstanceInfo, handleDirectDeleteInstanceInfo, instanceInfoStyleBasedOnLinesRequired,
            compactViewEnabled, compactViewMode,
            accordionFocus, accordionBlur, focusedObjectIdUserMap, deletingLinkId, handleCloneLink, handleCopyHyperlink, handleCopyLink,
            navigateToFirstComment, handleChangeIsInUseStatus, mappingOrLinkToSpotAndHighlight,
          } = this.props;

    // Used when deleting to "unmount" itself
    /*
    if(!this.props.visible) {
      return null;
    }
    */
    console.log('LinkComponent rendered');
    // This is the source Relation intermediate (list of <Source Node, Source Relation> tuples) View card content
    var sourceRelationViewIntermediateList = link.sourceIntermediateIds.map((intermediateId, intermediateIndex) => {
      let intermediate = link.sourceIntermediates[intermediateId];

      return (
        <SourceIntermediateComponentView key={'sourceIntermediateView_' + intermediateId}
                                         intermediate={intermediate}
                                         intermediateIndex={intermediateIndex}
                                         domainSourceNode={domainSourceNode}
        />
      );
    });

    // This is the target Relation intermediate (list of <Target Entity, Target Relation> tuples) View card content
    var targetRelationViewIntermediateList = link.targetIntermediateIds.map((intermediateId, intermediateIndex) => {
      let intermediate = link.targetIntermediates[intermediateId];


      return (
        <TargetIntermediateComponentView
          key={'targetIntermediateView_' + intermediateId}
          intermediate={intermediate}
          intermediateIndex={intermediateIndex}
          patternTypeColors={patternTypeColors}
          compactViewMode={compactViewMode}
          compactViewEnabled={this.props.compactViewEnabled}
        />
      );
    });

    // This is the source Relation intermediate (list of <Target Entity, Target Relation> tuples) Edit card content
    var sourceRelationEditIntermediateList = link.sourceIntermediateIds.map((intermediateId, intermediateIndex) => {
      let intermediate = link.sourceIntermediates[intermediateId];
      return (
        <Collapse key={'sourceIntermediateEdit_' + intermediateId}>
          <SourceIntermediateComponentEdit currMappingProjectId={this.props.currMappingProjectId}
                                           mappingId={mappingId}
                                           linkId={link.id}
                                           intermediate={intermediate}
                                           intermediateId={intermediateId}
                                           intermediateIndex={intermediateIndex}
                                           domainSourceNode={domainSourceNode}
                                           handleDeleteIntermediate={this.props.handleDeleteIntermediate}
                                           handleInputChange={this.props.handleIntermediateInputChange}
                                           handleInputChangeButton={this.props.handleIntermediateInputChangeButton}
                                           handleShowConfirmDeleteSnackbar={this.props.handleShowConfirmDeleteSnackbar}
          />
        </Collapse>
      );
    });

    // This is the target Relation intermediate (list of <Target Entity, Target Relation> tuples) Edit card content
    var targetRelationEditIntermediateList = link.targetIntermediateIds.map((intermediateId, intermediateIndex) => {
      let intermediate = link.targetIntermediates[intermediateId];
      return (
        <Collapse key={'targetIntermediateEdit_' + intermediateId}>
          <TargetIntermediateComponentEdit
            currMappingProjectId={this.props.currMappingProjectId}
            mappingId={mappingId}
            linkId={link.id}
            previousRelation={
              intermediateIndex > 0 ?
              (
                link.targetIntermediates[link.targetIntermediateIds[intermediateIndex-1]].targetRelation.item.value !== undefined ?
                link.targetIntermediates[link.targetIntermediateIds[intermediateIndex-1]].targetRelation :
                {item: ''}
              ) :
              (
                link.targetRelation.item.value !== undefined ?
                link.targetRelation :
                {item: ''}
              )
            }
            intermediate={intermediate}
            intermediateId={intermediateId}
            intermediateIndex={intermediateIndex}
            allClasses={allClasses}
            handleDeleteIntermediate={this.props.handleDeleteIntermediate}
            handleAddConstExpr={this.props.handleAddIntermediateConstExpr}
            handleInputChange={this.props.handleIntermediateInputChange}
            handleInputChangeButton={this.props.handleIntermediateInputChangeButton}
            handleDeleteIntermediateConstExpr={this.props.handleDeleteIntermediateConstExpr}
            handleInputChangeIntermediateConstExpr={this.props.handleInputChangeIntermediateConstExpr}
            handleInputChangeIntermediateConstExprButton={this.props.handleInputChangeIntermediateConstExprButton}
            handleUseGenerator={this.props.handleTargetIntermediateUseGenerator}
            handleConstExprUseGenerator={this.props.handleConstExprUseGenerator}
            handleGoToGeneratorDeclarationForm={this.props.handleGoToGeneratorDeclarationForm}
            patternTypeColors={patternTypeColors}
            compactViewMode={compactViewMode}
            handleUseVariable={this.props.handleTargetIntermediateUseVariable}
            handleDirectDeleteVariable={this.props.handleDirectDeleteVariable}
            handleConstExprUseVariable={this.props.handleConstExprUseVariable}
            handleShowConfirmDeleteSnackbar={this.props.handleShowConfirmDeleteSnackbar}
            handleDirectDeleteInstanceGeneratorDeclaration={handleDirectDeleteInstanceGeneratorDeclaration}
            handleDirectDeleteLabelGeneratorDeclarations={handleDirectDeleteLabelGeneratorDeclarations}
            handleUseInstanceInfo={this.props.handleTargetIntermediateUseInstanceInfo}
            handleConstExprUseInstanceInfo={this.props.handleConstExprUseInstanceInfo}
            handleDirectDeleteInstanceInfo={this.props.handleDirectDeleteInstanceInfo}
            instanceInfoStyleBasedOnLinesRequired={this.props.instanceInfoStyleBasedOnLinesRequired}
            compactViewEnabled={this.props.compactViewEnabled}
            handleDisplayConstExpr={this.props.handleDisplayConstExpr}
          />
        </Collapse>
      );
    });

    // Additional of the link - Edit
    var linkEditConstExprList = link.constExprIds.map((constExprId, constExprIndex) => {
      let constExpr = link.constExprs[constExprId];
      return (
        <Slide direction={'left'}
               key={'linkConstExprEdit_' + constExprId}
               style={{ transitionDelay: '400ms' }}>
               {/*timeout={400}*/}

          <ConstExprEdit
            currMappingProjectId={this.props.currMappingProjectId}
            mappingId={mappingId}
            linkId={link.id}
            intermediateId={-1}
            constExpr={constExpr}
            constExprId={constExprId}
            constExprIndex={constExprIndex}
            allClasses={allClasses}
            previousEntity={link.targetEntity}
            handleDeleteConstExpr={this.props.handleDeleteLinkConstExpr}
            handleInputChange={this.props.handleInputChangeLinkConstExpr}
            handleInputChangeButton={this.props.handleInputChangeLinkConstExprButton}
            handleUseGenerator={this.props.handleConstExprUseGenerator}
            handleUseVariable={this.props.handleConstExprUseVariable}
            handleDirectDeleteVariable={this.props.handleDirectDeleteVariable}
            handleGoToGeneratorDeclarationForm={this.props.handleGoToGeneratorDeclarationForm}
            startInverseZindex={999}
            patternTypeColors={patternTypeColors}
            compactViewMode={compactViewMode}
            handleShowConfirmDeleteSnackbar={this.props.handleShowConfirmDeleteSnackbar}
            handleDirectDeleteInstanceGeneratorDeclaration={handleDirectDeleteInstanceGeneratorDeclaration}
            handleDirectDeleteLabelGeneratorDeclarations={handleDirectDeleteLabelGeneratorDeclarations}
            handleUseInstanceInfo={this.props.handleConstExprUseInstanceInfo}
            handleDirectDeleteInstanceInfo={this.props.handleDirectDeleteInstanceInfo}
            instanceInfoStyleBasedOnLinesRequired={this.props.instanceInfoStyleBasedOnLinesRequired}
            compactViewEnabled={this.props.compactViewEnabled}
            handleDisplayConstExpr={this.props.handleDisplayConstExpr}
            index={constExprIndex}
          />
        </Slide>
      );
    });

    // Additional of the link - View
    var linkViewConstExprList = link.constExprIds.map((constExprId, constExprIndex) => {
      let constExpr = link.constExprs[constExprId];
      return (
        <ConstExprView
          key={'linkConstExprView_' + constExpr.id }
          currMappingProjectId={this.props.currMappingProjectId}
          constExpr={constExpr}
          constExprId={constExprId}
          constExprIndex={constExprIndex}
          patternTypeColors={patternTypeColors}
          compactViewMode={compactViewMode}
          compactViewEnabled={this.props.compactViewEnabled}
        />
      );
    });

    // Dynamically changing the space that the source and target sections occupies
    // They are only used for ExpansionPanelDetails
    // The respective decreaseSpaceWhenConstExprIsAdded & inreaseSpaceWhenConstExprIsAdded
    // are used for the ExpansionPanelSummary
    let sourceWidth = leaveEverywhereMoreSpace ? '33%' : '50%';
    let targetWidth = leaveEverywhereMoreSpace ? '67%' : '50%';

    const instanceGeneratorDeclarationErrorMsg =
      link.targetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        link.targetEntity.instanceGeneratorDeclaration.definitionId !== undefined ?
        (
          link.targetEntity.instanceGeneratorDeclaration.definitionId === null ?
          'Missing Generator Definition' :
          ''
        ) :
        ''
      ) :
      '';

    const lastInstanceGeneratorDeclarationArgErrorIndex =
      link.targetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        link.targetEntity.instanceGeneratorDeclaration.args !== undefined &&
        link.targetEntity.instanceGeneratorDeclaration.args.length > 0 ?
        link.targetEntity.instanceGeneratorDeclaration.args.findIndex(
          obj => (
            link.targetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'literal' &&
            link.targetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'preflabel'
          ) ?
          obj.error_type_text !== '' :
          (
            obj.name.toLowerCase() !== 'language' ?
            obj.error_type_text !== '' :
            false
          )
        ) :
        -1
      ) :
      -1;

    const instanceGeneratorDeclarationArgsErrorMsg =
      lastInstanceGeneratorDeclarationArgErrorIndex !== -1 ?
      link.targetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].name + ': ' +
      link.targetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].error_type_text :
      '';

    const instanceGeneratorDeclarationErrorStyle =
      instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
      classes.generatorDeclarationError :
      '';

    return (
      <div
        style={{margin: '1px 0 1px 0'}}
        className={deletingLinkId === link.id ? 'fadeOut' : this.state.hasBeenRendered ? '' : 'fadeIn'}
        ref={ref => this.ref = ref}
      >
        <Accordion
          classes={{expanded: classes.panelWhenExpanded}}
          className={
            classNames(
              classes.linkPanelRoot,
              this.state.isHovered ? classes.itemToDeleteHighlighted : ''
            )
          }
          expanded={isLinkExpanded}
          onChange={handleExpandChange({type: 'link', id: link.id, mappingId: mappingId})}
          style={{
            borderBottomLeftRadius: isTheLastOne ? '4' : 'unset',
            borderBottomRightRadius: isTheLastOne ? '4' : 'unset',
            borderTopLeftRadius: 'unset',
            borderTopRightRadius: 'unset',
            backgroundColor:
              !isLinkExpanded ?
              (
                mappingBackgroundColors!== undefined ?
                (
                  linkIndex%2 !== 0 ? // isNotEven (don't forget that the first link has index 0 not 1)
                  mappingBackgroundColors.linkEven.color :
                  mappingBackgroundColors.linkOdd.color
                ) :
                ''
              ) :
              ''
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            // className={classes.shrinkedSummary}
            className={
              classNames(
                classes.shrinkedSummary,
                mappingOrLinkToSpotAndHighlight === link.id ? classes.highlightAnimation : '',
              )
            }
            expandIcon={<ExpandMoreIcon />}
            classes={{
              expandIcon: canEdit ? classes.expandIcon : classes.hiddenIcon,
              root: canEdit ? classes.editSummaryStyle : classes.viewSummaryStyle
            }}
          >
            <div className={classes.expPanelSumRootDir}>
              <div>
                <Grid container spacing={1} >
                  <Grid item xs={12} style={{color:'#2096f3', fontWeight: 'bold'}}>
                    <div style={{display: 'flex'}}>
                      <div style={{display: 'flex', width: '100%'}}>
                        <div style={{paddingRight: 10, margin: 'auto', marginLeft: 'unset', marginRight: 'unset'}}>
                          <DragHandleIcon className={classNames(classes.dragHandleColor, 'drag-handle-link')} />
                        </div>
                        <div style={{paddingRight: 5, margin: 'auto', marginLeft: 'unset', marginRight: 'unset'}}>
                          <Tooltip
                            title={link.showCode ? 'Hide Fragment Code' : 'Show Fragment Code'}
                            placement="top-start"
                            enterDelay={500}
                            leaveDelay={200}
                          >
                            <IconButton
                              style={{padding:'unset', top: -2}}
                              hidden={!isLinkExpanded}
                              color="primary"
                              aria-label="code"
                              onClick={this.handleConfirmOrToggleShowCode({mappingId: mappingId, linkId: link.id, showCode: !link.showCode})}
                            >
                              <CodeIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div style={{display: 'flex'}}>
                          <Typography className={classes.heading} style={{fontWeight:'bold', paddingRight: 5}}>
                            <Tooltip
                              title={'Uncheck this box if you want this link to be ignored when producing the X3ML or the output'}
                              placement="top"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <Checkbox
                                size="small"
                                checked={
                                  link.isInUse !== undefined ?
                                  link.isInUse :
                                  true
                                }
                                disabled={isMappingInUse !== undefined ? !isMappingInUse : false}
                                onClick={handleChangeIsInUseStatus({mappingId: mappingId, linkId: link.id})}
                                style={{padding: 5, marginTop: -5}}
                              />
                            </Tooltip>
                            <Tooltip title={'ID: ' + link.id}
                                     placement="top"
                                     enterDelay={500}
                                     leaveDelay={200}>
                              <span>{'Link #' + (linkIndex+1) + ' (of mapping #' + (mappingIndex+1) + ')'}</span>
                            </Tooltip>
                          </Typography>
                          {/*Namedgraph*/}
                          <Fade
                            in={
                              link.namedgraph !== undefined ?
                              (
                                link.namedgraph.turnedOn ?
                                true :
                                isLinkExpanded
                              ) :
                              isLinkExpanded
                            }
                            mountOnEnter unmountOnExit
                          >
                            <div style={{display: 'flex', margin: 'auto'}}>
                              <Typography className={classes.heading} style={{paddingRight: 5}}>{'- Named Graph:'}</Typography>
                              {
                                <React.Fragment>
                                  {
                                    canEdit ?
                                    <div style={{display: 'flex', paddingRight: 5}}>
                                      <Button color="primary"
                                              className={classes.shrinkedLinkButton}
                                              onClick={handleUseNamedgraph({mappingId: mappingId, linkId: link.id})}
                                              style={{marginTop: 1}}
                                      >
                                        {
                                          link.namedgraph !== undefined ?
                                          (
                                            link.namedgraph.turnedOn ?
                                            (
                                              link.namedgraph.value !== '' ?
                                              link.namedgraph.value :
                                              '"... ∅ ..."'
                                            ) :
                                            <span style={{fontStyle: 'italic'}}>(Optionally add one)</span>
                                          ) :
                                          <span style={{fontStyle: 'italic'}}>(Optionally add one)</span>
                                        }
                                      </Button>
                                    </div> :
                                    link.namedgraph !== undefined ?
                                    (
                                      link.namedgraph.turnedOn ?
                                      (
                                        link.namedgraph.value !== '' ?
                                        link.namedgraph.value :
                                        '"... ∅ ..."'
                                      ) :
                                      ''
                                    ) :
                                    ''
                                  }
                                </React.Fragment>
                              }
                            </div>
                          </Fade>
                          <div style={{flexGrow: 1}} />
                          <div style={{paddingLeft: 5, margin: 'auto'}}>
                            <Tooltip
                              title={'Copy hyperlink to this link'}
                              placement="bottom-start"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <IconButton
                                style={{padding:5, top: -2}}
                                color="primary"
                                aria-label="copy hyperlink"
                                onClick={handleCopyHyperlink({mappingId: mappingId, linkId: link.id})}
                              >
                                <LinkIcon style={{fontSize: 16, transform: 'rotate(45deg)'}}/>
                              </IconButton>
                            </Tooltip>
                          </div>
                          <Fade in={canEdit}>
                            <div style={{paddingLeft: 5, margin: 'auto'}}>
                              <Tooltip
                                title={'Clone this link'}
                                placement="bottom-start"
                                enterDelay={500}
                                leaveDelay={200}
                              >
                                <IconButton
                                  style={{padding:5, top: -2}}
                                  color="primary"
                                  aria-label="clone"
                                  onClick={handleCloneLink({mappingId: mappingId, linkId: link.id})}
                                >
                                  <Icon className={classNames(classes.fontAwesomeIcon, 'fas fa-clone')} style={{fontSize: 16}}/>
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Fade>
                          <div
                            className={
                              classNames(
                                classes.balloonLinkCommentStyle,
                                classes.balloonArrow,
                                this.state.commentIsHovered ? classes.headerCommentToEditHighlighted : ''
                              )
                            }
                            hidden={
                              link.comments === undefined || link.comments === null ?
                              true :
                              link.comments.length === 0 ?
                              true :
                              link.comments[0].rationale === undefined || link.comments[0].rationale === null ?
                              true :
                              false
                            }
                            onClick={
                              navigateToFirstComment({
                                mappingId: mappingId,
                                linkId: link.id
                              })
                            }
                          >
                            <div
                              className={classes.balloonCommentContentStyle}
                              onMouseEnter={this.handleHoverEditFirstComment({status:'mouseEnter'})}
                              onMouseLeave={this.handleHoverEditFirstComment({status:'mouseLeave'})}
                            >
                              {
                                link.comments !== undefined && link.comments !== null ?
                                (
                                  link.comments.length > 0 ?
                                  (
                                    link.comments[0].rationale !== undefined && link.comments[0].rationale !== null ?
                                    link.comments[0].rationale :
                                    ''
                                  ) :
                                  ''
                                ) :
                                ''
                              }
                            </div>
                          </div>
                          <Fade in={canEdit}>
                            <div style={{paddingLeft: 5, margin: 'auto'}}>
                              <Tooltip
                                title={'Copy this link'}
                                placement="bottom-start"
                                enterDelay={500}
                                leaveDelay={200}
                              >
                                <IconButton
                                  style={{ padding: 5, top: -2, width: 30, height: 30 }}
                                  color="primary"
                                  aria-label="clone"
                                  onClick={handleCopyLink({mappingId: mappingId, linkId: link.id})}
                                >
                                  <LinkCopyPasteComplexIcon/>
                                  {/* <Icon className={classNames(classes.fontAwesomeIcon, 'fas fa-copy')} style={{fontSize: 16}}/> */}
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Fade>
                          <Fade
                            in={
                              isLinkExpanded ?
                              true :
                              link.comments !== undefined && link.comments !== null ?
                              link.comments.length > 0 :
                              false
                            }
                          >
                            <div style={{paddingLeft: 5, display: 'flex', margin: 'auto'}}>
                              {
                                canEdit ?
                                <Tooltip title="Add New Comment"
                                         placement="bottom-start"
                                         enterDelay={500}
                                         leaveDelay={200}
                                >
                                  <IconButton aria-label="Comment"
                                              color="primary"
                                              onClick={handleUseComment({mappingId: mappingId, linkId: link.id})}
                                              style={{padding:5, top: -2}}
                                              size="small"
                                  >
                                    <Badge
                                      badgeContent={
                                        link.comments !== undefined && link.comments !== null ?
                                        link.comments.length :
                                        0
                                      }
                                      color="secondary"
                                      classes={{
                                        badge: classes.niceBadgeStyle,
                                      }}
                                    >
                                      <MessageIcon style={{fontSize: 20, height: 18, width: 18}}/>
                                    </Badge>
                                  </IconButton>
                                </Tooltip> :
                                ''
                              }
                            </div>
                          </Fade>
                        </div>
                        <div
                          style={{
                            marginLeft: link.comments !== undefined && link.comments !== null ?
                            (link.comments.length > 0 ? 14 : 'unset') :
                            'unset'
                          }}
                        >
                          {
                            this.props.focusedObjectIdUserMap.get(link.id) !== undefined ?
                            <Avatar
                              alt="user-avatar"
                              src={
                                this.props.focusedObjectIdUserMap.get(link.id).avatarImageRelativePath !== undefined ?
                                avatarRootContextPath + this.props.focusedObjectIdUserMap.get(link.id).avatarImageRelativePath :
                                ''
                              }
                              className={classes.littleFocusedAvatar}
                            /> :
                            ''
                          }
                        </div>
                      </div>
                      <div style={{padding:'unset', float: 'right'}}>
                        <IconButton
                          hidden={!isLinkExpanded || link.showCode}
                          size="small"
                          aria-label="Delete"
                          onClick={
                            this.props.handleShowConfirmDeleteSnackbar({
                              handleDelete: this.props.handleDeleteLink,
                              mappingId: mappingId,
                              linkId: link.id,
                            })
                          }
                          onMouseEnter={this.handleHoverDeleteLink({status:'mouseEnter'})}
                          onMouseLeave={this.handleHoverDeleteLink({status:'mouseLeave'})}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* --overflow: 'hidden'-- below is the trick for preventing user from clicking and collapsing the panel*/}
              <div style={{overflow: 'hidden', paddingLeft: 10}}>
                <CSSTransition in={isLinkExpanded} appear
                               timeout={800}
                               classNames="hideExpansionPanelSummaryInOutIniShown">
                  {/* *** View *** */}
                  <div>
                    <Grid container spacing={1} >
                      <CSSTransition in={leaveEverywhereMoreSpace}
                                     timeout={500}
                                     classNames="decreaseSpaceWhenConstExprIsAdded"
                                     appear={true}>
                        <Grid
                          item xs={6}
                          className={classNames(classes.headerCellLeft, 'decreaseSpaceWhenConstExprIsAdded')}
                        >
                          <div style={{display:'flex',}}>
                            <div className={classes.divIconStyleWrapper}>
                              <ArrowDownwardIcon style={{color: link.sourceRelation.error_text !== undefined ? 'red' : 'unset'}}/>
                            </div>
                            <div style={{overflow: 'hidden'}}>
                              <div style={{display:'flex'}}>
                                <b style={{minWidth: 110, color: link.sourceRelation.error_text !== undefined ? 'red' : 'unset'}}>
                                  Source Relation:
                                </b>
                                <Tooltip title={
                                          <React.Fragment>
                                            <div className={classes.twoColumnGridContainer}>
                                              <span style={{fontWeight: 'bold'}}>{'Source Relation:'}</span>
                                              <span>{link.sourceRelation.item ? link.sourceRelation.item.label : ''}</span>
                                              <span style={{fontWeight: 'bold'}}>{'Domain Source Node:'}</span>
                                              <span>{domainSourceNode ? ' ' + domainSourceNode.item.value : 'Not Defined'}</span>
                                            </div>
                                          </React.Fragment>
                                         }
                                         classes={{ tooltip: classes.noMaxWidth }}
                                         placement="top"
                                         enterDelay={1000}
                                         leaveDelay={200}>
                                   <div style={{display:'flex', padding: 5, marginTop:-4, wordBreak: 'break-all'}}>
                                     {link.sourceRelation.item ? link.sourceRelation.item.label : ''}
                                   </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div style={{display:'flex'}}>
                            <div className={classes.intermediateNodesWrapper} style={{paddingRight: 10}}>
                              {sourceRelationViewIntermediateList}
                            </div>
                          </div>

                          {/* Display Conditions - Collapsed View*/}
                          <div
                            className={classes.conditionListWrapper}
                            hidden={
                              link.conditions === undefined ?
                              true :
                              (link.conditions.length === 0 ? true : false)
                            }
                          >
                            <div
                              hidden={
                                link.conditions === undefined ? true :
                                (link.conditions.length <= 1 ? true : false)
                              }
                              className={classes.annotationOrAnd}
                            >
                              {
                                link.conditionListLogicalOperator !== undefined ?
                                link.conditionListLogicalOperator.toUpperCase() :
                                ''
                              }
                            </div>
                            <div
                              className={classes.conditionList}
                              style={{
                                borderLeft: link.conditions !== undefined ?
                                            (
                                              link.conditions.length > 1 ?
                                              'solid rgba(0, 0, 0, 0.54)' :
                                              'unset'
                                            ) :
                                            'unset',
                              }}
                            >
                              {
                                link.conditions !== undefined ?
                                link.conditions.map((condition) => {
                                  return (
                                    <div key={condition.id}>
                                      <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'bottom'}}>
                                        <Tooltip title="Condition"
                                                 placement="left"
                                                 enterDelay={500}
                                                 leaveDelay={200}>
                                          <Icon className={classNames(classes.generatorIcon, 'fas fa-file-signature')}/>
                                        </Tooltip>
                                      </div>
                                      <div style={{display: 'table-cell'}}>
                                        <div style={{display: 'flex'}}>
                                          <Tooltip title="Negation"
                                                   placement="left"
                                                   enterDelay={500}
                                                   leaveDelay={200}>
                                            <div hidden={!condition.negation} className={classes.negationCondition}>¬</div>
                                          </Tooltip>
                                          <div>
                                            <i>
                                              {condition.xpath}
                                            </i>
                                            <b style={{paddingLeft: 5}}>
                                              {condition.type}
                                            </b>
                                            <i
                                              hidden={condition.value === undefined || condition.value === ''}
                                              style={{paddingLeft: 5}}
                                            >
                                              {condition.value},
                                            </i>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  )
                                }) :
                                ''
                              }
                            </div>
                          </div>
                        </Grid>
                      </CSSTransition>
                      <CSSTransition in={leaveEverywhereMoreSpace}
                                     timeout={500}
                                     classNames="increaseSpaceWhenConstExprIsAdded"
                                     appear={true}>
                        <Grid
                          item xs={6}
                          className={classNames(classes.headerCellRight, 'increaseSpaceWhenConstExprIsAdded')}
                        >
                          <div style={{display:'flex'}}>
                            <div className={classes.divIconStyleWrapper}>
                              <ArrowDownwardIcon style={{color: link.targetRelation.error_text !== undefined ? 'red' : 'unset'}}/>
                            </div>
                            <div style={{overflow: 'hidden'}}>
                              <div style={{display:'flex'}}>
                                <b style={{minWidth: 90, color: link.targetRelation.error_text !== undefined ? 'red' : 'unset'}}>
                                  Target Relation:
                                </b>
                                <div style={{display:'flex', padding: 5, marginTop:-4}}>
                                  {link.targetRelation.item ? link.targetRelation.item.label : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classes.intermediateNodesWrapper}>
                            {targetRelationViewIntermediateList}
                          </div>
                        </Grid>
                      </CSSTransition>
                      <CSSTransition in={leaveEverywhereMoreSpace}
                                     timeout={500}
                                     classNames="decreaseSpaceWhenConstExprIsAdded"
                                     appear={true}>
                        <Grid item xs={6} className={classNames(classes.headerCellLeft, 'decreaseSpaceWhenConstExprIsAdded')}>
                          <div style={{display:'flex',}}>
                            <div className={classes.divIconStyleWrapper}>
                              <div style={{
                                      borderColor: link.sourceNode.error_text !== undefined ? 'red' : 'unset',
                                   }}
                                   className={classes.divOutlinedIconStyle}/>
                            </div>
                            <div style={{overflow: 'hidden'}}>
                              <div style={{display:'flex'}}>
                                <b style={{minWidth: 90, color: link.sourceNode.error_text !== undefined ? 'red' : 'unset'}}>
                                  Source Node:
                                </b>
                                <Tooltip
                                  title={
                                    <React.Fragment>
                                      <div className={classes.twoColumnGridContainer}>
                                        <span style={{fontWeight: 'bold'}}>{'Source Node:'}</span>
                                        <span>{link.sourceNode.item ? link.sourceNode.item.label : ''}</span>
                                        <span style={{fontWeight: 'bold'}}>{'Domain Source Node:'}</span>
                                        <span>{domainSourceNode ? ' ' + domainSourceNode.item.value : 'Not Defined'}</span>
                                      </div>
                                    </React.Fragment>
                                  }
                                  classes={{ tooltip: classes.noMaxWidth }}
                                  placement="top"
                                  enterDelay={1000}
                                  leaveDelay={200}
                                >
                                   <div style={{display:'flex', padding: 5, marginTop:-4, wordBreak: 'break-all'}}>
                                     {link.sourceNode.item ? link.sourceNode.item.label : ''}
                                   </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>

                          {/* Display Conditions TargetNode- Collapsed View*/}
                          <div
                            className={classes.conditionListWrapper}
                            hidden={
                              link.conditionsTargetNode === undefined ?
                              true :
                              (link.conditionsTargetNode.length === 0 ? true : false)
                            }
                          >
                            <div
                              hidden={
                                link.conditionsTargetNode === undefined ?
                                true :
                                (link.conditionsTargetNode.length <= 1 ? true : false)
                              }
                              className={classes.annotationOrAnd}
                            >
                              {
                                link.conditionListLogicalOperatorTargetNode !== undefined ?
                                link.conditionListLogicalOperatorTargetNode.toUpperCase() :
                                ''
                              }
                            </div>
                            <div
                              className={classes.conditionList}
                              style={{
                                borderLeft: link.conditionsTargetNode !== undefined ?
                                            (
                                              link.conditionsTargetNode.length > 1 ?
                                              'solid rgba(0, 0, 0, 0.54)' :
                                              'unset'
                                            ) :
                                            'unset',
                              }}
                            >
                              {
                                link.conditionsTargetNode !== undefined ?
                                link.conditionsTargetNode.map((condition) => {
                                  return (
                                    <div key={condition.id}>
                                      <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'bottom'}}>
                                        <Tooltip title="Condition"
                                                 placement="left"
                                                 enterDelay={500}
                                                 leaveDelay={200}>
                                          <Icon className={classNames(classes.generatorIcon, 'fas fa-file-signature')}/>
                                        </Tooltip>
                                      </div>
                                      <div style={{display: 'table-cell'}}>
                                        <div style={{display: 'flex'}}>
                                          <Tooltip title="Negation"
                                                   placement="left"
                                                   enterDelay={500}
                                                   leaveDelay={200}>
                                            <div hidden={!condition.negation} className={classes.negationCondition}>¬</div>
                                          </Tooltip>
                                          <div>{condition.type}</div>
                                        </div>
                                        <div style={{fontStyle: 'italic', display: 'flex'}}>
                                          <span hidden={condition.value === undefined || condition.value === ''}
                                                style={{paddingRight: 5}}>
                                            Value: {condition.value},
                                          </span>
                                          <span>xPath: {condition.xpath}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }) :
                                ''
                              }
                            </div>
                          </div>
                        </Grid>
                      </CSSTransition>
                      <CSSTransition in={leaveEverywhereMoreSpace}
                                     timeout={500}
                                     classNames="increaseSpaceWhenConstExprIsAdded"
                                     appear={true}>
                        <Grid item xs={6} className={classNames(classes.headerCellLeft, 'increaseSpaceWhenConstExprIsAdded')}>
                          <div style={{display:'flex'}}>
                            <div style={{display:'flex'}}>
                              <div className={classes.divIconStyleWrapper}>
                                <div style={{
                                        borderColor: link.targetEntity.error_text !== undefined ? 'red' : 'unset',
                                     }}
                                     className={classes.divOutlinedIconStyle}/>
                              </div>
                              <div style={{overflow: 'hidden'}}>
                                <div style={{display:'flex', minWidth: 300}}>
                                  <b style={{minWidth: 90, color: link.targetEntity.error_text !== undefined ? 'red' : 'unset'}}>
                                    Target Entity:
                                  </b>
                                  <div style={{display:'flex', flexWrap: 'wrap', marginTop:-4}}>
                                    {
                                      link.targetEntity.item !== '' ?
                                      link.targetEntity.item.map((itemObj, index) => (
                                        // Without the `key`, React will fire a key warning
                                        <React.Fragment key={'mapping_domain_' + linkIndex + '_' + index}>
                                          <div style={{padding:5}}>{itemObj.label}{link.targetEntity.item.length-1 !== index ? ',' : ''}</div>
                                        </React.Fragment>
                                      )) :
                                      ''
                                    }
                                  </div>
                                </div>

                                {/* Variable / Generator Declaration Instance/Label*/}
                                <div>
                                  {/*Display the Variable*/}
                                  <Collapse
                                    in={
                                      compactViewEnabled ?
                                      !(
                                        compactViewMode !== undefined ?
                                        (
                                          compactViewMode.hideVariable !== undefined ?
                                          compactViewMode.hideVariable :
                                          false
                                        ) :
                                        false
                                      ) :
                                      true
                                    }
                                  >
                                    <div style={{display: 'flex'}}>
                                      <div style={{display: 'table-row'}}
                                           hidden={
                                             link.targetEntity.variable === undefined ?
                                             true :
                                             (
                                               link.targetEntity.variable.value === '' ?
                                               true :
                                               false
                                             )
                                           }>
                                        <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                                          <Tooltip title="Variable"
                                                   placement="left"
                                                   enterDelay={500}
                                                   leaveDelay={200}>
                                            <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                                              <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                                            </div>
                                          </Tooltip>
                                        </div>
                                        <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}
                                             hidden={
                                               link.targetEntity.variable !== undefined ?
                                               !link.targetEntity.variable.global :
                                               true
                                             }
                                        >
                                          <Tooltip title="Global"
                                                   placement="left"
                                                   enterDelay={500}
                                                   leaveDelay={200}>
                                            <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                                          </Tooltip>
                                        </div>

                                        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                          <div className={classes.declarationLabelWrapper}>
                                            {
                                              link.targetEntity.variable !== undefined ?
                                              link.targetEntity.variable.value :
                                              ''
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Collapse>
                                  {/* Display the instance Info */}
                                  <Collapse
                                    in={
                                      compactViewEnabled ?
                                      !(
                                        compactViewMode !== undefined ?
                                        (
                                          compactViewMode.hideInstanceInfo !== undefined ?
                                          compactViewMode.hideInstanceInfo :
                                          false
                                        ) :
                                        false
                                      ) :
                                      true
                                    }
                                  >
                                    <div style={{display: 'flex'}}>
                                      <div style={{display: 'table-row'}}
                                           hidden={
                                             link.targetEntity.instanceInfo === undefined ?
                                             true :
                                             (
                                               link.targetEntity.instanceInfo.constant === '' &&
                                               link.targetEntity.instanceInfo.description === '' &&
                                               link.targetEntity.instanceInfo.language === '' ?
                                               true :
                                               false
                                             )
                                           }>
                                        <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                                          <Tooltip title="Instance Info"
                                                   placement="left"
                                                   enterDelay={500}
                                                   leaveDelay={200}>
                                            <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                                              <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                                            </div>
                                          </Tooltip>
                                        </div>
                                        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                          {
                                            link.targetEntity.instanceInfo !== undefined ?
                                            (
                                              link.targetEntity.instanceInfo.constant !== undefined &&
                                              link.targetEntity.instanceInfo.constant !== '' ?
                                              <div
                                                className={classes.declarationLabelWrapper}
                                                style={{
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                  maxWidth: 148,
                                                }}
                                              >
                                                <Tooltip
                                                  title={link.targetEntity.instanceInfo.constant}
                                                  placement="bottom-start"
                                                  enterDelay={800}
                                                  leaveDelay={200}
                                                >
                                                  <span>
                                                    {
                                                      link.targetEntity.instanceInfo !== undefined ?
                                                      'Constant: ' + link.targetEntity.instanceInfo.constant :
                                                      ''
                                                    }
                                                  </span>
                                                </Tooltip>
                                              </div> :
                                              ''
                                            ) :
                                            ''
                                          }
                                          {
                                            link.targetEntity.instanceInfo !== undefined ?
                                            (
                                              link.targetEntity.instanceInfo.description !== undefined &&
                                              link.targetEntity.instanceInfo.description !== '' ?
                                              <div
                                                className={classes.declarationLabelWrapper}
                                                style={{
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                  maxWidth: 148,
                                                }}
                                              >
                                                <Tooltip
                                                  title={link.targetEntity.instanceInfo.description}
                                                  placement="bottom-start"
                                                  enterDelay={800}
                                                  leaveDelay={200}
                                                >
                                                  <span>
                                                    {
                                                      link.targetEntity.instanceInfo !== undefined ?
                                                      'Descriptin: ' + link.targetEntity.instanceInfo.description :
                                                      ''
                                                    }
                                                  </span>
                                                </Tooltip>
                                              </div> :
                                              ''
                                            ) :
                                            ''
                                          }
                                          <div
                                            style={{
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                              maxWidth: 148,
                                            }}
                                            hidden={
                                              link.targetEntity.instanceInfo !== undefined ?
                                              (
                                                link.targetEntity.instanceInfo.language !== undefined ?
                                                link.targetEntity.instanceInfo.language.value === '' :
                                                true
                                              ) :
                                              true
                                            }
                                            className={classes.declarationLabelWrapper}
                                          >
                                            {
                                              link.targetEntity.instanceInfo !== undefined ?
                                              (
                                                link.targetEntity.instanceInfo.language !== undefined ?
                                                'Language: ' +
                                                (
                                                  link.targetEntity.instanceInfo.language.label !== link.targetEntity.instanceInfo.language.value ?
                                                  link.targetEntity.instanceInfo.language.label + ' (' + link.targetEntity.instanceInfo.language.value + ')' :
                                                  link.targetEntity.instanceInfo.language.value
                                                ) :
                                                ''
                                              )
                                               :
                                              ''
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Collapse>

                                  {/* Display Instance Generator Declaration */}
                                  <Collapse
                                    in={
                                      compactViewEnabled ?
                                      !(
                                        compactViewMode !== undefined ?
                                        (
                                          compactViewMode.hideInstanceGenerator !== undefined ?
                                          compactViewMode.hideInstanceGenerator :
                                          true
                                        ) :
                                        true
                                      ) :
                                      true
                                    }
                                  >
                                    <div className={instanceGeneratorDeclarationErrorStyle}>
                                      <div style={{display: 'flex', width: 'fit-content'}}>
                                        <div hidden={link.targetEntity.instanceGeneratorDeclaration === undefined}
                                             className={classes.generatorDeclarationIconWrapperStyle}>
                                          <Tooltip
                                            title={
                                              <React.Fragment>
                                                <GeneratorInfo
                                                  title="Instance Generator"
                                                  patternTypeColors={patternTypeColors}
                                                  />
                                              </React.Fragment>
                                            }
                                            placement="left"
                                            enterDelay={500}
                                            leaveDelay={200}
                                          >
                                            <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                                          </Tooltip>
                                        </div>
                                        <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                                          <div className={classes.declarationLabelWrapper}>
                                            {
                                              link.targetEntity.instanceGeneratorDeclaration !== undefined ?
                                              link.targetEntity.instanceGeneratorDeclaration.name :
                                              ''
                                            }
                                          </div>
                                          <div className={classes.patternWithValuesAndColorsWrapper}>
                                            {
                                            link.targetEntity.instanceGeneratorDeclaration !== undefined ?
                                            <PatternWithValuesAndColors
                                              declaration={link.targetEntity.instanceGeneratorDeclaration}
                                              patternTypeColors={ patternTypeColors}
                                            /> :
                                            ''
                                            }
                                          </div>
                                        </div>
                                        <GeneratorDeclarationError
                                          generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                                          errorMessage={
                                            instanceGeneratorDeclarationErrorMsg !== '' ?
                                             instanceGeneratorDeclarationErrorMsg :
                                             (
                                               instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                                               instanceGeneratorDeclarationArgsErrorMsg :
                                               ''
                                             )
                                           }
                                        />
                                      </div>
                                    </div>
                                  </Collapse>

                                  {/* Display label Generator Declarations */}
                                  <Collapse
                                    in={
                                      compactViewEnabled ?
                                      !(
                                        compactViewMode !== undefined ?
                                        (
                                          compactViewMode.hideLabelGenerator !== undefined ?
                                          compactViewMode.hideLabelGenerator :
                                          true
                                        ) :
                                        true
                                      ) :
                                      true
                                    }
                                  >
                                    <div hidden={link.targetEntity.labelGeneratorDeclarations === undefined ? true :
                                           (link.targetEntity.labelGeneratorDeclarations.length === 0 ? true : false)}
                                    >
                                      <div style={{display: 'table-row'}}>
                                        {
                                          link.targetEntity.labelGeneratorDeclarations !== undefined ?
                                          link.targetEntity.labelGeneratorDeclarations.map((labelDeclaration) => {
                                            const labelGeneratorDeclarationErrorMsg =
                                              labelDeclaration.definitionId !== undefined ?
                                              (
                                                labelDeclaration.definitionId === null ?
                                                'Missing Generator Definition' :
                                                ''
                                              ) :
                                              '';
                                            const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                                              obj => (
                                                obj.name.toLowerCase() !== 'language' ?
                                                obj.error_type_text !== '' :
                                                false
                                              )
                                            );
                                            const labelGeneratorDeclarationArgsErrorMsg =
                                              lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                                              labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                                              labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                                              '';

                                            const labelGeneratorDeclarationErrorStyle =
                                              labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                              classes.generatorDeclarationError :
                                              '';
                                            return (
                                              <div
                                                key={labelDeclaration.id}
                                                className={labelGeneratorDeclarationErrorStyle}
                                                style={{display: 'flex', width: 'fit-content'}}
                                              >
                                                <div className={classes.generatorDeclarationIconWrapperStyle}>
                                                  <Tooltip
                                                    title={
                                                      <React.Fragment>
                                                        <GeneratorInfo
                                                          title="Label Generator"
                                                          patternTypeColors={patternTypeColors}
                                                          />
                                                      </React.Fragment>
                                                    }
                                                    placement="left"
                                                    enterDelay={500}
                                                    leaveDelay={200}
                                                  >
                                                    <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                                                  </Tooltip>
                                                </div>
                                                <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                                                  <div className={classes.declarationLabelWrapper}>
                                                    {labelDeclaration.name}
                                                  </div>
                                                  <div className={classes.patternWithValuesAndColorsWrapper}>
                                                    {
                                                    <PatternWithValuesAndColors declaration={labelDeclaration}
                                                                                patternTypeColors={ patternTypeColors}/>
                                                    }
                                                  </div>
                                                </div>
                                                <GeneratorDeclarationError
                                                  generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                                                  errorMessage={
                                                    labelGeneratorDeclarationErrorMsg !== '' ?
                                                     labelGeneratorDeclarationErrorMsg :
                                                     (
                                                       labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                       labelGeneratorDeclarationArgsErrorMsg :
                                                       ''
                                                     )
                                                   }
                                                />
                                              </div>
                                            )
                                          }) :
                                          ''
                                        }
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                            <div
                              className={classes.constExprNodesWrapper}
                              style={{marginLeft: 10, marginBottom: 5}}
                              hidden={linkViewConstExprList.length <= 0}
                            >
                              {linkViewConstExprList}
                            </div>
                          </div>
                        </Grid>
                      </CSSTransition>
                    </Grid>
                  </div>
                </CSSTransition>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            onFocus={accordionFocus({id: link.id})}
            onBlur={accordionBlur({id: link.id})}
          >
            <Collapse in={!link.showCode} mountOnEnter unmountOnExit
                   timeout={{ enter: 400, exit: 400 }} style={{width: '100%'}}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.headerCellLeft}
                      style={{transition: 'all 400ms ease-in-out', maxWidth: sourceWidth, flexBasis: sourceWidth}}>
                  <Card className={classes.card}>
                    <CardContent>
                      <div style={{display:'flex',}}>
                        <div className={classes.divIconStyleWrapper}>
                          <ArrowDownwardIcon />
                        </div>
                          <FormControl className={classes.autocompleteFormControl} error={link.sourceRelation.error_text !== undefined}>
                              <Autocomplete
                                options={sourceRelationOptions}
                                getOptionLabel={(option) => {
                                  // Add "xxx" option created dynamically
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  // Regular option
                                  return option.label !== undefined ? option.label : '';
                                }}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                                  </React.Fragment>
                                )}
                                getOptionSelected={
                                  (option, value) => {
                                    return ((value !== '' || value !== undefined) ? option.value === value.value : false)
                                  }
                                }
                                onChange={handleInputChange({name: 'sourceRelation', mappingId: mappingId, linkId: link.id})}
                                value={link.sourceRelation.item !== undefined && link.sourceRelation.item !== '' ? link.sourceRelation.item : null}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  // Suggest the creation of a new value
                                  if (params.inputValue !== '') {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      label: `Add "${params.inputValue}"`,
                                    });
                                  }
                                  return filtered;
                                }}
                                filterSelectedOptions={false /* if true it will remove selected from the option list */}
                                loading={sourceRelationIsLoading}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Source Relation"
                                    placeholder="Please select source relation"
                                    FormHelperTextProps={{component: 'div'}}
                                    helperText={
                                      <div style={{color: 'red'}}>
                                        <span style={{paddingRight: 10}}>
                                          {link.sourceRelation.error_text !== undefined ? link.sourceRelation.error_text : ''}
                                        </span>
                                        {
                                          link.sourceRelation.item !== '' ?
                                          <Button
                                            color="secondary"
                                            hidden={link.sourceRelation.error_text === undefined}
                                            size="small"
                                            className={classes.errorLabelButton}
                                            onClick={
                                              handleInputChangeButton({
                                                name: 'sourceRelation',
                                                mappingId: mappingId,
                                                linkId: link.id,
                                                item: link.sourceRelation.item,
                                              })
                                            }
                                          >
                                            Make Custom
                                          </Button> :
                                          ''
                                        }
                                      </div>
                                    }
                                    onFocus={(inputValue) => this.handleRetrieveXpathOptions({name: 'sourceRelation'})}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {sourceRelationIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                          </FormControl>
                      </div>
                      <div className={classes.intermediateNodesWrapper}>
                        <TransitionGroup>
                          {sourceRelationEditIntermediateList}
                        </TransitionGroup>
                      </div>
                      <div>
                        <Button color="primary" className={classes.button}
                                onClick={handleAddIntermediate({type: 'sourceIntermediate', mappingId: mappingId, linkId: link.id})}>
                          Add Intermediate
                        </Button>
                        <Button color="secondary" className={classes.button} disabled>
                          Set Blank Source Relation
                        </Button>
                      </div>
                      {/* Display Conditions - Expanded View*/}
                      <div>
                        <div
                          className={classes.conditionListWrapper}
                          hidden={
                            link.conditions === undefined ?
                            true :
                            (link.conditions.length === 0 ? true : false)
                          }>

                          <div
                            hidden={
                              link.conditions === undefined ?
                              true :
                              (link.conditions.length <= 1 ? true : false)
                            }
                            className={classes.annotationOrAnd}
                          >
                            {
                              link.conditionListLogicalOperator !== undefined ?
                              link.conditionListLogicalOperator.toUpperCase() :
                              ''
                            }
                          </div>

                          <div
                            className={classes.conditionList}
                            style={{
                              borderLeft: link.conditions !== undefined ?
                                          (
                                            link.conditions.length > 1 ?
                                            'solid rgba(0, 0, 0, 0.54)' :
                                            'unset'
                                          ) :
                                          'unset',
                            }}
                          >
                            {
                              link.conditions !== undefined ?
                              link.conditions.map((condition) => {
                                return (
                                  <div key={condition.id}>
                                    <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'bottom'}}>
                                      <Tooltip
                                        title="Condition"
                                        placement="left"
                                        enterDelay={500}
                                        leaveDelay={200}
                                      >
                                        <Icon className={classNames(classes.generatorIcon, 'fas fa-file-signature')}/>
                                      </Tooltip>
                                    </div>
                                    <div style={{display: 'table-cell'}}>
                                      <div style={{display: 'flex'}}>
                                        <React.Fragment>
                                          <Chip
                                            classes={{
                                              root: classes.generatorDeclarationValidChip,
                                              deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                            }}
                                            style={{height: '100%', borderRadius: 8, paddingTop: 3, paddingBottom: 3}}
                                            size="small"
                                            variant="outlined"
                                            label={
                                              <div style={{wordBreak: 'break-all', whiteSpace: 'normal', display: 'flex'}}>
                                                <Tooltip
                                                  title="Negation"
                                                  placement="left"
                                                  enterDelay={500}
                                                  leaveDelay={200}
                                                >
                                                  <div
                                                    hidden={!condition.negation}
                                                    className={
                                                      classNames(
                                                        classes.negationCondition,
                                                        classes.negationConditionEdit
                                                      )
                                                    }
                                                    style={{marginRight: 5}}
                                                  >¬</div>
                                                </Tooltip>
                                                <div>
                                                  <i>
                                                    {condition.xpath}
                                                  </i>
                                                  <b style={{paddingLeft: 5}}>
                                                    {condition.type}
                                                  </b>
                                                  <i
                                                    hidden={condition.value === undefined || condition.value === ''}
                                                    style={{paddingLeft: 5}}
                                                  >
                                                    {condition.value},
                                                  </i>
                                                </div>
                                              </div>
                                            }
                                            onClick={
                                              handleGoToConditionForm({
                                                condition: condition,
                                                type: 'link',
                                                conditionProps: {mappingId: mappingId, linkId: link.id}
                                              })
                                            }
                                            color="primary"
                                            onDelete={
                                              this.props.handleShowConfirmDeleteSnackbar({
                                                handleDelete: handleDirectDeleteCondition,
                                                condition: condition,
                                                conditionProps: {mappingId: mappingId, linkId: link.id}
                                              })
                                            }
                                          />
                                        </React.Fragment>
                                      </div>

                                    </div>
                                  </div>
                                )
                              }) :
                              ''
                            }
                          </div>
                        </div>
                        <div style={{float:'right'}}>
                          <Button color="primary"
                                  className={classes.button}
                                  onClick={handleUseCondition({mappingId: mappingId, linkId: link.id})}>
                            Condition
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} className={classes.headerCellRight}
                      style={{transition: 'all 400ms ease-in-out', maxWidth: targetWidth, flexBasis: targetWidth}}>
                  <Card className={classes.card}>
                    <CardContent>
                      <div style={{display:'flex',}}>
                        <div className={classes.divIconStyleWrapper}>
                          <ArrowDownwardIcon />
                        </div>
                        <div style={{width:'100%'}}>
                          <FormControl className={classes.autocompleteFormControl} error={link.targetRelation.error_text !== undefined}>
                            <Autocomplete
                              options={[].concat.apply([], targetRelationOptions.map(item => item.options.map(optItem => ({ ...optItem, group: item.label }))))}
                              groupBy={(option) => option.group}
                              getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
                              renderOption={(option) => (
                                <React.Fragment>
                                  <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                                </React.Fragment>
                              )}
                              getOptionSelected={
                                (option, value) => {
                                  return ((value !== '' || value !== undefined) ? option.value === value.value : false)
                                }
                              }
                              onChange={handleInputChange({name: 'targetRelation', mappingId: mappingId, linkId: link.id})}
                              value={link.targetRelation.item !== undefined && link.targetRelation.item !== '' ? link.targetRelation.item : null}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                //const filtered = options.filter(option => option.label.includes(params.inputValue));
                                // Suggest the creation of a new value
                                if (params.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    label: `Add "${params.inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                              filterSelectedOptions={false /* if true it will remove selected from the option list */}
                              loading={targetRelationIsLoading}
                              onOpen={this.handleRetrieveOptions({name: 'targetRelation'})}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Target Relation"
                                  placeholder="Please select target relation"
                                  FormHelperTextProps={{component: 'div'}}
                                  helperText={
                                    <div style={{color: 'red'}}>
                                      <span style={{paddingRight: 10}}>
                                        {link.targetRelation.error_text !== undefined ? link.targetRelation.error_text : ''}
                                      </span>
                                      {
                                        link.targetRelation.item !== '' ?
                                        <Button
                                          color="secondary"
                                          hidden={link.targetRelation.error_text === undefined}
                                          size="small"
                                          className={classes.errorLabelButton}
                                          onClick={
                                            handleInputChangeButton({
                                              name: 'targetRelation',
                                              mappingId: mappingId,
                                              linkId: link.id,
                                              item: link.targetRelation.item,
                                            })
                                          }
                                        >
                                          Make Custom
                                        </Button> :
                                        ''
                                      }
                                    </div>
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {targetRelationIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className={classes.intermediateNodesWrapper}>
                        <TransitionGroup>
                          {targetRelationEditIntermediateList}
                        </TransitionGroup>
                      </div>
                      <div style={{float:'right'}}>
                        <Button color="primary"
                                className={classes.button}
                                onClick={handleAddIntermediate({type: 'targetIntermediate', mappingId: mappingId, linkId: link.id})}>
                          Add Intermediate
                        </Button>
                        <Button color="secondary" className={classes.button} disabled>
                          Set Blank Source Relation
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} className={classes.headerCellLeft}
                      style={{transition: 'all 400ms ease-in-out', maxWidth: sourceWidth, flexBasis: sourceWidth}}>
                  <Card className={classes.card}>
                    <CardContent>
                      <div style={{display:'flex',}}>
                        <div className={classes.divIconStyleWrapper}>
                          <div className={classes.divOutlinedIconStyle}/>
                        </div>
                        <FormControl className={classes.autocompleteFormControl} error={link.sourceNode.error_text !== undefined}>
                          <Autocomplete
                            options={sourceNodeOptions}
                            getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
                            renderOption={(option) => (
                              <React.Fragment>
                                <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                              </React.Fragment>
                            )}
                            getOptionSelected={(option, value) => option.value === value.value}
                            onChange={handleInputChange({name: 'sourceNode', mappingId: mappingId, linkId: link.id})}
                            value={link.sourceNode.item !== undefined && link.sourceNode.item !== '' ? link.sourceNode.item : null}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              // Suggest the creation of a new value
                              if (params.inputValue !== '') {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  label: `Add "${params.inputValue}"`,
                                });
                              }
                              return filtered;
                            }}
                            filterSelectedOptions={false /* if true it will remove selected from the option list */}
                            loading={sourceNodeIsLoading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Source Node"
                                placeholder="Please select source node"
                                FormHelperTextProps={{component: 'div'}}
                                helperText={
                                  <div style={{color: 'red'}}>
                                    <span style={{paddingRight: 10}}>
                                      {link.sourceNode.error_text !== undefined ? link.sourceNode.error_text : ''}
                                    </span>
                                    {
                                      link.sourceNode.item !== '' ?
                                      <Button
                                        color="secondary"
                                        hidden={link.sourceNode.error_text === undefined}
                                        size="small"
                                        className={classes.errorLabelButton}
                                        onClick={
                                          handleInputChangeButton({
                                            name: 'sourceNode',
                                            mappingId: mappingId,
                                            linkId: link.id,
                                            item: link.sourceNode.item,
                                          })
                                        }
                                      >
                                        Make Custom
                                      </Button> :
                                      ''
                                    }
                                  </div>
                                }
                                onFocus={(inputValue) => this.handleRetrieveXpathOptions({name: 'sourceNode'})}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {sourceNodeIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </div>







                      {/* Display Conditions - Expanded View*/}
                      <div>
                        <div className={classes.conditionListWrapper}
                             hidden={link.conditionsTargetNode === undefined ? true :
                               (link.conditionsTargetNode.length === 0 ? true : false)}>

                          <div hidden={
                                  link.conditionsTargetNode === undefined ? true :
                                  (link.conditionsTargetNode.length <= 1 ? true : false)
                                }
                                className={classes.annotationOrAnd}
                           >
                            {
                              link.conditionListLogicalOperatorTargetNode !== undefined ?
                              link.conditionListLogicalOperatorTargetNode.toUpperCase() :
                              ''
                            }
                          </div>

                          <div className={classes.conditionList}
                               style={{
                                 borderLeft: link.conditionsTargetNode !== undefined ?
                                             (link.conditionsTargetNode.length > 1 ?
                                              'solid rgba(0, 0, 0, 0.54)' :
                                              'unset') :
                                              'unset',}}
                          >
                            {
                              link.conditionsTargetNode !== undefined ?
                              link.conditionsTargetNode.map((condition) => {
                                return (
                                  <div key={condition.id}>
                                    <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'bottom'}}>
                                      <Tooltip title="Condition"
                                               placement="left"
                                               enterDelay={500}
                                               leaveDelay={200}>
                                        <Icon className={classNames(classes.generatorIcon, 'fas fa-file-signature')}/>
                                      </Tooltip>
                                    </div>
                                    <div style={{display: 'table-cell'}}>
                                      <div style={{display: 'flex'}}>
                                        <Tooltip title="Negation"
                                                 placement="left"
                                                 enterDelay={500}
                                                 leaveDelay={200}>
                                          <div
                                            hidden={!condition.negation}
                                            className={
                                              classNames(
                                                classes.negationCondition,
                                                classes.negationConditionEdit
                                              )
                                            }>¬</div>
                                        </Tooltip>
                                        <React.Fragment>
                                          <Chip
                                            classes={{
                                              root: classes.generatorDeclarationValidChip,
                                              deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                            }}
                                            size="small"
                                            variant="outlined"
                                            label={condition.type}
                                            onClick={
                                              handleGoToConditionForm({
                                                condition: condition,
                                                type: 'link',
                                                conditionProps: {mappingId: mappingId, linkId: link.id, type: 'targetNode'}
                                              })
                                            }
                                            color="primary"
                                            onDelete={
                                              this.props.handleShowConfirmDeleteSnackbar({
                                                handleDelete: handleDirectDeleteCondition,
                                                condition: condition,
                                                conditionProps: {mappingId: mappingId, linkId: link.id, type: 'targetNode'}
                                              })
                                            }
                                          />
                                        </React.Fragment>
                                      </div>
                                      <div className={classes.patternWithValuesAndColorsWrapper} style={{display: 'flex'}}>
                                        <span hidden={condition.value === undefined || condition.value === ''}
                                              style={{paddingRight: 5}}>
                                          Value: {condition.value},
                                        </span>
                                        <span>xPath: {condition.xpath}</span>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }) :
                              ''
                            }
                          </div>
                        </div>
                        <div style={{float:'right'}}>
                          <Button color="primary"
                                  className={classes.button}
                                  onClick={handleUseCondition({mappingId: mappingId, linkId: link.id, type: 'targetNode'})}>
                            Condition
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} className={classes.headerCellRight}
                      style={{transition: 'all 400ms ease-in-out', maxWidth: targetWidth, flexBasis: targetWidth}}>
                  <Card className={classes.card}>
                    <CardContent>
                      <div style={{display: 'flex'}}>
                        <div style={{width:'100%'}}>
                          <div style={{display:'flex',}}>
                            <div className={classes.divIconStyleWrapper}>
                              <div className={classes.divOutlinedIconStyle}/>
                            </div>
                            <div style={{width:'100%'}}>
                              <FormControl className={classes.autocompleteFormControl} error={link.targetEntity.error_text !== undefined}>
                                <Autocomplete
                                  options={[].concat.apply([], targetEntityOptions.map(item => item.options.map(optItem => ({ ...optItem, group: item.label }))))}
                                  groupBy={(option) => option.group}
                                  getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                                    </React.Fragment>
                                  )}
                                  getOptionSelected={(option, value) => option.value === value.value}
                                  onChange={handleInputChange({name: 'targetEntity', mappingId: mappingId, linkId: link.id})}
                                  value={link.targetEntity.item !== '' ? link.targetEntity.item : []}
                                  multiple
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                      filtered.push({
                                        inputValue: params.inputValue,
                                        label: `Add "${params.inputValue}"`,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  loading={targetEntityIsLoading}
                                  onOpen={this.handleRetrieveOptions({name: 'targetEntity'})}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Target Entity"
                                      placeholder="Please select target entity"
                                      FormHelperTextProps={{component: 'div'}}
                                      helperText={
                                        <div style={{color: 'red'}}>
                                          <span style={{paddingRight: 10}}>
                                            {link.targetEntity.error_text !== undefined ? link.targetEntity.error_text : ''}
                                          </span>
                                          {
                                            link.targetEntity.item !== '' ?
                                            <Button
                                              color="secondary"
                                              hidden={link.targetEntity.error_text === undefined}
                                              size="small"
                                              style={{fontSize: 12, lineHeight: 'unset', color: '#2196f3'}}
                                              onClick={
                                                handleInputChangeButton({
                                                  name: 'targetEntity',
                                                  mappingId: mappingId,
                                                  linkId: link.id,
                                                  item: link.targetEntity.item,
                                                })
                                              }
                                            >
                                              Make Custom
                                            </Button> :
                                            ''
                                          }
                                        </div>
                                      }
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {targetEntityIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>
                          </div>

                          {/* Generator - Expanded panel */}
                          <div style={{marginLeft: 44, paddingTop: 10}}>

                            {/* Variable / Generator Declaration Instance/Label*/}
                            <div>

                              {/*Display the Variable*/}
                              <Collapse
                                in={
                                  compactViewEnabled ?
                                  !(
                                    compactViewMode !== undefined ?
                                    (
                                      compactViewMode.hideVariable !== undefined ?
                                      compactViewMode.hideVariable :
                                      false
                                    ) :
                                    false
                                  ) :
                                  true
                                }
                              >
                                <div style={{display: 'flex'}}>
                                  <div style={{display: 'table-row'}}
                                       hidden={
                                         link.targetEntity.variable === undefined ?
                                         true :
                                         (
                                           link.targetEntity.variable.value === '' ?
                                           true :
                                           false
                                         )
                                       }>
                                    <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'middle'}}>
                                      <Tooltip title="Variable"
                                               placement="left"
                                               enterDelay={500}
                                               leaveDelay={200}>
                                        <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                                          <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                                        </div>
                                      </Tooltip>
                                    </div>
                                    <div className={classes.generatorDeclarationIconWrapperStyle}
                                         hidden={
                                           link.targetEntity.variable !== undefined ?
                                           !link.targetEntity.variable.global :
                                           true
                                         }
                                    >
                                      <Tooltip title="Global"
                                               placement="left"
                                               enterDelay={500}
                                               leaveDelay={200}>
                                        <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                                      </Tooltip>
                                    </div>

                                    <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                      <div>
                                        {
                                          link.targetEntity.variable !== undefined ?
                                          <React.Fragment>
                                            <Chip
                                              classes={{
                                                root: classes.generatorDeclarationValidChip,
                                                deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                              }}
                                              size="small"
                                              variant="outlined"
                                              label={link.targetEntity.variable.value}
                                              onClick={
                                                handleUseVariable({
                                                  mappingId: mappingId,
                                                  linkId: link.id,
                                                })
                                              }
                                              color="primary"
                                              onDelete={
                                                this.props.handleShowConfirmDeleteSnackbar({
                                                  handleDelete: handleDirectDeleteVariable,
                                                  argument: {
                                                    variableProps: {
                                                      mappingId: mappingId,
                                                      linkId: link.id,
                                                    },
                                                  }
                                                })
                                              }
                                            />
                                          </React.Fragment> :
                                          ''
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>

                              {/* Display the instance Info */}
                              <Collapse
                                in={
                                  compactViewEnabled ?
                                  !(
                                    compactViewMode !== undefined ?
                                    (
                                      compactViewMode.hideInstanceInfo !== undefined ?
                                      compactViewMode.hideInstanceInfo :
                                      false
                                    ) :
                                    false
                                  ) :
                                  true
                                }
                              >
                                <div style={{display: 'flex'}}>
                                  <div style={{display: 'table-row'}}
                                       hidden={
                                         link.targetEntity.instanceInfo === undefined ?
                                         true :
                                         (
                                           link.targetEntity.instanceInfo.description === '' && link.targetEntity.instanceInfo.language === '' ?
                                           true :
                                           false
                                         )
                                       }>
                                    <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'middle'}}>
                                      <Tooltip title="Instance Info"
                                               placement="left"
                                               enterDelay={500}
                                               leaveDelay={200}>
                                        <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                                          <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                                        </div>
                                      </Tooltip>
                                    </div>

                                    <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                      <div>
                                        {
                                          link.targetEntity.instanceInfo !== undefined ?
                                          <React.Fragment>
                                            <Chip
                                              classes={{
                                                root: classes.generatorDeclarationValidChip,
                                                deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                                sizeSmall:  classes[instanceInfoStyleBasedOnLinesRequired(link.targetEntity.instanceInfo)],
                                              }}
                                              size="small"
                                              variant="outlined"
                                              label={
                                                <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                                  {
                                                    link.targetEntity.instanceInfo.constant !== undefined &&
                                                    link.targetEntity.instanceInfo.constant !== '' ?
                                                    <div
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        maxWidth: 148,
                                                      }}
                                                    >
                                                      {
                                                        link.targetEntity.instanceInfo !== undefined ?
                                                        'Constant: ' + link.targetEntity.instanceInfo.constant :
                                                        ''
                                                      }
                                                    </div> :
                                                    ''
                                                  }
                                                  {
                                                    link.targetEntity.instanceInfo.description !== undefined &&
                                                    link.targetEntity.instanceInfo.description !== '' ?
                                                    <div
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        maxWidth: 148,
                                                      }}
                                                    >
                                                      {
                                                        link.targetEntity.instanceInfo !== undefined ?
                                                        'Description: ' + link.targetEntity.instanceInfo.description :
                                                        ''
                                                      }
                                                    </div> :
                                                    ''
                                                  }
                                                  {
                                                    link.targetEntity.instanceInfo.language !== undefined ?
                                                    (
                                                      link.targetEntity.instanceInfo.language.value !== '' ?
                                                      <div>
                                                        {
                                                          link.targetEntity.instanceInfo !== undefined ?
                                                          (
                                                            link.targetEntity.instanceInfo.language !== undefined ?
                                                            'Language: ' +
                                                            (
                                                              link.targetEntity.instanceInfo.language.label !== link.targetEntity.instanceInfo.language.value ?
                                                              link.targetEntity.instanceInfo.language.label + ' (' + link.targetEntity.instanceInfo.language.value + ')' :
                                                              link.targetEntity.instanceInfo.language.value
                                                            ) :
                                                            ''
                                                          )
                                                           :
                                                          ''
                                                        }
                                                      </div> :
                                                      ''
                                                    ) :
                                                    ''
                                                  }
                                                </div>
                                              }
                                              onClick={
                                                handleUseInstanceInfo({
                                                  mappingId: mappingId,
                                                  linkId: link.id,
                                                })
                                              }
                                              color="primary"
                                              onDelete={
                                                this.props.handleShowConfirmDeleteSnackbar({
                                                  handleDelete: handleDirectDeleteInstanceInfo,
                                                  argument: {
                                                    instanceInfoProps: {mappingId: mappingId, linkId: link.id},
                                                  }
                                                })
                                              }
                                            />
                                          </React.Fragment> :
                                          ''
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>

                              {/*Display Generator Declaration Set*/}
                              <Collapse
                                in={
                                  compactViewEnabled ?
                                  !(
                                    compactViewMode !== undefined ?
                                    (
                                      compactViewMode.hideInstanceGenerator !== undefined ?
                                      compactViewMode.hideInstanceGenerator :
                                      true
                                    ) :
                                    true
                                  ) :
                                  true
                                }
                              >
                                <div className={instanceGeneratorDeclarationErrorStyle}>
                                  <div style={{display: 'flex', width: 'fit-content'}}>
                                    <div hidden={link.targetEntity.instanceGeneratorDeclaration === undefined}
                                         className={classes.generatorDeclarationIconWrapperStyle}>
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <GeneratorInfo
                                            title="Instance Generator"
                                            patternTypeColors={patternTypeColors}
                                            />
                                        </React.Fragment>
                                      }
                                      placement="left"
                                      enterDelay={500}
                                      leaveDelay={200}
                                    >
                                      <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                                    </Tooltip>
                                    </div>
                                    <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                                      <div>
                                        {
                                          link.targetEntity.instanceGeneratorDeclaration !== undefined ?
                                          <React.Fragment>
                                            <Chip
                                              classes={{
                                                root: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                                      classes.generatorDeclarationErrorneousChip :
                                                      classes.generatorDeclarationValidChip,
                                                deleteIconSmall: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                                      classes.generatorDeclarationErrorneousDeleteIcon :
                                                      classes.generatorDeclarationValidDeleteIcon,
                                              }}
                                              size="small"
                                              variant="outlined"
                                              label={link.targetEntity.instanceGeneratorDeclaration.name}
                                              onClick={handleGoToGeneratorDeclarationForm({
                                                generatorType: 'instance',
                                                type: 'link',
                                                generatorProps: {mappingId: mappingId, linkId: link.id}
                                              })}
                                              color="primary"
                                              onDelete={
                                                this.props.handleShowConfirmDeleteSnackbar({
                                                  handleDelete: handleDirectDeleteInstanceGeneratorDeclaration,
                                                  argument: {
                                                    generatorType: 'instance',
                                                    type: 'link',
                                                    generatorProps: {mappingId: mappingId, linkId: link.id},
                                                  }
                                                })
                                              }
                                            />
                                          </React.Fragment>
                                          :
                                          ''
                                        }
                                      </div>
                                      <div className={classes.patternWithValuesAndColorsWrapper}>
                                        {
                                        link.targetEntity.instanceGeneratorDeclaration !== undefined ?
                                        <PatternWithValuesAndColors declaration={link.targetEntity.instanceGeneratorDeclaration}
                                                                    patternTypeColors={ patternTypeColors}/> :
                                        ''
                                        }
                                      </div>
                                    </div>
                                    <GeneratorDeclarationError
                                      generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                                      errorMessage={
                                        instanceGeneratorDeclarationErrorMsg !== '' ?
                                         instanceGeneratorDeclarationErrorMsg :
                                         (
                                           instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                                           instanceGeneratorDeclarationArgsErrorMsg :
                                           ''
                                         )
                                       }
                                    />
                                  </div>
                                </div>
                              </Collapse>

                              {/* Display label Generator Declarations */}
                              <Collapse
                                in={
                                  compactViewEnabled ?
                                  !(
                                    compactViewMode !== undefined ?
                                    (
                                      compactViewMode.hideLabelGenerator !== undefined ?
                                      compactViewMode.hideLabelGenerator :
                                      true
                                    ) :
                                    true
                                  ) :
                                  true
                                }
                              >
                                <div hidden={link.targetEntity.labelGeneratorDeclarations === undefined ? true :
                                       (link.targetEntity.labelGeneratorDeclarations.length === 0 ? true : false)}>
                                  <div style={{display: 'table-row'}}>
                                    {
                                      link.targetEntity.labelGeneratorDeclarations !== undefined ?
                                      link.targetEntity.labelGeneratorDeclarations.map((labelDeclaration) => {
                                        const labelGeneratorDeclarationErrorMsg =
                                          labelDeclaration.definitionId !== undefined ?
                                          (
                                            labelDeclaration.definitionId === null ?
                                            'Missing Generator Definition' :
                                            ''
                                          ) :
                                          '';
                                        const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                                          obj => (
                                            obj.name.toLowerCase() !== 'language' ?
                                            obj.error_type_text !== '' :
                                            false
                                          )
                                        );
                                        const labelGeneratorDeclarationArgsErrorMsg =
                                          lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                                          labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                                          labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                                          '';

                                        const labelGeneratorDeclarationErrorStyle =
                                          labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                          classes.generatorDeclarationError :
                                          '';
                                        return (
                                          <div
                                            key={labelDeclaration.id}
                                            className={labelGeneratorDeclarationErrorStyle}
                                            style={{display: 'flex', width: 'fit-content'}}
                                          >
                                            <div className={classes.generatorDeclarationIconWrapperStyle}>
                                              <Tooltip
                                                title={
                                                  <React.Fragment>
                                                    <GeneratorInfo
                                                      title="Label Generator"
                                                      patternTypeColors={patternTypeColors}
                                                      />
                                                  </React.Fragment>
                                                }
                                                placement="left"
                                                enterDelay={500}
                                                leaveDelay={200}
                                              >
                                                <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                                              </Tooltip>
                                            </div>
                                            <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                                              <div>
                                                <React.Fragment>
                                                  <Chip
                                                    classes={{
                                                      root: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                            classes.generatorDeclarationErrorneousChip :
                                                            classes.generatorDeclarationValidChip,
                                                      deleteIconSmall: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                            classes.generatorDeclarationErrorneousDeleteIcon :
                                                            classes.generatorDeclarationValidDeleteIcon,
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    label={labelDeclaration.name}
                                                    onClick={handleGoToGeneratorDeclarationForm({
                                                      declaration: labelDeclaration,
                                                      generatorType: 'label',
                                                      type: 'link',
                                                      generatorProps: {mappingId: mappingId, linkId: link.id}
                                                    })}
                                                    color="primary"
                                                    onDelete={
                                                      this.props.handleShowConfirmDeleteSnackbar({
                                                        handleDelete: handleDirectDeleteLabelGeneratorDeclarations,
                                                        argument: {
                                                          labelGeneratorDeclarationsToBeDeleted: [labelDeclaration],
                                                          generatorProps: {mappingId: mappingId, linkId: link.id}
                                                        }
                                                      })
                                                    }
                                                  />
                                                </React.Fragment>
                                              </div>
                                              <div className={classes.patternWithValuesAndColorsWrapper}>
                                                {
                                                <PatternWithValuesAndColors declaration={labelDeclaration}
                                                                            patternTypeColors={ patternTypeColors}/>
                                                }
                                              </div>
                                            </div>
                                            <GeneratorDeclarationError
                                              generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                                              errorMessage={
                                                labelGeneratorDeclarationErrorMsg !== '' ?
                                                 labelGeneratorDeclarationErrorMsg :
                                                 (
                                                   labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                   labelGeneratorDeclarationArgsErrorMsg :
                                                   ''
                                                 )
                                               }
                                            />
                                          </div>
                                        )
                                      }) :
                                      ''
                                    }
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>

                          <div style={{float:'right'}}>
                            <Button color="primary"
                                    className={classes.button}
                                    onClick={handleAddConstExpr({mappingId: mappingId, linkId: link.id})}>
                              Additional
                            </Button>
                            <Button color="primary"
                                    className={classes.button}
                                    onClick={handleUseGenerator({mappingId: mappingId, linkId: link.id})}>
                              Generator
                            </Button>
                            <Button color="primary"
                                    className={classes.button}
                                    onClick={handleUseVariable({mappingId: mappingId, linkId: link.id})}>
                              Variable
                            </Button>
                            <Button color="primary"
                                    className={classes.button}
                                    onClick={handleUseInstanceInfo({mappingId: mappingId, linkId: link.id})}>
                              Instance Info
                            </Button>
                          </div>
                        </div>
                        {/*Additional of Link*/}
                        <div>
                          <div className={classes.constExprNodesWrapper}
                               style={{transition: 'all 300ms ease-in-out'}}>
                               {/*style={{transition: 'all 300ms ease-in-out', height: linkEditConstExprList.length*212}}*/}
                            <TransitionGroup>
                              {linkEditConstExprList}
                            </TransitionGroup>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={link.showCode} mountOnEnter unmountOnExit
                   timeout={{ enter: 400, exit: 400 }}
                   style={{flex: 1}}>
              <Typography variant="body1" style={{marginTop: -20, paddingBottom: 10}}>
                Put the cursor on or inside a pair of tags to highlight them.
                Press Ctrl-J to jump to the tag that matches the one under the
                cursor.
              </Typography>
              <CodeMirror editorDidMount={editor => editor.setSize('', 'auto')}
                          value={link.x3ml}
                          className="codemirrorCustomStyle"
                          options={{
                            mode: 'xml',
                            lineWrapping: true,
                            theme: 'material',
                            lineNumbers: true,
                            autoRefresh: { delay: 800, force: true },
                            matchTags: {bothTags: true},
                            extraKeys: {"Ctrl-J": "toMatchingTag"}
                          }}
                          onBeforeChange={(editor, data, value) => {
                            handleCodeMirrorInputChange({x3ml: value, mappingId: mappingId, linkId: link.id});
                          }}
                          onChange={(editor, data, value) => {}}
               />
               <div style={{display: 'flex', float:'right'}}>
                 <Button color="primary"
                         className={classes.button}
                         onClick={this.handleShowConfirmationForApplyingFragmentCodeChanges}>
                   Save
                 </Button>
                 <Button color="secondary"
                         className={classes.button}
                         onClick={this.handleConfirmOrToggleShowCode({mappingId: mappingId, linkId: link.id, showCode: false})}>
                   Cancel
                 </Button>
              </div>
            </Collapse>
          </AccordionDetails>
        </Accordion>

        {/*confirm to apply Link fragment code changes*/}
        <ConfirmSnackbar
          open={this.state.confirmImportFragmentCodeSnackbarShown}
          onClose={this.handleCloseImportFragmentCodeSnackbar}
          type="warning"
          msg={'Are you sure you want to apply the changes performed on the link fragment Code?'}
          onConfirmClick={this.handleApplyCodeFragmentChanges({mappingId: mappingId, linkId: link.id})}
          confirmButtonLabel="Yes apply the changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseImportFragmentCodeSnackbar}
        />

        {/*confirm to Close the CodeMirror editor for fragment codes of Domain or Link*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseFragmentCodeSnackbarShown}
          onClose={this.handleCloseConfirmCloseFragmentCodeSnackbar}
          type="warning"
          msg={'There are changes made in the fragment code editor. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseFragmentCode({mappingId: mappingId, linkId: link.id})}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseFragmentCodeSnackbar}
        />

      </div>
    );
  }
}

LinkComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LinkComponent);
