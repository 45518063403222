import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
//import DoneAllIcon from '@material-ui/icons/DoneAll';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const styles = theme => ({

  snackbarErrorTextPrimary: {
    color: '#fff',
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
  },
  smallSnackbarIcon: {
  },
  snackbarMessage: {
    alignItems: 'center',
    display: 'flex',
  },
  snackbarCard: {
    padding: 12
  },
  successVariant: {
    backgroundColor: '#43a047'
  },
  errorVariant: {
    backgroundColor: '#d32f2f'
  },
  warningVariant: {
    backgroundColor: '#ffa000'
  },
  infoVariant: {
    backgroundColor: '#2979ff'
  },
  snackbarBodyText: {
    color: '#fff',
  },
  actionRoot: {
    display: 'block',
    textAlign: 'right'
  },
});

//const CustomSnackMessage = React.forwardRef((props, ref) => {
class CustomSnackMessage extends React.Component {

  render() {
    const { classes, id, msg, variant, iconSize, hasHtmlContent, onClickOfDissmissButton, fullMessage } = this.props;

    return (
      <Card className={classNames(classes.snackbarCard, classes[variant+'Variant'], classes.snackbarBodyText)}>
        <div className={classes.snackbarMessage}>
          {
            variant === 'success' ?
            <CheckCircleOutlineIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            variant === 'error' ?
            <ErrorOutlineIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            variant === 'warning' ?
            <WarningIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            variant === 'info' ?
            <InfoIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            ''
          }
          {/* Simple structure Message */}
          <Typography hidden= {hasHtmlContent !== undefined ? hasHtmlContent : false} variant="body2">
            {msg}
          </Typography>
          {/* HTML structure Message */}
          <div hidden= {hasHtmlContent !== undefined ? !hasHtmlContent : true}>
            {renderHTML(msg)}
          </div>
        </div>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Button size="small"
                  color="primary"
                  classes={{textPrimary: classes.snackbarErrorTextPrimary,}}
                  onClick={
                    onClickOfDissmissButton === undefined ?
                    () => { this.props.closeSnackbar(id) }:
                    onClickOfDissmissButton({message: fullMessage, snackBarId: id})
                  }
          >
            Dismiss
          </Button>
        </CardActions>
      </Card>
    );
  }
}

CustomSnackMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(CustomSnackMessage);
