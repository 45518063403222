import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import reactStringReplace from 'react-string-replace';

const styles = theme => ({

});

export class PatternWithValuesAndColors extends React.Component {


  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.declaration !== nextProps.declaration) {
      return true;
    }
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    else {
      return false;
    }
  }

  // props: {declaration: <Object>, patternTypeColors: <ConfigObject>}
  //findPaternOfDefinitionInListCallBack = (props) => {
  findPaternOfDefinitionInList = (props) => {

    // If there are args in the definition, then there is pattern and
    // it is displayed by having replaced the arg's name with arg's value
    // i.e. {source}/dimension/{term} becomes {<Some_Value}/dimension/{<Some_Value>}
    let patterWithValuesAndColors = props.declaration.pattern;
    if(patterWithValuesAndColors !== null && patterWithValuesAndColors !== undefined) {
      for (const arg of props.declaration.args) {
        patterWithValuesAndColors = reactStringReplace(patterWithValuesAndColors, '{' + [arg.name] + '}', (match, i) => (
          <span key={Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6)}
                style={{color: props.patternTypeColors[arg.type] !== undefined ? props.patternTypeColors[arg.type].color : 'red'}}>
            {arg.type !== 'position' ? '{' + [arg.value] + '}' : '{' + '#' + '}'}
          </span>
        ));
      }
    }
    // Handling build in or Custom
    else {
      if(props.declaration.name.toLowerCase() !== 'uuid') {
        patterWithValuesAndColors = '';
        // Determine which of them are not empty
        let generatorDeclarationArgs = [];
        for (let buildInArg of props.declaration.args) {
          if((buildInArg.value !== undefined && buildInArg.value !== null && buildInArg.value !== '') || buildInArg.type === 'position') {
            generatorDeclarationArgs.push(buildInArg);
          }
        }
        // Constructing what to show for BuildIn or Custom Generators
        patterWithValuesAndColors = generatorDeclarationArgs.map((arg, index) => {
          return (
            <span key={Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6)}>
              {patterWithValuesAndColors + arg.name + ': '}
              <span style={{color: [props.patternTypeColors[arg.type].color]}}>
                {arg.type !== 'position' ? arg.value : '#'}
              </span>
              {index === generatorDeclarationArgs.length-1 ? '' : ', '}
            </span>
          );
        });
      }
    }


    return patterWithValuesAndColors;

  }

  render() {

    const {
      declaration,
    } = this.props;

    console.log('patterWithValuesAndColors - Rendered');
    return (
      <div>
        {this.findPaternOfDefinitionInList({declaration: declaration, patternTypeColors: this.props.patternTypeColors})}
      </div>
    );
  }
}

PatternWithValuesAndColors.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatternWithValuesAndColors);
