import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import SvgIcon from '@material-ui/core/SvgIcon';

import CardContent from '@material-ui/core/CardContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import CodeIcon from '@material-ui/icons/Code';
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import LinkComponent from './LinkComponent';
import ConstExprEdit from './ConstExprEdit';
import ConstExprView from './ConstExprView';
import PatternWithValuesAndColors from './PatternWithValuesAndColors';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import GeneratorInfo from './GeneratorInfo';
import { Controlled as CodeMirror } from 'react-codemirror2';
import ConfirmSnackbar from '.././snackbar/ConfirmationSnackbar';
import GeneratorDeclarationError from './GeneratorDeclarationError';
import { Container, Draggable } from "react-smooth-dnd";
import configuration from '../configuration.json';

const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)
const filter = createFilterOptions();

require('.././codemirror/autorefresh.ext');
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/addon/edit/matchtags');

//import { AnnotationBracket, Note, ConnectorElbow, Annotation } from 'react-annotation'
// Uninstall react-annotation



const styles = theme => ({
  root: {
    width: '100%',
  },
  mappingPanelRoot: {
    backgroundColor: 'rgba(199, 203, 208, 0.2)',
  },
  linkPanelRoot: {
    marginLeft: 10,
    marginRight: 10,
  },
  panelWhenExpanded: {
    marginTop: '16px !important',
    marginBottom: '16px !important',
  },
  domainPanelWhenExpanded: {
    margin: '16px 10px !important'
  },
  card: {
    height: '100%',
    overflow: 'visible'
  },
  headerCellLeft: {
    paddingRight: 8,
  },
  headerCellRight: {
    //borderLeft: '1px dashed  black',
    paddingLeft: 8,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    //color:'#2096f3',
    color: theme.palette.grey[600],
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  shrinkedLinkButton: {
    fontSize: 14,
    height: 21,
    textTransform:'unset',
    whiteSpace: 'nowrap',
    padding: '0px 2px',
    margin: '0px -2px',
    lineHeight: 'unset',
    justifyContent: 'unset',
    minWidth: 'unset'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  variableIcon: {
    color: '#52c41a',
  },
  variableCompIcon: {
    color: '#52c41a',
    fontWeight: '800',
    borderColor: '#52c41a',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  instanceInfoCompIcon: {
    color: '#e58ee9',
    fontWeight: '800',
    borderColor: '#e58ee9',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  expansionPanelSummary: {
    padding: 'unset',
  },
  expandIcon: {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 24px)',
    width: 48,
  },
  hiddenIcon: {
    position: 'absolute',
    top: 0,
    width: 48,
    display: 'none',
  },
  editSummaryStyle: {
    //cursor: 'pointer',
  },
  viewSummaryStyle: {
    cursor: 'auto !important',
    userSelect: 'auto',
  },
  expPanelSumRootDir: {
    display:'flex',
    flexDirection: 'column',
    width:'100%'
  },
  inverseAlignActions: {
    alignItems: 'start',
    justifyContent: 'flex-start',
  },
  autocompleteFormControl: {
    width: '100%',
    display: 'flex'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
    //display:'flex',
    //flexDirection:'column',
    //justifyContent: 'center',
  },
  divFilledIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  itemToDeleteHighlighted: {
    outline: '3px dashed #f50057',
  },
  constExprNodesWrapper: {
    borderLeft:'solid rgba(0, 0, 0, 0.54)',
    marginLeft: 10,
    marginTop:5,
    paddingRight:10,
    width: '100%',
  },
  negationCondition: {
    color: '#f50057',
    paddingRight: 5,
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: -8,
    userSelect: 'none',
  },
  negationConditionEdit: {
    paddingRight: 2,
  },
  conditionList: {
    paddingLeft: 5,
    //borderLeft: 'solid rgba(0, 0, 0, 0.54)',
    display: 'table-cell',
  },
  annotationOrAnd: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: 5,
    fontWeight: 'bold',
    color: '#f50057',
  },
  conditionListWrapper: {
    marginTop: 10,
    marginLeft: 45
  },
  // Generator Declaration
  generatorDeclarationError: {
    color: 'red',
  },
  generatorDeclarationErrorneousDeleteIcon: {
    color: '#ff00001a',
    '&&:hover, &&:active': {
      color: 'red',
    },
  },
  generatorDeclarationValidDeleteIcon: {
    color: 'rgb(171 218 255)',
  },
  generatorDeclarationErrorneousChip: {
    color: 'red',
    border: 'none',
    //border: '1px solid red',
    '&&:hover, &&:active, &&:focus': {
      backgroundColor: '#ff00001a',
    },
  },
  generatorDeclarationValidChip: {
    border: 'none',
  },
  generatorDeclarationIsolationBorder: {
    // border: '1px dashed',
    // borderRadius: 10,
    // padding: 7,
    // marginBottom: 5,
  },
  // generatorDeclarationIsolationBorderValid: {
  //   borderColor: theme.palette.primary.main,
  // },
  generatorDeclarationIsolationBorderError: {
    //borderColor: 'red',
  },
  generatorDeclarationIconWrapperStyle: {
    display: 'table-cell',
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 10
  },
  patternWithValuesAndColorsWrapper: {
    fontStyle: 'italic',
    marginLeft: 9,
    wordBreak: 'break-all',
  },
  declarationLabelWrapper: {
    marginLeft: 9,
  },
  niceBadgeStyle: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
  twoLinesSmallChip: {
    height: 44,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  threeLinesSmallChip: {
    height: 64,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  littleFocusedAvatar: {
    marginTop: -5,
    width:32,
    height:32
  },
  errorLabelButton: {
    fontSize: 12,
    lineHeight: 'unset',
    color: theme.palette.primary.main,
  },
  balloonCommentContentStyle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 2,
    color: theme.palette.primary.contrastText,
  },
  balloonMappingCommentStyle: {
    position: 'absolute',
    marginTop: -3,
    backgroundColor: theme.palette.primary.main,
		border: '1px solid #ccc',
		borderRadius: 5,
		boxShadow: '0 0 20px rgba(0,0,0,0.2)',
    fontStyle: 'italic',
    paddingLeft: 6,
    paddingRight: 5,
    right: 50,
    maxWidth: 'calc(50% - 85px)',
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    '&::before': {
      content: `''`,
	    position: 'absolute',
      borderStyle: 'solid',
			left: 15,
    },
    '&::after': {
      content: `''`,
	    position: 'absolute',
      borderStyle: 'solid',
			left: 16,
    },
  },
  balloonArrow: {
    '&::before': {
      borderWidth: '8px 10px 0 1px',
      borderColor: '#ccc transparent transparent transparent',
      bottom: -8,
    },
    '&::after': {
      borderWidth: '7px 9px 0 0',
      borderColor: '#fff transparent transparent transparent',
      borderTopColor: theme.palette.primary.main,
      bottom: -7,
    },
  },
  headerCommentToEditHighlighted: {
    backgroundColor: theme.palette.primary.dark,
    '&::after': {
      borderTopColor: theme.palette.primary.dark,
    },
  },
  indeterminateCheckBoxIconColor: {
    color: theme.palette.secondary.main,
  },
  disabledIndeterminateCheckBoxIcon: {
    color: theme.palette.grey[500],
  },

  compositeIcon: {
    position: 'relative',
    display: 'inline-block',
  },
  plusIcon: {
    position: 'absolute',
    bottom: -5.8,
    right: -5.2,
    width: '0.9em',
    height: '0.9em',
    fontSize: 'unset',
  },
  backgroundCircle: {
    position: 'absolute',
    bottom: 2,
    right: -5,
    width: '0.7em',
    height: '0.7em',
    borderRadius: '50%',
    backgroundColor: 'red', //theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // Complex Icons style
  letter: {
    position: 'absolute',
    top: '64.5%', // Position vertically
    left: '54%', // Position horizontally
    transform: 'translate(-50%, -50%)', // Center the letter
    fontSize: '0.53rem', // Adjust the font size of the letter "L"
    color: 'white', // Set the color of the letter "L" to white
  },
  svgIcon: {
    fontSize: 18, // Adjust the font size of the SVG icon
  },

  '@keyframes highlightAnimation': {
    '0%': {
      transform: 'rotate(0deg)',
      background: 'none',
    },
    '50%': {
      transform: 'rotate(0deg)',
      background: theme.palette.primary.light,
    },
    '100%': {
      transform: 'rotate(0deg)',
      background: 'none',
    },
  },
  highlightAnimation: {
    animation: '$highlightAnimation 2s ease 700ms', // Apply the animation to the highlighted class
  },
});

const LinkCopyPaste = ({ classes }) => {
  return (
    <div >
      <FileCopyIcon style={{ fontSize: 18 }} className={classes.svgIcon}/>
      <div className={classes.letter}>L</div>
    </div>
  );
};

const LinkCopyPasteComplexIcon = withStyles(styles)(LinkCopyPaste);

const MappingCopyPaste = ({ classes }) => {
  return (
    <div >
      <FileCopyIcon style={{ fontSize: 18 }} className={classes.svgIcon}/>
      <div className={classes.letter}>M</div>
    </div>
  );
};

const MappingCopyPasteComplexIcon = withStyles(styles)(MappingCopyPaste);

export class MappingComponent extends React.Component {
//export class MappingComponent extends React.PureComponent {

  state = {// Fragment Code
    confirmImportFragmentCodeSnackbarShown: false,
    confirmCloseFragmentCodeSnackbarShown: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.state.isHovered !== nextState.isHovered) {
      return true;
    }
    if(this.props.canEdit !== nextProps.canEdit) {
      return true;
    }
    if(this.props.isMappingExpanded !== nextProps.isMappingExpanded) {
      return true;
    }
    if(this.props.mappingIndex !== nextProps.mappingIndex) {
      return true;
    }
    if(this.props.mapping !== nextProps.mapping) {
    //if(isDeepStrictEqual(this.props.mapping, nextProps.mapping)) {
      return true;
    }
    if(this.props.allClasses !== nextProps.allClasses) {
      return true;
    }
    if(this.props.allXpaths !== nextProps.allXpaths) {
      return true;
    }
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.displayFirstCommentOnHeader !== nextProps.displayFirstCommentOnHeader) {
      return true;
    }
    if(this.props.compactViewMode !== nextProps.compactViewMode) {
      return true;
    }
    if(this.props.compactViewEnabled !== nextProps.compactViewEnabled) {
      return true;
    }
    if(this.props.focusedObjectIdUserMap !== nextProps.focusedObjectIdUserMap) {
      return true;
    }
    if(this.props.deletingLinkId !== nextProps.deletingLinkId) {
      return true;
    }
    if(this.props.linkIsInClipboard !== nextProps.linkIsInClipboard) {
      return true;
    }
    if(this.props.mappingOrLinkToSpotAndHighlight !== nextProps.mappingOrLinkToSpotAndHighlight) {
      return true;
    }

    if(this.props.isMappingExpanded) {
      if(this.props.expandedLinkId !== nextProps.expandedLinkId) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.handleHoldRefRecord({
      refRecType: 'mappingRefRecordMap',
      ref: this.ref,
      id: this.props.mapping.id
    });
  }

  componentWillUnmount() {
    this.props.handleRemoveRefRecord({
      refRecType: 'mappingRefRecordMap',
      ref: this.ref,
      id: this.props.mapping.id
    });
  }

  // Changes CSS when hovering over the delete icon of the Mapping
  // props: {status:'mouseEnter | mouseLeave}
  handleHoverDeleteMapping = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({isHovered: hovered});
  }

  // Changes CSS when hovering over the balloon comment displayed on the header
  // props: {status:'mouseEnter | mouseLeave}
  handleHoverEditFirstComment = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({commentIsHovered: hovered});
  }

  // Show snackbar for confirming that changes on the fragment code for the domain will be applied
  handleShowConfirmationForApplyingFragmentCodeChanges = () => {
    this.setState({
      confirmImportFragmentCodeSnackbarShown: true
    });
  }

  // Close Confirmation snackbar for Domain
  handleCloseImportFragmentCodeSnackbar = () => {
    this.setState({
      confirmImportFragmentCodeSnackbarShown: false,
    });
  }

  // Close Confirmation snackbar for Domain
  // props: {mappingId: mappingId}
  handleApplyCodeFragmentChanges = props => () => {
    this.setState({
      confirmImportFragmentCodeSnackbarShown: false,
    }, function() {
      // Callback to apply the changes on the parent
      this.props.importX3ml(props);
    });
  }

  // Confirmation when the fragment code has changed and user tries to close the editor

  // props: {mappingId: <Text>, showCode: <Boolean>}
  handleConfirmOrToggleShowCode = props => event => {
    event.stopPropagation();
    if(!props.showCode) { // Case where toggle --> Close
      const isCodeChanged = this.props.isFragmentCodeChanged(props);
      if(isCodeChanged) {
        this.setState({
          confirmCloseFragmentCodeSnackbarShown: true
        });
      }
      else {
        this.props.handleToggleShowCodeCallBack(props);
      }
    }
    else {
      this.props.handleToggleShowCodeCallBack(props);
    }
  }

  // Close Confirmation snackbar for Domain
  handleCloseConfirmCloseFragmentCodeSnackbar = () => {
    this.setState({
      confirmCloseFragmentCodeSnackbarShown : false,
    });
  }

  // props: {mappingId: <Text>}
  handleConfirmCloseFragmentCode = props => () => {
    this.setState({
      confirmCloseFragmentCodeSnackbarShown : false,
    }, function() {
      this.props.handleToggleShowCodeCallBack(props);
    });
  }

  render() {

    const { classes } = this.props;
    const { mapping, mappingIndex, mappingId, isMappingExpanded, isDomainExpanded, mappingIdsLength, expandedLinkId, canEdit,
            handleExpandChange, handleInputChange, handleInputChangeButton, handleAddLink, allClasses,
            allXpaths, handleAddConstExpr, handleUseGenerator, handleGoToGeneratorDeclarationForm,
            handleDirectDeleteInstanceGeneratorDeclaration, handleDirectDeleteLabelGeneratorDeclarations,
            mappingBackgroundColors, patternTypeColors,
            handleUseCondition, handleGoToConditionForm, handleDirectDeleteCondition,
            handleUseVariable, handleDirectDeleteVariable, handleUseNamedgraph, handleUseDomainNamedgraph,
            handleCodeMirrorInputChange,
            handleLinkCodeMirrorInputChange, importX3mlLink,
            handleUseComment, handleLinkUseComment,
            handleUseInstanceInfo, handleLinkUseInstanceInfo, handleDirectDeleteInstanceInfo, instanceInfoStyleBasedOnLinesRequired,
            compactViewEnabled, displayFirstCommentOnHeader, compactViewMode,
            accordionFocus, accordionBlur, focusedObjectIdUserMap, onDragDrop, handleCopyHyperlink, handlePasteLink, linkIsInClipboard,
            handleCopyMapping, mappingIsInClipboard, 
            navigateToFirstComment, handleChangeIsInUseStatus, handleChangeIsInUseStatusOfAffectedLinks,
            expandOrCollapseFinished, mappingOrLinkToSpotAndHighlight, 
          } = this.props;

    // Used when deleting to "unmount" itself
    /*
    if(!this.props.visible) {
      return null;
    }
    */
    console.log('MappingComponent rendered')

    // Additional of the domain
    var mappingEditConstExprList = mapping.constExprIds.map((constExprId, constExprIndex) => {
      let constExpr = mapping.constExprs[constExprId];
      return (
        <Slide direction={'left'}
               key={'linkConstExpr_' + constExprId}
               style={{ transitionDelay: '400ms' }}>
               {/*timeout={400}*/}

          <ConstExprEdit
            currMappingProjectId={this.props.currMappingProjectId}
            mappingId={mappingId}
            linkId={-1}
            intermediateId={-1}
            constExpr={constExpr}
            constExprId={constExprId}
            constExprIndex={constExprIndex}
            allClasses={allClasses}
            previousEntity={mapping.domainTargetEntity}
            handleDeleteConstExpr={this.props.handleDeleteMappingConstExpr}
            handleInputChange={this.props.handleInputChangeMappingConstExpr}
            handleInputChangeButton={this.props.handleInputChangeMappingConstExprButton}
            handleUseGenerator={this.props.handleConstExprUseGenerator}
            handleUseVariable={this.props.handleConstExprUseVariable}
            handleDirectDeleteVariable={this.props.handleDirectDeleteVariable}
            handleGoToGeneratorDeclarationForm={this.props.handleGoToGeneratorDeclarationForm}
            patternTypeColors={patternTypeColors}
            compactViewMode={compactViewMode}
            handleShowConfirmDeleteSnackbar={this.props.handleShowConfirmDeleteSnackbar}
            handleDirectDeleteInstanceGeneratorDeclaration={handleDirectDeleteInstanceGeneratorDeclaration}
            handleDirectDeleteLabelGeneratorDeclarations={handleDirectDeleteLabelGeneratorDeclarations}
            handleUseInstanceInfo={this.props.handleConstExprUseInstanceInfo}
            handleDirectDeleteInstanceInfo={this.props.handleDirectDeleteInstanceInfo}
            instanceInfoStyleBasedOnLinesRequired={this.props.instanceInfoStyleBasedOnLinesRequired}
            compactViewEnabled={this.props.compactViewEnabled}
            handleDisplayConstExpr={this.props.handleDisplayConstExpr}
            index={constExprIndex}
          />
        </Slide>
      );
    });

    // Additional of the target intermediate - Edit
    var mappingViewConstExprList = mapping.constExprIds.map((constExprId, constExprIndex) => {
      let constExpr = mapping.constExprs[constExprId];
      return (
        <ConstExprView
          key={'constExprView_' + constExpr.id }
          currMappingProjectId={this.props.currMappingProjectId}
          constExpr={constExpr}
          constExprId={constExprId}
          constExprIndex={constExprIndex}
          patternTypeColors={patternTypeColors}
          compactViewMode={compactViewMode}
          compactViewEnabled={this.props.compactViewEnabled}
        />
      );
    });

    // This is each Link Item
    var linkList = mapping.linkIds.map((linkId, linkIndex) => {
      let link = mapping.links[linkId];

      // ***** Link *****

      // If this is the first one, then send the target entity of the domain as well
      // Otherwise send the target entity of the previous link
      // the property to hold this prop is named "previousTargetEntity" (look below)
      return (
        /*
        <CSSTransition
          timeout={400}
          classNames="hideShowLink"
          unmountOnExit
          key={'linkComponent_' + link.id}
        >
        */
        <Draggable key={'linkComponent_' + link.id}>
          <LinkComponent
            currMappingProjectId={this.props.currMappingProjectId}
            mappingIndex={mappingIndex}
            mappingId={mappingId}
            isMappingInUse={mapping.isInUse}
            linkIndex={linkIndex}
            link={link}
            deletingLinkId={this.props.deletingLinkId}
            allClasses={allClasses}
            canEdit={canEdit}
            isLinkExpanded={canEdit ? expandedLinkId === link.id : false}
            leaveEverywhereMoreSpace={mapping.leaveEverywhereMoreSpace}
            isTheLastOne={linkIndex === mapping.linkIds.length-1}
            domainTargetEntity={mapping.domainTargetEntity ? mapping.domainTargetEntity : ''}
            domainSourceNode={mapping.domainSourceNode ? mapping.domainSourceNode : ''}
            handleExpandChange={this.props.handleExpandChange}
            handleDeleteLink={this.props.handleDeleteLink}
            handleInputChange={this.props.handleLinkInputChange}
            handleInputChangeButton={this.props.handleLinkInputChangeButton}
            updateMappingLinks={this.updateMappingLinks}
            handleAddIntermediate={this.props.handleAddIntermediate}
            handleDeleteIntermediate={this.props.handleDeleteIntermediate}
            handleIntermediateInputChange={this.props.handleIntermediateInputChange}
            handleIntermediateInputChangeButton={this.props.handleIntermediateInputChangeButton}
            handleAddConstExpr={this.props.handleAddLinkConstExpr}
            handleDeleteLinkConstExpr={this.props.handleDeleteLinkConstExpr}
            handleInputChangeLinkConstExpr={this.props.handleInputChangeLinkConstExpr}
            handleInputChangeLinkConstExprButton={this.props.handleInputChangeLinkConstExprButton}
            handleAddIntermediateConstExpr={this.props.handleAddIntermediateConstExpr}
            handleDeleteIntermediateConstExpr={this.props.handleDeleteIntermediateConstExpr}
            handleInputChangeIntermediateConstExpr={this.props.handleInputChangeIntermediateConstExpr}
            handleInputChangeIntermediateConstExprButton={this.props.handleInputChangeIntermediateConstExprButton}
            handleUseGenerator={this.props.handleLinkUseGenerator}
            handleTargetIntermediateUseGenerator={this.props.handleTargetIntermediateUseGenerator}
            handleConstExprUseGenerator={this.props.handleConstExprUseGenerator}
            handleGoToGeneratorDeclarationForm={this.props.handleGoToGeneratorDeclarationForm}
            mappingBackgroundColors={this.props.mappingBackgroundColors}
            patternTypeColors={patternTypeColors}
            displayFirstCommentOnHeader={displayFirstCommentOnHeader}
            compactViewMode={compactViewMode}
            handleUseVariable={this.props.handleLinkUseVariable}
            handleDirectDeleteVariable={this.props.handleDirectDeleteVariable}
            handleTargetIntermediateUseVariable={this.props.handleTargetIntermediateUseVariable}
            handleConstExprUseVariable={this.props.handleConstExprUseVariable}
            handleUseNamedgraph={this.props.handleLinkUseNamedgraph}
            handleUseCondition={this.props.handleLinkUseCondition}
            handleGoToConditionForm={handleGoToConditionForm}
            handleDirectDeleteCondition={this.props.handleDirectDeleteCondition}
            handleToggleShowCodeCallBack={this.props.handleToggleShowLinkCodeCallBack}
            isFragmentCodeChanged={this.props.isLinkCodeMirrorChanged}
            handleCodeMirrorInputChange={handleLinkCodeMirrorInputChange}
            importX3ml={importX3mlLink}
            handleShowConfirmDeleteSnackbar={this.props.handleShowConfirmDeleteSnackbar}
            handleDirectDeleteInstanceGeneratorDeclaration={handleDirectDeleteInstanceGeneratorDeclaration}
            handleDirectDeleteLabelGeneratorDeclarations={handleDirectDeleteLabelGeneratorDeclarations}
            handleUseComment={handleLinkUseComment}
            handleUseInstanceInfo={this.props.handleLinkUseInstanceInfo}
            handleTargetIntermediateUseInstanceInfo={this.props.handleTargetIntermediateUseInstanceInfo}
            handleConstExprUseInstanceInfo={this.props.handleConstExprUseInstanceInfo}
            handleDirectDeleteInstanceInfo={this.props.handleDirectDeleteInstanceInfo}
            instanceInfoStyleBasedOnLinesRequired={this.props.instanceInfoStyleBasedOnLinesRequired}
            compactViewEnabled={this.props.compactViewEnabled}
            accordionFocus={this.props.accordionFocus}
            accordionBlur={this.props.accordionBlur}
            focusedObjectIdUserMap={this.props.focusedObjectIdUserMap}
            handleCopyHyperlink={this.props.handleCopyHyperlink}
            handleCloneLink={this.props.handleCloneLink}
            handleCopyLink={this.props.handleCopyLink}
            handleDisplayConstExpr={this.props.handleDisplayConstExpr}
            navigateToFirstComment={this.props.navigateToFirstComment}
            handleChangeIsInUseStatus={this.props.handleChangeIsInUseStatus}
            handleHoldRefRecord={this.props.handleHoldRefRecord}
            handleRemoveRefRecord={this.props.handleHoldRefRecord}
            mappingOrLinkToSpotAndHighlight={this.props.mappingOrLinkToSpotAndHighlight}
          />
        </Draggable>
      );
    });

    // ***** Mapping *****
    // Dynamically changing the space that the source and target sections occupies
    // They are only used for ExpansionPanelDetails
    // The respective decreaseSpaceWhenConstExprIsAdded & inreaseSpaceWhenConstExprIsAdded
    // are used for the ExpansionPanelSummary
    let sourceWidth = mapping.leaveEverywhereMoreSpace ? '33%' : '50%';
    let targetWidth = mapping.leaveEverywhereMoreSpace ? '67%' : '50%';

    const instanceGeneratorDeclarationErrorMsg =
      mapping.domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        mapping.domainTargetEntity.instanceGeneratorDeclaration.definitionId !== undefined ?
        (
          mapping.domainTargetEntity.instanceGeneratorDeclaration.definitionId === null ?
          'Missing Generator Definition' :
          ''
        ) :
        ''
      ) :
      '';

    // Search if there is any empty argument excluding "language" from "literal" or "preflabel" declared definitions
    const lastInstanceGeneratorDeclarationArgErrorIndex =
      mapping.domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        mapping.domainTargetEntity.instanceGeneratorDeclaration.args !== undefined &&
        mapping.domainTargetEntity.instanceGeneratorDeclaration.args.length > 0 ?
        mapping.domainTargetEntity.instanceGeneratorDeclaration.args.findIndex(
          obj => (
            mapping.domainTargetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'literal' &&
            mapping.domainTargetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'preflabel'
          ) ?
          obj.error_type_text !== '' :
          (
            obj.name.toLowerCase() !== 'language' ?
            obj.error_type_text !== '' :
            false
          )
        ) :
        -1
      ) :
      -1;

    const instanceGeneratorDeclarationArgsErrorMsg =
      lastInstanceGeneratorDeclarationArgErrorIndex !== -1 ?
      mapping.domainTargetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].name + ': ' +
      mapping.domainTargetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].error_type_text :
      '';

    const instanceGeneratorDeclarationErrorStyle =
      instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
      classes.generatorDeclarationError :
      '';

    const linksInUse = mapping.linkIds.filter(id => mapping.links[id].isInUse !== undefined && mapping.links[id].isInUse !== null ? mapping.links[id].isInUse : true).length;


    return (
      <div ref={ref => this.ref = ref}>
        <Accordion
          classes={{expanded: classes.panelWhenExpanded}}
          className={
            classNames(
              classes.mappingPanelRoot, this.state.isHovered ? classes.itemToDeleteHighlighted : '', 
            )
          }
          expanded={isMappingExpanded}
          onChange={handleExpandChange({type: 'mapping', id: mappingId})}
          style={{
            borderBottomLeftRadius: mappingIndex === mappingIdsLength-1 ? '4' : 'unset',
            borderBottomRightRadius: mappingIndex === mappingIdsLength-1 ? '4' : 'unset',
            borderTopLeftRadius: 'unset',
            borderTopRightRadius: 'unset'
          }}
          TransitionProps={{
            unmountOnExit: true,
            addEndListener: expandOrCollapseFinished({mappingId: mappingId})
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" color="primary"/>}
            classes={{expandIcon: classes.expandIcon, }}
          >
            <div style={{display: 'flex', width: '100%'}}>
              <div style={{display: 'flex', width: '100%'}}>
                <div style={{paddingRight:4}}>
                  <Tooltip
                    title={'Uncheck this box if you want this mapping to be ignored when producing the X3ML or the output'}
                    placement="top"
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Checkbox
                      checked={
                        mapping.isInUse !== undefined ?
                        mapping.isInUse :
                        true
                      }
                      onClick={handleChangeIsInUseStatus({mappingId: mappingId})}
                      style={{padding: 6}}
                    />
                  </Tooltip>
                </div>
                <Typography
                  className={classes.heading}
                  style={{
                    fontWeight:'bold',
                    margin: 'auto',
                    marginLeft: 'unset',
                    marginRight: 'unset',
                    paddingRight: 5,
                  }}
                >
                  <Tooltip
                    title={'ID: ' + mappingId}
                    placement="top"
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <span>
                      {
                      'Mapping #' + (mappingIndex+1) +
                      (
                        mapping.linkIds.length > 0 ?
                        (' - Has ' + mapping.linkIds.length + (mapping.linkIds.length > 1 ? ' links' : ' link')) :
                        ('- Has no links')
                      )
                      }
                    </span>
                  </Tooltip>
                </Typography>
                <div style={{paddingRight: 5, display: 'flex'}}>
                  <Typography className={classes.heading} style={{margin: 'auto'}}>
                    {'(' + linksInUse + ' links selected'}
                  </Typography>
                  <Tooltip
                    title={'Checking/unchecking all contained links (to be used when producing the X3ML or the output)'}
                    placement="top"
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <div style={{paddingLeft: 4}}>
                      <Checkbox
                        size="small"
                        checked={linksInUse === mapping.linkIds.length}
                        onClick={handleChangeIsInUseStatusOfAffectedLinks({mappingId: mappingId})}
                        color="secondary"
                        value={linksInUse === mapping.linkIds.length}
                        style={{padding: 4, marginTop: 6}}
                        indeterminate={0 < linksInUse && linksInUse < mapping.linkIds.length}
                        indeterminateIcon={
                          <IndeterminateCheckBoxIcon
                            color={
                              mapping.isInUse !== undefined ?
                              (mapping.isInUse ? 'secondary' : 'disabled') :
                              'secondary'
                            }
                          />
                        }
                        disabled={mapping.isInUse !== undefined ? !mapping.isInUse : false}
                      />
                    </div>
                  </Tooltip>
                  <Typography className={classes.heading} style={{margin: 'auto'}}>)</Typography>
                </div>
                {/*Namedgraph*/}
                <Fade
                  in={
                    mapping.namedgraph !== undefined ?
                    (
                      mapping.namedgraph.turnedOn ?
                      true :
                      isMappingExpanded
                    ) :
                    isMappingExpanded
                  }
                  mountOnEnter unmountOnExit
                >
                  <div style={{display: 'flex', margin: 'auto', marginLeft: 'unset', marginRight: 'unset'}}>
                    <Typography className={classes.heading} style={{paddingRight: 5}}>{canEdit ? '- Named Graph:' : ''}</Typography>
                    {
                      <React.Fragment>
                        {
                          canEdit ?
                          <Button color="primary"
                                  className={classes.shrinkedLinkButton}
                                  onClick={handleUseNamedgraph({mappingId: mapping.id})}
                                  style={{marginTop: 1}}
                          >
                            {
                              mapping.namedgraph !== undefined ?
                              (
                                mapping.namedgraph.turnedOn ?
                                (
                                  mapping.namedgraph.value !== '' ?
                                  mapping.namedgraph.value :
                                  '"... ∅ ..."'
                                ) :
                                <span style={{fontStyle: 'italic'}}>(Optionally add one)</span>
                              ) :
                              <span style={{fontStyle: 'italic'}}>(Optionally add one)</span>
                            }
                          </Button> :
                          mapping.namedgraph !== undefined ?
                          (
                            mapping.namedgraph.turnedOn ?
                            (
                              mapping.namedgraph.value !== '' ?
                              mapping.namedgraph.value :
                              '"... ∅ ..."'
                            ) :
                            ''
                          ) :
                          ''
                        }
                      </React.Fragment>
                    }
                  </div>
                </Fade>
                <div style={{paddingLeft: 5, margin: 'auto', marginLeft: 'unset', marginRight: 'unset'}}>
                  <Tooltip
                    title={'Copy hyperlink to this mapping'}
                    placement="bottom-start"
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <IconButton
                      style={{padding:5, top: -2}}
                      color="primary"
                      aria-label="copy hyperlink"
                      onClick={handleCopyHyperlink({mappingId: mappingId})}
                    >
                      <LinkIcon style={{fontSize: 16, transform: 'rotate(45deg)'}}/>
                    </IconButton>
                  </Tooltip>
                </div>
                <Fade in={isMappingExpanded}>
                  <div style={{paddingLeft: 5, margin: 'auto', marginLeft: 'unset', marginRight: 'unset'}}>
                    <Tooltip
                      title={'Copy Mapping'}
                      placement="top-start"
                      enterDelay={500}
                      leaveDelay={200}
                    >
                      <div>
                        <IconButton
                          style={{ padding: 5, top: -2, width: 30, height: 30 }} // Adjust padding and set a fixed width and height for circular shape
                          color="primary"
                          aria-label="copy-mapping"
                          onClick={handleCopyMapping({mappingId: mappingId})}
                        >
                          <MappingCopyPasteComplexIcon/>
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </Fade>
                <Fade in={isMappingExpanded}>
                  <div style={{paddingLeft: 5, margin: 'auto', marginLeft: 'unset', marginRight: 'unset'}}>
                    <Tooltip
                      title={'Paste copied link'}
                      placement="top-start"
                      enterDelay={500}
                      leaveDelay={200}
                    >
                      <div>
                        <IconButton
                          // style={{padding:5, top: -2}}
                          style={{ padding: 5, top: -2, width: 30, height: 30 }} // Adjust padding and set a fixed width and height for circular shape


                          disabled={!linkIsInClipboard}
                          color="primary"
                          aria-label="paste-copied-link"
                          onClick={handlePasteLink({mappingId: mappingId})}
                        >
                          <LinkCopyPasteComplexIcon/>
                          {/* <Icon className={classNames(classes.fontAwesomeIcon, 'fas fa-paste')} style={{fontSize: 16}}/> */}
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </Fade>
                <Fade
                  in={displayFirstCommentOnHeader}
                >
                  <div
                    className={
                      classNames(
                        classes.balloonMappingCommentStyle,
                        classes.balloonArrow,
                        this.state.commentIsHovered ? classes.headerCommentToEditHighlighted : ''
                      )
                    }
                    hidden={
                      mapping.comments === undefined || mapping.comments === null ?
                      true :
                      mapping.comments.length === 0 ?
                      true :
                      mapping.comments[0].rationale === undefined || mapping.comments[0].rationale === null ?
                      true :
                      false
                    }
                    onClick={
                      navigateToFirstComment({
                        mappingId: mappingId
                      })
                    }
                  >
                    <div
                      className={classes.balloonCommentContentStyle}
                      onMouseEnter={this.handleHoverEditFirstComment({status:'mouseEnter'})}
                      onMouseLeave={this.handleHoverEditFirstComment({status:'mouseLeave'})}
                      >
                      {
                        mapping.comments !== undefined && mapping.comments !== null ?
                        (
                          mapping.comments.length > 0 ?
                          (
                            mapping.comments[0].rationale !== undefined && mapping.comments[0].rationale !== null ?
                            mapping.comments[0].rationale :
                            ''
                          ) :
                          ''
                        ) :
                        ''
                      }
                    </div>
                  </div>
                </Fade>
              </div>
              {
                canEdit ?
                <div style={{padding:'unset', float: 'right', right: -10}}>
                  <IconButton
                    hidden={!isMappingExpanded || mapping.showCode}
                    aria-label="Delete"
                    size="small"
                    onClick={
                      this.props.handleShowConfirmDeleteSnackbar({
                        handleDelete: this.props.handleDeleteMapping,
                        mappingId: mappingId
                      })
                    }
                    onMouseEnter={this.handleHoverDeleteMapping({status:'mouseEnter'})}
                    onMouseLeave={this.handleHoverDeleteMapping({status:'mouseLeave'})}
                  >
                    <CloseIcon style={{fontSize:28}}/>
                  </IconButton>
                </div>:
                ''
              }
            </div>
          </AccordionSummary>
          <AccordionDetails
            className={classes.expansionPanelSummary}
          >
            <div style={{width:'100%'}}>
              {/* This is the Domain Item */}
              <Accordion
                className={classes.linkPanelRoot}
                classes={{expanded: classes.domainPanelWhenExpanded }}
                expanded={isDomainExpanded}
                onChange={handleExpandChange({type: 'domain', id: mappingId})}
                style={{
                  backgroundColor:
                    !isDomainExpanded ?
                    mappingBackgroundColors!== undefined ? mappingBackgroundColors.domain.color : '' :
                    ''
                }}
                TransitionProps={{unmountOnExit: true}}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  classes={{
                    expandIcon: canEdit ? classes.expandIcon : classes.hiddenIcon,
                    root: canEdit ? classes.editSummaryStyle : classes.viewSummaryStyle,
                  }}
                  className={
                    classNames(
                      mappingOrLinkToSpotAndHighlight === mappingId ? classes.highlightAnimation : '',
                    )
                  }
                >
                  <div 
                    className={classes.expPanelSumRootDir}
                    
                  >
                    <div>
                      <Grid container spacing={1} >
                        <Grid item xs={12} style={{color:'#2096f3', fontWeight: 'bold'}}>
                          <div style={{display: 'flex', width: '100%'}}>
                            <div style={{paddingRight: 5}}>
                              <Tooltip title={mapping.showCode ? 'Hide Fragment Code' : 'Show Fragment Code'}
                                       placement="top-start"
                                       enterDelay={500}
                                       leaveDelay={200}>
                                <IconButton style={{padding:'unset', top: -2}}
                                            hidden={!isDomainExpanded}
                                            color="primary"
                                            aria-label="code"
                                            onClick={this.handleConfirmOrToggleShowCode({mappingId: mappingId, showCode: !mapping.showCode})}>
                                  <CodeIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div style={{display: 'flex'}}>
                              <Typography className={classes.heading} style={{fontWeight:'bold', paddingRight: 5}}>
                                Domain
                              </Typography>
                              {/*Namedgraph*/}
                              <Fade
                                in={
                                  mapping.domainNamedgraph !== undefined ?
                                  (
                                    mapping.domainNamedgraph.turnedOn ?
                                    true :
                                    isDomainExpanded
                                  ) :
                                  isDomainExpanded
                                }
                                mountOnEnter unmountOnExit
                              >
                                <div style={{display: 'flex'}}>
                                  <Typography className={classes.heading} style={{paddingRight: 5}}>{'- Named Graph:'}</Typography>
                                  {
                                    <React.Fragment>
                                      {
                                        canEdit ?
                                        <div style={{display: 'flex'}}>
                                          <Button color="primary"
                                                  className={classes.shrinkedLinkButton}
                                                  onClick={handleUseDomainNamedgraph({mappingId: mapping.id, domain: true})}
                                                  style={{marginTop: 1}}
                                          >
                                            {
                                              mapping.domainNamedgraph !== undefined ?
                                              (
                                                mapping.domainNamedgraph.turnedOn ?
                                                (
                                                  mapping.domainNamedgraph.value !== '' ?
                                                  mapping.domainNamedgraph.value :
                                                  '"... ∅ ..."'
                                                ) :
                                                <span style={{fontStyle: 'italic'}}>(Optionally add one)</span>
                                              ) :
                                              <span style={{fontStyle: 'italic'}}>(Optionally add one)</span>
                                            }
                                          </Button>
                                        </div> :
                                        mapping.domainNamedgraph !== undefined ?
                                        (
                                          mapping.domainNamedgraph.turnedOn ?
                                          (
                                            mapping.domainNamedgraph.value !== '' ?
                                            mapping.domainNamedgraph.value :
                                            '"... ∅ ..."'
                                          ) :
                                          ''
                                        ) :
                                        ''
                                    }
                                    </React.Fragment>
                                  }
                                </div>
                              </Fade>
                              <Fade
                                in={
                                  isDomainExpanded ?
                                  true :
                                  mapping.comments !== undefined && mapping.comments !== null ?
                                  mapping.comments.length > 0 :
                                  false
                                }
                              >
                                <div style={{display: 'flex'}}>
                                  {
                                    canEdit ?
                                    <Tooltip title="Add New Comment"
                                             placement="right"
                                             enterDelay={500}
                                             leaveDelay={200}
                                    >
                                      <IconButton aria-label="Comment"
                                                  color="primary"
                                                  onClick={handleUseComment({mappingId: mapping.id})}
                                                  style={{marginTop: -3, marginLeft: 10}}
                                                  size="small"
                                      >
                                        <Badge
                                          badgeContent={
                                            mapping.comments !== undefined && mapping.comments !== null ?
                                            mapping.comments.length :
                                            0
                                          }
                                          color="secondary"
                                          classes={{
                                            badge: classes.niceBadgeStyle,
                                          }}
                                        >
                                          <MessageIcon style={{fontSize: 24}}/>
                                        </Badge>
                                      </IconButton>
                                    </Tooltip> :
                                    ''
                                  }
                                </div>
                              </Fade>
                            </div>
                            <div
                              style={{
                                marginLeft: mapping.comments !== undefined && mapping.comments !== null ?
                                (mapping.comments.length > 0 ? 14 : 'unset') :
                                'unset'
                              }}
                            >
                              {
                                this.props.focusedObjectIdUserMap.get(mappingId) !== undefined ?
                                <Avatar
                                  alt="user-avatar"
                                  src={
                                    this.props.focusedObjectIdUserMap.get(mappingId).avatarImageRelativePath !== undefined ?
                                    avatarRootContextPath + this.props.focusedObjectIdUserMap.get(mappingId).avatarImageRelativePath :
                                    ''
                                  }
                                  className={classes.littleFocusedAvatar}
                                /> :
                                ''
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    {/* --overflow: 'hidden'-- below is the trick for preventing user from clicking and collapsing the panel*/}
                    <div style={{overflow: 'hidden', paddingLeft: 10}}>
                      <CSSTransition in={isDomainExpanded}
                                     timeout={600}
                                     classNames="hideExpansionPanelSummaryInOutIniShown">
                        {/* *** View *** */}
                        <div className="hideExpansionPanelSummaryInOutIniShown">
                          <Grid container spacing={1} >
                            <CSSTransition in={mapping.leaveEverywhereMoreSpace}
                                           timeout={500}
                                           classNames="decreaseSpaceWhenConstExprIsAdded"
                                           appear={true}>
                              <Grid item xs={12} sm={6} className={classNames(classes.headerCellLeft, 'decreaseSpaceWhenConstExprIsAdded')}>
                                <div style={{display:'flex'}}>
                                  <div className={classes.divIconStyleWrapper}>
                                    <div className={classes.divFilledIconStyle}
                                         style={{
                                          borderColor: mapping.domainSourceNode.error_text !== undefined ? 'red' : 'unset',
                                          backgroundColor: mapping.domainSourceNode.error_text !== undefined ? '#ff000052' : 'unset',
                                         }}/>
                                  </div>
                                  <div>
                                    <b style={{color: mapping.domainSourceNode.error_text !== undefined ? 'red' : 'unset'}}>
                                      Source Node:
                                    </b>
                                    <span style={{wordBreak: 'break-all'}}>
                                      {mapping.domainSourceNode.item ? mapping.domainSourceNode.item.label : ''}
                                    </span>
                                  </div>
                                </div>

                                {/* Display Conditions - Collapsed View*/}
                                <div className={classes.conditionListWrapper}
                                     hidden={mapping.conditions === undefined ? true :
                                       (mapping.conditions.length === 0 ? true : false)}>
                                   <div hidden={
                                          mapping.conditions === undefined ? true :
                                          (mapping.conditions.length <= 1 ? true : false)
                                        }
                                        className={classes.annotationOrAnd}
                                   >
                                     {
                                       mapping.conditionListLogicalOperator !== undefined ?
                                       mapping.conditionListLogicalOperator.toUpperCase() :
                                       ''
                                     }
                                   </div>
                                  <div className={classes.conditionList}
                                       style={{
                                         borderLeft: mapping.conditions !== undefined ?
                                                     (mapping.conditions.length > 1 ?
                                                      'solid rgba(0, 0, 0, 0.54)' :
                                                      'unset') :
                                                      'unset',}}
                                  >
                                    {
                                      mapping.conditions !== undefined ?
                                      mapping.conditions.map((condition) => {
                                        return (
                                          <div key={condition.id}>
                                            <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'bottom'}}>
                                              <Tooltip title="Condition"
                                                       placement="left"
                                                       enterDelay={500}
                                                       leaveDelay={200}>
                                                <Icon className={classNames(classes.generatorIcon, 'fas fa-file-signature')}/>
                                              </Tooltip>
                                            </div>
                                            <div style={{display: 'table-cell'}}>
                                              <div style={{display: 'flex'}}>
                                                <Tooltip title="Negation"
                                                         placement="left"
                                                         enterDelay={500}
                                                         leaveDelay={200}>
                                                  <div hidden={!condition.negation} className={classes.negationCondition}>¬</div>
                                                </Tooltip>
                                                <div>
                                                  <i>
                                                    {condition.xpath}
                                                  </i>
                                                  <b style={{paddingLeft: 5}}>
                                                    {condition.type}
                                                  </b>
                                                  <i
                                                    hidden={condition.value === undefined || condition.value === ''}
                                                    style={{paddingLeft: 5}}
                                                  >
                                                    {condition.value},
                                                  </i>
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        )
                                      }) :
                                      ''
                                    }
                                  </div>
                                </div>

                              </Grid>
                            </CSSTransition>
                            <CSSTransition in={mapping.leaveEverywhereMoreSpace}
                                           timeout={500}
                                           classNames="increaseSpaceWhenConstExprIsAdded"
                                           appear={true}>
                              <Grid item xs={12} sm={6} className={classNames(classes.headerCellRight, 'increaseSpaceWhenConstExprIsAdded')}>
                                <div style={{display:'flex'}}>
                                  <div className={classes.divIconStyleWrapper}>
                                    <div className={classes.divFilledIconStyle}
                                         style={{
                                           borderColor: mapping.domainTargetEntity.error_text !== undefined ? 'red' : 'unset',
                                           backgroundColor: mapping.domainTargetEntity.error_text !== undefined ? '#ff000052' : 'unset',
                                         }} />
                                  </div>
                                  <div>
                                    <div style={{display:'flex', minWidth: 300}}>
                                      <b style={{minWidth: 90, color: mapping.domainTargetEntity.error_text !== undefined ? 'red' : 'unset'}}>
                                      Target Entity:
                                      </b>
                                      <div style={{display:'flex', flexWrap: 'wrap', marginTop:-4}}>
                                        {
                                          mapping.domainTargetEntity.item !== '' ?
                                          mapping.domainTargetEntity.item.map((itemObj, index) => (
                                            // Without the `key`, React will fire a key warning
                                            <React.Fragment key={'mapping_domain_' + mappingIndex + '_' + index}>
                                              <div style={{padding:5}}>{itemObj.label}{mapping.domainTargetEntity.item.length-1 !== index ? ',' : ''}</div>
                                            </React.Fragment>
                                          )) :
                                          ''
                                        }
                                      </div>
                                    </div>
                                    {/* Variable / Generator Declaration Instance/Label*/}
                                    <div>
                                      {/*Display the Variable*/}
                                      <Collapse
                                        in={
                                          compactViewEnabled ?
                                          !(
                                            compactViewMode !== undefined ?
                                            (
                                              compactViewMode.hideVariable !== undefined ?
                                              compactViewMode.hideVariable :
                                              false
                                            ) :
                                            false
                                          ) :
                                          true
                                        }
                                      >
                                        <div style={{display: 'flex', marginBottom: 10}}>

                                          <div style={{display: 'table-row'}}
                                               hidden={
                                                 mapping.domainTargetEntity.variable === undefined ?
                                                 true :
                                                 (
                                                   mapping.domainTargetEntity.variable.value === '' ?
                                                   true :
                                                   false
                                                 )
                                               }
                                            >
                                            <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                                              <Tooltip title="Variable"
                                                       placement="left"
                                                       enterDelay={500}
                                                       leaveDelay={200}>
                                                <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                                                  <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                                                </div>
                                              </Tooltip>
                                            </div>
                                            <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}
                                                 hidden={
                                                   mapping.domainTargetEntity.variable !== undefined ?
                                                   !mapping.domainTargetEntity.variable.global :
                                                   true
                                                 }
                                            >
                                              <Tooltip title="Global"
                                                       placement="left"
                                                       enterDelay={500}
                                                       leaveDelay={200}>
                                                <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                                              </Tooltip>
                                            </div>

                                            <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                              <div className={classes.declarationLabelWrapper}>
                                                {
                                                  mapping.domainTargetEntity.variable !== undefined ?
                                                  mapping.domainTargetEntity.variable.value :
                                                  ''
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Collapse>

                                      {/* Display the instance Info */}
                                      <Collapse
                                        in={
                                          compactViewEnabled ?
                                          !(
                                            compactViewMode !== undefined ?
                                            (
                                              compactViewMode.hideInstanceInfo !== undefined ?
                                              compactViewMode.hideInstanceInfo :
                                              false
                                            ) :
                                            false
                                          ) :
                                          true
                                        }
                                      >
                                        <div style={{display: 'flex', marginBottom: 10}}>
                                          <div style={{display: 'table-row'}}
                                               hidden={
                                                 mapping.domainTargetEntity.instanceInfo === undefined ?
                                                 true :
                                                 (
                                                   mapping.domainTargetEntity.instanceInfo.constant === '' &&
                                                   mapping.domainTargetEntity.instanceInfo.description === '' &&
                                                   mapping.domainTargetEntity.instanceInfo.language === '' ?
                                                   true :
                                                   false
                                                 )
                                               }>
                                            <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                                              <Tooltip title="Instance Info"
                                                       placement="left"
                                                       enterDelay={500}
                                                       leaveDelay={200}>
                                                <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                                                  <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                                                </div>
                                              </Tooltip>
                                            </div>
                                            <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                              {
                                                mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                (
                                                  mapping.domainTargetEntity.instanceInfo.constant !== undefined &&
                                                  mapping.domainTargetEntity.instanceInfo.constant !== '' ?
                                                  <div
                                                    className={classes.declarationLabelWrapper}
                                                    style={{
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                      overflow: 'hidden',
                                                      maxWidth: 148,
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title={mapping.domainTargetEntity.instanceInfo.constant}
                                                      placement="bottom-start"
                                                      enterDelay={800}
                                                      leaveDelay={200}
                                                    >
                                                      <span>
                                                        {
                                                          mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                          'Constant: ' + mapping.domainTargetEntity.instanceInfo.constant :
                                                          ''
                                                        }
                                                      </span>
                                                    </Tooltip>
                                                  </div> :
                                                  ''
                                                ) :
                                                ''
                                              }
                                              {
                                                mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                (
                                                  mapping.domainTargetEntity.instanceInfo.description !== undefined &&
                                                  mapping.domainTargetEntity.instanceInfo.description !== '' ?
                                                  <div
                                                    className={classes.declarationLabelWrapper}
                                                    style={{
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                      overflow: 'hidden',
                                                      maxWidth: 148,
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title={mapping.domainTargetEntity.instanceInfo.description}
                                                      placement="bottom-start"
                                                      enterDelay={800}
                                                      leaveDelay={200}
                                                    >
                                                      <span>
                                                        {
                                                          mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                          'Description: ' + mapping.domainTargetEntity.instanceInfo.description :
                                                          ''
                                                        }
                                                      </span>
                                                    </Tooltip>
                                                  </div> :
                                                  ''
                                                ) :
                                                ''
                                              }
                                              <div
                                                style={{
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                  maxWidth: 148,
                                                }}
                                                hidden={
                                                  mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                  (
                                                    mapping.domainTargetEntity.instanceInfo.language !== undefined ?
                                                    mapping.domainTargetEntity.instanceInfo.language.value === '' :
                                                    true
                                                  ) :
                                                  true
                                                }
                                                className={classes.declarationLabelWrapper}
                                              >
                                                {
                                                  mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                  (
                                                    mapping.domainTargetEntity.instanceInfo.language !== undefined ?
                                                    'Language: ' +
                                                    (
                                                      mapping.domainTargetEntity.instanceInfo.language.label !== mapping.domainTargetEntity.instanceInfo.language.value ?
                                                      mapping.domainTargetEntity.instanceInfo.language.label + ' (' + mapping.domainTargetEntity.instanceInfo.language.value + ')' :
                                                      mapping.domainTargetEntity.instanceInfo.language.value
                                                    ) :
                                                    ''
                                                  )
                                                   :
                                                  ''
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Collapse>

                                      {/*Display the Instance Generator Declaration*/}
                                      <Collapse
                                        in={
                                          compactViewEnabled ?
                                          !(
                                            compactViewMode !== undefined ?
                                            (
                                              compactViewMode.hideInstanceGenerator !== undefined ?
                                              compactViewMode.hideInstanceGenerator :
                                              true
                                            ) :
                                            true
                                          ) :
                                          true
                                        }
                                      >
                                        <div style={{display: 'flex'}} className={instanceGeneratorDeclarationErrorStyle}>
                                          <div style={{display: 'flex', width: 'fit-content'}}>
                                            <div hidden={mapping.domainTargetEntity.instanceGeneratorDeclaration === undefined}
                                                 className={classes.generatorDeclarationIconWrapperStyle}>
                                              <Tooltip
                                                title={
                                                  <React.Fragment>
                                                    <GeneratorInfo
                                                      title="Instance Generator"
                                                      patternTypeColors={patternTypeColors}
                                                      />
                                                  </React.Fragment>
                                                }
                                                placement="left"
                                                enterDelay={500}
                                                leaveDelay={200}
                                              >
                                                <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                                              </Tooltip>
                                            </div>
                                            <div
                                              style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}
                                              className={
                                                classNames(
                                                  classes.generatorDeclarationIsolationBorder,
                                                  instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                                                  classes.generatorDeclarationIsolationBorderError :
                                                  '',
                                                )
                                              }
                                            >
                                              <div className={classes.declarationLabelWrapper}>
                                                {
                                                  mapping.domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
                                                  mapping.domainTargetEntity.instanceGeneratorDeclaration.name :
                                                  ''
                                                }
                                              </div>
                                              <div className={classes.patternWithValuesAndColorsWrapper}>
                                                {
                                                mapping.domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
                                                <PatternWithValuesAndColors declaration={mapping.domainTargetEntity.instanceGeneratorDeclaration}
                                                                            patternTypeColors={ patternTypeColors}/> :
                                                ''
                                                }
                                              </div>
                                            </div>
                                            <GeneratorDeclarationError
                                              generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                                              errorMessage={
                                                instanceGeneratorDeclarationErrorMsg !== '' ?
                                                 instanceGeneratorDeclarationErrorMsg :
                                                 (
                                                   instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                                                   instanceGeneratorDeclarationArgsErrorMsg :
                                                   ''
                                                 )
                                               }
                                            />
                                          </div>
                                        </div>
                                      </Collapse>

                                      {/* Display label Generator Declarations */}
                                      <Collapse
                                        in={
                                          compactViewEnabled ?
                                          !(
                                            compactViewMode !== undefined ?
                                            (
                                              compactViewMode.hideLabelGenerator !== undefined ?
                                              compactViewMode.hideLabelGenerator :
                                              true
                                            ) :
                                            true
                                          ) :
                                          true
                                        }
                                      >
                                        <div hidden={mapping.domainTargetEntity.labelGeneratorDeclarations === undefined ? true :
                                               (mapping.domainTargetEntity.labelGeneratorDeclarations.length === 0 ? true : false)}>
                                          <div style={{display: 'table-row'}}>
                                            {
                                              mapping.domainTargetEntity.labelGeneratorDeclarations !== undefined ?
                                              mapping.domainTargetEntity.labelGeneratorDeclarations.map((labelDeclaration) => {
                                                const labelGeneratorDeclarationErrorMsg =
                                                  labelDeclaration.definitionId !== undefined ?
                                                  (
                                                    labelDeclaration.definitionId === null ?
                                                    'Missing Generator Definition' :
                                                    ''
                                                  ) :
                                                  '';
                                                const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                                                  obj => (
                                                    obj.name.toLowerCase() !== 'language' ?
                                                    obj.error_type_text !== '' :
                                                    false
                                                  )
                                                );

                                                // Used to be<
                                                // const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                                                //   obj => (
                                                //     labelDeclaration.name.toLowerCase() !== 'literal' &&
                                                //     labelDeclaration.name.toLowerCase() !== 'preflabel'
                                                //   ) ?
                                                //   obj.error_type_text !== '' :
                                                //   (
                                                //     obj.name.toLowerCase() !== 'language' ?
                                                //     obj.error_type_text !== '' :
                                                //     false
                                                //   )
                                                // );

                                                const labelGeneratorDeclarationArgsErrorMsg =
                                                  lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                                                  labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                                                  labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                                                  '';
                                                const labelGeneratorDeclarationErrorStyle =
                                                  labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                  classes.generatorDeclarationError :
                                                  '';
                                                return (
                                                  <div
                                                    key={labelDeclaration.id}
                                                    className={labelGeneratorDeclarationErrorStyle}
                                                    style={{display: 'flex', marginTop: 4, width: 'fit-content'}}
                                                  >
                                                    <div className={classes.generatorDeclarationIconWrapperStyle}>
                                                      <Tooltip
                                                        title={
                                                          <React.Fragment>
                                                            <GeneratorInfo
                                                              title="Label Generator"
                                                              patternTypeColors={patternTypeColors}
                                                              />
                                                          </React.Fragment>
                                                        }
                                                        placement="left"
                                                        enterDelay={500}
                                                        leaveDelay={200}
                                                      >
                                                        <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                                                      </Tooltip>
                                                    </div>
                                                    <div
                                                      style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}
                                                      className={
                                                        classNames(
                                                          classes.generatorDeclarationIsolationBorder,
                                                          labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                          classes.generatorDeclarationIsolationBorderError :
                                                          '',
                                                        )
                                                      }
                                                    >
                                                      <div className={classes.declarationLabelWrapper}>
                                                        {labelDeclaration.name}
                                                      </div>
                                                      <div className={classes.patternWithValuesAndColorsWrapper}>
                                                        {
                                                        <PatternWithValuesAndColors declaration={labelDeclaration}
                                                                                    patternTypeColors={ patternTypeColors}/>
                                                        }
                                                      </div>
                                                    </div>
                                                    <GeneratorDeclarationError
                                                      generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                                                      errorMessage={
                                                        labelGeneratorDeclarationErrorMsg !== '' ?
                                                         labelGeneratorDeclarationErrorMsg :
                                                         (
                                                           labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                           labelGeneratorDeclarationArgsErrorMsg :
                                                           ''
                                                         )
                                                       }
                                                    />
                                                  </div>
                                                )
                                              }) :
                                              ''
                                            }
                                          </div>
                                        </div>
                                      </Collapse>
                                    </div>
                                  </div>

                                  <div className={classes.constExprNodesWrapper} style={{marginLeft:10}} hidden={mappingViewConstExprList.length <= 0}>
                                    {mappingViewConstExprList}
                                  </div>
                                </div>

                              </Grid>
                            </CSSTransition>
                          </Grid>
                        </div>
                      </CSSTransition>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  onFocus={accordionFocus({id: mappingId})}
                  onBlur={accordionBlur({id: mappingId})}
                >
                  <Collapse in={!mapping.showCode} mountOnEnter unmountOnExit style={{width: '100%'}}
                         timeout={{ enter: 400, exit: 400 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.headerCellLeft}
                            style={{transition: 'all 400ms ease-in-out', maxWidth: sourceWidth, flexBasis: sourceWidth}}>
                        <Card
                          className={classes.card}
                        >
                          <CardContent>
                            <div style={{display:'flex',}}>
                              <div className={classes.divIconStyleWrapper}>
                                <div className={classes.divFilledIconStyle}/>
                              </div>
                              <FormControl className={classes.autocompleteFormControl} error={mapping.domainSourceNode.error_text !== undefined}>
                                <Autocomplete
                                  options={allXpaths}
                                  getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                                    </React.Fragment>
                                  )}
                                  getOptionSelected={(option, value) => option.value === value.value}
                                  onChange={handleInputChange({name: 'domainSourceNode', mappingId: mapping.id})}
                                  value={mapping.domainSourceNode.item !== undefined && mapping.domainSourceNode.item !== '' ? mapping.domainSourceNode.item : null}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                      filtered.push({
                                        inputValue: params.inputValue,
                                        label: `Add "${params.inputValue}"`,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  filterSelectedOptions={false /* if true it will remove selected from the option list */}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Source Node"
                                      placeholder="Please select source node"
                                      FormHelperTextProps={{component: 'div'}}
                                      helperText={
                                        <div style={{color: 'red'}}>
                                          <span style={{paddingRight: 10}}>
                                            {mapping.domainSourceNode.error_text !== undefined ? mapping.domainSourceNode.error_text : ''}
                                          </span>
                                          {
                                            mapping.domainSourceNode.item !== '' ?
                                            <Button
                                              color="secondary"
                                              hidden={mapping.domainSourceNode.error_text === undefined}
                                              size="small"
                                              className={classes.errorLabelButton}
                                              onClick={
                                                handleInputChangeButton({
                                                  name: 'domainSourceNode',
                                                  mappingId: mapping.id,
                                                  item: mapping.domainSourceNode.item,
                                                })
                                              }
                                            >
                                              Make Custom
                                            </Button> :
                                            ''
                                          }
                                        </div>
                                      }
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>
                            {/* Display Conditions - Expanded View*/}
                            <div
                              className={classes.conditionListWrapper}
                              hidden={
                                mapping.conditions === undefined ? true :
                                (mapping.conditions.length === 0 ? true : false)
                              }
                            >

                              <div
                                hidden={
                                  mapping.conditions === undefined ? true :
                                  (mapping.conditions.length <= 1 ? true : false)
                                }
                                className={classes.annotationOrAnd}
                              >
                                {
                                  mapping.conditionListLogicalOperator !== undefined ?
                                  mapping.conditionListLogicalOperator.toUpperCase() :
                                  ''
                                }
                              </div>

                              <div
                                className={classes.conditionList}
                                style={{
                                  borderLeft: mapping.conditions !== undefined ?
                                              (
                                                mapping.conditions.length > 1 ?
                                                'solid rgba(0, 0, 0, 0.54)' :
                                                'unset'
                                              ) :
                                              'unset',
                                }}
                              >
                                {
                                  mapping.conditions !== undefined ?
                                  mapping.conditions.map((condition) => {
                                    return (
                                      <div key={condition.id}>
                                        <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'bottom'}}>
                                          <Tooltip title="Condition"
                                                   placement="left"
                                                   enterDelay={500}
                                                   leaveDelay={200}>
                                            <Icon className={classNames(classes.generatorIcon, 'fas fa-file-signature')}/>
                                          </Tooltip>
                                        </div>
                                        <div style={{display: 'table-cell'}}>
                                          <div style={{display: 'flex'}}>
                                            <React.Fragment>
                                              <Chip
                                                classes={{
                                                  root: classes.generatorDeclarationValidChip,
                                                  deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                                }}
                                                style={{height: '100%', borderRadius: 8, paddingTop: 3, paddingBottom: 3}}
                                                size="small"
                                                variant="outlined"
                                                label={
                                                  <div style={{wordBreak: 'break-all', whiteSpace: 'normal', display: 'flex'}}>
                                                    <Tooltip
                                                      title="Negation"
                                                      placement="left"
                                                      enterDelay={500}
                                                      leaveDelay={200}
                                                    >
                                                      <div
                                                        hidden={!condition.negation}
                                                        className={
                                                          classNames(
                                                            classes.negationCondition,
                                                            classes.negationConditionEdit
                                                          )
                                                        }
                                                        style={{marginRight: 5}}
                                                      >¬</div>
                                                    </Tooltip>
                                                    <div>
                                                      <i>
                                                        {condition.xpath}
                                                      </i>
                                                      <b style={{paddingLeft: 5}}>
                                                        {condition.type}
                                                      </b>
                                                      <i
                                                        hidden={condition.value === undefined || condition.value === ''}
                                                        style={{paddingLeft: 5}}
                                                      >
                                                        {condition.value},
                                                      </i>
                                                    </div>
                                                  </div>
                                                }
                                                onClick={
                                                  handleGoToConditionForm({
                                                    condition: condition,
                                                    type: 'mapping',
                                                    conditionProps: {mappingId: mapping.id}
                                                  })
                                                }
                                                color="primary"
                                                onDelete={
                                                  this.props.handleShowConfirmDeleteSnackbar({
                                                    handleDelete: handleDirectDeleteCondition,
                                                    condition: condition,
                                                    conditionProps: {mappingId: mapping.id},
                                                  })
                                                }
                                              />
                                            </React.Fragment>
                                          </div>

                                        </div>
                                      </div>
                                    )
                                  }) :
                                  ''
                                }
                              </div>
                            </div>
                            <div style={{float:'right'}}>
                              <Button color="primary"
                                      className={classes.button}
                                      onClick={handleUseCondition({mappingId: mapping.id})}>
                                Condition
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} className={classes.headerCellRight}
                            style={{transition: 'all 400ms ease-in-out', maxWidth: targetWidth, flexBasis: targetWidth}}>
                        <Card
                          className={classes.card}
                        >
                          <CardContent>
                            <div style={{display: 'flex'}}>
                              <div style={{width:'100%'}}>
                                <div style={{display: 'flex'}}>
                                  <div className={classes.divIconStyleWrapper}>
                                    <div className={classes.divFilledIconStyle}/>
                                  </div>
                                  <div style={{width:'100%'}}>
                                    <FormControl className={classes.autocompleteFormControl} error={mapping.domainTargetEntity.error_text !== undefined}>
                                      <Autocomplete
                                        options={[].concat.apply([], allClasses.map(item => item.options.map(optItem => ({ ...optItem, group: item.label }))))}
                                        groupBy={(option) => option.group}
                                        getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
                                        renderOption={(option) => (
                                          <React.Fragment>
                                            <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                                          </React.Fragment>
                                        )}
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        onChange={handleInputChange({name: 'domainTargetEntity', mappingId: mapping.id})}
                                        value={mapping.domainTargetEntity.item !== '' ? mapping.domainTargetEntity.item : []}
                                        multiple
                                        filterOptions={(options, params) => {
                                          const filtered = filter(options, params);
                                          // Suggest the creation of a new value
                                          if (params.inputValue !== '') {
                                            filtered.push({
                                              inputValue: params.inputValue,
                                              label: `Add "${params.inputValue}"`,
                                            });
                                          }
                                          return filtered;
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            label="Target Entity"
                                            placeholder="Please select target entity"
                                            FormHelperTextProps={{component: 'div'}}
                                            helperText={
                                              <div style={{color: 'red'}}>
                                                <span style={{paddingRight: 10}}>
                                                  {mapping.domainTargetEntity.error_text !== undefined ? mapping.domainTargetEntity.error_text : ''}
                                                </span>
                                                {
                                                  mapping.domainTargetEntity.item !== '' ?
                                                  <Button
                                                    color="secondary"
                                                    hidden={mapping.domainTargetEntity.error_text === undefined}
                                                    size="small"
                                                    className={classes.errorLabelButton}
                                                    onClick={
                                                      handleInputChangeButton({
                                                        name: 'domainTargetEntity',
                                                        mappingId: mapping.id,
                                                        item: mapping.domainTargetEntity.item,
                                                      })
                                                    }
                                                  >
                                                    Make Custom
                                                  </Button> :
                                                  ''
                                                }
                                              </div>
                                            }
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </div>
                                </div>

                                {/* Variable / Generator - Expanded panel */}
                                <div style={{marginLeft: 44, paddingTop: 10}}>

                                  {/* Variable / Generator Declaration Instance/Label*/}
                                  <div>
                                    {/*Display the Variable*/}
                                    <Collapse
                                      in={
                                        compactViewEnabled ?
                                        !(
                                          compactViewMode !== undefined ?
                                          (
                                            compactViewMode.hideVariable !== undefined ?
                                            compactViewMode.hideVariable :
                                            false
                                          ) :
                                          false
                                        ) :
                                        true
                                      }
                                    >
                                      <div style={{display: 'flex', marginBottom: 10}}>
                                        <div style={{display: 'table-row'}}
                                             hidden={
                                               mapping.domainTargetEntity.variable === undefined ?
                                               true :
                                               (
                                                 mapping.domainTargetEntity.variable.value === '' ?
                                                 true :
                                                 false
                                               )
                                             }>
                                          <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'middle'}}>
                                            <Tooltip title="Variable"
                                                     placement="left"
                                                     enterDelay={500}
                                                     leaveDelay={200}>
                                              <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                                                <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                                              </div>
                                            </Tooltip>
                                          </div>
                                          <div className={classes.generatorDeclarationIconWrapperStyle}
                                               hidden={
                                                 mapping.domainTargetEntity.variable !== undefined ?
                                                 !mapping.domainTargetEntity.variable.global :
                                                 true
                                               }
                                          >
                                            <Tooltip title="Global"
                                                     placement="left"
                                                     enterDelay={500}
                                                     leaveDelay={200}>
                                              <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                                            </Tooltip>
                                          </div>

                                          <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                            <div>
                                              {
                                                mapping.domainTargetEntity.variable !== undefined ?
                                                <React.Fragment>
                                                  <Chip
                                                    classes={{
                                                      root: classes.generatorDeclarationValidChip,
                                                      deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    label={mapping.domainTargetEntity.variable.value}
                                                    onClick={
                                                      handleUseVariable({
                                                        mappingId: mapping.id
                                                      })
                                                    }
                                                    color="primary"
                                                    onDelete={
                                                      this.props.handleShowConfirmDeleteSnackbar({
                                                        handleDelete: handleDirectDeleteVariable,
                                                        argument: {
                                                          variableProps: {mappingId: mapping.id},
                                                        }
                                                      })
                                                    }
                                                  />
                                                </React.Fragment> :
                                                ''
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>

                                    {/* Display the instance Info */}
                                    <Collapse
                                      in={
                                        compactViewEnabled ?
                                        !(
                                          compactViewMode !== undefined ?
                                          (
                                            compactViewMode.hideInstanceInfo !== undefined ?
                                            compactViewMode.hideInstanceInfo :
                                            false
                                          ) :
                                          false
                                        ) :
                                        true
                                      }
                                    >
                                      <div style={{display: 'flex', marginBottom: 10}}>
                                        <div style={{display: 'table-row'}}
                                             hidden={
                                               mapping.domainTargetEntity.instanceInfo === undefined ?
                                               true :
                                               (
                                                 mapping.domainTargetEntity.instanceInfo.description === '' && mapping.domainTargetEntity.instanceInfo.language === '' ?
                                                 true :
                                                 false
                                               )
                                             }>
                                          <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'middle'}}>
                                            <Tooltip title="Instance Info"
                                                     placement="left"
                                                     enterDelay={500}
                                                     leaveDelay={200}>
                                              <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                                                <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                                              </div>
                                            </Tooltip>
                                          </div>

                                          <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                            <div>
                                              {
                                                mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                <React.Fragment>
                                                  <Chip
                                                    classes={{
                                                      root: classes.generatorDeclarationValidChip,
                                                      deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                                      sizeSmall:  classes[instanceInfoStyleBasedOnLinesRequired(mapping.domainTargetEntity.instanceInfo)],
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    label={
                                                      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                                        {
                                                          mapping.domainTargetEntity.instanceInfo.constant !== undefined &&
                                                          mapping.domainTargetEntity.instanceInfo.constant !== '' ?
                                                          <div
                                                            style={{
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                              overflow: 'hidden',
                                                              maxWidth: 148,
                                                            }}
                                                          >
                                                            {
                                                              mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                              'Constant: ' + mapping.domainTargetEntity.instanceInfo.constant :
                                                              ''
                                                            }
                                                          </div> :
                                                          ''
                                                        }
                                                        {
                                                          mapping.domainTargetEntity.instanceInfo.description !== undefined &&
                                                          mapping.domainTargetEntity.instanceInfo.description !== '' ?
                                                          <div
                                                            style={{
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                              overflow: 'hidden',
                                                              maxWidth: 148,
                                                            }}
                                                          >
                                                            {
                                                              mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                              'Descriptin: ' + mapping.domainTargetEntity.instanceInfo.description :
                                                              ''
                                                            }
                                                          </div> :
                                                          ''
                                                        }
                                                        {
                                                          mapping.domainTargetEntity.instanceInfo.language !== undefined ?
                                                          (
                                                            mapping.domainTargetEntity.instanceInfo.language.value !== '' ?
                                                            <div>
                                                              {
                                                                mapping.domainTargetEntity.instanceInfo !== undefined ?
                                                                (
                                                                  mapping.domainTargetEntity.instanceInfo.language !== undefined ?
                                                                  'Language: ' +
                                                                  (
                                                                    mapping.domainTargetEntity.instanceInfo.language.label !== mapping.domainTargetEntity.instanceInfo.language.value ?
                                                                    mapping.domainTargetEntity.instanceInfo.language.label + ' (' + mapping.domainTargetEntity.instanceInfo.language.value + ')' :
                                                                    mapping.domainTargetEntity.instanceInfo.language.value
                                                                  ) :
                                                                  ''
                                                                )
                                                                 :
                                                                ''
                                                              }
                                                            </div> :
                                                            ''
                                                          ) :
                                                          ''
                                                        }
                                                      </div>
                                                    }
                                                    onClick={
                                                      handleUseInstanceInfo({
                                                        mappingId: mapping.id
                                                      })
                                                    }
                                                    color="primary"
                                                    onDelete={
                                                      this.props.handleShowConfirmDeleteSnackbar({
                                                        handleDelete: handleDirectDeleteInstanceInfo,
                                                        argument: {
                                                          instanceInfoProps: {mappingId: mapping.id},
                                                        }
                                                      })
                                                    }
                                                  />
                                                </React.Fragment> :
                                                ''
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>

                                    {/*Display Instance Generator Declaration*/}
                                    <Collapse
                                      in={
                                        compactViewEnabled ?
                                        !(
                                          compactViewMode !== undefined ?
                                          (
                                            compactViewMode.hideInstanceGenerator !== undefined ?
                                            compactViewMode.hideInstanceGenerator :
                                            true
                                          ) :
                                          true
                                        ) :
                                        true
                                      }
                                    >
                                      <div className={instanceGeneratorDeclarationErrorStyle}>
                                        <div style={{display: 'flex', width: 'fit-content'}}>
                                          <div hidden={mapping.domainTargetEntity.instanceGeneratorDeclaration === undefined}
                                               className={classes.generatorDeclarationIconWrapperStyle}
                                          >
                                            <Tooltip
                                              title={
                                                <React.Fragment>
                                                  <GeneratorInfo
                                                    title="Instance Generator"
                                                    patternTypeColors={patternTypeColors}
                                                    />
                                                </React.Fragment>
                                              }
                                              placement="bottom"
                                              enterDelay={500}
                                              leaveDelay={200}
                                            >
                                              <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                                            </Tooltip>
                                          </div>
                                          <div
                                            style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}
                                            className={
                                              classNames(
                                                classes.generatorDeclarationIsolationBorder,
                                                instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                                                classes.generatorDeclarationIsolationBorderError :
                                                '',
                                              )
                                            }
                                          >
                                            <div>
                                              {
                                                mapping.domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
                                                <React.Fragment>
                                                  <Chip
                                                    classes={{
                                                      root: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                                            classes.generatorDeclarationErrorneousChip :
                                                            classes.generatorDeclarationValidChip,
                                                      deleteIconSmall: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                                            classes.generatorDeclarationErrorneousDeleteIcon :
                                                            classes.generatorDeclarationValidDeleteIcon,
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    label={mapping.domainTargetEntity.instanceGeneratorDeclaration.name}
                                                    onClick={handleGoToGeneratorDeclarationForm({
                                                      generatorType: 'instance',
                                                      type: 'mapping',
                                                      generatorProps: {mappingId: mapping.id}
                                                    })}
                                                    color="primary"
                                                    onDelete={
                                                      this.props.handleShowConfirmDeleteSnackbar({
                                                        handleDelete: handleDirectDeleteInstanceGeneratorDeclaration,
                                                        argument: {
                                                          generatorType: 'instance',
                                                          type: 'mapping',
                                                          generatorProps: {mappingId: mapping.id},
                                                        }
                                                      })
                                                    }
                                                  />
                                                </React.Fragment>
                                                :
                                                ''
                                              }
                                            </div>
                                            <div className={classes.patternWithValuesAndColorsWrapper}>
                                              {
                                              mapping.domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
                                              <PatternWithValuesAndColors declaration={mapping.domainTargetEntity.instanceGeneratorDeclaration}
                                                                          patternTypeColors={ patternTypeColors}/> :
                                              ''
                                              }
                                            </div>
                                          </div>
                                          <GeneratorDeclarationError
                                            generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                                            errorMessage={
                                              instanceGeneratorDeclarationErrorMsg !== '' ?
                                               instanceGeneratorDeclarationErrorMsg :
                                               (
                                                 instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                                                 instanceGeneratorDeclarationArgsErrorMsg :
                                                 ''
                                               )
                                             }
                                          />
                                        </div>
                                      </div>
                                    </Collapse>

                                    {/* Display label Generator Declarations */}
                                    <Collapse
                                      in={
                                        compactViewEnabled ?
                                        !(
                                          compactViewMode !== undefined ?
                                          (
                                            compactViewMode.hideLabelGenerator !== undefined ?
                                            compactViewMode.hideLabelGenerator :
                                            true
                                          ) :
                                          true
                                        ) :
                                        true
                                      }
                                    >
                                      <div hidden={mapping.domainTargetEntity.labelGeneratorDeclarations === undefined ? true :
                                             (mapping.domainTargetEntity.labelGeneratorDeclarations.length === 0 ? true : false)}>
                                        <div style={{display: 'table-row'}}>
                                          {
                                            mapping.domainTargetEntity.labelGeneratorDeclarations !== undefined ?
                                            mapping.domainTargetEntity.labelGeneratorDeclarations.map((labelDeclaration) => {
                                              const labelGeneratorDeclarationErrorMsg =
                                                labelDeclaration.definitionId !== undefined ?
                                                (
                                                  labelDeclaration.definitionId === null ?
                                                  'Missing Generator Definition' :
                                                  ''
                                                ) :
                                                '';
                                              const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                                                obj => (
                                                  obj.name.toLowerCase() !== 'language' ?
                                                  obj.error_type_text !== '' :
                                                  false
                                                )
                                              );
                                              const labelGeneratorDeclarationArgsErrorMsg =
                                                lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                                                labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                                                labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                                                '';

                                              const labelGeneratorDeclarationErrorStyle =
                                                labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                classes.generatorDeclarationError :
                                                '';
                                              return (
                                                <div
                                                  key={labelDeclaration.id}
                                                  className={labelGeneratorDeclarationErrorStyle}
                                                  style={{display: 'flex', marginTop: 4, width: 'fit-content'}}
                                                >
                                                  <div className={classes.generatorDeclarationIconWrapperStyle}>
                                                    <Tooltip
                                                      title={
                                                        <React.Fragment>
                                                          <GeneratorInfo
                                                            title="Label Generator"
                                                            patternTypeColors={patternTypeColors}
                                                            />
                                                        </React.Fragment>
                                                      }
                                                      placement="left"
                                                      enterDelay={500}
                                                      leaveDelay={200}
                                                    >
                                                      <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                                                    </Tooltip>
                                                  </div>
                                                  <div
                                                    style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}
                                                    className={
                                                      classNames(
                                                        classes.generatorDeclarationIsolationBorder,
                                                        labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                        classes.generatorDeclarationIsolationBorderError :
                                                        '',
                                                      )
                                                    }
                                                  >
                                                    <div>
                                                      <React.Fragment>
                                                        <Chip
                                                          classes={{
                                                            root: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                                  classes.generatorDeclarationErrorneousChip :
                                                                  classes.generatorDeclarationValidChip,
                                                            deleteIconSmall: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                                  classes.generatorDeclarationErrorneousDeleteIcon :
                                                                  classes.generatorDeclarationValidDeleteIcon,
                                                          }}
                                                          size="small"
                                                          variant="outlined"
                                                          label={labelDeclaration.name}
                                                          onClick={handleGoToGeneratorDeclarationForm({
                                                            declaration: labelDeclaration,
                                                            generatorType: 'label',
                                                            type: 'mapping',
                                                            generatorProps: {mappingId: mapping.id}
                                                          })}
                                                          color="primary"
                                                          onDelete={
                                                            this.props.handleShowConfirmDeleteSnackbar({
                                                              handleDelete: handleDirectDeleteLabelGeneratorDeclarations,
                                                              argument: {
                                                                labelGeneratorDeclarationsToBeDeleted: [labelDeclaration],
                                                                generatorProps: {mappingId: mapping.id}
                                                              }
                                                            })
                                                          }
                                                        />
                                                      </React.Fragment>
                                                    </div>
                                                    <div className={classes.patternWithValuesAndColorsWrapper}>
                                                      {
                                                      <PatternWithValuesAndColors declaration={labelDeclaration}
                                                                                  patternTypeColors={ patternTypeColors}/>
                                                      }
                                                    </div>
                                                  </div>
                                                  <GeneratorDeclarationError
                                                    generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                                                    errorMessage={
                                                      labelGeneratorDeclarationErrorMsg !== '' ?
                                                       labelGeneratorDeclarationErrorMsg :
                                                       (
                                                         labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                                         labelGeneratorDeclarationArgsErrorMsg :
                                                         ''
                                                       )
                                                     }
                                                  />
                                                </div>
                                              )
                                            }) :
                                            ''
                                          }
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                                <div style={{float:'right'}}>
                                  <Button color="primary"
                                          className={classes.button}
                                          onClick={handleAddConstExpr({mappingId: mapping.id})}>
                                    Additional
                                  </Button>
                                  <Button color="primary"
                                          className={classes.button}
                                          onClick={handleUseGenerator({mappingId: mapping.id})}>
                                    Generator
                                  </Button>
                                  <Button color="primary"
                                          className={classes.button}
                                          onClick={handleUseVariable({mappingId: mapping.id})}>
                                    Variable
                                  </Button>
                                  <Button color="primary"
                                          className={classes.button}
                                          onClick={handleUseInstanceInfo({mappingId: mapping.id})}>
                                    Instance Info
                                  </Button>
                                </div>
                              </div>
                              {/*Additional of Link*/}
                              <div>
                                <div className={classes.constExprNodesWrapper}
                                     style={{transition: 'all 300ms ease-in-out', display:'flex'}}>
                                     {/*style={{transition: 'all 300ms ease-in-out', display:'flex', height: mappingEditConstExprList.length*212}}*/}
                                  <TransitionGroup>
                                    {mappingEditConstExprList}
                                  </TransitionGroup>
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Collapse>
                  <Collapse
                    in={mapping.showCode} mountOnEnter unmountOnExit
                    timeout={{ enter: 400, exit: 400 }}
                    style={{flex: 1}}
                  >
                    <Typography variant="body1" style={{marginTop: -20, paddingBottom: 10}}>
                      Put the cursor on or inside a pair of tags to highlight them.
                      Press Ctrl-J to jump to the tag that matches the one under the
                      cursor.
                    </Typography>
                    <CodeMirror
                      editorDidMount={editor => editor.setSize('', 'auto')}
                      value={mapping.x3ml}
                      className="codemirrorCustomStyle"
                      options={{
                        mode: 'xml',
                        lineWrapping: true,
                        theme: 'material',
                        lineNumbers: true,
                        autoRefresh: { delay: 800, force: true },
                        matchTags: {bothTags: true},
                        extraKeys: {"Ctrl-J": "toMatchingTag"}
                      }}
                      onBeforeChange={(editor, data, value) => {
                        handleCodeMirrorInputChange({x3ml: value, mappingId: mappingId});
                      }}
                      onChange={(editor, data, value) => {}}
                    />
                    <div style={{display: 'flex', float:'right'}}>
                      <Button
                        color="primary"
                        className={classes.button}
                        onClick={this.handleShowConfirmationForApplyingFragmentCodeChanges}>
                          Save
                      </Button>
                      <Button
                        color="secondary"
                        className={classes.button}
                        onClick={this.handleConfirmOrToggleShowCode({mappingId: mappingId, showCode: false})}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Collapse>
                </AccordionDetails>
              </Accordion>

                {/* This is the list of "link" items */}
                <Container
                  dragHandleSelector=".drag-handle-link"
                  lockAxis="y"
                  onDrop={onDragDrop({mappingId: mappingId})}
                >
                {/*<TransitionGroup>*/}
                  {linkList}
                </Container>
            </div>
          </AccordionDetails>
          {
            canEdit ?
            <div>
              <Divider style={{marginTop:15}}/>
              <AccordionActions className={classes.inverseAlignActions}>
                <Button variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleAddLink({mappingId: mappingId})}>
                  Add New Link
                </Button>
              </AccordionActions>
            </div> :
            <AccordionActions />
          }
        </Accordion>

        {/*confirm to apply Domain fragment code changes*/}
        <ConfirmSnackbar
          open={this.state.confirmImportFragmentCodeSnackbarShown}
          onClose={this.handleCloseImportFragmentCodeSnackbar}
          type="warning"
          msg={'Are you sure you want to apply the changes performed on the domain fragment Code?'}
          onConfirmClick={this.handleApplyCodeFragmentChanges({mappingId: mappingId})}
          confirmButtonLabel="Yes apply the changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseImportFragmentCodeSnackbar}
        />

        {/*confirm to Close the CodeMirror editor for fragment codes of Domain or Link*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseFragmentCodeSnackbarShown}
          onClose={this.handleCloseConfirmCloseFragmentCodeSnackbar}
          type="warning"
          msg={'There are changes made in the fragment code editor. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseFragmentCode({mappingId: mappingId})}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseFragmentCodeSnackbar}
        />

      </div>
    );
  }
}

MappingComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MappingComponent);
