import axios from 'axios';
import configuration from '../configuration.json';

const rootContextPath = configuration.rootContextPath;

export class SecurityService {

  // JWT Login
  async login(data) {
    return await axios(rootContextPath + '/performLogin', {
      method: 'post',
      data: data,
    });
  }

  /*
  Retrieves session timeout set on the server (property file)
  */
  async getSessionTimeout() {
     return await axios(rootContextPath + '/getSessionTimeout', {
      method: 'get',
    });
  }

  async getUserInformation() {
     return await axios(rootContextPath + '/getUsername', {
      method: 'get',
    });
  }

  // async isAuthenticated() {
  //    return await axios(rootContextPath + '/isAuthenticated', {
  //     method: 'get',
  //   });
  // }

  async registerNewUser(data) {
     return await axios(rootContextPath + '/registerNewUser', {
      method: 'post',
      data: data,
    });
  }

  async forgotPasswordRequest(data) {
     return await axios(rootContextPath + '/email/forgotPasswordRequest', {
      method: 'post',
      data: data,
    });
  }

  async sendCredentialsEmail(data) {
     return await axios(rootContextPath + '/email/sendCredentialsEmail', {
      method: 'post',
      data: data,
    });
  }

}
