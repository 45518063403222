import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  circularWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  circularUnderStyle: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  circularOnTopStyle: {
    color: theme.palette.primary.main,
    position: 'absolute',
    left: 0,
  },
});

export class DonutChartProgress extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.value !== nextProps.value) {
      return true;
    }
    if(this.props.fillInternalCircle !== nextProps.fillInternalCircle) {
      return true;
    }
    if(this.props.inputProps !== nextProps.inputProps) {
      return true;
    }
    if(this.props.size !== nextProps.size) {
      return true;
    }
    if(this.props.thickness !== nextProps.thickness) {
      return true;
    }
    if(this.props.variant !== nextProps.variant) {
      return true;
    }
    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  render() {
    const { classes, value, fillInternalCircle, inputProps, size, thickness, variant, } = this.props;

    return (
      <div
        className={
          inputProps.classes.root !== undefined ?
          inputProps.classes.root :
          classes.root
        }
      >
        <Box position="relative" display="inline-flex">
          <div className={classes.circularWrapper}
            className={
              inputProps.classes.circularWrapper !== undefined ?
              inputProps.classes.circularWrapper :
              classes.circularWrapper
            }
          >
            <CircularProgress
              variant="determinate"
              value={100}
              size={size !== undefined ? size : 30}
              thickness={thickness !== undefined ? thickness : 5}
              className={classes.circularUnderStyle}
            />
            <CircularProgress
              variant="determinate"
              value={Math.round(value)}
              size={size !== undefined ? size : 30}
              thickness={thickness !== undefined ? thickness : 5}
              className={classes.circularOnTopStyle}
            />
          </div>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={
              fillInternalCircle !== undefined ?
              (
                fillInternalCircle ?
                inputProps.classes.internalCircle :
                ''
              ) :
              ''
            }
          >
            <Typography
              variant={variant !== undefined ? variant : 'caption'}
              component="div"
              color="textSecondary"
              className={
                Math.round(value) === 100 ?
                (
                  inputProps.classes.completeCoverage !== undefined ?
                  inputProps.classes.completeCoverage :
                  ''
                ) :
                ''
              }
            >
              {`${Math.round(value,)}%`}
            </Typography>
          </Box>
        </Box>
      </div>
    )
  }
}

DonutChartProgress.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DonutChartProgress);
