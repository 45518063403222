import axios from 'axios';
import configuration from '../configuration.json';

const rootContextPath = configuration.rootContextPath;

export class CheckDbService {

  /*
  Retrieving the Generator Definitions
  */
  async checkIfThereIsDbAvailable() {
     return await axios(rootContextPath + '/checkdb/checkIfThereIsDbAvailable', {
      method: 'get',
    });
  }

  async constructNewMinimalDb(data) {
     return await axios(rootContextPath + '/checkdb/constructNewMinimalDb', {
      method: 'post',
      data: data,
    });
  }
}
