import React, { Component } from 'react';
import {
  Route,
  //NavLink,
  //BrowserRouter,
  //Switch,
  //HashRouter,
} from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import Form from './components/authentication/Form';
import Registration from './components/authentication/Registration';
import DefineNewAdministartorUser from './components/authentication/DefineNewAdministartorUser';
import NewCredentialsGenerator from './components/authentication/NewCredentialsGenerator';
import IdleTimer from 'react-idle-timer';
import RootAuthComp from './components/RootAuthComp';
import TimeoutDialog from './components/authentication/TimeoutDialog';
import CustomSnackMessage from './components/snackbar/CustomSnackMessage';
import { UserProfileService } from './components/services/UserProfileService';
import { SecurityService } from './components/services/SecurityService';
import { CheckDbService } from './components/services/CheckDbService';
import { D4ScieneceSecurityService } from './components/services/D4ScieneceSecurityService';

import configuration from './components/configuration.json';
import Cookies from 'js-cookie';

const rootContextPath = configuration.rootContextPath;

// To be used from D4Science when appropriate
let d4ScieneceToken = undefined;

// Case D4Science mode. If that condition fails, it works as standalone mode.
if(getUrlParameter('gcube-token', document.URL)) {
  d4ScieneceToken = getUrlParameter('gcube-token', document.URL);
}

// Getting query parameter from the "src" a URL string
function getUrlParameter (name, url) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(url);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const inputs = [{
  name: "username",
  placeholder: "username",
  type: "text"
},{
  name: "password",
  placeholder: "password",
  type: "password"
},{
  type: "submit",
  value: "Submit",
  className: "btn"
}]

const props = {
  name: 'loginForm',
  method: 'POST',
  action: rootContextPath + '/performLogin',
  inputs: inputs
}


const userProfileService = new UserProfileService();
const securityService = new SecurityService();
const checkDbService = new CheckDbService();
const d4ScieneceSecurityService  = new D4ScieneceSecurityService();

const params = new URLSearchParams(window.location.search)
const secs = 59;

class App extends Component {

  state = {
    currUser: {
      loggedIn: false,
    },
    numberOfClicks: 0,
    timeoutDialogOpen: false,
    seconds: secs,
    sessionTimeout: 0,
    //timeoutInMilliseconds: (1000 * 60 * 20) - (1000 * 60),
    timeoutInMilliseconds: (1000 * 60 * 175) - (1000 * 60),
    // Construct new Database
    defineNewAdminUserDialogOpen: false,
    newAdminUserDialogFullScreen: false,
    d4ScieneceEnabled: false,
  }

  async componentDidMount()  {

    let currUser = await this.checkIfAuthed();
    if(currUser.loggedIn) {
      await this.getSessionTimeout();
    }
    else {
      // Check for D4Science and login
      currUser = await this.checkIfD4ScieneceIsEnabledAndAct();
      if(currUser.loggedIn) {
        await this.getSessionTimeout();
      }
    }

    // Checking if there is database available
    await this.checkIfThereIsDbAvailable();
  }

  // Checks whether D4Scienece is enabled and if true then login by D4Scienece mechanism
  checkIfD4ScieneceIsEnabledAndAct = async () => {
    let currUser = {...this.state.currUser};
    this.setState({
      d4ScieneceEnabled: d4ScieneceToken !== undefined ? true : false,
    }, async () => {
      if(!currUser.loggedIn && this.state.d4ScieneceEnabled) {
        currUser = await this.handleD4ScieneceLogin();
      }
    });
    return currUser;
  }

  // To emulate iframe of D4Science use the URL at the address bar:
  // http://localhost:3000/3m?gcube-token=<3M_TOKEN>
  handleD4ScieneceLogin = async () => {
    let currUser = {...this.state.currUser};
    // Check if this is case of D4Scienece
    if(d4ScieneceToken !== undefined) {
      // Loging by using D4Scienece Token
      try {
        const res = await d4ScieneceSecurityService.d4ScieneceLogin({token: d4ScieneceToken});
        if(res.data.succeed) {
          const threeMToken = res.data.token; 
          //Cookies.set('3mToken', threeMToken,);                                      // Development
          Cookies.set('3mToken', threeMToken, { sameSite: 'none', secure: true }); // Production
          
          currUser.loggedIn = true;
          currUser.currUsername = res.data.username;
          this.setState({
            currUser: currUser
          });
        }
        else {
          console.error('Some error occured while attempting to loging through the D4Science service.');
          this.showErrorSnackBar({msg: 'Some error occured while attempting to loging through the D4Science service.', iconSize: 'large'});
          currUser.loggedIn = false;
          this.setState({
            currUser: currUser
          });
        }
      }
      catch (e) {
        console.error('Some error occured while attempting to loging through the D4Science service.');
        this.showErrorSnackBar({msg: 'Some error occured while attempting to loging through the D4Science service.', iconSize: 'large'});
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
        currUser.loggedIn = false;
        this.setState({
          currUser: currUser
        });
      }
      return currUser;
    }
  }

  checkIfThereIsDbAvailable = async () => {
    let count = 0;
    try {
      const res = await checkDbService.checkIfThereIsDbAvailable();
      console.log(res.data);
      if(res.data.succeed) {
        if(res.data.userCount > 0) {
          // There is database with users
        }
        else {
          // There is no database or the user table is empty
          this.handleOpenNewAdminUserDialog();
        }
      }
    }
    catch (e) {
      console.error('Some error occured while trying to check the database availability');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      this.showErrorSnackBar({msg: 'Some error occured while trying to check the database availability', iconSize: 'large'});
    }
  }

  getSessionTimeout = async () => {
    try {
      const res = await securityService.getSessionTimeout();
      if(res.data.sessionTimeout !== undefined) {
        let timeout = 1000 * 10;
        // Minutes
        if(res.data.sessionTimeout.substr(res.data.sessionTimeout.length - 1) === 'm') {
          timeout = res.data.sessionTimeout.substr(0, res.data.sessionTimeout.length - 1) * 60 * 1000;
        }
        // Secconds
        else if(res.data.sessionTimeout.substr(res.data.sessionTimeout.length - 1) === 's') {
          timeout = res.data.sessionTimeout.substr(0, res.data.sessionTimeout.length - 1) * 1000;
        }
        // Secconds
        else {
          timeout = res.data.sessionTimeout.substr(0, res.data.sessionTimeout.length) * 1000;
        }
        this.setState({
          sessionTimeout: res.data.sessionTimeout,
          timeoutInMilliseconds: timeout - (1000 * 60),
        }, function() {
          console.log('sessionTimeout:');
          console.log(this.state.sessionTimeout);
        });
      }
      else {
        this.setState({
          sessionTimeout: 100
        }, function() {
          this.showErrorSnackBar({msg: 'Some error occured while trying to communicate with the server.', iconSize: 'large'});
        });
      }
    } catch (e) {
      console.error('Failure!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      this.showErrorSnackBar({msg: 'Some error occured.', iconSize: 'large'});
    }
  }

  // To be used from other components to logout if
  // determined that the user is no longer authendicated
  updateAuthStatusOfCurrUser = (updatedAuthStatus) => {
    this.setState(state => ({
      currUser: {
        ...this.state.currUser,
        loggedIn: updatedAuthStatus,
      }
    }));

  }

  checkIfAuthed = async () => {
    let currUser = {...this.state.currUser};
    try {
      const res = await userProfileService.isUserAuthenticated();
      if(res.data.authenticated !== false) {
        currUser.loggedIn = res.data.authenticated;
        currUser.currUsername = res.data.username;
        this.setState({
          currUser: currUser
        });
      }

      else {
        currUser.loggedIn = false;
        this.setState({
          currUser: currUser
        });

        // When logged out allow only viewing the login, Registration and NewCredentialsGenerator 
        // pages, otherwise the login page will be loaded
        if(this.props.history.location.pathname !== rootContextPath + '/Registration' &&
           this.props.history.location.pathname !== rootContextPath + '/NewCredentialsGenerator') {
          this.props.history.push(rootContextPath + '/');
        }
      }
    } catch (e) {
      console.error('Failure!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      currUser.loggedIn = false;
      this.setState({
        currUser: currUser
      });
    }
    return currUser;
  }

  invalidateSession = async (username) => {
    try {
      await userProfileService.invalidateSession();
    }
    catch (e) {
      console.error('Some error occured while trying to invalidate the session.');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }  
  }

  handleLogout = async () => {
    // Session invalidation has to be done prior to state setting , otherwise
    // it will set again the current user in RootAuth component, through the useEffect listenning everychange
    // since the regulating condition, which is that the user is authendicated will still be true
    await this.invalidateSession(); 

    const token = Cookies.get('3mToken');
    if(token) {
        Cookies.remove('3mToken');
    }
    //navigate(rootContextPath + '/'); // Home
    await this.checkIfAuthed();
  }

  onAction = event => {
    //console.log('user did something', event)
  }

  onActive = event => {
    console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime());
    // Stop interval
    this.setState({
      timeoutDialogOpen: false,
    }, function() {
      clearInterval(this.interval);
      this.checkIfAuthed();
    });
  }

  onIdle = event => {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())

    this.setState({
      timeoutDialogOpen: true,
      seconds: secs,
    }, function() {
      // Start interval
      this.interval = setInterval(() => this.tick(), 1000);
    });
  }

  tick = () => {
    if(this.state.seconds > 0) {
      this.setState(prevState => ({
        seconds: prevState.seconds - 1
      }));
    }
    else {
      // Stop interval
      this.setState({
        timeoutDialogOpen: false,
      }, function() {
        clearInterval(this.interval);
        this.setState({
          currUser: {
            ...this.state.currUser,
            loggedIn: false
          }
        }, function() {
          this.handleLogout();
          this.showInfoSnackBar({msg: 'You have been logged out because your session was expired', iconSize: 'small'});
        });
      });
    }
  }

  showInfoSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'info'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}/>
        ),
      }
    );
  }

  showSuccessSnackBar = props => {
    this.props.enqueueSnackbar(
      props.msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500
      }
    );
  }

  showErrorSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'error'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}/>
        ),
      }
    );
  }

  // Construct new database related functions
  handleOpenNewAdminUserDialog = () => {
    this.setState({
        defineNewAdminUserDialogOpen: true,
    });
  }

  handleCloseNewAdminUserDialog = () => {
    this.setState({
        defineNewAdminUserDialogOpen: false,
    });
  }

  handleNewAdminUserDialogToggleFullScreen = () => {
    this.setState({
      newAdminUserDialogFullScreen: !this.state.newAdminUserDialogFullScreen
    });
  }

  // props: {user: <Object>}
  handleSaveAdminAndConstructNewDb = props => () => {
    console.log('handleSaveAdminAndConstructNewDb')
  }

  render() {
    if(this.state.currUser.loggedIn) {
      return (
        <div>
          <IdleTimer ref={ref => { this.idleTimer = ref }}
                     element={document}
                     onActive={this.onActive}
                     onIdle={this.onIdle}
                     onAction={this.onAction}
                     debounce={250}
                     timeout={this.state.timeoutInMilliseconds}/>
                     {/*1000 * 60 * 20*/}
          <RootAuthComp 
            currUser={this.state.currUser}
            checkIfAuthed={this.checkIfAuthed}
            updateAuthStatusOfCurrUser={this.updateAuthStatusOfCurrUser}
            handleLogout={this.handleLogout}
          />
                        {/*handleSetGeneratorDialogTypeColorCallBack={this.handleSetGeneratorDialogTypeColorCallBack}*/}

          <TimeoutDialog open={this.state.timeoutDialogOpen}
                         seconds={this.state.seconds}/>

        </div>
      );
    }

    else {

      //const LoginForm = <Form {...props} error={params.get('error')} />;

      return (
        <div style={{margin:10}}>
          <div></div>
          <div className="content">
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
            >
              {/* <Route exact={true} path={rootContextPath + '/'} component={() => <Form {...props} error={params.get('error')} />}/> */}
              <Route exact={true} path={rootContextPath + '/'} component={() => <Form {...props} error={params.get('error')} />}/>
              <Route path={rootContextPath + '/Registration'} component={Registration} history={this.props.history}/>
              <Route path={rootContextPath + '/NewCredentialsGenerator'} component={NewCredentialsGenerator} history={this.props.history}/>
            </AnimatedSwitch>
          </div>
          <DefineNewAdministartorUser
            dialogOpen={this.state.defineNewAdminUserDialogOpen}
            handleCloseDialog={this.handleCloseNewAdminUserDialog}
            fullScreen={this.state.newAdminUserDialogFullScreen}
            handleOnToggleFullScreen={this.handleNewAdminUserDialogToggleFullScreen}
            handleSave={this.handleSaveAdminAndConstructNewDb}
            showSuccessSnackBar={this.showSuccessSnackBar}
            showErrorSnackBar={this.showErrorSnackBar}
          />
        </div>
      );
    }
  }

}

//export default App;
//export default withRouter(App)
export default compose(withRouter, withSnackbar)(App); // import compose from 'recompose/compose';
