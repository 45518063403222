import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from './Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CustomSnackMessage from './snackbar/CustomSnackMessage';
import { UserProfileService } from './services/UserProfileService';
import { MainService } from './services/MainService';
import { CSSTransition } from 'react-transition-group';
import UserInfo from './userProfile/UserInfo';
import ConfirmSnackMessage from './snackbar/ConfirmSnackMessage';
import RichEditorDialog from './ManageUsers/RichEditorDialog';
import configuration from './configuration.json';

const userProfileService = new UserProfileService();
const mainService = new MainService();

const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)
const rootContextPath = configuration.rootContextPath; // (/3m);

const styles = theme => ({
  card: {
    margin: '10px',
  },
  summaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  expansionPanelDetailsStyle: {
    marginTop: -24,
    //padding: '0px 24px',
  },
  rightCardContent: {
    //position: 'absolute',
    //width: 225,
  },
  roleListStyle: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 488px)',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    //overflowX: 'auto',
    overflow: 'auto',
    height: 'calc(100vh - 226px)',
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.light + ' !important',
  },
  onlineCell: {
    color: theme.palette.primary.main
  },
  offlineCell: {
    color: theme.palette.secondary.main
  },
  confirmWithOptionsRoot: {
    width: 1000,
    backroundColor: 'green',
  },
  // primaryCode: {
  //   color: theme.palette.primary.main,
  //   backgroundColor: '#f9f2f4',
  //   borderRadius: 4,
  //   padding: '2px 4px',
  //   fontSize: '90%',
  // },
  primaryTextButton: {
    padding: '0px 4px',
    minWidth: 0,
    fontStyle: 'italic',
  }
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'all', numeric: false, boolean: false, list: false, disablePadding: false, useInAllFilter: false, headerPresence: false, label: 'All'},
  { id: 'online', numeric: false, boolean: true, list: false, disablePadding: true, useInAllFilter: false, headerPresence: true, label: 'Online'},
  { id: 'avatar', numeric: false, boolean: false, list: false, disablePadding: true, useInAllFilter: false, headerPresence: true, label: 'Avatar'},
  { id: 'username', numeric: false, boolean: false, list: false, disablePadding: true, useInAllFilter: true, headerPresence: true, label: 'Username'},
  { id: 'email', numeric: false, boolean: false, list: false, disablePadding: true, useInAllFilter: true, headerPresence: true, label: 'E-mail'},
  { id: 'firstname', numeric: false, boolean: false, list: false, disablePadding: true, useInAllFilter: true, headerPresence: true, label: 'First Name'},
  { id: 'lastname', numeric: false, boolean: false, list: false, disablePadding: true, useInAllFilter: true, headerPresence: true, label: 'Last Name'},
  { id: 'enabled', numeric: false, boolean: true, list: false, disablePadding: true, useInAllFilter: false, headerPresence: true, label: 'Enabled'},
  { id: 'roles', numeric: false, boolean: false, list: true, disablePadding: true, useInAllFilter: true, headerPresence: true, label: 'Role(s)'},
];

class UserTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  // To add filter check the video:
  // https://www.youtube.com/watch?v=SX_IL7LqSxM

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, showSelectAllCheckbox } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" style={{height: 42, width: 52}}>
            <CSSTransition in={showSelectAllCheckbox}
                           timeout={200}
                           classNames="revealInOutInitHidden">
              <div className="revealInOutInitHidden">
                <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={numSelected === rowCount}
                          onChange={onSelectAllClick}
                          hidden={!showSelectAllCheckbox}
                />
              </div>
            </CSSTransition>

          </TableCell>
          {rows.filter(row => row.headerPresence).map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

UserTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};





const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
    marginBottom: 10,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let UserTableToolbar = props => {
  const {
    numSelected, deleteModeActive, handleShowConfirmDeleteSnackbar, handleDeleteSelected, handleInputChange, handleSelectInputChange,
    searchText, columnToBeFiltered, classes
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root,{
        [classes.highlight]: deleteModeActive,
      })}
    >
      <div className={classes.title}>
        {deleteModeActive ? (
          <Typography color="inherit" variant="body1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6">
            User Management - Users
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {deleteModeActive ? (
          <Tooltip title="Delete">
            <div>
              <IconButton
                aria-label="Delete"
                color="secondary"
                onClick={
                  handleShowConfirmDeleteSnackbar({
                    handleDelete: handleDeleteSelected,
                  })
                }
                disabled={numSelected === 0}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <div style={{display: 'flex'}}>
            <div style={{paddingRight: 10, marginTop: -12}}>
              <TextField
                label="Search"
                onChange={handleInputChange('searchText')}
                value={searchText}
                disabled={columnToBeFiltered === undefined || columnToBeFiltered === null}
              />
            </div>
            <div style={{width: 320}}>
              <Autocomplete
                placeholder={'Select Column Filter'}
                options={
                  rows
                  .filter(item => item.id !== 'avatar')
                  .filter(item => item.boolean !== true)
                }
                value={columnToBeFiltered}
                onChange={handleSelectInputChange('columnToBeFiltered')}
                async={false}
                isMulti={false}
              />

            </div>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

UserTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

UserTableToolbar = withStyles(toolbarStyles)(UserTableToolbar);

export class ManageUsers extends React.Component {

  state = {
    selectedUser: {
      username: '',
      password: '',
      email:'',
      firstname: '',
      lastname: '',
      enabled: false,
      roles:[]
    },
    order: 'asc',
    orderBy: 'calories',
    selected: [],
    data: [],
    deleteModeActive: false,
    page: 0,
    rowsPerPage: 20,
    expandedPanel: 'information',
    availableUserRoles: ['administrator', 'regular', 'user_manager'],
    searchText: '',
    isLoadingNewAuthorOptions: false,
    allEnabledUsers: [],
    newAuthorSelectedItem: '', // i.e. {label: 'fullname (username)', item: {<User>}}
    loadingActivityInfo: true,
    selectedUserMembershipsCount: 0,
    selectedUserAuthorshipsCount: 0,
    enabledInitValueOfSelected: false,
    showRichEditorDialog: false,
    richEditorFullScreen: false,
    richContent: '',
    richTitle: '',
    richTitle_error_text: '',
    sendEmailIsLoading: false,
    columnToBeFiltered: rows[0],
  }
  /*
  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }
  */

  async componentDidMount() {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.11.1/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    await this.handleLoadUserList();
    await this.handleLoadOnlineUserList();
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.jsonPackage !== prevProps.jsonPackage) {
      this.handleUserStatusChange(this.props.jsonPackage);
    }
    if (this.props.connected !== prevProps.connected) {
      if (this.props.connected) {
        await this.handleLoadOnlineUserList();
      }
    }
  }

  handleLoadOnlineUserList = async () => {
    let res = await userProfileService.retrieveAllOnlineUsernames();
    this.setState({
        onlineUsernames: res.data,
    });
  }

  // WebSocket Related
  // Handling package received from others
  handleUserStatusChange = jsonPackage => {
    if(jsonPackage !== undefined && jsonPackage !== null ) {
      if(jsonPackage.type !== undefined && jsonPackage.type !== null ) {
        if(jsonPackage.type === 'onlineUserStatus') {

          // Connected
          let onlineUsernames = this.state.onlineUsernames.slice(); // Create a copy

          if(jsonPackage.isOnline) {
            if(onlineUsernames.findIndex(username => username === jsonPackage.username) === -1) {
              onlineUsernames.push(jsonPackage.username);
            }
          }
          // Disconnected
          else {
            const index = onlineUsernames.findIndex(username => username === jsonPackage.username);
            if(index !== -1) {
              onlineUsernames.splice(index, 1);
            }
          }
          this.setState({
              onlineUsernames: onlineUsernames,
          });
        }
      }
    }
  }

  // Determine if the passed username represents a connected user and return boolean value
  checkIfUserIsOnlineByUsername = username => {
    // Case this is the current user
    if(username === this.props.currUser.username) {
      return this.props.connected;
    }
    // Case this is not the current user
    else {
      return this.state.onlineUsernames !== undefined ?
        (
         this.state.onlineUsernames.findIndex(tmpUsername => tmpUsername === username) !== -1 ?
         true : false
        ) :
        false
    }
  }

  handleLoadUserList = async () => {
    let users = await userProfileService.retrieveAllUsers();
    for (let user of users.data) {
      delete user.password;
      delete user.userConfigurationId;
    }
    this.setState({
        data: users.data,
    });
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({
        selected: state.data.map(user => user.id)
      }));
      return;
    }
    this.setState({
      selected: []
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = (event, user) => {
    // Handle multiple clicks if the edit mode is active
    if(this.state.deleteModeActive) {
      const { selected } = this.state;
      const selectedIndex = selected.indexOf(user.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, user.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      this.setState({
        selected: newSelected
      });
    }

    // Handle single selected
    else {
      this.setState({
        selected: [],
      }, function () {
        let newSelected = [];
        newSelected = newSelected.concat(this.state.selected, user.id);
        this.setState({
          selected: newSelected,
        }, async function() {
          const retrieveUserRes = await userProfileService.retrieveUserById({id: user.id});
          const selectedUser = retrieveUserRes.data;
          this.setState({
            selectedUser: selectedUser,
            enabledInitValueOfSelected: selectedUser.enabled,
          }, async () => {
            if(this.state.expandedPanel==='activityInfo') {
              this.setState({
                  loadingActivityInfo: true,
              }, async () => {
                const countMemberships = await this.countUserMemberships(this.state.selectedUser);
                const countAuthorships = await this.countUserAuthorships(this.state.selectedUser);
                this.setState({
                    selectedUserMembershipsCount: countMemberships,
                    selectedUserAuthorshipsCount: countAuthorships,
                }, () => {
                  this.setState({
                      loadingActivityInfo: false,
                  });
                });
              });
            }
          });
        });
      });
    }
  };

  handleChange = name => event => {
    let value = '';
    if(name === 'enabled') {
      value = event.target.checked;
    }
    else {
      value = event.target.value;
    }
    // Username
    if(name === 'username') {
      if (event.target.value === '') {
        this.setState({
            username_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            username_error_text: '',
        });
      }
    }
    // email
    if(name === 'email') {
      var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (event.target.value === '') {
        this.setState({
            email_error_text: 'This field is required',
        });
      }
      else if (!emailRegEx.test(event.target.value)) {
        this.setState({
            email_error_text: 'This email has not a valid form',
        });
      }
      else {
        this.setState({
            email_error_text: '',
        });
      }
    }
    this.setState({
      selectedUser: {
        ...this.state.selectedUser,
        [name]: value,
      }
    });
  };

  handleSaveUser = user => async () => {
    if(this.userIsValid(this.state.user)) {
      try {
        let res = await userProfileService.saveUser(user);
        if(res.data.succeed) {
          this.props.showSuccessSnackBar({msg: res.data.msg});
          // Reloading list of users
          await this.handleLoadUserList();
          // Ask to send email in case that this user was just enabled
          if(this.state.enabledInitValueOfSelected === false && user.enabled === true) {
            this.showConfirmSnackBar({
              msg:  'Would you like to send an email to user \"' + user.firstname + ' ' + user.lastname + '\", with details of how to access the system?',
              iconSize: 'large',
              onConfirmClick: this.sendAccessGrantedEmailToUser,
              confirmButtonLabel: 'Yes please',
              onCancelClick: this.handleCloseConfirmSnackbar,
              cancelButtonLabel: 'Don\'t Send',
              argument: {user: user},
            });
          }
          this.setState({
            enabledInitValueOfSelected: this.state.selectedUser.enabled,
          });
        }
        else {
          this.props.showErrorSnackBar({msg: res.data.msg});
        }
      }
      catch (e) {
        console.error('Some error occured while persisting the user into the database.');
        this.props.showErrorSnackBar({msg: 'Some error occured while persisting the user into the database.', iconSize: 'large'});
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
    else {
      this.props.showErrorSnackBar({msg: 'There are errors in the form. Please fix them and try again.', iconSize: 'large'});
    }
  };

  // props: {user: <Object>}
  sendAccessGrantedEmailToUser = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    this.handleShowRichEditorDialog();
  }

  userIsValid = () => {
    let valid = true;
    if(valid) {
      // Username
      if(this.state.selectedUser.username === '') {
        this.setState({
            username_error_text: 'This field is required',
        });
        valid = false;
      }
      else {
        this.setState({
            username_error_text: '',
        });
        valid = true;
      }
    }
    if(valid) {
      // Email
      if(this.state.selectedUser.email === '') {
        this.setState({
            email_error_text: 'This field is required',
        });
        valid = false;
      }
      else {
        this.setState({
            email_error_text: '',
        });
        valid = true;
      }
    }
    return valid;
  }

  handleExpandedChange = expandedPanel => (event, isExpanded) => {
    this.setState({
        expandedPanel: isExpanded ? expandedPanel : '',
    }, () => {
      if(isExpanded ? expandedPanel==='activityInfo' : false) {
        this.setState({
            loadingActivityInfo: true,
        }, async () => {
          const countMemberships = await this.countUserMemberships(this.state.selectedUser);
          const countAuthorships = await this.countUserAuthorships(this.state.selectedUser);
          this.setState({
              selectedUserMembershipsCount: countMemberships,
              selectedUserAuthorshipsCount: countAuthorships,
          }, () => {
            this.setState({
                loadingActivityInfo: false,
            });
          });
        });
      }
    });
  }

  countUserMemberships = async user => {
    try {
      const res = await mainService.countMappingProjectsByMemberId({userId: user.id});
      const countMemberships = res.data;
      return countMemberships;
    }
    catch (e) {
      console.error('Some error occured while counting user\'s memberships in mapping projects');
      this.props.showErrorSnackBar({msg: 'Some error occured while counting user\'s memberships in mapping projects'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }
  countUserAuthorships = async user => {
    try {
      const res = await mainService.countMappingProjectsByAuthorId({userId: user.id});
      const countAuthorships = res.data;
      return countAuthorships;
    }
    catch (e) {
      console.error('Some error occured while counting user\'s authorships in mapping projects');
      this.props.showErrorSnackBar({msg: 'Some error occured while counting user\'s memberships in mapping projects'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  handleToggleRoleCheckbox = role => () => {
    const currentIndex = this.state.selectedUser.roles.indexOf(role);
    const newRoles = [...this.state.selectedUser.roles];

    if (currentIndex === -1) {
      newRoles.push(role);
    } else {
      newRoles.splice(currentIndex, 1);
    }
    this.setState({
      selectedUser: {
        ...this.state.selectedUser,
        roles: newRoles,
      }
    });
  };

  handleDeleteModeChange = event => {
    this.setState({
      deleteModeActive: event.target.checked,
    });
  };

  handleDeleteSelected = props => async () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    // First, check them out if they are involved to any mapping
    await this.checkSelectedUsersMembershipInMappingProjectsCallBack();
    //await this.handleDeleteSelectedCallBack();
  }

  checkSelectedUsersMembershipInMappingProjectsCallBack = async () => {
    let atLeastOneUserIsMemberOfOneMappingProject = false;
    let countMemberships = 0;
    try {
      for (const userId of this.state.selected) {
        const res = await mainService.countMappingProjectsByMemberId({userId: userId});
        countMemberships = res.data;
        atLeastOneUserIsMemberOfOneMappingProject =
          atLeastOneUserIsMemberOfOneMappingProject ?
          true :
          countMemberships > 0;
      }
      if(atLeastOneUserIsMemberOfOneMappingProject) {
        this.showConfirmSnackBar({
          msg:  this.state.selected.length === 1 ?
               'This user appears to be <strong>"member"</strong> of <strong>' + countMemberships + '</strong> ' +
               'mapping projects (has access to edit them).<br/>Do you still wnt to procceed?' :
               'It appears that one or more of the users you want to delete, are <strong>members</strong> of one ' +
               'or more mappings (have access to edit them).<br/>' +
               'Do you still want to procceed deleting these users (the associated mappings will remain untouched)?',
          iconSize: 'large',
          onConfirmClick: this.checkSelectedUsersAuthorshipInMappingProjects,
          confirmButtonLabel: 'Yes proceed',
          onCancelClick: this.handleCloseConfirmSnackbar,
          cancelButtonLabel: 'Cancel',
          argument: {},
        });
      }
      else {
        await this.checkSelectedUsersAuthorshipInMappingProjectsCallBack();
      }
    }
    catch (e) {
      console.error('Some error occured while investigating the membership of the selected users in mapping projects.');
      this.props.showErrorSnackBar({msg: 'Some error occured while investigating the membership of the selected users in mapping projects.'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  checkSelectedUsersAuthorshipInMappingProjects = props => async () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    this.checkSelectedUsersAuthorshipInMappingProjectsCallBack();
  }

  checkSelectedUsersAuthorshipInMappingProjectsCallBack = async () => {
    let atLeastOneUserIsAuthorOfOneMappingProject = false;
    let countAuthorships = 0;
    try {
      for (const userId of this.state.selected) {
        const res = await mainService.countMappingProjectsByAuthorId({userId: userId});
        countAuthorships = res.data;
        atLeastOneUserIsAuthorOfOneMappingProject =
          atLeastOneUserIsAuthorOfOneMappingProject ?
          true :
          countAuthorships > 0;
      }
      if(atLeastOneUserIsAuthorOfOneMappingProject) {
        await this.retrieveAllEnabledUsersCallback();
        this.showConfirmSnackBar({
          msg:  this.state.selected.length === 1 ?
                'This user appears to be <strong>"author"</strong> of <strong>' + countAuthorships + '</strong> ' +
                'mapping projects.<br/>' +
                'Do you still want to proceed? If yes please select some new author for the associated mapping projects first.<br/>' +
                '(By proceeding to the deletion, the associated mapping projects will remain untouched appart from their ' +
                'author which will be changed).' :
                'It appears that one or more users you want to delete, are <strong>authors</strong> of one or more mappings.<br/>' +
                'Do you still want to proceed? If yes please select a new author for the associated mapping projects first.<br>' +
                '(By proceeding to the deletion, the associated mapping projects will remain untouched appart from their ' +
                'author which will be changed).',
          iconSize: 'large',
          onConfirmClick: this.handleConfirmDeleteSelected,
          confirmButtonLabel: 'Yes proceed',
          onCancelClick: this.handleCloseConfirmSnackbar,
          cancelButtonLabel: 'Cancel',
          argument: {},
          showOptionsToChoose: true,
          options: this.state.allEnabledUsers.filter(user => this.state.selected.indexOf(user.id) === -1),
          label: 'New Author',
          placeholder: 'Please select one',
          handleInputChange: this.handleNewAuthorInputChangeCallBack,
          selectedItem: this.state.newAuthorSelectedItem
        });
      }
      else {
        await this.handleConfirmDeleteSelectedCallBack();
      }
    }
    catch (e) {
      console.error('Some error occured while investigating the selected users for any involvement in mappings.');
      this.props.showErrorSnackBar({msg: 'Some error occured while investigating the selected users for any involvement in mappings.'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // props: {user: <Object>, type: 'authorship | membership'}
  handleListMappingProjects = props => async () => {
    const type = props.type;
    const user = props.user;
    if(user.id !== undefined) {
      props.fromManagement = true;
      this.props.history.push({
        pathname: rootContextPath + '/ProjectsForUser',
        state: props,
      });
    }
  }

  handleConfirmDeleteSelected = props => async () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    this.handleConfirmDeleteSelectedCallBack();
  }
  handleConfirmDeleteSelectedCallBack = async () => {
    // this.props.showErrorSnackBar({
    //   msg:  'ToDo:<br/>' +
    //         'Use Selected ("this.state.newAuthorSelectedItem.username"): ' + this.state.newAuthorSelectedItem.username + '</br>' +
    //         'If procceed, then iterate deleted users, for each of them retrieve the mapping projects where they are members and remove them from the membership list<br/>' +
    //         'Then retrieve the mapping projects where they are authors and set as author the one seleceted at the last worning OR  <br/>' +
    //         'Iterate the users to be deleted and for each of them retrieve the mapping projects where they are member or author (participating mappings) and then either remove from the list of members or set new author or both<br/>' +
    //         'Then finaly delete each user <br/>' +
    //         'ERROR: the this.state.newAuthorSelectedItem --> passed as selectedItem to the ConfirmSnacMessage component, is not properly rendered. It disappears after clicking awway and displayes the previous value.<br/>' +
    //         'Also if many users are checked, reset the displayed user.<br/>' +
    //         'also if one users is selected and then the delede mode is off, the info displayed is not updated',
    //   hasHtmlContent: true,
    // });
    try {
      // Persist in the database
      //////const res = await userProfileService.removeListOfUsers(this.state.selected);
      const res = await userProfileService.removeListOfUsersAndModifyRespectiveMappings({
        listOfUserIds: this.state.selected,
        newAuthorId: this.state.newAuthorSelectedItem.id,
      });
      if(res.data.succeed) {
        // Reload the list users
        await this.handleLoadUserList();
        this.setState({
          selected: []
        }, () => {
          this.props.showSuccessSnackBar({msg: res.data.msg});
        });
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg});
      }
    }
    catch (e) {
      console.error('Some error occured while deleting the selected users.');
      this.props.showErrorSnackBar({msg: 'Some error occured while deleting the selected users.'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }


  retrieveAllEnabledUsersCallback = async () => {
    let res = await userProfileService.retrieveAllEnabledUsers();
    this.setState({
      allEnabledUsers: res.data,
    });
  }

  // handleNewAuthorInputChange = props => (event, user) => {
  //   //console.log(event.target.value);
  //   this.setState({
  //     newAuthorSelectedItem: user
  //   });
  // }

  handleNewAuthorInputChangeCallBack = async (props) =>  {
    this.setState({
      newAuthorSelectedItem: props
    });
  }

  handleSearchInputChange = fieldName => event => {
    this.setState({
      [fieldName]: event.target.value,
      page: fieldName === 'searchText' ? 0 : this.state.page, // Getting to the first page when searching
    });
  }

  handleSelectColumnInputChange = fieldName => event => {
    this.setState({
      [fieldName]: event !== null ? event : rows[0],
    });
  }

  handleShowConfirmDeleteSnackbar = props => event => {
    event.stopPropagation();
    this.showConfirmSnackBar({
      msg: 'Are you sure you want to delete the user(s)?',
      iconSize: 'large',
      onConfirmClick: props.handleDelete,
      confirmButtonLabel: 'Yes proceed',
      onCancelClick: this.handleCloseConfirmSnackbar,
      cancelButtonLabel: 'Cancel',
      argument: props,
    });
  }

  handleCloseConfirmSnackbar = props => () => {
    this.props.closeSnackbar(props.snackBarId);
  }

  // props: {
  //    msg:                  <Text>,
  //    iconSize:             'large | small (default: 'small')'
  //    onConfirmClick:       <Function>
  //    confirmButtonLabel:   <Text>
  //    onCancelClick:        <Function>
  //    cancelButtonLabel:    <Text>,
  //    argument:             <Object> Holding anything I need to pass to ConfirmSnackMessage component
  //    showOptionsToChoose:  OPTIONAL - Boolean value that determines whether an autocomplete
  //                          component will be placed on the left of the action butons
  //    options:              OPTIONAL - List of objects of the form <label, item>
  //    label:                OPTIONAL - The label of the autocomplete
  //    placeholder:          OPTIONAL - The placeholder of the autocomplete
  //    handleInputChange:    OPTIONAL - <function> triggered after selecting an item
  //    selectedItem:         OPTIONAL - The selected item
  // }

  // props: {
  //    msg: <Text>,
  //    iconSize: 'large | small (default: 'small')'
  //    onConfirmClick: <Function>
  //    confirmButtonLabel: <Text>
  //    onCancelClick: <Function>
  //    cancelButtonLabel: <Text>,
  //    argument: <Object> Holding anything I need to pass to ConfirmSnackMessage component
  // }
  showConfirmSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <ConfirmSnackMessage
            id={key}
            msg={props.msg}
            variant={'warning'}
            iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
            hasHtmlContent={true}
            onConfirmClick={props.onConfirmClick}
            confirmButtonLabel={props.confirmButtonLabel}
            onCancelClick={props.onCancelClick}
            cancelButtonLabel={props.cancelButtonLabel}
            argument={props.argument}
            />
        ),
      }
    );
  }

  onToggleRichEditorFullScreen = () => {
    this.setState({
      richEditorFullScreen: !this.state.richEditorFullScreen
    });
  }

  handleChangeRichContent = richContent => {
      this.setState({
          richContent: richContent,
      });
  }

  handleHideRichEditorDialog = () => {
    // Hide the dialog and clear the form
    this.setState({
      showRichEditorDialog: false,
      richContent: '',
      richTitle:'',
    });
  }

  handleShowRichEditorDialog = async () => {
    await this.prepareEmailInfo();
    this.setState({
      showRichEditorDialog: true,
    });
  }

  // props: {name: <Text>}
  handleChangeRichInput = props => event => {
    const capInputName = props.name.charAt(0).toUpperCase() + props.name.slice(1);
    if (event.target.value === '') {
      this.setState({
        ['rich' + capInputName + '_error_text']: 'This field is required',
      });
    }
    else {
      this.setState({
          ['rich' + capInputName + '_error_text']: '',
      });
    }
    this.setState({
        ['rich' + capInputName]: event.target.value,
    });
  }

  prepareEmailInfo = async () => {
    const baseUrl = window.location.origin + rootContextPath + '/';
    try {
      const res = await mainService.composeAccountActivationEmail({recipientUsername: this.state.selectedUser.username, baseUrl: baseUrl});
        if (res.data.succeed) {
          this.setState({
            richTitle_error_text: '',
            richTitle: res.data.title,
            richContent:  res.data.body,
            sendEmailIsLoading: false,
          });
        }
        else {
          this.props.showErrorSnackBar({msg: res.data.msg});
        }
    } catch (e) {
      console.error('Failed to compose an email, to inform user about his/her account activation!');
      this.props.showErrorSnackBar({msg: 'Some error occured while composing an email for the user about his/her account activation.'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  sendAccountActivationEmail = props => () => {
    try {
      this.setState({
        sendEmailIsLoading: true,
      }, async () => {
        const res = await mainService.sendEmail({recipientUser: this.state.selectedUser, title: props.title, body: props.body});
        if (res.data.succeed) {
          this.props.showSuccessSnackBar({msg: res.data.msg});
          this.setState({
            showRichEditorDialog: false,
            sendEmailIsLoading: false,
          });
        }
        else {
          this.props.showErrorSnackBar({msg: res.data.msg});
          this.setState({
            sendEmailIsLoading: false,
          });
        }
      });

    } catch (e) {
      console.error('Failed to send an email, to inform user about his/her account activation!');
      this.props.showErrorSnackBar({msg: 'Some error occured while sending email to user about his/her account activation. The email could not be sent.'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  render() {
    const { classes, } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;

    var { data } = this.state;

    // Remember that you have added the boolean "useInAllFilter" flag in each of the rows
    data =
      this.state.searchText !== null && this.state.searchText !== '' ?
      (
        this.state.columnToBeFiltered.id === 'all' ?
        data.filter( x => {
          for (var row of rows.filter(row => row.useInAllFilter)) {
            if(
              row.list ?
              x[row.id].join().toLowerCase().includes(this.state.searchText.toLowerCase()) :
              x[row.id].toLowerCase().includes(this.state.searchText.toLowerCase())
            )
              return true;
          }
          return false;
        }) :
        data.filter(
          x => x[this.state.columnToBeFiltered.id] !== null ?
          (
            this.state.columnToBeFiltered.list === true ?
            x[this.state.columnToBeFiltered.id].join().toLowerCase().includes(this.state.searchText.toLowerCase()) :
            x[this.state.columnToBeFiltered.id].toLowerCase().includes(this.state.searchText.toLowerCase())
          ) :
          false
        )
      ) :
      data;

    let columnIndex = selected.length > 0 ? this.state.data.findIndex(x => x.id === selected[0]) : -1;

    // let columnIndex = this.state.searchText ? rows.findIndex(obj => obj.id === this.state.columnToBeFiltered.value) : -1;
    // if(columnIndex !== -1 ? rows[columnIndex].boolean === false && rows[columnIndex].list === false : false) {
    //   data = this.state.searchText ?
    //          data.filter(
    //            x => x[this.state.columnToBeFiltered.value].toLowerCase().includes(this.state.searchText.toLowerCase())
    //          ) :
    //          data;
    //  }
    //  else if(columnIndex !== -1 ? rows[columnIndex].list === true : false) {
    //    data = this.state.searchText ?
    //           data.filter(
    //             x => x[this.state.columnToBeFiltered.value].toString().toLowerCase().includes(this.state.searchText.toLowerCase())
    //           ) :
    //           data;
    //  }

     const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
     console.log('ManageUsers Rendered');
     return (
      <div style={{display: 'flex'}}>
        <Card className={classes.card} style={{flexGrow: 1}}>
          <CardContent style={{padding: 0}}>
            <UserTableToolbar
              numSelected={selected.length}
              deleteModeActive={this.state.deleteModeActive}
              handleDeleteSelected={this.handleDeleteSelected}
              handleShowConfirmDeleteSnackbar={this.handleShowConfirmDeleteSnackbar}
              handleInputChange={this.handleSearchInputChange}
              handleSelectInputChange={this.handleSelectColumnInputChange}
              searchText={this.state.searchText}
              columnToBeFiltered={this.state.columnToBeFiltered}
            />
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <UserTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  showSelectAllCheckbox ={this.state.deleteModeActive}
                />
                {/*onSelectAllClick={this.handleSelectAllClick}*/}
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(user => {
                      const isSelected = this.isSelected(user.id);
                      return (
                        <TableRow
                          hover
                          onClick={event => this.handleClick(event, user)}
                          role="checkbox"
                          aria-checked={isSelected}
                          key={user.id}
                          selected={isSelected}
                          classes={!this.state.deleteModeActive ? {selected: classes.selectedRow} : {}}
                          style={{height: 49}}>
                          <TableCell padding="checkbox">
                            <CSSTransition in={this.state.deleteModeActive === true}
                                           timeout={400}
                                           classNames="revealInOutInitHidden">
                              <div className="revealInOutInitHidden">
                                <Checkbox checked={isSelected}
                                          hidden={!this.state.deleteModeActive}/>
                              </div>
                            </CSSTransition>

                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {
                              this.checkIfUserIsOnlineByUsername(user.username) ?
                              <Tooltip
                                title="Online"
                                placement={'right'}
                                enterDelay={300}
                              >
                                <Icon className={classNames(classes.fontAwesomeIcon, 'fas fa-plug', classes.onlineCell)} style={{fontSize: 24}}/>
                              </Tooltip> :
                              <Tooltip
                                title="Offline"
                                placement={'right'}
                                enterDelay={300}
                              >
                                <Icon className={classNames(classes.fontAwesomeIcon, 'fas fa-plug', classes.offlineCell)} style={{fontSize: 24}}/>
                              </Tooltip>
                            }
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Avatar alt="user-avatar"
                                    src={user.avatarImageRelativePath !== undefined ? avatarRootContextPath + user.avatarImageRelativePath : ''}
                            />
                          </TableCell>
                          {
                            Object.entries(user).map(([key,value])=>{
                              if(key !== 'id' && key !== 'description' &&
                                 key !== 'avatarImageRelativePath' &&
                                 key !== 'avatarImageBase64' &&
                                 value !== null) {
                                return (
                                  <TableCell key={key + "_" + user.id} component="th" scope="row" padding="none">
                                    {
                                      key === 'enabled' ?
                                      (
                                        value ?
                                        <DoneIcon style={{ color: green[500] }}/> :
                                        <CloseIcon style={{ color: red[500] }}/>
                                      ) :
                                      key === 'roles' ?
                                      <div style={{wordWrap: 'break-word', width: 100}}>
                                        {
                                          value.map((item, itemIndex) => {
                                            return (
                                              <span key={key + '_' + user.id + '_' + item}>{itemIndex === (value.length-1) ? item : item + ', '}</span>
                                            );
                                          })
                                        }
                                      </div> :
                                      value.toString()
                                    }
                                  </TableCell>
                                );
                              }
                              else {
                                return null
                              }
                            })
                          }
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={rows.length + 1} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <Divider />
            <div style={{marginBottom:-14, display: 'flex', paddingBottom: 16}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.deleteModeActive}
                    onChange={this.handleDeleteModeChange}
                    value={this.state.deleteModeActive}
                    color="secondary"
                  />
                }
                label="Delete Mode"
                style={{flexGrow: 1, paddingLeft: 16}}
              />
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100, 200]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </CardContent>
        </Card>
        <Card className={classes.card} style={{width: 320}}>
          <CardContent style={{paddingRight: 16}}>
            <Typography variant="h6" style={{marginBottom: 16}}>
              Selected User
            </Typography>
            <Fade in={this.state.selectedUser.username !== ''} unmountOnExit>
              <div className={classes.rightCardContent}>
                <div style={{paddingBottom: 46}}>
                  <Accordion expanded={this.state.expandedPanel === 'information'}
                                  onChange={this.handleExpandedChange('information')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.summaryHeading}>User Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionPanelDetailsStyle}>
                      <div style={{paddingTop: 5}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.selectedUser.enabled}
                              onChange={this.handleChange('enabled')}
                              value={this.state.selectedUser.enabled}
                              color="primary"
                            />
                          }
                          label="Enabled"
                          labelPlacement="start"
                        />
                        <div style={{marginTop: -15, marginLeft: 16}}>
                          <UserInfo user={this.state.selectedUser}
                                    username_error_text={this.state.username_error_text}
                                    email_error_text={this.state.email_error_text}
                                    handleChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={this.state.expandedPanel === 'roles'}
                                  onChange={this.handleExpandedChange('roles')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.summaryHeading}>User Roles</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionPanelDetailsStyle}>
                      <div>
                        <List className={classes.roleListStyle}>
                          {
                            this.state.availableUserRoles.map((role, roleIndex) => {
                              return (
                                <ListItem key={'userRole_' + roleIndex}
                                          role={undefined}
                                          dense button
                                          onClick={this.handleToggleRoleCheckbox(role)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      checked={this.state.selectedUser.roles.indexOf(role) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={role} />
                                </ListItem>
                              );
                            })
                          }
                        </List>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={this.state.expandedPanel === 'activityInfo'}
                    onChange={this.handleExpandedChange('activityInfo')}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.summaryHeading}>Activity Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionPanelDetailsStyle}>
                      <div style={{paddingTop: 16}}>
                        <Collapse in={this.state.loadingActivityInfo} unmountOnExit>
                          <div>
                            <Typography variant="body1" style={{marginBottom: 16, fontStyle: 'italic'}}>
                              Loading, please wait...
                            </Typography>
                            <CircularProgress />
                          </div>
                        </Collapse>
                        <Collapse in={!this.state.loadingActivityInfo} unmountOnExit>
                          <div>
                            <Typography variant="body1" style={{marginBottom: 16, fontStyle: 'italic'}}>
                              {
                                (
                                  this.state.selectedUserMembershipsCount > 0 ||
                                  this.state.selectedUserAuthorshipsCount > 0
                                ) ?
                                (
                                  (
                                    this.state.selectedUser.firstname !== '' &&
                                    this.state.selectedUser.lastname !== '' &&
                                    this.state.selectedUser.username !== ''
                                  ) ?
                                  (
                                    'User "' +
                                    this.state.selectedUser.firstname + ' ' +
                                    this.state.selectedUser.lastname + ' ' +
                                    '(' + this.state.selectedUser.username + ')": '
                                  ) :
                                  (
                                    'The selected user is: '
                                  )
                                ) :
                                (
                                  (
                                    this.state.selectedUser.firstname !== '' &&
                                    this.state.selectedUser.lastname !== '' &&
                                    this.state.selectedUser.username !== ''
                                  ) ?
                                  (
                                    'User "' +
                                    this.state.selectedUser.firstname + ' ' +
                                    this.state.selectedUser.lastname + ' ' +
                                    '(' + this.state.selectedUser.username + ')" ' +
                                    'is not participating, nor is the author of any mapping project.'
                                  ) :
                                  (
                                    'The selected user is not participating, nor is the author of any mapping project.'
                                  )
                                )
                              }
                            </Typography>
                            <Typography
                              hidden={this.state.selectedUserMembershipsCount === 0}
                              variant="body1"
                              style={{marginBottom: 16, fontStyle: 'italic'}}
                            >
                              <span style={{paddingRight: 5}}>
                                <FiberManualRecordIcon style={{fontSize: 12}}/>
                              </span>
                              {
                                'Participates as editor in '
                              }
                              <Tooltip
                                title={
                                  'Show the mapping projects, at which ' +
                                  this.state.selectedUser.firstname + ' ' +
                                  this.state.selectedUser.lastname + ' ' +
                                  'is listed as editor (not author)'
                                }
                                placement="top"
                                enterDelay={500}
                                leaveDelay={200}
                              >
                                <Button
                                  color="primary"
                                  onClick={
                                    this.handleListMappingProjects({
                                      user: this.state.selectedUser,
                                      type: 'membership',
                                    })
                                  }
                                  size="small"
                                  className={classes.primaryTextButton}
                                >
                                  {this.state.selectedUserMembershipsCount}
                                </Button>
                              </Tooltip>
                              {
                                ' ' +
                                'mapping projects.'
                              }
                            </Typography>
                            <Typography
                              hidden={this.state.selectedUserAuthorshipsCount === 0}
                              variant="body1"
                              style={{marginBottom: 16, fontStyle: 'italic'}}
                            >
                              <span style={{paddingRight: 5}}>
                                <FiberManualRecordIcon style={{fontSize: 12}}/>
                              </span>
                              {
                                'Is the author of '
                              }
                              <Tooltip
                                title={
                                  'Show the mapping projects, at which ' +
                                  this.state.selectedUser.firstname + ' ' +
                                  this.state.selectedUser.lastname + ' ' +
                                  'is the author'
                                }
                                placement="top"
                                enterDelay={500}
                                leaveDelay={200}
                              >
                                <Button
                                  color="primary"
                                  onClick={
                                    this.handleListMappingProjects({
                                      user: this.state.selectedUser,
                                      type: 'authorship',
                                    })
                                  }
                                  size="small"
                                  className={classes.primaryTextButton}
                                >
                                  {this.state.selectedUserAuthorshipsCount}
                                </Button>
                              </Tooltip>
                              {
                                ' ' +
                                'mapping projects.'
                              }
                            </Typography>
                          </div>
                        </Collapse>
                      </div>

                    </AccordionDetails>
                  </Accordion>
                  <div style={{display: 'flex', width: '100%'}}>
                    <div style={{padding: 10}}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSaveUser(this.state.selectedUser)}
                      >
                        Save
                      </Button>
                    </div>
                    <div style={{flexGrow: 1}} />
                    <div style={{padding: 10, whiteSpace: 'nowrap'}}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleShowRichEditorDialog}
                      >
                        Compose email
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade in={this.state.selectedUser.username === ''} unmountOnExit>
              <div className={classes.rightCardContent}>
                <Typography variant="body1" style={{marginBottom: 16, fontStyle: 'italic'}}>
                  Please Select some user from the list to edit
                </Typography>
              </div>
            </Fade>
          </CardContent>
        </Card>
        <RichEditorDialog
          show={this.state.showRichEditorDialog}
          fullScreen={this.state.richEditorFullScreen}
          handleHideDialog={this.handleHideRichEditorDialog}
          handleChangeRichContent={this.handleChangeRichContent}
          handleChangeRichInput={this.handleChangeRichInput}
          onToggleFullScreen={this.onToggleRichEditorFullScreen}
          richContent={this.state.richContent}
          richTitle={this.state.richTitle}
          richTitle_error_text={this.state.richTitle_error_text}
          recipientUser={this.state.selectedUser}
          sendEmail={this.sendAccountActivationEmail}
          isLoading={this.state.sendEmailIsLoading}
        />
      </div>
    )
  }
}

ManageUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(ManageUsers);
