import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const styles = theme => ({
  root: {
    margin: 'auto',
    marginLeft: -10,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  listContentWrapper: {
    height: 400,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
});

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export class TransferList extends React.Component {

  state = {
    checked: [], // preloaded
    left: [],
    right: [],
    leftChecked: [],
    rightChecked: [],
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.state !== nextState) {
      return true;
    }
    // if(this.props.localDefinitions !== nextProps.localDefinitions) {
    //   return true;
    // }
    if(this.props.newAvailableDefinitions !== nextProps.newAvailableDefinitions) {
      return true;
    }
    if(this.props.inTheListToLoad !== nextProps.inTheListToLoad) {
      return true;
    }

    else {
      return false;
    }
  }

  componentDidMount() {
    this.setState({
      left: this.props.newAvailableDefinitions,//this.props.localDefinitions,
      right: this.props.inTheListToLoad, //this.props.localDefinitions,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // if (this.props.localDefinitions !== prevProps.localDefinitions) {
    //   this.setState({
    //     left: this.props.localDefinitions,
    //   });
    // }
    if (this.props.newAvailableDefinitions !== prevProps.newAvailableDefinitions) {
      this.setState({
        left: this.props.newAvailableDefinitions,
        right: this.props.inTheListToLoad, //this.props.localDefinitions,
      });
    }
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      leftChecked: intersection(newChecked, this.state.left),
      rightChecked: intersection(newChecked, this.state.right),
    });
  }


  numberOfChecked = (items) => {
    return intersection(this.state.checked, items).length;
  }

  handleToggleAll = (items) => () => {
    let newChecked = [];
    if(this.numberOfChecked(items) === items.length) {
      newChecked = not(this.state.checked, items);
    }
    else {
      newChecked = union(this.state.checked, items);
    }
    this.setState({
      checked: newChecked,
      leftChecked: intersection(newChecked, this.state.left),
      rightChecked: intersection(newChecked, this.state.right),
    });
  };

  handleCheckedRight = () => {
    this.setState({
      right: this.state.right.concat(this.state.leftChecked),
      left: not(this.state.left, this.state.leftChecked),
      checked: not(this.state.checked, this.state.leftChecked),
    });
  }

  handleCheckedLeft = () => {
    this.setState({
      left: this.state.left.concat(this.state.rightChecked),
      right: not(this.state.right, this.state.rightChecked),
      checked: not(this.state.checked, this.state.rightChecked),
    });
  }

  render() {

    const {
            classes, leftTitle, rightTitle,
            disableSelectFromImportView, loadGeneratorDefinitions,
          } = this.props;

    const customList = (title, items) => (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={this.handleToggleAll(items)}
              checked={this.numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <div className={classes.listContentWrapper}>
          <List className={classes.list} dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem key={value.name} role="listitem" button onClick={this.handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={this.state.checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.name} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </div>
      </Card>
    );

    return (
      <div>
        <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
          <Grid item style={{width: '45%'}}>{customList(leftTitle, this.state.left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                size="small"
                className={classes.button}
                onClick={this.handleCheckedRight}
                disabled={this.state.leftChecked.length === 0}
                aria-label="move selected right"
                color="primary"
                style={{minWidth: 42}}
              >
                <ArrowForwardIosIcon />
              </Button>
              <Button
                size="small"
                className={classes.button}
                onClick={this.handleCheckedLeft}
                disabled={this.state.rightChecked.length === 0}
                aria-label="move selected left"
                color="primary"
                style={{minWidth: 42}}
              >
                <ArrowBackIosIcon style={{marginLeft: 8}}/>
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{width: '45%'}}>{customList(rightTitle, this.state.right)}</Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Button
            color="primary"
            onClick={loadGeneratorDefinitions(this.state.right)}
            style={{marginRight: 10}}
            disabled={this.state.right.length < 1}
          >
            Load Selected Definitions
          </Button>
          <Button
            color="primary"
            onClick={disableSelectFromImportView}
            style={{marginLeft: 10}}
          >
            Cancel
          </Button>
        </Grid>
      </div>
    );
  }
}

TransferList.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(TransferList);
