import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Fade from '@material-ui/core/Fade';

class TimeoutDialog extends Component {

  render() {

    const { open, seconds } = this.props;

    // onChange={this.checkIfAuthed} might not be needed
    return (
      <Dialog open={open}
              aria-labelledby="modal"
              TransitionComponent={Fade}
              transitionDuration={{enter: 400, exit: 400}}
              maxWidth="xl">
        <DialogContent>
          <DialogContentText>
            Your session is about to expire in <b>{seconds}</b> seconds.
          </DialogContentText>
          <DialogContentText style={{width: 435}}>
            Move your cursor around or do some action to expand your session, otherwize you should be automatically logged out.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default TimeoutDialog;
