import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();
const rootContextPath = configuration.rootContextPath;

export class MappingTableViewService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }
  
  /*
  Saving MappingTreeModel to the database
  */
  async saveMappingTreeModel(data) {
     return await axiosInstance(rootContextPath + '/mappingTableView/saveMappingTreeModel', {
      method: 'post',
      data: data,
    });
  }

  /*
  Retrieving the MappingTreeModel
  */
  async retrieveMappingTreeModelById(id) {
     return await axiosInstance(rootContextPath + '/mappingTableView/retrieveMappingTreeModelById', {
      method: 'get',
      params: id,
    });
  }

  /*
  Generate and store the namespaces for the filemetadata of the given ID onto
  that same fileMetadata (XML/XSD only)
  data: {
	 "id": <XML_FILEMETADATA_ID>,
	 "addNamespace2DB": <TRUE_TO_SAVE_NAMESPACES_OR_FALSE_OTHERWISE>
  }
  */
  async generateAndStoreXmlFileMetadataNamespaces(data) {
     return await axiosInstance(rootContextPath + '/getNamespaces', {
      method: 'post',
      data: data,
    });
  }
  /*
  Retrieving the metadata of the source and target files for the given
  project with ID
  */
  async retrieveFileMetadataListByMappingProjectId(id) {
     return await axiosInstance(rootContextPath + '/retrieveFileMetadataListByMappingProjectId', {
      method: 'get',
      params: id,
    });
  }

  /*
  Converting the JSON Model to X3ML
  */
  async convertAndsaveX3ml(data) {
     return await axiosInstance(rootContextPath + '/x3ml/convertAndsaveX3ml', {
      method: 'post',
      data: data,
    });
  }

  async convertAndsaveX3mlByMappingProjectId(data) {
     return await axiosInstance(rootContextPath + '/x3ml/convertAndsaveX3mlByMappingProjectId', {
      method: 'post',
      data: data,
    });
  }
  async constructX3mlByLinkJsonModel(data) {
     return await axiosInstance(rootContextPath + '/x3ml/constructX3mlByLinkJsonModel', {
      method: 'post',
      data: data,
    });
  }
  async constructDomainX3mlByMappingJsonModel(data) {
     return await axiosInstance(rootContextPath + '/x3ml/constructDomainX3mlByMappingJsonModel', {
      method: 'post',
      data: data,
    });
  }
  /*
  Using the X3ML engine to produce the RDF from the X3ML and the source input files
  Accepts one argument which is the ID of the MappingProject
  */
  async transformWithX3mlEngineByMappingProjectId(data) {
     return await axiosInstance(rootContextPath + '/x3ml/transformWithX3mlEngineByMappingProjectId', {
      method: 'post',
      data: data,
    });
  }

  /*
  Import X3ML
  */
  async convertX3mlFileToJson(data) {
     return await axiosInstance(rootContextPath + '/x3ml/import/convertX3mlFileToJson', {
      method: 'post',
      data: data,
    });
  }

  async convertX3mlCodeToJson(data) {
     return await axiosInstance(rootContextPath + '/x3ml/import/convertX3mlCodeToJson', {
      method: 'post',
      data: data,
    });
  }

  async convertX3mlFragmentCodeToJson(data) {
     return await axiosInstance(rootContextPath + '/x3ml/import/convertX3mlFragmentCodeToJson', {
      method: 'post',
      data: data,
    });
  }

  async retrieveLanguages() {
     return await axiosInstance(rootContextPath + '/x3ml/import/getLanguages', {
      method: 'get',
    });
  }

  /*
  Applies validatin on the contents (text) of some generator policy file
  */
  async validateGeneratorDefinitionPolicyFileFromString(data) {
     return await axiosInstance(rootContextPath + '/x3ml/validateGeneratorDefinitionPolicyFileFromString', {
      method: 'post',
      data: data,
    });
  }
  // async validateGeneratorDefinitionPolicyFileFromString(generatorPolicyFileString) {
  //    return await axiosInstance(rootContextPath + '/x3ml/validateGeneratorDefinitionPolicyFileFromString', {
  //     method: 'get',
  //     params: generatorPolicyFileString,
  //   });
  // }

}
