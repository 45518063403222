import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import JoditEditor from "jodit-react";
import { CSSTransition } from 'react-transition-group';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  headingText: {
    fontWeight: 'bold',
    fontSize: '1.15rem',
    margin: 'auto',
  },
  headerIconButton: {
    height: 48,
    width: 48,
  },
  centered: {
    height: '100%',
    width: '100%',

    marginTop: -20,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 2,
  },
  absoluteCenteredXY: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  absoluteCenteredWithBorderXY: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    background: '#ffffff',
    padding: 10,
    border: '2px solid rgb(0 0 0 / 20%)',
  },
});

export class RichEditorDialog extends React.Component {
  state = {
    richEditorConfig: {
      // all options from https://xdsoft.net/jodit/docs/classes/config.Config.html
      // also check http://rmamuzic.rs/node_modules/jodit/examples/index.html
      readonly: false,
      height: 'calc(100vh - 450px)',
      width: 900,
      hidePoweredByJodit: true,
      buttons: [
        'source', '|',
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'superscript',
        'subscript', '|',
        'ul',
        'ol', '|',
        'outdent', 'indent',  '|',
        'font',
        'fontsize',
        'brush',
        'paragraph', '|',
        'image',
        'file',
        'video',
        'table',
        'link', '|',
        'align', 'undo', 'redo', '|',
        'cut',
        'copy',
        'paste',
        'hr',
        'eraser',
        'copyformat', '|',
        'symbol',
        'fullsize',
        'print',
        //'preview',
        'find',
        'about'
      ],
    },
    fullScreenRichEditorConfig: {
      readonly: false,
      height: 'calc(100vh - 220px)',
      hidePoweredByJodit: true,
      buttons: [
        'source', '|',
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'superscript',
        'subscript', '|',
        'ul',
        'ol', '|',
        'outdent', 'indent',  '|',
        'font',
        'fontsize',
        'brush',
        'paragraph', '|',
        'image',
        'file',
        'video',
        'table',
        'link', '|',
        'align', 'undo', 'redo', '|',
        'cut',
        'copy',
        'paste',
        'hr',
        'eraser',
        'copyformat', '|',
        'symbol',
        'fullsize',
        'print',
        //'preview',
        'find',
        'about'
      ],
    },
    updateParticipantsSpeedDialIsOpen: false,
  };


  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.show !== nextProps.show) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.richContent !== nextProps.richContent) {
      return true;
    }
    if(this.props.richTitle !== nextProps.richTitle) {
      return true;
    }
    if(this.props.richTitle_error_text !== nextProps.richTitle_error_text) {
      return true;
    }
    if(this.props.recipientUser !== nextProps.recipientUser) {
      return true;
    }
    if(this.props.isLoading !== nextProps.isLoading) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  render() {

    const {
      classes, show, fullScreen, observerUserList, onToggleFullScreen, sendEmail,
      richContent, richTitle, richTitle_error_text, recipientUser, isLoading,
    } = this.props;

    console.log('RichEditorDialog Rendered');
    //const usePreSetTitle = useTitle !== undefined ? useTitle : false;
    return (

      <Dialog
        open={show}
        fullScreen={fullScreen}
        TransitionComponent={Fade}
        keepMounted={false}
        maxWidth="xl"
        scroll="paper"
      >
        <DialogTitle>
          <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, margin: 'auto'}}>
              <Typography variant="h6" className={classes.headingText} style={{flexGrow: 1}}>
                Compose email message ...
              </Typography>
            </div>
            <div>
              <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                       placement="bottom"
                       enterDelay={500}
                       leaveDelay={200}>
                <IconButton aria-label="maximize-minimize"
                            onClick={onToggleFullScreen}
                            className={classes.headerIconButton}

                >
                  {
                    !fullScreen ?
                    <FilterNoneIcon/> :
                    <CropSquareIcon/>
                  }
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Close"
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}>
                <IconButton onClick={this.props.handleHideDialog} className={classes.headerIconButton}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.centered}>
            <TextField
              required
              error={richTitle_error_text !== undefined ? richTitle_error_text !== '' : false}
              helperText={richTitle_error_text}
              label="Title"
              value={richTitle}
              onChange={this.props.handleChangeRichInput({name: 'title'})}
              margin="normal"
              style={{width: '100%'}}
            />
            <JoditEditor
              value={richContent}
              config={fullScreen ? this.state.fullScreenRichEditorConfig : this.state.richEditorConfig}
              tabIndex={1} // tabIndex of textarea
              onBlur={this.props.handleChangeRichContent}
            />
          </div>
          <Fade in={isLoading} className={classes.absoluteCenteredXY} style={{background: '#ffffffab'}}>
            <div style={{width: '100%', height: '100%'}}>
              <div className={classes.absoluteCenteredWithBorderXY}>
                <Typography variant="h6" style={{marginBottom: 16, margin: 'auto', fontStyle: 'italic', fontWeight: 'bold'}}>
                  Loading, please wait...
                </Typography>
                <CircularProgress thickness={5.6} style={{margin: 'auto'}}/>
              </div>
            </div>
          </Fade>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={sendEmail({recipientUser: recipientUser, title: richTitle, body: richContent})}
          >
            Send Email
          </Button>
          <Button onClick={this.props.handleHideDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

RichEditorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RichEditorDialog);
