import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DeleteIcon from '@material-ui/icons/Delete';
import JoditEditor from "jodit-react";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Autocomplete from './Autocomplete';
//import Switch from '@material-ui/core/Switch';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CustomSnackMessage from './snackbar/CustomSnackMessage';
import { UserProfileService } from './services/UserProfileService';
import { MessageService } from './services/MessageService';
import { CSSTransition } from 'react-transition-group';
const userProfileService = new UserProfileService();
const messageService = new MessageService();

// const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const styles = theme => ({
  rootCard: {
    margin: '10px 0',
  },
  card: {
    margin: '10px 5px',
    height: 'calc(100vh - 94px)'
  },
  centerCard: {
    margin: '10px 5px',
    height: 'calc(100vh - 94px)',
    //flexGrow: 1,
    width: '100%'
  },
  button: {
    margin: theme.spacing(1),
  },
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  onTop: {
    zIndex: 1,
  },
  listItemIconShorterSpace: {
    minWidth: 40,
  },
});

const inboxes = [
  { value: 'received', label: 'Received' },
  { value: 'unread', label: 'Unread' },
];

export class Messages extends React.Component {

  state = {
    currInbox: {
      value: 'received',
      label: 'Received'
    },
    messages: [],
    richContent: '',
    richTitle: '',
    leftSectionCollapsed: false,
    selectedMessage: '',
  }
/*
  constructor(props) {
    super(props);
    //this.containerRef = React.createRef();
  }
*/

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.connected !== nextProps.connected) {
      return true;
    }
    if(this.props.newReceivedMsg !== nextProps.newReceivedMsg) {
      return true;
    }
    if(this.props.webSocketServerFeedback !== nextProps.webSocketServerFeedback) {
      return true;
    }
    if(this.props.unreadMessages !== nextProps.unreadMessages) {
      return true;
    }

    if(this.props.jsonPackage !== nextProps.jsonPackage) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.newReceivedMsg !== prevProps.newReceivedMsg) {
      if(this.props.newReceivedMsg.recipients.findIndex(obj => obj.id === this.state.currentFullUser.id) !== -1) {
        if(this.state.currInbox.value === 'received' ||
           this.state.currInbox.value === 'unread') {
          await this.handleLoadMessages();
        }
      }
    }

    if (this.props.unreadMessages !== prevProps.unreadMessages) {
      if(this.state.currInbox.value === 'unread') {
        this.setState({
            messages: this.props.unreadMessages,
        });
      }
    }
    // Check if there is some package send from others (deleting message)
    if (this.props.jsonPackage !== prevProps.jsonPackage) {
      this.handleChangeFromOthers(this.props.jsonPackage);
    }
  }

  async componentDidMount() {
    await this.handleLoadMessages();
    const user = await userProfileService.retrieveUserByUsername({username: this.props.currUser.currUsername});
    this.setState({
        currentFullUser: user.data,
    });
  }

  handleLoadMessages = async () => {
    let messages = [];
    if(this.state.currInbox.value === 'received') {
      messages = await messageService.retrieveAllMessagesReceivedByThisUser();
    }
    else if(this.state.currInbox.value === 'unread') {
      await this.props.retrieveUserUnreadMessagesCallBack();
      messages = this.props.unreadMessages;//await messageService.retrieveUnreadMessages();
    }
    // Note that case of unread is already covered by componentDidUpdate method
    if(this.state.currInbox.value !== 'unread') {
      this.setState({
          messages: messages.data,
      });
    }
  }

  handleSelectMessage = message => () => {
    this.setState({
      richContent: message.content,
      richTitle: message.title,
      selectedMessage: message,
    }, async function() {
      if(message.recipients.findIndex(obj => obj.id === this.state.currentFullUser.id) !== -1) {
        await this.props.markMessageAsRead({message: message});
        await this.props.retrieveUserUnreadMessagesCallBack();
        if(this.state.currInbox.value === 'unread') {
          await this.handleLoadMessages();
        }
      }
    });
  }

  handleInboxChange = event => {
      this.setState({
          currInbox: event,
      }, async function () {
        await this.handleLoadMessages();
      });
  }

  collapseSection = sectionName => () => {
    this.setState({
        [sectionName + 'SectionCollapsed']: !this.state[sectionName + 'SectionCollapsed'],
    });
  }



  // Syncing from others
  // Handling package received from others
  handleChangeFromOthers = async jsonPackage => {
    if(jsonPackage !== undefined && jsonPackage !== null ) {
      if(jsonPackage.type !== undefined && jsonPackage.type !== null ) {
        // Case delete messages
        if(jsonPackage.type === 'deleteMessages') {
          // If any of the deleted messages is lited,
          // then reload the list of messages
          let anyOfThemisPresentInTheListOfMessages = false;
          for(var msgId of jsonPackage.ids) {
            if(this.state.messages.findIndex(obj => obj.id === msgId) !== -1) {
              anyOfThemisPresentInTheListOfMessages = true;
              break;
            }
          }
          if(anyOfThemisPresentInTheListOfMessages) {
            await this.handleLoadMessages();
          }
          // If the selected message is among those that have been deleted, then reset it.
          if(jsonPackage.ids.findIndex(id => id === this.state.selectedMessage.id) !== -1) {
            this.setState({
              selectedMessage: '',
            }, ()=> {
              this.props.showCustomSnackBar({
                msg: 'The message that you were viewing, has been erased by some user, holding administrative rights.',
                type: 'warning'
              });
            });
          }



        }
      }
    }
  }















  printState = () => {
    console.log(this.state);
  }
  //readonly containerRef = React.createRef<Container>();
  render() {
      const { classes, } = this.props;

      // data = this.state.searchText ?
      //         data.filter(
      //             x => x[this.state.columnToBeFiltered.value].toLowerCase().includes(this.state.searchText)
      //         ) :
      //         data;
      console.log('Messages Rendered');
      return (
        <div style={{display: 'flex'}}>
          <CSSTransition in={this.state.leftSectionCollapsed}
                         timeout={500}
                         classNames="sectionCollapseInitShown">
            <div className="sectionCollapseInitShown">
              <Card className={classes.card}>
                <CardContent>

                  <CSSTransition timeout={400}
                                 classNames="moveRightWhenPanelCollapsed"
                                 in={this.state.leftSectionCollapsed}>
                    <div className="moveRightWhenPanelCollapsed">
                      <Fade in={!this.state.leftSectionCollapsed} mountOnEnter unmountOnExit>
                        <Tooltip title="Collapse panel"
                                 placement="bottom-end"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <Fab className={classNames(classes.fab, classes.onTop)} color="primary"
                               style={{position: 'absolute', top: 0}}
                               onClick={this.collapseSection('left')}
                               size="medium">
                            <NavigateBeforeIcon style={{fontSize: 46}}/>
                          </Fab>
                        </Tooltip>
                      </Fade>
                      <Fade in={this.state.leftSectionCollapsed} mountOnEnter unmountOnExit>
                      <Tooltip title="Show panel"
                               placement="bottom-start"
                               enterDelay={500}
                               leaveDelay={200}>
                          <Fab className={classNames(classes.fab, classes.onTop)} color="primary"
                               style={{position: 'absolute', top: 0}}
                               onClick={this.collapseSection('left')}
                               size="medium">
                            <NavigateNextIcon style={{fontSize: 46}}/>
                          </Fab>
                        </Tooltip>
                      </Fade>
                    </div>
                  </CSSTransition>

                  <Typography variant="h6" style={{marginBottom: 16}}>
                      {this.state.currInbox.label} Messages
                  </Typography>

                  <div style={{width: '100%'}}>
                    <Autocomplete placeholder={'Select Inbox'}
                                  options={inboxes}
                                  value={this.state.currInbox}
                                  onChange={this.handleInboxChange}
                                  async={false}
                                  isMulti={false}
                                  isClearable={false}
                                  disabled={this.state.deleteModeActive}/>
                  </div>

                  <div hidden={this.state.messages.length > 0}>
                    <Typography variant="subtitle1" style={{paddingTop: 16, marginBottom: 16}}>
                        There are no messages to show yet
                    </Typography>
                  </div>
                  <div hidden={this.state.messages.length === 0}>
                    <List style={{overflow: 'auto', height: 'calc(100vh - 202px)'}}>
                      {
                        this.state.messages.map((message, messageIndex) => {
                          return (
                            <div key={'message_' + messageIndex}>
                              <ListItem dense>
                                <ListItemIcon className={classes.listItemIconShorterSpace}>
                                  <Radio  edge="start"
                                          onClick={this.handleSelectMessage(message)}
                                          checked={
                                              this.state.selectedMessage !== undefined ?
                                              this.state.selectedMessage.id === message.id :
                                              false
                                          }
                                  />
                                </ListItemIcon>
                                <ListItemText primary={message.title}
                                              secondary={
                                                <React.Fragment>
                                                  {
                                                    message.recipients.map((recipient, recipientIndex) => {
                                                      if(recipient !== null) {
                                                        return (
                                                          <span key={'recipient_' + recipientIndex}>
                                                            {recipientIndex > 0 && ", "}
                                                            {recipient.username}
                                                          </span>
                                                        )
                                                      }
                                                    })
                                                  }
                                                  <br/>
                                                  <span style={{fontSize: 'smaller'}}>
                                                    {new Date(message.creationTime).toDateString()}
                                                    {' '}
                                                    {new Date(message.creationTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                                  </span>
                                                </React.Fragment>
                                              }
                                />
                              </ListItem>
                              <Divider hidden={messageIndex === this.state.messages.length-1}/>
                            </div>
                          );
                        })
                      }
                    </List>
                  </div>
                  {/*
                  <Grow in={this.state.deleteModeActive}>
                    <Fab color="secondary"
                         aria-label="delete-messages"
                         onClick={this.handleDeleteMessages}
                         className={classes.fab}
                         style={{position: 'absolute', bottom: 24, zIndex: 1, left: 200}}>
                      <DeleteIcon />
                    </Fab>
                  </Grow>
                  */}
                </CardContent>
              </Card>
            </div>
          </CSSTransition>
          <Card className={classes.centerCard}>
            <CardContent>
              <CSSTransition timeout={400}
                             classNames="marginLeftWhenPanelCollapsed"
                             in={this.state.leftSectionCollapsed}>
                <div className="marginLeftWhenPanelCollapsed">
                  <Typography variant="h6"
                              style={{marginBottom: 16, flegGrow: 1, width: '100%'}}>
                    Message
                  </Typography>
                </div>
              </CSSTransition>
              {
                this.state.selectedMessage !== '' ?
                <div>
                  <Typography variant="h6" style={{marginBottom: 16, flegGrow: 1, width: '100%'}}>
                    Title:
                    <span style={{paddingLeft: 5}}>
                      {
                        (this.state.richTitle !== undefined && this.state.richTitle !== '') ?
                        this.state.richTitle :
                        <span style={{fontStyle: 'italic'}}>
                          «This message has no title»
                        </span>
                      }
                    </span>
                  </Typography>
                  <div style={{overflowY: 'auto', height: 'calc(100vh - 214px)'}}>
                    {
                      (this.state.richContent !== undefined && this.state.richContent !== '') ?
                      renderHTML(this.state.richContent) :
                      <Typography variant="subtitle1" style={{fontStyle: 'italic'}}>
                        «This message has no content»
                      </Typography>
                    }
                  </div>
                </div> :
                <Typography variant="h6">
                  Please select some message from the list on the left to show
                </Typography>
              }

            </CardContent>
          </Card>
        </div>
      )
  }
}

Messages.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(Messages);
