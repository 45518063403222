import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();
const rootContextPath = configuration.rootContextPath;

export class MainService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }

  /*
    Retrieves all the MappingProjects
  */
  async getAllMappingProjects() {
     return await axiosInstance(rootContextPath + '/retrieveAllMappingProjects', {
      method: 'get',
    });
  }
  /*
    Retrieves all the MappingProjects, returning only the metadata fields
  */
  async getAllMappingProjectsMetadataOnly() {
     return await axiosInstance(rootContextPath + '/retrieveAllMappingProjectsMetadataOnly', {
      method: 'get',
    });
  }

  /*
    Retrieves my MappingProjects, returning only the metadata fields
  */
  async getMyMappingProjectsMetadataOnly() {
     return await axiosInstance(rootContextPath + '/retrieveMyMappingProjectsMetadataOnly', {
      method: 'get',
    });
  }

  /*
    Retrieves participating MappingProjects, returning only the metadata fields
  */
  async getParticipatingMappingProjectsMetadataOnly() {
     return await axiosInstance(rootContextPath + '/retrieveParticipatingMappingProjectsMetadataOnly', {
      method: 'get',
    });
  }

  async retrieveMappingProjectsByAuthorId(userId) {
     return await axiosInstance(rootContextPath + '/retrieveMappingProjectsMetadataOnlyByAuthorId', {
      method: 'get',
      params: userId,
    });
  }

  /*
    Counts the mappingProjects where the user is the author
  */
  async countMappingProjectsByAuthorId(userId) {
     return await axiosInstance(rootContextPath + '/countMappingProjectsByAuthorId', {
      method: 'get',
      params: userId,
    });
  }

  /*
    Retrieve the mappingProjects where the user is the member (not author)
  */
  async retrieveMappingProjectsByMemberId(userId) {
     return await axiosInstance(rootContextPath + '/retrieveMappingProjectsMetadataOnlyByMemberId', {
      method: 'get',
      params: userId,
    });
  }

  /*
    Counts the mappingProjects where the user is the member (not author)
  */
  async countMappingProjectsByMemberId(userId) {
     return await axiosInstance(rootContextPath + '/countMappingProjectsByMemberId', {
      method: 'get',
      params: userId,
    });
  }

  /*
    Involving Groups - Starts
  */

  /*
    Retrieves all the MappingProjects, returning only the metadata fields
  */
  async getAllMappingProjectsInGroupMetadataOnly(groupId) {
     return await axiosInstance(rootContextPath + '/retrieveAllMappingProjectsInGroupMetadataOnly', {
      method: 'get',
      params: groupId,
    });
  }

  /*
    Retrieves my MappingProjects, returning only the metadata fields
  */
  async getMyMappingProjectsInGroupMetadataOnly(groupId) {
     return await axiosInstance(rootContextPath + '/retrieveMyMappingProjectsInGroupMetadataOnly', {
      method: 'get',
      params: groupId,
    });
  }

  /*
    Retrieves participating MappingProjects, returning only the metadata fields
  */
  async getParticipatingMappingProjectsInGroupMetadataOnly(groupId) {
     return await axiosInstance(rootContextPath + '/retrieveParticipatingMappingProjectsInGroupMetadataOnly', {
      method: 'get',
      params: groupId,
    });
  }

  /*
    Involving Groups - Ends
  */

  /*
  Adding a new MappingProject to the database
  */
  async saveMappingProject(data) {
     return await axiosInstance(rootContextPath + '/addNewProjectMapping', {
      method: 'post',
      data: data,
    });
  }

  async cloneMappingProject(data) {
     return await axiosInstance(rootContextPath + '/cloneProjectMapping', {
      method: 'post',
      data: data,
    });
  }

  async retrieveMappingProjectById(id) {
     return await axiosInstance(rootContextPath + '/retrieveMappingProjectById', {
      method: 'get',
      params: id,
    });
  }

  async deleteMappingProjectById(data) {
     return await axiosInstance(rootContextPath + '/deleteMappingProjectById', {
      method: 'post',
      data: data,
    });
  }

  // Retrieves the ID by given the HRID
  async retrieveMappingProjectIdByHrid(id) {
    return await axiosInstance(rootContextPath + '/retrieveMappingProjectIdByHrid', {
      method: 'get',
      params: id,
    });
  }
  

  // data: {id: <Text>, group: <Object>}
  // ID of the MappingProject to be update and the group to be added in it's groups list.
  async addMappingProjectsInGroup(data) {
     return await axiosInstance(rootContextPath + '/addMappingProjectsInGroup', {
      method: 'post',
      data: data,
    });
  }

  async retrieveOptionsTest(id) {
     return await axiosInstance(rootContextPath + '/retrieveOptionsTest', {
      method: 'get',
    });
  }

  async retrieveDefaultUserConfigurationSettings(username) {
     return await axiosInstance(rootContextPath + '/retrieveDefaultUserConfigurationSettings', {
      method: 'get',
    });
  }

  async retrieveUserConfigurationSettingsByUserName(username) {
     return await axiosInstance(rootContextPath + '/retrieveUserConfigurationSettingsByUserName', {
      method: 'get',
      params: username,
    });
  }

  async saveUserConfigurationSettingsByUserName(data) {
     return await axiosInstance(rootContextPath + '/saveUserConfigurationSettingsByUserName', {
      method: 'post',
      data: data,
    });
  }

  /*
  Retrieves all the MappingProjects, returning only the metadata fields
  */
  async getNewUUID() {
     return await axiosInstance(rootContextPath + '/getNewUUID', {
      method: 'get',
    });
  }

  async importFromZipFile(data) {
     return await axiosInstance(rootContextPath + '/importFromZipFile', {
      method: 'post',
      data: data,
    });
  }

  // async retrieveConfigurationProperties() {
  //    return await axiosInstance(rootContextPath + '/retrieveConfigurationProperties', {
  //     method: 'get',
  //   });
  // }

  async retrieveRdfVisualizerUrl() {
     return await axiosInstance(rootContextPath + '/rdfVisualizer/retrieveRdfVisualizerUrl', {
      method: 'get',
    });
  }

  // async sendAccountActivationEmail(data) {
  //    return await axiosInstance(rootContextPath + '/email/sendAccountActivationEmail', {
  //     method: 'post',
  //     data: data,
  //   });
  // }

  // Removes the users in the list
  async sendEmail(data) {
     return await axiosInstance(rootContextPath + '/email/sendEmail', {
      method: 'post',
      data: data,
    });
  }

  async composeAccountActivationEmail(data) {
     return await axiosInstance(rootContextPath + '/email/composeAccountActivationEmail', {
      method: 'post',
      data: data,
    });
  }

  async getAboutInfo() {
     return await axiosInstance(rootContextPath + '/about/getAboutInfo', {
      method: 'get',
    });
  }


}
