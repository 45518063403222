import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ArrowForward from '@material-ui/icons/ArrowForward';
import LoopIcon from '@material-ui/icons/Loop';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import PatternWithValuesAndColors from './PatternWithValuesAndColors';
import GeneratorInfo from './GeneratorInfo';
import GeneratorDeclarationError from './GeneratorDeclarationError';

//import './css/custom.css';
const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  nativeAdditionalCompIcon: {
    color: '#ec9d00',
    fontWeight: '800',
    borderColor: '#ec9d00',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 82,
    height: 24,
    textAlign: 'center',
  },
  variableIcon: {
    color: '#52c41a',
  },
  variableCompIcon: {
    color: '#52c41a',
    fontWeight: '800',
    borderColor: '#52c41a',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  instanceInfoCompIcon: {
    color: '#e58ee9',
    fontWeight: '800',
    borderColor: '#e58ee9',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  constExprEdit: {
    borderStyle: 'dashed',
    //borderColor: 'rgb(32, 150, 243)',
    //borderColor: '#37a700',
    borderColor: '#ec9d00',
    marginTop: 5,
    marginBottom: 5,
    marginLeft:20,
    padding:5,
    borderLeft:'solid'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  // Generator Declaration
  generatorDeclarationError: {
    color: 'red',
  },
  generatorDeclarationErrorneousDeleteIcon: {
    color: '#ff00001a',
    '&&:hover, &&:active': {
      color: 'red',
    },
  },
  generatorDeclarationValidDeleteIcon: {
    color: 'rgb(171 218 255)',
  },
  generatorDeclarationErrorneousChip: {
    color: 'red',
    border: 'none',
    //border: '1px solid red',
    '&&:hover, &&:active, &&:focus': {
      backgroundColor: '#ff00001a',
    },
  },
  generatorDeclarationValidChip: {
    border: 'none',
  },
  generatorDeclarationIsolationBorder: {
    // border: '1px dashed',
    // borderRadius: 10,
    // padding: 7,
    // marginBottom: 5,
  },
  // generatorDeclarationIsolationBorderValid: {
  //   borderColor: theme.palette.primary.main,
  // },
  generatorDeclarationIsolationBorderError: {
    //borderColor: 'red',
  },
  generatorDeclarationIconWrapperStyle: {
    display: 'table-cell',
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 10
  },
  patternWithValuesAndColorsWrapper: {
    fontStyle: 'italic',
    marginLeft: 9,
    wordBreak: 'break-all',
  },
  declarationLabelWrapper: {
    marginLeft: 9,
  },
  errorneousChildren: {
    color: 'red',
    borderColor: 'red',
  }
});

export class ConstExprView extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.compactViewMode !== nextProps.compactViewMode) {
      return true;
    }
    if(this.props.compactViewEnabled !== nextProps.compactViewEnabled) {
      return true;
    }
    if(this.props.constExpr !== nextProps.constExpr) {
      return true;
    }
    else {
      return false;
    }
  }

  // props: {constExpr: <Object>}
  checkIfChildrenHaveErrorsCallBack = props => {
    const constExpr = props.constExpr;
    let childrenHaveErrors = false;
    if(constExpr.constExprIds !== undefined) {
      for (const childConstExprId of constExpr.constExprIds) {
        const childConstExpr = constExpr.constExprs[childConstExprId];
        if(childConstExpr.relation.error_text !== undefined && childConstExpr.relation.error_text !== '') {
          childrenHaveErrors = true;
          break;
        }
        if(childConstExpr.entity.error_text !== undefined && childConstExpr.entity.error_text !== '') {
          childrenHaveErrors = true;
          break;
        }
        if(this.checkIfChildrenHaveErrorsCallBack({constExpr: childConstExpr})) {
          childrenHaveErrors = true;
          break;
        }
      }
    }
    return childrenHaveErrors;
  }

  render() {

    const { classes } = this.props;
    const { constExpr, constExprIndex, patternTypeColors, compactViewEnabled, compactViewMode, } = this.props;

    const instanceGeneratorDeclarationErrorMsg =
      constExpr.entity.instanceGeneratorDeclaration !== undefined ?
      (
        constExpr.entity.instanceGeneratorDeclaration.definitionId !== undefined ?
        (
          constExpr.entity.instanceGeneratorDeclaration.definitionId === null ?
          'Missing Generator Definition' :
          ''
        ) :
        ''
      ) :
      '';
    const lastInstanceGeneratorDeclarationArgErrorIndex =
      constExpr.entity.instanceGeneratorDeclaration !== undefined ?
      (
        constExpr.entity.instanceGeneratorDeclaration.args !== undefined &&
        constExpr.entity.instanceGeneratorDeclaration.args.length > 0 ?
        constExpr.entity.instanceGeneratorDeclaration.args.findIndex(
          obj => (
            constExpr.entity.instanceGeneratorDeclaration.name.toLowerCase() !== 'literal' &&
            constExpr.entity.instanceGeneratorDeclaration.name.toLowerCase() !== 'preflabel'
          ) ?
          obj.error_type_text !== '' :
          (
            obj.name.toLowerCase() !== 'language' ?
            obj.error_type_text !== '' :
            false
          )
        ) :
        -1
      ) :
      -1;
    const instanceGeneratorDeclarationArgsErrorMsg =
      lastInstanceGeneratorDeclarationArgErrorIndex !== -1 ?
      constExpr.entity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].name + ': ' +
      constExpr.entity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].error_type_text :
      '';

    const instanceGeneratorDeclarationErrorStyle =
      instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
      classes.generatorDeclarationError :
      '';

    const childrenHaveErrors = this.checkIfChildrenHaveErrorsCallBack({constExpr: constExpr});

    return (
      <div className={classes.constExprEdit}>
        {/*'ID: ' +  constExpr.id*/}
        <div style={{display:'flex'}}>
          <div className={classes.divIconStyleWrapper}>
            <ArrowForward style={{color: constExpr.relation.error_text !== undefined ? 'red' : 'unset'}}/>
          </div>
          <div style={{display:'flex'}}>
            <b style={{minWidth: 45, color: constExpr.relation.error_text !== undefined ? 'red' : 'unset'}}>
              Relation:
            </b>
            <div style={{display:'flex', padding: 5, marginTop:-4}}>
              {constExpr.relation.item ? constExpr.relation.item.label : ''}
            </div>
          </div>
        </div>
        <div style={{display:'flex',}}>
          <div className={classes.divIconStyleWrapper}>
            <div className={classes.divGreyOutlinedIconStyle}
                 style={{
                   borderColor: constExpr.entity.error_text !== undefined ? 'red' : 'unset',
                   backgroundColor: constExpr.entity.error_text !== undefined ? '#ff000052' : 'unset',
                 }}
            />
          </div>
          <div style={{display:'flex'}}>
            <b style={{minWidth: 45, color: constExpr.entity.error_text !== undefined ? 'red' : 'unset'}}>
              Entity:
            </b>
            <div style={{display:'flex', flexWrap: 'wrap', marginTop:-4}}>
              {
                constExpr.entity.item !== '' ?
                constExpr.entity.item.map((itemObj, index) => (
                  // Without the `key`, React will fire a key warning
                  <React.Fragment key={'constExpr_View_' + constExprIndex + '_' + index}>
                    <div style={{padding:5}}>{itemObj.label}{constExpr.entity.item.length-1 !== index ? ',' : ''}</div>
                  </React.Fragment>
                )) :
                ''
              }
            </div>
          </div>
        </div>

        {/* Generator */}
        <div style={{marginLeft: 44}}>

          {/* Variable / Generator Declaration Instance/Label*/}
          <div>

          {/*Display info indicating the presence of a native aditional*/}
            <div
              hidden={
                constExpr.constExprIds !== undefined ?
                constExpr.constExprIds.length === 0 :
                true
              }
              style={{marginTop: 10, marginBottom: 10}}
            >
              <Tooltip
                title={
                  <React.Fragment>
                    <span>
                      Native additional has been declared inside this additional.
                      <br/>
                      You can see it or change it, only while working with the
                      <br/>
                      X3ML code of this additional element.
                    </span>
                  </React.Fragment>
                }
                placement="left"
                enterDelay={500}
                leaveDelay={200}
              >
                <div className={classNames(classes.nativeAdditionalCompIcon, childrenHaveErrors ? classes.errorneousChildren : '')} style={{display: 'flex'}}>
                  <LoopIcon style={{marginTop: -2}}/>
                  <span></span><span style={{fontSize: 'x-small', marginTop: 2}}>Additional</span>
                </div>
              </Tooltip>
            </div>

            {/*Display the Variable*/}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideVariable !== undefined ?
                    compactViewMode.hideVariable :
                    false
                  ) :
                  false
                ) :
                true
              }
            >
              <div style={{display: 'flex'}}>
                <div style={{display: 'table-row'}}
                     hidden={
                       constExpr.entity.variable === undefined ?
                       true :
                       (
                         constExpr.entity.variable.value === '' ?
                         true :
                         false
                       )
                     }>
                  <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                    <Tooltip title="Variable"
                             placement="left"
                             enterDelay={500}
                             leaveDelay={200}>
                      <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                        <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}
                       hidden={
                         constExpr.entity.variable !== undefined ?
                         !constExpr.entity.variable.global :
                         true
                       }
                  >
                    <Tooltip title="Global"
                             placement="left"
                             enterDelay={500}
                             leaveDelay={200}>
                      <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                    </Tooltip>
                  </div>

                  <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                    <div className={classes.declarationLabelWrapper}>
                      {
                        constExpr.entity.variable !== undefined ?
                        constExpr.entity.variable.value :
                        ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>

            {/* Display the instance Info */}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideInstanceInfo !== undefined ?
                    compactViewMode.hideInstanceInfo :
                    false
                  ) :
                  false
                ) :
                true
              }
            >
              <div style={{display: 'flex'}}>
                <div style={{display: 'table-row'}}
                     hidden={
                       constExpr.entity.instanceInfo === undefined ?
                       true :
                       (
                         constExpr.entity.instanceInfo.constant === '' &&
                         constExpr.entity.instanceInfo.description === '' &&
                         constExpr.entity.instanceInfo.language === '' ?
                         true :
                         false
                       )
                     }>
                  <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                    <Tooltip title="Instance Info"
                             placement="left"
                             enterDelay={500}
                             leaveDelay={200}>
                      <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                        <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                    {
                      constExpr.entity.instanceInfo !== undefined ?
                      (
                        constExpr.entity.instanceInfo.constant !== undefined &&
                        constExpr.entity.instanceInfo.constant !== '' ?
                        <div
                          className={classes.declarationLabelWrapper}
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxWidth: 148,
                          }}
                        >
                          <Tooltip
                            title={constExpr.entity.instanceInfo.constant}
                            placement="bottom-start"
                            enterDelay={800}
                            leaveDelay={200}
                          >
                            <span>
                              {
                                constExpr.entity.instanceInfo !== undefined ?
                                'Constant: ' + constExpr.entity.instanceInfo.constant :
                                ''
                              }
                            </span>
                          </Tooltip>
                        </div> :
                        ''
                      ) :
                      ''
                    }
                    {
                      constExpr.entity.instanceInfo !== undefined ?
                      (
                        constExpr.entity.instanceInfo.description !== undefined &&
                        constExpr.entity.instanceInfo.description !== '' ?
                        <div
                          className={classes.declarationLabelWrapper}
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxWidth: 148,
                          }}
                        >
                          <Tooltip
                            title={constExpr.entity.instanceInfo.description}
                            placement="bottom-start"
                            enterDelay={800}
                            leaveDelay={200}
                          >
                            <span>
                              {
                                constExpr.entity.instanceInfo !== undefined ?
                                'Description: ' + constExpr.entity.instanceInfo.description :
                                ''
                              }
                            </span>
                          </Tooltip>
                        </div> :
                        ''
                      ) :
                      ''
                    }
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: 148,
                      }}
                      hidden={
                        constExpr.entity.instanceInfo !== undefined ?
                        (
                          constExpr.entity.instanceInfo.language !== undefined ?
                          constExpr.entity.instanceInfo.language.value === '' :
                          true
                        ) :
                        true
                      }
                      className={classes.declarationLabelWrapper}
                    >
                      {
                        constExpr.entity.instanceInfo !== undefined ?
                        (
                          constExpr.entity.instanceInfo.language !== undefined ?
                          'Language: ' +
                          (
                            constExpr.entity.instanceInfo.language.label !== constExpr.entity.instanceInfo.language.value ?
                            constExpr.entity.instanceInfo.language.label + ' (' + constExpr.entity.instanceInfo.language.value + ')' :
                            constExpr.entity.instanceInfo.language.value
                          ) :
                          ''
                        )
                         :
                        ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>

            {/* Display Instance Generator Declaration */}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideInstanceGenerator !== undefined ?
                    compactViewMode.hideInstanceGenerator :
                    true
                  ) :
                  true
                ) :
                true
              }
            >
              <div className={instanceGeneratorDeclarationErrorStyle}>
                <div style={{display: 'flex', width: 'fit-content'}}>
                  <div hidden={constExpr.entity.instanceGeneratorDeclaration === undefined}
                       className={classes.generatorDeclarationIconWrapperStyle}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <GeneratorInfo
                            title="Instance Generator"
                            patternTypeColors={patternTypeColors}
                            />
                        </React.Fragment>
                      }
                      placement="left"
                      enterDelay={500}
                      leaveDelay={200}
                    >
                     <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                    </Tooltip>
                  </div>
                  <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                    <div className={classes.declarationLabelWrapper}>
                      {
                        constExpr.entity.instanceGeneratorDeclaration !== undefined ?
                        constExpr.entity.instanceGeneratorDeclaration.name :
                        ''
                      }
                    </div>
                    <div className={classes.patternWithValuesAndColorsWrapper}>
                      {
                      constExpr.entity.instanceGeneratorDeclaration !== undefined ?
                      <PatternWithValuesAndColors declaration={constExpr.entity.instanceGeneratorDeclaration}
                                                  patternTypeColors={ patternTypeColors}/> :
                      ''
                      }
                    </div>
                  </div>
                  <GeneratorDeclarationError
                    generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                    errorMessage={
                      instanceGeneratorDeclarationErrorMsg !== '' ?
                       instanceGeneratorDeclarationErrorMsg :
                       (
                         instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                         instanceGeneratorDeclarationArgsErrorMsg :
                         ''
                       )
                     }
                  />
                </div>
              </div>
            </Collapse>

            {/* Display label Generator Declarations */}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideLabelGenerator !== undefined ?
                    compactViewMode.hideLabelGenerator :
                    true
                  ) :
                  true
                ) :
                true
              }
            >
              <div hidden={constExpr.entity.labelGeneratorDeclarations === undefined ? true :
                     (constExpr.entity.labelGeneratorDeclarations.length === 0 ? true : false)}>
                <div style={{display: 'table-row'}}>
                  {
                    constExpr.entity.labelGeneratorDeclarations !== undefined ?
                    constExpr.entity.labelGeneratorDeclarations.map((labelDeclaration) => {
                      const labelGeneratorDeclarationErrorMsg =
                        labelDeclaration.definitionId !== undefined ?
                        (
                          labelDeclaration.definitionId === null ?
                          'Missing Generator Definition' :
                          ''
                        ) :
                        '';
                      const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                        obj => (
                          obj.name.toLowerCase() !== 'language' ?
                          obj.error_type_text !== '' :
                          false
                        )
                      );
                      const labelGeneratorDeclarationArgsErrorMsg =
                        lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                        labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                        labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                        '';

                      const labelGeneratorDeclarationErrorStyle =
                        labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                        classes.generatorDeclarationError :
                        '';
                      return (
                        <div
                          key={labelDeclaration.id}
                          className={labelGeneratorDeclarationErrorStyle}
                          style={{display: 'flex', width: 'fit-content'}}
                          >
                          <div className={classes.generatorDeclarationIconWrapperStyle}>
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <GeneratorInfo
                                    title="Label Generator"
                                    patternTypeColors={patternTypeColors}
                                    />
                                </React.Fragment>
                              }
                              placement="left"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                            </Tooltip>
                          </div>
                          <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                            <div className={classes.declarationLabelWrapper}>
                              {labelDeclaration.name}
                            </div>
                            <div className={classes.patternWithValuesAndColorsWrapper}>
                              {
                              <PatternWithValuesAndColors declaration={labelDeclaration}
                                                          patternTypeColors={ patternTypeColors}/>
                              }
                            </div>
                          </div>
                          <GeneratorDeclarationError
                            generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                            errorMessage={
                              labelGeneratorDeclarationErrorMsg !== '' ?
                               labelGeneratorDeclarationErrorMsg :
                               (
                                 labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                 labelGeneratorDeclarationArgsErrorMsg :
                                 ''
                               )
                             }
                          />
                        </div>
                      )
                    }) :
                    ''
                  }
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

ConstExprView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConstExprView);
