import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();

const rootContextPath = configuration.rootContextPath;

export class UserProfileService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }

  // retrieveCurrentUser = async (token) => {
  //   return await axiosInstance(rootContextPath + '/retrieveCurrentUser', {
  //     method: 'get',
  //   });
  // }

  async isUserAuthenticated() {
    return await axiosInstance(rootContextPath + '/isUserAuthenticated', {
     method: 'get',
   });
  }

  async invalidateSession() {
    return await axiosInstance(rootContextPath + '/invalidateSession', {
      method: 'get',
    });
  }

  async retrieveUserByUsername(username) {
     return await axiosInstance(rootContextPath + '/retrieveUserByUsername', {
      method: 'get',
      params: username,
    });
  }

  async retrieveUserById(id) {
     return await axiosInstance(rootContextPath + '/retrieveUserById', {
      method: 'get',
      params: id,
    });
  }

  /*
  Retrieves all users
  */
  async retrieveAllUsers() {
     return await axiosInstance(rootContextPath + '/retrieveAllUsers', {
      method: 'get',
    });
  }

  /*
  Retrieves all enabled users
  */
  async retrieveAllEnabledUsers() {
     return await axiosInstance(rootContextPath + '/retrieveAllEnabledUsers', {
      method: 'get',
    });
  }

  async retrieveAllOnlineUsers() {
     return await axiosInstance(rootContextPath + '/retrieveAllOnlineUsers', {
      method: 'get',
    });
  }

  async retrieveAllOnlineUsernames() {
     return await axiosInstance(rootContextPath + '/retrieveAllOnlineUsernames', {
      method: 'get',
    });
  }

  async saveUser(data) {
     return await axiosInstance(rootContextPath + '/saveUser', {
      method: 'post',
      data: data,
    });
  }

  async updateUserPassword(data) {
     return await axiosInstance(rootContextPath + '/updateUserPassword', {
      method: 'post',
      data: data,
    });
  }

  async saveImageFromBase64String(data) {
     return await axiosInstance(rootContextPath + '/saveImageFromBase64String', {
      method: 'post',
      data: data,
    });
  }

  async downloadAvatarImageByUsername(data) {
     return await axiosInstance(rootContextPath + '/downloadAvatarImageByUsername', {
      method: 'get',
      params: data,
    }).then((response) => {
      const contentDisposition  = response.request.getResponseHeader('Content-Disposition');
      let fileName = 'unknown';
      // Checking if Content-Disposition is present in the header
      if(contentDisposition !== null && contentDisposition !== undefined) {
        // Getting the filename from the Content-Disposition
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  // Removes the users in the list
  async removeListOfUsers(data) {
     return await axiosInstance(rootContextPath + '/removeListOfUsers', {
      method: 'post',
      data: data,
    });
  }

  // Removes the users in the list and applies certain changes to the associated mappings such that there are no user related conflicts
  async removeListOfUsersAndModifyRespectiveMappings(data) {
     return await axiosInstance(rootContextPath + '/removeListOfUsersAndModifyRespectiveMappings', {
      method: 'post',
      data: data,
    });
  }
}
