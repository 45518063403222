import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import { Controlled as CodeMirror } from 'react-codemirror2'
import Icon from '@material-ui/core/Icon';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import { FileUploadService } from '.././services/FileUploadService';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/turtle/turtle');
require('codemirror/addon/edit/matchtags');

const filter = createFilterOptions();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = theme => ({
  dialogCustomPaper: {
    overflow: 'hidden',
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  autocompleteFormControl: {
    width: '100%',
    display: 'flex'
  },
});

const fileUploadService = new FileUploadService();

export class RDFVisualizerInputDialog extends React.Component {

  state ={
    tabValue: 0,
    allClasses: [],
    selectedClass: '',
    selectedInstance: '',
    markedText: '',
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      if(this.props.dialogOpen) {
        this.setState({
          tabValue: 0,
          selectedClass: '',
          selectedInstance: '',
          markedText: '',
        });
      }
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.input !== nextProps.input) {
      return true;
    }
    if(this.props.dialogTitle !== nextProps.dialogTitle) {
      return true;
    }
    if(this.props.editable !== nextProps.editable) {
      return true;
    }
    if(this.props.relativePath !== nextProps.relativePath) {
      return true;
    }
    if(this.props.inputState !== nextProps.inputState) {
      return true;
    }
    if(this.props.downloadable !== nextProps.downloadable) {
      return true;
    }
    if(this.props.outputClasses !== nextProps.outputClasses) {
      return true;
    }
    if(this.props.instancesOfSelectedOutputClass !== nextProps.instancesOfSelectedOutputClass) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleDownloadFile = props => async () => {
    await fileUploadService.downloadRdfFileByRelativePath({relativePath: props.relativePath});
  }

  getIdProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue
    });
  };

  handleChangeIndex = (index) => {
    this.setState({
      tabValue: index
    });
  };

  // Autocomplete
  handleClassInputChange = (event, item) => {
    if(item !== null) {
      this.setState({
        selectedClass: item
      }, async () => {
        // Retrieving instances of the given class (URI) ffrom the output file
        this.props.loadInstancesOfOutputClassCallBack({classUri: this.state.selectedClass})
      });
    }
  }

  handleSelectInstance = instance => () => {
    this.setState({
      selectedInstance: instance
    });
  }

  handleMarkedText = () => {
    this.setState({
      markedText: window.getSelection().toString(),
    });
  }

  hndleChangeRootUriInput = (event) => {
    this.setState({
      markedText: event.target.value,
    });
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, input, relativePath, downloadable,
      onClose, onToggleFullScreen, dialogTitle, editable, inputChange, inputState, handleSave,
      saveNow, shakeNow, outputClasses, instancesOfSelectedOutputClass,
      loadInstancesOfOutputClassCallBack, handleOpenInRdfVisualizer,
    } = this.props;

    /*
    // Beautifying the produced RDF
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(input, 'text/xml');
    var oSerializer = new XMLSerializer();
    var rdfInput = oSerializer.serializeToString(xmlDoc);
    */
    var rdfInput = input;
    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="transformed-rdf-output"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        fullWidth={true}
        maxWidth="xl"
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: classes.dialogCustomPaper,
          }
        }}>
        <DialogTitle style={{paddingBottom: 0}}>
          {dialogTitle}
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onClose}>
                          {/*padding: 'unset'*/}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent style={{height: 800, overflow: 'hidden'}}>
          <Typography variant="body1" style={{paddingBottom: 10}}>
            Please decide on the root URI to start visualising from.
            <br/>
            You can either search for it by selecting a class (1st tab) or
            directly mark it on the turtle file (2nd tab).
          </Typography>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Find Instance By Class" />
              <Tab label="Mark Instance on Turtle File" />
            </Tabs>
          </AppBar>
          <SwipeableViews
            index={this.state.tabValue}
            onChangeIndex={this.handleChangeIndex}
          >
            <TabPanel value={this.state.tabValue} index={0}>
              <div>
                <Typography variant="body1" style={{paddingBottom: 10}}>
                  Select or enter some class
                </Typography>
                <div>
                  <FormControl className={classes.autocompleteFormControl}>
                    <Autocomplete
                      options={outputClasses}
                      getOptionLabel={(option) => option}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span style={{wordBreak: 'break-word'}}>{option}</span>
                        </React.Fragment>
                      )}

                      onChange={this.handleClassInputChange}
                      value={this.state.selectedClass}

                      filterSelectedOptions={false /* if true it will remove selected from the option list */}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Classes"
                          placeholder="Please select some class"
                        />
                      )}
                    />
                    {/*getOptionSelected={(option, value) => option.value === value.value}*/}
                  </FormControl>
                </div>





                <div hidden={instancesOfSelectedOutputClass.length === 0}>
                  <Typography variant="body1" style={{paddingBottom: 10}}>
                    Please select any of the following class instances to be used as the root URI for the visualiser.
                  </Typography>
                  <List style={{overflow: 'auto', maxHeight: 'calc(100vh - 514px)'}}>
                    {
                      instancesOfSelectedOutputClass.map((instance, instanceIndex) => {
                        return (
                          <div key={'instance_' + instanceIndex}>
                            <ListItem dense>
                              <ListItemIcon>
                                <Radio  edge="start"
                                        onClick={this.handleSelectInstance(instance)}
                                        checked={
                                            this.state.selectedInstance !== undefined ?
                                            (
                                              this.state.selectedInstance.value !== undefined ?
                                              this.state.selectedInstance.value === instance.value :
                                              false
                                            ) :
                                            false
                                        }
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={instance.value}
                                secondary={
                                  instance.label !== '' ?
                                  instance.label :
                                  <span style={{fontStyle: 'italic'}}>There is no label</span>
                                }
                              />
                            </ListItem>
                            <Divider hidden={instanceIndex === instancesOfSelectedOutputClass.length-1}/>
                          </div>
                        );
                      })
                    }
                  </List>
                </div>






              </div>
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              <div>
                <Typography variant="body1" style={{paddingBottom: 10}}>
                  Please mark some URI from the Turtle file below and click the button to open in the RDF Visualiser
                  <br/>
                  {
                    editable ? '' : <span className={classes.secondaryColor}>Please note that you cannot edit this code.</span>
                  }
                </Typography>
                <div style={{overflow: 'auto', maxHeight: 'calc(100vh - 512px)'}}>
                  <div onMouseUp={this.handleMarkedText}>
                    <CodeMirror
                      editorDidMount={editor => editor.setSize(null, "auto")}
                      value={rdfInput}
                      className={classNames('codemirrorCustomStyle', 'codemirrorNoHeight')}
                      options={{
                        mode: 'text/turtle',
                        lineWrapping: true,
                        theme: 'material',
                        lineNumbers: true,
                        readOnly: !editable,
                      }}

                    />
                  </div>
                </div>
                <div style={{display: 'flex', paddingTop: 10}}>
                  <TextField
                    variant="standard"
                    label="Root URI"
                    value={this.state.markedText}
                    style={{flexGrow: 1}}
                    onChange={this.hndleChangeRootUriInput}
                  />
                </div>
              </div>

            </TabPanel>
          </SwipeableViews>




        </DialogContent>
        <DialogActions style={{paddingRight: 30, paddingLeft: 16}}>
          <div style={{flexGrow: 1}}>
            <Button
              disabled={this.state.tabValue === 0 && this.state.selectedInstance === '' || this.state.tabValue === 1 && this.state.markedText === ''}
              onClick={
                this.state.tabValue === 0 ?
                handleOpenInRdfVisualizer(this.state.selectedInstance.value) :
                handleOpenInRdfVisualizer(this.state.markedText)
              }
              color="primary"
            >
              <OpenInNewIcon />
              Open in RDF Visualizer
            </Button>
          </div>
          <Button hidden={!downloadable || this.state.tabValue === 0}
                  onClick={this.handleDownloadFile({relativePath: relativePath})}
                  color="primary">
            <Icon className={classNames(classes.icon, 'fa fa-download')} fontSize="small"/>
            Download
          </Button>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

RDFVisualizerInputDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RDFVisualizerInputDialog);
