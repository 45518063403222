import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AvatarEditor from 'react-avatar-editor'

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import CustomSnackMessage from './snackbar/CustomSnackMessage';
import UserInfo from './userProfile/UserInfo';
import {strengthColor, strengthIndicator} from './authentication/PasswordStrength';
import { UserProfileService } from './services/UserProfileService';
import { SecurityService } from './services/SecurityService';
import configuration from './configuration.json';

const userProfileService = new UserProfileService();
const securityService = new SecurityService();

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

const dirRootContextPath = configuration.dirRootContextPath; // This is different than the regular rootContextPath (/3m) and avatarRootContextPath (3m/)

const styles = theme => ({
  rootCard: {
    margin: '10px 0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    background: 'none',
    padding: 0
  },
  rightAllignedButton: {
    //marginLeft: 'auto',
    position: 'absolute',
    right: 30,
  },
  cardActionsStyle: {
    height: 52,
  },
  snackbarSuccess: {
    backgroundColor: green[600],
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 40,
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarCloseIconButton: {
    padding: theme.spacing(0.5),
  },
  snackbarErrorTextPrimary: {
    color: '#f50057',
  },
  // summaryHeading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },
  expansionPanelDetailsStyle: {
    marginTop: -24,
  },
  // /red
  redTextFieldUnderline: {
    color: 'red' ,
    '&::after': {
      border: '2px solid red'
    }
  },
  redLabelColor: {
    color: 'red',
  },
  redTexfieldColor: {
    color: 'red',
  },
  // Password Strength
  // Yellow
  yellowTextFieldUnderline: {
    color: '#faad14' ,
    '&::after': {
      border: '2px solid #faad14'
    }
  },
  yellowLabelColor: {
    color: '#faad14',
  },
  yellowTexfieldColor: {
    color: '#faad14',
  },
  // Orange
  orangeTextFieldUnderline: {
    color: '#fa8414' ,
    '&::after': {
      border: '2px solid #fa8414'
    }
  },
  orangeLabelColor: {
    color: '#fa8414',
  },
  orangeTexfieldColor: {
    color: '#fa8414',
  },
  // Lightgreen
  lightgreenTextFieldUnderline: {
    color: '#03ca03' ,
    '&::after': {
      border: '2px solid #03ca03'
    }
  },
  lightgreenLabelColor: {
    color: '#03ca03',
  },
  lightgreenTexfieldColor: {
    color: '#03ca03',
  },
  // Green
  greenTextFieldUnderline: {
    color: '#019c01' ,
    '&::after': {
      border: '2px solid #019c01'
    }
  },
  greenLabelColor: {
    color: '#019c01',
  },
  greenTexfieldColor: {
    color: '#019c01',
  },
  input: {
    width: 42,
  },
  filePondRoot: {
    marginBottom: 0,
    marginTop: 15,
  },
  filepondDropLabel: {
    height: 200,
  }
});

export class UserProfile extends React.Component {

  state = {
    user: {
      username: '',
      password: '',
      email:'',
      firstname: '',
      lastname: '',
      enabled: '',
      roles:[]
    },
    oldPassword: '',
    newPassword: '',
    newConfirmPassword: '',
    expandedPanel: 'information',
    // Avatar
    avatarEditorValueModel: {
      width: 250,
      height: 250,
      border: 50,
      scale: 1.2,
      rotate: 0,
      borderRadius: 0,
    },
    imageFile: ''
  }

  /*constructor(props) {
    super(props);
  }*/

  /*
  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }
  */

  async componentDidMount() {
    const user = await userProfileService.retrieveUserByUsername({username: this.props.currUser.currUsername});
    this.setState({
        user: user.data,
    });
  }

  handleExpandedChange = expandedPanel => (event, isExpanded) => {
    this.setState({
        expandedPanel: isExpanded ? expandedPanel : '',
    });
  }

  handleChange = name => event => {
    // Username
    if(name === 'username') {
      if (event.target.value === '') {
        this.setState({
            username_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            username_error_text: '',
        });
      }
    }

    // Password
    if(name === 'password') {
      if (event.target.value === '') {
        this.setState({
            password_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            password_error_text: '',
        });
      }
      if (event.target.value !== this.state.confirmPassword) {
        this.setState({
            confirm_password_error_text: 'The confirmed password doesn\'t much',
        });
      }
      else {
        this.setState({
            confirm_password_error_text: '',
        });
      }
    }
    // email
    if(name === 'email') {
      var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (event.target.value === '') {
        this.setState({
            email_error_text: 'This field is required',
        });
      }
      else if (!emailRegEx.test(event.target.value)) {
        this.setState({
            email_error_text: 'This email has not a valid form',
        });
      }
      else {
        this.setState({
            email_error_text: '',
        });
      }
    }

    this.setState({
      user: {
        ...this.state.user,
        [name]: event.target.value,
      }
    });
  };

  handlePasswordChange = name => event => {
    let errorHelpTextMsg = event.target.value === '' ? 'This field is required' : '';
    if(name === 'newConfirmPassword') {
      if (this.state.newPassword !== undefined ? event.target.value !== this.state.newPassword : false) {
        errorHelpTextMsg = 'The confirmed password doesn\'t much';
      }
      else{
        this.setState({
          newConfirmPassword_error_text: '',
        });
      }
    }
    else if(name === 'newPassword') {
      if (this.state.newConfirmPassword !== undefined ? event.target.value !== this.state.newConfirmPassword : false) {
        errorHelpTextMsg = 'Password doesn\'t much the confirmed one';
      }
      else{
        this.setState({
            newConfirmPassword_error_text: '',
        });
      }
      // Integer indicating strength
      const passwordStrengthValue = strengthIndicator(event.target.value);
      // Color indicating strength
      const passwordStrengthColor = strengthColor(passwordStrengthValue);
      //console.log('passwordStrengthValue: ' + passwordStrengthValue);
      //console.log('passwordStrengthColor: ' + passwordStrengthColor);
      this.setState({
        passwordStrengthColor: passwordStrengthColor,
        passwordStrengthValue: passwordStrengthValue,
      });
    }
    this.setState({
        [name]: event.target.value,
        //[name + '_error_text']: errorHelpTextMsg,
        newConfirmPassword_error_text: errorHelpTextMsg,
    });
  }

  handleSaveUser = async () => {
    if(this.userIsValid(this.state.user)) {
      try {
        let res = await userProfileService.saveUser(this.state.user);
        if(res.data.succeed) {
          this.props.handleRetrieveUserByUsernameCallBack(this.state.user.username);
          this.showSuccessSnackBar({msg: res.data.msg});
        }
        else {
          this.showErrorSnackBar({msg: res.data.msg});
        }
      }
      catch (e) {
        console.error('Some error occured while persisting the user into the database.');
        this.showErrorSnackBar({msg: 'Some error occured while persisting the user into the database.', iconSize: 'large'});
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
    else {
      this.showErrorSnackBar({msg: 'There are errors in the form. Please fix them and try again.', iconSize: 'large'});
    }
  };

  handleUpdateUserPassword = async () => {
    if(this.state.oldPassword !== '' &&
       this.state.newPassword === this.state.newConfirmPassword) {
      if (this.props.configurationSettings.enablePasswordStrengthRegulations ?
          strengthIndicator(this.state.user.password) > 3 :
          true) {
        try {
          let res = await userProfileService.updateUserPassword({
            id: this.state.user.id,
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
          });

          if(res.data.succeed) {
            this.showSuccessSnackBar({msg: res.data.msg});
          }
          else {
            this.showErrorSnackBar({msg: res.data.msg});
          }
        }
        catch (e) {
          console.error('Some error occured while updating user\'s password.');
          this.showErrorSnackBar({msg: 'Some error occured while updating user\'s password.', iconSize: 'large'});
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      }
      else {
        this.showErrorSnackBar({
          msg: 'You should set some stronger password in order to proceed with this registration form. Please try using at least 8 characters and include numbers, special characters and both low and upper case letters.'});
      }
    }
    else {
      this.showErrorSnackBar({msg: 'There are errors in the form. Please fix them and try again.', iconSize: 'large'});
    }
  };

  userIsValid = () => {
    let valid = true;
    if(valid) {
      // Username
      if(this.state.user.username === '') {
        this.setState({
            username_error_text: 'This field is required',
        });
        valid = false;
      }
      else {
        this.setState({
            username_error_text: '',
        });
        valid = true;
      }
    }
    if(valid) {
      // Email
      if(this.state.user.email === '') {
        this.setState({
            email_error_text: 'This field is required',
        });
        valid = false;
      }
      else {
        this.setState({
            email_error_text: '',
        });
        valid = true;
      }
    }
    return valid;
  }

  // ############
  // Avatar Stuff
  // ############
  onClickSave = () => {
    if (this.avatarEditor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.avatarEditor.getImage()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = this.avatarEditor.getImageScaledToCanvas()
    }
  }

  handleUploadFileProcessingError = (error) => {
    return 'Error occured while uploading image file - ' + error.body;
  }

  handleAfterImageFileUpload = async (error, fileObj) => {
    // No error
    if(error === null) {
      this.setState({
        imageFile: fileObj.file,
      });
    }
  };

  // controlName: <Text>
  handleControlSliderChange = controlName => (event, newValue) => {
    this.setState({
      avatarEditorValueModel: {
        ...this.state.avatarEditorValueModel,
        [controlName]: newValue
      }
    });
  };

  // controlName: <Text>
  handleControlInputChange = controlName => event => {
    this.setState({
      avatarEditorValueModel: {
        ...this.state.avatarEditorValueModel,
        [controlName]: event.target.value === '' ? '' : Number(event.target.value)
      }
    });
  };

  //props: {controlName: <Text>, max: <Integer>, min: <Integer>}
  handleControllInputBlur = props => event => {
    let value = undefined;
    if (event.target.value < props.min) {
      value = props.min;
    }
    else if (event.target.value > props.max) {
      value = props.max;
    }
    this.setState({
      avatarEditorValueModel: {
        ...this.state.avatarEditorValueModel,
        [props.controlName]: value
      }
    });
  };

  handleSaveAvatar = () => {
    if (this.avatarEditor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.avatarEditor.getImage();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = this.avatarEditor.getImageScaledToCanvas();
      console.log(canvasScaled);
      // Saving Image to server by base64
      this.handleSaveImageFromBase64String(canvasScaled.toDataURL());
    }
  }

  handleSaveImageFromBase64String = async (dataURL) => {
      try {
        let res = await userProfileService.saveImageFromBase64String({
          imgBase64: dataURL, // The base64 data of the image
          assignToUser: true  // Whether to associate this avatar image with the respective user (put inside the user object)
        });
        if(res.data.succeed) {
          //this.showSuccessSnackBar({msg: res.data.msg});
          console.log(res.data.msg);
          // Reload current user
          this.props.handleRetrieveUserByUsernameCallBack(this.props.currUser.currUsername);
          // This is how to download the image
          //await userProfileService.downloadAvatarImageByUsername({username: this.props.currUser.currUsername});
        }
        else {
          //this.showErrorSnackBar({msg: res.data.msg});
          console.log(res.data.msg);
        }
      }
      catch (e) {
        console.error('Some error occured while saving the image file on the server.');
        //this.showErrorSnackBar({msg: 'Some error occured while persisting the user into the database.', iconSize: 'large'});
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }

  };

  // props: {msg: <Text>}
  showSuccessSnackBar = props => {
    this.props.enqueueSnackbar(
      props.msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500
      }
    );
  }

  // props: {msg: <Text>, iconSize: 'large | small (default: 'small')'}
  showErrorSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'error'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}/>
        ),
      }
    );
  }

  render() {
    const {
      classes, configurationSettings,
    } = this.props;

    console.log('UserProfile Rendered');
    return (
      <Card className={classes.rootCard}>
        <CardContent>
          <Typography variant="h6" style={{marginBottom: 16}}>
            User Profile
          </Typography>
          <Accordion expanded={this.state.expandedPanel === 'information'}
                          onChange={this.handleExpandedChange('information')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Edit User Information</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelDetailsStyle}>
              <div style={{marginLeft: 30}}>
                <UserInfo user={this.state.user}
                          username_error_text={this.state.username_error_text}
                          email_error_text={this.state.email_error_text}
                          handleChange={this.handleChange}/>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={this.state.expandedPanel === 'credentials'}
                          onChange={this.handleExpandedChange('credentials')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Change Password</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelDetailsStyle}>
              <div style={{marginLeft: 30}}>
                <form ref={form => this.formEl = form}
                      className={classes.container}
                      autoComplete="off"
                      noValidate>
                  <TextField required
                            error={this.state.oldPassword_error_text !== undefined ? this.state.oldPassword_error_text !== '' : false}
                            helperText={this.state.oldPassword_error_text}
                            label="Current Password"
                            type="password"
                            value={this.state.oldPassword}
                            onChange={this.handlePasswordChange('oldPassword')}
                            margin="normal"
                  />
                  <TextField required
                            error={this.state.newPassword_error_text !== undefined ? this.state.newPassword_error_text !== '' : false}
                            helperText={
                              this.state.passwordStrengthColor === 'red' ||
                              this.state.passwordStrengthColor === 'yellow' ||
                              this.state.passwordStrengthColor === 'orange' ?
                              <span className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                                The password set is Weak.
                                {
                                  configurationSettings.enablePasswordStrengthRegulations ?
                                  ' Please try better!' :
                                  ' You can use it though, with your own risk!'
                                }
                              </span> :
                              (
                                this.state.passwordStrengthColor === 'lightgreen' ?
                                <span  className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                                  The password set is OK. You can do better if you like though!
                                </span>:
                                (
                                  this.state.passwordStrengthColor === 'green' ?
                                  <span  className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                                    The password set is very strong!
                                    </span> :
                                  ''
                                )
                              )
                            }
                            label="New Password"
                            type="password"
                            value={this.state.newPassword}
                            onChange={this.handlePasswordChange('newPassword')}
                            margin="normal"
                            InputLabelProps={{
                              classes: {
                                root: classes[this.state.passwordStrengthColor + 'LabelColor'],
                              }
                            }}
                            InputProps={{
                              classes: {
                                underline: classes[this.state.passwordStrengthColor + 'TextFieldUnderline'],
                                root: classes.redTexfieldColor
                              }
                            }}
                  />
                  <TextField required
                            disabled={this.state.newPassword !== undefined ? this.state.newPassword === '' : true}
                            error={this.state.newConfirmPassword_error_text !== undefined ? this.state.newConfirmPassword_error_text !== '' : false}
                            label="Confirm New Password"
                            type="password"
                            value={this.state.newConfirmPassword}
                            onChange={this.handlePasswordChange('newConfirmPassword')}
                            margin="normal"
                            helperText={this.state.newConfirmPassword_error_text}
                  />
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={this.state.expandedPanel === 'avatar'}
                          onChange={this.handleExpandedChange('avatar')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Edit User Avatar</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelDetailsStyle}>
              <div style={{marginLeft: 30, display: 'flex'}}>
                <div style={{width: 280, paddingRight: 10}}>
                  <FilePond
                    name="file"
                    labelIdle={'<div class="filePondCustomLabelStyle">Drag & Drop some avatar image here or <span class="filepond--label-action"> browse for it </div>'}
                    ref={ref => this.pond = ref}
                    server={
                      {
                        url: (dirRootContextPath === '' ? '.' : '') + dirRootContextPath + '/uploadFilepond/avatarInitImageUploaded',
                        process: {
                          headers: {
                            Authorization: Cookies.get('3mToken')
                          },
                        },
                        revert: {
                          headers: {
                            Authorization: Cookies.get('3mToken')
                          },
                          method: 'DELETE'
                        }
                      }
                    }
                    allowMultiple={false}
                    allowRevert={true}
                    allowReplace={true}
                    acceptedFileTypes={['image/*']}
                    labelFileProcessingError={this.handleUploadFileProcessingError}
                    onprocessfile={this.handleAfterImageFileUpload}
                    className={classes.filePondRoot}
                    credits="false"
                  />
                            {/*
                              imagePreviewHeight={170}
                              imageCropAspectRatio={'1:1'}
                              imageResizeTargetWidth={200}
                              imageResizeTargetHeight={200}
                              stylePanelLayout={'compact circle'}
                              styleLoadIndicatorPosition={'center bottom'}
                              styleProgressIndicatorPosition={'right bottom'}
                              styleButtonRemoveItemPosition={'left bottom'}
                              styleButtonProcessItemPosition={'right bottom'}
                              imageEditEditor={()=>('')}
                            */}
                </div>
                <Slide direction="left" in={this.state.imageFile !== ''} mountOnEnter unmountOnExit>
                  <div>
                    <AvatarEditor
                      ref={ref => this.avatarEditor = ref}
                      image={this.state.imageFile !== '' ? this.state.imageFile : ''}
                      width={250}
                      height={250}
                      border={50}
                      borderRadius={150}
                      scale={this.state.imageFile !== '' ? this.state.avatarEditorValueModel.scale : 0.6}
                      rotate={this.state.avatarEditorValueModel.rotate}
                      style={{borderRadius: 15}}
                    />
                    {/* Controlls */}
                    <div>
                      {/* scale */}
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <Slider
                            value={this.state.avatarEditorValueModel.scale}
                            min={0}
                            step={0.1}
                            max={6}
                            valueLabelDisplay="auto"
                            onChange={this.handleControlSliderChange('scale')}
                            disabled={this.state.imageFile === ''}
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            className={classes.input}
                            value={this.state.avatarEditorValueModel.scale}
                            margin="dense"
                            onChange={this.handleControlInputChange('scale')}
                            onBlur={this.handleControllInputBlur({controlName: 'scale', max: 6, min: 0})}
                            inputProps={{
                              step: 0.1,
                              min: 0,
                              max: 6,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                            }}
                            disabled={this.state.imageFile === ''}
                          />
                        </Grid>
                      </Grid>
                      {/* rotate */}
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <Slider
                            value={this.state.avatarEditorValueModel.rotate}
                            min={-180}
                            step={1}
                            max={180}
                            valueLabelDisplay="auto"
                            onChange={this.handleControlSliderChange('rotate')}
                            disabled={this.state.imageFile === ''}
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            className={classes.input}
                            value={this.state.avatarEditorValueModel.rotate}
                            margin="dense"
                            onChange={this.handleControlInputChange('rotate')}
                            onBlur={this.handleControllInputBlur({controlName: 'rotate', max: 180, min: -180})}
                            inputProps={{
                              step: 1,
                              min: -180,
                              max: 180,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                            }}
                            disabled={this.state.imageFile === ''}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Slide>
              </div>
            </AccordionDetails>
          </Accordion>
        </CardContent>
        <CardActions className={
                      this.state.expandedPanel !== undefined ?
                      (this.state.expandedPanel !== '' ? classes.cardActionsStyle : '') :
                      ''
                    }
        >
          <Fade in={this.state.expandedPanel === 'information'} unmountOnExit>
            <Button variant="contained"
                    color="primary"
                    className={classes.rightAllignedButton}
                    onClick={this.handleSaveUser}
            >
              Save User Profile
            </Button>
          </Fade>
          <Fade in={this.state.expandedPanel === 'credentials'} unmountOnExit>
            <Button variant="contained"
                    color="primary"
                    className={classes.rightAllignedButton}
                    onClick={this.handleUpdateUserPassword}
            >
              Save New Password
            </Button>
          </Fade>
          <Fade in={this.state.expandedPanel === 'avatar'} unmountOnExit>
            <Button variant="contained"
                    color="primary"
                    className={classes.rightAllignedButton}
                    onClick={this.handleSaveAvatar}
            >
              Save New Avatar
            </Button>
          </Fade>
        </CardActions>
      </Card>
    )
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(UserProfile);
