import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function ItemCard({ item, itemId }: { itemId: string }) {
  const visibility = React.useContext(VisibilityContext);

  const visible = visibility.isItemVisible(itemId);

  return (
    <div
      role="button"
      style={{
        display: "inline-block",
        userSelect: "none"
      }}
      tabIndex={0}
      className="card"
    >
      {item}
    </div>
  );
}
