import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import update from 'immutability-helper';
import Cookies from 'js-cookie';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
//import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from './Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CustomSnackMessage from './snackbar/CustomSnackMessage';
import { FileUploadService } from './services/FileUploadService';
import { PredefinedFileMetadataManageService } from './services/PredefinedFileMetadataManageService';
import { MainService } from './services/MainService';
import { SecurityService } from './services/SecurityService';
import { MappingTableViewService } from './services/MappingTableViewService';
import { CSSTransition } from 'react-transition-group';
import ConfirmSnackMessage from './snackbar/ConfirmSnackMessage';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';

import configuration from './configuration.json';

const fileUploadService = new FileUploadService();
const predefinedFileMetadataManageService = new PredefinedFileMetadataManageService();
const mainService = new MainService();
const securityService = new SecurityService();
const mappingTableViewService = new MappingTableViewService();

const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)
const dirRootContextPath = configuration.dirRootContextPath; // This is different than the regular rootContextPath (/3m) and avatarRootContextPath (3m/)

registerPlugin(
  FilePondPluginFileValidateType
);

const styles = theme => ({
  card: {
    margin: '10px',
  },
  rightAllignedButton: {
    //marginLeft: 'auto',
  },
  summaryHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  expansionPanelDetailsStyle: {
    marginTop: -24,
    //padding: '0px 24px',
  },
  rightCardContent: {
    //position: 'absolute',
    //width: 225,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    //overflowX: 'auto',
    overflow: 'auto',
    height: 'calc(100vh - 293px)',
  },
  optionsPanelWrapper: {
    height: 'calc(100vh - 340px)',
    overflowY: 'auto'
  },
  schemataCollapsiblePanelWrapper: {
    height: 'calc(100vh - 600px)',
    overflowY: 'auto'
  },
  generatorPolicycollapsiblePanelWrapper: {
    height: 'calc(100vh - 530px)',
    overflowY: 'auto'
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.light + ' !important',
  },
  onlineCell: {
    color: theme.palette.primary.main
  },
  offlineCell: {
    color: theme.palette.secondary.main
  },
  confirmWithOptionsRoot: {
    width: 1000,
    backroundColor: 'green',
  },
  // primaryCode: {
  //   color: theme.palette.primary.main,
  //   backgroundColor: '#f9f2f4',
  //   borderRadius: 4,
  //   padding: '2px 4px',
  //   fontSize: '90%',
  // },
  primaryTextButton: {
    padding: '0px 4px',
    minWidth: 0,
    fontStyle: 'italic',
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  // Namespaces Section - Related
  expandedError: {
    border: 'dotted',
    borderRadius: 10,
    borderColor: 'red',
    //color: 'red',
    textAlign: 'justify',
    padding: '10px !important',
  },
  errorIconButtonHover: {
    '&:hover': {
      backgroundColor: '#f7eaeb'
    }
  },

});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const columns = [
  { id: 'title', numeric: false, boolean: false, list: false, disablePadding: true, label: 'Name' },
  { id: 'version', numeric: false, boolean: false, list: false, disablePadding: true, label: 'Version' },
  { id: 'description', numeric: false, boolean: false, list: false, disablePadding: true, longText: true, label: 'Description' },
  { id: 'fileExtension', numeric: false, boolean: false, list: false, disablePadding: true, label: 'Type' },
  { id: 'uploadDate', numeric: false, boolean: true, list: false, disablePadding: true, date: true, label: 'Uploaded' },
];

class FileMetadataTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  // To add filter check the video:
  // https://www.youtube.com/watch?v=SX_IL7LqSxM

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, showSelectAllCheckbox } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" style={{height: 42, width: 52}}>
            <CSSTransition in={showSelectAllCheckbox}
                           timeout={200}
                           classNames="revealInOutInitHidden">
              <div className="revealInOutInitHidden">
                <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={numSelected === rowCount}
                          onChange={onSelectAllClick}
                          hidden={!showSelectAllCheckbox}
                />
              </div>
            </CSSTransition>

          </TableCell>
          {columns.map(column => {
            return (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

FileMetadataTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
    marginBottom: 10,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  filePondRoot: {
    marginBottom: 0,
    marginTop: 15,
  },
});

let TableToolbar = props => {
  const {
    numSelected, deleteModeActive, handleShowConfirmDeleteSnackbar, handleDeleteSelected, handleInputChange, handleSelectInputChange,
    searchText, columnToBeFiltered, classes, tabFileScope, inactiveRecordsAreHidden, handleToggleHideInactiveRecords,
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root,{[classes.highlight]: deleteModeActive,})}
    >
      <div className={classes.title}>
        {deleteModeActive ? (
          <Typography color="inherit" variant="body1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6">
            Management -
            {
              tabFileScope === 'targetSchemaFile' ?
              ' Predefined Target Schema Files' :
              ' Predefined Generator Policy Files'
            }
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {deleteModeActive ? (
          <Tooltip title="Delete">
            <div>
              <IconButton
                aria-label="Delete"
                color="secondary"
                onClick={
                  handleShowConfirmDeleteSnackbar({
                    handleDelete: handleDeleteSelected,
                  })
                }
                disabled={numSelected === 0}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <div style={{display: 'flex'}}>
            <div>
              <Tooltip
                title="Only display the active file records"
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={inactiveRecordsAreHidden}
                      onChange={handleToggleHideInactiveRecords}
                      value={inactiveRecordsAreHidden}
                      color="primary"
                    />
                  }
                  label="Hide Inactive"
                />
              </Tooltip>
            </div>
            <div style={{paddingRight: 10, marginTop: -12}}>
              <TextField
                label="Search"
                onChange={handleInputChange('searchText')}
                value={searchText}
                disabled={columnToBeFiltered === undefined || columnToBeFiltered === null}
              />
            </div>
            <div style={{width: 320}}>
              <Autocomplete placeholder={'Select Column Filter'}
                            options={columns.map(item => ({
                              value: item.id,
                              label: item.label,
                            }))}
                            value={columnToBeFiltered}
                            onChange={handleSelectInputChange('columnToBeFiltered')}
                            async={false}
                            isMulti={false}/>

            </div>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

TableToolbar = withStyles(toolbarStyles)(TableToolbar);

export class ManagePredefinedFileMetadata extends React.Component {

  state = {
    tabFileScope: 'targetSchemaFile', // targetSchemaFile | generatorPoliocyFile
    selectedFileMetadata: undefined,
    order: 'asc',
    orderBy: 'calories',
    selected: [],
    data: [],
    deleteModeActive: false,
    page: 0,
    rowsPerPage: 10,
    expandedPanel: 'information',
    searchText: '',
    applyToAllInstancesAsWell: false,
    fileMetadataChanged: false,
    files: [],
    // Namespaces Related
    hoveredNamespaceIndex: -1,
    inactiveRecordsAreHidden: true,
  }

  async componentDidMount() {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.11.1/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    await this.handleLoadPredefinedFileMetadataList();
  }

  handleChangeTabValue = (event, value) => {
    this.handleChangeTabValueCallBack(value);
  }

  handleChangeTabAfterConfirm = props => () => {
    this.setState({
      fileMetadataChanged: false,
    }, () => {
      let snackbarId = props.snackBarId;
      this.props.closeSnackbar(snackbarId);
      this.handleChangeTabValueCallBack(props.argument);
    });
  }

  handleChangeTabValueCallBack = value => {
    if(!this.state.fileMetadataChanged) {
      this.setState({
        tabFileScope: value,
        searchText: '',
        rowsPerPage: 10,
        columnToBeFiltered: null,
      }, async () => {
        await this.handleLoadPredefinedFileMetadataList();
      });
    }
    else {
      this.handleShowConfirmChangeSnackbarCallBack({argument: value, confirmFunction: this.handleChangeTabAfterConfirm});
    }
  };

  // props: {type: 'source | target | generatorPolicy', predefined: <Boolean>}
  handleLoadPredefinedFileMetadataList = async () => {
    try {
      const res = await fileUploadService.retrievePredefinedFileMetadataListByFileScope({
        fileScope: this.state.tabFileScope,
      });
      this.setState({data: res.data});
    }
    catch (e) {
      this.props.showErrorSnackBar({msg: 'Some error has occured while retrieving the list of predefined files.'});
      console.error('Some error has occured while retrieving the list of predefined files.');
    }
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({
        selected: state.data.map(object => object.id)
      }));
      return;
    }
    this.setState({
      selected: []
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = (event, object) => {
    this.handleClickCallBack(object);
  }

  handleClickAfterConfirm = props => () => {
    this.setState({
      fileMetadataChanged: false,
    }, () => {
      let snackbarId = props.snackBarId;
      this.props.closeSnackbar(snackbarId);
      this.handleClickCallBack(props.argument);
    });
  }

  handleClickCallBack = object => {
    if(!this.state.fileMetadataChanged) {
      // Handle multiple clicks if the edit mode is active
      if(this.state.deleteModeActive) {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(object.id);
        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, object.id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }

        this.setState({
          selected: newSelected
        });
      }

      // Handle single selected
      else {
        this.setState({
          selected: [],
          title_error_text: '',
          applyToAllInstancesAsWell: false,
          fileMetadataChanged: false,
        }, function () {
          let newSelected = [];
          newSelected = newSelected.concat(this.state.selected, object.id);
          this.setState({
            selected: newSelected,
          }, async () => {
            this.setState({
              selectedFileMetadata: object,
            }, async () => {
              // Do something
            });
          });
        });
      }
    }
    else {
      // Ask to confirm
      this.handleShowConfirmChangeSnackbarCallBack({argument: object, confirmFunction: this.handleClickAfterConfirm});
    }
  };

  // handleShowConfirmChangeSnackbar = props => event => {
  //   event.stopPropagation();
  //   this.handleShowConfirmChangeSnackbarCallBack(props);
  // }

  handleShowConfirmChangeSnackbarCallBack = props => {
    this.showConfirmSnackBar({
      msg: 'There are unsaved changes on the metadata of the selected target schema. Are you sure you want to leave without saving?',
      iconSize: 'large',
      onConfirmClick: props.confirmFunction,
      confirmButtonLabel: 'Yes and discard any changes',
      onCancelClick: this.handleCloseConfirmSnackbar,
      cancelButtonLabel: 'Cancel',
      argument: props.argument, // (in case of click row it is object and in case of changing tab it is value)
    });
  }

  handleChange = name => event => {
    let value = '';
    if(name === 'applyToAllInstancesAsWell') {
      value = event.target.checked;
      this.setState({
        [name]: value,
        fileMetadataChanged: true,
      });
    }
    else {
      if(name === 'enabled') {
        value = event.target.checked;
      }
      else {
        value = event.target.value;
        // Name (title)
        if(name === 'title') {
          if (event.target.value === '') {
            this.setState({
                title_error_text: 'This field is required',
            });
          }
          else {
            this.setState({
                title_error_text: '',
            });
          }
        }
      }
      this.setState({
        selectedFileMetadata: {
          ...this.state.selectedFileMetadata,
          [name]: value
        },
        fileMetadataChanged: true,
      });
    }
  };

  handleSaveFileMetadata = props => async () => {
    const fileMetadata = props.fileMetadata;
    const applyToAllInstancesAsWell = props.applyToAllInstancesAsWell;
    if(this.fileMetadataFormIsValid(fileMetadata)) {
      try {
        let res = await predefinedFileMetadataManageService.storePredefinedFileMetadata({
          fileMetadata: fileMetadata,
          applyToAllInstancesAsWell: applyToAllInstancesAsWell
        });
        if(res.data.succeed) {
          this.props.showSuccessSnackBar({msg: res.data.msg});
          // Reloading list of target schemata
          await this.handleLoadPredefinedFileMetadataList();
          this.setState({
            fileMetadataChanged: false,
            applyToAllInstancesAsWell: false
          })
        }
        else {
          this.props.showErrorSnackBar({msg: res.data.msg});
        }
      }
      catch (e) {
        console.error('Some error occured while persisting the target schema into the database.');
        this.props.showErrorSnackBar({msg: 'Some error occured while persisting the target schema into the database.', iconSize: 'large'});
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
    else {
      this.props.showErrorSnackBar({
        msg: 'There are errors in the target schema form.<br/>Please resolve them before trying again.',
        iconSize: 'large',
        hasHtmlContent: true
      });
    }
  };

  fileMetadataFormIsValid = fileMetadata => {
    let valid = true;
    if(valid) {
      // title
      if(fileMetadata.title === '') {
        this.setState({
            title_error_text: 'This field is required',
        });
        valid = false;
      }
      else {
        this.setState({
            title_error_text: '',
        });
        valid = true;
      }
    }
    return valid;
  }

  handleExpandedChange = expandedPanel => (event, isExpanded) => {
    this.setState({
        expandedPanel: isExpanded ? expandedPanel : '',
    });
  }

  handleDeleteModeChange = event => {
    this.setState({
      deleteModeActive: event.target.checked,
    });
  };

  handleDeleteSelected = props => async () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
  }

  handleConfirmDeleteSelected = props => async () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
  }

  // Retrieves the namespaces of the selected fileMetadata through the reasoner and updates the fileMetadata with these new namespaces. Doesn't save.
  handleResetNamnespacesOfFileMetadata = props => async () => {
    try {
      // Reading the file
      let res = await predefinedFileMetadataManageService.produceNamespacesForTargetSchemaFileMetadata({fileMetadata: this.state.selectedFileMetadata});
      if(res.data.succeed) {
        this.setState({
            selectedFileMetadata: res.data.fileMetadata,
            fileMetadataChanged: true,
        }, () => {
          this.props.showSuccessSnackBar({msg: res.data.msg});
        });
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg, iconSize: 'large', hasHtmlContent: true});
      }
    }
    catch (e) {
      console.error('The namespaces of the selected target schema could not be generated automatically.');
      this.props.showErrorSnackBar({msg: 'The namespaces of the selected target schema could not be generated automatically.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return false;
    }
  }

  handleSearchInputChange = fieldName => event => {
    this.setState({
      [fieldName]: event.target.value
    });
  }

  handleSelectColumnInputChange = fieldName => event => {
    this.setState({
      [fieldName]: event
    });
  }

  handleShowConfirmDeleteSnackbar = props => event => {
    event.stopPropagation();
    this.showConfirmSnackBar({
      msg: 'Are you sure you want to delete this target schema' + (this.state.selected.length > 1 ? 'ta' : '') + '?',
      iconSize: 'large',
      onConfirmClick: props.handleDelete,
      confirmButtonLabel: 'Yes proceed',
      onCancelClick: this.handleCloseConfirmSnackbar,
      cancelButtonLabel: 'Cancel',
      argument: props,
    });
  }

  handleCloseConfirmSnackbar = props => () => {
    this.props.closeSnackbar(props.snackBarId);
  }

  // props: {
  //    msg:                  <Text>,
  //    iconSize:             'large | small (default: 'small')'
  //    onConfirmClick:       <Function>
  //    confirmButtonLabel:   <Text>
  //    onCancelClick:        <Function>
  //    cancelButtonLabel:    <Text>,
  //    argument:             <Object> Holding anything I need to pass to ConfirmSnackMessage component
  //    showOptionsToChoose:  OPTIONAL - Boolean value that determines whether an autocomplete
  //                          component will be placed on the left of the action butons
  //    options:              OPTIONAL - List of objects of the form <label, item>
  //    label:                OPTIONAL - The label of the autocomplete
  //    placeholder:          OPTIONAL - The placeholder of the autocomplete
  //    handleInputChange:    OPTIONAL - <function> triggered after selecting an item
  //    selectedItem:         OPTIONAL - The selected item
  // }

  // props: {
  //    msg: <Text>,
  //    iconSize: 'large | small (default: 'small')'
  //    onConfirmClick: <Function>
  //    confirmButtonLabel: <Text>
  //    onCancelClick: <Function>
  //    cancelButtonLabel: <Text>,
  //    argument: <Object> Holding anything I need to pass to ConfirmSnackMessage component
  // }
  showConfirmSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <ConfirmSnackMessage
            id={key}
            msg={props.msg}
            variant={'warning'}
            iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
            hasHtmlContent={true}
            onConfirmClick={props.onConfirmClick}
            confirmButtonLabel={props.confirmButtonLabel}
            onCancelClick={props.onCancelClick}
            cancelButtonLabel={props.cancelButtonLabel}
            argument={props.argument}
            />
        ),
      }
    );
  }

  expandLongDataCell = (id, fieldName) => () => {
    let index = this.state.data.findIndex(obj => obj.id === id);
    if(index !== -1) {
      let array = this.state.data.slice(); // Create a copy
      let changedEntry = Object.assign({}, array[index]); // Copy
                  // i.e. description-expanded
      changedEntry[fieldName + '-expanded'] = changedEntry[fieldName + '-expanded'] ? false : true;
      array[index] = changedEntry;
      this.setState({
        data: array,
      });
    }
  };

  //  ##################### FilePond #######################
  handleFileProcessingError = (error) => {
    return 'Error occured while uploading the file - ' + error.body;
  }

  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Error occured while parsing the file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

  // Valdating the uploaded generator policy file
  handleFileMetadataFileValidation = async (file, type) => {
    // boolean to hold whether the file is a valid generator policy file
    let isValid = false;
    try {
      // Reading the file
      const fileContent = await this.readUploadedFileAsText(file);
      let res = undefined;
      if(this.state.tabFileScope === 'targetSchemaFile')
        res = await predefinedFileMetadataManageService.validateTargetSchemaFile({fileContent: fileContent, fileNameExtension: file.fileExtension});
      else
        res = await mappingTableViewService.validateGeneratorDefinitionPolicyFileFromString({generatorPolicyFileString: fileContent, fileNameExtension: file.fileExtension});

      if(res.data.succeed) {
        isValid = true;
        this.props.showSuccessSnackBar({msg: res.data.msg});
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg, iconSize: 'large'});
      }
    }
    catch (e) {
      console.error('This is either not a target schema file or it cannot be validated.');
      this.props.showErrorSnackBar({msg: 'This is either not a target schema file or it cannot be validated.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return false;
    }

    if(isValid) {
      return new Promise((resolve, reject) => {
          resolve('text/xml');
      });
    }
    else {
      return new Promise((resolve, reject) => {
          reject();
      });
    }
  }

  handleAfterFileUpload = async (error, file) => {
    // No error
    if(error === null) {
      let fileMetadata = {};
      fileMetadata.filename = file.filename;
      // The regEx used, removes the extension of the file no mater how many dots the file has or how long the extension
      fileMetadata.title = file.filename.replace(/\.[^/.]+$/, '');
      fileMetadata.version = '';
      fileMetadata.description = '';
      fileMetadata.fileExtension = file.fileExtension;
      fileMetadata.fileScope = this.state.tabFileScope;
      fileMetadata.fileType = file.fileType;
      fileMetadata.fileSize = file.fileSize;
      fileMetadata.relativePath = file.serverId;
      fileMetadata.predefined = true;
      fileMetadata.enabled = false;
      fileMetadata.isInitialized = false;
      fileMetadata.files = this.state.files;

      // Setting title automatically (when not set) and setting mock files
      let mockFile = {
        //lastModifiedDate: fileMetadata.uploadDate,
        name: fileMetadata.filename,
        size: fileMetadata.fileSize,
        type: fileMetadata.fileType,
      }


      // Setting namespaces in case of target schema file
      if(this.state.tabFileScope === 'targetSchemaFile') {
        try {
          // Reading the file
          let res = await predefinedFileMetadataManageService.produceNamespacesForTargetSchemaFileMetadata({fileMetadata: fileMetadata});
          if(res.data.succeed) {
            fileMetadata = res.data.fileMetadata;
          }
          else {
            this.props.showErrorSnackBar({msg: res.data.msg, iconSize: 'large', hasHtmlContent: true});
          }
        }
        catch (e) {
          console.error('The namespaces of the target schema (just uploaded) could not be generated automatically.');
          this.props.showErrorSnackBar({msg: 'The namespaces of the target schema (just uploaded) could not be generated automatically.', iconSize: 'large'});
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      }
      // Persisting
      fileMetadata = await this.storeFileMetadata(fileMetadata);
      await this.handleLoadPredefinedFileMetadataList();

      // Select the fileMetadata in the list and
      // Switching filter to show the inactive file records as well
      this.setState({
        selected: [].concat([], fileMetadata.id),
        selectedFileMetadata: fileMetadata,
        inactiveRecordsAreHidden: false,
        //files: [],
      });
    }
  };

  storeFileMetadata = async (fileMetadata) => {
    try {
      const res = await fileUploadService.storeFileMetadata(fileMetadata);
      if(res.data.succeed) {
        // Update the state with the new generated ID of this FileMetadata
        if(fileMetadata.id === undefined || fileMetadata.id === null) {
          fileMetadata.id = res.data.fileMetadataId;
        }
        return fileMetadata;
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.message, iconSize: 'large'});
        return null;
      }
    } catch (e) {
      console.error('Some error occured while storing metadata to the database!');
      this.props.showErrorSnackBar({msg: 'Some error occured while storing metadata to the database!', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  // Namespaces Related - Start

  // Handle the input change of any field in the metadata
  // props: {
  //    fieldName: '<NAME_OF_FIELD_THAT_IS_CHANGED>',
  //    nameSpaceIndex: <NUMBER_(INDEX_OF_ITEM_IN_NAMESPACE_LIST_LOCATED_IN_ITEM_IN_MAIN_LIST>)>
  // }
  handleFileMetadataInputChange = props => event => {
    const namespacesArray = update(this.state.selectedFileMetadata.namespacePrefixList, {[props.nameSpaceIndex]: {[props.fieldName]: {$set: event.target.value}}});
    // Setting it back to the state
    this.setState({
      selectedFileMetadata: {
        ...this.state.selectedFileMetadata,
        namespacePrefixList: namespacesArray
      },
      fileMetadataChanged: true,
    });
  }

  // props: {listType: 'source | targe', index: <Integer>}
  // index holds the index of the fileMetadata within the source or taget list
  addNamespaceInFileMetadata = () => {
    console.log(this.state.selectedFileMetadata);
    let namespacesArray =
      this.state.selectedFileMetadata.namespacePrefixList !== undefined && this.state.selectedFileMetadata.namespacePrefixList !== null ?
      [...this.state.selectedFileMetadata.namespacePrefixList] :
      [];

    namespacesArray.push({label: '', value: ''});
    // Setting it back to the state
    this.setState({
      selectedFileMetadata: {
        ...this.state.selectedFileMetadata,
        namespacePrefixList: namespacesArray
      },
      fileMetadataChanged: true,
    });
  }

  // props: {listType: 'source | targe', index: <Integer>, nameSpaceIndex: <Integer>}
  // index: the index of the fileMetadata within the source or taget list
  // nameSpaceIndex: the index of the namespafileMetadata within the source or taget list
  handleRemoveFileMetadataNamespace = props => () => {
    this.showConfirmSnackBar({
      msg: 'You are about to remove this namespace. Are you sure you want to proceed?',
      iconSize: 'large',
      onConfirmClick: this.handleConfirmRemoveFileMetadataNamespace,
      confirmButtonLabel: 'Yes proceed',
      onCancelClick: this.handleCloseConfirmSnackbar,
      cancelButtonLabel: 'Cancel',
      argument: props,
    });
  }

  // props: {
  //   argument: {
  //     nameSpaceIndex: <Integer>
  //   },
  //   id: <SnackBar's ID>
  // }
  handleConfirmRemoveFileMetadataNamespace = props => () => {
    const namespacesArray = [...this.state.selectedFileMetadata.namespacePrefixList];
    namespacesArray.splice(props.argument.nameSpaceIndex, 1);
    // Setting it back to the state
    this.setState({
      selectedFileMetadata: {
        ...this.state.selectedFileMetadata,
        namespacePrefixList: namespacesArray
      },
      fileMetadataChanged: true,
    }, () => {
      this.props.closeSnackbar(props.id);
    });
  }

  handleToggleHideInactiveRecords = event => {
    this.setState({
      inactiveRecordsAreHidden: event.target.checked,
    });
  };


  // Namespaces Related - End

  render() {
    const { classes, } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;

    var { data } = this.state;
    data = this.state.inactiveRecordsAreHidden ?
           data.filter(
             x => x.enabled
           ) :
           data;

    let columnIndex = this.state.searchText ? columns.findIndex(obj => obj.id === this.state.columnToBeFiltered.value) : -1;
    if(columnIndex !== -1 ? columns[columnIndex].boolean === false && columns[columnIndex].list === false : false) {
      data = this.state.searchText ?
             data.filter(
               x => x[this.state.columnToBeFiltered.value].toLowerCase().includes(this.state.searchText.toLowerCase())
             ) :
             data;
     }
     else if(columnIndex !== -1 ? columns[columnIndex].list === true : false) {
       data = this.state.searchText ?
              data.filter(
                x => x[this.state.columnToBeFiltered.value].toString().toLowerCase().includes(this.state.searchText.toLowerCase())
              ) :
              data;
     }

     const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
     console.log('ManagePredefinedFileMetadata Rendered');
     return (
      <div>
        <div style={{padding: 8}}>
          <Paper>
            <Tabs
              value={this.state.tabFileScope}
              onChange={this.handleChangeTabValue}
              textColor="primary"
              indicatorColor="primary" centered
            >
              <Tab label="Target Schema Files" value="targetSchemaFile" />
              <Tab label="Generator Policy Files" value="generatorPolicyFile" />
            </Tabs>
          </Paper>
        </div>
        <div style={{display: 'flex'}}>
          <Card className={classes.card} style={{flexGrow: 1}}>
            <CardContent style={{padding: 0}}>
              <TableToolbar
                numSelected={selected.length}
                deleteModeActive={this.state.deleteModeActive}
                handleDeleteSelected={this.handleDeleteSelected}
                handleShowConfirmDeleteSnackbar={this.handleShowConfirmDeleteSnackbar}
                handleInputChange={this.handleSearchInputChange}
                handleSelectInputChange={this.handleSelectColumnInputChange}
                searchText={this.state.searchText}
                columnToBeFiltered={this.state.columnToBeFiltered}
                inactiveRecordsAreHidden={this.state.inactiveRecordsAreHidden}
                handleToggleHideInactiveRecords={this.handleToggleHideInactiveRecords}
                tabFileScope = {this.state.tabFileScope}
              />
              <div className={classes.tableWrapper}>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <FileMetadataTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={data.length}
                    showSelectAllCheckbox ={this.state.deleteModeActive}
                  />
                  {/*onSelectAllClick={this.handleSelectAllClick}*/}
                  <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(object => {
                        const isSelected = this.isSelected(object.id);
                        return (
                          <TableRow
                            hover
                            onClick={event => this.handleClick(event, object)}
                            role="checkbox"
                            aria-checked={isSelected}
                            key={object.id}
                            selected={isSelected}
                            classes={!this.state.deleteModeActive ? {selected: classes.selectedRow} : {}}
                            style={{
                              height: 49,
                            }}
                          >
                            <TableCell padding="checkbox">
                              <CSSTransition in={this.state.deleteModeActive === true}
                                             timeout={400}
                                             classNames="revealInOutInitHidden">
                                <div className="revealInOutInitHidden">
                                  <Checkbox
                                    checked={isSelected}
                                    hidden={!this.state.deleteModeActive}
                                  />
                                </div>
                              </CSSTransition>

                            </TableCell>
                            {
                              Object.entries(object).map(([key,value]) => {
                                // Determine if longText
                                const columnIndex = columns.findIndex(
                                  obj => obj.id === key
                                );
                                // Only handle those columns set in this.props.rows array
                                if(columnIndex !== -1) {
                                  const isHidden = columns[columnIndex].hidden;
                                  const isAvatar = columns[columnIndex].avatar;
                                  const isLongText = columns[columnIndex].longText;
                                  const isVeryLongText = columns[columnIndex].longText ? value.toString().length > 100 : false;
                                  const isDate = columns[columnIndex].date;
                                  return (
                                    <TableCell
                                      key={key + "_" + object.id}
                                      ref={(el) => {this[key + "_" + object.id] = el}}
                                      component="th"
                                      scope="row"
                                      padding="none"
                                      style={{
                                        paddingRight: 20,
                                        maxWidth: !isLongText ? 'unset' : 500,
                                        color: object.enabled ? undefined : '#a5a5a5',
                                        textDecorationLine: object.enabled ? undefined : 'line-through',
                                      }}
                                    >
                                      {
                                        isHidden ?
                                        '' :
                                        isAvatar ?
                                        <Avatar src={value !== undefined ? value : ''}
                                        /> :
                                        isVeryLongText ?
                                        <div style={{display: 'flex'}}>
                                          <Collapse in={object[key+'-expanded']} collapsedSize={39} timeout="auto">
                                            <Typography
                                              noWrap={false}
                                              style={{
                                                maxHeight: 200,
                                                textAlign: 'justify',
                                                marginTop: 10,
                                                marginBottom: 10,
                                                overflowY: object[key+'-expanded'] ?
                                                           'auto' :
                                                           'unset',
                                                textOverflow: 'ellipsis',
                                                overflowX: 'hidden'
                                              }}
                                            >
                                            {value.toString()}
                                            </Typography>
                                          </Collapse>
                                          <div style={{margin: 'auto', paddingLeft: 5}}> {/*style={{margin: 'auto'}}*/}
                                            <IconButton
                                              aria-label="expand"
                                              aria-expanded={object[key+'-expanded']}
                                              size="small"
                                              onClick={this.expandLongDataCell(object.id, key)}
                                              className={clsx(classes.expand, {
                                                [classes.expandOpen]: object[key+'-expanded'],
                                              })}
                                            >
                                              <ExpandMoreIcon fontSize="inherit"/>
                                            </IconButton>
                                          </div>
                                        </div>:
                                        isLongText ?
                                        <Typography style={{textAlign: 'justify'}}>{value.toString()}</Typography> :
                                        isDate ?
                                        <span style={{fontSize: 'smaller'}}>
                                          {new Date(value).toDateString()}
                                          {' '}
                                          {new Date(value).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                        </span> :
                                        <Typography noWrap style={{textAlign: 'justify'}}>{value.toString()}</Typography>
                                      }
                                    </TableCell>
                                  );
                                }
                              })
                            }
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={columns.length + 1} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <Divider />
              <div style={{marginBottom:-14, display: 'flex', paddingBottom: 16}}>
                <div style={{flexGrow: 1}} />
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100, 200]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </div>
            </CardContent>
          </Card>
          <div>
            <Card className={classNames(classes.card, classes.optionsPanelWrapper)} style={{width: 358}}>
              <CardContent style={{paddingRight: 16, paddingBottom: 0}}>
                <Typography variant="h6" style={{marginBottom: 16}}>
                  Selected {this.state.tabFileScope === 'targetSchemaFile' ? 'Target Schema' : 'Generator Policy'}
                </Typography>
                <Fade in={this.state.selectedFileMetadata !== undefined} unmountOnExit>
                  <div className={classes.rightCardContent}>
                    <div>
                      <Accordion expanded={this.state.expandedPanel === 'information'}
                                      onChange={this.handleExpandedChange('information')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography className={classes.summaryHeading}>Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className={
                            classNames(
                              classes.expansionPanelDetailsStyle,
                              this.state.tabFileScope === 'targetSchemaFile' ?
                              classes.schemataCollapsiblePanelWrapper :
                              classes.generatorPolicycollapsiblePanelWrapper
                            )
                          }
                        >
                          <div style={{display: 'flex', flexDirection: 'column', width: '100%',}}>
                            <div style={{paddingTop: 5}}>
                              <TextField
                                label="Name"
                                value={this.state.selectedFileMetadata !== undefined ? this.state.selectedFileMetadata.title : undefined}
                                onChange={this.handleChange('title')}
                                margin="normal"
                                required
                                error={this.state.title_error_text !== '' && this.state.title_error_text !== undefined}
                                helperText={this.state.title_error_text}
                              />
                              <TextField
                                label="Version"
                                value={this.state.selectedFileMetadata !== undefined ? this.state.selectedFileMetadata.version : undefined}
                                onChange={this.handleChange('version')}
                                margin="normal"
                              />
                              <div style={{display: 'flex'}}>
                                <TextField
                                  label="Description"
                                  value={this.state.selectedFileMetadata !== undefined ? this.state.selectedFileMetadata.description : undefined}
                                  onChange={this.handleChange('description')}
                                  multiline
                                  maxRows={4}
                                  margin="normal"
                                  style={{flexGrow:1}}
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.selectedFileMetadata !== undefined ? this.state.selectedFileMetadata.enabled : false}
                                      onChange={this.handleChange('enabled')}
                                      value={this.state.selectedFileMetadata !== undefined ? this.state.selectedFileMetadata.enabled : false}
                                      color="primary"
                                    />
                                  }
                                  label="Enabled"
                                  labelPlacement="start"
                                  style={{marginLeft: 0}}
                                />
                              </div>
                            </div>
                            <div style={{width: '100%', padding: 10, display: 'flex'}}>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={this.state.applyToAllInstancesAsWell}
                                      onChange={this.handleChange('applyToAllInstancesAsWell')}
                                      value={this.state.applyToAllInstancesAsWell}
                                      color="primary"
                                    />
                                  }
                                  label="Apply to all instances"
                                />
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {/* Namespaces */}
                      {
                        this.state.tabFileScope === 'targetSchemaFile' ?
                        <Accordion expanded={this.state.expandedPanel === 'namespaces'}
                                        onChange={this.handleExpandedChange('namespaces')}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.summaryHeading}>Namespaces</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            className={
                              classNames(
                                classes.expansionPanelDetailsStyle,
                                this.state.tabFileScope === 'targetSchemaFile' ?
                                classes.schemataCollapsiblePanelWrapper :
                                classes.generatorPolicycollapsiblePanelWrapper
                              )
                            }
                          >
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%',}}>
                              <div style={{paddingTop: 5}}>
                                <Collapse
                                  in={
                                    this.state.selectedFileMetadata !== undefined ?
                                    this.state.selectedFileMetadata.namespacePrefixList !== null :
                                    false
                                  }
                                >
                                  <div>
                                    {
                                      this.state.selectedFileMetadata !== undefined ?
                                      (
                                        this.state.selectedFileMetadata.namespacePrefixList !== null && this.state.selectedFileMetadata.namespacePrefixList !== undefined ?
                                        this.state.selectedFileMetadata.namespacePrefixList.map((namespace, nameSpaceIndex) => (
                                          <div key={this.state.selectedFileMetadata.id + '_namespace_' + nameSpaceIndex}>
                                            <Grid container spacing={1}
                                              onMouseEnter={() => this.setState({hoveredNamespaceIndex: nameSpaceIndex})}
                                              onMouseLeave={() => this.setState({hoveredNamespaceIndex: -1})}
                                            >
                                              <div style={{display: 'flex', width: '100%', paddingLeft: 20}}>
                                                <TextField
                                                  label="Prefix"
                                                  value={namespace.label}
                                                  onChange={this.handleFileMetadataInputChange({fieldName: 'label', nameSpaceIndex})}
                                                  margin="normal"
                                                  style={{width: 80, paddingRight: 10}}
                                                  error={namespace.error_label_text !== '' && namespace.error_label_text !== undefined}
                                                />
                                                <TextField
                                                  label="URI"
                                                  value={namespace.value}
                                                  onChange={this.handleFileMetadataInputChange({fieldName: 'value', nameSpaceIndex})}
                                                  margin="normal"
                                                  style={{flexGrow: 1, paddingRight: 10}}
                                                  error={namespace.error_label_text !== '' && namespace.error_label_text !== undefined}
                                                />
                                                {/*This throws some waring in red !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DIV BELOW*/}
                                                <div style={{margin: 'auto', marginLeft: 0, padding: 10}}>
                                                  <Zoom in={nameSpaceIndex === this.state.hoveredNamespaceIndex}>
                                                    <Tooltip
                                                      title={'Delete Namespace'}
                                                      placement="top-end"
                                                      enterDelay={500}
                                                      leaveDelay={200}
                                                    >
                                                      <IconButton
                                                        aria-label="delete-namespace-icon-button"
                                                        size="small"
                                                        onClick={this.handleRemoveFileMetadataNamespace({nameSpaceIndex: nameSpaceIndex})}
                                                      >
                                                        <CloseIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </Zoom>
                                                </div>
                                              </div>
                                            </Grid>
                                            <Grid hidden= {namespace.error_label_text === undefined} style={{color: 'red'}}>
                                              <div style={{display: 'flex'}}>
                                                <div>
                                                  {namespace.error_label_text !== undefined ?
                                                   (
                                                     namespace.error_label_text.shortDescr !== undefined ?
                                                     namespace.error_label_text.shortDescr :
                                                     ''
                                                   ) :
                                                   ''}
                                                </div>
                                              </div>
                                            </Grid>
                                            <Grid>
                                              <Collapse
                                                in={
                                                  namespace.error_label_text !== undefined ?
                                                  (
                                                    namespace.error_label_text.expanded !== undefined ?
                                                    namespace.error_label_text.expanded :
                                                    false
                                                  ) :
                                                  false
                                                }
                                                timeout="auto"
                                                unmountOnExit
                                                style={{paddingTop: 5}}
                                              >
                                                <CardContent className={classes.expandedError}>
                                                {namespace.error_label_text !== undefined ?
                                                 (
                                                   namespace.error_label_text.longDescr !== undefined ?
                                                   namespace.error_label_text.longDescr :
                                                   'Error decription is missing.'
                                                 ) :
                                                 ''}
                                                </CardContent>
                                              </Collapse>
                                            </Grid>
                                          </div>
                                        )) :
                                        ''
                                      ) :
                                      ''
                                    }
                                  </div>
                                </Collapse>
                                <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,}}>
                                  <div style={{margin: 'auto', paddingRight: 10}} >
                                    <Button
                                      variant="contained"
                                      className={classes.rightAllignedButton}
                                      onClick={
                                        this.handleResetNamnespacesOfFileMetadata({
                                          fileMetadata: this.state.selectedFileMetadata,
                                        })
                                      }
                                      size="small"
                                      color="primary"
                                    >
                                      Generate Namespaces
                                    </Button>
                                  </div>
                                  <div style={{margin: 'auto'}}>
                                    <Tooltip
                                      title="Add new namespace"
                                      placement="bottom"
                                      enterDelay={500}
                                      leaveDelay={200}
                                    >
                                      <Fab
                                        color="primary"
                                        aria-label="handle-add-namespace"
                                        size="small"
                                        onClick={this.addNamespaceInFileMetadata}
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion> :
                        ''
                      }

                      {/* Common Actions */}
                      <div style={{width: '100%', padding: 10, display: 'flex', justifyContent: 'flex-end'}}>
                        <div style={{textAlign: 'right'}}>
                          <Button
                            variant="contained"
                            className={classes.rightAllignedButton}
                            onClick={
                              this.handleSaveFileMetadata({
                                fileMetadata: this.state.selectedFileMetadata,
                                applyToAllInstancesAsWell: this.state.applyToAllInstancesAsWell
                              })
                            }
                            color={!this.state.fileMetadataChanged ? 'primary' : 'secondary'}
                          >
                            Save
                          </Button>
                        </div>
                      </div>




                    </div>
                  </div>
                </Fade>
                <Fade in={this.state.selectedFileMetadata === undefined} unmountOnExit>
                  <div className={classes.rightCardContent}>
                    <Typography variant="body1" style={{marginBottom: 16, fontStyle: 'italic'}}>
                      Please Select some {this.state.tabFileScope === 'targetSchemaFile' ? 'target Schema' : 'generator policy'} from the list to gain more options
                    </Typography>
                  </div>
                </Fade>
              </CardContent>
            </Card>
            <Card className={classes.card} style={{width: 358}}>
              <CardContent style={{paddingRight: 16, paddingBottom: 0}}>
                <Typography variant="h6" style={{marginBottom: 16}}>
                  Upload New Target Schema
                </Typography>
                <div style={{display: 'flex', margin: 10, }}>
                  <div style={{flexGrow: 1}}>
                    <FilePond
                      name="file"
                      labelIdle={'<div class="filePondCustomLabelStyle">Drag & Drop the ' + (this.state.tabFileScope === 'targetSchemaFile' ? 'target Schema' : 'generator policy') + ' file here or <span class="filepond--label-action"> browse for it </div>'}
                      ref={ref => this.pond = ref}
                      files={this.state.files}
                      server={
                        {
                          url: (dirRootContextPath === '' ? '.' : '') + dirRootContextPath + '/uploadFilepond/' + this.state.tabFileScope,
                          process: {
                            headers: {
                              Authorization: Cookies.get('3mToken')
                            },
                          },
                          revert: {
                            headers: {
                              Authorization: Cookies.get('3mToken')
                            },
                            method: 'DELETE'
                          }
                        }
                      }
                      allowMultiple={false}
                      allowRevert={false}
                      allowReplace={true}
                      acceptedFileTypes={this.state.tabFileScope === 'targetSchemaFile' ? undefined : ['text/xml']}
                      fileValidateTypeDetectType={this.handleFileMetadataFileValidation}
                      labelFileProcessingError={this.handleFileProcessingError}
                      onprocessfile={this.handleAfterFileUpload}
                      className={classes.filePondRoot}
                      credits="false"
                    />
                    {/*acceptedFileTypes={['application/rdf+xml', 'application/rdf+xml']}*/}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

ManagePredefinedFileMetadata.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(ManagePredefinedFileMetadata);
