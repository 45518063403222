import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();
const rootContextPath = configuration.rootContextPath;

export class GeneratorService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }

  /*
  Retrieving the Generator Definitions
  */
  async convertGeneratorFileToJsonByMappingProjectId(id) {
     return await axiosInstance(rootContextPath + '/convertGeneratorFileToJsonByMappingProjectId', {
      method: 'get',
      params: id,
    });
  }

  /*
  Store the definitions from the generator policy file to the db
  in the MappingProject collection
  */
  async storeGeneratorDefinitionsFromFileToMappingProjectWithId(id) {
     return await axiosInstance(rootContextPath + '/storeGeneratorDefinitionsFromFileToMappingProjectWithId', {
      method: 'get',
      params: id,
    });
  }

  /*
  Returns an array of generator definitions from the filepath of some generator policy file
  */
  async constructGeneratorDefinitionsArrayFromFilePath(filePathStr) {
     return await axiosInstance(rootContextPath + '/constructGeneratorDefinitionsArrayFromFilePath', {
      method: 'get',
      params: filePathStr,
    });
  }

  // Stores the passed GeneratorDefinition to the database in the respective collection
  async saveGeneratorDefinition(data) {
     return await axiosInstance(rootContextPath + '/saveGeneratorDefinition', {
      method: 'post',
      data: data,
    });
  }
  // Removes the passed GeneratorDefinition from the database
  async removeGeneratorDefinition(data) {
     return await axiosInstance(rootContextPath + '/removeGeneratorDefinition', {
      method: 'post',
      data: data,
    });
  }

  // Removes the passed GeneratorDefinitions from the database
  async removeListOfGeneratorDefinitions(data) {
     return await axiosInstance(rootContextPath + '/removeListOfGeneratorDefinitions', {
      method: 'post',
      data: data,
    });
  }

  // Retrieve all the Generator Definitions from the database
  async retrieveAllGeneratorDefinitions() {
     return await axiosInstance(rootContextPath + '/retrieveAllGeneratorDefinitions', {
      method: 'get',
    });
  }
}
