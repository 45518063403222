import React from 'react';
import update from 'immutability-helper';
import Lodash from "lodash";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import {CSSTransition, TransitionGroup } from 'react-transition-group';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import classNames from 'classnames';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InputIcon from '@material-ui/icons/Input';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import amber from '@material-ui/core/colors/amber';
//import Autocomplete from '.././Autocomplete';
import FileAndMetadataUploader from './FileAndMetadataUploader';
import { MainService } from '.././services/MainService';
import { MappingTableViewService } from '.././services/MappingTableViewService';
import { FileUploadService } from '.././services/FileUploadService'
import { GeneratorService } from '.././services/GeneratorService';

const styles = theme => ({
  dialogPopperRoot: {
    //overflow: 'visible'
  },
  stepIconCompleted: {
    color: '#3fbd00 !important'
  },
  stepperRoot: {
      margin: 5,
  },
  targetRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 'unset',
    paddingTop: 15,
    background: 'unset',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  iconContainer: { // define styles for icon container
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formBodyContainer: {
    marginBottom: 15,
  },
  formMainBodyContainer: {
    marginBottom: 15,
    //overflow: 'auto',
    //overflow: 'visible'
  },
  targetFormControl: {
    width: '100%',
  },
  formRoot: {
    //flexGrow: 1,
    padding: 0,
    background: 'none'
  },
  /*
  autocompleteFormControl: {
    minWidth: 120,
    display: 'flex'
  },
  */
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  markedDownBlue: {
    backgroundColor: '#40a9ff38',
    borderRadius: 3,
    //fontSize: '85%',
    margin: 0,
    padding: '.2em .4em',
    fontSize: 14
  },
  predifinedFileListRoot: {
    margin: -24,
    backgroundColor: theme.palette.background.paper,
  },
  snackbarWarning: {
    backgroundColor: amber[700],
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 40,
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarCloseIconButton: {
    padding: theme.spacing(0.5),
  },
});

function getSteps() {
  return ['Mapping Information', 'Source Input', 'Target Schema', 'URI Generator Policy', 'Confirmation'];
}

const mainService = new MainService();
const mappingTableViewService = new MappingTableViewService();
const fileUploadService = new FileUploadService();
const generatorService = new GeneratorService();

export class InsertMappingDialog extends React.Component {
  state = {
    failedStep: -1,
    activeStep: 0,
    skipped: new Set(),
    sourceInputExpandedPanel: 'sourceInputPredefinedPanel',
    targetSchemaExpandedPanel: 'targetSchemaPredefinedPanel',
    generatorPolicyExpandedPanel: 'generatorPolicyPredefinedPanel',
    metadata: {
      title: '',
      description: '',
      sourceInputs: [],
      targetSchemata: [],
      generatorPolicy: null,
      status: 'Draft',
    },
    sourceInputError: false,
    targetSchemaError: false,
    generatorPolicyError: false,
    // Holding the available predifined fileMetadata instances
    predifinedSourceInputList: [],
    predifinedTargetSchemaList: [],
    predifinedGeneratorPolicyList: [],
    // Holding the selected fileMetadata instances from the list of predifined
    sourceSelectedPredifinedFileMetadata: [],
    targetSchemaSelectedPredifinedFileMetadata: [],
    generatorPolicySelectedPredifinedFileMetadata: [],
    // Holding the created fileMetadata instances for the uploaded files
    sourceFileMetadataList: [],
    targetSchemaFileMetadataList: [],
    generatorPolicyFileMetadataList: [],
    // Merged Arrays (selected + new generated)
    sourceMergedFileMetadataList: [],
    targetSchemaMergedFileMetadataList: [],
    generatorPolicyMergedFileMetadataList: [],
    snackbarShown: false,
  };

  constructor(props) {
    super(props);
    this.handleNext=this.handleNext.bind(this);
    //this.handleMarkFieldAsInvalid=this.handleMarkFieldAsInvalid.bind(this);
    //this.storeFileMetadataAndUpdateId=this.storeFileMetadataAndUpdateId.bind(this);
  }

  async componentDidMount() {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.11.1/css/all.css',
      document.querySelector('#insertion-point-jss'),
    );

    // Load SourceInput List
    try {
      const tmpSourceInputs = await fileUploadService.retrievePredefinedFileMetadataListByFileScopeAndEnability(
        {fileScope: 'sourceFile', enabled: true}
      );
      if(tmpSourceInputs.data !== false) {
        this.setState({
            predifinedSourceInputList: tmpSourceInputs.data
        }, function() {
          // In case that there are no predifined, then expand the panel for adding new fileMetadata
          if(this.state.predifinedSourceInputList.length === 0)
            this.setState({
                sourceInputExpandedPanel: 'sourceInputAddNewPanel'
            })
        });
      }
      else {
        //
      }
    } catch (e) {
      console.error('Failure while retrieving File Metadata List!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }

    // Load target Schemata List
    try {
      const tmpTargetSchemata = await fileUploadService.retrievePredefinedFileMetadataListByFileScopeAndEnability(
        {fileScope: 'targetSchemaFile', enabled: true}
      );
      if(tmpTargetSchemata.data !== false) {
        this.setState({
            predifinedTargetSchemaList: tmpTargetSchemata.data
        }, function() {
          // In case that there are no predifined, then expand the panel for adding new fileMetadata
          if(this.state.predifinedTargetSchemaList.length === 0)
            this.setState({
                targetSchemaExpandedPanel: 'targetSchemaAddNewPanel'
            })
        });
      }
      else {
        //
      }
    } catch (e) {
      console.error('Failure while retrieving File Metadata List!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      
    }

    // Load generator Policy List
    try {
      const generatorPolicies = await fileUploadService.retrievePredefinedFileMetadataListByFileScopeAndEnability(
        {fileScope: 'generatorPolicyFile', enabled: true}
      );
      if(generatorPolicies.data !== false) {
        this.setState({
            predifinedGeneratorPolicyList: generatorPolicies.data
        }, function() {
          // In case that there are no predifined, then expand the panel for adding new fileMetadata
          if(this.state.predifinedGeneratorPolicyList.length === 0)
            this.setState({
                generatorPolicyExpandedPanel: 'generatorPolicyAddNewPanel'
            })
        });
      }
      else {
        //
      }
    } catch (e) {
      console.error('Failure while retrieving File Metadata List!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // load selected metadata (if any)
    if(this.props.selectedMetadataId !== undefined) {
      if(this.props.selectedMetadataId !== prevProps.selectedMetadataId) {
        //const selectedMetadata = Object.assign({}, this.props.selectedMetadata);
        const mappingProjectMetadataOnly = await this.retrieveCurrentMappingProjectMetadataOnlyCallBack({id: this.props.selectedMetadataId});
        const sourceTargetFileMetadataObject = await this.retrieveFileMetadataListByMappingProjectId({id: mappingProjectMetadataOnly.id});
        // Handling source & target filemetadata:
        // - Enhancing source filemetadat with mock files;
        // - Splitting target filemetada into normal and predefined; and
        // - Enhancing the normal  target filemetadata with mock files.
        this.handleSourceTargetFileMetadataCallBack(sourceTargetFileMetadataObject);
        // Retrieving the generator policy fileMetadata
        // Handing the generator policy file (always one, either normal or predefined)
        let generatorPolicyFileMetadata = undefined
        if(mappingProjectMetadataOnly.generatorPolicy !== null && mappingProjectMetadataOnly.generatorPolicy !== undefined) {
          if(mappingProjectMetadataOnly.generatorPolicy.value !== '' && mappingProjectMetadataOnly.generatorPolicy.value !== undefined) {
            generatorPolicyFileMetadata = await this.retrieveFileMetadataById(mappingProjectMetadataOnly.generatorPolicy.value);
            this.handleGeneratorPolicyFileMetadataCallBack([generatorPolicyFileMetadata]);
          }
        }

        this.setState({
          metadata: mappingProjectMetadataOnly,
          /*metadata: {
            ...this.state.metadata,
            id: mappingProjectMetadataOnly.id,
            title: mappingProjectMetadataOnly.title,
            description: mappingProjectMetadataOnly.description,
            author: mappingProjectMetadataOnly.author,
            sourceInputs: mappingProjectMetadataOnly.sourceInputs,
            targetSchemata: mappingProjectMetadataOnly.targetSchemata,
            generatorPolicy: mappingProjectMetadataOnly.generatorPolicy,
            status: 'Draft',
          },
          */

        }, () => {
          let activeStep = 0;
          if(mappingProjectMetadataOnly.sourceInputs !== null && mappingProjectMetadataOnly.sourceInputs !== undefined ?
             (
                mappingProjectMetadataOnly.sourceInputs.length === 0 ? // there is not any filemetadata form added yet
                true :
                sourceTargetFileMetadataObject.sourceFileMetadataList.filter(fileItem => fileItem.filename === undefined || fileItem.filename === null).length > 0
              ) :
              true) {
            activeStep = 1;
          }
          else if(mappingProjectMetadataOnly.targetSchemata !== null && mappingProjectMetadataOnly.targetSchemata !== undefined ?
             (
                mappingProjectMetadataOnly.targetSchemata.length === 0 ? // there is not any filemetadata form added yet
                true :
                sourceTargetFileMetadataObject.targetSchemaMergedFileMetadataList.filter(
                  fileItem => fileItem.filename === undefined || fileItem.filename === null
                ).length > 0
              ) :
              true) {
            activeStep = 2;
          }
          else if(
            mappingProjectMetadataOnly.generatorPolicy === null || mappingProjectMetadataOnly.generatorPolicy === undefined ?
            true :
            (
              generatorPolicyFileMetadata !== undefined ?
              generatorPolicyFileMetadata.filename === undefined || generatorPolicyFileMetadata.filename === null :
              true
            )
          ) {
            activeStep = 3;
          }
          // else if(mappingProjectMetadataOnly.generatorPolicy === null || mappingProjectMetadataOnly.generatorPolicy === undefined) {
          //   activeStep = 3;
          // }
          else {
            activeStep = 4;
          }
          this.setState({ activeStep: activeStep });
        });
      }
    }

    if (!prevProps.wizardShown && this.props.wizardShown) {
      if(this.props.selectedMetadataId === undefined) {
        // Reseting metadata
        this.setState({
          metadata: {
            title: '',
            description: '',
            sourceInputs: [],
            targetSchemata: [],
            generatorPolicy: null,
            status: 'Draft',
          }
        });
      }
    }
  }

  // props: {id: <Text>}
  retrieveCurrentMappingProjectMetadataOnlyCallBack = async props => {
    // Retrieve current MappingProject
    try {
      let res = await mainService.retrieveMappingProjectById(props);
      if(res.data !== false) {
        return res.data;
      }
    } catch (e) {
      console.error('Some error occured while retrieving the mapping project!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  // props: {id: <Text>}
  retrieveFileMetadataListByMappingProjectId = async (props) => {
    let sourceTargetFileMetadataObject = {};
    try {
      var res = await mappingTableViewService.retrieveFileMetadataListByMappingProjectId({id: props.id});
      if(res.data !== undefined) {
        console.log('retrieveFileMetadataListByMappingProjectId');
        console.log(res.data);
        const targetSchemaMergedFileMetadataList = res.data.targetFiles.slice(); // Copy
        const sourceFileMetadataList = res.data.sourceFiles.slice(); // Copy
        sourceTargetFileMetadataObject.sourceFileMetadataList = sourceFileMetadataList;
        sourceTargetFileMetadataObject.targetSchemaMergedFileMetadataList = targetSchemaMergedFileMetadataList;
      }
      else {
        console.error('Some error occured while retrieving the fileMetadat of this project');
      }
    }

    catch (e) {
      console.error('Some error occured while retrieving the fileMetadat of this project');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
    return sourceTargetFileMetadataObject;
  }

  retrieveFileMetadataById = async (id) => {
    console.log('retrieveFileMetadataById');
    let fileMetadata = {};
    try {
      var res = await fileUploadService.retrieveFileMetadataById({id: id});
      if(res.data !== undefined) {
        fileMetadata = res.data;
      }
      else {
        console.error('Some error occured while retrieving the fileMetadata');
      }
    }

    catch (e) {
      console.error('Some error occured while retrieving the fileMetadata');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
    return fileMetadata;
  }

  // props: {sourceFileMetadataList: [fileMetadata_Objects], sourceTargetFileMetadataObject: [fileMetadata_Objects]}
  handleSourceTargetFileMetadataCallBack = props => {
    const sourceFileMetadataList = props.sourceFileMetadataList;
    const targetSchemaMergedFileMetadataList = props.targetSchemaMergedFileMetadataList;

    // Enhancing source files (never predefined) with mock files
    let sourceMockfiles = []; // Initial Loaded files set
    sourceFileMetadataList.forEach(fileMetadata => {
      if(fileMetadata.filename !== undefined && fileMetadata.filename !== null) {
        let mockFile = {
          lastModifiedDate: fileMetadata.uploadDate,
          name: fileMetadata.filename,
          size: fileMetadata.fileSize,
          type: fileMetadata.fileType,
        }
        sourceMockfiles.push(mockFile);
        fileMetadata.files = sourceMockfiles;
      }
    });

    // Preparing Target schema files
    // Recognizing which are predefined and which normal
    let targetSchemaFileMetadataList = []; // To hold "normal "filemetadata enchanced with mock files
    let targetSchemaSelectedPredifinedFileMetadata = []; // To hold "predefined "filemetadata
    let targetMockfiles = []; // Initial Loaded files set

    targetSchemaMergedFileMetadataList.forEach(fileMetadata => {
      // Normal ones
      // Enhancing taregt files (normal ones) with mock files
      if(fileMetadata.copyOfId === null) {
        if(fileMetadata.filename !== undefined && fileMetadata.filename !== null) {
          let mockFile = {
            lastModifiedDate: fileMetadata.uploadDate,
            name: fileMetadata.filename,
            size: fileMetadata.fileSize,
            type: fileMetadata.fileType,
          }
          targetMockfiles.push(mockFile);
          fileMetadata.files = targetMockfiles;
        }
        targetSchemaFileMetadataList.push(fileMetadata);
      }
      // predefined ones
      else {
        targetSchemaSelectedPredifinedFileMetadata.push(fileMetadata);
      }
    });
    // Save the list of fileMetadata of this project in the state
    this.setState({
      sourceFileMetadataList: sourceFileMetadataList,
      targetSchemaFileMetadataList: targetSchemaFileMetadataList,
      targetSchemaSelectedPredifinedFileMetadata: targetSchemaSelectedPredifinedFileMetadata,
      targetSchemaMergedFileMetadataList: targetSchemaMergedFileMetadataList,
    }, () => {
      // ...
    });
  }

  // props: {}
  handleGeneratorPolicyFileMetadataCallBack = generatorPolicyMergedFileMetadataList => {

    // Preparing Generator Policy files
    // Recognizing which are predefined and which normal
    let generatorPolicyFileMetadataList = []; // To hold "normal "filemetadata enchanced with mock files
    let generatorPolicySelectedPredifinedFileMetadata = []; // To hold "predefined "filemetadata
    let generatorPoliyMockfiles = []; // Initial Loaded files set

    generatorPolicyMergedFileMetadataList.forEach(fileMetadata => {
      // Normal ones
      // Enhancing taregt files (normal ones) with mock files
      if(fileMetadata.copyOfId === null) {
        if(fileMetadata.filename !== undefined && fileMetadata.filename !== null) {
          let mockFile = {
            lastModifiedDate: fileMetadata.uploadDate,
            name: fileMetadata.filename,
            size: fileMetadata.fileSize,
            type: fileMetadata.fileType,
          }
          generatorPoliyMockfiles.push(mockFile);
          fileMetadata.files = generatorPoliyMockfiles;
        }
        generatorPolicyFileMetadataList.push(fileMetadata);
      }
      // predefined ones
      else {
        generatorPolicySelectedPredifinedFileMetadata.push(fileMetadata);
      }
    });
    // Save the list of fileMetadata of this project in the state
    this.setState({
      generatorPolicyFileMetadataList: generatorPolicyFileMetadataList,
      generatorPolicySelectedPredifinedFileMetadata: generatorPolicySelectedPredifinedFileMetadata,
      generatorPolicyMergedFileMetadataList: generatorPolicyMergedFileMetadataList,
    }, () => {
      // ...
    });
  }

  // If I have optional steps they could be declared here
  isStepOptional = step => {
    //return step === 1;
    //return step === 1 || step === 2;
    return false;
  };

  // Method trigered when pressing the 'Next' button
  handleNext = async () => {
    var allowedToContinue = true;  // Controlling whether this step is fulfilled
    const { activeStep } = this.state;
    // Case Metadata Info
    if(activeStep === 0) {
      // In case that the form is invalid, mark the
      // 'allowedToContinue' flag as false
      if(this.formMapingMetadata.checkValidity() === false) {
        allowedToContinue = false;
        this.setState({
            failedStep: 0
        });
        // Username
        if (this.state.metadata.title === '') {
          this.setState({
              mappingTitle_error_text: 'This field is required',
          });
        }
        else {
          this.setState({
              mappingTitle_error_text: '',
          });
        }
      }
      else {
        this.setState({
            failedStep: -1
        });
      }
    }

    // SourceInput
    else if(activeStep === 1) {

      // In case that the form is invalid, mark the
      // 'allowedToContinue' flag as false
      //if (this.formSourceInput.checkValidity() === false) {

      let tmpArray = this.state.sourceFileMetadataList.slice(); // Create a copy

      if(this.state.sourceFileMetadataList.length === 0 && this.state.sourceSelectedPredifinedFileMetadata.length === 0) {
        allowedToContinue = false;
        this.setState({
            failedStep: 1,
            sourceInputError: true,
            sourceInputList_error_text: this.state.predifinedSourceInputList.length > 0 ?
                                        'Please add at least one file of your own, since no predefined files have been selected.' :
                                        'Please add at least one file here, in order to continue',
            sourceSelectedPredifined_error_text: 'Please select at least one file from the available list or add your own.',
        });
      }
      else {
        this.state.sourceFileMetadataList.forEach((fileMetadata, index) => {
          if(fileMetadata.filename !== undefined && (fileMetadata.title !== undefined && fileMetadata.title !== '')) {
            this.setState({
                failedStep: -1,
                sourceInputError: false,
                sourceInputList_error_text: '',
                sourceSelectedPredifined_error_text: '',
            });
          }

          else {
            allowedToContinue = false;
            this.setState({
                failedStep: 1,
                sourceInputError: true,
                sourceInputList_error_text: 'In order to continue, all required fileds must be filled in and a file must be attached.',
                sourceSelectedPredifined_error_text: '',
            });
          }

          // Update the error text for the title and the filePond
          tmpArray = update(tmpArray, {
            [index]: {
              title_error_text: {$set: fileMetadata.title === undefined || fileMetadata.title === '' ? 'This field is Required' : ''},
              file_error_text: {$set: fileMetadata.filename === undefined || fileMetadata.filename === '' ? 'This field is Required' : ''},
            }
          });

      	});

        if(this.state.sourceFileMetadataList.length === 0) {
          this.setState({
            sourceInputError: false,
            failedStep: -1,
          });
        }

        this.setState({
          sourceFileMetadataList: tmpArray,
        });

      }

    }

    // targetSchema
    else if(activeStep === 2) {

      // In case that the form is invalid, mark the
      // 'allowedToContinue' flag as false
      //if (this.formTargetSchema.checkValidity() === false) {

      let tmpArray = this.state.targetSchemaFileMetadataList.slice(); // Create a copy

      if(this.state.targetSchemaFileMetadataList.length === 0 && this.state.targetSchemaSelectedPredifinedFileMetadata.length === 0) {
        allowedToContinue = false;
        this.setState({
            failedStep: 2,
            targetSchemaError: true,
            targetSchemaList_error_text: this.state.predifinedTargetSchemaList.length > 0 ?
                                        'Please add at least one file of your own, since no predefined files have been selected.' :
                                        'Please add at least one file here, in order to continue',
            targetSelectedPredifined_error_text: 'Please select at least one file from the available list or add your own.',
        });
      }

      else {
        this.state.targetSchemaFileMetadataList.forEach((fileMetadata, index) => {
          if(fileMetadata.filename !== undefined && (fileMetadata.title !== undefined && fileMetadata.title !== '')) {
            this.setState({
                failedStep: -1,
                targetSchemaError: false,
                targetSchemaList_error_text: '',
                targetSelectedPredifined_error_text: '',
            });
          }

          else {
            allowedToContinue = false;
            this.setState({
                failedStep: 2,
                targetSchemaError: true,
                targetSchemaList_error_text: 'In order to continue, all required fileds must be filled in and a file must be attached.',
                targetSelectedPredifined_error_text: '',
            });
          }

          // Update the error text for the title and the filePond
          tmpArray = update(tmpArray, {
            [index]: {
              title_error_text: {$set: fileMetadata.title === undefined || fileMetadata.title === '' ? 'This field is Required' : ''},
              file_error_text: {$set: fileMetadata.filename === undefined || fileMetadata.filename === '' ? 'This field is Required' : ''},
            }
          });

      	});

        if(this.state.targetSchemaFileMetadataList.length === 0) {
          this.setState({
            targetSchemaError: false,
            failedStep: -1,
          });
        }

        this.setState({
          targetSchemaFileMetadataList: tmpArray,
        });

      }

    }

    // generatorPolicy
    else if(activeStep === 3) {

      // In case that the form is invalid, mark the
      // 'allowedToContinue' flag as false
      //if (this.formTargetSchema.checkValidity() === false) {

      let tmpArray = this.state.generatorPolicyFileMetadataList.slice(); // Create a copy

      if(this.state.generatorPolicyFileMetadataList.length === 0 && this.state.generatorPolicySelectedPredifinedFileMetadata.length === 0) {
        allowedToContinue = false;
        this.setState({
            failedStep: 3,
            generatorPolicyError: true,
            generatorPolicyList_error_text: this.state.predifinedGeneratorPolicyList.length > 0 ?
                                        'Please add at least one file of your own, since no predefined files have been selected.' :
                                        'Please add at least one file here, in order to continue',
            generatorPolicySelectedPredifined_error_text: 'Please select at least one file from the available list or add your own.',
        });
      }

      else {
        this.state.generatorPolicyFileMetadataList.forEach((fileMetadata, index) => {
          if(fileMetadata.filename !== undefined && (fileMetadata.title !== undefined && fileMetadata.title !== '')) {
            this.setState({
                failedStep: -1,
                generatorPolicyError: false,
                generatorPolicyList_error_text: '',
                generatorPolicySelectedPredifined_error_text: '',
            });
          }

          else {
            allowedToContinue = false;
            this.setState({
                failedStep: 3,
                generatorPolicyError: true,
                generatorPolicyList_error_text: 'In order to continue, all required fileds must be filled in and a file must be attached.',
                generatorPolicySelectedPredifined_error_text: '',
            });
          }

          // Update the error text for the title and the filePond
          tmpArray = update(tmpArray, {
            [index]: {
              title_error_text: {$set: fileMetadata.title === undefined || fileMetadata.title === '' ? 'This field is Required' : ''},
              file_error_text: {$set: fileMetadata.filename === undefined || fileMetadata.filename === '' ? 'This field is Required' : ''},
            }
          });

      	});

        if(this.state.generatorPolicyFileMetadataList.length === 0) {
          this.setState({
            generatorPolicyError: false,
            failedStep: -1,
          });
        }

        this.setState({
          generatorPolicyFileMetadataList: tmpArray,
        });

      }

    }

    // Finish action
    else if(activeStep === getSteps().length - 1) {
      this.setState({
        metadata: {
          ...this.state.metadata,
          status: 'In Progress',
        },
      });
    }

    // Steps actions if valid
    // Continue only if the 'allowedToContinue' is true
    if(allowedToContinue) {
      let { skipped } = this.state;
      if (this.isStepSkipped(activeStep)) {
        skipped = new Set(skipped.values());
        skipped.delete(activeStep);
      }

      // Handle the predefined files and merging the two lists
      await this.handlePredefinedAndMergeTheTwoListsBack({listType: 'source'});
      await this.handlePredefinedAndMergeTheTwoListsBack({listType: 'targetSchema'});
      await this.handlePredefinedAndMergeTheTwoListsBack({listType: 'generatorPolicy'});

      // Construct tuples from each fileMetadata for each list
      // Source fileMetadata
      let sourceTuples = [];
      this.state.sourceMergedFileMetadataList.forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        sourceTuples.push(tuple);
      });
      // Target Schema fileMetadata
      let targetSchemaTuples = [];
      this.state.targetSchemaMergedFileMetadataList.forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        targetSchemaTuples.push(tuple);
      });
      // Generator Policy fileMetadata
      let generatorPolicyTuples = [];
      this.state.generatorPolicyMergedFileMetadataList.forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        generatorPolicyTuples.push(tuple);
      });

      this.setState({
        metadata: {
          ...this.state.metadata,
          sourceInputs: sourceTuples,
          targetSchemata: targetSchemaTuples,
          generatorPolicy: generatorPolicyTuples[0],
        },
      }, async function() {
        // Store the new project mapping to the database
        try {
          let saveRes = await mainService.saveMappingProject(this.state.metadata);
          if(saveRes.data.succeed) {
            // Retrieve it from the response to get the full data (i.e. ID)
            this.setState({
              metadata: saveRes.data.mappingProject,
            }, async () => {
              // Re-retrieve mapping Projects from the database
              await this.props.retrieveMappingProjects();





              // Database Persistance for case 1, 2, 3 (New FileMetadata related)
              // Updating the previously constructed (and saved as empty) fileMetadata
              // to the database (Cases 1, 2, 3)
              if(activeStep === 1) {
                //sourceFileMetadataList: [],
                //targetSchemaFileMetadataList: [],
                //generatorPolicyFileMetadataList: [],
                this.state.sourceFileMetadataList.forEach(fileMetadata => {
                  this.storeFileMetadata(fileMetadata);
              	});
              }
              else if(activeStep === 2) {
                //sourceFileMetadataList: [],
                //targetSchemaFileMetadataList: [],
                //generatorPolicyFileMetadataList: [],
                this.state.targetSchemaFileMetadataList.forEach(fileMetadata => {
                  this.storeFileMetadata(fileMetadata);
              	});
              }
              else if(activeStep === 3) {
                //sourceFileMetadataList: [],
                //targetSchemaFileMetadataList: [],
                //generatorPolicyFileMetadataList: [],
                this.state.generatorPolicyFileMetadataList.forEach(fileMetadata => {
                  this.storeFileMetadata(fileMetadata);
              	});
              }
              // Finished action
              else if(activeStep === getSteps().length - 1) {
                // Construct the array of definitions based on the generator policy file and
                // store it to the MappingProject in the db.
                await generatorService.storeGeneratorDefinitionsFromFileToMappingProjectWithId({id: this.state.metadata.id});
                // Re-retrieve mapping Projects from the database
                await this.props.retrieveMappingProjects();
                // Reset the wizard
                this.handleReset();
                this.handleDialogClose();
              }
              // else if(activeStep === getSteps().length - 1) {
              //   this.setState({
              //     metadata: {
              //       ...this.state.metadata,
              //       status: 'In Progress',
              //     },
              //   }, async () => {
              //     await mainService.saveMappingProject(this.state.metadata);
              //     // Construct the array of definitions based on the generator policy file and
              //     // store it to the MappingProject in the db.
              //     await generatorService.storeGeneratorDefinitionsFromFileToMappingProjectWithId({id: this.state.metadata.id});
              //     // Re-retrieve mapping Projects from the database
              //     await this.props.retrieveMappingProjects();
              //     // Reset the wizard
              //     this.handleReset();
              //     this.handleDialogClose();
              //   });
              // }

              // Going to the next step
              if(activeStep < getSteps().length - 1) {
                this.setState({
                  activeStep: activeStep + 1,
                  skipped,
                });
              }


            });
          }
          else {
            this.props.showErrorSnackBar({msg: saveRes.data.msg, iconSize: 'large'});
          }
        } catch (e) {
          console.error('Failure!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      });

    }
  };

  //
  // props: {listType: 'source' | 'targetSchema' | 'generatorPolicy'}
  handlePredefinedAndMergeTheTwoListsBack = async (props) => {
    // Merging the two file (predefined and generated)
    let mergedArray = Lodash.cloneDeep(this.state[props.listType + 'SelectedPredifinedFileMetadata']); // Create a deep copy
    // Save the selected copies in the database by first removing the IDs, creator, upload date
    // (to be filled in by the server) and updating the username with the current one and the predefined flag (from true to false)
    // (such that new instances will be saved)

    // Add the new generated fileMetadata
    for (let fileMetadata of this.state[props.listType + 'FileMetadataList']) {
      mergedArray.push(fileMetadata);
    }
    this.setState({
      [props.listType + 'MergedFileMetadataList']: mergedArray,
    });
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      failedStep: -1,
      metadata: {
        ...this.state.metadata,
        title: '',
        description: '',
        sourceInputs: [],
        targetSchemata: [],
        generatorPolicy: null,
        status: 'Draft',
      },
      // Errors
      mappingTitle_error_text: undefined ,
      sourceInputError: false,
      sourceInputList_error_text: undefined ,
      sourceSelectedPredifined_error_text: undefined ,
      targetSchemaError: false,
      targetSchemaList_error_text: undefined ,
      targetSelectedPredifined_error_text: undefined ,
      generatorPolicyError: false,
      generatorPolicyList_error_text: undefined ,
      generatorPolicySelectedPredifined_error_text: undefined ,
      // Holding the selected fileMetadata instances from the list of predifined
      sourceSelectedPredifinedFileMetadata: [],
      targetSchemaSelectedPredifinedFileMetadata: [],
      generatorPolicySelectedPredifinedFileMetadata: [],
      // Holding the created fileMetadata instances for the uploaded files
      sourceFileMetadataList: [],
      targetSchemaFileMetadataList: [],
      generatorPolicyFileMetadataList: [],
      // Merged Arrays (selected + new generated)
      sourceMergedFileMetadataList: [],
      targetSchemaMergedFileMetadataList: [],
      generatorPolicyMergedFileMetadataList: [],
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  handleDialogClose = () => {

    if(this.state.metadata.title !== '' || this.state.metadata.description !== '' ||
       this.state.sourceSelectedPredifinedFileMetadata.length > 0 ||
       this.state.targetSchemaSelectedPredifinedFileMetadata.length > 0 ||
       this.state.generatorPolicySelectedPredifinedFileMetadata.length > 0 ||
       this.state.sourceFileMetadataList.length > 0 ||
       this.state.targetSchemaFileMetadataList.length > 0 ||
       this.state.generatorPolicyFileMetadataList.length > 0) {
      this.handleSnackbarOpen();
    }
    else {
      this.props.handleCloseWizard();
      this.handleReset();
    }
  };

  handleConfirmDialogClose = async () => {

    // delete the mappingProject and the associated files and FileMetadata objects
    if(this.state.metadata.id !== undefined) {
      try {
        const projectRes = await mainService.deleteMappingProjectById({id: this.state.metadata.id, deleteAssociatedFileMetadata: true});
        if(projectRes.data !== false) {
          console.info('Project was deleted successfully');
          // Re-retrieve mapping Projects from the database
          await this.props.retrieveMappingProjects();
        }
        else {
          console.error('Some error occured while deleting the project from the database!');
        }
      } catch (e) {
        console.error('Some error occured while deleting the project from the database!');
        console.error(e);
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }

    // Then close the dialog
    this.setState({
      snackbarShown: false
    }, function() {
      this.props.handleCloseWizard();
      this.handleReset();
    });
  };

  openMapping = () => {

  };

  // Used to handle which panel is expanded for the "Source Input" section
  handleSourceInputExpandPanelChange = panel => (event, expanded) => {
    //sourceInputExpandedPanel: expanded ? panel : false,
    this.setState({
      sourceInputExpandedPanel: expanded ?
      (this.state.predifinedSourceInputList.length === 0 ? panel : panel) :
      (this.state.predifinedSourceInputList.length === 0 ? panel : false),
    });
  };

  // Used to handle which panel is expanded for the "Target Schema" section
  handleTargetSchemaExpandPanelChange = panel => (event, expanded) => {
    //targetSchemaExpandedPanel: expanded ? panel : false,
    this.setState({
      targetSchemaExpandedPanel: expanded ?
      (this.state.predifinedTargetSchemaList.length === 0 ? panel : panel) :
      (this.state.predifinedTargetSchemaList.length === 0 ? panel : false),
    });
  };

  // Used to handle which panel is expanded for the "Generator Policy" section
  handleGeneratorPolicyExpandPanelChange = panel => (event, expanded) => {
    //generatorPolicyExpandedPanel: expanded ? panel : false,
    this.setState({
      generatorPolicyExpandedPanel: expanded ?
      (this.state.predifinedGeneratorPolicyList.length === 0 ? panel : panel) :
      (this.state.predifinedGeneratorPolicyList.length === 0 ? panel : false),
    });
  };

  // Used for the General info of the ProjectMapping (Wizard Step 0)
  // name: '<FIELD_NAME>' (i.e. 'title')
  handleInputChange = name => event => {
    if(name === 'title' || name === 'description') {
      if(name === 'title') {
        if (event.target.value === '') {
          this.setState({
              mappingTitle_error_text: 'This field is required',
          });
        }
        else {
          this.setState({
              mappingTitle_error_text: '',
          });
        }
      }

      this.setState({
        metadata: {
          ...this.state.metadata,
          [name]: event.target.value,
        }
      });
      //console.log(this.state);
    }

    else if(name === 'sourceInputs') {
      this.setState({
        metadata: {
          ...this.state.metadata,
          [name]: event
        }
      }, function () {
        if(event.value === undefined) {
          // Case of multiple values (Array is empty)
          if(event.length===0) {
            this.setState({
                sourceInputError: true,
                sourceInputList_error_text: 'This field is required',
            });
          }
          // Case of multiple values (Array not empty)
          else {
            this.setState({
                sourceInputError: false,
                sourceInputList_error_text: '',
            });
          }
        }
        // Case of single value where the object is not empty
        else {
          this.setState({
              sourceInputError: false,
              sourceInputList_error_text: '',
          });
        }
      });
    }

    // Mind that this is array NOT Object
    else if(name === 'targetSchemata') {
      this.setState({
        metadata: {
          ...this.state.metadata,
          [name]: event
        }
      }, function () {
        console.log('this.state.metadata.targetSchemata');
        console.log(this.state.metadata.targetSchemata);
        if(event.value === undefined) {
          // Case of multiple values (Array is empty)
          if(event.length===0) {
            this.setState({
                targetSchemaError: true,
                targetSchemaList_error_text: 'This field is required',
            });
          }
          // Case of multiple values (Array not empty)
          else {
            this.setState({
                targetSchemaError: false,
                targetSchemaList_error_text: '',
            });
          }
        }
        // Case of single value where the object is not empty
        else {
          this.setState({
              targetSchemaError: false,
              targetSchemaList_error_text: '',
          });
        }
      });
    }

    else if(name === 'generatorPolicy') {
      this.setState({
        metadata: {
          ...this.state.metadata,
          [name]: event
        }
      }, function () {
        if(event.value === undefined) {
          // Case of multiple values (Array is empty)
          if(event.length===0) {
            this.setState({
                generatorPolicyError: true,
                generatorPolicyList_error_text: 'This field is required',
            });
          }
          // Case of multiple values (Array not empty)
          else {
            this.setState({
                generatorPolicyError: false,
                generatorPolicyList_error_text: '',
            });
          }
        }
        // Case of single value where the object is not empty
        else {
          this.setState({
              generatorPolicyError: false,
              generatorPolicyList_error_text: '',
          });
        }
      });
    }
  }

/*
  // This
  holdInMetadataObject(fileMetadata) {
    console.log('holdInMetadataObject:');
    console.log(this.state);

  }
*/
  // props: {type: 'source | target | generatorPolicy'}
  addNewFileMetadata = props => async () => {
  // addNewFileMetadata = props => async () => {
  //async addNewFileMetadata(props) {
    let newFileMetadata = {
      title: '',
      description: '',
      version: '',
      fileScope: props.type + 'File',
      username: this.props.currUser.currUsername,
    };

    let fileMetadata = await this.storeFileMetadata(newFileMetadata);

    let array = this.state[props.type + 'FileMetadataList'].slice(); // Create a copy
    array.push(fileMetadata);
    this.setState({
      [props.type + 'FileMetadataList']: array
    }, async function() {

      // Just in case of generatorPolicy
      if(props.type === 'generatorPolicy') {
        // Delete all the instances from the db
        for (let fileMetadata of this.state[props.type + 'SelectedPredifinedFileMetadata']) {
          await this.deleteFileAndMaybeFileMetadata({fileMetadata: fileMetadata, deleteFileMetadata: true});
        };
        // Set the selected list as empty
        this.setState({
          [props.type + 'SelectedPredifinedFileMetadata']: []
        });
      }

      // Merging the two lists of fileMetadata (predefined and new ones)
      await this.handlePredefinedAndMergeTheTwoListsBack({listType: props.type});
      let tuples = [];
      this.state[props.type + 'MergedFileMetadataList'].forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        tuples.push(tuple);
      });

      // Determine what the name of the tuple list is for the mappingProject
      let metadataTupleName = '';
      if(props.type === 'source') {
        metadataTupleName = 'sourceInputs';
      }
      else if(props.type === 'targetSchema') {
        metadataTupleName = 'targetSchemata';
      }
      else {//if(props.type === 'generatorPolicy') {
        metadataTupleName = 'generatorPolicy';
      }

      // Associate that tuple list with the MappingProject
      this.setState({
        metadata: {
          ...this.state.metadata,
          [metadataTupleName]: props.type !== 'generatorPolicy' ? tuples : tuples[0],
        },
      }, async function() {
        // Store the new project mapping to the database
        try {
          const saveRes = await mainService.saveMappingProject(this.state.metadata);
          // Retrieve it from the database to get the full data (i.e. ID)
          this.setState({
            metadata: saveRes.data.mappingProject,
          });

        } catch (e) {
          console.error('Failure!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      });

    });
  }

  storeFileMetadata = async (fileMetadata) => {
    try {
      const res = await fileUploadService.storeFileMetadata(fileMetadata);
      if(res.data !== false) {
        // Update the state with the new generated ID of this FileMetadata
        if(fileMetadata.id === undefined) {
          fileMetadata.id = res.data.fileMetadataId;
        }
        return fileMetadata;
      }
      else {
        console.error('Some error occured while storing metadata to the database!');
        return null;
      }
    } catch (e) {
      console.error('Some error occured while storing metadata to the database!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  // props: {fileMetadata: <FileMetadata_Object>, deleteFileMetadata: <BOOLEAN>}
  deleteFileAndMaybeFileMetadata = async (props) => {
    try {
      const res = await fileUploadService.deleteFileAndMaybeFileMetadata({id: props.fileMetadata.id, deleteFileMetadata: props.deleteFileMetadata});
      if(res.data.succeed) {
        console.info('File was deleted successfully');
      }
      else {
        console.error(res.data.message);
        this.props.showErrorSnackBar({msg: res.data.message, iconSize: 'large'});
      }
    } catch (e) {
      console.error('Some error occured while deleting metadata from the database!');
      console.error(e);
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // props: {type: 'source | target | generatorPolicy', fileMetadata: <SOME_FILEMETADATA_OBJECT}
  handleDeleteFileMetadata = props => event => {
    let array = this.state[props.type + 'FileMetadataList'].slice(); // Create a copy
    let index = array.indexOf(props.fileMetadata);
    array.splice(index, 1);
    this.setState({
      [props.type + 'FileMetadataList']: array
    }, async function() {
      // Delete file from server and the respective database entry
      await this.deleteFileAndMaybeFileMetadata({fileMetadata: props.fileMetadata, deleteFileMetadata: true});
      // Merging the two lists of fileMetadata (predefined and new ones)
      await this.handlePredefinedAndMergeTheTwoListsBack({listType: props.type});
      let tuples = [];
      this.state[props.type + 'MergedFileMetadataList'].forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        tuples.push(tuple);
      });

      // Determine what the name of the tuple list is for the mappingProject
      let metadataTupleName = '';
      if(props.type === 'source') {
        metadataTupleName = 'sourceInputs';
      }
      else if(props.type === 'targetSchema') {
        metadataTupleName = 'targetSchemata';
      }
      else {//if(props.type === 'generatorPolicy') {
        metadataTupleName = 'generatorPolicy';
      }

      // Associate that tuple list with the MappingProject
      this.setState({
        metadata: {
          ...this.state.metadata,
          [metadataTupleName]: props.type !== 'generatorPolicy' ? tuples : tuples[0],
        },
      }, async function() {
        // Store the new project mapping to the database
        try {
          const saveRes = await mainService.saveMappingProject(this.state.metadata);
          // Retrieve it from the database to get the full data (i.e. ID)
          this.setState({
            metadata: saveRes.data.mappingProject,
          });

        } catch (e) {
          console.error('Failure!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      });

    });
  }

  // Used to set value to field programmaticaly (i.e set the title out of file name)
  // props: {fieldName: <NAME_OF_FIELD>, listType: 'source | targetSchema | generatorPolicy', index: <INTEGER>, value: <TEXT>}
  handleSetFileMetadataInput = async props => {
    let array = this.state[props.listType + 'FileMetadataList'].slice(); // Create a copy
    array = update(this.state[props.listType + 'FileMetadataList'], {
      [props.index]: {
        [props.fieldName + '_error_text']: {$set: props.value === '' ? 'This field is required' : ''},
        [props.fieldName]: {$set: props.value},
        files: {$set: props.files !== undefined ? props.files : []},
      }
    });
    this.setState({
      [props.listType + 'FileMetadataList']: array
    }, async () => {
      await this.storeFileMetadata(array[props.index]);
    });
  }

  // props: {fieldName: <NAME_OF_FIELD>, listType: 'source | targetSchema | generatorPolicy', index: <SOME_INTEGER>}
  handleFileMetadataInputChange = props => event => {

    let array = this.state[props.listType + 'FileMetadataList'].slice(); // Create a copy
    array = update(this.state[props.listType + 'FileMetadataList'], {
      [props.index]: {
        [props.fieldName + '_error_text']: {$set: event.target.value === '' ? 'This field is required' : ''},
        [props.fieldName]: {$set: event.target.value},
      }
    });
    this.setState({
      [props.listType + 'FileMetadataList']: array
    }, function() {
      //console.log(this.state);
    });
  }

  // props: {listType: 'source | target | generatorPolicy', fieldName: <Text>, index: <Integer>}
  handleMarkFieldAsInvalid = props => {
    let array = this.state[props.listType + 'FileMetadataList'].slice(); // Create a copy
    array = update(this.state[props.listType + 'FileMetadataList'], {
      [props.index]: {
        [props.fieldName + '_error_text']: {$set: 'This field is required'}
      }
    });
    this.setState({
      [props.listType + 'FileMetadataList']: array
    }, function() {
      console.log(this.state);
    });
  }

  // props: {listType: 'source | target | generatorPolicy', fieldName: <Text>, index: <Integer>}
  handleMarkFieldAsValid = props => {
  //handleMarkFieldAsValid (listType, fieldName, index) {
    let array = this.state[props.listType + 'FileMetadataList'].slice(); // Create a copy
    array = update(this.state[props.listType + 'FileMetadataList'], {
      [props.index]: {
        [props.fieldName + '_error_text']: {$set: ''}
      }
    });
    this.setState({
      [props.listType + 'FileMetadataList']: array
    }, function() {
      console.log(this.state);
    });
  }

  // props: {type: 'source | targetSchema | generatorPolicy', fileMetadata: <FileMetadata>, multiple*: <boolean>}
  // * When multiple is true, allows many selections (checkboxes) and when it is false, it allows only a single
  //   secettion (radioBoxes)
  handleSelectPredifinedFileMetadata = props => async () => {
    let fileMetadata = Object.assign({}, props.fileMetadata);
    const currentIndex = this.state[props.type + 'SelectedPredifinedFileMetadata'].findIndex(
      obj => obj.copyOfId === fileMetadata.id
    );
    let newArrayList = [];
    if(props.multiple) { // Checkboxes if true (when multiple is false, then an empty Array is used)
      newArrayList = [...this.state[props.type + 'SelectedPredifinedFileMetadata']];
    }
    // Single (Radio Buttons)
    else {
      // Delete them all from the database
      for (let tmpFileMetadata of this.state[props.type + 'SelectedPredifinedFileMetadata']) {
        await this.deleteFileAndMaybeFileMetadata({fileMetadata: tmpFileMetadata, deleteFileMetadata: true});
      }
    }
    if (currentIndex === -1) {
      // Create a new database instance of this fileMetadata
      fileMetadata.copyOfId = fileMetadata.id; // Used to determine whether to delete the physical file or not
      delete fileMetadata.id;
      delete fileMetadata.creator;
      delete fileMetadata.uploadDate;
      fileMetadata.username = this.props.currUser.currUsername;
      fileMetadata.predefined = false; // Also used to determine whether to delete the physical file or not
      fileMetadata = await this.storeFileMetadata(fileMetadata);
      newArrayList.push(fileMetadata);

    } else {
      newArrayList.splice(currentIndex, 1);
      // Delete the database instance (not the physical file)
      await this.deleteFileAndMaybeFileMetadata({
        fileMetadata: this.state[props.type + 'SelectedPredifinedFileMetadata'][currentIndex],
        deleteFileMetadata: true
      });
    }

    this.setState({
      [props.type + 'SelectedPredifinedFileMetadata']: newArrayList,
    }, async function () {
      // Merging the two lists of fileMetadata (predefined and new ones)
      await this.handlePredefinedAndMergeTheTwoListsBack({listType: props.type});
      let tuples = [];
      this.state[props.type + 'MergedFileMetadataList'].forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        tuples.push(tuple);
      });

      // Determine what the name of the tuple list is for the mappingProject
      let metadataTupleName = '';
      if(props.type === 'source') {
        metadataTupleName = 'sourceInputs';
      }
      else if(props.type === 'targetSchema') {
        metadataTupleName = 'targetSchemata';
      }
      else {//if(props.type === 'generatorPolicy') {
        metadataTupleName = 'generatorPolicy';
      }

      // Associate that tuple list with the MappingProject
      this.setState({
        metadata: {
          ...this.state.metadata,
          [metadataTupleName]: props.type !== 'generatorPolicy' ? tuples : tuples[0],
        },
      }, async function() {
        // Store the new project mapping to the database
        try {
          const saveRes = await mainService.saveMappingProject(this.state.metadata);
          // Retrieve it from the database to get the full data (i.e. ID)
          this.setState({
            metadata: saveRes.data.mappingProject,
          });

        } catch (e) {
          console.error('Failure!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      });
    });
  }

  isStepFailed = step => {
    return step === this.state.failedStep;
  };

  getStepContent = (step) => {
    const { classes } = this.props;
    const sourceMultiple = true;
    const targetSchemaMultiple = true;
    const generatorPolicyMultiple = false;

    switch (step) {
      case 0:
        return (
          <DialogContent>
            <DialogContentText>
              Please fill in the following form to create a new "Mapping Project".
            </DialogContentText>
            <form ref={form => this.formMapingMetadata = form}
                  autoComplete="off"
                  noValidate
                  className={classes.formRoot}>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Title"
                fullWidth
                error={this.state.mappingTitle_error_text !== '' && this.state.mappingTitle_error_text !== undefined}
                helperText={this.state.mappingTitle_error_text}
                onChange={this.handleInputChange('title')}
                value={this.state.metadata.title}
              />
              <TextField
                margin="dense"
                label="Description"
                placeholder="Placeholder"
                multiline
                fullWidth
                onChange={this.handleInputChange('description')}
                value={this.state.metadata.description}
              />
            </form>
          </DialogContent>
        );

      case 1:

        let sourceFileMetadataList = this.state.sourceFileMetadataList.map((fileMetadata, index) => {
          return (
            <CSSTransition timeout={400}
                           classNames="hideShowLink"
                           unmountOnExit
                           key={'sourceFileMetadata_' + fileMetadata.id}>
              <FileAndMetadataUploader currUser={this.props.currUser}
                                       updateAuthStatusOfCurrUser={this.props.updateAuthStatusOfCurrUser}
                                       fileMetadata={fileMetadata}
                                       fileScope={'sourceFile'}
                                       type={'source'}
                                       index={index}
                                       showFileCounter={true}
                                       handleFileMetadataInputChange={this.handleFileMetadataInputChange}
                                       handleSetFileMetadataInput={this.handleSetFileMetadataInput}
                                       handleDeleteFileMetadata={this.handleDeleteFileMetadata}
                                       handleMarkFieldAsInvalid={this.handleMarkFieldAsInvalid}
                                       handleMarkFieldAsValid={this.handleMarkFieldAsValid}
                                       storeFileMetadata={this.storeFileMetadata}/>
                                       {/*storeFileMetadataAndUpdateId={this.storeFileMetadataAndUpdateId}*/}
                                       {/*files={this.state.files}*/}
            </CSSTransition>
          );
        });

        return (
          <DialogContent className={classes.dialogPopperRoot}>
            <DialogContentText style={{paddingBottom: 10}}>
              {
                !this.state.sourceInputError ?
                <span>
                  Please <span hidden={this.state.predifinedSourceInputList.length === 0}>select
                  one or more Source Input from the available list. Additionally, you can further</span> add your own file(s) bellow.
                </span> :
                <span style={{color: 'red'}}>
                  Please <span hidden={this.state.predifinedSourceInputList.length === 0}>select
                  one or more Source Input from the available list. Additionally, you can further</span> add your own file(s) bellow.
                </span>
              }
            </DialogContentText>
            <Accordion expanded={this.state.sourceInputExpandedPanel === 'sourceInputPredefinedPanel'}
                            onChange={this.handleSourceInputExpandPanelChange('sourceInputPredefinedPanel')}
                            hidden={this.state.predifinedSourceInputList.length === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {/*<AddIcon style={{marginTop: -1, marginRight: 5,}}/>*/}
                <Typography className={classes.expandPanelHeading}>
                  Select one or more predefined <span className={classes.markedDownBlue}>"Source Input"</span> files
                  from the following list.
                  <br/>
                  <span style={{color: 'red'}}>
                  {
                    this.state.sourceInputError ?
                    this.state.sourceSelectedPredifined_error_text :
                    ''
                  }
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block', maxHeight: 368, overflowY: 'auto'}}>
                <List className={classes.predifinedFileListRoot}>
                  {this.state.predifinedSourceInputList.map(fileMetadata => (
                    <div key={fileMetadata.id}>
                      <Divider />
                      <ListItem role={undefined} dense button onClick={this.handleSelectPredifinedFileMetadata({type: 'source', fileMetadata: fileMetadata, multiple: sourceMultiple})}>
                        <Checkbox
                          hidden={!sourceMultiple}
                          checked={this.state.sourceSelectedPredifinedFileMetadata.indexOf(fileMetadata) !== -1}
                          tabIndex={-1}
                          color="primary"
                        />
                        <ListItemText primary={
                                        <React.Fragment>
                                          {fileMetadata.title}
                                          {fileMetadata.version !== null  && fileMetadata.version !== '' ?
                                          ' (' + fileMetadata.version + ')' : ' (Version N/A)'}
                                        </React.Fragment>
                                      }
                                      secondary={fileMetadata.description !== null && fileMetadata.description !== '' ? fileMetadata.description : 'Description N/A'}/>
                          <FileCopyIcon />
                      </ListItem>
                    </div>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={this.state.sourceInputExpandedPanel === 'sourceInputAddNewPanel'}
                            onChange={this.handleSourceInputExpandPanelChange('sourceInputAddNewPanel')}>
              <AccordionSummary expandIcon={this.state.predifinedSourceInputList.length > 0 ? <ExpandMoreIcon /> : ''}>
                {/*<AddIcon style={{marginTop: -1, marginRight: 5,}}/>*/}
                <Typography className={classes.expandPanelHeading}>
                  You can add your own <span className={classes.markedDownBlue}>"Source Input"</span> files
                  from here{this.state.sourceInputExpandedPanel !== 'sourceInputAddNewPanel' ? '.' : ','}
                  <span hidden={this.state.sourceInputExpandedPanel !== 'sourceInputAddNewPanel'}> by
                  clicking on the <span className={classes.markedDownBlue}>"plus"</span> blue
                  button and then filling in the respective fields.
                  </span>
                  <br/>
                  <span style={{color: 'red'}}>
                  {
                    this.state.sourceInputError ?
                    this.state.sourceInputList_error_text :
                    ''
                  }
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block', maxHeight: 368, overflowY: 'auto'}}>
                <TransitionGroup>
                  {sourceFileMetadataList}
                </TransitionGroup>
                <Grow in={this.state.sourceInputExpandedPanel === 'sourceInputAddNewPanel'}
                      timeout={{enter: 300, exit: 300}}>
                  <div style={{position: 'absolute', bottom: 62, right: 100, zIndex: 1}}>
                    <div style={{position: 'fixed'}}>
                      <Tooltip title="Add a new file" placement="left">
                        <Fab color="primary"
                             aria-label="Add"
                             onClick={this.addNewFileMetadata({type: 'source'})}
                             className={classes.fab}>
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                </Grow>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        );

      case 2:

        let targetSchemaFileMetadataList = this.state.targetSchemaFileMetadataList.map((fileMetadata, index) => {
          return (
            <CSSTransition timeout={400}
                           classNames="hideShowLink"
                           unmountOnExit
                           key={'targetFileMetadata_' + fileMetadata.id}>
              <FileAndMetadataUploader currUser={this.props.currUser}
                                       updateAuthStatusOfCurrUser={this.props.updateAuthStatusOfCurrUser}
                                       fileMetadata={fileMetadata}
                                       fileScope={'targetSchemaFile'}
                                       type={'targetSchema'}
                                       index={index}
                                       showFileCounter={true}
                                       handleFileMetadataInputChange={this.handleFileMetadataInputChange}
                                       handleSetFileMetadataInput={this.handleSetFileMetadataInput}
                                       handleDeleteFileMetadata={this.handleDeleteFileMetadata}
                                       handleMarkFieldAsInvalid={this.handleMarkFieldAsInvalid}
                                       handleMarkFieldAsValid={this.handleMarkFieldAsValid}
                                       storeFileMetadata={this.storeFileMetadata}/>
            </CSSTransition>
          );
        });

        return (
          <DialogContent className={classes.dialogPopperRoot}>
            <DialogContentText style={{paddingBottom: 10}}>
              {
                !this.state.targetSchemaError ?
                <span>
                  Please <span hidden={this.state.predifinedTargetSchemaList.length === 0}>select
                  one or more Target Schema file(s) from the available list. Additionally, you can further</span> add your own file(s) bellow.
                </span> :
                <span style={{color: 'red'}}>
                  Please <span hidden={this.state.predifinedTargetSchemaList.length === 0}>select
                  one or more Target Schema file(s) from the available list. Additionally, you can further</span> add your own file(s) bellow.
                </span>
              }

            </DialogContentText>
            <Accordion expanded={this.state.targetSchemaExpandedPanel === 'targetSchemaPredefinedPanel'}
                            onChange={this.handleTargetSchemaExpandPanelChange('targetSchemaPredefinedPanel')}
                            hidden={this.state.predifinedTargetSchemaList.length === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.expandPanelHeading}>
                  Select one or more predefined <span className={classes.markedDownBlue}>"Target Shema"</span> files
                  from the following list.
                  <br/>
                  <span style={{color: 'red'}}>
                  {
                    this.state.targetSchemaError ?
                    this.state.targetSelectedPredifined_error_text :
                    ''
                  }
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block', maxHeight: 368, overflowY: 'auto'}}>
              <List className={classes.predifinedFileListRoot}>
                {this.state.predifinedTargetSchemaList.map(fileMetadata => (
                  <div key={fileMetadata.id}>
                    <Divider />
                    <ListItem
                      role={undefined}
                      dense
                      button
                      onClick={
                        this.handleSelectPredifinedFileMetadata(
                          {
                            type: 'targetSchema',
                            fileMetadata: fileMetadata,
                            multiple: targetSchemaMultiple
                          }
                        )
                      }>
                      <Checkbox
                        hidden={!targetSchemaMultiple}
                        checked={
                          this.state.targetSchemaSelectedPredifinedFileMetadata.findIndex(
                            obj => {return obj.copyOfId === null ? obj.id === fileMetadata.id : obj.copyOfId === fileMetadata.id}
                          ) !== -1
                        }
                        tabIndex={-1}
                        color="primary"
                      />
                      <Radio
                        hidden={targetSchemaMultiple}
                        checked={
                          this.state.targetSchemaSelectedPredifinedFileMetadata.findIndex(
                            obj => {return obj.copyOfId === null ? obj.id === fileMetadata.id : obj.copyOfId === fileMetadata.id}
                          ) !== -1
                        }
                      />
                      <ListItemText primary={
                                      <React.Fragment>
                                        {fileMetadata.title}
                                        {fileMetadata.version !== null  && fileMetadata.version !== '' ?
                                        ' (' + fileMetadata.version + ')' : ' (Version N/A)'}
                                      </React.Fragment>
                                    }
                                    secondary={fileMetadata.description !== null && fileMetadata.description !== '' ? fileMetadata.description : 'Description N/A'}/>
                        <FileCopyIcon />
                    </ListItem>
                  </div>
                ))}
              </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={this.state.targetSchemaExpandedPanel === 'targetSchemaAddNewPanel'}
                            onChange={this.handleTargetSchemaExpandPanelChange('targetSchemaAddNewPanel')}>
              <AccordionSummary expandIcon={this.state.predifinedTargetSchemaList.length > 0 ? <ExpandMoreIcon /> : ''}>
                <Typography className={classes.expandPanelHeading}>
                  You can add your own <span className={classes.markedDownBlue}>"Target Schema"</span> files
                  from here{this.state.targetSchemaExpandedPanel !== 'targetSchemaAddNewPanel' ? '.' : ','}
                  <span hidden={this.state.targetSchemaExpandedPanel !== 'targetSchemaAddNewPanel'}> by
                  clicking on the <span className={classes.markedDownBlue}>"plus"</span> blue
                  button and then filling in the respective fields.
                  </span>
                  <br/>
                  <span style={{color: 'red'}}>
                  {
                    this.state.targetSchemaError ?
                    this.state.targetSchemaList_error_text :
                    ''
                  }
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block', maxHeight: 368, overflowY: 'auto'}}>
                <TransitionGroup>
                  {targetSchemaFileMetadataList}
                </TransitionGroup>
                <Grow in={this.state.targetSchemaExpandedPanel === 'targetSchemaAddNewPanel'}
                      timeout={{enter: 300, exit: 300}}>
                  <div style={{position: 'absolute', bottom: 62, right: 100, zIndex: 1}}>
                    <div style={{position: 'fixed'}}>
                      <Tooltip title="Add a new file" placement="left">
                        <Fab color="primary"
                             aria-label="Add"
                             onClick={this.addNewFileMetadata({type: 'targetSchema'})}
                             className={classes.fab}>
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                </Grow>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        );

      case 3:
        let generatorPolicyFileMetadataList = this.state.generatorPolicyFileMetadataList.map((fileMetadata, index) => {
          return (
            <CSSTransition timeout={400}
                           classNames="hideShowLink"
                           unmountOnExit
                           key={'generatorPolicyFileMetadata_' + fileMetadata.id}>
              <FileAndMetadataUploader currUser={this.props.currUser}
                                       updateAuthStatusOfCurrUser={this.props.updateAuthStatusOfCurrUser}
                                       fileMetadata={fileMetadata}
                                       fileScope={'generatorPolicyFile'}
                                       type={'generatorPolicy'}
                                       index={index}
                                       showFileCounter={true}
                                       handleFileMetadataInputChange={this.handleFileMetadataInputChange}
                                       handleSetFileMetadataInput={this.handleSetFileMetadataInput}
                                       handleDeleteFileMetadata={this.handleDeleteFileMetadata}
                                       handleMarkFieldAsInvalid={this.handleMarkFieldAsInvalid}
                                       handleMarkFieldAsValid={this.handleMarkFieldAsValid}
                                       storeFileMetadata={this.storeFileMetadata}/>
            </CSSTransition>
          );
        });

        // Disable the radio buttons when a custom file is selected
        let predefinedGeneratorPolicyDisabled = !generatorPolicyMultiple && generatorPolicyFileMetadataList.length > 0;
        return (
          <DialogContent className={classes.dialogPopperRoot}>
            <DialogContentText style={{paddingBottom: 10}}>
              {
                !this.state.generatorPolicyError ?
                <span>
                  Please <span hidden={this.state.predifinedGeneratorPolicyList.length === 0}>select
                  one or more Generator Policy file(s) from the available list. Additionally, you can further</span> add your own file(s) bellow.
                </span> :
                <span style={{color: 'red'}}>
                  Please <span hidden={this.state.predifinedGeneratorPolicyList.length === 0}>select
                  one or more Generator Policy file(s) from the available list. Additionally, you can further</span> add your own file(s) bellow.
                </span>
              }
            </DialogContentText>
            <Accordion expanded={this.state.generatorPolicyExpandedPanel === 'generatorPolicyPredefinedPanel'}
                            onChange={this.handleGeneratorPolicyExpandPanelChange('generatorPolicyPredefinedPanel')}
                            hidden={this.state.predifinedGeneratorPolicyList.length === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.expandPanelHeading} style={!predefinedGeneratorPolicyDisabled ? {} : {color: 'rgba(0, 0, 0, 0.54)'}}> {/*HERE I AM !!!!!!!!!*/}
                  Select one or more predefined <span className={classes.markedDownBlue}>"Generator Policy"</span> files
                  from the following list.
                  <br/>
                  <span style={{color: 'red'}}>
                  {
                    this.state.generatorPolicyError ?
                    this.state.generatorPolicySelectedPredifined_error_text :
                    ''
                  }
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block', maxHeight: 368, overflowY: 'auto'}}>
              <List className={classes.predifinedFileListRoot}>
                {this.state.predifinedGeneratorPolicyList.map(fileMetadata => (
                  <div key={fileMetadata.id}>
                    <Divider />
                    <ListItem role={undefined} dense button
                              disabled={predefinedGeneratorPolicyDisabled}
                              onClick={
                                this.handleSelectPredifinedFileMetadata(
                                  {
                                    type: 'generatorPolicy',
                                    fileMetadata: fileMetadata,
                                    multiple: generatorPolicyMultiple
                                  }
                                )
                              }>
                      <Checkbox
                        hidden={!generatorPolicyMultiple}
                        checked={
                          this.state.generatorPolicySelectedPredifinedFileMetadata.findIndex(
                            obj => {return obj.copyOfId === null ? obj.id === fileMetadata.id : obj.copyOfId === fileMetadata.id}
                          ) !== -1
                        }
                        tabIndex={-1}
                        color="primary"
                      />
                      <Radio
                        hidden={generatorPolicyMultiple}
                        checked={
                          this.state.generatorPolicySelectedPredifinedFileMetadata.findIndex(
                            obj => {return obj.copyOfId === null ? obj.id === fileMetadata.id : obj.copyOfId === fileMetadata.id}
                          ) !== -1
                        }
                      />
                      <ListItemText primary={
                                      <React.Fragment>
                                        {fileMetadata.title}
                                        {fileMetadata.version !== null  && fileMetadata.version !== '' ?
                                        ' (' + fileMetadata.version + ')' : ' (Version N/A)'}
                                      </React.Fragment>
                                    }
                                    secondary={fileMetadata.description !== null && fileMetadata.description !== '' ? fileMetadata.description : 'Description N/A'}/>
                        <FileCopyIcon />
                    </ListItem>
                  </div>
                ))}
              </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={this.state.generatorPolicyExpandedPanel === 'generatorPolicyAddNewPanel'}
                            onChange={this.handleGeneratorPolicyExpandPanelChange('generatorPolicyAddNewPanel')}>
              <AccordionSummary expandIcon={this.state.predifinedGeneratorPolicyList.length > 0 ? <ExpandMoreIcon /> : ''}>
                <Typography className={classes.expandPanelHeading}>
                You can add your own <span className={classes.markedDownBlue}>"Generator Policy"</span> files
                from here{this.state.generatorPolicyExpandedPanel !== 'generatorPolicyAddNewPanel' ? '.' : ','}
                <span hidden={this.state.generatorPolicyExpandedPanel !== 'generatorPolicyAddNewPanel'}> by
                clicking on the <span className={classes.markedDownBlue}>"plus"</span> blue
                button and then filling in the respective fields.
                </span>
                <br/>
                <span style={{color: 'red'}}>
                {
                  this.state.generatorPolicyError ?
                  this.state.generatorPolicyList_error_text :
                  ''
                }
                </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block', maxHeight: 368, overflowY: 'auto'}}>
              <TransitionGroup>
                {generatorPolicyFileMetadataList}
              </TransitionGroup>
              <Grow
                in={
                  this.state.generatorPolicyExpandedPanel === 'generatorPolicyAddNewPanel' &&
                  generatorPolicyFileMetadataList.length === 0
                }
                timeout={{enter: 300, exit: 300}}>
                <div style={{position: 'absolute', bottom: 60, right: 90, zIndex: 1}}>
                  <div style={{position: 'fixed'}}>
                    <Tooltip title="Add a new file" placement="left">
                      <Fab color="primary"
                           aria-label="Add"
                           onClick={this.addNewFileMetadata({type: 'generatorPolicy'})}
                           className={classes.fab}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                </div>
              </Grow>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        );

      case 4:
      // Concatenating the predefined and generated lists of fileMetadata
      var tmpSourceMergedFileMetadataList = [
        ...this.state.sourceSelectedPredifinedFileMetadata,
        ...this.state.sourceFileMetadataList];
      var selectedSourceInputList = tmpSourceMergedFileMetadataList.map((sourceInput, index) => {
        return (
          <ListItem hidden={!tmpSourceMergedFileMetadataList.length >0 } key={'selectedSourceInputList' + index}>
            <div style={{paddingRight: 10}}>
              <InputIcon />
            </div>
            <ListItemText primary="Source Input"
                          secondary={sourceInput.title + ' ' + (sourceInput.version  !== undefined && sourceInput.version !== '' ? 'v' + sourceInput.version : '')
                          + ' (' + (sourceInput.filename !== undefined ? sourceInput.filename : 'Pre-loaded') + ')'}/>
          </ListItem>
        )
      });
      // Concatenating the predefined and generated lists of fileMetadata
      var tmpTargetSchemaMergedFileMetadataList = [
        ...this.state.targetSchemaSelectedPredifinedFileMetadata,
        ...this.state.targetSchemaFileMetadataList
      ];
      var selectedTargetSchemaList = tmpTargetSchemaMergedFileMetadataList.map((targetSchema, index) => {
        return (
          <ListItem hidden={!tmpTargetSchemaMergedFileMetadataList.length > 0 } key={'targetSchemaListItem' + index}>
            <div style={{paddingRight: 10}}>
              <Icon className={classNames(classes.icon, 'fa fa-bullseye')} />
            </div>
            <ListItemText primary="Target Schema"
                          secondary={targetSchema.title  + ' ' + (targetSchema.version !== undefined && targetSchema.version !== '' ? 'v' + targetSchema.version : '')
                            + ' (' + (targetSchema.filename !== undefined ? targetSchema.filename : 'Pre-loaded') + ')'}/>
          </ListItem>
        )
      });
      // Concatenating the predefined and generated lists of fileMetadata
      var tmpGeneratorPolicyMergedFileMetadataList = [
        ...this.state.generatorPolicySelectedPredifinedFileMetadata,
        ...this.state.generatorPolicyFileMetadataList
      ];
      var selectedgeneratorPolicyList = tmpGeneratorPolicyMergedFileMetadataList.map((generatorPolicy, index) => {
        return (
          <ListItem hidden={!tmpGeneratorPolicyMergedFileMetadataList.length > 0 } key={'generatorPolicyListItem' + index}>
            <div style={{paddingRight: 10}}>
              <Icon className={classNames(classes.icon, 'fa fa-file-signature')} />
            </div>
            <ListItemText
              primary="Generator Policy"
              secondary={
                generatorPolicy.title  + ' ' +
                (generatorPolicy.version !== undefined && generatorPolicy.version !== '' ?
                'v' + generatorPolicy.version :
                '') +
                ' (' + (generatorPolicy.filename !== undefined ? generatorPolicy.filename : 'Pre-loaded') + ')'
              }/>
          </ListItem>
        )
      });

      return (
        <DialogContent className={classes.dialogPopperRoot} style={{overflowY:'auto', maxHeight:450}}>
          <List>
            <ListItem hidden={!this.state.metadata.title}>
              <div style={{paddingRight: 10}}>
                <Icon className={classNames(classes.icon, 'far fa-sticky-note')} />
              </div>
              <ListItemText primary="Title"
                            secondary={this.state.metadata.title}/>
            </ListItem>
            <ListItem hidden={!this.state.metadata.description}>
              <div style={{paddingRight: 10}}>
                <Icon className={classNames(classes.icon, 'far fa-sticky-note')} />
              </div>
              <ListItemText primary="Description"
                            secondary={this.state.metadata.description}/>
            </ListItem>
            {selectedSourceInputList}
            {selectedTargetSchemaList}
            {selectedgeneratorPolicyList}
          </List>
        </DialogContent>
      );
      default:
        return 'Unknown step';
    }
  }

  printState = () => {
    console.log(this.state);
    console.log(JSON.stringify(this.state));
  }

  // This is not used (development purposes)
  goToStep = props => () => {
    this.setState({ activeStep: props.step });
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  render() {

    const { classes, wizardShown } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <div style={{paddingBottom:10, paddingLeft:20}}>

        <Dialog
          open={wizardShown}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
          classes={{ paperScrollPaper: classes.dialogPopperRoot }}
        >

          <IconButton className={classes.button}
                      aria-label="close"
                      onClick={this.handleDialogClose}
                      style={{marginLeft: 'auto', marginRight: 5, marginTop: 5}}>
            <CloseIcon />
          </IconButton>

          <div className={classes.stepperRoot}>
            <Stepper activeStep={activeStep} style={{padding:'0px 24px'}}>
              {steps.map((label, index) => {
                const props = {};
                const labelProps = {};
                if (this.isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (this.isStepFailed(index)) {
                  labelProps.error = true;
                }
                if (this.isStepSkipped(index)) {
                  props.completed = false;
                }
                return (
                  <Step key={label} {...props}>
                    <StepLabel className={classes.iconContainer}
                               StepIconProps={{
                                 classes: {
                                   completed: classes.stepIconCompleted,
                                   active: classes.stepIconActive,
                                 }
                                }}
                                {...labelProps}>
                                {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className={classes.formBodyContainer} style={{minHeight:310}}>
              <div className={classes.formMainBodyContainer}>{this.getStepContent(activeStep)}</div>
            </div>
            <DialogActions>
              {/*activeStep === steps.length*/}
              <div className={classes.buttons}>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                {this.isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
                {/*
                <Button color="secondary"
                        className={classes.button}
                        onClick={this.goToStep({step: 2})}>
                  Go To Step 2
                </Button>
                <Button color="secondary"
                        className={classes.button}
                        onClick={this.goToStep({step: 3})}>
                  Go To Step 3
                </Button>
                <Button color="primary"
                        className={classes.button}
                        onClick={this.printState}>
                  Print State
                </Button>
                */}
              </div>
            </DialogActions>

          </div>

        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.snackbarShown}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
              root: classes.snackbarWarning,
            }
          }}
          message={
            <span className={classes.snackbarMessage}>
              <WarningIcon className={classNames(classes.largeSnackbarIcon, classes.snackbarIconVariant)} />
              "New Mapping" wizard setup is under progress. <br/>
              Are you sure you want to discard it?
            </span>}
          action={[
            <Button key="undo" color="secondary" size="small" onClick={this.handleConfirmDialogClose}>
              <b>Yes I'm sure</b>
            </Button>,
            <IconButton key="closeSnackbar" color="inherit" className={classes.snackbarCloseIconButton} onClick={this.handleSnackbarClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </div>
    );
  }
}

InsertMappingDialog.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(InsertMappingDialog);
//export default withStyles(styles)(InsertMappingDialog);
