import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

//import './css/custom.css';
const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  constExprEdit: {
    borderStyle: 'dashed',
    //borderColor: 'rgb(32, 150, 243)',
    //borderColor: '#37a700',
    borderColor: '#ec9d00',
    marginTop: 5,
    marginBottom: 5,
    marginLeft:20,
    padding:5,
    borderLeft:'solid'
  },
  divIconStyleWrapper: {
    padding:5,
    paddingRight:15,
    //display:'flex',
    //flexDirection:'column',
    //justifyContent: 'center',
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  twoColumnGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 5,
    padding: 5,
  },
});

export class GeneratorInfo extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.title !== nextProps.title) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {

    const { classes, title, patternTypeColors } = this.props; //constExprIndex

    return (
      <div>
        <div style={{paddingBottom: 5}}>
          <span style={{fontWeight: 'bold', fontSize: 11}}>{title}</span>
        </div>
        <div style={{paddingBottom: 5}}>
          <span>Generator Types:</span>
        </div>
        <div className={classes.twoColumnGridContainer}>
          <Icon className={classNames(classes.titleToolBarAwesomeIcon, 'fa fa-square')}
                style={{color: patternTypeColors.constant.color, width: 16, height: 16}}/>
          <span>Constant</span>
          <Icon className={classNames(classes.titleToolBarAwesomeIcon, 'fa fa-square')}
                style={{color: patternTypeColors.xpath.color, width: 16, height: 16}}/>
          <span>XPath</span>
          <Icon className={classNames(classes.titleToolBarAwesomeIcon, 'fa fa-square')}
                style={{color: patternTypeColors.position.color, width: 16, height: 16}}/>
          <span>Position</span>
        </div>
      </div>
    );
  }
}

GeneratorInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneratorInfo);
