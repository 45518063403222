import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
//import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
//import Autocomplete from '.././Autocomplete';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { XpathService } from '.././services/XpathService';

const xpathService = new XpathService();

const filter = createFilterOptions();

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  padding5TopBottom: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
  },
  intermediateEdit: {
    borderStyle: 'dashed',
    borderColor: 'rgb(32, 150, 243)',
    marginTop: 5,
    marginBottom: 5,
    //marginLeft: 20,
    marginLeft: -8,
    padding:5,
    borderLeft:'solid'
  },
  autocompleteFormControl: {
    width: '100%',
    display: 'flex'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
    display:'flex',
    flexDirection:'column',
    justifyContent: 'center',
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  intermediateToDeleteHighlighted: {
    borderColor: '#f50057',
  },
  closeIconWrapper: {
    position: 'relative',
    paddingTop: 5,
    zIndex: 1,
  },
  closeIcon: {
    position: 'absolute',
    padding: 'unset',
    right: 0,
    top: 0,
  },
  errorLabelButton: {
    fontSize: 12,
    lineHeight: 'unset',
    color: theme.palette.primary.main,
  },
});

const sourceRelationOptions = [];
const sourceNodeOptions  = [];

export class SourceIntermediateComponentEdit extends React.Component {
//export class SourceIntermediateComponentEdit extends React.Component {

  state = {
    intermediate: {
      id: -1,
      sourceNode: {
        item: '',
        isLoading: false
      },
      sourceRelation: {
        item: '',
        isLoading: false
      }
    },
    sourceNodeIsLoading: false,
    sourceRelationIsLoading: false,
    sourceNodeOptions: [],
    sourceRelationOptions:[],
  };

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.intermediate !== nextProps.intermediate) {
      return true;
    }
    else {
      return false;
    }
  }

  handleRetrieveXpathOptions = async (paramObj) => {
    if(this.props.domainSourceNode.item !== '') {
      // Notifying for loading data
      this.setState({[paramObj.name + 'IsLoading']: true}, function() {
        // Resetting option list
        this.setState({[paramObj.name + 'Options']: []});
      });
      // Async Wait
      try {
        // Getting the selected item
        let res = await xpathService.getXpaths({
          id: this.props.currMappingProjectId,
          type: 'source',
          xpath: this.props.domainSourceNode !== '' ? this.props.domainSourceNode.item.value : ''//[paramObj.name].item.value ? [paramObj.name].item.value : ''
        });

        if(res.data !== false) {
          // Creating option list
          this.setState({[paramObj.name + 'Options']: res.data.response.map(targetSchema => ({
            value: targetSchema.value,
            label: targetSchema.value,//label: targetSchema.label,
            //full: targetSchema.label,
          }))
        }, function () {
            // Notifying that loading data is over
            this.setState({[paramObj.name + 'IsLoading']: false});
          });
        }
        else {
          // Resetting option list
          this.setState({[paramObj.name + 'Options']: []}, function() {
            // Notifying that loading data is over
            this.setState({[paramObj.name + 'IsLoading']: false});
          });
        }
      }
      catch (e) {
        console.error('Failure while retrieving XPaths!');
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
        // Resetting option list
        this.setState({[paramObj.name + 'Options']: []}, function() {
          // Notifying that loading data is over
          this.setState({[paramObj.name + 'IsLoading']: false});
        });
      } // catch - ends

    } // If there is value selected for the respective targetRelation - Ends
    else {
      this.setState({
        [paramObj.name + 'Options']: []
      });
    }
  };

  handleRetrieveOptions = async (paramObj) => {

    if(paramObj.name === 'sourceRelation') {
      // Dummy loading
      this.setState({
        sourceRelationOptions: sourceRelationOptions
      });
    } // if(paramObj.name === 'sourceRelation') - ends

    else if(paramObj.name === 'sourceNode') {
      // Dummy loading
      this.setState({
        sourceNodeOptions: sourceNodeOptions
      });
    } // if(paramObj.name === 'sourceNode') - Ends
  };

  // props: {status:'mouseEnter | mouseLeave}
  handleHoverDeleteIntermediate = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({isHovered: hovered});
  }

  render() {

    const { classes } = this.props;
    const { sourceNodeOptions, sourceRelationOptions, sourceNodeIsLoading, sourceRelationIsLoading } = this.state;
    const { mappingId, linkId, intermediate, intermediateId,
            handleInputChange, handleInputChangeButton,
          } = this.props; //intermediateIndex

    // Used when deleting to "unmount" itself
    /*
    if(!this.props.visible) {
      return null;
    }
    */
    return (
      <div className={classNames(classes.intermediateEdit, this.state.isHovered ? classes.intermediateToDeleteHighlighted : '')}>
        <div className={classes.closeIconWrapper}>
          <IconButton className={classes.closeIcon}
                      aria-label="Delete"
                      onClick={
                        this.props.handleShowConfirmDeleteSnackbar({
                          handleDelete: this.props.handleDeleteIntermediate,
                          type: 'sourceIntermediate',
                          mappingId: mappingId,
                          linkId: linkId,
                          intermediateId: intermediate.id,
                        })
                      }
                      onMouseEnter={this.handleHoverDeleteIntermediate({status:'mouseEnter'})}
                      onMouseLeave={this.handleHoverDeleteIntermediate({status:'mouseLeave'})}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.padding5TopBottom}>
          <div className={classes.divIconStyleWrapper}>
            <div className={classes.divGreyOutlinedIconStyle}/>
          </div>
          <FormControl className={classes.autocompleteFormControl}
                       error={intermediate.sourceNode.error_text !== undefined}>
          <Autocomplete
            options={sourceNodeOptions}
            getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
            renderOption={(option) => (
              <React.Fragment>
                <span style={{wordBreak: 'break-word'}}>{option.label}</span>
              </React.Fragment>
            )}
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={
              handleInputChange({
                name: 'sourceNode',
                type: 'sourceIntermediate',
                mappingId: mappingId,
                linkId: linkId,
                intermediateId: intermediateId
              })
            }
            value={intermediate.sourceNode.item !== undefined && intermediate.sourceNode.item !== '' ? intermediate.sourceNode.item : null}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  label: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            filterSelectedOptions={false /* if true it will remove selected from the option list */}
            loading={sourceNodeIsLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Source Node"
                placeholder="Please select source node"
                FormHelperTextProps={{component: 'div'}}
                helperText={
                  <div style={{color: 'red'}}>
                    <span style={{paddingRight: 10}}>
                      {intermediate.sourceNode.error_text !== undefined ? intermediate.sourceNode.error_text : ''}
                    </span>
                    <Button
                      color="secondary"
                      hidden={intermediate.sourceNode.error_text === undefined}
                      size="small"
                      className={classes.errorLabelButton}
                      onClick={
                        handleInputChangeButton({
                          name: 'sourceNode',
                          type: 'sourceIntermediate',
                          mappingId: mappingId,
                          linkId: linkId,
                          intermediateId: intermediateId,
                          item: intermediate.sourceNode.item,
                        })
                      }
                    >
                      Make Custom
                    </Button>
                  </div>
                }
                onFocus={(inputValue) => this.handleRetrieveXpathOptions({name: 'sourceNode'})}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {sourceNodeIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          {/*
            <TextField ... ->
              helperText={<span style={{color: 'red'}}>{intermediate.sourceRelation.error_text !== undefined ? intermediate.sourceRelation.error_text : ''}</span>}
              FormHelperTextProps={{component: 'div'}}
              helperText={
                <div style={{color: 'red'}}>
                  <span style={{paddingRight: 10}}>
                    {intermediate.sourceNode.error_text !== undefined ? intermediate.sourceNode.error_text : ''}
                  </span>
                  <Button
                    size="small"
                    style={{fontSize: 12, lineHeight: 'unset', color: 'red'}}
                  >
                    Make Custom
                  </Button>
                </div>
              }
          */}
          </FormControl>
        </div>
        <div className={classes.padding5TopBottom}>
          <div className={classes.divIconStyleWrapper}>
            <ArrowDownwardIcon/>
          </div>
          <FormControl className={classes.autocompleteFormControl}
                       error={intermediate.sourceRelation.error_text !== undefined}>
            {/*
            <Autocomplete label={'Source Relation'}
                          placeholder={'Please select source relation'}
                          options={sourceRelationOptions}
                          onFocus={(inputValue) => this.handleRetrieveXpathOptions({name: 'sourceRelation'})}
                          value={intermediate.sourceRelation.item}
                          onChange={handleInputChange({name: 'sourceRelation', type: 'sourceIntermediate', mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}
                          error={intermediate.sourceRelation.error_text !== undefined}
                          errorText={intermediate.sourceRelation.error_text}
                          async={false}
                          isLoading={sourceRelationIsLoading}
                          isMulti={false}
                          creatable={true}/>
            */}
            <Autocomplete
              options={sourceRelationOptions}
              getOptionLabel={(option) => {
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.label !== undefined ? option.label : '';
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                </React.Fragment>
              )}
              getOptionSelected={
                (option, value) => (value !== '' || value !== undefined) ? option.value === value.value : false
              }
              onChange={handleInputChange({name: 'sourceRelation', type: 'sourceIntermediate', mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}
              value={intermediate.sourceRelation.item !== undefined && intermediate.sourceRelation.item !== '' ? intermediate.sourceRelation.item : null}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              filterSelectedOptions={false /* if true it will remove selected from the option list */}
              loading={sourceRelationIsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Source Relation"
                  placeholder="Please select source relation"
                  helperText={<span style={{color: 'red'}}>{intermediate.sourceRelation.error_text !== undefined ? intermediate.sourceRelation.error_text : ''}</span>}
                  FormHelperTextProps={{component: 'div'}}
                  helperText={
                    <div style={{color: 'red'}}>
                      <span style={{paddingRight: 10}}>
                        {intermediate.sourceRelation.error_text !== undefined ? intermediate.sourceRelation.error_text : ''}
                      </span>
                      <Button
                        color="secondary"
                        hidden={intermediate.sourceRelation.error_text === undefined}
                        size="small"
                        className={classes.errorLabelButton}
                        onClick={
                          handleInputChangeButton({
                            name: 'sourceRelation',
                            type: 'sourceIntermediate',
                            mappingId: mappingId,
                            linkId: linkId,
                            intermediateId: intermediateId,
                            item: intermediate.sourceRelation.item,
                          })
                        }
                      >
                        Make Custom
                      </Button>
                    </div>
                  }
                  onFocus={(inputValue) => this.handleRetrieveXpathOptions({name: 'sourceRelation'})}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {sourceRelationIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </div>

      </div>
    );
  }
}

SourceIntermediateComponentEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SourceIntermediateComponentEdit);
