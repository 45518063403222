import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import Input from './Input';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import Cookies from 'js-cookie';

import logo from '../../images/logo-400-w-text-color.png';

import configuration from '../configuration.json';

const rootContextPath = configuration.rootContextPath;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    background: 'none',
    padding: 0
  },
  title: {
    fontSize: 14,
  },
  media: {
    height: 210,
    width: 530,
    marginTop:'24px',
    marginLeft:'24px'
  },
  contentWrapper: {
    paddingTop: 16,
    paddingLeft: 16,
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  errorMsg: {
    margin: theme.spacing(1),
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
});

// class Form extends Component {
class Form extends React.Component {

  state = {
    authendicationError: '',
  };


  constructor(props) {
    super(props)
    if(props.error) {
      this.state = {
        failure: 'wrong username or password!',
        errcount: 0
      }
    } else {
      this.state = { errcount: 0 }
    }
  }

  renderError = () => {
    if(this.state.errcount || this.state.failure) {
        const errmsg = this.state.failure
          || Object.values(this.state.errmsgs).find(v=>v)
        return <div className="error">{errmsg}</div>
    }
  }

  handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    if (!this.state.errcount) {
      const data = new FormData(this.form);
      fetch(this.form.action, {
          method: this.form.method,
          body: new URLSearchParams(data),
      })
      .then(response => {
        if (!response.ok) {
            throw new Error('Failed to authenticate'); // Handle authentication failure
        }
        return response.json(); // Parse the JSON response
      })
      .then(data => {
          // Set the token in the cookie
          Cookies.set('3mToken', data.token);
          
          // Get the current URL
          const currentUrl = window.location.href;

          const url = new URL(currentUrl);

          // Get the hostname (domain) from the parsed URL
          const domain = url.hostname;

          // Redirect to the specified URL
          window.location.href = '';
      })
      .catch(error => {
          console.error('Authentication failed:', error);
          console.log(error);
          this.setState({
            authendicationError: error.message,
          });
      });
    }
  };

  render() {
    const {classes} = this.props;
    const inputs = this.props.inputs.map(
      ({name, placeholder, type, value, className}, index) => (
        <Input key={index} name={name} placeholder={placeholder} type={type} value={value}
          className={type==='submit'? className : ''} />
      )
    )
    const errors = this.renderError();

    return (
      <Card style={{display:'flex', height: 'calc(100vh - 22px)'}}>
        <CardMedia className={classes.media} image={logo} title="3M" component="img"/>
        <CardContent>
          <div className={classes.contentWrapper}>
            <Typography variant="h6" color="textSecondary">
              Sign In
            </Typography>
            <form {...this.props} onSubmit={this.handleSubmit} ref={fm => {this.form=fm}} className={classes.container}>
              {inputs}
              {errors}
            </form>
            <div className={classes.errorMsg}>
              {
                this.state.authendicationError !== undefined && this.state.authendicationError !== '' ? 
                this.state.authendicationError : 
                ''
              }
            </div>
            <div>
                <Button
                  color="primary"
                  component={Link}
                  to={rootContextPath + '/NewCredentialsGenerator'}
                  className={classes.button}
                >
                  Forgot your password?
                </Button>
              </div>
          </div>
        </CardContent>
      </Card>
    )
  }
}

Form.propTypes = {
  name: PropTypes.string,
  action: PropTypes.string,
  method: PropTypes.string,
  inputs: PropTypes.array,
  error: PropTypes.string
}

export default withStyles(styles)(Form)
