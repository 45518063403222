import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import JoditEditor from "jodit-react";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Autocomplete from './Autocomplete';
import Switch from '@material-ui/core/Switch';
import CustomSnackMessage from './snackbar/CustomSnackMessage';
import { UserProfileService } from './services/UserProfileService';
import { MessageService } from './services/MessageService';
import { CSSTransition } from 'react-transition-group';
import configuration from './configuration.json';

const userProfileService = new UserProfileService();
const messageService = new MessageService();

const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)

const richEditorConfig = {
  // all options from https://xdsoft.net/jodit/doc/
  readonly: false,
  height: 'calc(100vh - 310px)',
  hidePoweredByJodit: true,
  buttons: [
    'source', '|',
    'bold',
    'strikethrough',
    'underline',
    'italic', '|',
    'superscript',
    'subscript', '|',
    'ul',
    'ol', '|',
    'outdent', 'indent',  '|',
    'font',
    'fontsize',
    'brush',
    'paragraph', '|',
    'image',
    'file',
    'video',
    'table',
    'link', '|',
    'align', 'undo', 'redo', '|',
    'cut',
    'copy',
    'paste',
    'hr',
    'eraser',
    'copyformat', '|',
    'symbol',
    'fullsize',
    'print',
    //'preview',
    'find',
    'about'
  ],
}

const styles = theme => ({
  rootCard: {
    margin: '10px 0',
  },
  card: {
    margin: '10px 5px',
    height: 'calc(100vh - 94px)'
  },
  centerCard: {
    margin: '10px 5px',
    height: 'calc(100vh - 94px)',
    //flexGrow: 1,
    width: '100%'
  },
  button: {
    margin: theme.spacing(1),
  },
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  onTop: {
    zIndex: 1,
  },
  listItemIconShorterSpace: {
    minWidth: 40,
  },
});

const inboxes = [
  { value: 'received', label: 'Received' },
  { value: 'sent', label: 'Sent' },
  { value: 'all', label: 'All' },
  { value: 'unread', label: 'Unread' },
];

export class NotificationMessenger extends React.Component {

  state = {
    currInbox: {
      value: 'sent',
      label: 'Sent'
    },
    messages: [],
    richContent: '',
    richTitle: '',
    richTitle_error_text: '',
    leftSectionCollapsed: false,
    rightSectionCollapsed: false,
    selectedMessage: '',
    allUsers: [],
    selectedUsers: [],
    snackBarNotificationType: false,
    deleteModeActive: false,
    selectedMessagesToDelete: [],
  }
/*
  constructor(props) {
    super(props);
    //this.containerRef = React.createRef();
  }
*/

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.connected !== nextProps.connected) {
      return true;
    }
    if(this.props.newReceivedMsg !== nextProps.newReceivedMsg) {
      return true;
    }
    if(this.props.webSocketServerFeedback !== nextProps.webSocketServerFeedback) {
      return true;
    }
    if(this.props.unreadMessages !== nextProps.unreadMessages) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.newReceivedMsg !== prevProps.newReceivedMsg) {
      if(this.props.newReceivedMsg.recipients.findIndex(obj => obj.id === this.state.currentFullUser.id) !== -1) {
        if(this.state.currInbox.value === 'received' ||
           this.state.currInbox.value === 'sent' ||
           this.state.currInbox.value === 'unread' ||
           this.state.currInbox.value === 'all') {
          await this.handleLoadMessages();
        }
      }
    }
    // Websocket Server Feedback
    if (this.props.webSocketServerFeedback !== prevProps.webSocketServerFeedback) {
      console.log('this.props.webSocketServerFeedback !== prevProps.webSocketServerFeedback');
      if(this.props.webSocketServerFeedback.succeed) {
        console.log('this.props.webSocketServerFeedback.succeed');
        if(this.state.currInbox.value === 'sent') {
          console.log('this.state.currInbox.value === sent');
          await this.handleLoadMessages();
        }
      }
    }

    if (this.props.unreadMessages !== prevProps.unreadMessages) {
      if(this.state.currInbox.value === 'unread') {
        this.setState({
            messages: this.props.unreadMessages,
        });
      }
    }
  }

  async componentDidMount() {
    await this.handleLoadMessages();
    await this.handleLoadAllUsers();
    const user = await userProfileService.retrieveUserByUsername({username: this.props.currUser.currUsername});
    this.setState({
        currentFullUser: user.data,
    });
  }

  handleLoadMessages = async () => {
    let messages = [];
    if(this.state.currInbox.value === 'sent') {
      messages = await messageService.retrieveAllMessagesSentByThisUser();
    }
    else if(this.state.currInbox.value === 'received') {
      messages = await messageService.retrieveAllMessagesReceivedByThisUser();
    }
    else if(this.state.currInbox.value === 'all') {
      messages = await messageService.retrieveAllMessages();
      //messages = await messageService.retrieveAllMessages('light');
    }
    else if(this.state.currInbox.value === 'unread') {
      await this.props.retrieveUserUnreadMessagesCallBack();
      messages = this.props.unreadMessages;//await messageService.retrieveUnreadMessages();
    }
    // Note that case of unread is already covered by componentDidUpdate method
    if(this.state.currInbox.value !== 'unread') {
      this.setState({
          messages: messages.data,
      });
    }
  }

  handleLoadAllUsers = async () => {
    let users = await userProfileService.retrieveAllUsers();
    for (let user of users.data) {
      //delete user.password;
      delete user.userConfigurationId;
    }
    this.setState({
        allUsers: users.data,
    });
  }

  handleSelectMessage = message => () => {
    this.setState({
      richContent: message.content,
      richTitle: message.title,
      selectedMessage: message,
    }, async function() {
      if(message.recipients.findIndex(obj => obj !== null ? obj.id === this.state.currentFullUser.id : -1) !== -1) {
        await this.props.markMessageAsRead({message: message});
        await this.props.retrieveUserUnreadMessagesCallBack();
        if(this.state.currInbox.value === 'unread') {
          await this.handleLoadMessages();
        }
      }
    });
  }

  handleSelectMessageToDelete = message => event => {
    let array = this.state.selectedMessagesToDelete.slice();
    if(event.target.checked) {
      array.push(message);
    }
    else {
      let index = array.findIndex(
        obj => obj.id === message.id
      );
      array.splice(index, 1);
    }
    this.setState({
      selectedMessagesToDelete: array,
    });
  }

  handleSelectAllUsers = event => {
    let array = event.target.checked ? this.state.allUsers.slice() : []; // Create a copy
    this.setState({
      selectedUsers: array,
    });
  };

  handleSelectUser = user => event => {
    let array = this.state.selectedUsers.slice(); // Create a copy
    let tmpUser = Object.assign({}, user);
    if(event.target.checked) {
      delete tmpUser.avatarImageBase64; //Reducing volume of message
      // delete tmpUser.password; //Reducing volume of message
      array.push(tmpUser);
    }
    else {
      let index = array.findIndex(
        obj => obj.id === user.id
      );
      array.splice(index, 1);
    }
    this.setState({
      selectedUsers: array,
    });
  }

  handleChangeNotificationForm = event => {
    this.setState({
      snackBarNotificationType: event.target.checked,
    });
  };

  // props: {name: <Text>}
  handleChangeRichInput = props => event => {
      const capInputName = props.name.charAt(0).toUpperCase() + props.name.slice(1);
      if (event.target.value === '') {
        this.setState({
          ['rich' + capInputName + '_error_text']: 'This field is required',
        });
      }
      else {
        this.setState({
            ['rich' + capInputName + '_error_text']: '',
        });
      }
      this.setState({
          ['rich' + capInputName]: event.target.value,
      });
  }

  handleChangeRichContent = (richContent) => {
      this.setState({
          richContent: richContent,
      });
  }

  handleInboxChange = event => {
      this.setState({
          currInbox: event,
      }, async function () {
        await this.handleLoadMessages();
      });
  }

  // props: {title: <Text>, content: <Text>}
  sendMsgToUsers = msg => async () => {
    // Send msg to all the users
    if(this.state.selectedUsers.length === this.state.allUsers.length) {
      this.props.stompClient.send('/app/sendMsgToAll/' + 'HelloWorld', {}, JSON.stringify({
        title: msg.title,
        //from: this.props.currUser.currUsername,
        snackBarType: this.state.snackBarNotificationType,
        content: msg.content,
      }));
    }
    // Send msg to selected users only
    else {
      this.props.stompClient.send('/app/sendMsgToUsers', {}, JSON.stringify({
        title: msg.title,
        //from: this.props.currUser.currUsername,
        snackBarType: this.state.snackBarNotificationType,
        alertType: 'info',
        content: msg.content,
        recipients: this.state.selectedUsers
      }));
    }
  }

  // props: {title: <Text>, content: <Text>, recipients: [Username]}
  sendMsgToSpecificUsers = msg => () => {
    this.props.stompClient.send('/app/sendMsgToUsers', {}, JSON.stringify({
      title: msg.title,
      //from: this.props.currUser.currUsername,
      snackBarType: this.state.snackBarNotificationType,
      alertType: 'info',
      content: msg.content,
      recipients: this.state.selectedUsers
    }));
  }

  handleDeleteModeChange = event => {
    this.setState({
      deleteModeActive: event.target.checked,
    });
  };

  handleDeleteMessages = async () => {
    try {
      // Persist in the database
      const res = await messageService.removeListOfMessages(this.state.selectedMessagesToDelete);
      if(res.data.succeed) {
        // Send to other users in order to be synced
        let msgIds = [];
        this.state.selectedMessagesToDelete.forEach((msg) => {
          msgIds.push(msg.id);
        });
        await this.props.stompClient.send('/app/sendJsonPackageToAllButTheSender', {}, JSON.stringify(
          {
            type: 'deleteMessages',
            ids: msgIds,
          }
        ));
        this.setState({
            selectedMessagesToDelete: [],
        }, async () => {
          // Reload the list messages (not the unread)
          if(this.state.currInbox.value === 'received' ||
             this.state.currInbox.value === 'sent' ||
             this.state.currInbox.value === 'all') {
               await this.props.retrieveUserUnreadMessagesCallBack();
               await this.handleLoadMessages();
          }
          // Reload the list of unread messages and their count (badge)
          await this.props.retrieveUserUnreadMessagesCallBack();
        });
      }
      else {
        this.showErrorSnackBar({msg: res.data.msg});
      }
    }
    catch (e) {
      console.error('Some error occured while deleting the selected messages.');
      this.showErrorSnackBar({msg: 'Some error occured while deleting the selected messages.'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // handleDeleteMessages = async () => {
  //   try {
  //     // Persist in the database
  //     const res = await messageService.removeListOfMessages(this.state.selectedMessagesToDelete);
  //     if(res.data.succeed) {
  //       // Reload the list messages (not the unread)
  //       if(this.state.currInbox.value === 'received' ||
  //          this.state.currInbox.value === 'sent' ||
  //          this.state.currInbox.value === 'all') {
  //            await this.props.retrieveUserUnreadMessagesCallBack();
  //            await this.handleLoadMessages();
  //       }
  //       // Reload the list of unread messages and their count (badge)
  //       await this.props.retrieveUserUnreadMessagesCallBack();
  //     }
  //     else {
  //       this.showErrorSnackBar({msg: res.data.msg});
  //     }
  //   }
  //   catch (e) {
  //     console.error('Some error occured while deleting the selected messages.');
  //     this.showErrorSnackBar({msg: 'Some error occured while deleting the selected messages.'});
  //     if(e.response !== undefined) {
  //       console.error(e.response.status);
  //     }
  //   }
  // }

  // props: {msg: <Text>}
  showSuccessSnackBar = props => {
      this.props.enqueueSnackbar(
      props.msg, {
          variant: 'success',
          anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
          },
          autoHideDuration: 2500
      }
      );
  }

  // props: {msg: <Text>, iconSize: 'large | small (default: 'small')'}
  showErrorSnackBar = props => {
      this.props.enqueueSnackbar(
      '', {
          variant: 'error',
          anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
          },
          persist: true,
          autoHideDuration: 2500,
          content: (key) => (
              // variant: 'success | error | warning | info'
              // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'error'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}/>
          ),
      }
      );
  }


  collapseSection = sectionName => () => {
    // const container = this.containerRef.current;
    // const resizer = container.getResizer();
    // console.log(resizer);
    // resizer.resizeSection(2, { toSize: 500 });
    // container.applyResizer(resizer);

    this.setState({
        [sectionName + 'SectionCollapsed']: !this.state[sectionName + 'SectionCollapsed'],
    });

  }

  printState = () => {
    console.log(this.state);
  }
  //readonly containerRef = React.createRef<Container>();
  render() {
      const { classes, } = this.props;

      // data = this.state.searchText ?
      //         data.filter(
      //             x => x[this.state.columnToBeFiltered.value].toLowerCase().includes(this.state.searchText)
      //         ) :
      //         data;
      console.log('NotificationMessenger Rendered');
      return (
        <div style={{display: 'flex'}}>
          <CSSTransition in={this.state.leftSectionCollapsed}
                         timeout={500}
                         classNames="sectionCollapseInitShown">
            <div className="sectionCollapseInitShown">
              <Card className={classes.card}>
                <CardContent>

                  <CSSTransition timeout={400}
                                 classNames="moveRightWhenPanelCollapsed"
                                 in={this.state.leftSectionCollapsed}>
                    <div className="moveRightWhenPanelCollapsed">
                      <Fade in={!this.state.leftSectionCollapsed} mountOnEnter unmountOnExit>
                        <Tooltip title="Collapse panel"
                                 placement="bottom-end"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <Fab className={classNames(classes.fab, classes.onTop)} color="primary"
                               style={{position: 'absolute', top: 0}}
                               onClick={this.collapseSection('left')}
                               size="medium">
                            <NavigateBeforeIcon style={{fontSize: 46}}/>
                          </Fab>
                        </Tooltip>
                      </Fade>
                      <Fade in={this.state.leftSectionCollapsed} mountOnEnter unmountOnExit>
                      <Tooltip title="Show panel"
                               placement="bottom-start"
                               enterDelay={500}
                               leaveDelay={200}>
                          <Fab className={classNames(classes.fab, classes.onTop)} color="primary"
                               style={{position: 'absolute', top: 0}}
                               onClick={this.collapseSection('left')}
                               size="medium">
                            <NavigateNextIcon style={{fontSize: 46}}/>
                          </Fab>
                        </Tooltip>
                      </Fade>
                    </div>
                  </CSSTransition>

                  <Typography variant="h6" style={{marginBottom: 16}}>
                      {this.state.currInbox.label} Messages
                  </Typography>

                  <div style={{width: '100%'}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.deleteModeActive}
                          onChange={this.handleDeleteModeChange}
                          value={this.state.deleteModeActive}
                          color="secondary"
                        />
                      }
                      label="Delete Mode"
                      style={{paddingTop: 20}}
                      classes={{label: classes.boldLabel}}
                    />
                  </div>

                  <div style={{width: '100%'}}>
                    <Autocomplete placeholder={'Select Inbox'}
                                  options={inboxes}
                                  value={this.state.currInbox}
                                  onChange={this.handleInboxChange}
                                  async={false}
                                  isMulti={false}
                                  isClearable={false}
                                  disabled={this.state.deleteModeActive}/>
                  </div>

                  <div hidden={this.state.messages.length > 0}>
                    <Typography variant="subtitle1" style={{paddingTop: 16, marginBottom: 16}}>
                        There are no messages to show yet
                    </Typography>
                  </div>
                  <div hidden={this.state.messages.length === 0}>
                    <List style={{overflow: 'auto', height: 'calc(100vh - 260px)'}}>
                      {
                        this.state.messages.map((message, messageIndex) => {
                          return (
                            <div key={'message_' + messageIndex}>
                              <ListItem dense>
                                <ListItemIcon className={classes.listItemIconShorterSpace}>
                                  <div>
                                    <Fade in={!this.state.deleteModeActive}>
                                      <Radio  edge="start"
                                              onClick={this.handleSelectMessage(message)}
                                              disabled={this.state.currentFullUser === undefined}
                                              checked={
                                                  this.state.selectedMessage !== undefined ?
                                                  this.state.selectedMessage.id === message.id :
                                                  false
                                              }
                                              style={{position: 'absolute'}}
                                      />
                                    </Fade>
                                    <Fade in={this.state.deleteModeActive}>
                                      <Checkbox  edge="start"
                                                 checked={this.state.selectedMessagesToDelete.findIndex(obj => obj.id === message.id) !== -1}
                                                 onClick={this.handleSelectMessageToDelete(message)}
                                      />
                                    </Fade>
                                  </div>
                                </ListItemIcon>
                                <ListItemText primary={message.title}
                                              secondary={
                                                <React.Fragment>
                                                  {
                                                    message.recipients.map((recipient, recipientIndex) => {
                                                      if(recipient !== null) {
                                                        return (
                                                          <span key={'recipient_' + recipientIndex}>
                                                            {recipientIndex > 0 && ", "}
                                                            {recipient.username}
                                                          </span>
                                                        )
                                                      }
                                                    })
                                                  }
                                                  <br/>
                                                  <span style={{fontSize: 'smaller'}}>
                                                    {new Date(message.creationTime).toDateString()}
                                                    {new Date(message.creationTime).toLocaleTimeString()}
                                                  </span>
                                                </React.Fragment>
                                              }
                                />
                              </ListItem>
                              <Divider hidden={messageIndex === this.state.messages.length-1}/>
                            </div>
                          );
                        })
                      }
                    </List>
                  </div>
                  <Grow in={this.state.deleteModeActive}>
                    <Fab color="secondary"
                         aria-label="delete-messages"
                         onClick={this.handleDeleteMessages}
                         className={classes.fab}
                         style={{position: 'absolute', bottom: 24, zIndex: 1, left: 200}}>
                      <DeleteIcon />
                    </Fab>
                  </Grow>
                </CardContent>
              </Card>
            </div>
          </CSSTransition>
          <Card className={classes.centerCard}>
            <CardContent>
              <CSSTransition timeout={400}
                             classNames="marginLeftWhenPanelCollapsed"
                             in={this.state.leftSectionCollapsed}>
                <div className="marginLeftWhenPanelCollapsed">
                  <Typography variant="h6" style={{marginBottom: 16, flegGrow: 1, width: '100%'}}>
                    Message
                  </Typography>
                </div>
              </CSSTransition>
              <div>
                <div style={{display: 'flex', height: 100, marginTop: -20}}>
                  <TextField required
                            error={this.state.richTitle_error_text !== undefined ? this.state.richTitle_error_text !== '' : false}
                            helperText={this.state.richTitle_error_text}
                            label="Title"
                            value={this.state.richTitle}
                            onChange={this.handleChangeRichInput({name: 'title'})}
                            margin="normal"
                            style={{width: '100%'}}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.snackBarNotificationType}
                        onChange={this.handleChangeNotificationForm}
                        color="primary"
                      />
                    }
                    label="Use SnackBar Form"
                    labelPlacement="start"
                    style={{paddingLeft: 5, whiteSpace: 'nowrap',}}
                  />

                </div>
                <JoditEditor value={this.state.richContent}
                             config={richEditorConfig}
                             tabIndex={1} // tabIndex of textarea
                             onBlur={this.handleChangeRichContent}
                             style={{height: 200}}
                />
                          {/*onChange={this.handleChangeRichContent(this.state.richContent)}*/}
              </div>
              <div style={{paddingTop: 10}}>
                <Button color="primary" className={classes.button}
                        onClick={this.printState}>
                        printState
                </Button>
                <Button color="primary" className={classes.button}
                        variant="contained"
                        disabled={this.state.selectedUsers.length === 0 || !this.props.connected}
                        onClick={this.sendMsgToUsers({title: this.state.richTitle, content: this.state.richContent})}>
                        Send Message
                </Button>
              </div>
            </CardContent>
          </Card>
          <CSSTransition in={this.state.rightSectionCollapsed}
                         timeout={500}
                         classNames="sectionCollapseInitShown350">
            <div className="sectionCollapseInitShown350">
              <Card className={classes.card}>
                <CardContent>
                    <CSSTransition timeout={400}
                                   classNames="moveLeftWhenPanelCollapsed"
                                   in={this.state.rightSectionCollapsed}>
                      <div className="moveLeftWhenPanelCollapsed">
                        <Fade in={!this.state.rightSectionCollapsed} mountOnEnter unmountOnExit>
                          <Tooltip title="Collapse panel"
                                   placement="bottom-start"
                                   enterDelay={500}
                                   leaveDelay={200}>
                            <Fab className={classNames(classes.fab, classes.onTop)} color="primary"
                                 style={{position: 'absolute', top: 0}}
                                 onClick={this.collapseSection('right')}
                                 size="medium">
                              <NavigateNextIcon style={{fontSize: 46}}/>
                            </Fab>
                          </Tooltip>
                        </Fade>
                        <Fade in={this.state.rightSectionCollapsed} mountOnEnter unmountOnExit>
                        <Tooltip title="Show panel"
                                 placement="bottom-end"
                                 enterDelay={500}
                                 leaveDelay={200}>
                            <Fab className={classNames(classes.fab, classes.onTop)} color="primary"
                                 style={{position: 'absolute', top: 0}}
                                 onClick={this.collapseSection('right')}
                                 size="medium">
                              <NavigateBeforeIcon style={{fontSize: 46}}/>
                            </Fab>
                          </Tooltip>
                        </Fade>
                      </div>
                    </CSSTransition>
                  <Typography variant="h6"
                              style={{
                                overflow: 'hidden',
                                marginBottom: 16,
                                whiteSpace: 'nowrap',
                                marginLeft: 30,
                              }}>
                    User List
                  </Typography>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.selectedUsers.length === this.state.allUsers.length}
                          indeterminate={this.state.selectedUsers.length < this.state.allUsers.length && this.state.selectedUsers.length > 0}
                          onChange={this.handleSelectAllUsers}
                          color="primary"
                        />
                      }
                      label="Select All"
                      labelPlacement="end"
                      style={{marginLeft: 4, color: 'dodgerblue'}}
                    />
                    <List style={{overflow: 'auto', height: 'calc(100vh - 208px)'}}>
                      {
                        this.state.allUsers.map((user, userIndex) => {
                          return (
                            <div key={'user_' + userIndex}>
                              <ListItem dense>
                                <ListItemIcon className={classes.listItemIconShorterSpace}>
                                  <Checkbox  edge="start"
                                             checked={this.state.selectedUsers.findIndex(obj => obj.id === user.id) !== -1}
                                             onClick={this.handleSelectUser(user)}
                                  />
                                </ListItemIcon>
                                <ListItemAvatar>
                                  <Avatar alt="user-avatar"
                                          src={user.avatarImageRelativePath !== undefined ? avatarRootContextPath + user.avatarImageRelativePath : ''}
                                  />
                                </ListItemAvatar>
                                <ListItemText primary={user.firstname + ' ' + user.lastname + ' (' + user.username + ')'}
                                              secondary={
                                                <React.Fragment>
                                                  {
                                                    user.roles.map((role, roleIndex) => {
                                                      return (
                                                        <span key={'role_' + roleIndex}>
                                                          {roleIndex > 0 && ", "}
                                                          {role}
                                                        </span>
                                                      )
                                                    })
                                                  }
                                                  </React.Fragment>
                                              }
                                />
                              </ListItem>
                              <Divider hidden={userIndex === this.state.allUsers.length-1}/>
                            </div>
                          );
                        })
                      }
                    </List>
                  </div>
                </CardContent>
              </Card>
            </div>
          </CSSTransition>





        </div>
      )
  }
}

NotificationMessenger.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(NotificationMessenger);
