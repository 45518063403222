import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '.././Autocomplete';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import TransferList from './TransferList';
import configuration from '../configuration.json';

const dirRootContextPath = configuration.dirRootContextPath; // This is different than the regular rootContextPath (/3m) and avatarRootContextPath (3m/)

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
  },
  /*
  customScrollPaper: {
    maxHeight: 'unset',
  },
  */
  card: {
    margin: 10,
    width: '50%'
  },
  singleCard: {
    margin: 10,
    width: '100%'
  },
  formRoot: {
    padding: 0,
    background: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  formBase: {
    minWidth: 265,
  },
  boldLabel: {
    fontWeight: 'bold!important',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  customRootExpansionPanelDetails: {
    padding: 0,
    display: 'block',
  },
  customExpansionPanelSummaryRoot: {
    borderBottom: 'solid',
    borderBottomWidth: 'thin',
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  customExpansionPanelSummaryExpanded: {
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  itemToDeleteHighlighted: {
    outline: '3px dashed #f50057',
    // borderStyle: 'dashed',
    // borderColor: '#f50057',
    // borderWidth: 3,
  },
  downloadButton: {
    fontSize: 'smaller',
    margin: '10px 10px 10px 10px',
    paddingLeft: '18px!important',
    paddingRight: '18px!important',
  }
});




export class GeneratorDefinitionsManagerDialog extends React.Component {

  state = {
    expandedDefListPanel: 'local', // preloaded
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.currDefinition !== nextProps.currDefinition) {
      return true;
    }
    if(this.props.generatorDefinitions !== nextProps.generatorDefinitions) {
      return true;
    }
    if(this.props.selectedDefinitionListForEditMode !== nextProps.selectedDefinitionListForEditMode) {
      return true;
    }
    if(this.props.selectedPreloadedDefinitionListForEditMode !== nextProps.selectedPreloadedDefinitionListForEditMode) {
      return true;
    }
    if(this.props.definitionListEditModeEnabled !== nextProps.definitionListEditModeEnabled) {
      return true;
    }
    if(this.props.preloadedGeneratorDefinitions !== nextProps.preloadedGeneratorDefinitions) {
      return true;
    }
    if(this.props.prefixNamespaceMap !== nextProps.prefixNamespaceMap) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }
    if(this.props.theSystemIsUpdatingGeneratorDeclarations !== nextProps.theSystemIsUpdatingGeneratorDeclarations) {
      return true;
    }
    if(this.props.argHoveredIndex !== nextProps.argHoveredIndex) {
      return true;
    }
    if(this.props.user !== nextProps.user) {
      return true;
    }
    if(this.props.generatorPolicyFileImportShown !== nextProps.generatorPolicyFileImportShown) {
      return true;
    }
    if(this.props.selectFromImportViewEnabled !== nextProps.selectFromImportViewEnabled) {
      return true
    }
    if(this.props.toBeImportedGeneratorDfinitions !== nextProps.toBeImportedGeneratorDfinitions) {
      return true
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }


  // loadingPrefixHistory = () => {
  //   let prefixArray = [];
  //   // Mapping
  //   for (const definition of this.props.generatorDefinitions) {
  //
  //     if(definition.prefix !== undefined) {
  //       const prefixOption = {label: definition.prefix, value: definition.prefix};
  //       prefixArray.push(prefixOption);
  //     }
  //   }
  //   return prefixArray;
  // }
  // map: Map<Prefix, Namespace>
  loadingPrefixHistory = map => {
    let prefixArray = [];
    map.forEach((value, key) => {
      const prefixOption = {label: key, value: key};
      prefixArray.push(prefixOption);
    });
    return prefixArray;
  }

  // props: {definition: <Object>}
  getSwitchForNewIfAppropriate = props => {
    console.log('getSwitchForNewIfAppropriate');
    const { classes, handleInputChange} = this.props;
    // Case New
    if(props.definition.new !== undefined ? props.definition.new : false) {
      // This should be in separate method because it returns!!!!!!!!!!
      return (
        <div style={{display: 'flex', marginTop: -25}}>
          <FormControl className={classes.formControl} style={{paddingBottom: 25}}>
            <InputLabel style={{fontWeight: 'bold'}}>Pattern Based</InputLabel>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={props.definition.custom}
                onChange={handleInputChange({fieldName: 'custom'})}
                value={props.definition.custom}
                color="primary"
              />
            }
            label="Java Based"
            style={{paddingTop: 20}}
            classes={{label: classes.boldLabel}}
          />
        </div>
      );
    }
  }

  // props: {definition: <Object>}
  getDefinitionForm = props => {

    console.log('getDefinitionForm');

    const {
      classes, handleInputChange, handleArgInputChange, prefixNamespaceMap,
      handleArgHoverDelete, handleDeleteArg, argHoveredIndex,
    } = this.props;
    const prefHistory = prefixNamespaceMap !== undefined ? this.loadingPrefixHistory(prefixNamespaceMap) : [];
    const noDuplicatesPrefixHistory = prefHistory.filter((thing, index, self) =>
      index === self.findIndex((t) =>
        (t.value === thing.value && t.label === thing.label)
      )
    );

    const typeOptions = [{
        label: 'any',
        value: 'any',
      },{
        label: 'instance',
        value: 'instance',
      },{
        label: 'label',
        value: 'label',
      }];

    let useFullOptionList = [{
        label: 'Constant',
        value: 'constant',
      },{
        label: 'Xpath',
        value: 'xpath',
      },{
        label: 'Position',
        value: 'position',
      }];

    if(props.definition !== undefined && props.definition !== '') {
      if(props.definition.name !== undefined ?
         (props.definition.name.toLowerCase() === 'uuid' ||
          props.definition.name.toLowerCase() === 'literal' ||
          props.definition.name.toLowerCase() === 'preflabel') :
          false) {
        return (
          <div className={classes.formBase}>
            <Typography style={{maxWidth: 300, paddingTop: 10, fontStyle: 'italic'}} color="textSecondary">
              This is a build-in generator and therefore, not editable.
            </Typography>
          </div>
        );
      }
      else if (props.definition.custom !== undefined ? props.definition.custom : false) {
        return (
          <form ref={form => this.declarationForm = form}
                autoComplete="off"
                noValidate
                className={classes.formRoot}>
            {this.getSwitchForNewIfAppropriate(props)}
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <FormControl className={classes.formControl} error={props.definition.prefix_error_text !== undefined}>
                <Autocomplete label={'Type'}
                              placeholder={'Select Value'}
                              options={typeOptions}
                              value={
                                props.definition.type !== '' ?
                                {
                                  label: props.definition.type,
                                  value: props.definition.type,
                                } :
                                {
                                  label: 'any',
                                  value: 'any',
                                }
                              }
                              isClearable={false}
                              onChange={handleInputChange({fieldName: 'type', value: props.definition.type})}
                              isMulti={false}
                              async={false}
                />
              </FormControl>
              <FormControl className={classes.formControl} error={props.definition.name_error_text !== undefined}>
                 <TextField required
                            autoComplete="off"
                            label="Name"
                            placeholder="Please enter some text value"
                            margin="dense"
                            value={props.definition.name}
                            error={props.definition.name_error_text !== undefined}
                            helperText={props.definition.name_error_text !== undefined? props.definition.name_error_text : ''}
                            onChange={handleInputChange({fieldName: 'name', value: props.definition.name})}/>
              </FormControl>
              <FormControl className={classes.formControl} error={props.definition.generatorClass_error_text !== undefined}>
                 <TextField required
                            autoComplete="off"
                            label="Java Class"
                            placeholder="Please enter some Java Class Name"
                            margin="dense"
                            value={props.definition.generatorClass}
                            error={props.definition.generatorClass_error_text !== undefined}
                            helperText={props.definition.generatorClass_error_text !== undefined ? props.definition.generatorClass_error_text : ''}
                            onChange={handleInputChange({fieldName: 'generatorClass', value: props.definition.generatorClass})}/>
              </FormControl>
              {
                props.definition.setArgs !== undefined ?
                props.definition.setArgs.map((arg, argIndex) => (
                  <Card key={props.definition.name + 'arg-' + argIndex}
                        style={{margin: 10}}>
                    <div style={{margin: 3}} className={argIndex === argHoveredIndex ? classes.itemToDeleteHighlighted : ''}>
                      <div style={{display: 'flex'}}>
                        <Typography color="textSecondary" style={{margin: 10}}>
                          {'Argument # ' + argIndex}
                        </Typography>
                        <div style={{textAlign: 'right', flexGrow: 1, margin: 5}}>
                          <IconButton className={classes.closeIcon}
                                      size="small"
                                      aria-label="Delete"
                                      onClick={handleDeleteArg({index: argIndex})}
                                      onMouseEnter={handleArgHoverDelete({status:'mouseEnter', index: argIndex})}
                                      onMouseLeave={handleArgHoverDelete({status:'mouseLeave', index: argIndex})}>
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                      <CardContent style={{display: 'flex', flexDirection: 'column', paddingTop: 0, marginTop: -15}}>
                        {
                          arg.attributes.map((attr, attrIndex) => (
                            Object.keys(attr).map(attrKey => (
                                attrKey === 'name' ?
                                <FormControl key={props.definition.name + 'arg-' + argIndex + '-attr-' + attrKey + '-' + attrIndex}
                                             className={classes.formControl}
                                             error={props.definition.generatorClass_error_text !== undefined}>
                                   <TextField required
                                              autoComplete="off"
                                              label={attrKey}
                                              placeholder="Please enter some value"
                                              margin="dense"
                                              value={attr.name}
                                              error={attr.name_error_text !== undefined}
                                              helperText={attr.name_error_text}
                                              onChange={handleArgInputChange({attrName: attrKey, attrIndex: attrIndex, argIndex: argIndex})}/>
                                </FormControl> :
                                (
                                  attrKey === 'type' ?
                                  <FormControl key={props.definition.name + 'arg-' + argIndex + '-attr-' + attrKey + '-' + attrIndex}
                                               className={classes.formControl}>
                                    <Autocomplete label={'Select Type'}
                                                  placeholder={'Please select type'}
                                                  options={useFullOptionList}
                                                  value={
                                                    attr.type !== '' ?
                                                    {
                                                      label: attr.type,
                                                      value: attr.type,
                                                    } :
                                                    ''
                                                  }
                                                  onChange={handleArgInputChange({attrName: attrKey, attrIndex: attrIndex, argIndex: argIndex})}
                                                  isMulti={false}
                                                  async={false}/>
                                  </FormControl> :
                                  ''
                                )
                            ))
                          ))
                        }
                      </CardContent>
                    </div>
                  </Card>
                )) :
                ''
              }
            </div>
          </form>
        )
      }
      else {
        return (
          <form ref={form => this.declarationForm = form}
                autoComplete="off"
                noValidate
                className={classes.formRoot}>
            {this.getSwitchForNewIfAppropriate(props)}
            <div style={{display: 'flex', flexDirection: 'column', paddingTop: 20}}>
              <Fade in={props.definition.type.toLowerCase() !== 'label'} unmountOnExit>
                <div style={{display: 'flex'}}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={props.definition.shorten === 'yes' ? true : false}
                                value={props.definition.shorten === 'yes' ? true : false}
                                onChange={handleInputChange({fieldName: 'shorten'})}
                                color="primary"
                      />
                    }
                    label="Use Shorten"
                    style={{marginTop: -7, marginLeft: 0, marginRight: 0, width: '50%'}}
                    classes={{label: classes.boldLabel}}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={props.definition.uuid === 'yes' ? true : false}
                                value={props.definition.uuid === 'yes' ? true : false}
                                onChange={handleInputChange({fieldName: 'uuid'})}
                                color="primary"
                      />
                    }
                    label="Use UUID"
                    style={{marginTop: -7, marginLeft: 0, marginRight: 0, width: '50%'}}
                    classes={{label: classes.boldLabel}}
                  />
                </div>
              </Fade>
              <FormControl className={classes.formControl} error={props.definition.prefix_error_text !== undefined}>
                <Autocomplete label={'Type'}
                              placeholder={'Select Value'}
                              options={typeOptions}
                              value={
                                props.definition.type !== '' ?
                                {
                                  label: props.definition.type,
                                  value: props.definition.type,
                                } :
                                {
                                  label: 'any',
                                  value: 'any',
                                }
                              }
                              isClearable={false}
                              onChange={handleInputChange({fieldName: 'type', value: props.definition.type})}
                              isMulti={false}
                              async={false}
                />
              </FormControl>
              <FormControl className={classes.formControl} error={props.definition.name_error_text !== undefined}>
                 <TextField required
                            autoComplete="off"
                            label="Name"
                            placeholder="Please enter some text value"
                            margin="dense"
                            value={props.definition.name}
                            error={props.definition.name_error_text !== undefined}
                            helperText={props.definition.name_error_text}
                            onChange={handleInputChange({fieldName: 'name', value: props.definition.name})}/>
              </FormControl>
              <FormControl className={classes.formControl} error={props.definition.pattern_error_text !== undefined}>
                 <TextField required
                            autoComplete="off"
                            label="Pattern"
                            placeholder="Please enter some pattern value"
                            margin="dense"
                            value={props.definition.pattern}
                            error={props.definition.pattern_error_text !== undefined}
                            helperText={props.definition.pattern_error_text}
                            onChange={handleInputChange({fieldName: 'pattern', value: props.definition.pattern})}/>
              </FormControl>
              <Fade in={props.definition.type.toLowerCase() !== 'label'} unmountOnExit>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <FormControl className={classes.formControl} error={props.definition.prefix_error_text !== undefined}>
                    <Autocomplete label={'Prefix'}
                                  placeholder={'Type some prefix value'}
                                  options={noDuplicatesPrefixHistory}
                                  value={
                                    props.definition.prefix !== '' ?
                                    {
                                      label: props.definition.prefix,
                                      value: props.definition.prefix,
                                    } :
                                    ''
                                  }
                                  onChange={handleInputChange({fieldName: 'prefix', value: props.definition.prefix})}
                                  error={props.definition.prefix_error_text !== undefined}
                                  errorText={props.definition.prefix_error_text}
                                  isMulti={false}
                                  async={false}
                                  creatable
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} error={props.definition.namespace_error_text !== undefined}>
                     <TextField required
                                autoComplete="off"
                                label="Namespace"
                                placeholder="Please enter some namespace value"
                                margin="dense"
                                value={props.definition.namespace}
                                error={props.definition.namespace_error_text !== undefined}
                                helperText={props.definition.namespace_error_text}
                                onChange={handleInputChange({fieldName: 'namespace', value: props.definition.namespace})}/>
                  </FormControl>
                </div>
              </Fade>
            </div>
          </form>
        );
      }
    }
    else {
      return (
        <div className={classes.formBase} style={{maxWidth: 300}}>
          <Typography color="textSecondary">
            Please select some generator Definition from the list on the left panel
          </Typography>
        </div>
      );
    }

  }

  // props: {id: <Text>, listType: 'selected | selectedPreloaded'}
  isSelectedForEditMode = props => {
    let index = -1;
    index = this.props[props.listType + 'DefinitionListForEditMode'].findIndex(
      obj => obj.id === props.id
    );
    return index === -1 ? false : true;
  }

  handleTogglePreloadedExpanded = expandedPanelName => () => {
    this.setState({
      expandedDefListPanel: this.state.expandedDefListPanel === expandedPanelName ? '' : expandedPanelName,
    });
  };

  //  ##################### FilePond #######################
  handleGeneratorPolicyImportFileProcessingError = (error) => {
    return 'Error occured while importing the generator policy file - ' + error.body;
  }

  render() {
    const {
      classes, user, dialogOpen, fullScreen, onToggleFullScreen, onLeave, saveNow, shakeNow, definitionListEditModeEnabled,
      selectedDefinitionListForEditMode, selectedPreloadedDefinitionListForEditMode, generatorDefinitions,
      preloadedGeneratorDefinitions, currDefinition, generatorPolicyFileImportShown,
      handleSaveGeneratorDefinition, handleSaveGeneratorDefinitionCallBack, selectDefinition, deleteSelectedGeneratorDefinitions, deleteSelectedPreloadedGeneratorDefinitions,
      handleInputChangeDefinitionListEditMode, selectDefinitionForEditMode, doOrUndoGeneratorDefinitionPreloaded,
      theSystemIsUpdatingGeneratorDeclarations, handleSelectToAddToLocalList, handleAddEmptyCustomArgToGeneratorDefinition,
      exportLocalDefinitions, selectFromImportViewEnabled, toBeImportedGeneratorDfinitions,
      handleGeneratorPolicyFileValidation, handleAfterGeneratorPolicyImportFileUpload,
      showGeneratorPolicyImport, hideGeneratorPolicyImport, disableSelectFromImportView, loadGeneratorDefinitions,
    } = this.props;

    console.log('GeneratorDialog Rendered');

    const isBuildInDefinition =
      currDefinition !== '' ?
      (
        currDefinition.name.toLowerCase() === 'uuid' ||
        currDefinition.name.toLowerCase() === 'literal' ||
        currDefinition.name.toLowerCase() === 'preflabel'
      ) :
      false;

    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="generator-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="xl"
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : '',
          }
        }}
        onKeyUp={(e) => {
          const ENTER = 13;
          if (e.keyCode === ENTER) {
            if(!isBuildInDefinition && currDefinition !== undefined && currDefinition !== '') {
              handleSaveGeneratorDefinitionCallBack({generatorDefinition: currDefinition});
            }
          }
        }}
      >
              {/*classes={{paperScrollPaper: classes.customScrollPaper}}*/}
        <DialogTitle style={{paddingBottom: 0}}>
          Generator Definitions Manager Dialog
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onLeave({actionType: 'close'})}>
                          {/*padding: 'unset'*/}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Divider style={{marginTop: 15, marginLeft: -24, marginRight: -24}}/>
        </DialogTitle>
        <Fade in={theSystemIsUpdatingGeneratorDeclarations} unmountOnExit>
          <LinearProgress />
        </Fade>
        {/*Like Backdrop*/}
        <div hidden={!theSystemIsUpdatingGeneratorDeclarations}
             style={{zIndex: 11,
                     backgroundColor: 'rgba(0, 0, 0, 0.5)',
                     width: '100%',
                     height: '100%',
                     position: 'absolute',
                     marginTop: 68}}
        />
        <DialogContent style={{overflow: 'hidden'}}>
          <DialogContentText style={{width: 765, paddingBottom: 20}}>
            <Fade in={!definitionListEditModeEnabled}>
              <span style={{position: 'absolute'}}>
                {
                  currDefinition !== undefined ?
                  (
                    currDefinition !== '' ?
                    (
                      currDefinition.id !== undefined ?
                      '' :
                      'Please fill in the form and save to add the new definition in the list of "Available Definitions for this Project"'
                    ) :
                    ''
                  ) :
                  ''
                }
              </span>
            </Fade>
            <Fade in={definitionListEditModeEnabled}>
              <span style={{position: 'absolute'}}>
                Please check one or more generator definitions from the list to be removed.
              </span>
            </Fade>
            <Fade in={selectFromImportViewEnabled}>
              <span style={{position: 'absolute'}}>
                Please define the definitions to be loaded, by selecting them at the left panel
                and then moving them at the right panel. When you are done, click the "Load Selected
                Definitions" button.
              </span>
            </Fade>
          </DialogContentText>
          <Collapse in={!selectFromImportViewEnabled}>
            <div style={{display: 'flex', minHeight: 500}}>
              <Slide direction="right" in={currDefinition.new !== undefined ? !currDefinition.new : true} mountOnEnter unmountOnExit>
            	  <Card style={{marginRight: 15, flexGrow: 1}}>
                  <CardContent style={{paddingBottom: 20, minWidth: 470}}>
                    <div style={{
                          backgroundColor: definitionListEditModeEnabled ? 'rgb(255, 226, 236)' : 'unset',
                          marginTop: -16,
                          marginLeft: -16,
                          marginRight: -16
                         }}>
                      <div style={{padding: 16}}>
                        <Fade in={!definitionListEditModeEnabled}>
                          <Typography color="textPrimary" style={{position: 'absolute'}}>
                            Generator Definitions
                          </Typography>
                        </Fade>
                        <Fade in={definitionListEditModeEnabled}>
                          <Typography color="textPrimary" style={{position: 'absolute', color: '#f50057'}}>
                          {
                            this.state.expandedDefListPanel === 'local' ?
                            <span>
                              <span>Selected:</span>
                              <span style={{paddingLeft: 5}}>{selectedDefinitionListForEditMode.length}</span>
                            </span> :
                              !user.roles.includes('administrator') ?
                              <span>
                                Administration access is required for deleting pre-loaded definitions
                              </span> :
                              <span>
                                <span>Selected:</span>
                                <span style={{paddingLeft: 5}}>{selectedPreloadedDefinitionListForEditMode.length}</span>
                              </span>
                            }
                          </Typography>
                        </Fade>
                        <Typography color="textPrimary"style={{textAlign: 'right'}}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={definitionListEditModeEnabled}
                                onChange={handleInputChangeDefinitionListEditMode}
                                value={definitionListEditModeEnabled}
                                color="secondary"
                              />
                            }
                            label="Edit List"
                            style={{marginTop: -7, marginLeft: 0, marginRight: 0}}
                            classes={{label: classes.boldLabel}}
                          />
                        </Typography>
                      </div>
                    </div>


                    {/*<div style={{maxHeight: !fullScreen ? 'calc(100vh - 350px)' : 'calc(100vh - 243px', overflowY: 'auto'}}>*/}

                    <Accordion expanded={this.state.expandedDefListPanel === 'preloaded'} onChange={this.handleTogglePreloadedExpanded('preloaded')}>
                    <AccordionSummary classes={{
                                              root: this.state.expandedDefListPanel === 'preloaded' ? classes.customExpansionPanelSummaryRoot : '',
                                              expanded: this.state.expandedDefListPanel === 'preloaded' ? classes.customExpansionPanelSummaryExpanded : '',
                                            }}
                                            expandIcon={<ExpandMoreIcon />}>
                        <Tooltip title={'Definitions to be available for all users'}
                                 placement="top"
                                 enterDelay={500}
                                 leaveDelay={200}>
                                 <Typography>Pre-loaded Definitions</Typography>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails classes={{root: classes.customRootExpansionPanelDetails}}>
                        <div style={{
                              width: '100%',
                              maxHeight: !fullScreen ? 'calc(100vh - 556px)' : definitionListEditModeEnabled ? 'calc(100vh - 370px' : 'calc(100vh - 490px',
                              overflowY: 'auto'
                            }}>
                          <List>
                            {preloadedGeneratorDefinitions.map((definition, index) => (
                              <div key={definition.id}>
                                <ListItem role={undefined} dense>
                                  <div style={{display: 'flex', width: '100%'}}>
                                    <div style={{width: 45, marginTop: -4}}>
                                      <Fade in={definitionListEditModeEnabled}>
                                        <Checkbox checked={this.isSelectedForEditMode({id: definition.id, listType: 'selectedPreloaded'})}
                                                  onChange={selectDefinitionForEditMode({definition: definition, listType: 'selectedPreloaded'})}
                                                  style={{position: 'absolute'}}
                                                  disabled={!user.roles.includes('administrator')}
                                        />
                                      </Fade>
                                    </div>
                                    <ListItemText primary={definition.name}
                                                  secondary={
                                                    <React.Fragment>
                                                      <span hidden={definition.prefix === undefined}>
                                                        {'Prefix: ' + definition.prefix}<br/>
                                                      </span>
                                                      <span hidden={definition.pattern === undefined}>
                                                        {'Pattern: ' + definition.pattern}<br/>
                                                      </span>
                                                      <span>
                                                        {
                                                          definition.shorten !== undefined ?
                                                          (definition.shorten || definition.shorten === 'yes' || definition.shorten === 1 ? 'Shorten' : '') :
                                                          ''
                                                        }
                                                        {
                                                          definition.shorten !== undefined && definition.uuid !== undefined ?
                                                          (
                                                            (definition.uuid || definition.uuid === 'yes' || definition.uuid === 1) &&
                                                            (definition.shorten || definition.shorten === 'yes' || definition.shorten === 1) ?
                                                            ', ' :
                                                            ''
                                                          ) :
                                                          ''
                                                        }
                                                        {
                                                          definition.uuid !== undefined ?
                                                          (definition.uuid || definition.uuid === 'yes' || definition.uuid === 1 ? 'UUID' : '') :
                                                          ''
                                                        }
                                                      </span>
                                                      <span hidden={definition.description === undefined} style={{fontStyle: 'italic'}}>
                                                        {definition.description}<br/>
                                                      </span>
                                                    </React.Fragment>
                                                  }
                                    />
                                    <Fade in={!definitionListEditModeEnabled}>
                                      <div style={{textAlign: 'right'}}>
                                        <Tooltip title={'Insert to local list'}
                                                 placement="bottom"
                                                 enterDelay={500}
                                                 leaveDelay={200}>
                                          <IconButton className={classes.button}
                                                      aria-label="insert-to-local-list"
                                                      onClick={handleSelectToAddToLocalList({generatorDefinition: definition})}
                                                      style={{marginRight: -10}}>
                                            <Icon className={classNames('fas fa-clone')}/>
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </Fade>
                                  </div>
                                </ListItem>
                                <Divider hidden={index === preloadedGeneratorDefinitions.length-1}/>
                              </div>
                            ))}
                          </List>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={this.state.expandedDefListPanel === 'local'} onChange={this.handleTogglePreloadedExpanded('local')} style={{marginBottom: 0}}>
                      <AccordionSummary classes={{
                                                root: this.state.expandedDefListPanel === 'local' ? classes.customExpansionPanelSummaryRoot : '',
                                                expanded: this.state.expandedDefListPanel === 'local' ? classes.customExpansionPanelSummaryExpanded : '',
                                              }}
                                              expandIcon={<ExpandMoreIcon />}>
                        <Typography>Available Definitions for this Project</Typography>
                      </AccordionSummary>
                      <AccordionDetails classes={{root: classes.customRootExpansionPanelDetails}}>
                        <div style={{
                                width: '100%',
                                maxHeight: !fullScreen ? 'calc(100vh - 556px)' : definitionListEditModeEnabled ? 'calc(100vh - 370px' : 'calc(100vh - 490px',
                                overflowY: 'auto'
                              }}>
                          <List>
                            {generatorDefinitions.map((definition, index) => (
                              <div key={definition.id}>
                                <ListItem role={undefined} dense button
                                          disabled={
                                            definitionListEditModeEnabled ?
                                            (
                                              definition.name !== undefined ?
                                              (definition.name.toLowerCase() === 'uuid' ||
                                               definition.name.toLowerCase() === 'literal' ||
                                               definition.name.toLowerCase() === 'preflabel') :
                                              false
                                            ):
                                            false
                                          }
                                          onClick={
                                            definitionListEditModeEnabled ?
                                            selectDefinitionForEditMode({definition: definition, listType: 'selected'}) :
                                            selectDefinition({generatorDefinition: definition, index: index})
                                          }>
                                  <div style={{display: 'flex'}}>
                                    <div style={{width: 45, marginTop: -4}}>
                                      <Fade in={!definitionListEditModeEnabled}>
                                        <Radio checked={definition.id === currDefinition.id}
                                               style={{position: 'absolute'}}
                                        />
                                      </Fade>
                                      <Fade in={definitionListEditModeEnabled}>
                                        <Checkbox checked={this.isSelectedForEditMode({id: definition.id, listType: 'selected'})}
                                                  style={{position: 'absolute'}}
                                                  hidden={
                                                    definition.name !== undefined ?
                                                    (definition.name.toLowerCase() === 'uuid' ||
                                                     definition.name.toLowerCase() === 'literal' ||
                                                     definition.name.toLowerCase() === 'preflabel') :
                                                    false
                                                  }
                                        />
                                      </Fade>
                                    </div>
                                    <ListItemText primary={definition.name}
                                                  secondary={
                                                    <React.Fragment>
                                                      <span hidden={definition.prefix === undefined}>
                                                        {'Prefix: ' + definition.prefix}<br/>
                                                      </span>
                                                      <span hidden={definition.pattern === undefined}>
                                                        {'Pattern: ' + definition.pattern}<br/>
                                                      </span>
                                                      <span>
                                                        {
                                                          definition.shorten !== undefined ?
                                                          (definition.shorten || definition.shorten === 'yes' || definition.shorten === 1 ? 'Shorten' : '') :
                                                          ''
                                                        }
                                                        {
                                                          definition.shorten !== undefined && definition.uuid !== undefined ?
                                                          (
                                                            (definition.uuid || definition.uuid === 'yes' || definition.uuid === 1) &&
                                                            (definition.shorten || definition.shorten === 'yes' || definition.shorten === 1) ?
                                                            ', ' :
                                                            ''
                                                          ) :
                                                          ''
                                                        }
                                                        {
                                                          definition.uuid !== undefined ?
                                                          (definition.uuid || definition.uuid === 'yes' || definition.uuid === 1 ? 'UUID' : '') :
                                                          ''
                                                        }
                                                      </span>
                                                      <span hidden={definition.description === undefined} style={{fontStyle: 'italic'}}>
                                                        {definition.description}<br/>
                                                      </span>
                                                    </React.Fragment>
                                                  }
                                    />
                                  </div>
                                </ListItem>
                                <Divider hidden={index === generatorDefinitions.length-1}/>
                              </div>
                            ))}
                          </List>
                          <div style={{position: 'absolute', bottom: 100, zIndex: 1, right: 30}}>
                            <Fade in={!definitionListEditModeEnabled}>
                              <div>
                                <Tooltip title="Add New Generator Definition"
                                         placement="right">
                                   <Fab color="primary"
                                        aria-label="add-label-generator"
                                        onClick={onLeave({actionType: 'goToAddNew'})}
                                        >
                                     <AddIcon />
                                   </Fab>
                                </Tooltip>
                              </div>
                            </Fade>
                          </div>
                        </div>
                        <div style={{height: definitionListEditModeEnabled ? 'unset' : 100}}>
                          <Grow in={!generatorPolicyFileImportShown && !definitionListEditModeEnabled}>
                            <div>
                              <Divider style={{height: 2}}/>
                              <div style={{position: 'absolute'}}>
                                <div style={{display: 'flex', marginTop: 20}}>
                                  <div style={{marginLeft: 10, marginTop: 2, display: 'flex'}}>
                                    <Fab variant="extended"
                                         size="small"
                                         color="primary"
                                         aria-label="download"
                                         className={classes.downloadButton}
                                         onClick={exportLocalDefinitions}>
                                      <Icon
                                        className={classNames(classes.icon, 'fa fa-download')}
                                        style={{marginRight: 5}}
                                        fontSize="small"
                                      />
                                      Export definitions to file
                                    </Fab>
                                  </div>
                                  <div style={{marginTop: 10}}>
                                    <Button color="primary" onClick={showGeneratorPolicyImport}>
                                      Import from File
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grow>
                          <Grow in={generatorPolicyFileImportShown && !definitionListEditModeEnabled}>
                            <div>
                              <div style={{position: 'absolute', width: '100%'}}>
                                <div style={{display: 'flex', margin: 10, }}>
                                  <div style={{flexGrow: 1}}>
                                    <FilePond
                                      name="file"
                                      labelIdle={'<div class="filePondCustomLabelStyle">Drag & Drop the generator policy XML file to import here or <span class="filepond--label-action"> browse for it </div>'}
                                      ref={ref => this.pond = ref}
                                      server={
                                        {
                                          url: (dirRootContextPath === '' ? '.' : '') + dirRootContextPath + '/uploadFilepond/generatorPolicyImported',
                                          process: {
                                            headers: {
                                              Authorization: Cookies.get('3mToken')
                                            },
                                          },
                                          revert: {
                                            headers: {
                                              Authorization: Cookies.get('3mToken')
                                            },
                                            method: 'DELETE'
                                          }
                                        }
                                      }
                                      allowMultiple={false}
                                      allowRevert={false}
                                      allowReplace={true}
                                      acceptedFileTypes={['text/xml']}
                                      fileValidateTypeDetectType={handleGeneratorPolicyFileValidation}
                                      labelFileProcessingError={this.handleGeneratorPolicyImportFileProcessingError}
                                      onprocessfile={handleAfterGeneratorPolicyImportFileUpload}
                                      className={classes.generatorPolicyImportFilePondRoot}
                                      credits="false"
                                    />
                                  </div>
                                  <div style={{margin: 'auto', marginLeft: 10}}>
                                    <Button color="primary" onClick={hideGeneratorPolicyImport}>
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grow>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <div style={{zIndex: 1}} align="right">
                      <Fade in={
                        definitionListEditModeEnabled && this.state.expandedDefListPanel !== ''}>
                        <div>
                          <Tooltip title={
                                    this.state.expandedDefListPanel === 'local' ?
                                    'Delete selected Generator Definition(s)' :
                                    'Delete selected pre-loaded Generator Definition(s)'
                                   }
                                   placement="top"
                          >
                             <div>
                               <Fab color="secondary"
                                    aria-label="delete-label-generator"
                                    onClick={
                                      this.state.expandedDefListPanel === 'local' ?
                                      deleteSelectedGeneratorDefinitions(selectedDefinitionListForEditMode) :
                                      deleteSelectedPreloadedGeneratorDefinitions(selectedPreloadedDefinitionListForEditMode)
                                    }
                                    disabled={
                                      this.state.expandedDefListPanel === 'local' ?
                                      selectedDefinitionListForEditMode.length <= 0 :
                                        selectedPreloadedDefinitionListForEditMode.length <= 0 ?
                                        true :
                                        !user.roles.includes('administrator')
                                    }
                                    style={{
                                      position: 'absolute',
                                      bottom: this.state.expandedDefListPanel === 'preloaded' ? 160 : 100,
                                      right: 84
                                    }}>
                                 <DeleteIcon />
                               </Fab>
                             </div>
                          </Tooltip>
                        </div>
                      </Fade>
                    </div>
                  </CardContent>
            	  </Card>
              </Slide>
              <Slide direction="left" in={!definitionListEditModeEnabled} mountOnEnter unmountOnExit>
                <Card style={{flexGrow: 1}}>
                  <CardContent style={{minWidth: 350}}>
                    <div>
                      <div style={{display: 'flex', width: '100%'}}>
                        <Typography color="textPrimary">
                          {currDefinition.name}
                        </Typography>
                        <Fade in={
                          currDefinition.preloaded !== undefined ? !currDefinition.preloaded : true &&
                          currDefinition.new !== undefined ? !currDefinition.new : true &&
                          !isBuildInDefinition &&
                          currDefinition !== ''
                        }>
                          <div style={{textAlign: 'right', flexGrow: 1, marginTop: -13}}
                               hidden={user.id !== undefined ? !user.roles.includes('administrator') : true}
                          >
                            <Tooltip title={'Add this definition in the list of pre-loaded'}
                                     placement="left-end"
                                     enterDelay={500}
                                     leaveDelay={200}>
                              <IconButton className={classes.button}
                                          aria-label="maximize"
                                          onClick={doOrUndoGeneratorDefinitionPreloaded}>
                                <PresentToAllIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Fade>
                      </div>
                      <div style={{overflowY: 'auto', maxHeight: !fullScreen ? 'calc(100vh - 363px)' : 'calc(100vh - 260px)'}}>
                        {this.getDefinitionForm({definition: currDefinition})}
                      </div>
                      <div style={{zIndex: 1, paddingRight: 90}} align="right">
                        <Fade in={currDefinition.custom !== undefined ? currDefinition.custom : false}>
                          <div>
                            <Tooltip title="Add Argument"
                                     placement="left">
                               <Fab color="primary"
                                    aria-label="add-argument-custom-generator"
                                    onClick={handleAddEmptyCustomArgToGeneratorDefinition({definition: currDefinition})}
                                    style={{position: 'absolute', bottom: 40, zIndex: 1}}>
                                 <AddIcon />
                               </Fab>
                            </Tooltip>
                          </div>
                        </Fade>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Slide>
            </div>
          </Collapse>
          <Collapse in={selectFromImportViewEnabled}>
            <div style={{marginTop: 16}}>
              {/*localDefinitions={generatorDefinitions}*/}
              <TransferList
                newAvailableDefinitions={toBeImportedGeneratorDfinitions}
                inTheListToLoad={[]}
                leftTitle={'Generator definitions from the file'}
                rightTitle={'Generator definitions to load'}
                disableSelectFromImportView={disableSelectFromImportView}
                loadGeneratorDefinitions={loadGeneratorDefinitions}
              />
            </div>
          </Collapse>
        </DialogContent>
        <Collapse in={!selectFromImportViewEnabled}>
          <div>
            <DialogActions>
              <Fade in={currDefinition.new !== undefined ? currDefinition.new : false}>
                <div style={{paddingRight: 15}}>
                  <Button color="primary" onClick={onLeave({actionType: 'goToMain'})}>
                    cancel
                  </Button>
                </div>
              </Fade>
              <Fade in={!definitionListEditModeEnabled}>
                <div style={{paddingRight: 15}}>
                  <Button
                    color={!saveNow ? 'primary' : 'secondary'}
                    onClick={handleSaveGeneratorDefinition({generatorDefinition: currDefinition})}
                    disabled={isBuildInDefinition || currDefinition === undefined || currDefinition === ''}
                  >
                    <span className={shakeNow ? 'rumble' : ''}>save</span>
                  </Button>
                </div>
              </Fade>
              <div style={{paddingRight: 15}}>
                <Button color="primary" onClick={onLeave({actionType: 'close'})}>
                  Close
                </Button>
              </div>
            </DialogActions>
          </div>
        </Collapse>
      </Dialog>
    )
  }
}

GeneratorDefinitionsManagerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneratorDefinitionsManagerDialog);
