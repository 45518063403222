import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();
const rootContextPath = configuration.rootContextPath;

export class ReasonerService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }
  
  async initModels(data) {
     return await axiosInstance(rootContextPath + '/reasoner/initModelsByMappingProjectId', {
      method: 'post',
      data: data,
      //adapter: cacheAdapterEnhancer(axios.defaults.adapter),
    });
  }

  async updateModels(data) {
     return await axiosInstance(rootContextPath + '/reasoner/updateModelsByMappingProjectId', {
      method: 'post',
      data: data,
    });
  }

  /*
  List all classes
  */
  async getClasses(data) {
     return await axiosInstance(rootContextPath + '/reasoner/getClasses', {
      method: 'post',
      data: data,
    });
  }

  async getProperties(data) {
     return await axiosInstance(rootContextPath + '/reasoner/getProperties', {
      method: 'post',
      data: data,
    });
  }

  async getObjects(data) {
     return await axiosInstance(rootContextPath + '/reasoner/getObjects', {
      method: 'post',
      data: data,
    });
  }

  // Output file related services

  async retrieveAllOutputClasses(data) {
     return await axiosInstance(rootContextPath + '/reasoner/retrieveAllOutputClasses', {
      method: 'post',
      data: data,
    });
  }

  async retrieveInstancesOfClass(data) {
     return await axiosInstance(rootContextPath + '/reasoner/retrieveInstancesOfClass', {
      method: 'post',
      data: data,
    });
  }

}
