import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();

const rootContextPath = configuration.rootContextPath;

export class FileUploadService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }
  async storeFileMetadata(data) {
     return await axiosInstance(rootContextPath + '/storeFileMetadata', {
      method: 'post',
      data: data,
    });
  }

  async retrieveFileMetadataById(data) {
     return await axiosInstance(rootContextPath + '/retrieveFileMetadataById', {
      method: 'get',
      params: data,
    });
  }

  async retrieveFileMetadataListByFileScope(data) {
     return await axiosInstance(rootContextPath + '/retrieveFileMetadataListByFileScope', {
      method: 'get',
      params: data,
    });
  }

  async retrievePredefinedFileMetadataListByFileScope(data) {
     return await axiosInstance(rootContextPath + '/retrievePredefinedFileMetadataListByFileScope', {
      method: 'get',
      params: data,
    });
  }

  async retrievePredefinedFileMetadataListByFileScopeAndEnability(data) {
     return await axiosInstance(rootContextPath + '/retrievePredefinedFileMetadataListByFileScopeAndEnability', {
      method: 'get',
      params: data,
    });
  }

  async deleteFileAndMaybeFileMetadata(data) {
     return await axiosInstance(rootContextPath + '/deleteFileAndFileMetadata', {
      method: 'post',
      data: data,
    });
  }

  // Not used
  async deleteFileByRelativePath(data) {
     return await axiosInstance(rootContextPath + '/deleteFileByRelativePath', {
       method: 'get',
       params: data,
    });
  }

  async downloadFileById(data) {
     return await axiosInstance(rootContextPath + '/downloadFileById/'+ data.id, {
      method: 'get',
    }).then((response) => {
      const contentDisposition  = response.request.getResponseHeader('Content-Disposition');
      let fileName = 'unknown';
      // Checking if Content-Disposition is present in the header
      if(contentDisposition !== null && contentDisposition !== undefined) {
        // Getting the filename from the Content-Disposition
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  async downloadRdfFileByRelativePath(data) {
     return await axiosInstance(rootContextPath + '/downloadRdfFileByRelativePath', {
      method: 'get',
      params: data,
    }).then((response) => {
      const contentDisposition  = response.request.getResponseHeader('Content-Disposition');
      let fileName = 'unknown';
      // Checking if Content-Disposition is present in the header
      if(contentDisposition !== null && contentDisposition !== undefined) {
        // Getting the filename from the Content-Disposition
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  async constructAndDownloadGeneratorPolicyFile(data) {
     return await axiosInstance(rootContextPath + '/x3ml/constructAndDownloadGeneratorPolicyFileByMappingProjectId', {
      method: 'get',
      params: {id: data.id},
    }).then((response) => {
      const contentDisposition  = response.request.getResponseHeader('Content-Disposition');
      let fileName = data.fileName;
      // Checking if Content-Disposition is present in the header
      if(contentDisposition !== null && contentDisposition !== undefined) {
        // Getting the filename from the Content-Disposition
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  async exportMappingProjectToZipById(data) {
     return await axiosInstance(rootContextPath + '/exportMappingProjectToZipById', {
      method: 'get',
      params: data,
      responseType: 'blob',
    }).then((response) => {
      const contentDisposition  = response.request.getResponseHeader('Content-Disposition');
      let fileName = 'unknown';
      // Checking if Content-Disposition is present in the header
      if(contentDisposition !== null && contentDisposition !== undefined) {
        // Getting the filename from the Content-Disposition
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      //link.remove();
    });
  }

}
