import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const styles = theme => ({

  snackbarErrorTextPrimary: {
    color: '#fff',
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
  },
  smallSnackbarIcon: {
  },
  snackbarMessage: {
    alignItems: 'center',
    display: 'flex',
  },
  snackbarCard: {
    padding: 12
  },
  successVariant: {
    backgroundColor: '#43a047'
  },
  errorVariant: {
    backgroundColor: '#d32f2f'
  },
  warningVariant: {
    backgroundColor: '#ffa000'
  },
  infoVariant: {
    backgroundColor: '#2979ff'
  },
  snackbarBodyText: {
    color: '#fff',
  },
  actionRoot: {
    display: 'block',
    textAlign: 'right'
  },
  optionsToSelectCase: {
    display: 'flex',
    paddingTop: 20,
  },
  dropDownPaper: {
    backgroundColor: '#ffa000',
    //backgroundColor: '#d98a05',
    color: '#fff',
  },
  autocompleteInputLabel: {
    color: '#ffffffab',
  },
  inputUnderlineCustom: {
    borderBottom: '#fff',
    '&::before': {
      borderBottomColor: '#fff',
    },
    '&:hover::before': {
      borderBottomColor: '#fff !important',
    },
  },
  inputCustom: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      //color: '#fff',
    }
  },
  disabledButton: {
    color: '#ffffff6b !important',
  }
});

class ConfirmSnackMessage extends React.Component {

  state={
    isNewAuthorSelected: false
  }

  handleNewAuthorInputChange = props => (event, user) => {
    this.setState({
      isNewAuthorSelected: true
    }, () => {
      this.props.handleInputChange(user);
    });
  }

  render() {
    const {
      classes, id, msg, variant, iconSize, hasHtmlContent, onClickOfDissmissButton, fullMessage,
      onConfirmClick, confirmButtonLabel, cancelButtonLabel, onCancelClick, argument,
    } = this.props;

    return (
      <Card className={classNames(classes.snackbarCard, classes[variant+'Variant'], classes.snackbarBodyText)}>
        <span className={classes.snackbarMessage}>
          {
            variant === 'success' ?
            <CheckCircleOutlineIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            variant === 'error' ?
            <ErrorOutlineIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            variant === 'warning' ?
            <WarningIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            variant === 'info' ?
            <InfoIcon className={classNames(classes[iconSize+'SnackbarIcon'], classes.snackbarIconVariant)} /> :
            ''
          }
          {/* Simple structure Message */}
          <Typography hidden= {hasHtmlContent !== undefined ? hasHtmlContent : false} variant="body2">
            {msg}
          </Typography>
          {/* HTML structure Message */}
          <div hidden= {hasHtmlContent !== undefined ? !hasHtmlContent : true}>
            {renderHTML(msg)}
          </div>
        </span>
        <CardActions classes={{ root: classes.actionRoot }}>
          {/* Optional component */}
          <div
            className={
              this.props.showOptionsToChoose !== undefined ?
              this.props.showOptionsToChoose ?
                classes.optionsToSelectCase :
                '' :
              ''
            }
          >
            {
              this.props.showOptionsToChoose !== undefined ?
                this.props.showOptionsToChoose ?
                <div style={{flexGrow: 1, marginTop: -24, paddingRight: 10}}>
                  <Autocomplete
                    disablePortal
                    options={this.props.options}
                    getOptionLabel={
                      (option) =>
                        (
                          (option.firstname !== undefined ? option.firstname : '') +
                          (option.lastname !== undefined ? (' ' + option.lastname) : '') +
                          (option.username !== undefined ? (' (' + option.username + ')') : '')
                        )
                    }
                    renderOption={(option) => (
                      <React.Fragment>
                        <span style={{wordBreak: 'break-all'}}>
                          {
                            (
                              (option.firstname !== undefined ? option.firstname : '') +
                              (option.lastname !== undefined ? (' ' + option.lastname) : '') +
                              (option.username !== undefined ? (' (' + option.username + ')') : '')
                            )
                          }
                        </span>
                      </React.Fragment>
                    )}
                    getOptionSelected={
                      (option, value) => {
                        return ((value !== '' || value !== undefined) ? option.value === value.value : false)
                      }
                    }
                    onChange={this.handleNewAuthorInputChange()}
                    disableClearable={true}
                    noOptionsText={<span className={classes.autocompleteInputLabel}>{'No options'}</span>}
                    popupIcon={
                      <ArrowDropDownIcon className={classes.autocompleteInputLabel} />
                    }
                    filterSelectedOptions={false /* if true it will remove selected from the option list */}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="standard"
                        label={this.props.label}
                        placeholder={this.props.placeholder}
                        InputLabelProps={{
                          classes: {
                            root: classes.autocompleteInputLabel,
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            underline: classes.inputUnderlineCustom,
                            input: classes.inputCustom
                          },
                        }}
                      />
                    )}
                    classes={{
                      paper: classes.dropDownPaper,
                      input: classes.snackbarBodyText,
                    }}
                  />
                </div> :
                '' :
              ''
            }
            {/*value={this.props.selectedItem !== undefined ? this.props.selectedItem : ''}*/}
            <Button
              size="small"
              color="secondary"
              onClick={
                onConfirmClick({id: id, argument: argument})
              }
              disabled={
                this.props.showOptionsToChoose !== undefined ?
                  (
                    this.props.showOptionsToChoose ?
                    !this.state.isNewAuthorSelected :
                    false
                  ) :
                false
              }
              classes={{
                disabled: classes.disabledButton,
              }}
            >
              <b>{confirmButtonLabel}</b>
            </Button>
            <Button size="small" color="secondary" onClick={onCancelClick({id: id, argument: argument})}>
              <b>{cancelButtonLabel}</b>
            </Button>
          </div>
        </CardActions>
      </Card>
    );
  }
}

ConfirmSnackMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(ConfirmSnackMessage);
