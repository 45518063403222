import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Tooltip from '@material-ui/core/Tooltip';

//import './css/custom.css';
const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  intermediateEdit: {
    borderStyle: 'dashed',
    borderColor: 'rgb(32, 150, 243)',
    marginTop: 5,
    marginBottom: 5,
    //marginLeft: 20,
    marginLeft: -8,
    padding:5,
    borderLeft:'solid'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
    display:'flex',
    flexDirection:'column',
    justifyContent: 'center',
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  twoColumnGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 5,
    padding: 5,
  }
});

export class SourceIntermediateComponentView extends React.Component {
//export class SourceIntermediateComponentView extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.domainSourceNode !== nextProps.domainSourceNode) {
      return true;
    }
    if(this.props.intermediate !== nextProps.intermediate) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {

    const { classes } = this.props;
    const { intermediate, domainSourceNode } = this.props; //intermediateIndex

    return (
      <div className={classes.intermediateEdit}>
        <div style={{display:'flex'}}>
          <div className={classes.divIconStyleWrapper}>
            <div className={classes.divGreyOutlinedIconStyle}
                 style={{
                   borderColor: intermediate.sourceNode.error_text !== undefined ? 'red' : 'unset',
                   backgroundColor: intermediate.sourceNode.error_text !== undefined ? '#ff000052' : 'unset',
                 }}/>
          </div>
          <div style={{display:'flex', overflow: 'hidden'}}>
            <b style={{minWidth: 90, color: intermediate.sourceNode.error_text !== undefined ? 'red' : 'unset'}}>
              Source Node:
            </b>
            <Tooltip title={
                      <React.Fragment>
                        <div className={classes.twoColumnGridContainer}>
                          <span style={{fontWeight: 'bold'}}>{'Source Node:'}</span>
                          <span>{intermediate.sourceNode.item ? intermediate.sourceNode.item.label : ''}</span>
                          <span style={{fontWeight: 'bold'}}>{'Domain Source Node:'}</span>
                          <span>{domainSourceNode ? ' ' + domainSourceNode.item.value : 'Not Defined'}</span>
                        </div>
                      </React.Fragment>
                     }
                     classes={{ tooltip: classes.noMaxWidth }}
                     placement="top"
                     enterDelay={1000}
                     leaveDelay={200}>
               <div style={{display:'flex', padding: 5, marginTop:-4, wordBreak: 'break-all'}}>
                 {intermediate.sourceNode.item ? intermediate.sourceNode.item.label : ''}
               </div>
            </Tooltip>
          </div>
        </div>
        <div style={{display:'flex'}}>
          <div className={classes.divIconStyleWrapper}>
            <ArrowDownwardIcon style={{color: intermediate.sourceRelation.error_text !== undefined ? 'red' : 'unset'}}/>
          </div>
          <div style={{display:'flex', overflow: 'hidden'}}>
            <b style={{minWidth: 110, color: intermediate.sourceRelation.error_text !== undefined ? 'red' : 'unset'}}>
            Source Relation:
            </b>
            <Tooltip title={
                      <React.Fragment>
                        <div className={classes.twoColumnGridContainer}>
                          <span style={{fontWeight: 'bold'}}>{'Source Relation:'}</span>
                          <span>{intermediate.sourceRelation.item ? intermediate.sourceRelation.item.label : ''}</span>
                          <span style={{fontWeight: 'bold'}}>{'Domain Source Node:'}</span>
                          <span>{domainSourceNode ? ' ' + domainSourceNode.item.value : 'Not Defined'}</span>
                        </div>
                      </React.Fragment>
                     }
                     classes={{ tooltip: classes.noMaxWidth }}
                     placement="top"
                     enterDelay={1000}
                     leaveDelay={200}>
               <div style={{display:'flex', padding: 5, marginTop:-4, wordBreak: 'break-all'}}>
                 {intermediate.sourceRelation.item ? intermediate.sourceRelation.item.label : ''}
               </div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

SourceIntermediateComponentView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SourceIntermediateComponentView);
