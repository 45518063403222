import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group'; //CSSTransition
import Slide from '@material-ui/core/Slide';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConstExprEdit from './ConstExprEdit';
import PatternWithValuesAndColors from './PatternWithValuesAndColors';
import GeneratorInfo from './GeneratorInfo';
import GeneratorDeclarationError from './GeneratorDeclarationError';
import { ReasonerService } from '.././services/ReasonerService';

const reasonerService = new ReasonerService();

const filter = createFilterOptions();

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  shrinkedLinkButton: {
    fontSize: 14,
    height: 21,
    textTransform:'unset',
    whiteSpace: 'nowrap',
    padding: '0px 2px',
    margin: '0px -2px',
    lineHeight: 'unset',
    justifyContent: 'unset',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  variableIcon: {
    color: '#52c41a',
  },
  variableCompIcon: {
    color: '#52c41a',
    fontWeight: '800',
    borderColor: '#52c41a',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  instanceInfoCompIcon: {
    color: '#e58ee9',
    fontWeight: '800',
    borderColor: '#e58ee9',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  padding5TopBottom: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
  },
  intermediateEdit: {
    borderStyle: 'dashed',
    borderColor: 'rgb(32, 150, 243)',
    marginTop: 5,
    marginBottom: 5,
    //marginLeft: 20,
    marginLeft: -8,
    padding:5,
    borderLeft:'solid'
  },
  autocompleteFormControl: {
    width: '100%',
    display: 'flex'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  intermediateToDeleteHighlighted: {
    borderColor: '#f50057',
  },
  closeIconWrapper: {
    position: 'relative',
    paddingTop: 5,
    zIndex: 1,
  },
  closeIcon: {
    position: 'absolute',
    padding: 'unset',
    right: 0,
    top: 0,
  },
  constExprNodesWrapper: {
    borderLeft:'solid rgba(0, 0, 0, 0.54)',
    marginLeft: 10,
    //marginTop: 25,
    paddingRight: 25,
    //paddingTop: 25,
    width: '100%',
  },
  // Generator Declaration
  generatorDeclarationError: {
    color: 'red',
  },
  generatorDeclarationErrorneousDeleteIcon: {
    color: '#ff00001a',
    '&&:hover, &&:active': {
      color: 'red',
    },
  },
  generatorDeclarationValidDeleteIcon: {
    color: 'rgb(171 218 255)',
  },
  generatorDeclarationErrorneousChip: {
    color: 'red',
    border: 'none',
    //border: '1px solid red',
    '&&:hover, &&:active, &&:focus': {
      backgroundColor: '#ff00001a',
    },
  },
  generatorDeclarationValidChip: {
    border: 'none',
  },
  generatorDeclarationIsolationBorder: {
    // border: '1px dashed',
    // borderRadius: 10,
    // padding: 7,
    // marginBottom: 5,
  },
  // generatorDeclarationIsolationBorderValid: {
  //   borderColor: theme.palette.primary.main,
  // },
  generatorDeclarationIsolationBorderError: {
    //borderColor: 'red',
  },
  generatorDeclarationIconWrapperStyle: {
    display: 'table-cell',
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 10
  },
  patternWithValuesAndColorsWrapper: {
    fontStyle: 'italic',
    marginLeft: 9,
    wordBreak: 'break-all',
  },
  twoLinesSmallChip: {
    height: 44,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  threeLinesSmallChip: {
    height: 64,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  errorLabelButton: {
    fontSize: 12,
    lineHeight: 'unset',
    color: theme.palette.primary.main,
  },
});

//export class TargetIntermediateComponentEdit extends React.PureComponent {
export class TargetIntermediateComponentEdit extends React.Component {

  state = {
    intermediate: {
      id: -1,
      targetEntity: {
        item: '',
        isLoading: false
      },
      targetRelation: {
        item: '',
        isLoading: false
      }
    },
    targetEntityIsLoading: false,
    targetRelationIsLoading: false,
    targetEntityOptions: [],
    targetRelationOptions: [],
  };


  shouldComponentUpdate(nextProps, nextState) {

    if(this.state !== nextState) {
      return true;
    }
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.compactViewMode !== nextProps.compactViewMode) {
      return true;
    }
    if(this.props.compactViewEnabled !== nextProps.compactViewEnabled) {
      return true;
    }
    if(this.props.previousRelation.item !== nextProps.previousRelation.item) {
      return true;
    }
    if(this.props.intermediate !== nextProps.intermediate) {
      return true;
    }
    else {
      return false;
    }
  }

  //handleRetrieveOptions = paramObj => async () => {
  handleRetrieveOptions = paramObj => async () => {
    if(paramObj.name === 'targetRelation') {
      // If there is value selected for the respective targetEntity (this is an array of classes)
      if(this.props.intermediate.targetEntity.item.length > 0) {
        // Notifying for loading data
        this.setState({targetRelationIsLoading: true}, function() {
          // Resetting option list
          this.setState({targetRelationOptions: []});
        });

        // Async Wait
        try {
          // Getting the selected items (case Array of Items)
          let selectedClasses = [];
          this.props.intermediate.targetEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
          let res = await reasonerService.getProperties({
            id: this.props.currMappingProjectId,
            type: 'target',
            request: selectedClasses
          });

          if(res.data.response[0].options !== undefined) {
            // Creating option list
            this.setState({targetRelationOptions: res.data.response}, function () {
              // Notifying that loading data is over
              this.setState({targetRelationIsLoading: false});
            });
          }
          else {
            // Resetting option list
            this.setState({targetRelationOptions: []}, function() {
              // Notifying that loading data is over
              this.setState({targetRelationIsLoading: false});
            });
          }
        }
        catch (e) {
          console.error('Failure while retrieving target relation properties!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
          // Resetting option list
          this.setState({targetRelationOptions: []}, function() {
            // Notifying that loading data is over
            this.setState({targetRelationIsLoading: false});
          });
        } // catch - ends

      } // If there is value selected for the respective targetRelation - Ends
      else {
        this.setState({
          targetRelationOptions: []
        });
      }
    } // if(paramObj.name === 'targetRelation') - ends

    else if(paramObj.name === 'targetEntity') {
      // Two caces:
      //  1. load based on "Target Relation" (when this is there is none selected),
      //  2. Load all classes (when there is at least one selected),
      // Case load based on "Target Relation"
      if(this.props.intermediate.targetEntity.item.length === 0) {
        // In the case of the target entity one of the required params of the
        // service to be called is the previously target relation set.
        // This either the target relation of the link itself or the previous intermediate.
        // That's why it is passed from the parent as "previousRelation" parameter
        // and is Used here.

        if(this.props.previousRelation.item.value !== undefined) {
          // Notifying for loading data
          this.setState({targetEntityIsLoading: true}, function() {
            // Resetting option list
            this.setState({targetEntityOptions: []});
          });
          // Async Wait
          try {
            let res = await reasonerService.getObjects({
              id: this.props.currMappingProjectId,
              type: 'target',
              //property: this.props.previousRelation.item.full
              property: this.props.previousRelation.item.full ? this.props.previousRelation.item.full : this.props.previousRelation.item.value
            });

            if(res.data.response[0].options !== undefined) {
              // Creating option list
              this.setState({targetEntityOptions: res.data.response}, function () {
                // Notifying that loading data is over
                this.setState({targetEntityIsLoading: false});
              });
            }
            else {
              // Resetting option list
              this.setState({targetEntityOptions: []}, function() {
                // Notifying that loading data is over
                this.setState({targetEntityIsLoading: false});
              });
            }
          }
          catch (e) {
            console.error('Failure while retrieving target entity properties!');
            if(e.response !== undefined) {
              console.error(e.response.status);
            }
            // Resetting option list
            this.setState({targetEntityOptions: []}, function() {
              // Notifying that loading data is over
              this.setState({targetEntityIsLoading: false});
            });
          } // catch - ends
        } // If there is value selected for the respective targetRelation - Ends
        else {
          this.setState({
            targetEntityOptions: []
          });
        }
      }
      // Case load all classes (there is already one selected)
      else {
        this.setState({targetEntityOptions: this.props.allClasses});
      }
    } // if(paramObj.name === 'targetEntity') - Ends
  };

  // props: {status:'mouseEnter | mouseLeave}
  handleHoverDeleteIntermediate = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({isHovered: hovered});
  }

  render() {

    const { classes, allClasses } = this.props;
    const { targetEntityOptions, targetRelationOptions, targetEntityIsLoading, targetRelationIsLoading } = this.state;
    const {
      intermediate, mappingId, linkId, intermediateId, intermediateIndex,
      handleInputChange, handleInputChangeButton, handleAddConstExpr, handleUseGenerator, handleGoToGeneratorDeclarationForm,
      handleDirectDeleteInstanceGeneratorDeclaration, handleDirectDeleteLabelGeneratorDeclarations,
      patternTypeColors,
      handleUseVariable, handleDirectDeleteVariable,
      handleUseInstanceInfo, handleDirectDeleteInstanceInfo, instanceInfoStyleBasedOnLinesRequired,
      compactViewEnabled, compactViewMode
    } = this.props;

    console.log('render - Target Intermediate');
    // Used when deleting to "unmount" itself
    /*if(!this.props.visible) {
      return null;
    }*/

    // Additional of the target intermediate - Edit
    var intermediateEditConstExprList = intermediate.constExprIds.map((constExprId, constExprIndex) => {
      let constExpr = intermediate.constExprs[constExprId];
      return (
        <Slide direction={'left'}
               key={'targetIntermediateConstExprEdit_' + constExprId}
               style={{ transitionDelay: '400ms' }}>
               {/*timeout={400}*/}

          <ConstExprEdit
            currMappingProjectId={this.props.currMappingProjectId}
            mappingId={mappingId}
            linkId={linkId}
            intermediateId={intermediate.id}
            constExpr={constExpr}
            constExprId={constExprId}
            constExprIndex={constExprIndex}
            allClasses={allClasses}
            previousEntity={intermediate.targetEntity}
            handleDeleteConstExpr={this.props.handleDeleteIntermediateConstExpr}
            handleInputChange={this.props.handleInputChangeIntermediateConstExpr}
            handleInputChangeButton={this.props.handleInputChangeIntermediateConstExprButton}
            startInverseZindex={999999-intermediateIndex*99}
            handleUseGenerator={this.props.handleConstExprUseGenerator}
            handleUseVariable={this.props.handleConstExprUseVariable}
            handleDirectDeleteVariable={this.props.handleDirectDeleteVariable}
            handleGoToGeneratorDeclarationForm={this.props.handleGoToGeneratorDeclarationForm}
            patternTypeColors={patternTypeColors}
            compactViewMode={compactViewMode}
            handleShowConfirmDeleteSnackbar={this.props.handleShowConfirmDeleteSnackbar}
            handleDirectDeleteInstanceGeneratorDeclaration={handleDirectDeleteInstanceGeneratorDeclaration}
            handleDirectDeleteLabelGeneratorDeclarations={handleDirectDeleteLabelGeneratorDeclarations}
            handleUseInstanceInfo={this.props.handleConstExprUseInstanceInfo}
            handleDirectDeleteInstanceInfo={this.props.handleDirectDeleteInstanceInfo}
            instanceInfoStyleBasedOnLinesRequired={this.props.instanceInfoStyleBasedOnLinesRequired}
            compactViewEnabled={this.props.compactViewEnabled}
            handleDisplayConstExpr={this.props.handleDisplayConstExpr}
            index={constExprIndex}
          />
        </Slide>
      );
    });

    const instanceGeneratorDeclarationErrorMsg =
      intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        intermediate.targetEntity.instanceGeneratorDeclaration.definitionId !== undefined ?
        (
          intermediate.targetEntity.instanceGeneratorDeclaration.definitionId === null ?
          'Missing Generator Definition' :
          ''
        ) :
        ''
      ) :
      '';
    const lastInstanceGeneratorDeclarationArgErrorIndex =
      intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
      (
        intermediate.targetEntity.instanceGeneratorDeclaration.args !== undefined &&
        intermediate.targetEntity.instanceGeneratorDeclaration.args.length > 0 ?
        intermediate.targetEntity.instanceGeneratorDeclaration.args.findIndex(
          obj => (
            intermediate.targetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'literal' &&
            intermediate.targetEntity.instanceGeneratorDeclaration.name.toLowerCase() !== 'preflabel'
          ) ?
          obj.error_type_text !== '' :
          (
            obj.name.toLowerCase() !== 'language' ?
            obj.error_type_text !== '' :
            false
          )
        ) :
        -1
      ) :
      -1;

    const instanceGeneratorDeclarationArgsErrorMsg =
      lastInstanceGeneratorDeclarationArgErrorIndex !== -1 ?
      intermediate.targetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].name + ': ' +
      intermediate.targetEntity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].error_type_text :
      '';

    const instanceGeneratorDeclarationErrorStyle =
      instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
      classes.generatorDeclarationError :
      '';

    return (
      <div className={classNames(classes.intermediateEdit, this.state.isHovered ? classes.intermediateToDeleteHighlighted : '')}>
        <div className={classes.closeIconWrapper}>
          <IconButton className={classes.closeIcon}
                      aria-label="Delete"
                      onClick={
                        this.props.handleShowConfirmDeleteSnackbar({
                          handleDelete: this.props.handleDeleteIntermediate,
                          type: 'targetIntermediate',
                          mappingId: mappingId,
                          linkId: linkId,
                          intermediateId: intermediate.id,
                        })
                      }
                      onMouseEnter={this.handleHoverDeleteIntermediate({status:'mouseEnter'})}
                      onMouseLeave={this.handleHoverDeleteIntermediate({status:'mouseLeave'})}>
            <CloseIcon />
          </IconButton>
        </div>
        {/* ***************************************************************** */}
        {/* **************** Intermediate Target Entity Root **************** */}
        {/* ***************************************************************** */}
        <div style={{display:'flex'}}>
          <div style={{width: '100%'}}>
            <div className={classes.padding5TopBottom}>
              <div className={classes.divIconStyleWrapper}>
                <div className={classes.divGreyOutlinedIconStyle}/>
              </div>
              <FormControl className={classes.autocompleteFormControl}
                           error={intermediate.targetEntity.error_text !== undefined}
                           style={{minWidth: 220}}>
                <Autocomplete
                  options={
                    [].concat.apply(
                      [], targetEntityOptions.map(
                        item => item.options.map(
                          optItem => ({ ...optItem, group: item.label })
                        )
                      )
                    )
                  }
                  groupBy={(option) => option.group}
                  getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                    </React.Fragment>
                  )}
                  getOptionSelected={(option, value) => option.value === value.value}
                  onChange={handleInputChange({name: 'targetEntity', type: 'targetIntermediate', mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}
                  value={intermediate.targetEntity.item !== '' ? intermediate.targetEntity.item : []}
                  multiple
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  loading={targetEntityIsLoading}
                  onOpen={this.handleRetrieveOptions({name: 'targetEntity'})}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Target Entity"
                      placeholder="Please select target entity"
                      FormHelperTextProps={{component: 'div'}}
                      helperText={
                        <div style={{color: 'red'}}>
                          <span style={{paddingRight: 10}}>
                            {intermediate.targetEntity.error_text !== undefined ? intermediate.targetEntity.error_text : ''}
                          </span>
                          {
                            intermediate.targetEntity.item !== '' ?
                            <Button
                              color="secondary"
                              hidden={intermediate.targetEntity.error_text === undefined}
                              size="small"
                              className={classes.errorLabelButton}
                              onClick={
                                handleInputChangeButton({
                                  name: 'targetEntity',
                                  type: 'targetIntermediate',
                                  mappingId: mappingId,
                                  linkId: linkId,
                                  intermediateId: intermediateId,
                                  item: intermediate.targetEntity.item,
                                })
                              }
                            >
                              Make Custom
                            </Button> :
                            ''
                          }
                        </div>
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {targetEntityIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            {/* Generator - Expanded panel */}
            <div style={{marginLeft: 44}}>

              {/* Variable / Generator Declaration Instance/Label*/}
              <div>

                {/*Display the Variable*/}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideVariable !== undefined ?
                        compactViewMode.hideVariable :
                        false
                      ) :
                      false
                    ) :
                    true
                  }
                >
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'table-row'}}
                         hidden={
                           intermediate.targetEntity.variable === undefined ?
                           true :
                           (
                             intermediate.targetEntity.variable.value === '' ?
                             true :
                             false
                           )
                         }>
                      <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'middle'}}>
                        <Tooltip title="Variable"
                                 placement="left"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                            <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                          </div>
                        </Tooltip>
                      </div>
                      <div className={classes.generatorDeclarationIconWrapperStyle}
                           hidden={
                             intermediate.targetEntity.variable !== undefined ?
                             !intermediate.targetEntity.variable.global :
                             true
                           }
                      >
                        <Tooltip title="Global"
                                 placement="left"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                        </Tooltip>
                      </div>

                      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                        <div>
                          {
                            intermediate.targetEntity.variable !== undefined ?
                            <React.Fragment>
                              <Chip
                                classes={{
                                  root: classes.generatorDeclarationValidChip,
                                  deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                }}
                                size="small"
                                variant="outlined"
                                label={intermediate.targetEntity.variable.value}
                                onClick={
                                  handleUseVariable({
                                    mappingId: mappingId,
                                    linkId: linkId,
                                    intermediateId: intermediateId,
                                  })
                                }
                                color="primary"
                                onDelete={
                                  this.props.handleShowConfirmDeleteSnackbar({
                                    handleDelete: handleDirectDeleteVariable,
                                    argument: {
                                      variableProps: {
                                        mappingId: mappingId,
                                        linkId: linkId,
                                        intermediateId: intermediateId,
                                      },
                                    }
                                  })
                                }
                              />
                            </React.Fragment> :
                            ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>

                {/* Display the instance Info */}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideInstanceInfo !== undefined ?
                        compactViewMode.hideInstanceInfo :
                        false
                      ) :
                      false
                    ) :
                    true
                  }
                >
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'table-row'}}
                         hidden={
                           intermediate.targetEntity.instanceInfo === undefined ?
                           true :
                           (
                             intermediate.targetEntity.instanceInfo.description === '' && intermediate.targetEntity.instanceInfo.language === '' ?
                             true :
                             false
                           )
                         }>
                      <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'middle'}}>
                        <Tooltip title="Instance Info"
                                 placement="left"
                                 enterDelay={500}
                                 leaveDelay={200}>
                          <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                            <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                          </div>
                        </Tooltip>
                      </div>

                      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                        <div>
                          {
                            intermediate.targetEntity.instanceInfo !== undefined ?
                            <React.Fragment>
                              <Chip
                                classes={{
                                  root: classes.generatorDeclarationValidChip,
                                  deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                                  sizeSmall:  classes[instanceInfoStyleBasedOnLinesRequired(intermediate.targetEntity.instanceInfo)],
                                }}
                                size="small"
                                variant="outlined"
                                label={
                                  <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                    {
                                      intermediate.targetEntity.instanceInfo.constant !== undefined &&
                                      intermediate.targetEntity.instanceInfo.constant !== ''?
                                      <div
                                        style={{
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          maxWidth: 148,
                                        }}
                                      >
                                        {
                                          intermediate.targetEntity.instanceInfo !== undefined ?
                                          'Constant: ' + intermediate.targetEntity.instanceInfo.constant :
                                          ''
                                        }
                                      </div> :
                                      ''
                                    }
                                    {
                                      intermediate.targetEntity.instanceInfo.description !== undefined &&
                                      intermediate.targetEntity.instanceInfo.description !== '' ?
                                      <div
                                        style={{
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          maxWidth: 148,
                                        }}
                                      >
                                        {
                                          intermediate.targetEntity.instanceInfo !== undefined ?
                                          'Description: ' + intermediate.targetEntity.instanceInfo.description :
                                          ''
                                        }
                                      </div> :
                                      ''
                                    }
                                    {
                                      intermediate.targetEntity.instanceInfo.language !== undefined ?
                                      (
                                        intermediate.targetEntity.instanceInfo.language.value !== '' ?
                                        <div>
                                          {
                                            intermediate.targetEntity.instanceInfo !== undefined ?
                                            (
                                              intermediate.targetEntity.instanceInfo.language !== undefined ?
                                              'Language: ' +
                                              (
                                                intermediate.targetEntity.instanceInfo.language.label !== intermediate.targetEntity.instanceInfo.language.value ?
                                                intermediate.targetEntity.instanceInfo.language.label + ' (' + intermediate.targetEntity.instanceInfo.language.value + ')' :
                                                intermediate.targetEntity.instanceInfo.language.value
                                              ) :
                                              ''
                                            )
                                             :
                                            ''
                                          }
                                        </div> :
                                        ''
                                      ) :
                                      ''
                                    }
                                  </div>
                                }
                                onClick={
                                  handleUseInstanceInfo({
                                    mappingId: mappingId,
                                    linkId: linkId,
                                    intermediateId: intermediateId,
                                  })
                                }
                                color="primary"
                                onDelete={
                                  this.props.handleShowConfirmDeleteSnackbar({
                                    handleDelete: handleDirectDeleteInstanceInfo,
                                    argument: {
                                      instanceInfoProps: {
                                        mappingId: mappingId,
                                        linkId: linkId,
                                        intermediateId: intermediateId,
                                      },
                                    }
                                  })
                                }
                              />
                            </React.Fragment> :
                            ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>

                {/*Display Generator Declaration Set*/}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideInstanceGenerator !== undefined ?
                        compactViewMode.hideInstanceGenerator :
                        true
                      ) :
                      true
                    ) :
                    true
                  }
                >
                  <div className={instanceGeneratorDeclarationErrorStyle}>
                    <div style={{display: 'flex', width: 'fit-content'}}>
                      <div hidden={intermediate.targetEntity.instanceGeneratorDeclaration === undefined}
                           className={classes.generatorDeclarationIconWrapperStyle}>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <GeneratorInfo
                                title="Instance Generator"
                                patternTypeColors={patternTypeColors}
                                />
                            </React.Fragment>
                          }
                          placement="left"
                          enterDelay={500}
                          leaveDelay={200}
                        >
                         <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                        </Tooltip>
                      </div>
                      <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                        <div>
                          {
                            intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
                            <React.Fragment>
                              <Chip
                                classes={{
                                  root: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                        classes.generatorDeclarationErrorneousChip :
                                        classes.generatorDeclarationValidChip,
                                  deleteIconSmall: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                        classes.generatorDeclarationErrorneousDeleteIcon :
                                        classes.generatorDeclarationValidDeleteIcon,
                                }}
                                size="small"
                                variant="outlined"
                                label={intermediate.targetEntity.instanceGeneratorDeclaration.name}
                                onClick={handleGoToGeneratorDeclarationForm({
                                  generatorType: 'instance',
                                  type: 'intermediate',
                                  generatorProps: {mappingId: mappingId, linkId: linkId, intermediateId: intermediateId}
                                })}
                                color="primary"
                                onDelete={
                                  this.props.handleShowConfirmDeleteSnackbar({
                                    handleDelete: handleDirectDeleteInstanceGeneratorDeclaration,
                                    argument: {
                                      generatorType: 'instance',
                                      type: 'intermediate',
                                      generatorProps: {mappingId: mappingId, linkId: linkId, intermediateId: intermediateId},
                                    }
                                  })
                                }
                              />
                            </React.Fragment>
                            :
                            ''
                          }
                        </div>
                        <div className={classes.patternWithValuesAndColorsWrapper}>
                          {
                          intermediate.targetEntity.instanceGeneratorDeclaration !== undefined ?
                          <PatternWithValuesAndColors declaration={intermediate.targetEntity.instanceGeneratorDeclaration}
                                                      patternTypeColors={ patternTypeColors}/> :
                          ''
                          }
                        </div>
                      </div>
                      <GeneratorDeclarationError
                        generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                        errorMessage={
                          instanceGeneratorDeclarationErrorMsg !== '' ?
                           instanceGeneratorDeclarationErrorMsg :
                           (
                             instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                             instanceGeneratorDeclarationArgsErrorMsg :
                             ''
                           )
                         }
                      />
                    </div>
                  </div>
                </Collapse>

                {/* Display label Generator Declarations */}
                <Collapse
                  in={
                    compactViewEnabled ?
                    !(
                      compactViewMode !== undefined ?
                      (
                        compactViewMode.hideLabelGenerator !== undefined ?
                        compactViewMode.hideLabelGenerator :
                        true
                      ) :
                      true
                    ) :
                    true
                  }
                >
                  <div hidden={intermediate.targetEntity.labelGeneratorDeclarations === undefined ? true :
                         (intermediate.targetEntity.labelGeneratorDeclarations.length === 0 ? true : false)}>
                    <div style={{display: 'table-row'}}>
                      {
                        intermediate.targetEntity.labelGeneratorDeclarations !== undefined ?
                        intermediate.targetEntity.labelGeneratorDeclarations.map((labelDeclaration) => {
                          const labelGeneratorDeclarationErrorMsg =
                            labelDeclaration.definitionId !== undefined ?
                            (
                              labelDeclaration.definitionId === null ?
                              'Missing Generator Definition' :
                              ''
                            ) :
                            '';
                          const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                            obj => (
                              obj.name.toLowerCase() !== 'language' ?
                              obj.error_type_text !== '' :
                              false
                            )
                          );
                          const labelGeneratorDeclarationArgsErrorMsg =
                            lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                            labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                            labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                            '';

                          const labelGeneratorDeclarationErrorStyle =
                            labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                            classes.generatorDeclarationError :
                            '';
                          return (
                            <div
                              key={labelDeclaration.id}
                              className={labelGeneratorDeclarationErrorStyle}
                              style={{display: 'flex', width: 'fit-content'}}
                            >
                              <div className={classes.generatorDeclarationIconWrapperStyle}>
                                <Tooltip
                                  title={
                                    <React.Fragment>
                                      <GeneratorInfo
                                        title="Label Generator"
                                        patternTypeColors={patternTypeColors}
                                        />
                                    </React.Fragment>
                                  }
                                  placement="left"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                                </Tooltip>
                              </div>
                              <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                                <div>
                                  <React.Fragment>
                                    <Chip
                                      classes={{
                                        root: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                              classes.generatorDeclarationErrorneousChip :
                                              classes.generatorDeclarationValidChip,
                                        deleteIconSmall: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                              classes.generatorDeclarationErrorneousDeleteIcon :
                                              classes.generatorDeclarationValidDeleteIcon,
                                      }}
                                      size="small"
                                      variant="outlined"
                                      label={labelDeclaration.name}
                                      onClick={handleGoToGeneratorDeclarationForm({
                                        declaration: labelDeclaration,
                                        generatorType: 'label',
                                        type: 'intermediate',
                                        generatorProps: {mappingId: mappingId, linkId: linkId, intermediateId: intermediateId}
                                      })}
                                      color="primary"
                                      onDelete={
                                        this.props.handleShowConfirmDeleteSnackbar({
                                          handleDelete: handleDirectDeleteLabelGeneratorDeclarations,
                                          argument: {
                                            labelGeneratorDeclarationsToBeDeleted: [labelDeclaration],
                                            generatorProps: {mappingId: mappingId, linkId: linkId, intermediateId: intermediateId}
                                          }
                                        })
                                      }
                                    />
                                  </React.Fragment>
                                </div>
                                <div className={classes.patternWithValuesAndColorsWrapper}>
                                  {
                                  <PatternWithValuesAndColors declaration={labelDeclaration}
                                                              patternTypeColors={ patternTypeColors}/>
                                  }
                                </div>
                              </div>
                              <GeneratorDeclarationError
                                generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                                errorMessage={
                                  labelGeneratorDeclarationErrorMsg !== '' ?
                                   labelGeneratorDeclarationErrorMsg :
                                   (
                                     labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                     labelGeneratorDeclarationArgsErrorMsg :
                                     ''
                                   )
                                 }
                              />
                            </div>
                          )
                        }) :
                        ''
                      }
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
            <div className={classes.padding5TopBottom}>
              <div style={{width: '100%'}}>
                <Button color="primary"
                        className={classes.button}
                        onClick={handleAddConstExpr({mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}>
                  Additional
                </Button>
                <Button color="primary"
                        className={classes.button}
                        onClick={handleUseGenerator({mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}>
                  Generator
                </Button>
                <Button color="primary"
                        className={classes.button}
                        onClick={handleUseVariable({mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}>
                  Variable
                </Button>
                <Button color="primary"
                        className={classes.button}
                        onClick={handleUseInstanceInfo({mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}>
                  Instance Info
                </Button>
              </div>
            </div>
          </div>
          <div style={{marginTop: 20}}>
            <div className={classes.constExprNodesWrapper}
                 style={{transition: 'all 300ms ease-in-out'}}>
                 {/*style={{transition: 'all 300ms ease-in-out', height: intermediateEditConstExprList.length*212}}*/}
              <TransitionGroup>
                {intermediateEditConstExprList}
              </TransitionGroup>
            </div>
          </div>

        </div>
        <div className={classes.padding5TopBottom}>
          <div className={classes.divIconStyleWrapper}>
            <ArrowDownwardIcon/>
          </div>
          <FormControl className={classes.autocompleteFormControl}
                       error={intermediate.targetRelation.error_text !== undefined}>
            <Autocomplete
              options={[].concat.apply([], targetRelationOptions.map(item => item.options.map(optItem => ({ ...optItem, group: item.label }))))}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
              renderOption={(option) => (
                <React.Fragment>
                  <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                </React.Fragment>
              )}
              getOptionSelected={
                (option, value) => (value !== '' || value !== undefined) ? option.value === value.value : false
              }
              onChange={handleInputChange({name: 'targetRelation', type: 'targetIntermediate', mappingId: mappingId, linkId: linkId, intermediateId: intermediateId})}
              value={intermediate.targetRelation.item !== undefined && intermediate.targetRelation.item !== '' ? intermediate.targetRelation.item : null}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              filterSelectedOptions={false /* if true it will remove selected from the option list */}
              loading={targetRelationIsLoading}
              onOpen={this.handleRetrieveOptions({name: 'targetRelation'})}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Target Relation"
                  placeholder="Please select target relation"
                  FormHelperTextProps={{component: 'div'}}
                  helperText={
                    <div style={{color: 'red'}}>
                      <span style={{paddingRight: 10}}>
                        {intermediate.targetRelation.error_text !== undefined ? intermediate.targetRelation.error_text : ''}
                      </span>
                      {
                        intermediate.targetRelation.item !== '' ?
                        <Button
                          color="secondary"
                          hidden={intermediate.targetRelation.error_text === undefined}
                          size="small"
                          className={classes.errorLabelButton}
                          onClick={
                            handleInputChangeButton({
                              name: 'targetRelation',
                              type: 'targetIntermediate',
                              mappingId: mappingId,
                              linkId: linkId,
                              intermediateId: intermediateId,
                              item: intermediate.targetRelation.item,
                            })
                          }
                        >
                          Make Custom
                        </Button> :
                        ''
                      }
                    </div>
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {targetRelationIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </div>

      </div>
    );
  }
}

TargetIntermediateComponentEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TargetIntermediateComponentEdit);
