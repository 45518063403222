import React from 'react';
// import ReactDOM from 'react-dom';
//import Lodash from "lodash";
import clsx from 'clsx';
import arrayMove from "array-move";
import update from 'immutability-helper';
import compose from 'recompose/compose';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SettingsIcon from '@material-ui/icons/Settings';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MessageIcon from '@material-ui/icons/Message';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MappingComponent from './mappingTableViewComponents/MappingComponent';
import ProjectFileMetadataManagement from './mappingTableViewComponents/ProjectFileMetadataManagement';
import { withSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomSnackMessage from './snackbar/CustomSnackMessage';
import CodemirrorDialog from './codemirror/CodemirrorDialog';
import GeneratorDialog from './mappingTableViewComponents/GeneratorDialog';
import NativeConstExprDialog from './mappingTableViewComponents/NativeConstExprDialog';
import VariableDialog from './mappingTableViewComponents/VariableDialog';
import InstanceInfoDialog from './mappingTableViewComponents/InstanceInfoDialog';
import NamedgraphDialog from './mappingTableViewComponents/NamedgraphDialog';
import ConditionDialog from './mappingTableViewComponents/ConditionDialog';
import CommentDialog from './mappingTableViewComponents/CommentDialog';
import GeneratorDefinitionsManagerDialog from './mappingTableViewComponents/GeneratorDefinitionsManagerDialog';
import TransformationErrorDialog from './mappingTableViewComponents/TransformationErrorDialog';
import ParticipatingUsersDialog from './mappingTableViewComponents/ParticipatingUsersDialog';
import RDFVisualizerInputDialog from './mappingTableViewComponents/RDFVisualizerInputDialog';
import SourceInputCoverageDialog from './mappingTableViewComponents/SourceInputCoverageDialog';
import WarningIcon from '@material-ui/icons/Warning';
import Snackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConfirmSnackbar from './snackbar/ConfirmationSnackbar';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import ConfirmSnackMessage from './snackbar/ConfirmSnackMessage';
import { Scrollbars } from 'react-custom-scrollbars';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { LeftArrow, RightArrow } from './horizontalScrollingMenuArrows/arrows';
import { ItemCard } from "./horizontalScrollingMenuArrows/itemCard";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import "./horizontalScrollingMenuArrows/globalStyles.css";
import "./horizontalScrollingMenuArrows/hideScrollbar.css";


import DonutChartProgress from './mappingTableViewComponents/DonutChartProgress';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Services
import { MainService } from './services/MainService';
import { ReasonerService } from './services/ReasonerService';
import { XpathService } from './services/XpathService';
import { MappingTableViewService } from './services/MappingTableViewService';
import { FileUploadService } from './services/FileUploadService';
import { SecurityService } from './services/SecurityService';
import { GeneratorService } from './services/GeneratorService';
import { UserProfileService } from './services/UserProfileService';
import { OnlineUserPresenceService } from './services/OnlineUserPresenceService';

import organisationLogo from '../images/Logos_ICS_ISL_CCi_White_TransparentBack.png';

import configuration from './configuration.json';

import 'filepond/dist/filepond.min.css';
import './css/custom.css';

// const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

// Register the plugin
registerPlugin(FilePondPluginFileValidateType);

const rootContextPath = configuration.rootContextPath;
const dirRootContextPath = configuration.dirRootContextPath; // This is different than the regular rootContextPath (/3m) and avatarRootContextPath (3m/)

const produceOutputButtonGroupOptions = [
  {label: 'RDF', value: 'rdf', codeMirrorMode: 'application/xml'},
  {label: 'Turtle', value: 'ttl', codeMirrorMode: 'text/turtle'},
  {label: 'N-Triples', value: 'nq', codeMirrorMode: 'application/n-triples'},
  {label: 'TriG', value: 'trig', codeMirrorMode: 'text/turtle'},
];

const leftSidePanelWidth = 300;//240;

const styles = theme => ({
  root: {
    width: '100%',
  },
  mappingPanelRoot: {
    backgroundColor: 'rgba(199, 203, 208, 0.2)',
  },
  linkPanelRoot: {
    marginLeft: 10,
    marginRight: 10,
  },
  panelWhenExpanded: {
    marginTop: '16px !important',
    marginBottom: '16px !important',
  },
  headerCellLeft: {
    paddingRight: 8,
  },
  headerCellRight: {
    //borderLeft: '1px dashed  black',
    paddingLeft: 8,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color:'#2096f3',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(14),
    right: theme.spacing(6),
  },
  onTop: {
    zIndex: 1,
  },
  projectTitle: {
    flex: '0 0 auto',
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  expansionPanelSummary: {
    padding: 'unset',
  },
  expandIcon: {
    top: 15,
    left: 'calc(50% - 24px)',
    width: 48,
  },
  expPanelSumRootDir: {
    display:'flex',
    flexDirection: 'column',
    width:'100%'
  },
  inverseAlignActions: {
    alignItems: 'start',
    justifyContent: 'flex-start',
  },
  padding5TopBottom: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
  },
  intermediateNodesWrapper: {
    borderLeft:'solid rgba(0, 0, 0, 0.54)',
    marginLeft: 10,
    marginTop:5,
    width: '100%',
  },
  intermediateEdit: {
    borderStyle: 'dashed',
    borderColor: 'rgb(32, 150, 243)',
    marginTop: 5,
    marginBottom: 5,
    marginLeft:20,
    padding:5,
    borderLeft:'solid'
  },
  autocompleteFormControl: {
    width: '100%',
    display: 'flex'
  },
  divIconStyleWrapper: {
    padding:5,
    paddingRight:15,
    display:'flex',
    flexDirection:'column',
    justifyContent: 'center',
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  divOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    background: 'repeating-linear-gradient( -45deg, rgb(117, 117, 117), rgb(117, 117, 117) 2px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0) 11px)',
    /*
    background: 'repeating-linear-gradient( -45deg, rgb(117, 117, 117), rgb(117, 117, 117) 2px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0) 6px)',
    */
  },
  divFilledIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  intermediateToDeleteHighlighted: {
    borderColor: '#f50057',
  },
  titleTextFieldInput: {
    fontSize: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    letterSpacing: '0.0075em'
  },
  formRoot: {
    height: 'unset',
    padding: 'unset',
    background: 'unset'
  },
  dialogCustomPaper: {
    overflow: 'hidden',
  },
  snackbarSuccess: {
    backgroundColor: green[600],
  },
  /*snackbarWarning: {
    backgroundColor: amber[700],
    width: 460,
  },*/
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 40,
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarCloseIconButton: {
    padding: theme.spacing(0.5),
  },
  snackbarErrorTextPrimary: {
    color: '#f50057',
  },
  titleToolBarAwesomeIcon: {
    margin: 4,
  },
  x3mlImportFilePondRoot: {
    marginBottom: 'unset',
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  participatingUsersIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 32,
  },
  secondaryCode: {
    color: '#c7254e',
    backgroundColor: '#f9f2f4',
    borderRadius: 4,
    padding: '2px 4px',
    fontSize: '90%',
  },
  niceBadgeStyle: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
  accordionContentNotOverflow: {
    overflow: 'hidden',
    // minHeight: 40,
    minHeight: 30,
  },
  typographyLikeInput: {
    lineHeight: 1.5,
    textAlign: 'justify',
    paddingRight: 10,
  },
  typographyLikeInputReadOnly: {
    lineHeight: 1.5,
    textAlign: 'justify',
    paddingRight: 10,
    color: 'unset',
  },
  textAreaReadOnlyInputDisabled: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  statusStyle: {
    marginTop: -16,
    marginLeft: 10,
    fontWeight: 'bold',
    fontStyle:'italic',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  footerLogoStyle: {
    backgroundColor: theme.palette.primary.main,
    textAlign: 'end',
    width: 340,
    padding: 14,
    borderTopLeftRadius: '30px 50%',
    borderBottomLeftRadius: '30px 50%',
  },
  fullSourceInputCoverage: {
    fontSize: '0.56rem',
    fontWeight: 'bold',
  },
  circularUnderStyle: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  circularOnTopStyle: {
    color: theme.palette.primary.main,
    //animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  internalCircle: {
    backgroundColor: '#f6a2c0',
    borderRadius: 30,
    margin: 3,
  },
  circularWrapper: {
    position: 'relative',
    zIndex: 1,
    width: 32,
    height: 32
  },
  
  // Left side panel and main workbench

  // Style of left side panel always
  leftSidePanelStyle: {
    position: 'fixed', // Set position to absolute
    top: 197, // Position it at the top of the parent container
    left: 7,
    width: leftSidePanelWidth,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // Style of left side panel when closed
  leftSidePanelStyleClosed: {
    width: 0, // Slide out the panel when closed
  },
  leftSidePanelHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  leftSidePanelContent: {
    height: 'calc(100vh - 294px)',
    marginLeft: 5,
    marginRight: 5,
  },
  // Style on main workbench always
  mainWorkBenchContent: {
    width: '100%',
    marginLeft: 5,
    marginRight: 5,
    flexGrow: 1, // Expand to fill the remaining width
  },
  // Style on main workbench when side panel is open
  mainWorkBenchContentShiftedRight: {
    marginLeft: leftSidePanelWidth + 5,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // Style on main workbench when side panel is closed
  mainWorkBenchContentNotShiftedRight: {
    marginLeft: 0,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  // Style on description panel when expanded and the side panel is open
  descriptionPanelShiftedRight: {
    marginLeft: leftSidePanelWidth - 6,
    width: `calc(100vw - ${leftSidePanelWidth + 25}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // Style on main workbench when not expanded and side panel is closed
  descriptionPanelNotShiftedRight: {
    marginLeft: 0,
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hideShowButtonStyle : {
    marginTop: 'auto', 
    marginBottom: 'auto',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hideShowButtonStyleHidden: {
    width: 0, // Slide out the panel when closed
  },

  treeItemLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  treeItemMappingSourceLabel: {
    width: leftSidePanelWidth-43,
    paddingLeft: 8,
  },
  treeItemMappingTargetLabel: {
    width: leftSidePanelWidth-75,
  },
  treeItemLinkSourceLabel: {
    width: leftSidePanelWidth-60,
    paddingLeft: 8,
  },
  treeItemLinkTargetLabel: {
    width: leftSidePanelWidth-100,
  },
  // Complex Icons style
  letter: {
    position: 'absolute',
    top: '64.5%', // Position vertically
    left: '54%', // Position horizontally
    transform: 'translate(-50%, -50%)', // Center the letter
    fontSize: '0.53rem', // Adjust the font size of the letter "L"
    color: 'white', // Set the color of the letter "L" to white
  },
  svgIcon: {
    fontSize: 18, // Adjust the font size of the SVG icon
  },
});

const mainService = new MainService();
const reasonerService = new ReasonerService();
const xpathService = new XpathService();
const mappingTableViewService = new MappingTableViewService();
const fileUploadService = new FileUploadService();
const securityService = new SecurityService();
const generatorService = new GeneratorService();
const userProfileService = new UserProfileService();
const onlineUserPresenceService = new OnlineUserPresenceService();

const templateMappingId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);

const MappingCopyPaste = ({ classes }) => {
  return (
    <div >
      <FileCopyIcon style={{ fontSize: 18 }} className={classes.svgIcon}/>
      <div className={classes.letter}>M</div>
    </div>
  );
};

const MappingCopyPasteComplexIcon = withStyles(styles)(MappingCopyPaste);

class MappingTableView extends React.Component {

  state = {
    organisationLogoLoaded: false,
    doRender: false,
    isRedndering: true,
    canEdit: false,
    expandedMappingIdList: [],
    expandedLinkId: null,
    loadingModalOpen: false,
    sourceLoading: false,
    targetLoading: false,
    configDialogOpen: false,
    x3mlImportDialogOpen: false,
    configDialogFullScreen: false,
    x3mlImportDialogFullScreen: false,
    fileMetadataObjectList: {},
    fileMetadataRefRecordMap: new Map(), // Array holding the refs of the fileMetadataUplader forms
    fileMetadataFormHasErrors: false,
    filesManagementChanged: false, // Denoting whether there was change in the files' metadata to consider when saving the dialog
    mappingIds: [templateMappingId],
    mappings: {
      [templateMappingId]: {
        id: templateMappingId,
        panelExpanded: true,
        domainSourceNode: {
          item: ''
        },
        domainTargetEntity: {
          item: ''
        },
        domainPanelExpanded: false,
        label: 'mapping 1',
        linkIds: [],
        links: {},
        constExprIds: [],
        constExprs: {}
      }
    },
    deletingLinkId: '',
    allXpaths: [],
    allClasses: [],
    targetEntityOptions: [],
    targetRelationOptions:[],
    //sourceSpeedDialOpen: false,
    //targetSpeedDialOpen: false,
    // Predefined FileMetadata Lists
    sourcePredefinedFileMetadataList: [],
    targetPredefinedFileMetadataList: [],
    generatorPolicyPredefinedFileMetadataList: [],
    titleIsEditing: false,
    // RDF/x3ML Dialog
    xmlDialogOpen: false,
    xmlCodeMirrorTitle: '',
    isCodeMirrorEditable: false,
    codeMirorInputState: undefined,
    xmlDialogFullScreen: false,
    confirmImportWholeX3MLCodeSnackbarShown: false,
    confirmCloseWholeX3MLCodeSnackbarShown: false,
    wholeX3MLCodeChanged: false,

    // RDF Vizualiser Input
    rdfVisualizerInputDialogOpen: false,
    rdfVisualizerInputFullScreen: false,
    instancesOfSelectedOutputClass: [],

    // Compression - zip
    confirmExportWithoutX3MLSnackbarShown: false,

    // Generator Dialog
    instanceGeneratorDefinitions: [],
    labelGeneratorDefinitions: [],
    generatorDialogOpen: false,
    generatorDialogFullScreen: false,
    currGeneratorProps: '',
    currInstanceGeneratorDefinitionSelected: '',
    currLabelGeneratorDefinitionSelected: '',
    generatorDeclarationChanged: false,
    generatorDefinitionChanged: false,
    currInstanceGeneratorDeclaration: '',
    currLabelGeneratorDeclaration: '',
    currLabelGeneratorDeclarations: [],
    generatorTabValue: 0,
    generatorTabIndex: 0,
    areGeneratorTabsDisabled: false,
    //successWithErrorsSnackbarShown: false,
    transformationErrorMessage: '',
    transformationErrors: [],
    confirmCloseGeneratorSnackbarShown: false,
    confirmGoBackGeneratorSnackbarShown: false,
    confirmChangeTabGeneratorSnackbarShown :false,
    tmpGeneratorTabValue: -1,
    showFixMissingDefinitionErrorView: false,
    theSystemIsUpdatingGeneratorDeclarations: false, // Used for updateAllProjectDeclarationsCallBack
    generatorDialogXpaths: [],

    // Native ConstantExpression Dialog
    nativeConstExprDialogOpen: false,
    nativeConstExprDialogFullScreen: false,
    nativeConstExprProps: {level: 0},
    currNativeConstExpr: '',
    nativeConstExprChanged: false,
    confirmCloseNativeConstExprSnackbarShown: false,

    // Variable Dialog
    variableDialogOpen: false,
    variableDialogFullScreen: false,
    currVariableProps: '',
    currVariable: '',
    variableChanged: false,
    confirmCloseVariableSnackbarShown: false,
    variableHistory: [],

    // Instance Info Dialog
    instanceInfoDialogOpen: false,
    instanceInfoDialogFullScreen: false,
    currInstanceInfoProps: '',
    currInstanceInfo: '',
    currInstanceInfoChanged: false,
    confirmCloseInstanceInfoSnackbarShown: false,

    // Namedgraph Dialog
    namedgraphDialogOpen: false,
    namedgraphDialogFullScreen: false,
    currNamedgraphProps: '',
    currNamedgraph: '',
    namedgraphChanged: false,
    confirmCloseNamedgraphSnackbarShown: false,
    namedgraphHistory: [],

    // Condition Dialog
    conditionDialogOpen: false,
    conditionDialogFullScreen: false,
    currConditionProps: '',
    currCondition: {
      type: '',
      value: '',
      xpath: '',
      negation: false,
      error_type_text: '',
      error_value_text: '',
      error_xpath_text: '',
    },
    currConditions: [],
    conditionTabIndex: 0,
    currConditionListUseOr: false,
    conditionListLogicalOperator: 'or',
    confirmCloseConditionSnackbarShown: false,
    confirmGoBackConditionSnackbarShown: false,
    conditionChanged: false,

    // Comment Dialog
    commentDialogOpen: false,
    commentDialogFullScreen: false,
    currCommentProps: '',
    currComment: {
      type: '',
      rationale: '',
      alternatives: '',
      typicalMistakes: '',
      localHabits: '',
      linkToCookBook: '',
      exampleSource: '',
      exampleTarget: '',
      lastUpdateDate: '',
      lastUpdatePerson: '',
    },
    currComments: [],
    commentTabIndex: 0,
    confirmCloseCommentSnackbarShown: false,
    confirmGoBackCommentSnackbarShown: false,
    commentChanged: false,

    // Generator Definitions Manager Dialog
    generatorDefinitionsManagerDialogOpen: false,
    generatorDefinitionsManagerDialogFullScreen: false,
    generatorDefinitionsManagerChanged: false,
    confirmCloseGeneratorDefinitionsManagerSnackbarShown: false,
    confirmGoToMainGeneratorDefinitionsManagerSnackbarShown: false,
    confirmGoToAddNewGeneratorDefinitionsManagerSnackbarShown: false,
    confirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbarShown: false,
    confirmBeforeMakingGeneratorDefinitionPreloadedSnackbarShown: false,
    confirmUpdateGeneratorDefinitionManagerSnackbarShown: false,
    confirmDeleteGeneratorDefinitionManagerSnackbarShown: false,
    tmp_numOfDeclarationsAssociatedToDefinition: 0,
    currManagerGeneratorDefinition: '',
    fileMetadataInputChangeMap: new Map(), // Used to temporarely tracking changes applied for the namespace filed through the FileMetadata Dialog before saving
    selectedManagerGeneratorDefinitionIndex: undefined,
    preloadedGeneratorDefinitions: [],
    selectedDefinitionListForEditMode: [],
    selectedPreloadedDefinitionListForEditMode:[],
    definitionListEditModeEnabled: false,
    generatorPolicyFileImportShown: false,
    toBeImportedGeneratorDfinitions: [],

    // Transformation Error Dialog
    transformationErrorDialogOpen: false,
    transformationErrorDialogFullScreen: false,

    // Participating Users Dialog
    participatingUsersDialogOpen: false,
    participatingUsersDialogFullScreen: false,
    allUsers: [],
    // Automated Actions
    automatedActionsDialogOpen: false,
    automatedActionsDialogFullScreen: false,

    // Source Input CoverageOpen Dialog
    sourceInputCoverageOpen: false,
    sourceInputCoverageDialogFullScreen: false,
    currentlyUsedXpaths: new Set(),

    // Validate Selections
    validateSelections: true, // Flag denoting that selections are validated
    compactViewEnabled: false,
    // Description
    mappingProjectDescriptionExpanded: false,
    showEditMappingProjectDescription: false,
    // Online participants
    onlineParticipantingUsers: [],
    // Focused Object
    focusedObjectId: '',
    focusedObjectIdUserMap: new Map(), // Array holding the refs of the fileMetadataUplader forms
    //
    prefixNamespaceMap: new Map(),
    validatingSelectedValuesNow: false,
    linkIsInClipboard: false,
    mappingIsInClipboard: false,
    showFileMetadataDialogBackDrop: false,

    // produce Output Button Group
    produceOutputButtonGroupSelectedIndex: 0,
    produceOutputButtonGroupAnchorRef: null,

    linkRefRecordMap: new Map(), // Array holding the refs of the links
    mappingRefRecordMap: new Map(), // Array holding the refs of the mapping (domain)

    erroneousId: -1, // Error for empty mapping or link related field

    leftSidePanelIsOpen: false,
    basicExplorerNodesExpanded: [],
    selectedBasicExplorerNode: '',
    mappingOrLinkToSpotAndHighlight: '',
  }

  constructor(props) {
    super(props);
    this.mappingListRef = React.createRef();
    //this.handleMarkFileMetadataFieldAsInvalid = this.handleMarkFileMetadataFieldAsInvalid.bind(this);
    this.textInput = React.createRef();
    this.fileMetadataDialogRef = React.createRef();
    this.mappingProjectDescriptionTextInput = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.configurationSettings !== nextProps.configurationSettings) {
      return true;
    }
    if(this.props.rdfVisualizerUrl !== nextProps.rdfVisualizerUrl) {
      return true;
    }
    if(this.props.connected !== nextProps.connected) {
      return true;
    }
    if(this.props.jsonPackage !== nextProps.jsonPackage) {
      return true;
    }
    else {
      return false;
    }

  }

  async componentDidUpdate(prevProps, prevState) {
    if(this.props.history.location.state) {
      // Typical usage (don't forget to compare props):
      if(this.props.jsonPackage !== prevProps.jsonPackage) {
        this.syncSaveWithOthers(this.props.jsonPackage);
      }
      if(this.props.connected !== prevProps.connected) {
        if(this.props.connected) {
          await this.handleUserBecomesPresentInMappingProject();
          await this.cleanUpAndGetUsersInMappingProject();
          await this.retrieveFocusOfOthers();
        }
      }
    }
    // if(this.state.linkIsInClipboard !== prevState.linkIsInClipboard) {
    //   this.setState({
    //     linkIsInClipboard: localStorage.getItem('linkCopy3M') !== null,
    //   });
    // }
  }

  async componentDidMount() {

    // First check whether there is any selected ID or a URL path HRID
    const pathHridPathParam = 
      this.props.match ?
      this.props.match.params.mappingTableHrid :
      undefined;

    // Then check if there is any mapping or link ID passes through URL path
    const mappingOrLinkIdPathParam = 
      this.props.match ?
      this.props.match.params.mappingOrLinkId :
      undefined;

    // If there is some pathID (HRID), then use it to retrieve the actual mappingProject's ID
    let retrievedMappingTableId = undefined;
    if(pathHridPathParam)
      retrievedMappingTableId = await this.retrieveMappingProjectIdByHrid(pathHridPathParam); // Doesn't wait to get the ID

    const selectedId = 
    retrievedMappingTableId ?
    retrievedMappingTableId :
    this.props.history.location.state ?
    this.props.history.location.state.selectedId :
    undefined;

    // console.log('selectedId:');
    // console.log(selectedId);

    if(!selectedId) {
      this.handleMissingHistoryLocationStateCallBack();
    }
    else {
      loadCSS(
        'https://use.fontawesome.com/releases/v5.11.1/css/all.css',
        document.querySelector('#font-awesome-css'),
      );
      // Retrieve metadata of this "Mapping Project"
      const displayFileMetadataDialog = mappingOrLinkIdPathParam === undefined; // Determine whether to display the fileMetadata dilaog
      const succeed = await this.retrieveCurrentMappingProject(selectedId, displayFileMetadataDialog);
      if(succeed) {
        this.setState({
          doRender: true,
        });
        // Retrieve predefined FileMetadata - Target
        await this.retrievePredefinedFileMetadataListByFileScopeAndEnability({
          type: 'target',
          enabled: true
        });
        // Retrieve All Users
        await this.retrieveAllEnabledUsers();
        // Retrieve the languages used in the instance info
        await this.retrieveLanguages();
        // Adding current user in the list of participats to the workflow
        if(this.props.connected) {
          await this.handleUserBecomesPresentInMappingProject();
          await this.cleanUpAndGetUsersInMappingProject();
          await this.retrieveFocusOfOthers();
        }
        // If threre is any mapping or link ID passes through URL path, 
        // then close the File MEtadata dialog and scroll to it
        if(mappingOrLinkIdPathParam) {
          this.handleCloseFileMetadataDialog();
          this.scrollToLinkOrMappingByIdCallBack({id: mappingOrLinkIdPathParam});
        }
      }
      else {
        this.props.showErrorSnackBar({msg: `The Mapping with id "${selectedId}" cannot be retrieved`, iconSize: 'large'});
      }
    }
    if(localStorage.getItem('linkCopy3M') !== null) {
      this.setState({
        linkIsInClipboard: true,
      });
    }
    if(localStorage.getItem('mappingCopy3M') !== null) {
      this.setState({
        mappingIsInClipboard: true,
      });
    }
    // Loading history of namedgraphs used
    let tmpNamedgraphHistory = await this.loadingNamedgraphHistory();
    this.setState({
      namedgraphHistory: tmpNamedgraphHistory,    // History
    });
  }

  async componentWillUnmount() {
    // Removing user from the list of participants of this workflow
    this.handleUserBecomesAbsentFromMappingProject();
    this.accordionBlurOnUnmountCallBack({id: this.state.focusedObjectId});
  }

  handleMissingHistoryLocationStateCallBack = () => {
    this.props.history.push({
      pathname: rootContextPath + '/Projects',
    });
    this.props.showInfoSnackBar({
      msg: 'You should select some \"Mapping Project\" first before getting to work on it.',
      //hasHtmlContent: true,
    });
  }

  onOrganisationLogoLoad = () => {
    this.setState({
        organisationLogoLoaded: true
    })
  }

  retrieveMappingProjectIdByHrid = async (hrid) => {
    let retrievedId = undefined;
    try {
      let res = await mainService.retrieveMappingProjectIdByHrid({id: hrid});
      if(res.data !== undefined && res.data !== null) {
        retrievedId = res.data;
      }
    } catch (e) {
      console.error('Some error occured while retrieving the ID of the mapping project, based on the respective HRID.');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
    return retrievedId;
  }

  retrieveLanguages = async () => {
    try {
      let res = await mappingTableViewService.retrieveLanguages();
      if(res.data !== false) {
        this.setState({
            languages: res.data.languages,
        });
      }
    } catch (e) {
      console.error('Some error occured while retrieving the languages.');
      this.props.showErrorSnackBar({msg: 'Some error occured while retrieving the languages.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  retrieveAllEnabledUsers = async props => {
    let res = await userProfileService.retrieveAllEnabledUsers();
    this.setState({
      allUsers: res.data,
    });
  }

  retrieveCurrentMappingProject = async (selectedId, displayFileMetadataDialog) => {
    let isRetrieved = false;
    // Retrieve current MappingProject
    try {
      // console.log('this.props.history.location.state:');
      // console.log(this.props.history.location.state);
      if(selectedId) {
        let resMappingProject = await mainService.retrieveMappingProjectById({id: selectedId});
        if(resMappingProject.data !== undefined && resMappingProject.data !== null) {
          this.setState({
              currMappingProject: resMappingProject.data,
              loadingModalOpen: true, // Displaying loading modal
          }, async function() {

            // Check if there is any mapping tree associated and if no, then use the template (empty)
            // one and assign it to the project
            if(this.state.currMappingProject.mappingTreeModelId === null) {
              this.saveMappingTreeModel();
            }
            // Retrieve the treeModel and load it in the state
            else{
              let mappingTreeModelRes = await mappingTableViewService.retrieveMappingTreeModelById({id: this.state.currMappingProject.mappingTreeModelId})
              if(mappingTreeModelRes.data) {
                this.setState({
                  mappings: mappingTreeModelRes.data.treeModel.mappings,
                  mappingIds: mappingTreeModelRes.data.treeModel.mappingIds,
                });
              }
            }
            // Indicating that both source and target inputs are loading
            // (messages to be displayed on the loading modal)
            this.setState({
              sourceLoading: true,
              loadingTargetMessage: 'Resolving target model(s) to get the available classes.',
              targetLoading: true,
              loadingSourceMessage: 'Resolving source model(s) to get the available xPaths.',
              isRedndering: false,
            }, async function() {

              // Executing the reasoner for the target sources
              await this.executeReasoner();

              // Adding Async functions to array
              let promiseArray = [];
              // Determining whether the current user can edit or not
              promiseArray.push(this.determineWhetherCurrUserCanEdit());
              // Adding more Async functions to array
              if(this.state.currMappingProject.sourceInputs !== null) {
                this.state.currMappingProject.sourceInputs.forEach(sourceInput => {
                  // Execute Service to save namespaces for each XML based source fileMedatata
                  promiseArray.push(this.generateAndStoreNamespacesForAllSourceInputs({sourceInput: sourceInput, addNamespace2DB: true}));
                });
              }

              let inputParams = {
                id: this.state.currMappingProject.id,
                canEdit: this.state.canEdit ? displayFileMetadataDialog : false,
              }
              Promise.all(promiseArray).then(async () => {
                // When all promises have been completed, make one more ajax call
                await this.retrieveFileMetadataListByMappingProjectId(inputParams);
                // Executing the respective analyzer for the input sources
                await this.executeSimpleXpathResolver();
                // Give some millisecs (1000 ms = 1 sec) for the animations to complete
                setTimeout( () => {
                  this.setState({
                    loadingModalOpen: false,
                  }, function () {
                    this.setState({
                      sourceLoading: false,
                      targetLoading: false,
                    });
                  });
                }, 1000);
              });
            });
          });
          isRetrieved = true;
        }
      }
      
    } catch (e) {
      console.error('Some error occured while retrieving the mapping project!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
    return isRetrieved;
  }

  // NOT IN USE (Works)
  // props: {fileMetadataId: <Text>, listType: 'target | source'}
  updateFileMetadataInObjectList = async (props) => {
    // Retrieve current MappingProject
    try {
      const res = await fileUploadService.retrieveFileMetadataById({id: props.fileMetadataId});
      if(res.data !== null) {
        const fileMetadata = res.data;
        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
        console.log('fileMetadata:');
        console.log(fileMetadata);
        const fileMetadataIndex = this.state.fileMetadataObjectList[props.listType + 'Files'].findIndex(
          obj => obj.id === props.fileMetadataId
        );
        let array = update(this.state.fileMetadataObjectList[props.listType + 'Files'], {
          [fileMetadataIndex]: {
            $set: fileMetadata,
          },
        });
        this.setState({
          fileMetadataObjectList: {
            ...this.state.fileMetadataObjectList,
            [props.listType + 'Files']: array,
          },
        });
      }
      //return fileMetadata;
    } catch (e) {
      console.error('Some error occured while retrieving the metadata of the file');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
    console.log();
    //return fileMetadata;
  }

  // Callback update reasoner and files in list
  // (i.e. show the namespaces)
  // props: {fileMetadataId: <Text>, doRetrieve: <boolean>}
  executeReasonerCallBack = async (props) =>  {
    this.setState({
      targetLoading: true,
    }, async function() {
      // Executing the reasoner for the target sources
      await this.executeReasoner();
      let inputParams = {
        id: this.state.currMappingProject.id,
        canEdit: this.state.canEdit,
      }
      if(props.doRetrieve) {
        await this.updateFileMetadataInObjectList({fileMetadataId: props.fileMetadataId, listType: 'target'});
      }
      await this.checkForNamespaceDuplicatesInMetadataFiles();
      this.setState({
        targetLoading: false,
      });
    });
  }

  // Callback used to re-init the xml resolver when needed
  // props: {fileMetadataId: <Text>, doRetrieve: <boolean>, doSave: <boolean>}
  executeXpathResolverCallBack = async (props) => {
    const fileMetadataId = props.fileMetadataId;
    const doRetrieve = props.doRetrieve;
    const doSave = props.doSave;
    this.setState({
      sourceLoading: true,
    }, async function() {
      // Adding more Async functions to array
      for (const sourceInput of this.state.currMappingProject.sourceInputs) {
        await this.generateAndStoreNamespacesForAllSourceInputs({sourceInput: sourceInput, addNamespace2DB: true});
      }

      if(doRetrieve) {
        await this.updateFileMetadataInObjectList({fileMetadataId: fileMetadataId, listType: 'source'});
      }
      await this.checkForNamespaceDuplicatesInMetadataFiles();
      // Executing the respective analyzer for the input sources
      await this.executeSimpleXpathResolver();
      if(doSave != undefined ? doSave : false) {
        // Persist to the database
        await mainService.saveMappingProject(this.state.currMappingProject);
      }
      this.setState({
        sourceLoading: false,
      });
    });
  }

  // Executes reasoner and then saves all the classes into the state
  // (property 'allClasses' of the state)
  executeReasoner = async () => {
    // Loading target Schema on the reasoner
    let modelsLoaded = false;
    try {
      let inputData = {
        id: this.state.currMappingProject.id,
        type: 'target'
      }
      var initReasonerModelsRes = await reasonerService.initModels(inputData);
      //var initReasonerModelsRes = await mainService.retrieveOptionsTest();
      if(initReasonerModelsRes.data !== false) {
        console.log('init Reasoner Models Succeed');
        modelsLoaded = true;

        // Show errorMsges if there are any (a general error message for all files)
        if(initReasonerModelsRes.data.errorMsg !== undefined) {
          this.props.showErrorSnackBar({msg: initReasonerModelsRes.data.errorMsg, iconSize: 'large'});
        }

        // an object of errors where the attribute name correspond to the file name and the attribute value is the error message
        if(initReasonerModelsRes.data.errors !== undefined) {
          let errorHtmlMsg = 'The following errors were found per file:';
          errorHtmlMsg = errorHtmlMsg + '<ul>';
          for (const propertyName in initReasonerModelsRes.data.errors) { // Are performed in sequence
            errorHtmlMsg = errorHtmlMsg + '<li>';
            errorHtmlMsg = errorHtmlMsg + propertyName + ': ';
            errorHtmlMsg = errorHtmlMsg + initReasonerModelsRes.data.errors[propertyName];
            errorHtmlMsg = errorHtmlMsg + '</li>';
          }
          errorHtmlMsg = errorHtmlMsg + '</ul>';
          this.props.showErrorSnackBar({msg: errorHtmlMsg, iconSize: 'large', hasHtmlContent: true,});
        }
      }
      else {
        console.error('Some error occured while initializing the target models!');
        this.props.showErrorSnackBar({msg: 'Some error occured while initializing the target models!', iconSize: 'large'});
        modelsLoaded = false;
      }
    }
    catch (e) {
      console.error('Some error occured while initializing the target models!');
      this.props.showErrorSnackBar({msg: 'Some error occured while initializing the target models!', iconSize: 'large'});
      modelsLoaded = false;
    }
    // List all classes for the domain
    if(modelsLoaded) {
      // Gets classes
      await this.getClasses();
    }
    // this.setState({
    //   targetLoading: false,
    // });
  }

  getClasses = async () => {
    try {
      let inputData = {
        id: this.state.currMappingProject.id,
        type: 'target'
      }
      let res = await reasonerService.getClasses(inputData);
      if(res.data.succeed) {
        this.setState({
          allClasses: res.data.response
        });
      }
      else {
        console.error('Some error occured while retrieving the classes.');
        this.props.showErrorSnackBar({msg: 'Some error occured while retrieving the classes.', iconSize: 'large'});
      }
    } catch (e) {
      console.error('Some error occured while retrieving the classes.');
      this.props.showErrorSnackBar({msg: 'Some error occured while retrieving the classes.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  // Execute service to generate and store XML FileMetadata namespaces for each source file
  generateAndStoreNamespacesForAllSourceInputs = async (props) => {
    try {
      var sourceMedatataNamespacesRes = await mappingTableViewService.generateAndStoreXmlFileMetadataNamespaces(
        {id: props.sourceInput.value, addNamespace2DB: props.addNamespace2DB}
      );
      if(sourceMedatataNamespacesRes.data !== undefined) {
        console.log('Namespaces have been generated and stored for the ' + props.sourceInput.label + ' file!');
      }
      else {
        console.error('Some error occured while storing the namespaces for the ' + props.sourceInput.label + ' file!');
      }
    }

    catch (e) {
      console.error('Some error occured while storing the namespaces for the ' + props.sourceInput.label + ' file!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // Executes a simple xpath element analysis returning all the elements of
  // an XML (xpaths)
  executeSimpleXpathResolver = async () => {
    try {
      // Only execute it if there are sourceInput files to use
      let inputData = {
        id: this.state.currMappingProject.id,
        type: 'source'
      }
      let res = await xpathService.getXpaths(inputData);
      if(Object.entries(res.data).length !== 0) { // Not empty
        if(res.data.response[0].value !== 'An error occured: null') { // Not empty
          this.setState({
            allXpaths: res.data.response.map(xpath => ({
              value: xpath.label,//xpath.value,
              label: xpath.label,
            }))
          }, () => {
            // initialize toCoverXpaths Set
            this.setState({
              currMappingProject: {
                ...this.state.currMappingProject,
                toCoverXpaths: this.state.allXpaths.map(xpath =>
                  Array.isArray(this.state.currMappingProject.toCoverXpaths) &&       // Array exists AND
                  this.state.currMappingProject.toCoverXpaths.length !== 0 ?          // is not empty
                  (
                    // The toCoverXpaths includes the xpath either as selected or diselected
                    this.state.currMappingProject.toCoverXpaths.map(obj => obj.value).includes(xpath.value) ?
                    (
                      // The toCoverXpaths includes the xpath as selected
                      this.state.currMappingProject.toCoverXpaths.filter(obj => obj.selected).map(obj => obj.value).includes(xpath.value) ?
                      ({value: xpath.value, selected: true}) :
                      ({value: xpath.value, selected: false})
                    ) :
                    // The toCoverXpaths includes the xpath as deselected
                    ({value: xpath.value, selected: true})
                  ) :
                  ({value: xpath.value, selected: true})
                ),
              }
            }, () => {
              this.retrieveUsedXpathsCallBack();
            });
          });
        }
      }
      else {
        this.setState({
          allXpaths: [],
          currMappingProject: {
            ...this.state.currMappingProject,
            toCoverXpaths: [],
          }
        }, () => {
          this.retrieveUsedXpathsCallBack();
        });
      }
    }
    catch (e) {
      console.error('Some error occured while resolving the xPaths of the source inputs!');
        this.props.showErrorSnackBar({msg: 'Some error occured while resolving the xPaths of the source inputs!', iconSize: 'large'});
    }
    // this.setState({
    //   sourceLoading: false,
    // });
  }

  // Execute service to retrieve the fileMetadata of the project
  retrieveFileMetadataListByMappingProjectId = async (props) => {
    try {
      var fileMetadataList = await mappingTableViewService.retrieveFileMetadataListByMappingProjectId({id: props.id});
      if(fileMetadataList.data !== undefined) {
        // Save the list of fileMetadata of this project in the state
        this.setState({
          fileMetadataObjectList: fileMetadataList.data,
          configDialogOpen: props.canEdit !== undefined ? props.canEdit : this.state.configDialogOpen,
          fileMetadataInputChangeMap: new Map(),
        }, () => {
          if(props.canEdit) {
            // Holding copy of the filemetadata list before appling any change on them
            let oldFileMetadataObjectList = {... this.state.fileMetadataObjectList}; // Copy
            this.setState({
              oldFileMetadataObjectList: oldFileMetadataObjectList,
            });
          }
        });
      }
      else {
        console.error('Some error occured while retrieving the fileMetadat of this project');
      }
    }

    catch (e) {
      console.error('Some error occured while retrieving the fileMetadat of this project');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // Check whether the current user is able to edit/save or not
  determineWhetherCurrUserCanEdit = () => {
    let canEdit = false;
    if(this.state.currMappingProject !== undefined) {
      let participantUserIndex = -1;
      if(this.state.currMappingProject.users !== undefined && this.state.currMappingProject.users !== null) {
        participantUserIndex = this.state.currMappingProject.users.findIndex(
          obj => obj.username === this.props.currUser.currUsername
        );
      }
      if(this.state.currMappingProject.author.username === this.props.currUser.currUsername) {
        canEdit = true;
      }
      else if(this.state.currMappingProject.status.toLowerCase() === 'In Progress'.toLowerCase() && participantUserIndex !== -1) {
        canEdit = true;
      }
    }
    this.setState({
      canEdit: canEdit
    });
  }

  handleChangeExpandAllMappings = event => {
    if (event.target.checked) {
      this.setState(state => ({
        expandedMappingIdList: state.mappingIds,
      }));
      return;
    }
    this.setState({
      expandedMappingIdList: []
    });
  };


  // props: {type: <mapping | domain | link>, id: <TEXT>, mappingId*: <Text>}
  // *: only in case of expanding / collapsing some link
  handleExpandChange = props => (event, expanded) => {
    // Case Mapping
    if(props.type === 'mapping') {
      let newExpandedMappingIdList = [...this.state.expandedMappingIdList];
      if(expanded) {
        if(!newExpandedMappingIdList.includes(props.id)) {
          newExpandedMappingIdList.push(props.id)
        }

      }
      else {
        if(newExpandedMappingIdList.includes(props.id)) {
          newExpandedMappingIdList = newExpandedMappingIdList.filter(item => item !== props.id);
        }
      }
      this.setState({
        expandedMappingIdList: newExpandedMappingIdList,
      }, () => {
        console.log('expandedMappingIdList');
        console.log(this.state.expandedMappingIdList);
      });
    }
    // Case Link (or Domain)
    else if(props.type === 'domain' || props.type === 'link') {
      this.setState({
        expandedLinkId: this.state.canEdit ? (expanded ? props.id : false) : false,
      }, () => {
        if(!expanded) {
          if(props.type === 'domain') {
            this.handleToggleShowDomainCodeCallBack({mappingId: props.id, showCode: false});
            // CANNOT USE confirmation here because it is defined inside the MappingComponent.js
            // const isCodeChanged = this.isFragmentCodeChanged({mappingId: props.id});
            // if(isCodeChanged) {
            //   this.setState({
            //     confirmCloseFragmentCodeSnackbarShown: true
            //   });
            // }
            // else {
            //   this.handleToggleShowDomainCodeCallBack({mappingId: props.id, showCode: false});
            // }

          }
          if(props.type === 'link') {
            this.handleToggleShowLinkCodeCallBack({mappingId: props.mappingId, linkId: props.id, showCode: false});
          }
        }
      });
    }
    // Basic Explorer left side panel related

    // Select the respective mapping or link
    this.setState({
      selectedBasicExplorerNode: props.id,
    });
    // Expand the respective mapping (case of opening link) if needed
    if(props.mappingId !== undefined) {
      // Determining if not already expanded
      const indexOfNode = this.state.basicExplorerNodesExpanded.findIndex(
        nodeId => nodeId === props.mappingId
      );
      // If collapsed, then  expand it (mapping at the side panel)
      if(indexOfNode === -1)
        this.handleToggleBasicExplorerNodeCallBack({nodeId: props.mappingId});
      
    }
  };

  // Performance: https://medium.freecodecamp.org/optimising-the-state-shape-of-your-react-app-with-redux-3a280e6ef436
  // setstate native: https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react?rq=1

  // Storing the MappingTreeModel

  saveMappingTreeModel = async () => {
    let model = { treeModel: {}, id: this.state.currMappingProject.mappingTreeModelId !== null ? this.state.currMappingProject.mappingTreeModelId : null };
    model.treeModel.mappings = Object.assign({}, this.state.mappings); // Copy of the object of objects
    model.treeModel.mappingIds = this.state.mappingIds.slice();   // Create a copy of the Array
    try {
      let response = await mappingTableViewService.saveMappingTreeModel(model);
      if(response.data.succeed) {
        // Check if this is an actual insertion to the database
        if(response.data.id !== undefined) {
          // Associate this treeModel with the project
          this.setState({
            currMappingProject: {
              ...this.state.currMappingProject,
              mappingTreeModelId: response.data.id,
            }
          }, async function() {
            // Update the currMappingProject to include the associated mappingTreeModelId in the database
            await mainService.saveMappingProject(this.state.currMappingProject);
          });
        }
        // Update modification Date
        if(response.data.modificationDate !== undefined) {
          this.setState({
            currMappingProject: {
              ...this.state.currMappingProject,
              modificationDate: response.data.modificationDate,
            }
          });
        }
        // WebSocket send
        //this.sendJsonObjectToSpecificUsers();
      }
      else {
        this.props.showErrorSnackBar({msg: response.data.msg});
      }
    } catch (e) {
      console.error('Some error occured while persisting the current mapping to the database!');
      if(e.response !== undefined) {
        console.error(e.response.status);
        this.props.showErrorSnackBar({msg: 'Some error occured while persisting the current mapping to the database!'});
        return e.response;
      }
    }
    //console.log('=====================> saveMappingTreeModel');
  }


  // ******************************************************************************
  // *********************************** Mapping **********************************
  // ******************************************************************************

  handleAddMapping = () => {
    const mappingId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    let newMapping = {
      id: mappingId,
      panelExpanded: true,
      domainSourceNode: {
        item: ''
      },
      domainTargetEntity: {
        item: ''
      },
      domainPanelExpanded: false,
      label: 'mapping ID - ' + mappingId,
      linkIds: [],
      links: {},
      constExprIds: [],
      constExprs: {},
      leaveEverywhereMoreSpace: false,
      conditionListUseOr: true,
      conditionListLogicalOperator: 'or',
      conditions: []
    };
    // Update the state
    this.setState({
      mappings: {
        ...this.state.mappings,
        [mappingId]: newMapping
      },
    }, function() {
      // Update the mappingIds Array
      let array = this.state.mappingIds.slice(); // Create a copy
      array.push(mappingId);
      this.setState({
          mappingIds: array,
      }, async function() {
        // Persist to the database
        await this.saveMappingTreeModel();
        // Syncing with others
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleAddMapping',
          argument: {newMapping: newMapping},
          doSave: false
        });
      });
    });
  }

  handleDeleteMapping = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDeleteMappingCallBack(props);
  }

  // First hide it, then delete its ID from the array of IDs,
  // then delete it from the object of objects
  // props: {mappingId: <TEXT>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleDeleteMappingCallBack = props => {
    // First delete Id from the array
    let array = this.state.mappingIds.slice(); // Create a copy
    let index = array.indexOf(props.mappingId);
    if(index !== -1) {
      array.splice(index, 1);
      this.setState({
        mappingIds: array,
      }, function() {
        // Then delete the respective object from the object of objects
        let newObjOfObjects = Object.assign({}, this.state.mappings); // Copy
        delete newObjOfObjects[props.mappingId]; // Mutate the copy
        // Replace the object of objects
        this.setState({
            mappings: newObjOfObjects
        }, async function() {
          this.retrieveUsedXpathsCallBack();
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            await mainService.saveMappingProject(this.state.currMappingProject);
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteMappingCallBack',
              argument: newProps
            });
          }
        });
      });
    }
    else {
      let successMsg = 'Sorry, the mapping to be erased was not found';
      this.props.showErrorSnackBar({msg: successMsg});
    }
  }

  // props: {
  //         name: 'domainSourceNode | doamainTargetEntity',
  //         mappingId: <TEXT>,
  //         event: <Event>,
  //         item: <Object>
  // }
  // This is the same as the below but it doesn't have the separated event and item properties
  handleMappingInputChangeButton = props => () => {
    // Case Single item (not an array of items)
    if(props.item.value !== undefined || props.item.inputValue !== undefined) {
      //const item = Object.assign({}, props.item);
      let customItem = {
        inputValue: props.item.value,
        label: 'Add \"' + props.item.value + '\"'
      }
      props.item = customItem;
    }
    // Case Array
    else {
      let customItem = [];
      props.item.forEach(tmpItem => {
        let customTmpItem = {
          inputValue: tmpItem.value,
          label: 'Add \"' + tmpItem.value + '\"'
        }
        customItem.push(customTmpItem);
      });
      props.item = customItem;
    }
    this.handleMappingInputChangeCallBack(props);
  }

  // props: {name: 'domainSourceNode | doamainTargetEntity', mappingId: <TEXT>}
  handleMappingInputChange = props => (event, item) => {
    props.event = null;
    props.item = item;
    this.handleMappingInputChangeCallBack(props);
  }

  // props: {
  //         name: 'domainSourceNode | doamainTargetEntity',
  //         mappingId: <TEXT>,
  //         event: <Event>,
  //         item: <Object>
  // }
  handleMappingInputChangeCallBack = (props) => {
    const item = props.item;
    if(item !== null) {
      // The "inputValue" property only exist in case of adding a custom value
      // Case Single item (not an array of items)
      if(item.value !== undefined || item.inputValue !== undefined) {
        // Case Single
        if(item.inputValue !== undefined) {
          if(item.inputValue !== null) {
            let inputValueCopy = item.inputValue;
            item.value = inputValueCopy;
            item.label = inputValueCopy;
            item.full = inputValueCopy;
            item.custom = true;
          }
          delete item.inputValue;
        }
      }
      // Case Array
      else {
        item.forEach(tmpItem => {
          if(tmpItem.inputValue !== undefined) {
            if(tmpItem.inputValue !== null) {
              let inputValueCopy = tmpItem.inputValue;
              tmpItem.value = inputValueCopy;
              tmpItem.label = inputValueCopy;
              tmpItem.full = inputValueCopy;
              tmpItem.custom = true;
            }
            delete tmpItem.inputValue;
          }
        });
      }

    }

    var objToHold = {};
    // Case Select value
    if(item !== null) {
      // Hold the object to change
      objToHold = Object.assign({}, this.state.mappings[props.mappingId][props.name]);

      // Case where some value is returned
      // In that case it is an object formed like this {value: <TEXT>, label: <TEXT>}
      if(item.value !== undefined) {
        // Changing object
        objToHold.item = item;
        delete objToHold.error_text;
      }
      // Case where no value is returned
      // In that case it is not an object but an ARRAY
      else {
        // Case the array is not empty (multivalues)
        if(item.length > 0) {
          // Changing object
          objToHold.item = item;
          //delete objToHold.error_text;
        }
        // Case the array is empty (propmt error)
        else {
          // Changing object
          objToHold.error_text = 'This field is required';
          objToHold.item = '';
        }
      }
    }
    // Case X button pressed
    else {
      // Changing object
      objToHold.error_text = 'This field is required';
      objToHold.item = '';
    }
    // Set changed object back to state
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          [props.name]: objToHold,
        }
      }
    }, async () => {
      // Validating next fields (if there are any)
      // Domain Target Entity has been changed
      if(this.state.validateSelections) {
        if(props.name === 'domainTargetEntity') {
          // Validate mapping's domain target entity (itself) since it holds many objects and we could have just deleted one of them
          await this.handleValidateSelectedValuesOfMappingDomainTargetEntityCallBack({
            mappingId: props.mappingId,
          });
          if(props.doSave !== undefined ? props.doSave : true) { // Don't call when syncing
            // Validating the target relation of each contained link
            for (const linkId of this.state.mappings[props.mappingId].linkIds) { // Are performed in sequence
              await this.handleValidateSelectedValuesOfLinkTargetRelationCallBack({
                mappingId: props.mappingId,
                linkId: linkId,
              });
            }

            // Validating additional relation
            for (const constExprId of this.state.mappings[props.mappingId].constExprIds) { // Are performed in sequence
              await this.handleValidateSelectedValuesOfAdditionalRelationCallBack({
                mappingId: props.mappingId,
                constExprId: constExprId,
              });
            }
          }
        }
      }

      if(props.name === 'domainSourceNode') {
        // Validate mapping's domain source node (itself) since it holds many objects and we
        // could have just deleted one of them
        await this.handleValidateSelectedValuesOfMappingDomainSourceNodeCallBack({
          mappingId: props.mappingId,
        });
        if(props.doSave !== undefined ? props.doSave : true) { // Don't call when syncing
          // Validating the source relation of each contained link
          for (const linkId of this.state.mappings[props.mappingId].linkIds) { // Are performed in sequence
            // Source
            await this.handleValidateSelectedValuesOfLinkSourceFieldCallBack({
              mappingId: props.mappingId,
              linkId: linkId,
              fieldType: 'sourceRelation'
            });
            await this.handleValidateSelectedValuesOfLinkSourceFieldCallBack({
              mappingId: props.mappingId,
              linkId: linkId,
              fieldType: 'sourceNode'
            });

            // Source Intermediates
            for (const intermediateId of this.state.mappings[props.mappingId].links[linkId].sourceIntermediateIds) { // Are performed in sequence
              await this.handleValidateSelectedValuesOfIntermediateSourceFieldCallBack({
                mappingId: props.mappingId,
                linkId: linkId,
                intermediateId: intermediateId,
                fieldType: 'sourceNode'
              });
              await this.handleValidateSelectedValuesOfIntermediateSourceFieldCallBack({
                mappingId: props.mappingId,
                linkId: linkId,
                intermediateId: intermediateId,
                fieldType: 'sourceRelation'
              });
            }
          }
        }
        // Retieving all the used xpaths
        this.retrieveUsedXpathsCallBack();
      }

      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await this.saveMappingTreeModel();
        await mainService.saveMappingProject(this.state.currMappingProject);
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleMappingInputChangeCallBack',
          argument: newProps,
        });
      }
    });
  }

  // ******************************************************************************
  // ************************************ Link ************************************
  // ******************************************************************************

  handleAddLink = props => () => {
    this.handleAddLinkCallBack(props);
  }
  // props: {mappingId: <TEXT>, newLink*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleAddLinkCallBack = props => {
    let newLink = props.newLink !== undefined ? props.newLink : undefined;
    if(newLink === undefined) {
      const linkId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
      newLink = {
        id: linkId,
        panelExpanded: false,
        sourceRelation: {
          item: '',
        },
        sourceIntermediateIds: [],
        sourceIntermediates: {},
        sourceNode: {
          item: '',
        },
        targetRelation: {
          item: '',
        },
        targetIntermediateIds: [],
        targetIntermediates: {},
        targetEntity: {
          item: '',
        },
        constExprIds: [],
        constExprs: {},
        conditionListUseOr: true,
        conditionListUseOrTargetNode: true,
        conditionListLogicalOperator: 'or',
        conditionListLogicalOperatorTargetNode: 'or',
        conditions: []
      };
    }
    // Update the object of objects for the respective mapping
    let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links); // Copy
    newObjOfObjects[newLink.id] = newLink;
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: newObjOfObjects,
        }
      }
    }, function() {
      // Update the linkIds Array
      let array = this.state.mappings[props.mappingId].linkIds.slice(); // Create a copy
      array.push(newLink.id);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            linkIds: array,
          }
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.newLink = newLink;
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleAddLinkCallBack',
            argument: newProps
          });
        }
      });
    });
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>}
  handleDeleteLink = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDeleteLinkCallBack(props);
  }
  // props: {mappingId: <TEXT>, linkId: <TEXT>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleDeleteLinkCallBack = props => {
    this.setState({
      deletingLinkId: props.linkId,
    }, () => {
      setTimeout(() => {
        // Update the linkIds Array
        let array = this.state.mappings[props.mappingId].linkIds.slice(); // Create a copy
        let index = array.indexOf(props.linkId);
        array.splice(index, 1);
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              linkIds: array,
            }
          }
        }, () => {
          // Update the object of objects for the respective mapping
          let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links); // Copy
          delete newObjOfObjects[props.linkId]; // Mutate the copy
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                links: newObjOfObjects,
              }
            }
          }, () => {
            this.setState({
              mappings: {
                ...this.state.mappings,
                [props.mappingId]: {
                  ...this.state.mappings[props.mappingId],
                  leaveEverywhereMoreSpace: this.thereIsAtLeastOneConstExprInTheWholeModel(),
                }
              }
            }, async () => {
              this.retrieveUsedXpathsCallBack();
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                await mainService.saveMappingProject(this.state.currMappingProject);
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleDeleteLinkCallBack',
                  argument: newProps
                });
              }
            });
          });
        });
      }, 500);
    });
  }

  // props: {
  //         name: 'sourceRelation | sourceNode | targetRelation | targetEntity',
  //         mappingId: <TEXT>,
  //         linkId: <TEXT>,
  //         item: <Object>
  // }
  // This is the same as the below but it doesn't have the separated event and item properties
  handleLinkInputChangeButton = props => () => {
    // Case Single item (not an array of items)
    if(props.item.value !== undefined || props.item.inputValue !== undefined) {
      //const item = Object.assign({}, props.item);
      let customItem = {
        inputValue: props.item.value,
        label: 'Add \"' + props.item.value + '\"'
      }
      props.item = customItem;
    }
    // Case Array
    else {
      let customItem = [];
      props.item.forEach(tmpItem => {
        let customTmpItem = {
          inputValue: tmpItem.value,
          label: 'Add \"' + tmpItem.value + '\"'
        }
        customItem.push(customTmpItem);
      });
      props.item = customItem;
    }
    this.handleLinkInputChangeCallBack(props);
  }

  // props: {name: 'sourceRelation | sourceNode | targetRelation | targetEntity', mappingId: <TEXT>, linkId: <TEXT>}
  handleLinkInputChange = props => (event, item) => {
    props.event = null;
    props.item = item;
    this.handleLinkInputChangeCallBack(props);
  }

  // props: {
  //         name: 'sourceRelation | sourceNode | targetRelation | targetEntity',
  //         mappingId: <TEXT>,
  //         linkId: <TEXT>,
  //         event: <Object>,
  //         item: <Object>
  // }
  handleLinkInputChangeCallBack = (props) => {
    const item = props.item;
    if(item !== null) {
      // The "inputValue" property only exist in case of adding a custom value
      // Case Single item (not an array of items)
      if(item.value !== undefined || item.inputValue !== undefined) {
        // Case Single
        if(item.inputValue !== undefined) {
          if(item.inputValue !== null) {
            let inputValueCopy = item.inputValue;
            item.value = inputValueCopy;
            item.label = inputValueCopy;
            item.full = inputValueCopy;
            item.custom = true;
          }
          delete item.inputValue;
        }
      }
      // Case Array
      else {
        item.forEach(tmpItem => {
          if(tmpItem.inputValue !== undefined) {
            if(tmpItem.inputValue !== null) {
              let inputValueCopy = tmpItem.inputValue;
              tmpItem.value = inputValueCopy;
              tmpItem.label = inputValueCopy;
              tmpItem.full = inputValueCopy;
              tmpItem.custom = true;
            }
            delete tmpItem.inputValue;
          }
        });
      }
    }

    var objToHold = {};
    // Case Select value
    if(item !== null) {
      // Hold the object to change
      objToHold = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId][props.name]);

      // Case where some value is returned
      // In that case it is an object formed like this {value: <TEXT>, label: <TEXT>}
      if(item.value !== undefined) {
        // Changing object
        objToHold.item = item;
        delete objToHold.error_text;
      }
      // Case where no value is returned
      // In that case it is not an object but an ARRAY
      else {
        // Case the array is not empty (multivalues)
        if(item.length > 0) {
          // Changing object
          objToHold.item = item;
          //delete objToHold.error_text;
        }
        // Case the array is empty (propmt error)
        else {
          // Changing object
          objToHold.error_text = 'This field is required';
          objToHold.item = '';
        }
      }
    }
    // Case X button pressed
    else {
      // Changing object
      objToHold.error_text = 'This field is required';
      objToHold.item = '';
    }
    // Set changed object to the state
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: {
              ...this.state.mappings[props.mappingId].links[props.linkId],
              [props.name]: objToHold,
            }
          }
        }
      }
    }, async function() {
      // Re-calculating source input coverage
      if(props.name === 'sourceRelation' || props.name === 'sourceNode') {
        this.retrieveUsedXpathsCallBack();
      }
      // Autofilling Source node if not empty
      // Target Entity has been changed
      if(props.name === 'sourceRelation') {
        if(this.state.mappings[props.mappingId].links[props.linkId].sourceNode.item === '') {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                links: {
                  ...this.state.mappings[props.mappingId].links,
                  [props.linkId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId],
                    sourceNode: objToHold,
                  }
                }
              }
            }
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              await mainService.saveMappingProject(this.state.currMappingProject);
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleLinkInputChangeCallBack',
                argument: newProps,
              });
            }
          });
        }
        else {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleLinkInputChangeCallBack',
              argument: newProps,
            });
          }
        }
      }
      else {
        if(props.doSave !== undefined ? props.doSave : true) { // Don't call when syncing
          // Validating next fields (if there are any)
          // Target Relation has been changed
          if(this.state.validateSelections) {
            if(props.name === 'targetRelation') {
              // Determine what to validate
              if(this.state.mappings[props.mappingId].links[props.linkId].targetIntermediateIds.length > 0) {
                // Validate the target entity of the first intermediate of this link
                await this.handleValidateSelectedValuesOfIntermediateTargetEntityCallBack({
                  mappingId: props.mappingId,
                  linkId: props.linkId,
                  intermediateId: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediateIds[0],
                });
              }
              else {
                // Validate link's target entity
                await this.handleValidateSelectedValuesOfLinkTargetEntityCallBack({
                  mappingId: props.mappingId,
                  linkId: props.linkId,
                });
              }
            }
            // Target Entity has been changed
            if(props.name === 'targetEntity') {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Validate link's target entity (itself) since it holds many objects and we could have just deleted one of them
                await this.handleValidateSelectedValuesOfLinkTargetEntityCallBack({
                  mappingId: props.mappingId,
                  linkId: props.linkId,
                });

                for (const constExprId of this.state.mappings[props.mappingId].links[props.linkId].constExprIds) { // Are performed in sequence
                  await this.handleValidateSelectedValuesOfAdditionalRelationCallBack({
                    mappingId: props.mappingId,
                    linkId: props.linkId,
                    constExprId: constExprId,
                  });
                }
              }
              // // Validate link's target entity (itself) since it holds many objects and we could have just deleted one of them
              // await this.handleValidateSelectedValuesOfLinkTargetEntityCallBack({
              //   mappingId: props.mappingId,
              //   linkId: props.linkId,
              // });
            }
          }
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleLinkInputChangeCallBack',
            argument: newProps,
          });
        }
      }
    });
  }

  // ******************************************************************************
  // ******************************** Intermediate ********************************
  // ******************************************************************************

  // props: {type: 'sourceIntermediate | targetIntermediate', mappingId: <TEXT>, linkId: <TEXT>}
  handleAddIntermediate = props => () => {
    this.handleAddIntermediateCallBack(props);
  }

  // props: {type: 'sourceIntermediate | targetIntermediate', mappingId: <TEXT>, linkId: <TEXT>,
  //         newIntermediate*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleAddIntermediateCallBack = props => {
    let newIntermediate = props.newIntermediate !== undefined ? props.newIntermediate : undefined;
    if(newIntermediate === undefined) {
      const intermediateId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
      newIntermediate = {};
      if(props.type === 'sourceIntermediate') {
        newIntermediate = {
          id: intermediateId,
          sourceNode: {
            item: '',
            isLoading: false
          },
          sourceRelation: {
            item: '',
            isLoading: false
          }
        };
      }
      else if(props.type === 'targetIntermediate') {
        newIntermediate = {
          id: intermediateId,
          targetEntity: {
            item: '',
            isLoading: false
          },
          targetRelation: {
            item: '',
            isLoading: false
          },
          constExprIds: [],
          constExprs: {}
        };
      }
    }
    // Update the object of objects for the respective mapping
    let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId][props.type+'s']); // Copy
    newObjOfObjects[newIntermediate.id] = newIntermediate;
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: {
              ...this.state.mappings[props.mappingId].links[props.linkId],
              [props.type+'s']: newObjOfObjects,
            }
          },
        }
      }
    }, () => {
      // Update the linkIds Array
      let array = this.state.mappings[props.mappingId].links[props.linkId][props.type+'Ids'].slice(); // Create a copy
      array.push(newIntermediate.id);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                [props.type+'Ids']: array,
              }
            }
          }
        }
      }, async function() {

        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.newIntermediate = newIntermediate;
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleAddIntermediateCallBack',
            argument: newProps
          });
        }
      });
    });
  }

  // props: {type: 'sourceIntermediate | targetIntermediate', mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <TEXT>}
  handleDeleteIntermediate = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDeleteIntermediateCallBack(props);
  }

  // props: {type: 'sourceIntermediate | targetIntermediate', mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <TEXT>}
  handleDeleteIntermediateCallBack = props => {
    // Update the linkIds Array
    let array = this.state.mappings[props.mappingId].links[props.linkId][props.type+'Ids'].slice(); // Create a copy
    const oldArrayLength = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediateIds.length; // Used for validation only
    const index = array.indexOf(props.intermediateId); // Used for validation as well
    array.splice(index, 1);
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: {
              ...this.state.mappings[props.mappingId].links[props.linkId],
              [props.type+'Ids']: array,
            }
          }
        }
      }
    }, function() {
      // Update the object of objects for the respective mapping
      let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId][props.type+'s']); // Copy
      delete newObjOfObjects[props.intermediateId]; // Mutate the copy
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                [props.type+'s']: newObjOfObjects,
              }
            }
          }
        }
      }, function() {
        if(props.type === 'sourceIntermediate') {
          this.retrieveUsedXpathsCallBack();
        }
        // Check if needed to leave more space or not and apply
        // change if necesary
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              leaveEverywhereMoreSpace: this.thereIsAtLeastOneConstExprInTheWholeModel(),
            }
          }
        }, async function() {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Validating next fields (if there are any)
            // Intermediate's target relation has been changed
            if(this.state.validateSelections) {
              if(props.type === 'targetIntermediate') {
                // Using index and oldArrayLength, which were measured before the deletion
                // Case - This is not the last intermediate
                if(index < oldArrayLength-1) {
                  // Validate the target entity of the first intermediate of this link
                  await this.handleValidateSelectedValuesOfIntermediateTargetEntityCallBack({
                    mappingId: props.mappingId,
                    linkId: props.linkId,
                    intermediateId: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediateIds[index],
                  });
                }
                // Case - This is the last intermediate
                else {
                  // Validate link's target entity
                  await this.handleValidateSelectedValuesOfLinkTargetEntityCallBack({
                    mappingId: props.mappingId,
                    linkId: props.linkId,
                  });
                }
              }
            }

            // Persist to the database
            await this.saveMappingTreeModel();
            await mainService.saveMappingProject(this.state.currMappingProject);
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteIntermediateCallBack',
              argument: newProps
            });
          }
        });
      });
    });
  }

  // props: {
  //         name: 'sourceRelation | sourceNode | targetRelation | targetEntity',
  //         type: 'sourceIntermediate | targetIntermediate',
  //         mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <TEXT>,
  //         item: <Object>
  // }
  // This is the same as the below but it doesn't have the separated event and item properties
  handleIntermediateInputChangeButton = props => () => {
    // Case Single item (not an array of items)
    if(props.item.value !== undefined || props.item.inputValue !== undefined) {
      //const item = Object.assign({}, props.item);
      let customItem = {
        inputValue: props.item.value,
        label: 'Add \"' + props.item.value + '\"'
      }
      props.item = customItem;
    }
    // Case Array
    else {
      let customItem = [];
      props.item.forEach(tmpItem => {
        let customTmpItem = {
          inputValue: tmpItem.value,
          label: 'Add \"' + tmpItem.value + '\"'
        }
        customItem.push(customTmpItem);
      });
      props.item = customItem;
    }
    this.handleIntermediateInputChangeCallBack(props);
  }

  // props: {name: 'sourceRelation | sourceNode | targetRelation | targetEntity',
  //         type: 'sourceIntermediate | targetIntermediate',
  //         mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <TEXT>}
  handleIntermediateInputChange = props => (event, item) => {
    props.event = null;
    props.item = item;
    this.handleIntermediateInputChangeCallBack(props);
  }

  // props: {
  //         name: 'sourceRelation | sourceNode | targetRelation | targetEntity',
  //         type: 'sourceIntermediate | targetIntermediate',
  //         mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <TEXT>,
  //         event: <Object>,
  //         item: <Object>
  // }
  handleIntermediateInputChangeCallBack = (props) => {
    const item = props.item;
    if(item !== null) {
      // The "inputValue" property only exist in case of adding a custom value
      // Case Single item (not an array of items)
      if(item.value !== undefined || item.inputValue !== undefined) {
        // Case Single
        if(item.inputValue !== undefined) {
          if(item.inputValue !== null) {
            let inputValueCopy = item.inputValue;
            item.value = inputValueCopy;
            item.label = inputValueCopy;
            item.full = inputValueCopy;
            item.custom = true;
          }
          delete item.inputValue;
        }
      }
      // Case Array
      else {
        item.forEach(tmpItem => {
          if(tmpItem.inputValue !== undefined) {
            if(tmpItem.inputValue !== null) {
              let inputValueCopy = tmpItem.inputValue;
              tmpItem.value = inputValueCopy;
              tmpItem.label = inputValueCopy;
              tmpItem.full = inputValueCopy;
              tmpItem.custom = true;
            }
            delete tmpItem.inputValue;
          }
        });
      }
    }
    var objToHold = {};
    // Case Select value
    if(item !== null) {
      // Hold the object to change
      objToHold = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId][props.type+'s'][props.intermediateId][props.name]); // Copy

      // Case where some value is returned
      // In that case it is an object formed like this {value: <TEXT>, label: <TEXT>}
      if(item.value !== undefined) {
        // Changing object
        objToHold.item = item;
        delete objToHold.error_text;
      }
      // Case where no value is returned
      // In that case it is not an object but an ARRAY
      else {
        // Case the array is not empty (multivalues)
        if(item.length > 0) {
          // Changing object
          objToHold.item = item;
          //delete objToHold.error_text;
        }
        // Case the array is empty (propmt error)
        else {
          // Changing object
          objToHold.error_text = 'This field is required';
          objToHold.item = '';
        }
      }
    }
    // Case X button pressed
    else {
      // Changing object
      objToHold.error_text = 'This field is required';
      objToHold.item = '';
    }
    // Set changed object back to state
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: {
              ...this.state.mappings[props.mappingId].links[props.linkId],
              [props.type+'s']: {
                ...this.state.mappings[props.mappingId].links[props.linkId][props.type+'s'],
                [props.intermediateId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId][props.type+'s'][props.intermediateId],
                  [props.name]: objToHold
                }
              }
            }
          }
        }
      }
    }, async function() {
      // Re-calculating source input coverage
      if(props.name === 'sourceRelation' || props.name === 'sourceNode') {
        this.retrieveUsedXpathsCallBack();
      }
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Validating next fields (if there are any)
        // Intermediate's target relation has been changed
        if(this.state.validateSelections) {
          if(props.name === 'targetRelation') {
            // Determine what to validate
            // Find the index of this intermediate
            let intermediateIndex = this.state.mappings[props.mappingId].links[props.linkId][props.type+'Ids'].findIndex(
              id => id === props.intermediateId
            );
            if(intermediateIndex < this.state.mappings[props.mappingId].links[props.linkId].targetIntermediateIds.length-1) {
              // Validate the target entity of the first intermediate of this link
              await this.handleValidateSelectedValuesOfIntermediateTargetEntityCallBack({
                mappingId: props.mappingId,
                linkId: props.linkId,
                intermediateId: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediateIds[intermediateIndex+1],
              });
            }
            else {
              // Validate link's target entity
              await this.handleValidateSelectedValuesOfLinkTargetEntityCallBack({
                mappingId: props.mappingId,
                linkId: props.linkId,
              });
            }
          }
          // Intermediate's target entity has been changed
          if(props.name === 'targetEntity') {
            // Validate intermediate's target entity (itself) since it holds many objects and we could have just deleted one of them
            await this.handleValidateSelectedValuesOfIntermediateTargetEntityCallBack({
              mappingId: props.mappingId,
              linkId: props.linkId,
              intermediateId: props.intermediateId,
            });
            // Validate the target relation of this intermediate
            await this.handleValidateSelectedValuesOfIntermediateTargetRelationCallBack({
              mappingId: props.mappingId,
              linkId: props.linkId,
              intermediateId: props.intermediateId,
            });
            // Validating Intermediate's additional relation
            if(props.doSave !== undefined ? props.doSave : true) { // Don't call when syncing
              for (const constExprId of this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprIds) { // Are performed in sequence
                await this.handleValidateSelectedValuesOfAdditionalRelationCallBack({
                  mappingId: props.mappingId,
                  linkId: props.linkId,
                  intermediateId: props.intermediateId,
                  constExprId: constExprId,
                });
              }
            }
          }
        }

        // Persist to the database
        await this.saveMappingTreeModel();
        await mainService.saveMappingProject(this.state.currMappingProject);
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleIntermediateInputChangeCallBack',
          argument: newProps,
        });
      }
    });
  }

  // *******************************************************************************
  // ************************** Constant Expression - Mapping (Doamain) *************************
  // *******************************************************************************

  // props: {mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <Text>,
  //         newConstExpr*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleAddMappingConstExpr = props => () => {
    this.handleAddMappingConstExprCallBack(props);
  }

  // props: {mappingId: <TEXT>,
  //         newConstExpr*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleAddMappingConstExprCallBack = props => {
    let newConstExpr = props.newConstExpr !== undefined ? props.newConstExpr : undefined;
    if(newConstExpr === undefined) {
      const constExprId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
      newConstExpr = {
        id: constExprId,
        entity: {
          item: '',
          isLoading: false
        },
        relation: {
          item: '',
          isLoading: false
        }
      };
    }

    // Update the object of objects for the respective mapping
    let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].constExprs); // Copy
    newObjOfObjects[newConstExpr.id] = newConstExpr;
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          constExprs: newObjOfObjects,
        }
      }
    }, () => {
      // Update the linkIds Array
      let array = this.state.mappings[props.mappingId].constExprIds.slice(); // Create a copy
      array.push(newConstExpr.id);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            constExprIds: array,
            leaveEverywhereMoreSpace: true,
          }
        },
      }, async function() {
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.newConstExpr = newConstExpr;
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleAddMappingConstExprCallBack',
            argument: newProps
          });
        }
      });
    });
  }

  // props: {mappingId: <TEXT>, linkId: -1, intermediateId: -1, constExprId: <TEXT>}
  handleDeleteMappingConstExpr = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDeleteMappingConstExprCallBack(props);
  }

  // props: {mappingId: <TEXT>, linkId: -1, intermediateId: -1, constExprId: <TEXT>}
  handleDeleteMappingConstExprCallBack = async props => {
    let recursionArray = props.recursionArray !== undefined ? [...props.recursionArray] : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }

    // Native ConstExpr
    if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
      const rootNativeConstExprId = recursionArray.shift();
      // Using nativeConstExprProps from state
      // nativeConstExprProps: {
      //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
      //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
      // }
      let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
      if(fullTreeConstExpr === undefined) {
        fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
          recursionArray: recursionArray,
          object: undefined,
          currentConstExprId: props.constExprId,
          fieldName: 'delete',
          objectName: undefined
        });
      }
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            constExprs: {
              ...this.state.mappings[props.mappingId].constExprs,
              [rootNativeConstExprId] : fullTreeConstExpr
            }
          }
        }
      }, () => {
        let currNativeConstExpr = undefined;
        // For the user that took action at first place
        if(props.doSave !== undefined ? props.doSave : true) {
          currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
            rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
          });
        }
        // others to sync (clients)
        else {
          const clinetRecursionArray =
            this.state.nativeConstExprProps.recursionArray !== undefined ?
            [...this.state.nativeConstExprProps.recursionArray] :
            undefined;
          if(clinetRecursionArray !== undefined) {
            clinetRecursionArray.shift();
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
              recursionArray: clinetRecursionArray,
            });
          }
        }
        this.setState({
          currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
            newProps.fullTreeConstExpr = fullTreeConstExpr;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteMappingConstExprCallBack',
              argument: newProps,
            });
          }
        });
      });
    } // Native ConstExpr - ends
    else {
      // Update the Array
      let array = this.state.mappings[props.mappingId].constExprIds.slice(); // Create a copy
      let index = array.indexOf(props.constExprId);
      array.splice(index, 1);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            constExprIds: array,
          }
        }
      }, () => {
        // Update the object of objects for the respective mapping
        let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].constExprs); // Copy
        delete newObjOfObjects[props.constExprId]; // Mutate the copy
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              constExprs: newObjOfObjects,
              leaveEverywhereMoreSpace: this.thereIsAtLeastOneConstExprInTheWholeModel(),
            }
          }
        }, async () => {
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteMappingConstExprCallBack',
              argument: newProps
            });
          }
        });
      });
    }
  }

  // props: {
  //         name: 'relation | entity',
  //         mappingId: <TEXT>,
  //         linkId*: <TEXT>,
  //         intermediateId*: <TEXT>,
  //         constExprId: <TEXT>,
  //         event: <Object>,
  //         item: <Object>
  // }
  // This is "-1" in case of link (not used)
  handleInputChangeMappingConstExprButton = props => () => {
    // Case Single item (not an array of items)
    if(props.item.value !== undefined || props.item.inputValue !== undefined) {
      //const item = Object.assign({}, props.item);
      let customItem = {
        inputValue: props.item.value,
        label: 'Add \"' + props.item.value + '\"'
      }
      props.item = customItem;
    }
    // Case Array
    else {
      let customItem = [];
      props.item.forEach(tmpItem => {
        let customTmpItem = {
          inputValue: tmpItem.value,
          label: 'Add \"' + tmpItem.value + '\"'
        }
        customItem.push(customTmpItem);
      });
      props.item = customItem;
    }
    this.handleInputChangeMappingConstExprCallBack(props);
  }

  // props: {name: 'relation | entity', mappingId: <TEXT>, linkId*: <TEXT>, intermediateId*: <TEXT>, constExprId: <TEXT>}
  // * This is "-1" in case of link or intermediate (not used)
  handleInputChangeMappingConstExpr = props => (event, item) => {
    props.event = null;
    props.item = item;
    this.handleInputChangeMappingConstExprCallBack(props);
  }

  // props: {
  //          name: 'relation | entity',
  //          mappingId: <TEXT>,
  //          linkId*: <TEXT>,
  //          intermediateId*: <TEXT>,
  //          constExprId: <TEXT>,
  //          event: <Object>,
  //          item: <Object>,
  //
  // }
  // Using nativeConstExprProps.recursionArray
  // * This is "-1" in case of link or intermediate (not used)
  handleInputChangeMappingConstExprCallBack = async (props) => {
    const item = props.item;
    if(item !== null) {
      // The "inputValue" property only exist in case of adding a custom value
      // Case Single item (not an array of items)
      if(item.value !== undefined || item.inputValue !== undefined) {
        // Case Single
        if(item.inputValue !== undefined) {
          if(item.inputValue !== null) {
            let inputValueCopy = item.inputValue;
            item.value = inputValueCopy;
            item.label = inputValueCopy;
            item.full = inputValueCopy;
            item.custom = true;
          }
          delete item.inputValue;
        }
      }
      // Case Array
      else {
        item.forEach(tmpItem => {
          if(tmpItem.inputValue !== undefined) {
            if(tmpItem.inputValue !== null) {
              let inputValueCopy = tmpItem.inputValue;
              tmpItem.value = inputValueCopy;
              tmpItem.label = inputValueCopy;
              tmpItem.full = inputValueCopy;
              tmpItem.custom = true;
            }
            delete tmpItem.inputValue;
          }
        });
      }
    }

    let recursionArray = props.recursionArray !== undefined ? [...props.recursionArray] : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }

    // Native ConstExpr
    if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
      const rootNativeConstExprId = recursionArray.shift();
      // Using nativeConstExprProps from state
      // nativeConstExprProps: {
      //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
      //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
      // }
      let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
      if(fullTreeConstExpr === undefined) {
        fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
          recursionArray: recursionArray,
          object: item,
          currentConstExprId: props.constExprId,
          fieldName: props.name,
          objectName: 'item'
        });
      }
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            constExprs: {
              ...this.state.mappings[props.mappingId].constExprs,
              [rootNativeConstExprId] : fullTreeConstExpr
            }
          }
        },
      }, () => {
        let currNativeConstExpr = undefined;
        // For the user that took action at first place
        if(props.doSave !== undefined ? props.doSave : true) {
          currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
            rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
          });
        }
        // others to sync (clients)
        else {
          const clinetRecursionArray =
            this.state.nativeConstExprProps.recursionArray !== undefined ?
            [...this.state.nativeConstExprProps.recursionArray] :
            undefined;
          if(clinetRecursionArray !== undefined) {
            clinetRecursionArray.shift();
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
              recursionArray: clinetRecursionArray,
            });
          }
        }
        this.setState({
          currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleInputChangeMappingConstExprCallBack',
              argument: newProps,
            });
          }
        });
      });
    } // Native ConstExpr - ends
    else {
      // Hold the object to change
      var objToHold = Object.assign({}, this.state.mappings[props.mappingId].constExprs[props.constExprId][props.name]); // Copy

      // Case Select value
      if(item !== null) {
        // Case where some value is returned
        // In that case it is an object formed like this {value: <TEXT>, label: <TEXT>}
        if(item.value !== undefined) {
          // Changing object
          objToHold.item = item;
          delete objToHold.error_text;
        }
        // Case where no value is returned
        // In that case it is not an object but an ARRAY
        else {
          // Case the array is not empty (multivalues)
          if(item.length > 0) {
            // Changing object
            objToHold.item = item;
            delete objToHold.error_text;
          }
          // Case the array is empty (propmt error)
          else {
            // Changing object
            objToHold.error_text = 'This field is required';
            objToHold.item = '';
          }
        }
      }
      // Case X button pressed
      else {
        // Changing object
        objToHold.error_text = 'This field is required';
        objToHold.item = '';
      }
      // Set changed object back to state
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            constExprs: {
              ...this.state.mappings[props.mappingId].constExprs,
              [props.constExprId]: {
                ...this.state.mappings[props.mappingId].constExprs[props.constExprId],
                [props.name]: objToHold
              }
            }
          }
        }
      }, async () => {
        // Validating the relation field of any child native additional
        if(this.state.mappings[props.mappingId].constExprs[props.constExprId].constExprIds !== undefined) {
          for (const subConstExprId of this.state.mappings[props.mappingId].constExprs[props.constExprId].constExprIds) {
            let childErrorMsg =
              await this.handleValidateSelectedValuesOfNativeAdditionalCallBack({
                constExpr:this.state.mappings[props.mappingId].constExprs[props.constExprId].constExprs[subConstExprId],
                parentConstExpr: this.state.mappings[props.mappingId].constExprs[props.constExprId],
                type: 'relation'
              });
            // Setting the error label for the relation of the child
            let childRelation = {...this.state.mappings[props.mappingId].constExprs[props.constExprId].constExprs[subConstExprId].relation};
            if(childErrorMsg !== undefined)
              childRelation.error_text = childErrorMsg;
            else
              delete childRelation.error_text;

            this.setState({
              mappings: {
                ...this.state.mappings,
                [props.mappingId]: {
                  ...this.state.mappings[props.mappingId],
                  constExprs: {
                    ...this.state.mappings[props.mappingId].constExprs,
                    [props.constExprId]: {
                      ...this.state.mappings[props.mappingId].constExprs[props.constExprId],
                      constExprs: {
                        ...this.state.mappings[props.mappingId].constExprs[props.constExprId].constExprs,
                        [subConstExprId]: {
                          ...this.state.mappings[props.mappingId].constExprs[props.constExprId].constExprs[subConstExprId],
                          relation: childRelation
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        }
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Validating next fields (if there are any)
          // Link additional's relation has been changed
          if(this.state.validateSelections) {
            if(props.name === 'relation') {
              await this.handleValidateSelectedValuesOfAdditionalEntityCallBack({
                mappingId: props.mappingId,
                constExprId: props.constExprId,
              });
            }
          }
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleInputChangeMappingConstExprCallBack',
            argument: newProps,
          });
        }
      });
    }
  }

  // *******************************************************************************
  // ************************** Constant Expression - Link *************************
  // *******************************************************************************

  handleAddLinkConstExpr = props => () => {
    this.handleAddLinkConstExprCallBack(props);
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>,
  //         newConstExpr*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleAddLinkConstExprCallBack = props => {
    let newConstExpr = props.newConstExpr !== undefined ? props.newConstExpr : undefined;
    if(newConstExpr === undefined) {
      const constExprId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
      newConstExpr = {
        id: constExprId,
        entity: {
          item: '',
          isLoading: false
        },
        relation: {
          item: '',
          isLoading: false
        }
      };
    }

    // Update the object of objects for the respective mapping
    let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId].constExprs); // Copy
    newObjOfObjects[newConstExpr.id] = newConstExpr;
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: {
              ...this.state.mappings[props.mappingId].links[props.linkId],
              constExprs: newObjOfObjects,
            }
          }
        }
      }
    }, function() {
      // Update the linkIds Array
      let array = this.state.mappings[props.mappingId].links[props.linkId].constExprIds.slice(); // Create a copy
      array.push(newConstExpr.id);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                constExprIds: array,
              }
            },
            leaveEverywhereMoreSpace: true,
          }
        }
      }, async function() {
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.newConstExpr = newConstExpr;
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleAddLinkConstExprCallBack',
            argument: newProps
          });
        }
      });
    });
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>, intermediateId: -1, constExprId: <TEXT>}
  handleDeleteLinkConstExpr = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDeleteLinkConstExprCallBack(props);
  }

  handleDeleteLinkConstExprCallBack = async props => {
    let recursionArray = props.recursionArray !== undefined ? [...props.recursionArray] : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }

    // Native ConstExpr
    if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
      const rootNativeConstExprId = recursionArray.shift();
      // Using nativeConstExprProps from state
      // nativeConstExprProps: {
      //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
      //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
      // }
      let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
      if(fullTreeConstExpr === undefined) {
        fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
          recursionArray: recursionArray,
          object: undefined,
          currentConstExprId: props.constExprId,
          fieldName: 'delete',
          objectName: undefined
        });
      }
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                constExprs: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                  [rootNativeConstExprId] : fullTreeConstExpr
                }
              }
            }
          }
        }
      }, () => {
        let currNativeConstExpr = undefined;
        // For the user that took action at first place
        if(props.doSave !== undefined ? props.doSave : true) {
          currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
            rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
          });
        }
        // others to sync (clients)
        else {
          const clinetRecursionArray =
            this.state.nativeConstExprProps.recursionArray !== undefined ?
            [...this.state.nativeConstExprProps.recursionArray] :
            undefined;
          if(clinetRecursionArray !== undefined) {
            clinetRecursionArray.shift();
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
              recursionArray: clinetRecursionArray,
            });
          }
        }
        this.setState({
          currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
            newProps.fullTreeConstExpr = fullTreeConstExpr;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteLinkConstExprCallBack',
              argument: newProps,
            });
          }
        });
      });
    } // Native ConstExpr - ends
    else {
      // Update the linkIds Array
      let array = this.state.mappings[props.mappingId].links[props.linkId].constExprIds.slice(); // Create a copy
      let index = array.indexOf(props.constExprId);
      array.splice(index, 1);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                constExprIds: array,
              }
            },
          }
        }
      }, function() {
        // Update the object of objects for the respective mapping
        let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId].constExprs); // Copy
        delete newObjOfObjects[props.constExprId]; // Mutate the copy
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  constExprs: newObjOfObjects,
                }
              },
              leaveEverywhereMoreSpace: this.thereIsAtLeastOneConstExprInTheWholeModel(),
            }
          }
        }, async function() {
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteLinkConstExprCallBack',
              argument: newProps
            });
          }
        });
      });
    }
  }

  // props: {
  //         name: 'relation | entity',
  //         mappingId: <TEXT>,
  //         linkId: <TEXT>,
  //         intermediateId*: <TEXT>,
  //         constExprId: <TEXT>
  //         event: <Object>,
  //         item: <Object>
  // }
  // This is "-1" in case of link (not used)
  handleInputChangeLinkConstExprButton = props => () => {
    // Case Single item (not an array of items)
    if(props.item.value !== undefined || props.item.inputValue !== undefined) {
      //const item = Object.assign({}, props.item);
      let customItem = {
        inputValue: props.item.value,
        label: 'Add \"' + props.item.value + '\"'
      }
      props.item = customItem;
    }
    // Case Array
    else {
      let customItem = [];
      props.item.forEach(tmpItem => {
        let customTmpItem = {
          inputValue: tmpItem.value,
          label: 'Add \"' + tmpItem.value + '\"'
        }
        customItem.push(customTmpItem);
      });
      props.item = customItem;
    }
    this.handleInputChangeLinkConstExprCallBack(props);
  }

  // props: {name: 'relation | entity', mappingId: <TEXT>, linkId: <TEXT>, intermediateId*: <TEXT>, constExprId: <TEXT>}
  // This is "-1" in case of link (not used)
  handleInputChangeLinkConstExpr = props => (event, item) => {
    props.event = null;
    props.item = item;
    this.handleInputChangeLinkConstExprCallBack(props);
  }

  // props: {
  //         name: 'relation | entity',
  //         mappingId: <TEXT>,
  //         linkId: <TEXT>,
  //         intermediateId*: <TEXT>,
  //         constExprId: <TEXT>
  //         event: <Object>,
  //         item: <Object>
  // }
  // Using nativeConstExprProps.recursionArray
  // * This is "-1" in case of link (not used)
  handleInputChangeLinkConstExprCallBack = async (props) => {
    const item = props.item;
    if(item !== null) {
      // The "inputValue" property only exist in case of adding a custom value
      // Case Single item (not an array of items)
      if(item.value !== undefined || item.inputValue !== undefined) {
        // Case Single
        if(item.inputValue !== undefined) {
          if(item.inputValue !== null) {
            let inputValueCopy = item.inputValue;
            item.value = inputValueCopy;
            item.label = inputValueCopy;
            item.full = inputValueCopy;
            item.custom = true;
          }
          delete item.inputValue;
        }
      }
      // Case Array
      else {
        item.forEach(tmpItem => {
          if(tmpItem.inputValue !== undefined) {
            if(tmpItem.inputValue !== null) {
              let inputValueCopy = tmpItem.inputValue;
              tmpItem.value = inputValueCopy;
              tmpItem.label = inputValueCopy;
              tmpItem.full = inputValueCopy;
              tmpItem.custom = true;
            }
            delete tmpItem.inputValue;
          }
        });
      }
    }

    let recursionArray = props.recursionArray !== undefined ? [...props.recursionArray] : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }

    // Native ConstExpr
    if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
      const rootNativeConstExprId = recursionArray.shift();
      // Using nativeConstExprProps from state
      // nativeConstExprProps: {
      //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
      //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
      // }
      let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
      if(fullTreeConstExpr === undefined) {
        fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
          recursionArray: recursionArray,
          object: item,
          currentConstExprId: props.constExprId,
          fieldName: props.name,
          objectName: 'item'
        });
      }
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                constExprs: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                  [rootNativeConstExprId] : fullTreeConstExpr
                }
              }
            }
          }
        }
      }, () => {
        let currNativeConstExpr = undefined;
        // For the user that took action at first place
        if(props.doSave !== undefined ? props.doSave : true) {
          currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
            rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
          });
        }
        // others to sync (clients)
        else {
          const clinetRecursionArray =
            this.state.nativeConstExprProps.recursionArray !== undefined ?
            [...this.state.nativeConstExprProps.recursionArray] :
            undefined;
          if(clinetRecursionArray !== undefined) {
            clinetRecursionArray.shift();
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
              recursionArray: clinetRecursionArray,
            });
          }
        }
        this.setState({
          currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleInputChangeLinkConstExprCallBack',
              argument: newProps,
            });
          }
        });
      });
    } // Native ConstExpr - ends
    else {

      var objToHold = {};
      // Case Select value
      if(item !== null) {
        // Hold the object to change
        objToHold = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId][props.name]); // Copy

        // Case where some value is returned
        // In that case it is an object formed like this {value: <TEXT>, label: <TEXT>}
        if(item.value !== undefined) {
          // Changing object
          objToHold.item = item;
          delete objToHold.error_text;
        }
        // Case where no value is returned
        // In that case it is not an object but an ARRAY
        else {
          // Case the array is not empty (multivalues)
          if(item.length > 0) {
            // Changing object
            objToHold.item = item;
            delete objToHold.error_text;
          }
          // Case the array is empty (propmt error)
          else {
            // Changing object
            objToHold.error_text = 'This field is required';
            objToHold.item = '';
          }
        }
      }
      // Case X button pressed
      else {
        // Changing object
        objToHold.error_text = 'This field is required';
        objToHold.item = '';
      }
      // Set changed object back to state
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                constExprs: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                  [props.constExprId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId],
                    [props.name]: objToHold
                  }
                }
              }
            }
          }
        }
      }, async () => {
        // Validating the relation field of any child native additional
        if(this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].constExprIds !== undefined) {
          for (const subConstExprId of this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].constExprIds) {
            let childErrorMsg =
              await this.handleValidateSelectedValuesOfNativeAdditionalCallBack({
                constExpr:this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].constExprs[subConstExprId],
                parentConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId],
                type: 'relation'
              });
            // Setting the error label for the relation of the child
            let childRelation = {...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].constExprs[subConstExprId].relation};
            if(childErrorMsg !== undefined)
              childRelation.error_text = childErrorMsg;
            else
              delete childRelation.error_text;

            this.setState({
              mappings: {
                ...this.state.mappings,
                [props.mappingId]: {
                  ...this.state.mappings[props.mappingId],
                  links: {
                    ...this.state.mappings[props.mappingId].links,
                    [props.linkId]: {
                      ...this.state.mappings[props.mappingId].links[props.linkId],
                      constExprs: {
                        ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                        [props.constExprId]: {
                          ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId],
                          constExprs: {
                            ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].constExprs,
                            [subConstExprId]: {
                              ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].constExprs[subConstExprId],
                              relation: childRelation
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        }
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Validating next fields (if there are any)
          // Link additional's relation has been changed
          if(this.state.validateSelections) {
            if(props.name === 'relation') {
              await this.handleValidateSelectedValuesOfAdditionalEntityCallBack({
                mappingId: props.mappingId,
                linkId: props.linkId,
                constExprId: props.constExprId,
              });
            }
          }
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleInputChangeLinkConstExprCallBack',
            argument: newProps,
          });
        }
      });
    }
  }

  // *******************************************************************************
  // ********************** Constant Expression - Intermediate *********************
  // *******************************************************************************

  // props: {mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <Text>,
  //         newConstExpr*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleAddIntermediateConstExpr = props => () => {
    this.handleAddIntermediateConstExprCallBack(props);
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <Text>,
  //         newConstExpr*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleAddIntermediateConstExprCallBack = props => {
    let newConstExpr = props.newConstExpr !== undefined ? props.newConstExpr : undefined;
    if(newConstExpr === undefined) {
      const constExprId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
      newConstExpr = {
        id: constExprId,
        entity: {
          item: '',
          isLoading: false
        },
        relation: {
          item: '',
          isLoading: false
        }
      };
    }

    // Update the object of objects for the respective constExpr
    let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs); // Copy
    newObjOfObjects[newConstExpr.id] = newConstExpr;
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: {
              ...this.state.mappings[props.mappingId].links[props.linkId],
              targetIntermediates: {
                ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                [props.intermediateId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                  constExprs: newObjOfObjects
                }
              }
            }
          }
        }
      }
    }, function() {
      // Update the linkIds Array
      let array = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprIds.slice(); // Create a copy
      array.push(newConstExpr.id);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                    constExprIds: array
                  }
                }
              }
            },
            leaveEverywhereMoreSpace: true,
          }
        }
      }, async function() {
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.newConstExpr = newConstExpr;
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleAddIntermediateConstExprCallBack',
            argument: newProps
          });
        }
      });
    });
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>, intermediateId*: <TEXT>, constExprId: <TEXT>}
  // * This is "-1" in case that this Constant Expression is located inside the Link
  handleDeleteIntermediateConstExpr = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDeleteIntermediateConstExprCallBack(props);
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>, intermediateId*: <TEXT>, constExprId: <TEXT>}
  // * This is "-1" in case that this Constant Expression is located inside the Link
  handleDeleteIntermediateConstExprCallBack = async props => {

    let recursionArray = props.recursionArray !== undefined ? [...props.recursionArray] : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }

    // Native ConstExpr
    if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
      const rootNativeConstExprId = recursionArray.shift();
      // Using nativeConstExprProps from state
      // nativeConstExprProps: {
      //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
      //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
      // }
      let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
      if(fullTreeConstExpr === undefined) {
        fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
          recursionArray: recursionArray,
          object: undefined,
          currentConstExprId: props.constExprId,
          fieldName: 'delete',
          objectName: undefined
        });
      }
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                    constExprs: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                      [rootNativeConstExprId] : fullTreeConstExpr
                    }
                  }
                }
              }
            }
          }
        }
      }, () => {
        let currNativeConstExpr = undefined;
        // For the user that took action at first place
        if(props.doSave !== undefined ? props.doSave : true) {
          currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
            rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
          });
        }
        // others to sync (clients)
        else {
          const clinetRecursionArray =
            this.state.nativeConstExprProps.recursionArray !== undefined ?
            [...this.state.nativeConstExprProps.recursionArray] :
            undefined;
          if(clinetRecursionArray !== undefined) {
            clinetRecursionArray.shift();
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
              recursionArray: clinetRecursionArray,
            });
          }
        }
        this.setState({
          currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
            newProps.fullTreeConstExpr = fullTreeConstExpr;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteIntermediateConstExprCallBack',
              argument: newProps,
            });
          }
        });
      });
    } // Native ConstExpr - ends
    else {
      // Update the linkIds Array
      let array = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprIds.slice(); // Create a copy
      let index = array.indexOf(props.constExprId);
      array.splice(index, 1);
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                    constExprIds: array
                  }
                }
              }
            }
          }
        }
      }, function() {
        // Update the object of objects for the respective mapping
        let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs); // Copy
        delete newObjOfObjects[props.constExprId]; // Mutate the copy
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  targetIntermediates: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                    [props.intermediateId]: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                      constExprs: newObjOfObjects
                    }
                  }
                }
              },
              leaveEverywhereMoreSpace: this.thereIsAtLeastOneConstExprInTheWholeModel(),
            }
          }
        }, async function() {
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteIntermediateConstExprCallBack',
              argument: newProps
            });
          }
        });
      });
    }
  }

  // props: {
  //         name: 'relation | entity',
  //         mappingId: <TEXT>,
  //         linkId: <TEXT>,
  //         intermediateId: <TEXT>,
  //         constExprId: <TEXT>
  //         event: <Object>,
  //         item: <Object>
  // }
  // This is "-1" in case of link (not used)
  handleInputChangeIntermediateConstExprButton = props => () => {
    // Case Single item (not an array of items)
    if(props.item.value !== undefined || props.item.inputValue !== undefined) {
      //const item = Object.assign({}, props.item);
      let customItem = {
        inputValue: props.item.value,
        label: 'Add \"' + props.item.value + '\"'
      }
      props.item = customItem;
    }
    // Case Array
    else {
      let customItem = [];
      props.item.forEach(tmpItem => {
        let customTmpItem = {
          inputValue: tmpItem.value,
          label: 'Add \"' + tmpItem.value + '\"'
        }
        customItem.push(customTmpItem);
      });
      props.item = customItem;
    }
    this.handleInputChangeIntermediateConstExprCallBack(props);
  }

  // props: {name: 'relation | entity', mappingId: <TEXT>, linkId: <TEXT>, intermediateId: <TEXT>, constExprId: <TEXT>}
  handleInputChangeIntermediateConstExpr = props => (event, item) => {
    props.event = null;
    props.item = item;
    this.handleInputChangeIntermediateConstExprCallBack(props);
  }

  // props: {
  //         name: 'relation | entity',
  //         mappingId: <TEXT>,
  //         linkId: <TEXT>,
  //         intermediateId: <TEXT>,
  //         constExprId: <TEXT>
  //         event: <Object>,
  //         item: <Object>
  //        }
  handleInputChangeIntermediateConstExprCallBack = async (props) => {
    const item = props.item;
    if(item !== null) {
      // The "inputValue" property only exist in case of adding a custom value
      // Case Single item (not an array of items)
      if(item.value !== undefined || item.inputValue !== undefined) {
        // Case Single
        if(item.inputValue !== undefined) {
          if(item.inputValue !== null) {
            let inputValueCopy = item.inputValue;
            item.value = inputValueCopy;
            item.label = inputValueCopy;
            item.full = inputValueCopy;
            item.custom = true;
          }
          delete item.inputValue;
        }
      }
      // Case Array
      else {
        item.forEach(tmpItem => {
          if(tmpItem.inputValue !== undefined) {
            if(tmpItem.inputValue !== null) {
              let inputValueCopy = tmpItem.inputValue;
              tmpItem.value = inputValueCopy;
              tmpItem.label = inputValueCopy;
              tmpItem.full = inputValueCopy;
              tmpItem.custom = true;
            }
            delete tmpItem.inputValue;
          }
        });
      }
    }

    let recursionArray = props.recursionArray !== undefined ? [...props.recursionArray] : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }

    // Native ConstExpr
    if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
      const rootNativeConstExprId = recursionArray.shift();
      // Using nativeConstExprProps from state
      // nativeConstExprProps: {
      //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
      //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
      // }
      let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
      if(fullTreeConstExpr === undefined) {
        fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
          recursionArray: recursionArray,
          object: item,
          currentConstExprId: props.constExprId,
          fieldName: props.name,
          objectName: 'item'
        });
      }
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                    constExprs: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                      [rootNativeConstExprId] : fullTreeConstExpr
                    }
                  }
                }
              }
            }
          }
        }
      }, () => {
        let currNativeConstExpr = undefined;
        // For the user that took action at first place
        if(props.doSave !== undefined ? props.doSave : true) {
          currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
            rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
          });
        }
        // others to sync (clients)
        else {
          const clinetRecursionArray =
            this.state.nativeConstExprProps.recursionArray !== undefined ?
            [...this.state.nativeConstExprProps.recursionArray] :
            undefined;
          if(clinetRecursionArray !== undefined) {
            clinetRecursionArray.shift();
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
              recursionArray: clinetRecursionArray,
            });
          }
        }
        this.setState({
          currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleInputChangeIntermediateConstExprCallBack',
              argument: newProps,
            });
          }
        });
      });
    } // Native ConstExpr - ends
    else {

      var objToHold = {};
      // Case Select value
      if(item !== null) {
        // Hold the object to change
        objToHold = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId][props.name]); // Copy

        // Case where some value is returned
        // In that case it is an object formed like this {value: <TEXT>, label: <TEXT>}
        if(item.value !== undefined) {
          // Changing object
          objToHold.item = item;
          delete objToHold.error_text;
        }
        // Case where no value is returned
        // In that case it is not an object but an ARRAY
        else {
          // Case the array is not empty (multivalues)
          if(item.length > 0) {
            // Changing object
            objToHold.item = item;
            delete objToHold.error_text;
          }
          // Case the array is empty (propmt error)
          else {
            // Changing object
            objToHold.error_text = 'This field is required';
            objToHold.item = '';
          }
        }
      }
      // Case X button pressed
      else {
        // Changing object
        objToHold.error_text = 'This field is required';
        objToHold.item = '';
      }
      //this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId][props.name]
      // Set changed object back to state
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                    constExprs: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                      [props.constExprId] : {
                        ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId],
                        [props.name]: objToHold
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }, async () => {
        // Validating the relation field of any child native additional
        if(this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].constExprIds !== undefined) {
          for (const subConstExprId of this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].constExprIds) {
            let childErrorMsg =
              await this.handleValidateSelectedValuesOfNativeAdditionalCallBack({
                constExpr:this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].constExprs[subConstExprId],
                parentConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId],
                type: 'relation'
              });
            // Setting the error label for the relation of the child
            let childRelation = {...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].constExprs[subConstExprId].relation};
            if(childErrorMsg !== undefined)
              childRelation.error_text = childErrorMsg;
            else
              delete childRelation.error_text;

            this.setState({
              mappings: {
                ...this.state.mappings,
                [props.mappingId]: {
                  ...this.state.mappings[props.mappingId],
                  links: {
                    ...this.state.mappings[props.mappingId].links,
                    [props.linkId]: {
                      ...this.state.mappings[props.mappingId].links[props.linkId],
                      targetIntermediates: {
                        ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                        [props.intermediateId]: {
                          ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                          constExprs: {
                            ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                            [props.constExprId] : {
                              ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId],
                              constExprs: {
                                ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].constExprs,
                                [subConstExprId]: {
                                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].constExprs[subConstExprId],
                                  relation: childRelation
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        }
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Validating next fields (if there are any)
          // Link additional's relation has been changed
          if(this.state.validateSelections) {
            if(props.name === 'relation') {
              await this.handleValidateSelectedValuesOfAdditionalEntityCallBack({
                mappingId: props.mappingId,
                linkId: props.linkId,
                intermediateId: props.intermediateId,
                constExprId: props.constExprId,
              });
            }
          }
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleInputChangeIntermediateConstExprCallBack',
            argument: newProps,
          });
        }
      });
    }
  }

  // Checking the whole tree to determine if there is at least one constant
  // expression added in any domain, link or intermediate and returns true if
  // there is or false otherwise.
  thereIsAtLeastOneConstExprInTheWholeModel = props => {
    let leaveEverywhereMoreSpace = false;

    this.state.mappingIds.forEach(mappingId => {
      let mapping = this.state.mappings[mappingId];
  		if(mapping.constExprIds.length > 0) {
        leaveEverywhereMoreSpace = true;
      }
      if(leaveEverywhereMoreSpace === false) {
        mapping.linkIds.forEach(linkId => {
          let link = mapping.links[linkId];
      		if(link.constExprIds.length > 0) {
            leaveEverywhereMoreSpace = true;
          }
          if(leaveEverywhereMoreSpace === false) {
            link.targetIntermediateIds.forEach(targetIntermediateId => {
              let targetIntermediate = link.targetIntermediates[targetIntermediateId];
          		if(targetIntermediate.constExprIds.length > 0) {
                leaveEverywhereMoreSpace = true;
              }
          	});
          }
      	});
      }
  	});
    return leaveEverywhereMoreSpace;
  }

  // **********************************
  // Native Cosntant Expression - Start
  // **********************************

  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>}
  handleDisplayConstExpr = props => () => {
    this.handleDisplayConstExprCallBack(props);
  }

  // CallBack
  // props: {
  //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
  //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
  // }
  // *  Optional (can be undefined)
  // ** Optional - Case of native constant expression
  handleDisplayConstExprCallBack = async props => {
    const constExpr = props.constExpr;
    // Open Dialog
    this.setState({
      nativeConstExprDialogOpen: true,
      currNativeConstExpr: Object.assign({}, constExpr), // Copy,
      nativeConstExprProps: props,
    });
  }

  // CallBack
  // props: {
  //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
  //    recursionArray: <ArrayOfIntegers>
  // }
  // *  Optional (they have -1 value when not defined)
  handleAddNewNativeConstExpr = props => () => {
    this.handleAddNewNativeConstExprCallBack(props);
  }

  // CallBack
  handleAddNewNativeConstExprCallBack = props => {
    console.log('props:');
    console.log(props);
    console.log(this.state.nativeConstExprProps);
    console.log('this.state.nativeConstExprProps');
    const mappingId = props.mappingId;
    const linkId = props.linkId;
    const intermediateId = props.intermediateId;
    const constExprId = props.constExprId;
    const recursionArray = [...props.recursionArray];
    let parentConstExp = {...props.constExpr};

    const successMsg = 'The native additional was updated successfully';

    const newConstExprId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    let newConstExpr = {
      id: newConstExprId,
      entity: {
        item: '',
        isLoading: false
      },
      relation: {
        item: '',
        isLoading: false
      }
    };

    if(parentConstExp.constExprs === undefined) {
      parentConstExp.constExprs = {};
      parentConstExp.constExprIds = [];
    }
    parentConstExp.constExprs[newConstExpr.id] = newConstExpr;
    parentConstExp.constExprIds.push(newConstExprId);

    if(!recursionArray.includes(constExprId))
      recursionArray.push(constExprId);

    this.setState({
      nativeConstExprProps: {
        ...this.state.nativeConstExprProps,
        recursionArray: [...recursionArray],
      }
    }, async () => {

      let rootNativeConstExprId = recursionArray.shift();
      // Case where the rootNativeConstExpr is the very root one
      // if(rootNativeConstExprId === undefined)
      //   rootNativeConstExprId = constExprId;

      // Native Additional for Intermediate
      if(intermediateId !== undefined && intermediateId !== -1) {
        let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
        if(fullTreeConstExpr === undefined) {
          fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
            rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
            object: parentConstExp,
            currentConstExprId: props.constExprId,
            fieldName: 'constExpr',
          });
        }

        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  targetIntermediates: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                    [props.intermediateId]: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                      constExprs: {
                        ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                        [rootNativeConstExprId] : fullTreeConstExpr
                      }
                    }
                  }
                }
              }
            }
          }
        }, () => {
          let currNativeConstExpr = undefined;
          // For the user that took action at first place
          if(props.doSave !== undefined ? props.doSave : true) {
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
            });
          }
          // others to sync (clients)
          else {
            const clinetRecursionArray =
              this.state.nativeConstExprProps.recursionArray !== undefined ?
              [...this.state.nativeConstExprProps.recursionArray] :
              undefined;
            if(clinetRecursionArray !== undefined) {
              clinetRecursionArray.shift();
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
                recursionArray: clinetRecursionArray,
              });
            }
          }
          this.setState({
            currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
              newProps.fullTreeConstExpr = fullTreeConstExpr;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleAddNewNativeConstExprCallBack',
                argument: newProps
              });
            }
          });
        });
      } // Native Additional for Intermediate ends

      // Native Additional for link
      else if(linkId !== undefined && linkId !== -1) {
        let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
        if(fullTreeConstExpr === undefined) {
          fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
            rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
            object: parentConstExp,
            currentConstExprId: props.constExprId,
            fieldName: 'constExpr',
          });
        }

        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  constExprs: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                    [rootNativeConstExprId] : fullTreeConstExpr
                  }
                }
              }
            }
          }
        }, () => {
          let currNativeConstExpr = undefined;
          // For the user that took action at first place
          if(props.doSave !== undefined ? props.doSave : true) {
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
            });
          }
          // others to sync (clients)
          else {
            const clinetRecursionArray =
              this.state.nativeConstExprProps.recursionArray !== undefined ?
              [...this.state.nativeConstExprProps.recursionArray] :
              undefined;
            if(clinetRecursionArray !== undefined) {
              clinetRecursionArray.shift();
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
                recursionArray: clinetRecursionArray,
              });
            }
          }

          this.setState({
            currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
              newProps.fullTreeConstExpr = fullTreeConstExpr;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleAddNewNativeConstExprCallBack',
                argument: newProps
              });
            }
          });
        });
      } // Native Additional for link ends

      // Native Additional for Mapping
      else {
        let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
        if(fullTreeConstExpr === undefined) {
          fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
            rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
            recursionArray: recursionArray,
            object: parentConstExp,
            currentConstExprId: props.constExprId,
            fieldName: 'constExpr',
          });
        }

        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              constExprs: {
                ...this.state.mappings[props.mappingId].constExprs,
                [rootNativeConstExprId] : fullTreeConstExpr
              }
            }
          },
        }, () => {
          let currNativeConstExpr = undefined;
          // For the user that took action at first place
          if(props.doSave !== undefined ? props.doSave : true) {
            currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
              rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
            });
          }
          // others to sync (clients)
          else {
            const clinetRecursionArray =
              this.state.nativeConstExprProps.recursionArray !== undefined ?
              [...this.state.nativeConstExprProps.recursionArray] :
              undefined;
            if(clinetRecursionArray !== undefined) {
              clinetRecursionArray.shift();
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
                recursionArray: clinetRecursionArray,
              });
            }
          }

          this.setState({
            currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
              newProps.fullTreeConstExpr = fullTreeConstExpr;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleAddNewNativeConstExprCallBack',
                argument: newProps
              });
            }
          });
        });
      }
    });
  }

  // props: {
  //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
  //    recursionArray: recursionArray // DO I NEED THIS
  // }
  handleGoToPreviousNativeConstExpr = props => () => {
    console.log('handleGoToPreviousNativeConstExpr props:');
    //console.log(props);
    if(props.recursionArray.length > 0) {
      const rootNativeConstExprId = props.recursionArray[0]; // The very root constant expression
      let recursionArray = [...this.state.nativeConstExprProps.recursionArray];
      recursionArray.pop(); // Removing the very last one
      // Changing the native ConstExpr Properties (to be persisted in state later on)
      let nativeConstExprProps = {...this.state.nativeConstExprProps};
      nativeConstExprProps.recursionArray = recursionArray;
      nativeConstExprProps.level = nativeConstExprProps.level-1;
      // Using a copy of the recursionArray, to be mutated by removing the very root (first item)
      // without ruining the nativeConstExprProps stored n state
      let recursionWithoutRootArray = [...recursionArray];
      recursionWithoutRootArray.shift();
      let currNativeConstExpr = undefined;
      if(props.intermediateId !== undefined && props.intermediateId !== -1) {
        // Finding the current native constant expression (to be displayed)
        currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[rootNativeConstExprId],
          recursionArray: recursionWithoutRootArray
        });
      }
      else if(props.linkId !== undefined && props.linkId !== -1) {
        // Finding the current native constant expression (to be displayed)
        currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[rootNativeConstExprId],
          recursionArray: recursionWithoutRootArray
        });
      }
      else if(props.mappingId !== undefined && props.mappingId !== -1) {
        // Finding the current native constant expression (to be displayed)
        currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
          rootConstExpr: this.state.mappings[props.mappingId].constExprs[rootNativeConstExprId],
          recursionArray: recursionWithoutRootArray
        });
      }
      if(currNativeConstExpr !== undefined) {
        this.setState({
          currNativeConstExpr: currNativeConstExpr,
          nativeConstExprProps: nativeConstExprProps,
        }, () => {
          // console.log('currNativeConstExpr');
          // console.log(currNativeConstExpr);
          // console.log('nativeConstExprProps');
          // console.log(nativeConstExprProps);
        });
      }
    }
  }

  onNativeConstExprToggleFullScreen = () => {
    this.setState({
      nativeConstExprDialogFullScreen: !this.state.nativeConstExprDialogFullScreen
    });
  }

  // props: {actionType: '', newTabValue*: <text>}
  // * only available if actionType is changeTab
  handleCheckWhetherToLeaveNativeConstExprDialog = props => () => {
    this.handleCheckWhetherToLeaveNativeConstExprDialogDialogCallBack(props);
  }

  handleCheckWhetherToLeaveNativeConstExprDialogDialogCallBack = props => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.nativeConstExprChanged) {
      if(props.actionType === 'close') {
        this.setState({
          confirmCloseNativeConstExprSnackbarShown : true
        });
      }
    }
    else {
      // Close the dialog
      if(props.actionType === 'close') {
        this.handleCloseNativeConstExprDialog();
      }
    }
  }

  handleCloseNativeConstExprDialog = () => {
    this.setState({
        nativeConstExprDialogOpen: false,
    }, () => {
      this.setState({
          currNativeConstExpr: '', // Copy,
          nativeConstExprProps: {level: 0},
      });
    });
  }

  // Using nativeConstExprProps from the state
  // this.state.nativeConstExprProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  // props: {variable: <Object>}
  handleSaveNativeConstExpr = props => () => {
    this.handleSaveNativeConstExprCallBack(props);
  }

  // Using nativeConstExprProps from the state
  // this.state.nativeConstExprProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  // props: {variable: <Object>}
  handleSaveNativeConstExprCallBack = props => {
    // ...
  }

  // props: {mappingId: <TEXT>, linkId*: <TEXT> | -1, intermediateId*: <TEXT> | -1, constExprId: <TEXT>, level: <Integer>}
  handleDeleteNativeConstExpr = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    if(props.intermediateId !== -1 ) {
      this.handleDeleteIntermediateConstExprCallBack(props);
    }
    else if(props.linkId !== -1 ) {
      this.handleDeleteLinkConstExprCallBack(props);
    }
    else {
      this.handleDeleteMappingConstExprCallBack(props);
    }
  }

  // props: {
  //  name: 'relation | entity',
  //  mappingId: <TEXT>,
  //  linkId*: <TEXT> | -1,
  //  intermediateId*: <TEXT> | -1,
  //  constExprId: <TEXT>}
  // * This is "either -1" or text in case of link or intermediate
  handleInputChangeNativeConstExpr = props => (event, item) => {
    props.event = null;
    props.item = item;
    if(props.intermediateId !== -1 ) {
      this.handleInputChangeIntermediateConstExprCallBack(props);
    }
    else if(props.linkId !== -1 ) {
      this.handleInputChangeLinkConstExprCallBack(props);
    }
    else {
      this.handleInputChangeMappingConstExprCallBack(props);
    }
  }

  // props: {
  //         name: 'relation | entity',
  //         mappingId: <TEXT>,
  //         linkId*: <TEXT> | -1,
  //         intermediateId*: <TEXT> | -1,
  //         constExprId: <TEXT>,
  //         level: <Integer>,
  //         event: <Object>,
  //         item: <Object>
  // }
  // * This is "either -1" or text in case of link or intermediate
  handleInputChangeNativeConstExprButton = props => () => {
    // Case Single item (not an array of items)
    if(props.item.value !== undefined || props.item.inputValue !== undefined) {
      //const item = Object.assign({}, props.item);
      let customItem = {
        inputValue: props.item.value,
        label: 'Add \"' + props.item.value + '\"'
      }
      props.item = customItem;
    }
    // Case Array
    else {
      let customItem = [];
      props.item.forEach(tmpItem => {
        let customTmpItem = {
          inputValue: tmpItem.value,
          label: 'Add \"' + tmpItem.value + '\"'
        }
        customItem.push(customTmpItem);
      });
      props.item = customItem;
    }
    if(props.intermediateId !== -1 ) {
      this.handleInputChangeIntermediateConstExprCallBack(props);
    }
    else if(props.linkId !== -1 ) {
      this.handleInputChangeLinkConstExprCallBack(props);
    }
    else {
      this.handleInputChangeMappingConstExprCallBack(props);
    }
  }

  // ***********************************
  // Constant Expression Functions - End
  // ***********************************

  // Uses the list of definitions and pre-selects the respective definition
  // based on the defined declaration (if there is one)
  // props: {type: 'instance | label', capType: 'Instance | Label'}
  setPredefinedGeneratorDefinion = props => {
    if(this.state['curr' + props.capType + 'GeneratorDeclaration'].name !== undefined) {
      let generatorDefinitionIndex = this.state[props.type + 'GeneratorDefinitions'].findIndex(
        obj => obj.name.toLowerCase() === this.state['curr' + props.capType + 'GeneratorDeclaration'].name.toLowerCase()
      );

      if(generatorDefinitionIndex !== -1) {
        this.setState({
            ['curr' + props.capType + 'GeneratorDefinitionSelected']: this.state[props.type + 'GeneratorDefinitions'][generatorDefinitionIndex],
        });
      }
      // When not found in the list
      else {
        this.setState({
            ['curr' + props.capType + 'GeneratorDefinitionSelected']: '',
        });
      }
    }
    // When there is no declaration set to the target yet
    else {
      this.setState({
          ['curr' + props.capType + 'GeneratorDefinitionSelected']: '',
      });
    }
  }

  // Getting the list of all definitions and sets separately in the State
  // a list of those to be use for instance generator declarations
  refreshListOfGeneratorDefinitions = async () => {
    this.setState({
      instanceGeneratorDefinitions:
        this.state.currMappingProject.generatorDefinitions !== null ?
        this.state.currMappingProject.generatorDefinitions.filter(function(definition) {
          return (definition.type === 'instance' || definition.type === 'any');
        }) :
        [],
      labelGeneratorDefinitions:
        this.state.currMappingProject.generatorDefinitions !== null ?
        this.state.currMappingProject.generatorDefinitions.filter(function(definition) {
          return (definition.type === 'label' || definition.type === 'any');
        }) :
        [],
    });
  }

  // props: {mappingId: <Text>}
  handleMappingUseGenerator = props => () => {
    this.handleMappingUseGeneratorCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>}
  handleMappingUseGeneratorCallBack = async props => {
    console.log('handleMappingUseGenerator');
    // Re-loading the list of generator definitions from the mapping-project
    await this.refreshListOfGeneratorDefinitions();
    // Load Xpaths (as options in case of arg type: XPath)
    const xpaths = await this.getAvailableXpathsForGeneratorDeclaration(props);
    // Open Dialog
    this.setState({
        generatorDialogOpen: true,
        currGeneratorProps: props,
        currInstanceGeneratorDeclaration: this.state.mappings[props.mappingId].domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
                                          this.state.mappings[props.mappingId].domainTargetEntity.instanceGeneratorDeclaration :
                                          '',
        currLabelGeneratorDeclarations: this.state.mappings[props.mappingId].domainTargetEntity.labelGeneratorDeclarations !== undefined ?
                                          this.state.mappings[props.mappingId].domainTargetEntity.labelGeneratorDeclarations :
                                          [],
        showFixMissingDefinitionErrorView: false, // normal view
        generatorDialogXpaths: xpaths,
    }, () => {
      // Set predefined value for the list of definitions wrt the instance generator
      this.setPredefinedGeneratorDefinion({type: 'instance', capType: 'Instance'});
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseGenerator = props => () => {
    this.handleLinkUseGeneratorCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseGeneratorCallBack = async props => {
    console.log('handleLinkUseGenerator');
    // Re-loading the list of instance generator definitions from the mapping-project
    await this.refreshListOfGeneratorDefinitions();
    // Load Xpaths (as options in case of arg type: XPath)
    const xpaths = await this.getAvailableXpathsForGeneratorDeclaration(props);
    // Open Dialog
    this.setState({
        generatorDialogOpen: true,
        currGeneratorProps: props,
        currInstanceGeneratorDeclaration: this.state.mappings[props.mappingId].links[props.linkId].targetEntity.instanceGeneratorDeclaration !== undefined ?
                                          this.state.mappings[props.mappingId].links[props.linkId].targetEntity.instanceGeneratorDeclaration :
                                          '',
        currLabelGeneratorDeclarations: this.state.mappings[props.mappingId].links[props.linkId].targetEntity.labelGeneratorDeclarations !== undefined ?
                                          this.state.mappings[props.mappingId].links[props.linkId].targetEntity.labelGeneratorDeclarations :
                                          [],
        showFixMissingDefinitionErrorView: false, // normal view
        generatorDialogXpaths: xpaths,
    }, () => {
      // Set predefined value for the list of definitions wrt the instance generator
      this.setPredefinedGeneratorDefinion({type: 'instance', capType: 'Instance'});
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleTargetIntermediateUseGenerator = props => () => {
    this.handleTargetIntermediateUseGeneratorCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleTargetIntermediateUseGeneratorCallBack = async props => {
    console.log('handleTargetIntermediateUseGenerator');
    // Re-loading the list of instance generator definitions from the mapping-project
    await this.refreshListOfGeneratorDefinitions();
    // Load Xpaths (as options in case of arg type: XPath)
    const xpaths = await this.getAvailableXpathsForGeneratorDeclaration(props);
    // Open Dialog
    this.setState({
        generatorDialogOpen: true,
        currGeneratorProps: props,
        currInstanceGeneratorDeclaration: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.instanceGeneratorDeclaration !== undefined ?
                                          this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.instanceGeneratorDeclaration :
                                          '',
        currLabelGeneratorDeclarations: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.labelGeneratorDeclarations !== undefined ?
                                          this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.labelGeneratorDeclarations :
                                          [],
        showFixMissingDefinitionErrorView: false, // normal view
        generatorDialogXpaths: xpaths,
    }, () => {
      // Set predefined value for the list of definitions wrt the instance generator
      this.setPredefinedGeneratorDefinion({type: 'instance', capType: 'Instance'});
    });
  }

  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>}
  handleConstExprUseGenerator = props => () => {
    this.handleConstExprUseGeneratorCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>}
  // * Optional (can be undefined)
  handleConstExprUseGeneratorCallBack = async props => {
    console.log('handleConstExprUseGenerator');
    // Re-loading the list of instance generator definitions from the mapping-project
    await this.refreshListOfGeneratorDefinitions();
    let tmpInstanceGeneratorDeclaration = '';
    let tmpLabelGeneratorDeclarations = '';
    if(props.intermediateId !== undefined && props.intermediateId !== -1) {
      // Native ConstExpr
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpInstanceGeneratorDeclaration = constExpr !== undefined ?
                                          (
                                            constExpr.entity.instanceGeneratorDeclaration !== undefined ?
                                            constExpr.entity.instanceGeneratorDeclaration :
                                            ''
                                          ) :
                                          '';
        tmpLabelGeneratorDeclarations = constExpr !== undefined ?
                                        (
                                          constExpr.entity.labelGeneratorDeclarations !== undefined ?
                                          constExpr.entity.labelGeneratorDeclarations :
                                          []
                                        ) :
                                        [];
      }
      else {
        tmpInstanceGeneratorDeclaration = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.instanceGeneratorDeclaration !== undefined ?
                                          this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.instanceGeneratorDeclaration :
                                          '';
        tmpLabelGeneratorDeclarations = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.labelGeneratorDeclarations !== undefined ?
                                        this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.labelGeneratorDeclarations :
                                        [];
      }
    }
    else if(props.linkId !== undefined && props.linkId !== -1) {
      // Native ConstExpr
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpInstanceGeneratorDeclaration = constExpr !== undefined ?
                                          (
                                            constExpr.entity.instanceGeneratorDeclaration !== undefined ?
                                            constExpr.entity.instanceGeneratorDeclaration :
                                            ''
                                          ) :
                                          '';
        tmpLabelGeneratorDeclarations = constExpr !== undefined ?
                                        (
                                          constExpr.entity.labelGeneratorDeclarations !== undefined ?
                                          constExpr.entity.labelGeneratorDeclarations :
                                          []
                                        ) :
                                        [];
      }
      else {
        tmpInstanceGeneratorDeclaration = this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.instanceGeneratorDeclaration !== undefined ?
                                          this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.instanceGeneratorDeclaration :
                                          '';
        tmpLabelGeneratorDeclarations = this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.labelGeneratorDeclarations !== undefined ?
                                        this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.labelGeneratorDeclarations :
                                        [];
      }
    }
    else if(props.mappingId !== undefined && props.mappingId !== -1) {
      // Native ConstExpr
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpInstanceGeneratorDeclaration = constExpr !== undefined ?
                                          (
                                            constExpr.entity.instanceGeneratorDeclaration !== undefined ?
                                            constExpr.entity.instanceGeneratorDeclaration :
                                            ''
                                          ) :
                                          '';
        tmpLabelGeneratorDeclarations = constExpr !== undefined ?
                                        (
                                          constExpr.entity.labelGeneratorDeclarations !== undefined ?
                                          constExpr.entity.labelGeneratorDeclarations :
                                          []
                                        ) :
                                        [];
      }
      else {
        tmpInstanceGeneratorDeclaration = this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.instanceGeneratorDeclaration !== undefined ?
                                          this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.instanceGeneratorDeclaration :
                                          '';
        tmpLabelGeneratorDeclarations = this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.labelGeneratorDeclarations !== undefined ?
                                        this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.labelGeneratorDeclarations :
                                        [];
      }
    }
    // Load Xpaths (as options in case of arg type: XPath)
    const xpaths = await this.getAvailableXpathsForGeneratorDeclaration(props);
    // Open Dialog
    this.setState({
        generatorDialogOpen: true,
        currGeneratorProps: props,
        currInstanceGeneratorDeclaration: tmpInstanceGeneratorDeclaration,
        currLabelGeneratorDeclarations: tmpLabelGeneratorDeclarations,
        showFixMissingDefinitionErrorView: false, // normal view
        generatorDialogXpaths: xpaths,
    }, () => {
      // Set predefined value for the list of definitions wrt the instance generator
      this.setPredefinedGeneratorDefinion({type: 'instance', capType: 'Instance'});
    });
  }

  // Using: this.state.currMappingProject.generatorDefinitions
  //        this.state.fileMetadataObjectList.sourceFiles
  //        this.state.fileMetadataObjectList.targetFiles
  // props: {prefix: <Text>, namespace: <Text>, doSave*: <Boolean>}
  // *Optional parameter for saving or not the fileMetadata
  handleUpdateNamespaceEveryWhere = props => {

    let generatorDefinitions = props.generatorDefinitions !== undefined ? props.generatorDefinitions : undefined;
    if(generatorDefinitions === undefined) {
      generatorDefinitions = this.state.currMappingProject.generatorDefinitions;
    }
    let sourceFiles = props.sourceFiles !== undefined ? props.sourceFiles : undefined;
    if(sourceFiles === undefined) {
      sourceFiles = this.state.fileMetadataObjectList.sourceFiles;
    }
    let targetFiles = props.targetFiles !== undefined ? props.targetFiles : undefined;
    if(targetFiles === undefined) {
      targetFiles = this.state.fileMetadataObjectList.targetFiles;
    }

    // Handling the list of all the definitions of the currMappingProject in the state
    var defArrayIndices = generatorDefinitions.map(
      (obj, i) => obj.prefix !== undefined && obj.prefix !== null ? (obj.prefix === props.prefix ? i : '') : ''
    ).filter(String);

    // Updating the Array of definitions
    let defArray = generatorDefinitions.slice(); // Create a copy
    for (let index of defArrayIndices) {
      defArray[index].namespace = props.namespace
    }

    // Update the Array of Source FileMetadata
    let fileMetadataChanged = false; // Flag used to decide whether to save/relodad fileMetadata services
    let sourceFileMetadataArray = sourceFiles.slice(); // Create a copy
    let sourceFileMetadataArrayIndex = 0;
    for (let fileMetadata of sourceFileMetadataArray) {
      let namespacePrefixListIndex = 0;
      if(fileMetadata.namespacePrefixList !== null) {
        for (let namespacePrefix of fileMetadata.namespacePrefixList) {
          if(namespacePrefix.label === props.prefix) {
            namespacePrefix.value = props.namespace;
            fileMetadataChanged = true;
          }
          namespacePrefixListIndex = namespacePrefixListIndex + 1;
        }
      }
      sourceFileMetadataArrayIndex = sourceFileMetadataArrayIndex + 1;
    }

    // Update the Array of Target FileMetadata
    let targetFileMetadataArray = targetFiles.slice(); // Create a copy
    let targetFileMetadataArrayIndex = 0;
    for (let fileMetadata of targetFileMetadataArray) {
      let namespacePrefixListIndex = 0;
      for (let namespacePrefix of fileMetadata.namespacePrefixList) {
        if(namespacePrefix.label === props.prefix) {
          namespacePrefix.value = props.namespace;
          fileMetadataChanged = true;
        }
        namespacePrefixListIndex = namespacePrefixListIndex + 1;
      }
      targetFileMetadataArrayIndex = targetFileMetadataArrayIndex + 1;
    }

    // Since if prefix have been changed it will save the MappingProject,
    // this flag will be used to hold that information and will be returned
    // by this function
    let mappingProjectIsSaved = false;
    // Apply changes in the state
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        generatorDefinitions: defArray,
      },
      generatorDefinitionChanged:
        props.doSave !== undefined ?
        (
          props.doSave ?
          false :
          this.state.generatorDefinitionChanged
        ) :
        false,
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        sourceFiles: sourceFileMetadataArray,
        targetFiles: targetFileMetadataArray,
      }
    }, async () => {
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        if(props.doPersist !== undefined ? props.doPersist : true) {
          if(defArrayIndices.length > 0) {
            await mainService.saveMappingProject(this.state.currMappingProject);
            this.showSuccessSnackBar({msg: 'The generator "Definition" was updated successfully'});
            mappingProjectIsSaved = true;
          }

          // Only Save and reload FileMetadata (along with reasoner's services) if necesary
          if(fileMetadataChanged) {
            await this.handleSaveFileMetadataDialog();
            this.showSuccessSnackBar({msg: 'All "Source" & "Target" file metadata were updated successfully'});
          }
        }
        // Calling afterCllBack function if there is any in the props
        // props might include the folowing object, which is used if found
        // afterCallBack: {
        //   function: this.props.handleSaveInstanceGeneratorDeclaration | this.props.handleSaveLabelGeneratorDeclaration,
        //   props: {
        //     instanceGeneratorDeclaration: props.instanceGeneratorDeclaration | props.labelGeneratorDeclaration,
        //     instanceGeneratorDefinition: props.generatorDefinition | labelGeneratorDefinition,
        //   }
        // }
        if(props.afterCallBack !== undefined) {
          props.afterCallBack.function(props.afterCallBack.props);
        }
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        newProps.generatorDefinitions = this.state.currMappingProject.generatorDefinitions;
        newProps.sourceFiles = this.state.fileMetadataObjectList.sourceFiles;
        newProps.targetFiles = this.state.fileMetadataObjectList.targetFiles;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleUpdateNamespaceEveryWhere',
          argument: newProps
        });
      }
    });
    return mappingProjectIsSaved;
  }

  // Using currGeneratorProps from the state
  // this.state.currGeneratorProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  // props: {
  //    instanceGeneratorDeclaration: <Object>,
  //    instanceGeneratorDefinition: <Object>,
  //    notify*: <Boolean>,                       // Boolean value. When false, no success message will be displayd (default is true)
  //    doPersist*: <Boolean>,
  // }
  // * doPersist is an optional argument with default to true (saves to the database)
  handleSaveInstanceGeneratorDeclaration = async props => {
    let generatorProps = props.generatorProps !== undefined ? props.generatorProps : undefined;
    if(generatorProps === undefined) {
      generatorProps = this.state.currGeneratorProps;
    }
    let recursionArray = props.recursionArray !== undefined ? props.recursionArray : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }
    const notify = props.notify !== undefined ? props.notify : true;
    const doPersist = props.doPersist !== undefined ? props.doPersist : true;
    let newDeclaration = props.instanceGeneratorDeclaration !== undefined ?
                         Object.assign({}, props.instanceGeneratorDeclaration) :
                         undefined; // Copy
    if(newDeclaration !== undefined && props.instanceGeneratorDefinition !== undefined && props.instanceGeneratorDefinition !== '') {
      // Case: Not custom
      if(newDeclaration.custom !== undefined ? !newDeclaration.custom : true) {
        newDeclaration.pattern = props.instanceGeneratorDefinition.pattern;
      }
      if(newDeclaration.definitionId === undefined || newDeclaration.definitionId === null) {
        newDeclaration.definitionId = props.instanceGeneratorDefinition.id;
      }
    }
    const successMsg = 'The instance generator declaration was updated successfully';
    // Don't apply in case of syncing (doSave: false)
    if(props.doSave !== undefined ? props.doSave : true) {
      // Flag declaration as not changed
      this.setState({
        generatorDeclarationChanged: false,
      });
    }

    // Intermediate
    if(generatorProps.intermediateId !== undefined && generatorProps.intermediateId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: newDeclaration,
              currentConstExprId: this.state.currGeneratorProps.constExprId,
              fieldName: 'entity',
              objectName: 'instanceGeneratorDeclaration'
            });
          }
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                  ...this.state.mappings[generatorProps.mappingId].links,
                  [generatorProps.linkId]: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates,
                      [generatorProps.intermediateId]: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId],
                        constExprs: {
                          ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs,
                          [rootNativeConstExprId] : fullTreeConstExpr
                        }
                      }
                    }
                  }
                }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.generatorProps = this.state.currGeneratorProps;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveInstanceGeneratorDeclaration',
                  argument: newProps
                });
              }
            });
          });

        } // Native ConstExpr - ends

        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                  ...this.state.mappings[generatorProps.mappingId].links,
                  [generatorProps.linkId]: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates,
                      [generatorProps.intermediateId]: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId],
                        constExprs: {
                          ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs,
                          [generatorProps.constExprId] : {
                            ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[generatorProps.constExprId],
                            entity: {
                              ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[generatorProps.constExprId].entity,
                              instanceGeneratorDeclaration: newDeclaration
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              if(doPersist) {
                await this.saveMappingTreeModel();
              }
              if(notify) {
                this.showSuccessSnackBar({msg: successMsg});
              }
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.generatorProps = this.state.currGeneratorProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveInstanceGeneratorDeclaration',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to intermediate
        this.setState({
          mappings: {
            ...this.state.mappings,
            [generatorProps.mappingId]: {
              ...this.state.mappings[generatorProps.mappingId],
              links: {
                ...this.state.mappings[generatorProps.mappingId].links,
                [generatorProps.linkId]: {
                  ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                  targetIntermediates: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates,
                    [generatorProps.intermediateId]: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId],
                      targetEntity: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].targetEntity,
                        instanceGeneratorDeclaration: newDeclaration
                      }
                    }
                  }
                }
              }
            }
          }
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            if(doPersist) {
              await this.saveMappingTreeModel();
            }
            if(notify) {
              this.showSuccessSnackBar({msg: successMsg});
            }
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.generatorProps = this.state.currGeneratorProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveInstanceGeneratorDeclaration',
              argument: newProps
            });
          }
        });
      }
    }

    // Link
    else if(generatorProps.linkId !== undefined && generatorProps.linkId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: newDeclaration,
              currentConstExprId: this.state.currGeneratorProps.constExprId,
              fieldName: 'entity',
              objectName: 'instanceGeneratorDeclaration'
            });
          }
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                    ...this.state.mappings[generatorProps.mappingId].links,
                    [generatorProps.linkId]: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                      constExprs: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs,
                        [rootNativeConstExprId] : fullTreeConstExpr
                      }
                    }
                  }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.generatorProps = this.state.currGeneratorProps;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveInstanceGeneratorDeclaration',
                  argument: newProps
                });
              }
            });
          });

        } // Native ConstExpr - ends

        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                  ...this.state.mappings[generatorProps.mappingId].links,
                  [generatorProps.linkId]: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                    constExprs: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs,
                      [generatorProps.constExprId] : {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[generatorProps.constExprId],
                        entity: {
                          ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[generatorProps.constExprId].entity,
                          instanceGeneratorDeclaration: newDeclaration
                        }
                      }
                    }
                  }
                }
              }
            }
          }, async () =>  {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              if(doPersist) {
                await this.saveMappingTreeModel();
              }
              if(notify) {
                this.showSuccessSnackBar({msg: successMsg});
              }
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.generatorProps = this.state.currGeneratorProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveInstanceGeneratorDeclaration',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to link
        this.setState({
          mappings: {
            ...this.state.mappings,
            [generatorProps.mappingId]: {
              ...this.state.mappings[generatorProps.mappingId],
              links: {
                ...this.state.mappings[generatorProps.mappingId].links,
                [generatorProps.linkId]: {
                  ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                  targetEntity: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetEntity,
                    instanceGeneratorDeclaration: newDeclaration
                  }
                }
              }
            }
          }
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            if(doPersist) {
              await this.saveMappingTreeModel();
            }
            if(notify) {
              this.showSuccessSnackBar({msg: successMsg});
            }
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.generatorProps = this.state.currGeneratorProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveInstanceGeneratorDeclaration',
              argument: newProps
            });
          }
        });
      }
    }
    // Mapping (Domain)
    else if(generatorProps.mappingId !== undefined && generatorProps.mappingId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[generatorProps.mappingId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: newDeclaration,
              currentConstExprId: this.state.currGeneratorProps.constExprId,
              fieldName: 'entity',
              objectName: 'instanceGeneratorDeclaration'
            });
          }
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                constExprs: {
                  ...this.state.mappings[generatorProps.mappingId].constExprs,
                  [rootNativeConstExprId] : fullTreeConstExpr
                }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[generatorProps.mappingId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[generatorProps.mappingId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.generatorProps = this.state.currGeneratorProps;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveInstanceGeneratorDeclaration',
                  argument: newProps
                });
              }
            });
          });

        } // Native ConstExpr - ends

        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                constExprs: {
                  ...this.state.mappings[generatorProps.mappingId].constExprs,
                  [generatorProps.constExprId] : {
                    ...this.state.mappings[generatorProps.mappingId].constExprs[generatorProps.constExprId],
                    entity: {
                      ...this.state.mappings[generatorProps.mappingId].constExprs[generatorProps.constExprId].entity,
                      instanceGeneratorDeclaration: newDeclaration
                    }
                  }
                }
              }
            }
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              if(doPersist) {
                await this.saveMappingTreeModel();
              }
              if(notify) {
                this.showSuccessSnackBar({msg: successMsg});
              }
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.generatorProps = this.state.currGeneratorProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveInstanceGeneratorDeclaration',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to mapping
        this.setState({
          mappings: {
            ...this.state.mappings,
            [generatorProps.mappingId]: {
              ...this.state.mappings[generatorProps.mappingId],
              domainTargetEntity: {
                ...this.state.mappings[generatorProps.mappingId].domainTargetEntity,
                instanceGeneratorDeclaration: newDeclaration
              }
            }
          }
        }, async function() {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            if(doPersist) {
              await this.saveMappingTreeModel();
            }
            if(notify) {
              this.showSuccessSnackBar({msg: successMsg});
            }
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.generatorProps = this.state.currGeneratorProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveInstanceGeneratorDeclaration',
              argument: newProps
            });
          }
        });
      }
    }
  }



  handleDirectDeleteInstanceGeneratorDeclaration = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDirectDeleteInstanceGeneratorDeclarationCallBack(props.argument);
  }

  handleDirectDeleteInstanceGeneratorDeclarationCallBack = props => {
    this.setState({
      currGeneratorProps: props.generatorProps,
    }, () => {
      this.handleSaveInstanceGeneratorDeclaration({
        instanceGeneratorDeclaration: undefined,
        instanceGeneratorDefinition: undefined,
      });
    });
  }

  // props: {
  //    labelGeneratorDeclaration: <Object>,
  //    labelGeneratorDefinition: <Object>,
  //    notify*: <Boolean>,                       // Boolean value. When false, no success message will be displayd (default is true)
  //    doPersist*: <Boolean>
  // }
  //
  // * optional argument with default to true
  handleSaveLabelGeneratorDeclaration = props => {
    const doPersist = props.doPersist !== undefined ? props.doPersist : true;
    const notify = props.notify !== undefined ? props.notify : true;
    let newDeclaration = Object.assign({}, props.labelGeneratorDeclaration); // Copy

    if(newDeclaration !== undefined) {
      // Adding normal pattern in declaration
      if(newDeclaration !== undefined && props.labelGeneratorDefinition !== undefined && props.labelGeneratorDefinition !== '') {
        if(newDeclaration.custom !== undefined ? !newDeclaration.custom : true) {
          newDeclaration.pattern = props.labelGeneratorDefinition.pattern;
        }
        if(newDeclaration.definitionId === undefined || newDeclaration.definitionId === null) {
          newDeclaration.definitionId = props.labelGeneratorDefinition.id;
        }
      }

      // Setting new ID
      if(newDeclaration.id === undefined) {
        newDeclaration.id = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
      }
    }
    // New array to replace the old one
    let array = [];
    // copy currLabelGeneratorDeclarations
    array = this.state.currLabelGeneratorDeclarations.slice(); // Create a copy

    // prefLabel and Literal don't have an ID
    // if(newDeclaration.name === 'prefLabel' || newDeclaration.name === 'literal') {
    //
    // }
    let declarationIndex = this.state.currLabelGeneratorDeclarations.findIndex(
      obj => obj.id === newDeclaration.id
    );

    // Case Existing Declaration
    if(declarationIndex !== -1) {
      array[declarationIndex] = newDeclaration;
    }

    // Case New Declaration
    else {
      // Adding to the list of current label declarations the new declaration
      array.push(newDeclaration);
    }

    // Set it to the currLabelGeneratorDeclarations array
    this.setState({
      currLabelGeneratorDeclarations: array,
      generatorDeclarationChanged: false,
      // Then update the respective array in the origin entity
    }, function() {
      this.handleUpdateLabelGeneratorDeclarationsCallBack({notify: notify, doPersist: doPersist});
    });
    console.log('arrayarrayarrayarrayarrayarrayarrayarrayarrayarrayarray');
    console.log(array);
  }



  handleDirectDeleteLabelGeneratorDeclarations = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDirectDeleteLabelGeneratorDeclarationsCallBack(props.argument);
  }

  handleDirectDeleteLabelGeneratorDeclarationsCallBack = props => {
    // Holding the generatorProps and using them before storying them in the
    // currGeneratorProps state
    const generatorProps = props.generatorProps;
    // Holding the original list of labels per case
    let currLabelGeneratorDeclarations = [];
    // Intermediate
    if(generatorProps.intermediateId !== undefined && generatorProps.intermediateId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        currLabelGeneratorDeclarations = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[generatorProps.constExprId].entity.labelGeneratorDeclarations
      }
      else {
        currLabelGeneratorDeclarations = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].targetEntity.labelGeneratorDeclarations
      }
    }
    // Link
    else if(generatorProps.linkId !== undefined && generatorProps.linkId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        currLabelGeneratorDeclarations = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[generatorProps.constExprId].entity.labelGeneratorDeclarations
      }
      else {
        currLabelGeneratorDeclarations = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetEntity.labelGeneratorDeclarations
      }
    }
    // Mapping (Domain)
    else if(generatorProps.mappingId !== undefined && generatorProps.mappingId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        currLabelGeneratorDeclarations = this.state.mappings[generatorProps.mappingId].constExprs[generatorProps.constExprId].entity.labelGeneratorDeclarations
      }
      else {
        currLabelGeneratorDeclarations = this.state.mappings[generatorProps.mappingId].domainTargetEntity.labelGeneratorDeclarations
      }
    }
    this.setState({
      currGeneratorProps: generatorProps,
      currLabelGeneratorDeclarations: currLabelGeneratorDeclarations,
    }, () => {
      this.handleDeleteLabelGeneratorDeclarations({
        labelGeneratorDeclarationsToBeDeleted: props.labelGeneratorDeclarationsToBeDeleted
      });
    });
  }

  // props: {labelGeneratorDeclarationsToBeDeleted: <Array>, doPersist*: <Boolean>}
  handleDeleteLabelGeneratorDeclarations = props => {
    const doPersist = props.doPersist !== undefined ? props.doPersist : true;
    // New array to replace the old one
    let array = this.state.currLabelGeneratorDeclarations.slice(); // Create a copy

    for (const declaration of props.labelGeneratorDeclarationsToBeDeleted) {
      let originArrayIndex = array.findIndex(
        obj => obj.id === declaration.id
      );
      if(originArrayIndex !== -1) {
        array.splice(originArrayIndex, 1);
      }
    }

    // Set it to the currLabelGeneratorDeclarations array
    this.setState({
      currLabelGeneratorDeclarations: array,
      // Then update the respective array in the origin entity
    }, function() {
      this.handleUpdateLabelGeneratorDeclarationsCallBack({doPersist: doPersist});
    });
  }

  // Set new array to the respective entity of the tree model
  // props: {
  //    doPersist*: <Boolean>,
  //    notify*: <Boolean>,                       // Boolean value. When false, no success message will be displayd (default is true)
  // }
  // * optional argument with default to true
  // Using currGeneratorProps and currLabelGeneratorDeclarations from the state
  // this.state.currGeneratorProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  handleUpdateLabelGeneratorDeclarationsCallBack = async props => {

    const notify = props.notify !== undefined ? props.notify : true;

    // The props saved in state to use for determining where
    // is the array to be changed
    let generatorProps = props.generatorProps !== undefined ? props.generatorProps : undefined;
    if(generatorProps === undefined) {
      generatorProps = this.state.currGeneratorProps;
    }
    let labelGeneratorDeclarations = props.labelGeneratorDeclarations !== undefined ? props.labelGeneratorDeclarations : undefined;
    if(labelGeneratorDeclarations === undefined) {
      labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
    }

    let recursionArray = props.recursionArray !== undefined ? props.recursionArray : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }

    const doPersist = props.doPersist !== undefined ? props.doPersist : true;
    const successMsg = 'The label generator definition was updated successfully';
    // Intermediate
    if(generatorProps.intermediateId !== undefined && generatorProps.intermediateId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          //recursionArray = [...this.state.nativeConstExprProps.recursionArray];
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: labelGeneratorDeclarations, // It's an array actually
              currentConstExprId: this.state.currGeneratorProps.constExprId,
              fieldName: 'entity',
              objectName: 'labelGeneratorDeclarations'
            });
          }

          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                  ...this.state.mappings[generatorProps.mappingId].links,
                  [generatorProps.linkId]: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates,
                      [generatorProps.intermediateId]: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId],
                        constExprs: {
                          ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs,
                          [rootNativeConstExprId] : fullTreeConstExpr
                        }
                      }
                    }
                  }
                }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.generatorProps = this.state.currGeneratorProps;
                newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
                  argument: newProps
                });
              }
            });
          });

        } // Native ConstExpr - ends

        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                  ...this.state.mappings[generatorProps.mappingId].links,
                  [generatorProps.linkId]: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates,
                      [generatorProps.intermediateId]: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId],
                        constExprs: {
                          ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs,
                          [generatorProps.constExprId] : {
                            ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[generatorProps.constExprId],
                            entity: {
                              ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[generatorProps.constExprId].entity,
                              labelGeneratorDeclarations: labelGeneratorDeclarations
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database if wanted
              if(doPersist !== undefined ? doPersist : true) {
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
              }
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.generatorProps = this.state.currGeneratorProps;
              newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to intermediate
        this.setState({
          mappings: {
            ...this.state.mappings,
            [generatorProps.mappingId]: {
              ...this.state.mappings[generatorProps.mappingId],
              links: {
                ...this.state.mappings[generatorProps.mappingId].links,
                [generatorProps.linkId]: {
                  ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                  targetIntermediates: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates,
                    [generatorProps.intermediateId]: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId],
                      targetEntity: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].targetEntity,
                        labelGeneratorDeclarations: labelGeneratorDeclarations
                      }
                    }
                  }
                }
              }
            }
          }
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database if wanted
            if(doPersist !== undefined ? doPersist : true) {
              await this.saveMappingTreeModel();
              if(notify) {
                this.showSuccessSnackBar({msg: successMsg});
              }
            }
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.generatorProps = this.state.currGeneratorProps;
            newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
              argument: newProps
            });
          }
        });
      }
    }

    // Link
    else if(generatorProps.linkId !== undefined && generatorProps.linkId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: labelGeneratorDeclarations, // It's an array actually
              currentConstExprId: this.state.currGeneratorProps.constExprId,
              fieldName: 'entity',
              objectName: 'labelGeneratorDeclarations'
            });
          }
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                    ...this.state.mappings[generatorProps.mappingId].links,
                    [generatorProps.linkId]: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                      constExprs: {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs,
                        [rootNativeConstExprId] : fullTreeConstExpr
                      }
                    }
                  }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.generatorProps = this.state.currGeneratorProps;
                newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
                  argument: newProps
                });
              }
            });
          });

        } // Native ConstExpr - ends

        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                links: {
                  ...this.state.mappings[generatorProps.mappingId].links,
                  [generatorProps.linkId]: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                    constExprs: {
                      ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs,
                      [generatorProps.constExprId] : {
                        ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[generatorProps.constExprId],
                        entity: {
                          ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[generatorProps.constExprId].entity,
                          labelGeneratorDeclarations: labelGeneratorDeclarations
                        }
                      }
                    }
                  }
                }
              }
            }
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database if wanted
              if(doPersist !== undefined ? doPersist : true) {
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
              }
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.generatorProps = this.state.currGeneratorProps;
              newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to link
        this.setState({
          mappings: {
            ...this.state.mappings,
            [generatorProps.mappingId]: {
              ...this.state.mappings[generatorProps.mappingId],
              links: {
                ...this.state.mappings[generatorProps.mappingId].links,
                [generatorProps.linkId]: {
                  ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId],
                  targetEntity: {
                    ...this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetEntity,
                    labelGeneratorDeclarations: labelGeneratorDeclarations
                  }
                }
              }
            }
          }
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database if wanted
            if(doPersist !== undefined ? doPersist : true) {
              await this.saveMappingTreeModel();
              if(notify) {
                this.showSuccessSnackBar({msg: successMsg});
              }
            }
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.generatorProps = this.state.currGeneratorProps;
            newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
              argument: newProps
            });
          }
        });
      }
    }
    // Mapping (Domain)
    else if(generatorProps.mappingId !== undefined && generatorProps.mappingId !== -1) {
      if(generatorProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[generatorProps.mappingId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: labelGeneratorDeclarations, // It's an array actually
              currentConstExprId: this.state.currGeneratorProps.constExprId,
              fieldName: 'entity',
              objectName: 'labelGeneratorDeclarations'
            });
          }
          this.setState({
              mappings: {
                ...this.state.mappings,
                [generatorProps.mappingId]: {
                  ...this.state.mappings[generatorProps.mappingId],
                  constExprs: {
                    ...this.state.mappings[generatorProps.mappingId].constExprs,
                    [rootNativeConstExprId] : fullTreeConstExpr
                  }
                }
              },
            }, () => {
              let currNativeConstExpr = undefined;
              // For the user that took action at first place
              if(props.doSave !== undefined ? props.doSave : true) {
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[generatorProps.mappingId].constExprs[rootNativeConstExprId],
                  recursionArray: recursionArray,
                });
              }
              // others to sync (clients)
              else {
                const clinetRecursionArray =
                  this.state.nativeConstExprProps.recursionArray !== undefined ?
                  [...this.state.nativeConstExprProps.recursionArray] :
                  undefined;
                if(clinetRecursionArray !== undefined) {
                  clinetRecursionArray.shift();
                  currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                    rootConstExpr: this.state.mappings[generatorProps.mappingId].constExprs[rootNativeConstExprId],
                    recursionArray: clinetRecursionArray,
                  });
                }
              }
              this.setState({
                currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
              }, async () => {
                // Don't apply in case of syncing (doSave: false)
                if(props.doSave !== undefined ? props.doSave : true) {
                  // Persist to the database
                  await this.saveMappingTreeModel();
                  if(notify) {
                    this.showSuccessSnackBar({msg: successMsg});
                  }
                  // Syncing with others
                  let newProps = Object.assign({}, props); // Copy
                  newProps.doSave = false;
                  newProps.generatorProps = this.state.currGeneratorProps;
                  newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
                  newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                  newProps.fullTreeConstExpr = fullTreeConstExpr;
                  this.sendJsonObjectToSpecificUsers({
                    actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
                    argument: newProps
                  });
                }
              });
          });

        } // Native ConstExpr - ends

        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [generatorProps.mappingId]: {
                ...this.state.mappings[generatorProps.mappingId],
                constExprs: {
                  ...this.state.mappings[generatorProps.mappingId].constExprs,
                  [generatorProps.constExprId] : {
                    ...this.state.mappings[generatorProps.mappingId].constExprs[generatorProps.constExprId],
                    entity: {
                      ...this.state.mappings[generatorProps.mappingId].constExprs[generatorProps.constExprId].entity,
                      labelGeneratorDeclarations: labelGeneratorDeclarations
                    }
                  }
                }
              }
            }
          }, async () => {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database if wanted
              if(doPersist !== undefined ? doPersist : true) {
                await this.saveMappingTreeModel();
                if(notify) {
                  this.showSuccessSnackBar({msg: successMsg});
                }
              }
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.generatorProps = this.state.currGeneratorProps;
              newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to mapping
        this.setState({
          mappings: {
            ...this.state.mappings,
            [generatorProps.mappingId]: {
              ...this.state.mappings[generatorProps.mappingId],
              domainTargetEntity: {
                ...this.state.mappings[generatorProps.mappingId].domainTargetEntity,
                labelGeneratorDeclarations: labelGeneratorDeclarations
              }
            }
          }
        }, async () => {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database if wanted
            if(doPersist !== undefined ? doPersist : true) {
              await this.saveMappingTreeModel();
              if(notify) {
                this.showSuccessSnackBar({msg: successMsg});
              }
            }
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.generatorProps = this.state.currGeneratorProps;
            newProps.labelGeneratorDeclarations = this.state.currLabelGeneratorDeclarations;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleUpdateLabelGeneratorDeclarationsCallBack',
              argument: newProps
            });
          }
        });
      }
    }
  }

  // Variable Related functions

  // props: {mappingId: <Text>}
  handleMappingUseVariable = props => () => {
    this.handleMappingUseVariableCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>}
  handleMappingUseVariableCallBack = async props => {
    console.log('handleMappingUseVariable');
    // Generate History
    let tmpVariableHistory = await this.loadingVariableHistory();
    // Open Dialog
    this.setState({
      variableDialogOpen: true,
      currVariableProps: props,
      currVariable: this.state.mappings[props.mappingId].domainTargetEntity.variable !== undefined ?
                    this.state.mappings[props.mappingId].domainTargetEntity.variable :
                    {value: '', global: false},
      variableHistory: tmpVariableHistory,    // History
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseVariable = props => () => {
    this.handleLinkUseVariableCallBack(props);
  }
  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseVariableCallBack = async props => {
    console.log('handleLinkUseVariable');
    // Generate History
    let tmpVariableHistory = await this.loadingVariableHistory();
    // Open Dialog
    this.setState({
      variableDialogOpen: true,
      currVariableProps: props,
      currVariable: this.state.mappings[props.mappingId].links[props.linkId].targetEntity.variable !== undefined ?
                    this.state.mappings[props.mappingId].links[props.linkId].targetEntity.variable :
                    {value: '', global: false},
      variableHistory: tmpVariableHistory,    // History
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleTargetIntermediateUseVariable = props => () => {
    this.handleTargetIntermediateUseVariableCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleTargetIntermediateUseVariableCallBack = async props => {
    console.log('handleTargetIntermediateUseVariable');
    // Generate History
    let tmpVariableHistory = await this.loadingVariableHistory();
    // Open Dialog
    this.setState({
      variableDialogOpen: true,
      currVariableProps: props,
      currVariable: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.variable !== undefined ?
                    this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.variable :
                    {value: '', global: false},
      variableHistory: tmpVariableHistory,    // History
    });
  }

  // props: {rootConstExpr: <Object>, recursionArray: <ArrayOfIntegers>}
  retrievCertaineConstExprOutOfRootConstExpr = props => {
    const rootConstExpr = props.rootConstExpr;
    let recursionArray = [...props.recursionArray];

    let constExpr = {...rootConstExpr};

    if(recursionArray.length > 0 ) {
      const constExprId = recursionArray.shift();
      let index = rootConstExpr.constExprIds.findIndex(
        id => id === constExprId
      );
      if(index !== -1) {
        constExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
          rootConstExpr: rootConstExpr.constExprs[constExprId],
          recursionArray: recursionArray,
        });
      }
    }
    return constExpr;
  }

  // props: {rootConstExpr: <Object>, recursionArray: <ArrayOfIntegers>, currentConstExprId: <Integer>}
  handleConstExprRecursionCallBack = props => {
    const rootConstExpr = props.rootConstExpr;
    let recursionArray = [...props.recursionArray];
    const currentConstExprId = props.currentConstExprId;

    let constExpr =
      recursionArray.length < 1 ?
      (
        rootConstExpr.constExprs[currentConstExprId] !== undefined ?
        rootConstExpr.constExprs[currentConstExprId] :
        undefined
      ) :
      (
        rootConstExpr.constExprs[recursionArray[0]] !== undefined ?
        rootConstExpr.constExprs[recursionArray[0]] :
        undefined
      );

    if(recursionArray.length > 0 ) {
      const constExprId = recursionArray.shift();
      let index = rootConstExpr.constExprIds.findIndex(
        id => id === constExprId
      );
      if(index !== -1) {
        constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: rootConstExpr.constExprs[constExprId],
          recursionArray: recursionArray,
          currentConstExprId: props.currentConstExprId
        });
      }
    }
    return constExpr;
  }

  // Recursively updtes the native constatnt expression and returns the very root one containing the changed one
  // props: {
  //    rootConstExpr: <Object>,
  //    recursionArray: <ArrayOfIntegers>,
  //    object: <Object> | undefined*, (i.e the variable or instanceInfo object)
  //    currentConstExprId: <Integer>,
  //    fieldName: <'relation' | 'entity', 'delete'*>,
  //    objectName: <'variable' | 'instanceInfo' | 'instanceGeneratorDeclaration' | 'labelGeneratorDeclarations' | 'item' | undefined*>
  // }
  // * Case of deleting the native additional (object: undefined, fieldName: delete, objectName: 'undefined')
  handleRecursivelyUpdateNativeConstExprCallBack = async props => { //async
    let rootConstExpr = {...props.rootConstExpr};
    let recursionArray = [...props.recursionArray];
    const object = props.object;//{...props.object};
    const currentConstExprId = props.currentConstExprId
    const fieldName = props.fieldName;
    const objectName = props.objectName;

    if(rootConstExpr.constExprIds !== undefined) { // rootConstExpr has children
      if(recursionArray.length > 0) {
        const constExprId = recursionArray.shift();
        let index = rootConstExpr.constExprIds.findIndex(
          id => id === constExprId
        );
        if(index !== -1) {
          rootConstExpr.constExprs[rootConstExpr.constExprIds[index]] =
          await this.handleRecursivelyUpdateNativeConstExprCallBack({
            rootConstExpr: rootConstExpr.constExprs[rootConstExpr.constExprIds[index]],
            recursionArray: recursionArray,
            object: object,
            currentConstExprId: currentConstExprId,
            fieldName: props.fieldName,
            objectName: props.objectName
          });
        }
      }
      else {
        let currConstExprIndex = rootConstExpr.constExprIds.findIndex(
          id => id === currentConstExprId
        );
        if(currConstExprIndex !== -1) {
          if(fieldName === 'delete') { // Adding native constant expression
            rootConstExpr.constExprIds.splice(currConstExprIndex, 1);
            delete rootConstExpr.constExprs[currentConstExprId]
          }
          else if(fieldName === 'constExpr') { // Adding native constant expression
            rootConstExpr = object;
          }
          else {
            console.log('else it\'s not delete nor constExpr');
            // Case - Changing the selected Value (within the relation or entity)
            if(objectName === 'item') {  // Changing the selected Value (relation or entity)
              // Case Select value
              if(object !== null) {
                // Case where some value is returned
                // In that case it is an object formed like this {value: <TEXT>, label: <TEXT>}
                if(object.value !== undefined) { // Relation
                  // Changing object
                  rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName][objectName] = object;
                  delete rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName].error_text;
                  // Validating - Start   (TESTINGGGGGGGGGGNativeAdditionalValidation)
                  // Validating the entity value of that additional (which is the very next of 'relation')
                  if(fieldName === 'relation') {

                    // This returns the right error message but crushes
                    let errorMsg = await this.handleValidateSelectedValuesOfNativeAdditionalCallBack({ //await
                      constExpr: {...rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]]},
                      parentConstExpr: undefined,
                      type: 'entity'
                    });
                    // Setting the error label
                    if(errorMsg !== undefined)
                      rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]].entity.error_text = errorMsg;
                    else
                      delete rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]].entity.error_text;
                  }
                  // Vlidation of next fields - Ends for reltion
                }

                // Vlidation of next fields - Ends for entity

                // Case where no value is returned
                // In that case it is not an object but an ARRAY
                else {
                  // Case the array is not empty (multivalues)
                  if(object.length > 0) {
                    // Changing object
                    rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName][objectName] = object;
                    delete rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName].error_text;

                    // Validating - Start   (TESTINGGGGGGGGGGNativeAdditionalValidation)
                    // Validating the relation value of its children additionls (if any)
                    if(fieldName === 'entity') {
                      if(rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]].constExprIds !== undefined) {
                        for (const subConstExprId of rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]].constExprIds) {
                          let childErrorMsg =
                            await this.handleValidateSelectedValuesOfNativeAdditionalCallBack({
                              constExpr: rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]].constExprs[subConstExprId],
                              parentConstExpr: rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]],
                              type: 'relation'
                            });
                          // Setting the error label
                          if(childErrorMsg !== undefined)
                            rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]].constExprs[subConstExprId].relation.error_text = childErrorMsg;
                          else
                            delete rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]].constExprs[subConstExprId].relation.error_text;
                        }
                      }

                    }
                    // Vlidation of next fields - Ends for entity

                  }
                  // Case the array is empty (propmt error)
                  else {
                    // Changing object
                    rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName].error_text = 'This field is required';
                    rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName][objectName] = '';
                  }
                }
              }
              // Case X button pressed
              else {
                // Changing object
                rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName].error_text = 'This field is required';
                rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName][objectName] = '';
              }

            }
            // Case - Anything else but changing the selected value (item)
            else {
              rootConstExpr.constExprs[rootConstExpr.constExprIds[currConstExprIndex]][fieldName][objectName] = object;
            }
          }
        }
        else {
          if(fieldName === 'constExpr') { // Adding native constant expression
            rootConstExpr = object;
          }
        }
      }
    }
    else {
      if(fieldName === 'constExpr') { // Adding native constant expression
        rootConstExpr = object;
      }
    }
    return rootConstExpr
  }

  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>}
  handleConstExprUseVariable = props => () => {
    this.handleConstExprUseVariableCallBack(props);
  }

  // CallBack
  // props: {
  //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>,
  //    recursionArray: <ArrayOfIntegers>,
  ////NOT YET    level: <Integer>
  // }
  // * Optional (can be undefined)
  handleConstExprUseVariableCallBack = async props => {
    console.log('handleConstExprUseVariableCallBack');
    let tmpVariable = '';
    // Intermediate
    if(props.intermediateId !== undefined && props.intermediateId !== -1) {
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpVariable = constExpr !== undefined ?
                      (
                        constExpr.entity.variable !== undefined ?
                         constExpr.entity.variable :
                         {value: '', global: false}
                      ) :
                      {value: '', global: false};
      }
      else {
        tmpVariable = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.variable !== undefined ?
                      this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.variable :
                      {value: '', global: false};
      }
    }
    // Link
    else if(props.linkId !== undefined && props.linkId !== -1) {
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpVariable = constExpr !== undefined ?
                      (
                        constExpr.entity.variable !== undefined ?
                         constExpr.entity.variable :
                         {value: '', global: false}
                      ) :
                      {value: '', global: false};
      }
      else {
        tmpVariable = this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.variable !== undefined ?
                      this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.variable :
                      {value: '', global: false};
      }
    }
    // Mapping (Domain)
    else if(props.mappingId !== undefined && props.mappingId !== -1) {
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        // if(constExpr !== undefined)
        //   tmpVariable = constExpr.entity.variable;
        // else
        //   tmpVariable = {value: '', global: false};
        tmpVariable = constExpr !== undefined ?
                      (
                        constExpr.entity.variable !== undefined ?
                         constExpr.entity.variable :
                         {value: '', global: false}
                      ) :
                      {value: '', global: false};
      }
      else {
        tmpVariable = this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.variable !== undefined ?
                      this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.variable :
                      {value: '', global: false};
        }
    }

    // Generate History
    let tmpVariableHistory = await this.loadingVariableHistory();

    // Open Dialog
    this.setState({
      variableDialogOpen: true,
      currVariableProps: props,
      currVariable: tmpVariable != undefined ? tmpVariable : {value: '', global: false},
      variableHistory: tmpVariableHistory,    // History
    });
  }

  handleVariableDialogToggleFullScreen = () => {
    this.setState({
      variableDialogFullScreen: !this.state.variableDialogFullScreen
    });
  }

  // props: {fieldName: 'global' | 'value'}
  handleInputVariableSelectChange = props => event => {
    this.setState({
      currVariable: {
        ...this.state.currVariable,
        [props.fieldName]: props.fieldName === 'global' ? event.target.checked : (event !== null ? event.value : '')
      },
      variableChanged: true,
    });
  }

  // props: {fieldName: 'global' | 'value'}
  handleInputVariableChange = props => (inputValue, action) => {
    const nonEmptySpaceRegEx = /^\S*$/;
    let error_text = undefined;
    if(props.fieldName === 'value') {
      if(inputValue !== null) {
        if(nonEmptySpaceRegEx.test(inputValue)) {
          error_text = undefined;
        }
        else {
          error_text = 'This is not a valid value. Space is not allowed.';
        }
      }
    }

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        currVariable: {
          ...this.state.currVariable,
          [props.fieldName]: props.fieldName === 'global' ?
                             inputValue.target.checked :
                             (inputValue !== null ? inputValue : ''),
          error_text: error_text,
        },
        variableChanged: true,
      });
    }
  }

  // props: {variableProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}}
  // * Optional (can be undefined)
  handleDirectDeleteVariable = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDirectDeleteVariableCallBack(props.argument);
  }

  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  handleDirectDeleteVariableCallBack = props => {
    this.setState({
      currVariableProps: props.variableProps,
    }, () => {
      this.handleSaveVariableCallBack({
        variable: undefined,
      });
    });
  }

  // Using currVariableProps from the state
  // this.state.currVariableProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  // props: {variable: <Object>}
  handleSaveVariable = props => () => {
    this.handleSaveVariableCallBack(props);
  }

  // Using currVariableProps from the state
  // this.state.currVariableProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  // props: {variable: <Object>}
  handleSaveVariableCallBack = async props => {
    let variableProps = props.variableProps !== undefined ? props.variableProps : undefined;
    if(variableProps === undefined) {
      variableProps = this.state.currVariableProps;
    }
    let recursionArray = props.recursionArray !== undefined ? props.recursionArray : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }
    let successMsg = 'The "variable" was updated successfully';
    // Procceed to save (the value is valid)
    if(props.variable !== undefined ? props.variable.error_text === undefined : true) {

      // Intermediate
      if(variableProps.intermediateId !== undefined && variableProps.intermediateId !== -1) {
        if(variableProps.constExprId !== undefined) {
          // Native ConstExpr
          if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
            const rootNativeConstExprId = recursionArray.shift();
            // Using nativeConstExprProps from state
            // nativeConstExprProps: {
            //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
            //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
            // }
            let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
            if(fullTreeConstExpr === undefined) {
              fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
                rootConstExpr: this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
                object: props.variable,
                currentConstExprId: this.state.currVariableProps.constExprId,
                fieldName: 'entity',
                objectName: 'variable'
              });
            }
            this.setState({
              mappings: {
                ...this.state.mappings,
                [variableProps.mappingId]: {
                  ...this.state.mappings[variableProps.mappingId],
                  links: {
                    ...this.state.mappings[variableProps.mappingId].links,
                    [variableProps.linkId]: {
                      ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId],
                      targetIntermediates: {
                        ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates,
                        [variableProps.intermediateId]: {
                          ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId],
                          constExprs: {
                            ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].constExprs,
                            [rootNativeConstExprId] : fullTreeConstExpr
                          }
                        }
                      }
                    }
                  }
                }
              },
            }, () => {
              let currNativeConstExpr = undefined;
              // For the user that took action at first place
              if(props.doSave !== undefined ? props.doSave : true) {
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].constExprs[rootNativeConstExprId],
                  recursionArray: recursionArray,
                });
              }
              // others to sync (clients)
              else {
                const clinetRecursionArray =
                  this.state.nativeConstExprProps.recursionArray !== undefined ?
                  [...this.state.nativeConstExprProps.recursionArray] :
                  undefined;
                if(clinetRecursionArray !== undefined) {
                  clinetRecursionArray.shift();
                  currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                    rootConstExpr: this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].constExprs[rootNativeConstExprId],
                    recursionArray: clinetRecursionArray,
                  });
                }
              }
              this.setState({
                currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
              }, async () => {
                // Don't apply in case of syncing (doSave: false)
                if(props.doSave !== undefined ? props.doSave : true) {
                  // Persist to the database
                  await this.saveMappingTreeModel();
                  this.showSuccessSnackBar({msg: successMsg});
                  // Syncing with others
                  let newProps = Object.assign({}, props); // Copy
                  newProps.doSave = false;
                  newProps.variableProps = this.state.currVariableProps;
                  newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                  newProps.fullTreeConstExpr = fullTreeConstExpr;
                  this.sendJsonObjectToSpecificUsers({
                    actionName: 'handleSaveVariableCallBack',
                    argument: newProps
                  });
                }
              });
            });

          } // Native ConstExpr - ends

          else {
            // Add to constExpr
            this.setState({
              mappings: {
                ...this.state.mappings,
                [variableProps.mappingId]: {
                  ...this.state.mappings[variableProps.mappingId],
                  links: {
                    ...this.state.mappings[variableProps.mappingId].links,
                    [variableProps.linkId]: {
                      ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId],
                      targetIntermediates: {
                        ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates,
                        [variableProps.intermediateId]: {
                          ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId],
                          constExprs: {
                            ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].constExprs,
                            [variableProps.constExprId] : {
                              ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].constExprs[variableProps.constExprId],
                              entity: {
                                ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].constExprs[variableProps.constExprId].entity,
                                variable: props.variable
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }, async function() {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                this.showSuccessSnackBar({msg: successMsg});
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.variableProps = this.state.currVariableProps;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveVariableCallBack',
                  argument: newProps
                });
              }
            });
          }
        }
        else {
          // Add to intermediate
          this.setState({
            mappings: {
              ...this.state.mappings,
              [variableProps.mappingId]: {
                ...this.state.mappings[variableProps.mappingId],
                links: {
                  ...this.state.mappings[variableProps.mappingId].links,
                  [variableProps.linkId]: {
                    ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates,
                      [variableProps.intermediateId]: {
                        ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId],
                        targetEntity: {
                          ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetIntermediates[variableProps.intermediateId].targetEntity,
                          variable: props.variable
                        }
                      }
                    }
                  }
                }
              }
            }
          }, async function() {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.variableProps = this.state.currVariableProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveVariableCallBack',
                argument: newProps
              });
            }
          });
        }
      }

      // Link
      else if(variableProps.linkId !== undefined && variableProps.linkId !== -1) {
        if(variableProps.constExprId !== undefined) {
          // Native ConstExpr
          if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
            const rootNativeConstExprId = recursionArray.shift();
            // Using nativeConstExprProps from state
            // nativeConstExprProps: {
            //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
            //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
            // }
            let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
            if(fullTreeConstExpr === undefined) {
              fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
                rootConstExpr: this.state.mappings[variableProps.mappingId].links[variableProps.linkId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
                object: props.variable,
                currentConstExprId: variableProps.constExprId,
                fieldName: 'entity',
                objectName: 'variable'
              });
            }
            this.setState({
              mappings: {
                ...this.state.mappings,
                [variableProps.mappingId]: {
                  ...this.state.mappings[variableProps.mappingId],
                  links: {
                    ...this.state.mappings[variableProps.mappingId].links,
                    [variableProps.linkId]: {
                      ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId],
                      constExprs: {
                        ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].constExprs,
                        [rootNativeConstExprId] : fullTreeConstExpr
                      }
                    }
                  }
                }
              },
            }, () => {
              let currNativeConstExpr = undefined;
              // For the user that took action at first place
              if(props.doSave !== undefined ? props.doSave : true) {
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[variableProps.mappingId].links[variableProps.linkId].constExprs[rootNativeConstExprId],
                  recursionArray: recursionArray,
                });
              }
              // others to sync (clients)
              else {
                const clinetRecursionArray =
                  this.state.nativeConstExprProps.recursionArray !== undefined ?
                  [...this.state.nativeConstExprProps.recursionArray] :
                  undefined;
                if(clinetRecursionArray !== undefined) {
                  clinetRecursionArray.shift();
                  currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                    rootConstExpr: this.state.mappings[variableProps.mappingId].links[variableProps.linkId].constExprs[rootNativeConstExprId],
                    recursionArray: clinetRecursionArray,
                  });
                }
              }
              this.setState({
                currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
              }, async () => {
                // Don't apply in case of syncing (doSave: false)
                if(props.doSave !== undefined ? props.doSave : true) {
                  // Persist to the database
                  await this.saveMappingTreeModel();
                  this.showSuccessSnackBar({msg: successMsg});
                  // Syncing with others
                  let newProps = Object.assign({}, props); // Copy
                  newProps.doSave = false;
                  newProps.variableProps = this.state.currVariableProps;
                  newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                  newProps.fullTreeConstExpr = fullTreeConstExpr;
                  this.sendJsonObjectToSpecificUsers({
                    actionName: 'handleSaveVariableCallBack',
                    argument: newProps
                  });
                }
              });
            });

          } // Native ConstExpr - ends
          else {
            // Add to constExpr
            this.setState({
              mappings: {
                ...this.state.mappings,
                [variableProps.mappingId]: {
                  ...this.state.mappings[variableProps.mappingId],
                  links: {
                    ...this.state.mappings[variableProps.mappingId].links,
                    [variableProps.linkId]: {
                      ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId],
                      constExprs: {
                        ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].constExprs,
                        [variableProps.constExprId] : {
                          ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].constExprs[variableProps.constExprId],
                          entity: {
                            ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].constExprs[variableProps.constExprId].entity,
                            variable: props.variable
                          }
                        }
                      }
                    }
                  }
                }
              }
            }, async function() {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                this.showSuccessSnackBar({msg: successMsg});
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.variableProps = this.state.currVariableProps;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveVariableCallBack',
                  argument: newProps
                });
              }
            });
          }
        }
        else {
          // Add to link
          this.setState({
            mappings: {
              ...this.state.mappings,
              [variableProps.mappingId]: {
                ...this.state.mappings[variableProps.mappingId],
                links: {
                  ...this.state.mappings[variableProps.mappingId].links,
                  [variableProps.linkId]: {
                    ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId],
                    targetEntity: {
                      ...this.state.mappings[variableProps.mappingId].links[variableProps.linkId].targetEntity,
                      variable: props.variable
                    }
                  }
                }
              }
            }
          }, async function() {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.variableProps = this.state.currVariableProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveVariableCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      // Mapping (Domain)
      else if(variableProps.mappingId !== undefined && variableProps.mappingId !== -1) {
        if(variableProps.constExprId !== undefined) {
          // Native ConstExpr
          if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
            const rootNativeConstExprId = recursionArray.shift();
            // Using nativeConstExprProps from state
            // nativeConstExprProps: {
            //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
            //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
            // }
            let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
            if(fullTreeConstExpr === undefined) {
              fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
                rootConstExpr: this.state.mappings[variableProps.mappingId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
                object: props.variable,
                currentConstExprId: variableProps.constExprId,
                fieldName: 'entity',
                objectName: 'variable'
              });
            }
            this.setState({
              mappings: {
                ...this.state.mappings,
                [variableProps.mappingId]: {
                  ...this.state.mappings[variableProps.mappingId],
                  constExprs: {
                    ...this.state.mappings[variableProps.mappingId].constExprs,
                    [rootNativeConstExprId] : fullTreeConstExpr
                  }
                }
              },
            }, () => {
              let currNativeConstExpr = undefined;
              // For the user that took action at first place
              if(props.doSave !== undefined ? props.doSave : true) {
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[variableProps.mappingId].constExprs[rootNativeConstExprId],
                  recursionArray: recursionArray,
                });
              }
              // others to sync (clients)
              else {
                const clinetRecursionArray =
                  this.state.nativeConstExprProps.recursionArray !== undefined ?
                  [...this.state.nativeConstExprProps.recursionArray] :
                  undefined;
                if(clinetRecursionArray !== undefined) {
                  clinetRecursionArray.shift();
                  currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                    rootConstExpr: this.state.mappings[variableProps.mappingId].constExprs[rootNativeConstExprId],
                    recursionArray: clinetRecursionArray,
                  });
                }
              }
              this.setState({
                currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
              }, async () => {
                // Don't apply in case of syncing (doSave: false)
                if(props.doSave !== undefined ? props.doSave : true) {
                  // Persist to the database
                  await this.saveMappingTreeModel();
                  this.showSuccessSnackBar({msg: successMsg});
                  // Syncing with others
                  let newProps = Object.assign({}, props); // Copy
                  newProps.doSave = false;
                  newProps.variableProps = this.state.currVariableProps;
                  newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                  newProps.fullTreeConstExpr = fullTreeConstExpr;
                  this.sendJsonObjectToSpecificUsers({
                    actionName: 'handleSaveVariableCallBack',
                    argument: newProps
                  });
                }
              });
            });
          } // Native ConstExpr - ends
          else {
            // Add to constExpr
            this.setState({
              mappings: {
                ...this.state.mappings,
                [variableProps.mappingId]: {
                  ...this.state.mappings[variableProps.mappingId],
                  constExprs: {
                    ...this.state.mappings[variableProps.mappingId].constExprs,
                    [variableProps.constExprId] : {
                      ...this.state.mappings[variableProps.mappingId].constExprs[variableProps.constExprId],
                      entity: {
                        ...this.state.mappings[variableProps.mappingId].constExprs[variableProps.constExprId].entity,
                        variable: props.variable
                      }
                    }
                  }
                }
              }
            }, async function() {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                this.showSuccessSnackBar({msg: successMsg});
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.variableProps = this.state.currVariableProps;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveVariableCallBack',
                  argument: newProps
                });
              }
            });
          }
        }
        else {
          // Add to mapping
          this.setState({
            mappings: {
              ...this.state.mappings,
              [variableProps.mappingId]: {
                ...this.state.mappings[variableProps.mappingId],
                domainTargetEntity: {
                  ...this.state.mappings[variableProps.mappingId].domainTargetEntity,
                  variable: props.variable
                }
              }
            }
          }, async function() {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.variableProps = this.state.currVariableProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveVariableCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      if(props.doSave !== undefined ? props.doSave : true) {
        this.setState({
          variableChanged: false,                 // has been changed flag
          variableDialogOpen: false,              // Close dialog
        });
      }
    }
    // Dont't proceed to save. The value is invalid
    else {
      let successMsg = 'The "variable" was not saved. You cannot use empty space as input for "variable"';
      this.props.showErrorSnackBar({msg: successMsg});
    }
  }

  // props: {actionType: '', newTabValue*: <text>}
  // * only available if actionType is changeTab
  handleCheckWhetherToLeaveVariableDialog = props => () => {
    this.handleCheckWhetherToLeaveVariableDialogCallBack(props);
  }

  handleCheckWhetherToLeaveVariableDialogCallBack = props => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.variableChanged) {
      if(props.actionType === 'close') {
        this.setState({
          confirmCloseVariableSnackbarShown : true
        });
      }
    }
    else {
      // Close the dialog
      if(props.actionType === 'close') {
        this.handleCloseVariableDialog();
      }
    }
  }

  handleCloseVariableDialog = () => {
    this.setState({
        variableDialogOpen: false,
    });
  }

  // Confirm close - Variable
  handleCloseConfirmCloseVariableSnackbar = () => {
    this.setState({
      confirmCloseVariableSnackbarShown: false
    });
  }

  handleConfirmCloseVariableDialog = () => {
    this.setState({
      confirmCloseVariableSnackbarShown: false,
      variableDialogOpen: false,
      currVariable: {
        value: '',
        global: false,
      },
      variableChanged: false,
    });
  }

  // props: {rootConstExpr: <Object>}
  retrievVariableHistoryOfNativeConstExprCallBack = props => {
    const rootConstExpr = props.rootConstExpr;
    let variableArray = [];

    if(rootConstExpr.constExprIds !== undefined) {
      for (const constExprId of rootConstExpr.constExprIds) {
        const constExpr = Object.assign({}, rootConstExpr.constExprs[constExprId]);
        // Handling native
        let nativeVariableArray = [];
        if(constExpr.constExprIds !== undefined) {
          nativeVariableArray = this.retrievVariableHistoryOfNativeConstExprCallBack({
            rootConstExpr: constExpr
          });
        }
        const variable = constExpr.entity.variable !== undefined ? constExpr.entity.variable : '';
        if(variable !== '') {
          const valueOption = {label: variable.value, value: variable.value};
          variableArray.push(valueOption);
        }
        variableArray = [...variableArray, ...nativeVariableArray];
      }
    }
    return variableArray;
  }

  // History For Variable
  loadingVariableHistory = () => {
    let variableArray = [];

    // Mapping
    for (const mappingId of this.state.mappingIds) {
      const mapping = Object.assign({}, this.state.mappings[mappingId]);
      const variable = mapping.domainTargetEntity.variable !== undefined? mapping.domainTargetEntity.variable : '';
      if(variable !== '') {
        const valueOption = {label: variable.value, value: variable.value};
        variableArray.push(valueOption);
      }

      // Mapping - Additional
      for (const constExprId of mapping.constExprIds) {
        const constExpr = Object.assign({}, mapping.constExprs[constExprId]);
        const variable = constExpr.entity.variable !== undefined ? constExpr.entity.variable : '';
        if(variable !== '') {
          const valueOption = {label: variable.value, value: variable.value};
          variableArray.push(valueOption);
        }
        // Native ConstExpr
        const nativeVariableArray = this.retrievVariableHistoryOfNativeConstExprCallBack({
          rootConstExpr: constExpr
        });
        variableArray = [...variableArray, ...nativeVariableArray];
      }

      // Link
      for (const linkId of mapping.linkIds) {
        const link = Object.assign({}, mapping.links[linkId]);
        const variable = link.targetEntity.variable !== undefined ? link.targetEntity.variable : '';
        if(variable !== '') {
          const valueOption = {label: variable.value, value: variable.value};
          variableArray.push(valueOption);
        }

        // Link - Additional
        for (const constExprId of link.constExprIds) {
          const constExpr = Object.assign({}, link.constExprs[constExprId]);
          const variable = constExpr.entity.variable !== undefined ? constExpr.entity.variable : '';
          if(variable !== '') {
            const valueOption = {label: variable.value, value: variable.value};
            variableArray.push(valueOption);
          }
          // Native ConstExpr
          const nativeVariableArray = this.retrievVariableHistoryOfNativeConstExprCallBack({
            rootConstExpr: constExpr
          });
          variableArray = [...variableArray, ...nativeVariableArray];
        }

        // Intermediate
        for (const targetIntermediateId of link.targetIntermediateIds) {
          const targetIntermediate = Object.assign({}, link.targetIntermediates[targetIntermediateId]);
          const variable = targetIntermediate.targetEntity.variable !== undefined ? targetIntermediate.targetEntity.variable : '';
          if(variable !== '') {
            const valueOption = {label: variable.value, value: variable.value};
            variableArray.push(valueOption);
          }

          // Intermediate - Additional
          for (const constExprId of targetIntermediate.constExprIds) {
            const constExpr = Object.assign({}, targetIntermediate.constExprs[constExprId]);
            const variable = constExpr.entity.variable !== undefined ? constExpr.entity.variable : '';
            if(variable !== '') {
              const valueOption = {label: variable.value, value: variable.value};
              variableArray.push(valueOption);
            }
            // Native ConstExpr
            const nativeVariableArray = this.retrievVariableHistoryOfNativeConstExprCallBack({
              rootConstExpr: constExpr
            });
            variableArray = [...variableArray, ...nativeVariableArray];
          }
        }
      }
    }
    return variableArray;
  }

  // Instance Info functions

  // props: {mappingId: <Text>}
  handleMappingUseInstanceInfo = props => () => {
    this.handleMappingUseInstanceInfoCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>}
  handleMappingUseInstanceInfoCallBack = async props => {
    console.log('handleMappingUseInstanceInfo');
    // Open Dialog
    this.setState({
      instanceInfoDialogOpen: true,
      currInstanceInfoProps: props,
      instanceInfoChanged: false,
      currInstanceInfo: this.state.mappings[props.mappingId].domainTargetEntity.instanceInfo !== undefined ?
                    this.state.mappings[props.mappingId].domainTargetEntity.instanceInfo :
                    '',
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseInstanceInfo = props => () => {
    this.handleLinkUseInstanceInfoCallBack(props);
  }
  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseInstanceInfoCallBack = async props => {
    console.log('handleLinkUseInstanceInfo');
    // Open Dialog
    this.setState({
      instanceInfoDialogOpen: true,
      currInstanceInfoProps: props,
      instanceInfoChanged: false,
      currInstanceInfo: this.state.mappings[props.mappingId].links[props.linkId].targetEntity.instanceInfo !== undefined ?
                    this.state.mappings[props.mappingId].links[props.linkId].targetEntity.instanceInfo :
                    '',
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleTargetIntermediateUseInstanceInfo = props => () => {
    this.handleTargetIntermediateUseInstanceInfoCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleTargetIntermediateUseInstanceInfoCallBack = async props => {
    console.log('handleTargetIntermediateUseInstanceInfo');
    // Open Dialog
    this.setState({
      instanceInfoDialogOpen: true,
      currInstanceInfoProps: props,
      instanceInfoChanged: false,
      currInstanceInfo: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.instanceInfo !== undefined ?
                    this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity.instanceInfo :
                    '',
    });
  }

  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>}
  handleConstExprUseInstanceInfo = props => () => {
    this.handleConstExprUseInstanceInfoCallBack(props);
  }

  // CallBack
  // props: {
  //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>,
  //    recursionArray*: <ArrayOfIntegers>,
  // }
  // * Optional (can be undefined)
  handleConstExprUseInstanceInfoCallBack = async props => {
    console.log('handleConstExprUseInstanceInfoCallBack');
    let tmpInstanceInfo = '';
    if(props.intermediateId !== undefined && props.intermediateId !== -1) {
      // Native ConstExpr
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpInstanceInfo = constExpr !== undefined ?
                          (
                            constExpr.entity.instanceInfo !== undefined ?
                            constExpr.entity.instanceInfo :
                            ''
                          ) :
                          '';
      }
      else {
        tmpInstanceInfo = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.instanceInfo !== undefined ?
                          this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity.instanceInfo :
                          '';
      }
    }
    else if(props.linkId !== undefined && props.linkId !== -1) {
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpInstanceInfo = constExpr !== undefined ?
                          (
                            constExpr.entity.instanceInfo !== undefined ?
                            constExpr.entity.instanceInfo :
                            ''
                          ) :
                          '';
      }
      else {
        tmpInstanceInfo = this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.instanceInfo !== undefined ?
                          this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity.instanceInfo :
                          '';
      }
    }
    else if(props.mappingId !== undefined && props.mappingId !== -1) {
      if(props.recursionArray !== undefined) {
        let recursionArray = [...props.recursionArray];
        recursionArray.shift();
        const constExpr = this.handleConstExprRecursionCallBack({
          rootConstExpr: this.state.mappings[props.mappingId].constExprs[props.recursionArray[0]],
          recursionArray: recursionArray,
          currentConstExprId: props.constExprId
        });
        tmpInstanceInfo = constExpr !== undefined ?
                          (
                            constExpr.entity.instanceInfo !== undefined ?
                            constExpr.entity.instanceInfo :
                            ''
                          ) :
                          '';
      }
      else {
        tmpInstanceInfo = this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.instanceInfo !== undefined ?
                          this.state.mappings[props.mappingId].constExprs[props.constExprId].entity.instanceInfo :
                          '';
      }
    }

    // Open Dialog
    this.setState({
      instanceInfoDialogOpen: true,
      currInstanceInfoProps: props,
      instanceInfoChanged: false,
      currInstanceInfo: tmpInstanceInfo,
    });
  }

  // props: {actionType: ''}
  handleCheckWhetherToLeaveInstanceInfoDialog = props => () => {
    this.handleCheckWhetherToLeaveInstanceInfoDialogCallBack(props);
  }

  handleCheckWhetherToLeaveInstanceInfoDialogCallBack = props => {
    if(props.actionType === 'remove') {
      this.setState({
        confirmCloseRemoveInstanceInfoSnackbarShown : true
      });
    }
    else {
      // Check if there are any unsaved changes and show the confirmation Snackbar
      if(this.state.instanceInfoChanged) {
        if(props.actionType === 'close') {
          this.setState({
            confirmCloseInstanceInfoSnackbarShown : true
          });
        }
      }
      else {
        // Close the dialog
        if(props.actionType === 'close') {
          this.handleCloseInstanceInfoDialog();
        }
      }
    }
  }

  handleCloseInstanceInfoDialog = () => {
    this.setState({
        instanceInfoDialogOpen: false,
    });
  }

  handleInstanceInfoDialogToggleFullScreen = () => {
    this.setState({
      instanceInfoDialogFullScreen: !this.state.instanceInfoDialogFullScreen
    });
  }

  // props: {fieldName: <Text>}
  handleInstanceInfoInputChange = props => event => {
    // Copy the new value
    const newVal = event !== null ?
                   event.target.value :
                   '';
    this.setState({
      currInstanceInfo: {
        ...this.state.currInstanceInfo,
          [props.fieldName]: newVal,
        },
        instanceInfoChanged: true,
    });
  }

  handleInputInstanceInfoSelectChange = props => (event, item) => {
    // Used for custom languages only
    let customLangValueIndex = -1;
    let customLanglabelIndex = -1;
    let customLanguage = {label: '', value: ''};
    if(item !== null) {
      // Case of Custom (inputValue is only present when adding custom language)
      if(item.inputValue !== undefined) {
        //  Searching for value and label within the languages list
        customLangValueIndex = this.state.languages.findIndex(lang => lang.value.toLowerCase() === item.inputValue.toLowerCase());
        customLanglabelIndex = this.state.languages.findIndex(lang => lang.label.toLowerCase() === item.inputValue.toLowerCase());
        if(customLangValueIndex !== -1) {
          customLanguage = Object.assign({}, this.state.languages[customLangValueIndex]);
        }
        else if(customLanglabelIndex !== -1) {
          customLanguage = Object.assign({}, this.state.languages[customLanglabelIndex]);
        }
        else {
          customLanguage = {label: item.inputValue, value: item.inputValue};
        }
      }
    }

    this.setState({
      currInstanceInfo: {
        ...this.state.currInstanceInfo,
        // Note that inputValue is only present when adding custom language
        [props.fieldName]: item !== null ? // The X button is not clicked
                           (
                             item.inputValue === undefined ?
                             (item !== null ? item : '') :
                             customLanguage
                           ) :
                           undefined,
      },
      instanceInfoChanged: true,
    });
  }

  // props: {instanceInfoProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}}
  // * Optional (can be undefined)
  handleDirectDeleteInstanceInfo = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    this.handleDirectDeleteInstanceInfoCallBack(props.argument);
  }

  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  handleDirectDeleteInstanceInfoCallBack = props => {
    this.setState({
      currInstanceInfoProps: props.instanceInfoProps,
    }, () => {
      this.handleSaveInstanceInfoCallBack({
        instanceInfo: undefined,
      });
    });
  }

  // Using currInstanceInfoProps from the state
  // this.state.currInstanceInfoProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  // props: {instanceInfo: <Object>}
  handleSaveInstanceInfo = props => () => {
    this.handleSaveInstanceInfoCallBack(props);
  }

  // Using currInstanceInfoProps from the state
  // this.state.currInstanceInfoProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
  // * Optional (can be undefined)
  // props: {instanceInfo: <Object>}
  handleSaveInstanceInfoCallBack = async props => {

    let instanceInfoProps = props.instanceInfoProps !== undefined ? props.instanceInfoProps : undefined;
    if(instanceInfoProps === undefined) {
      instanceInfoProps = this.state.currInstanceInfoProps;
    }

    let recursionArray = props.recursionArray !== undefined ? props.recursionArray : undefined;
    if(recursionArray === undefined) {
      if(props.doSave !== undefined ? props.doSave : true) {
        recursionArray = this.state.nativeConstExprProps.recursionArray !== undefined ?
                         [...this.state.nativeConstExprProps.recursionArray] :
                         undefined;
      }
    }
    let successMsg = 'The "instance info" was updated successfully';
    let instanceInfo = Object.assign({}, props.instanceInfo);
    if(instanceInfo !== undefined) {
      if(instanceInfo.constant === '') {
        delete instanceInfo.constant;
      }
      if(instanceInfo.description === '') {
        delete instanceInfo.description;
      }
      if(instanceInfo.language !== undefined) {
        if(instanceInfo.language.value === '') {
          delete instanceInfo.language;
        }
      }
      if(instanceInfo.constant === undefined &&
        instanceInfo.description === undefined &&
        instanceInfo.language === undefined) {
        instanceInfo = undefined;
      }
    }

    // Intermediate
    if(instanceInfoProps.intermediateId !== undefined && instanceInfoProps.intermediateId !== -1) {
      if(instanceInfoProps.constExprId !== undefined) {

        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: instanceInfo,
              currentConstExprId: this.state.currInstanceInfoProps.constExprId,
              fieldName: 'entity',
              objectName: 'instanceInfo'
            });
          }
          this.setState({
            mappings: {
              ...this.state.mappings,
              [instanceInfoProps.mappingId]: {
                ...this.state.mappings[instanceInfoProps.mappingId],
                links: {
                  ...this.state.mappings[instanceInfoProps.mappingId].links,
                  [instanceInfoProps.linkId]: {
                    ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates,
                      [instanceInfoProps.intermediateId]: {
                        ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId],
                        constExprs: {
                          ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].constExprs,
                          [rootNativeConstExprId] : fullTreeConstExpr
                        }
                      }
                    }
                  }
                }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: JSON.parse(JSON.stringify(currNativeConstExpr)), // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                this.showSuccessSnackBar({msg: successMsg});
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.instanceInfoProps = this.state.currInstanceInfoProps;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveInstanceInfoCallBack',
                  argument: newProps
                });
              }
            });
          });

        } // Native ConstExpr - ends

        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [instanceInfoProps.mappingId]: {
                ...this.state.mappings[instanceInfoProps.mappingId],
                links: {
                  ...this.state.mappings[instanceInfoProps.mappingId].links,
                  [instanceInfoProps.linkId]: {
                    ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates,
                      [instanceInfoProps.intermediateId]: {
                        ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId],
                        constExprs: {
                          ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].constExprs,
                          [instanceInfoProps.constExprId] : {
                            ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].constExprs[instanceInfoProps.constExprId],
                            entity: {
                              ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].constExprs[instanceInfoProps.constExprId].entity,
                              instanceInfo: instanceInfo
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }, async function() {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.instanceInfoProps = this.state.currInstanceInfoProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveInstanceInfoCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to intermediate
        this.setState({
          mappings: {
            ...this.state.mappings,
            [instanceInfoProps.mappingId]: {
              ...this.state.mappings[instanceInfoProps.mappingId],
              links: {
                ...this.state.mappings[instanceInfoProps.mappingId].links,
                [instanceInfoProps.linkId]: {
                  ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId],
                  targetIntermediates: {
                    ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates,
                    [instanceInfoProps.intermediateId]: {
                      ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId],
                      targetEntity: {
                        ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetIntermediates[instanceInfoProps.intermediateId].targetEntity,
                        instanceInfo: instanceInfo
                      }
                    }
                  }
                }
              }
            }
          }
        }, async function() {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            this.showSuccessSnackBar({msg: successMsg});
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.instanceInfoProps = this.state.currInstanceInfoProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveInstanceInfoCallBack',
              argument: newProps
            });
          }
        });
      }
    }

    // Link
    else if(instanceInfoProps.linkId !== undefined && instanceInfoProps.linkId !== -1) {
      if(instanceInfoProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: instanceInfo,
              currentConstExprId: instanceInfoProps.constExprId,
              fieldName: 'entity',
              objectName: 'instanceInfo'
            });
          }
          this.setState({
            mappings: {
              ...this.state.mappings,
              [instanceInfoProps.mappingId]: {
                ...this.state.mappings[instanceInfoProps.mappingId],
                links: {
                  ...this.state.mappings[instanceInfoProps.mappingId].links,
                  [instanceInfoProps.linkId]: {
                    ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId],
                    constExprs: {
                      ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].constExprs,
                      [rootNativeConstExprId] : fullTreeConstExpr
                    }
                  }
                }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                this.showSuccessSnackBar({msg: successMsg});
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.instanceInfoProps = this.state.currInstanceInfoProps;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveInstanceInfoCallBack',
                  argument: newProps
                });
              }
            });
          });

        } // Native ConstExpr - ends
        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [instanceInfoProps.mappingId]: {
                ...this.state.mappings[instanceInfoProps.mappingId],
                links: {
                  ...this.state.mappings[instanceInfoProps.mappingId].links,
                  [instanceInfoProps.linkId]: {
                    ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId],
                    constExprs: {
                      ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].constExprs,
                      [instanceInfoProps.constExprId] : {
                        ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].constExprs[instanceInfoProps.constExprId],
                        entity: {
                          ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].constExprs[instanceInfoProps.constExprId].entity,
                          instanceInfo: instanceInfo
                        }
                      }
                    }
                  }
                }
              }
            }
          }, async function() {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.instanceInfoProps = this.state.currInstanceInfoProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveInstanceInfoCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to link
        this.setState({
          mappings: {
            ...this.state.mappings,
            [instanceInfoProps.mappingId]: {
              ...this.state.mappings[instanceInfoProps.mappingId],
              links: {
                ...this.state.mappings[instanceInfoProps.mappingId].links,
                [instanceInfoProps.linkId]: {
                  ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId],
                  targetEntity: {
                    ...this.state.mappings[instanceInfoProps.mappingId].links[instanceInfoProps.linkId].targetEntity,
                    instanceInfo: instanceInfo
                  }
                }
              }
            }
          }
        }, async function() {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            this.showSuccessSnackBar({msg: successMsg});
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.instanceInfoProps = this.state.currInstanceInfoProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveInstanceInfoCallBack',
              argument: newProps
            });
          }
        });
      }
    }
    // Mapping (Domain)
    else if(instanceInfoProps.mappingId !== undefined && instanceInfoProps.mappingId !== -1) {
      if(instanceInfoProps.constExprId !== undefined) {
        // Native ConstExpr
        if(recursionArray !== undefined ? recursionArray.length > 0 : false) {
          const rootNativeConstExprId = recursionArray.shift();
          // Using nativeConstExprProps from state
          // nativeConstExprProps: {
          //    mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>, constExpr: <Object>,
          //    recursionArray**: <ArrayOfIntegers>, level**: <Integer>
          // }
          let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
          if(fullTreeConstExpr === undefined) {
            fullTreeConstExpr = await this.handleRecursivelyUpdateNativeConstExprCallBack({
              rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].constExprs[rootNativeConstExprId],
              recursionArray: recursionArray,
              object: instanceInfo,
              currentConstExprId: instanceInfoProps.constExprId,
              fieldName: 'entity',
              objectName: 'instanceInfo'
            });
          }
          this.setState({
            mappings: {
              ...this.state.mappings,
              [instanceInfoProps.mappingId]: {
                ...this.state.mappings[instanceInfoProps.mappingId],
                constExprs: {
                  ...this.state.mappings[instanceInfoProps.mappingId].constExprs,
                  [rootNativeConstExprId] : fullTreeConstExpr
                }
              }
            },
          }, () => {
            let currNativeConstExpr = undefined;
            // For the user that took action at first place
            if(props.doSave !== undefined ? props.doSave : true) {
              currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].constExprs[rootNativeConstExprId],
                recursionArray: recursionArray,
              });
            }
            // others to sync (clients)
            else {
              const clinetRecursionArray =
                this.state.nativeConstExprProps.recursionArray !== undefined ?
                [...this.state.nativeConstExprProps.recursionArray] :
                undefined;
              if(clinetRecursionArray !== undefined) {
                clinetRecursionArray.shift();
                currNativeConstExpr = this.retrievCertaineConstExprOutOfRootConstExpr({
                  rootConstExpr: this.state.mappings[instanceInfoProps.mappingId].constExprs[rootNativeConstExprId],
                  recursionArray: clinetRecursionArray,
                });
              }
            }
            this.setState({
              currNativeConstExpr: currNativeConstExpr !== undefined ? JSON.parse(JSON.stringify(currNativeConstExpr)) : '', // Fully Cloning
            }, async () => {
              // Don't apply in case of syncing (doSave: false)
              if(props.doSave !== undefined ? props.doSave : true) {
                // Persist to the database
                await this.saveMappingTreeModel();
                this.showSuccessSnackBar({msg: successMsg});
                // Syncing with others
                let newProps = Object.assign({}, props); // Copy
                newProps.doSave = false;
                newProps.instanceInfoProps = this.state.currInstanceInfoProps;
                newProps.recursionArray = this.state.nativeConstExprProps.recursionArray;
                newProps.fullTreeConstExpr = fullTreeConstExpr;
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleSaveInstanceInfoCallBack',
                  argument: newProps
                });
              }
            });
          });
        } // Native ConstExpr - ends
        else {
          // Add to constExpr
          this.setState({
            mappings: {
              ...this.state.mappings,
              [instanceInfoProps.mappingId]: {
                ...this.state.mappings[instanceInfoProps.mappingId],
                constExprs: {
                  ...this.state.mappings[instanceInfoProps.mappingId].constExprs,
                  [instanceInfoProps.constExprId] : {
                    ...this.state.mappings[instanceInfoProps.mappingId].constExprs[instanceInfoProps.constExprId],
                    entity: {
                      ...this.state.mappings[instanceInfoProps.mappingId].constExprs[instanceInfoProps.constExprId].entity,
                      instanceInfo: instanceInfo
                    }
                  }
                }
              }
            }
          }, async function() {
            // Don't apply in case of syncing (doSave: false)
            if(props.doSave !== undefined ? props.doSave : true) {
              // Persist to the database
              await this.saveMappingTreeModel();
              this.showSuccessSnackBar({msg: successMsg});
              // Syncing with others
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.instanceInfoProps = this.state.currInstanceInfoProps;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleSaveInstanceInfoCallBack',
                argument: newProps
              });
            }
          });
        }
      }
      else {
        // Add to mapping
        this.setState({
          mappings: {
            ...this.state.mappings,
            [instanceInfoProps.mappingId]: {
              ...this.state.mappings[instanceInfoProps.mappingId],
              domainTargetEntity: {
                ...this.state.mappings[instanceInfoProps.mappingId].domainTargetEntity,
                instanceInfo: instanceInfo
              }
            }
          }
        }, async function() {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            this.showSuccessSnackBar({msg: successMsg});
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.instanceInfoProps = this.state.currInstanceInfoProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveInstanceInfoCallBack',
              argument: newProps
            });
          }
        });
      }
    }
    if(props.doSave !== undefined ? props.doSave : true) {
      this.setState({
        instanceInfoChanged: false,                 // has been changed flag
        instanceInfoDialogOpen: false,              // Close dialog
      });
    }
  }

  // Confirm close - Instance Info
  handleCloseConfirmCloseInstanceInfoSnackbar = () => {
    this.setState({
      confirmCloseInstanceInfoSnackbarShown: false
    });
  }

  handleConfirmCloseInstanceInfoDialog = () => {
    this.setState({
      confirmCloseInstanceInfoSnackbarShown: false,
      instanceInfoDialogOpen: false,
      currInstanceInfo: '',
    });
  }

  // Confirm remove - Instance Info
  handleCloseConfirmRemoveInstanceInfoSnackbar = () => {
    this.setState({
      confirmCloseRemoveInstanceInfoSnackbarShown: false
    });
  }

  handleConfirmRemoveInstanceInfoDialog = () => {
    this.setState({
      confirmCloseRemoveInstanceInfoSnackbarShown: false,
      instanceInfoDialogOpen: false,
      currInstanceInfo: '',
      instanceInfoChanged: false,
    }, () => {
      // props: {instanceInfo: <Object>}
      this.handleSaveInstanceInfoCallBack({instanceInfo: undefined});
    });
  }

  // Determine the style to be applied based on the required lines
  instanceInfoStyleBasedOnLinesRequired = (instanceInfo) => {
    let lines = 0;
    if(instanceInfo.constant !== undefined &&
       instanceInfo.constant !== '') {
      lines = lines + 1;
    }
    if(instanceInfo.description !== undefined &&
       instanceInfo.description !== '') {
      lines = lines + 1;
    }
    if(instanceInfo.language !== undefined &&
       instanceInfo.language.value !== '') {
      lines = lines + 1;
    }
    //return lines;
    return lines === 3 ? 'threeLinesSmallChip' : lines === 2 ? 'twoLinesSmallChip' : ''
  }

  // Namedgraph Related functions

  // props: {mappingId: <Text>}
  handleMappingUseNamedgraph = props => event => {
    event.stopPropagation();
    this.handleMappingUseNamedgraphCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>}
  handleMappingUseNamedgraphCallBack = async props => {
    console.log('handleMappingUseNamedgraph');
    // Generate History
    let tmpNamedgraphHistory = await this.loadingNamedgraphHistory();
    // Open Dialog
    this.setState({
      namedgraphDialogOpen: true,
      currNamedgraphProps: props,
      currNamedgraph: this.state.mappings[props.mappingId].namedgraph !== undefined ?
                    this.state.mappings[props.mappingId].namedgraph :
                    {value: '', turnedOn: false},
      namedgraphHistory: tmpNamedgraphHistory,    // History
    });
  }

  // props: {mappingId: <Text>}
  handleDomainUseNamedgraph = props => event => {
    event.stopPropagation();
    this.handleDomainUseNamedgraphCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, domain: <Boolean>}
  handleDomainUseNamedgraphCallBack = async props => {
    console.log('handleDomainUseNamedgraph');
    // Generate History
    let tmpNamedgraphHistory = await this.loadingNamedgraphHistory();
    // Open Dialog
    this.setState({
      namedgraphDialogOpen: true,
      currNamedgraphProps: props,
      currNamedgraph: this.state.mappings[props.mappingId].domainNamedgraph !== undefined ?
                    this.state.mappings[props.mappingId].domainNamedgraph :
                    {value: '', turnedOn: false},
      namedgraphHistory: tmpNamedgraphHistory,    // History
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseNamedgraph = props => event => {
    event.stopPropagation();
    this.handleLinkUseNamedgraphCallBack(props);
  }
  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseNamedgraphCallBack = async props => {
    console.log('handleLinkUseNamedgraph');
    // Generate History
    let tmpNamedgraphHistory = await this.loadingNamedgraphHistory();
    // Open Dialog
    this.setState({
      namedgraphDialogOpen: true,
      currNamedgraphProps: props,
      currNamedgraph: this.state.mappings[props.mappingId].links[props.linkId].namedgraph !== undefined ?
                    this.state.mappings[props.mappingId].links[props.linkId].namedgraph :
                    {value: '', turnedOn: false},
      namedgraphHistory: tmpNamedgraphHistory,    // History
    });
  }

  handleNamedgraphDialogToggleFullScreen = () => {
    this.setState({
      namedgraphDialogFullScreen: !this.state.namedgraphDialogFullScreen
    });
  }

  // props: {fieldName: 'turnedOn' | 'value'}
  handleInputNamedgraphSelectChange = props => event => {
    this.setState({
      currNamedgraph: {
        ...this.state.currNamedgraph,
        [props.fieldName]: props.fieldName === 'turnedOn' ? event.target.checked : (event !== null ? event.value : '')
      },
      namedgraphChanged: true,
    }, function() {
      if(props.fieldName === 'turnedOn') {
        if(!this.state.currNamedgraph.turnedOn) {
          this.setState({
            currNamedgraph: {
              ...this.state.currNamedgraph,
              value: '',
            },
          });
        }
      }
    });
  }
  // props: {fieldName: 'turnedOn' | 'value'}
  handleInputNamedgraphChange = props => (inputValue, action) => {
    const nonEmptySpaceRegEx = /^\S*$/;
    let error_text = undefined;
    if(props.fieldName === 'value') {
      if(inputValue !== null) {
        if(nonEmptySpaceRegEx.test(inputValue)) {
          error_text = undefined;
        }
        else {
          error_text = 'This is not a valid value. Space is not allowed.';
        }
      }
    }

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        currNamedgraph: {
          ...this.state.currNamedgraph,
          [props.fieldName]: props.fieldName === 'turnedOn' ?
                             inputValue.target.checked :
                             (inputValue !== null ? inputValue : ''),
          error_text: error_text,
        },
        namedgraphChanged: true,
      }, function() {
        if(props.fieldName === 'turnedOn') {
          // If the Namedgraph is turned off
          if(!this.state.currNamedgraph.turnedOn) {
            // Resetting Namedgraph's value
            this.setState({
              currNamedgraph: {
                ...this.state.currNamedgraph,
                value: '',
              },
            });
          }
        }
      });
    }
  }

  // Using currNamedgraphProps from the state
  // this.state.currNamedgraphProps: {mappingId: <Text>, linkId*: <Text>, domain: <Boolean>}
  // * Optional (can be undefined)
  // props: {namedgraph: <Object>}
  handleSaveNamedgraph = props => () => {
    this.handleSaveNamedgraphCallBack(props);
  }
  // Using currNamedgraphProps from the state
  // this.state.currNamedgraphProps: {mappingId: <Text>, linkId*: <Text>, domain: <Boolean>}
  // * Optional (can be undefined)
  // props: {namedgraph: <Object>}
  handleSaveNamedgraphCallBack = props => {
    let namedgraphProps = props.namedgraphProps !== undefined ? props.namedgraphProps : undefined;
    if(namedgraphProps === undefined) {
      namedgraphProps = this.state.currNamedgraphProps;
    }
    let successMsg = 'The named graph was updated successfully';
    // If there is not any error in the field of named graph
    if(props.namedgraph.error_text === undefined) {
      // Link
      if(namedgraphProps.linkId !== undefined && namedgraphProps.linkId !== -1) {
        // Add to link
        this.setState({
          mappings: {
            ...this.state.mappings,
            [namedgraphProps.mappingId]: {
              ...this.state.mappings[namedgraphProps.mappingId],
              links: {
                ...this.state.mappings[namedgraphProps.mappingId].links,
                [namedgraphProps.linkId]: {
                  ...this.state.mappings[namedgraphProps.mappingId].links[namedgraphProps.linkId],
                  namedgraph: props.namedgraph
                }
              }
            }
          }
        }, async () => {
          // Loadding history of namedgraphs
          let tmpNamedgraphHistory = await this.loadingNamedgraphHistory();
          this.setState({
            namedgraphHistory: tmpNamedgraphHistory,    // History
          });
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            this.showSuccessSnackBar({msg: successMsg});
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.namedgraphProps = this.state.currNamedgraphProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveNamedgraphCallBack',
              argument: newProps
            });
          }
        });
      }
      // Mapping
      else if(namedgraphProps.mappingId !== undefined && namedgraphProps.mappingId !== -1) {
        // Decide whether this is for mapping or domain
        const attrName = namedgraphProps.domain === undefined ? 'namedgraph' : 'domainNamedgraph'
        // Add to mapping
        this.setState({
          mappings: {
            ...this.state.mappings,
            [namedgraphProps.mappingId]: {
              ...this.state.mappings[namedgraphProps.mappingId],
              [attrName]: props.namedgraph
            }
          }
        }, async () => {
          // Loadding history of namedgraphs
          let tmpNamedgraphHistory = await this.loadingNamedgraphHistory();
          this.setState({
            namedgraphHistory: tmpNamedgraphHistory,    // History
          });
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            // Persist to the database
            await this.saveMappingTreeModel();
            this.showSuccessSnackBar({msg: successMsg});
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.doSave = false;
            newProps.namedgraphProps = this.state.currNamedgraphProps;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleSaveNamedgraphCallBack',
              argument: newProps
            });
          }
        });
      }
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        this.setState({
          namedgraphChanged: false,                 // has been changed flag
          namedgraphDialogOpen: false,              // Close dialog
        });
      }
    }
    // If the namedgraph field has errors
    else {
      if(props.doSave !== undefined ? props.doSave : true) {
        successMsg = 'The named graph could not be updated due to errors in the respective form.';
        this.props.showErrorSnackBar({msg: successMsg});
      }
    }

  }

  // props: {actionType: '', newTabValue*: <text>}
  // * only available if actionType is changeTab
  handleCheckWhetherToLeaveNamedgraphDialog = props => () => {
    this.handleCheckWhetherToLeaveNamedgraphDialogCallBack(props);
  }

  handleCheckWhetherToLeaveNamedgraphDialogCallBack = props => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.namedgraphChanged) {
      if(props.actionType === 'close') {
        this.setState({
          confirmCloseNamedgraphSnackbarShown : true
        });
      }
    }
    else {
      // Close the dialog
      if(props.actionType === 'close') {
        this.handleCloseNamedgraphDialog();
      }
    }
  }

  handleCloseNamedgraphDialog = () => {
    this.setState({
        namedgraphDialogOpen: false,
    });
  }

  // Confirm close - Generator
  handleCloseConfirmCloseNamedgraphSnackbar = () => {
    this.setState({
      confirmCloseNamedgraphSnackbarShown: false
    });
  }

  handleConfirmCloseNamedgraphDialog = () => {
    this.setState({
      confirmCloseNamedgraphSnackbarShown: false,
      namedgraphDialogOpen: false,
      currNamedgraph: {
        value: '',
        turnedOn: false
      },
      namedgraphChanged: false,
    });
  }

  // History for Named Graph
  loadingNamedgraphHistory = () => {
    let namedgraphArray = [];
    for (const mappingId of this.state.mappingIds) {
      // Mapping
      const mapping = Object.assign({}, this.state.mappings[mappingId]);
      const namedgraph = mapping.namedgraph !== undefined? mapping.namedgraph : '';
      if(namedgraph !== '') {
        if(namedgraph.turnedOn) {
          // Check if already added
          if (namedgraphArray.find(item => item.value === namedgraph.value) === undefined) {
            const valueOption = {label: namedgraph.value !== '' ? namedgraph.value : '... ∅ ...' , value: namedgraph.value};
            namedgraphArray.push(valueOption);
          }
        }
      }
      // Domain
      const domainNamedgraph = mapping.domainNamedgraph !== undefined? mapping.domainNamedgraph : '';
      if(domainNamedgraph !== '') {
        if(domainNamedgraph.turnedOn) {
          // Check if already added
          if (namedgraphArray.find(item => item.value === domainNamedgraph.value) === undefined) {
            const valueOption = {label: domainNamedgraph.value !== '' ? domainNamedgraph.value : '... ∅ ...' , value: domainNamedgraph.value};
            namedgraphArray.push(valueOption);
          }
        }
      }

      for (const linkId of mapping.linkIds) {
        // Link
        const link = Object.assign({}, mapping.links[linkId]);
        const namedgraph = link.namedgraph !== undefined ? link.namedgraph : '';
        if(namedgraph !== '') {
          if(namedgraph.turnedOn) {
            // Check if already added
            if(namedgraphArray.find(item => item.value === namedgraph.value) === undefined) {
              const valueOption = {label: namedgraph.value !== '' ? namedgraph.value : '... ∅ ...' , value: namedgraph.value};
              namedgraphArray.push(valueOption);
            }
          }
        }
      }
    }
    // Decide on selected option for producing output (rdf, ttl, nq, trig)
    // Note that if there is at least one namedgraph used, then trig is the only available option
    if(namedgraphArray.length > 0) {
      this.setState({
        produceOutputButtonGroupSelectedIndex: 3// make it trig
      });
    }
    return namedgraphArray;
  }

  // Condition Related functions

  // props: {mappingId: <Text>}
  handleMappingUseCondition = props => () => {
    this.handleMappingUseConditionCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>}
  handleMappingUseConditionCallBack = async props => {
    console.log('handleMappingUseCondition');
    // Open Dialog
    this.setState({
        conditionDialogOpen: true,
        currConditionProps: props,
        currConditions: this.state.mappings[props.mappingId].conditions !== undefined ?
                        this.state.mappings[props.mappingId].conditions :
                        [],
        currConditionListUseOr: this.state.mappings[props.mappingId].conditionListUseOr !== undefined ?
                        this.state.mappings[props.mappingId].conditionListUseOr :
                        false,
        conditionListLogicalOperator: this.state.mappings[props.mappingId].conditionListLogicalOperator !== undefined ?
                                      (
                                        this.state.mappings[props.mappingId].conditionListUseOr ?
                                        'or' :
                                        'and'
                                      ) :
                                      'and',
    });
  }

  // props: {linkId: <Text>}
  handleLinkUseCondition = props => () => {
    this.handleLinkUseConditionCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>, type: 'targetNode' | undefined}
  // undefined denotes targetRelation
  handleLinkUseConditionCallBack = async props => {
    console.log('handleLinkUseCondition');

    let variableSuffix = ''; // Case targetRelation (initially)
    // Determine whether it is a condition of the target node or the relation node
    if(props.type !== undefined) {
      // That can either be a case of targetNode or targetRelation (empty)
      variableSuffix = props.type.charAt(0).toUpperCase() + props.type.slice(1);
    }
    // Open Dialog
    this.setState({
        conditionDialogOpen: true,
        currConditionProps: props,
        currConditions: this.state.mappings[props.mappingId].links[props.linkId]['conditions' + variableSuffix] !== undefined ?
                        this.state.mappings[props.mappingId].links[props.linkId]['conditions' + variableSuffix] :
                        [],
        currConditionListUseOr: this.state.mappings[props.mappingId].links[props.linkId]['conditionListUseOr' + variableSuffix] !== undefined ?
                        this.state.mappings[props.mappingId].links[props.linkId]['conditionListUseOr' + variableSuffix] :
                        false,
        conditionListLogicalOperator: this.state.mappings[props.mappingId].links[props.linkId]['conditionListLogicalOperator' + variableSuffix] !== undefined ?
                                      (
                                        this.state.mappings[props.mappingId].links[props.linkId]['conditionListUseOr' + variableSuffix] ?
                                        'or' :
                                        'and'
                                      ) :
                                      'and',
    });
  }

  handleCloseConditionDialog = () => {
    this.setState({
        conditionDialogOpen: false,
        conditionTabIndex: 0,
        currCondition: {
          type: '',
          value: '',
          xpath: '',
          negation: false,
          error_type_text: '',
          error_value_text: '',
          error_xpath_text: '',
        },
        currConditions: [],
    });
  }

  handleCheckWhetherToLeaveConditionDialog = actionType => () => {
    this.handleCheckWhetherToLeaveConditionDialogCallBack(actionType);
  }

  handleCheckWhetherToLeaveConditionDialogCallBack = actionType => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.conditionChanged) {
      if(actionType === 'close') {
        this.setState({
          confirmCloseConditionSnackbarShown: true
        });
      }
      else if(actionType === 'goBack') {
        this.setState({
          confirmGoBackConditionSnackbarShown: true
        });
      }
    }
    else {
      // Close the dialog
      if(actionType === 'close') {
        this.handleCloseConditionDialog();
      }
      else if(actionType === 'goBack') {
        this.conditionGoBackTo({tabIndex: 0});
      }
    }
  }

  handleCloseConfirmCloseConditionSnackbar = () => {
    this.setState({
      confirmCloseConditionSnackbarShown: false
    });
  }

  handleConfirmCloseConditionDialog = () => {
    this.setState({
      confirmCloseConditionSnackbarShown: false,
      conditionDialogOpen: false,
      conditionTabIndex: 0,
      currCondition: {
        type: '',
        value: '',
        xpath: '',
        negation: false,
        error_type_text: '',
        error_value_text: '',
        error_xpath_text: '',
      },
      currConditions: [],
      conditionChanged: false,
    });
  }

  handleCloseConfirmGoBackConditionSnackbar = () => {
    this.setState({
      confirmGoBackConditionSnackbarShown: false
    });
  }

  handleConfirmGoBackConditionDialog = () => {
    this.setState({
      confirmGoBackConditionSnackbarShown: false,
      conditionTabIndex: 0,
      conditionChanged: false,
      currCondition: {
        type: '',
        value: '',
        xpath: '',
        negation: false,
        error_type_text: '',
        error_value_text: '',
        error_xpath_text: '',
      },
    });
  }

  handleConditionDialogToggleFullScreen = () => {
    this.setState({
      conditionDialogFullScreen: !this.state.conditionDialogFullScreen
    });
  }

  // props: {condition: <Object>}
  handleSaveCondition = props => {
    // New array to replace the old one
    let array = [];
    let newCondition = Object.assign({}, props.condition); // Copy

    // Setting new ID
    if(newCondition.id === undefined) {
      newCondition.id = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    }
    // copy currConditions
    array = this.state.currConditions.slice(); // Create a copy

    let conditionIndex = this.state.currConditions.findIndex(
      obj => obj.id === newCondition.id
    );

    // Case Existing Condition
    if(conditionIndex !== -1) {
      array[conditionIndex] = newCondition;
    }

    // Case New Condition
    else {
      // Adding to the list of current conditions the new condition
      array.push(newCondition);
    }

    // Set it to the currConditions array
    this.setState({
      currConditions: array,
      conditionChanged: false,
      // Then update the respective array in the origin entity
    }, function() {
      this.handleUpdateConditionsCallBack()
    });
  }

  // Using currConditionProps from the state
  // this.state.currConditionProps: {mappingId: <Text>, linkId*: <Text>}
  // * Optional (can be undefined)
  handleUpdateConditionsCallBack = optionalProps => {

    let props = optionalProps !== undefined ? optionalProps : {};
    let conditionProps = props.conditionProps !== undefined ? props.conditionProps : undefined;
    if(conditionProps === undefined) {
      conditionProps = this.state.currConditionProps;
    }
    let conditions = props.conditions !== undefined ? props.conditions : undefined;
    if(conditions === undefined) {
      conditions = this.state.currConditions;
    }
    let variableSuffix = ''; // Case targetRelation (initially)
    // Determine whether it is a condition of the target node or the relation node
    if(conditionProps.type !== undefined) {
      // That can either be a case of targetNode or targetRelation (empty)
      variableSuffix = conditionProps.type.charAt(0).toUpperCase() + conditionProps.type.slice(1);
    }
    const successMsg = 'The condition was updated successfully';

    // Link
    if(conditionProps.linkId !== undefined && conditionProps.linkId !== -1) {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [conditionProps.mappingId]: {
            ...this.state.mappings[conditionProps.mappingId],
            links: {
              ...this.state.mappings[conditionProps.mappingId].links,
              [conditionProps.linkId]: {
                ...this.state.mappings[conditionProps.mappingId].links[conditionProps.linkId],
                ['conditions' + variableSuffix]: conditions,
              }
            }
          }
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: successMsg});
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.conditionProps = this.state.currConditionProps;
          newProps.conditions = this.state.currConditions;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUpdateConditionsCallBack',
            argument: newProps
          });
        }
      });

    }
    // Mapping (Domain)
    else if(conditionProps.mappingId !== undefined && conditionProps.mappingId !== -1) {
      // Add to mapping
      this.setState({
        mappings: {
          ...this.state.mappings,
          [conditionProps.mappingId]: {
            ...this.state.mappings[conditionProps.mappingId],
            conditions: conditions,
          }
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: successMsg});
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.conditionProps = this.state.currConditionProps;
          newProps.conditions = this.state.currConditions;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUpdateConditionsCallBack',
            argument: newProps
          });
        }
      });
    }
  }

  handleUpdateConditionListAndOrCallBack = optionalProps => {
    let props = optionalProps !== undefined ? optionalProps : {};
    let conditionProps = props.conditionProps !== undefined ? props.conditionProps : undefined;
    if(conditionProps === undefined) {
      conditionProps = this.state.currConditionProps;
    }
    let conditionListUseOr = props.conditionListUseOr !== undefined ? props.conditionListUseOr : undefined;
    if(conditionListUseOr === undefined) {
      conditionListUseOr = this.state.currConditionListUseOr;
    }

    let variableSuffix = ''; // Case targetRelation (initially)
    // Determine whether it is a condition of the target node or the relation node
    if(conditionProps.type !== undefined) {
      // That can either be a case of targetNode or targetRelation (empty)
      variableSuffix = conditionProps.type.charAt(0).toUpperCase() + conditionProps.type.slice(1);
    }
    const successMsg = 'The condition list was updated successfully';

    // Add to link
    if(conditionProps.linkId !== undefined && conditionProps.linkId !== -1) {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [conditionProps.mappingId]: {
            ...this.state.mappings[conditionProps.mappingId],
            links: {
              ...this.state.mappings[conditionProps.mappingId].links,
              [conditionProps.linkId]: {
                ...this.state.mappings[conditionProps.mappingId].links[conditionProps.linkId],
                ['conditionListUseOr' + variableSuffix]: conditionListUseOr,
                ['conditionListLogicalOperator' + variableSuffix]: conditionListUseOr ? 'or' : 'and',
              }
            }
          }
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: successMsg});
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.conditionProps = this.state.currConditionProps;
          newProps.conditionListUseOr = this.state.currConditionListUseOr;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUpdateConditionListAndOrCallBack',
            argument: newProps
          });
        }
      });

    }
    // Add to mapping (Domain)
    else if(conditionProps.mappingId !== undefined && conditionProps.mappingId !== -1) {
      // Add to mapping
      this.setState({
        mappings: {
          ...this.state.mappings,
          [conditionProps.mappingId]: {
            ...this.state.mappings[conditionProps.mappingId],
            conditionListUseOr: conditionListUseOr,
            conditionListLogicalOperator: conditionListUseOr ? 'or' : 'and',
          }
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: successMsg});
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.conditionProps = this.state.currConditionProps;
          newProps.conditionListUseOr = this.state.currConditionListUseOr;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUpdateConditionListAndOrCallBack',
            argument: newProps
          });
        }
      });
    }
  }

  // props: {conditionsToBeDeleted: <Array>}
  handleDeleteConditions = props => {
    // New array to replace the old one
    let array = this.state.currConditions.slice(); // Create a copy

    for (const condition of props.conditionsToBeDeleted) {
      let originArrayIndex = array.findIndex(
        obj => obj.id === condition.id
      );
      if(originArrayIndex !== -1) {
        array.splice(originArrayIndex, 1);
      }
    }

    // Set it to the currLabelGeneratorDeclarations array
    this.setState({
      currConditions: array,
      // Then update the respective array in the origin entity
    }, function() {
      this.handleUpdateConditionsCallBack();
    });
  }

  // props: {condition: <Object>, index: <integer>}
  selectCondition = props => {
    this.setState({
      currCondition: props.condition
    });
  }

  // Used when showing the form to add new label generator
  resetCondition = () => {
    // Construct new UI for condition
    this.setState({
      currCondition: {
        type: '',
        value: '',
        xpath: '',
        negation: false,
        error_type_text: '',
        error_value_text: '',
        error_xpath_text: '',
      }
    });
  }

  // Condition Dialog Tabs and index
  showConditionForm = () => {
    this.setState({
      conditionTabIndex: 1
    });
  }

  handleConditionTabChange = (event, newValue) => {
    this.setState({
      conditionTabIndex: newValue,
    });
  }

  handleChangeConditionTabIndex = tabIndex => {
    this.setState({
      conditionTabIndex: tabIndex,
    });
  };

  handleChangeConditionTabIndexAndValue = props => {
    this.setState({
      conditionTabIndex: props.tabIndex,
    });
  };

  conditionGoBackTo = props => {
    this.setState({
      conditionTabIndex: props.tabIndex,
      conditionChanged: false,
    });
  }

  // props: {condition: <Object>}
  handleValidateConditionForm = props => {
    let condition = Object.assign({}, props.condition); // Copy
    // Flag denoting whether to proceed with submitting based on the form's validation
    let proceed = true;
    if(condition.type !== undefined) {
      if(condition.type === '') {
        condition.error_type_text = 'This Field is Required';
      }
    }
    if(condition.value !== undefined) {
      //if((condition.type === 'equals' || condition.type === 'exact_match' || condition.type === 'broader') && condition.value === '') {
      if((condition.type === 'exact_match' || condition.type === 'broader') && condition.value === '') {
        condition.error_value_text = 'This Field is Required';
      }
    }
    if(condition.xpath !== undefined) {
      if(condition.xpath === '') {
        condition.error_xpath_text = 'This Field is Required';
      }
    }
    if((condition.error_type_text !== '' && condition.error_type_text !== undefined) ||
       (condition.error_value_text !== '' && condition.error_value_text !== undefined) ||
       (condition.error_xpath_text !== '' && condition.error_xpath_text !== undefined)) {
      proceed = false;
    }

    this.setState({
      currCondition: condition,
    });
    return proceed;
  }

  // props: {fieldName: <Text>}
  handleConditionInputChange = props => event => {
    // Copy the new value
    const newVal = event !== null ?
                   (
                     props.fieldName === 'type' ?
                     event.value :
                     (
                       props.fieldName === 'negation' ?
                       event.target.checked :
                       event.target.value
                     )
                   ) :
                   '';
    this.setState({
      currCondition: {
        ...this.state.currCondition,
          [props.fieldName]: newVal,
          ['error_' + props.fieldName + '_text']: newVal === '' ? 'This Field is Required' : '',
        },
        conditionChanged: true,
    }, function() {
      // Resetting the "Value" field if not required (based on selected type)
      if(props.fieldName === 'type') {
        let valueFieldIsRequired = event !== null ?
                                   (event.value === 'equals' || event.value === 'exact_match' || event.value === 'broader') :
                                   false;
        this.setState({
          currCondition: {
            ...this.state.currCondition,
              error_value_text: '',// === valueFieldIsRequired ? 'This Field is Required' : '',
              value: valueFieldIsRequired ? this.state.currCondition.value : '',
            },
        });
      }
    });
  }

  handleConditionListAndOrInputChange = event => {
    // Copy the new value
    const andOrVal = event.target.checked;
    this.setState({
      currConditionListUseOr: andOrVal
    }, function() {
      // Updating the list of conditions in terms of and / or expression
      this.handleUpdateConditionListAndOrCallBack();
    });
  }

  // props: {condition*: <Object>,
  //         type: 'mapping | link',
  //         conditionProps: {mappingId: mappingId, linkId*: linkId, type**: 'targetNode | undefined'}
  // *  Optional (linkId only exist in case of link)
  // ** if type is undefined, then this is about the targetRelation and not the targetNode
  handleGoToConditionForm = props => () => {
    if(props.type === 'mapping') {
      this.handleMappingUseConditionCallBack(props.conditionProps);
    }
    else {//} if(props.type === 'link') {
      this.handleLinkUseConditionCallBack(props.conditionProps);
    }

    this.setState({
        currCondition: props.condition,
    }, function() {
      this.handleChangeConditionTabIndexAndValue({tabValue: 0, tabIndex: 1});
    });
  }

  // props: {conditionProps: {mappingId: mapping.id}}
  handleDirectDeleteCondition = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    // conditionProps: {mappingId: <Text>, linkId: <Text>, type: 'targetNode' | undefined}
    const conditionProps = props.conditionProps;
    let variableSuffix = ''; // Case targetRelation (initially)
    // Determine whether it is a condition of the target node or the relation node
    if(conditionProps.type !== undefined) {
      // That can either be a case of targetNode or targetRelation (empty)
      variableSuffix = conditionProps.type.charAt(0).toUpperCase() + conditionProps.type.slice(1);
    }
    const conditionToBeDeleted = props.condition;
    let currConditions = [];
    // Retrieving the current conditions according to the conditionProps
    // Case: Link
    if(conditionProps.linkId !== undefined && conditionProps.linkId !== -1) {
      currConditions = this.state.mappings[conditionProps.mappingId].links[conditionProps.linkId]['conditions' + variableSuffix] !== undefined ?
                       this.state.mappings[conditionProps.mappingId].links[conditionProps.linkId]['conditions' + variableSuffix].slice() :
                       [];

    }
    // Case: Mapping (Domain)
    else if(conditionProps.mappingId !== undefined && conditionProps.mappingId !== -1) {
      currConditions = this.state.mappings[conditionProps.mappingId].conditions !== undefined ?
                       this.state.mappings[conditionProps.mappingId].conditions.slice() :
                       [];
    }

    const index = currConditions.findIndex(obj => obj.id === conditionToBeDeleted.id);
    currConditions.splice(index, 1);

    this.setState({
      currConditions: currConditions,
      currConditionProps: conditionProps,
    }, () => {
      this.handleUpdateConditionsCallBack();
    });
  }

  // Finished with Condition related functions

  // Comment Related functions

  // For the whole X3ML
  handleUseComment = () => {
    console.log('handleX3MLUseComment');
    this.setState({
        commentDialogOpen: true,
        currCommentProps: '',
        currComments: this.state.currMappingProject.comments !== undefined && this.state.currMappingProject.comments !== null ?
                      this.state.currMappingProject.comments :
                      [],
    });
  }

  // For the domain
  // props: {mappingId: <Text>}
  handleMappingUseComment = props => (event) => {
    event.stopPropagation();
    this.handleMappingUseCommentCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>}
  handleMappingUseCommentCallBack = async props => {
    console.log('handleMappingUseComment');
    // Open Dialog
    this.setState({
        commentDialogOpen: true,
        currCommentProps: props,
        currComments: this.state.mappings[props.mappingId].comments !== undefined ?
                      this.state.mappings[props.mappingId].comments :
                      [],
    });
  }

  // For the link
  // props: {linkId: <Text>}
  handleLinkUseComment = props => (event) => {
    event.stopPropagation();
    this.handleLinkUseCommentCallBack(props);
  }

  // CallBack
  // props: {mappingId: <Text>, linkId: <Text>}
  handleLinkUseCommentCallBack = async props => {
    console.log('handleLinkUseComment');
    // Open Dialog
    this.setState({
        commentDialogOpen: true,
        currCommentProps: props,
        currComments: this.state.mappings[props.mappingId].links[props.linkId].comments !== undefined ?
                      this.state.mappings[props.mappingId].links[props.linkId].comments :
                      [],
    });
  }

  handleCloseCommentDialog = () => {
    this.setState({
        commentDialogOpen: false,
        commentTabIndex: 0,
        currComment: {
          type: '',
          rationale: '',
          alternatives: '',
          typicalMistakes: '',
          localHabits: '',
          linkToCookBook: '',
          exampleSource: '',
          exampleTarget: '',
          lastUpdateDate: '',
          lastUpdatePerson: '',
        },
        currComments: [],
    });
  }

  handleCheckWhetherToLeaveCommentDialog = actionType => () => {
    this.handleCheckWhetherToLeaveCommentDialogCallBack(actionType);
  }

  handleCheckWhetherToLeaveCommentDialogCallBack = actionType=> {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.commentChanged) {
      if(actionType === 'close') {
        this.setState({
          confirmCloseCommentSnackbarShown: true
        });
      }
      else if(actionType === 'goBack') {
        this.setState({
          confirmGoBackCommentSnackbarShown: true
        });
      }
    }
    else {
      // Close the dialog
      if(actionType === 'close') {
        this.handleCloseCommentDialog();
      }
      else if(actionType === 'goBack') {
        this.commentGoBackTo({tabIndex: 0});
      }
    }
  }

  handleCloseConfirmCloseCommentSnackbar = () => {
    this.setState({
      confirmCloseCommentSnackbarShown: false
    });
  }

  handleConfirmCloseCommentDialog = () => {
    this.setState({
      confirmCloseCommentSnackbarShown: false,
      commentDialogOpen: false,
      commentTabIndex: 0,
      currComment: {
        type: '',
        rationale: '',
        alternatives: '',
        typicalMistakes: '',
        localHabits: '',
        linkToCookBook: '',
        exampleSource: '',
        exampleTarget: '',
        lastUpdateDate: '',
        lastUpdatePerson: '',
      },
      currComments: [],
      commentChanged: false,
    });
  }

  handleCloseConfirmGoBackCommentSnackbar = () => {
    this.setState({
      confirmGoBackCommentSnackbarShown: false
    });
  }

  handleConfirmGoBackCommentDialog = () => {
    this.setState({
      confirmGoBackCommentSnackbarShown: false,
      commentTabIndex: 0,
      commentChanged: false,
      currComment: {
        type: '',
        rationale: '',
        alternatives: '',
        typicalMistakes: '',
        localHabits: '',
        linkToCookBook: '',
        exampleSource: '',
        exampleTarget: '',
        lastUpdateDate: '',
        lastUpdatePerson: '',
      },
    });
  }

  handleCommentDialogToggleFullScreen = () => {
    this.setState({
      commentDialogFullScreen: !this.state.commentDialogFullScreen
    });
  }

  // props: {comment: <Object>}
  handleSaveComment = props => {
    console.log(this.state);
    // New array to replace the old one
    let array = [];
    let newComment = Object.assign({}, props.comment); // Copy

    // Setting new ID
    if(newComment.id === undefined) {
      newComment.id = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    }
    newComment.lastUpdateDate = Date.now();
    newComment.lastUpdatePerson = this.props.user;

    // copy currComments
    array = this.state.currComments.slice(); // Create a copy

    let commentIndex = this.state.currComments.findIndex(
      obj => obj.id === newComment.id
    );

    // Case Existing Comment
    if(commentIndex !== -1) {
      array[commentIndex] = newComment;
    }

    // Case New Comment
    else {
      // Adding to the list of current comments the new comment
      array.push(newComment);
    }

    // Set it to the currComments array
    this.setState({
      currComments: array,
      commentChanged: false,
      // Then update the respective array in the origin entity
    }, function() {
      this.handleUpdateCommentsCallBack()
    });
  }

  // Using currCommentProps from the state
  // this.state.currCommentProps: {mappingId: <Text>, linkId*: <Text>}
  // * Optional (can be undefined)
  handleUpdateCommentsCallBack = optionalProps => {
    let props = optionalProps !== undefined ? optionalProps : {};
    let commentProps = props.commentProps !== undefined ? props.commentProps : undefined;
    if(commentProps === undefined) {
      commentProps = this.state.currCommentProps;
    }
    let comments = props.comments !== undefined ? props.comments : undefined;
    if(comments === undefined) {
      comments = this.state.currComments;
    }
    const successMsg = 'The comment was updated successfully';
    // Link
    if(commentProps.linkId !== undefined && commentProps.linkId !== -1) {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [commentProps.mappingId]: {
            ...this.state.mappings[commentProps.mappingId],
            links: {
              ...this.state.mappings[commentProps.mappingId].links,
              [commentProps.linkId]: {
                ...this.state.mappings[commentProps.mappingId].links[commentProps.linkId],
                comments: comments,
              }
            }
          }
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: successMsg});
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.commentProps = this.state.currCommentProps;
          newProps.comments = this.state.currComments;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUpdateCommentsCallBack',
            argument: newProps
          });
        }
      });

    }
    // Mapping (Domain)
    else if(commentProps.mappingId !== undefined && commentProps.mappingId !== -1) {
      // Add to mapping
      this.setState({
        mappings: {
          ...this.state.mappings,
          [commentProps.mappingId]: {
            ...this.state.mappings[commentProps.mappingId],
            comments: comments,
          }
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: successMsg});
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.commentProps = this.state.currCommentProps;
          newProps.comments = this.state.currComments;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUpdateCommentsCallBack',
            argument: newProps
          });
        }
      });
    }
    // X3ML
    else {
      // Add to the whole x3ML
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          comments: comments,
        }
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          const res = await mainService.saveMappingProject(this.state.currMappingProject);
          if(res.data.succeed) {
            this.showSuccessSnackBar({msg: successMsg});
          }
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.commentProps = this.state.currCommentProps;
          newProps.comments = this.state.currComments;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUpdateCommentsCallBack',
            argument: newProps
          });
        }
      });
    }
  }

  // props: {commentsToBeDeleted: <Array>}
  handleDeleteComments = props => {
    // New array to replace the old one
    let array = this.state.currComments.slice(); // Create a copy

    for (const comment of props.commentsToBeDeleted) {
      let originArrayIndex = array.findIndex(
        obj => obj.id === comment.id
      );
      if(originArrayIndex !== -1) {
        array.splice(originArrayIndex, 1);
      }
    }

    // Set it to the currLabelGeneratorDeclarations array
    this.setState({
      currComments: array,
      // Then update the respective array in the origin entity
    }, function() {
      this.handleUpdateCommentsCallBack();
    });
  }

  // props: {comment: <Object>, index: <integer>}
  selectComment = props => {
    this.setState({
      currComment: props.comment
    });
  }

  resetComment = () => {
    // Construct new UI for comment
    this.setState({
      currComment: {
        type: '',
        rationale: '',
        alternatives: '',
        typicalMistakes: '',
        localHabits: '',
        linkToCookBook: '',
        exampleSource: '',
        exampleTarget: '',
        lastUpdateDate: '',
        lastUpdatePerson: '',
      },
    });
  }

  // Comment Dialog Tabs and index
  showCommentForm = () => {
    this.setState({
      commentTabIndex: 1
    });
  }

  handleCommentTabChange = (event, newValue) => {
    this.setState({
      commentTabIndex: newValue,
    });
  }

  handleChangeCommentTabIndex = tabIndex => {
    this.setState({
      commentTabIndex: tabIndex,
    });
  };

  handleChangeCommentTabIndexAndValue = props => {
    this.setState({
      commentTabIndex: props.tabIndex,
    });
  };

  commentGoBackTo = props => {
    this.setState({
      commentTabIndex: props.tabIndex,
      commentChanged: false,
    });
  }

  // props: {comment: <Object>}
  handleValidateCommentForm = props => {
    let comment = Object.assign({}, props.comment); // Copy
    // Flag denoting whether to proceed with submitting based on the form's validation
    let proceed = true;
    if(comment.rationale !== undefined) {
      if(comment.rationale === '') {
        comment.error_rationale_text = 'This Field is Required';
        proceed = false;
      }
    }

    this.setState({
      currComment: comment,
    });
    return proceed;
  }

  // props: {fieldName: <Text>}
  handleCommentInputChange = props => event => {
    // Copy the new value
    const newVal = event !== null ?
                   event.target.value :
                   '';
    this.setState({
      currComment: {
        ...this.state.currComment,
          [props.fieldName]: newVal,
          error_rationale_text: props.fieldName === 'rationale' ?
                                (newVal === '' ? 'This Field is Required' : '') :
                                '',
        },
        commentChanged: true,
    });
  }

  // props: {comment*: <Object>,
  //         type: 'mapping | link',
  //         commentProps: {mappingId: mappingId, linkId*: linkId,}
  // *  Optional (linkId only exist in case of link)
  handleGoToCommentForm = props => () => {
    if(props.type === 'mapping') {
      this.handleMappingUseCommentCallBack(props.commentProps);
    }
    else {//} if(props.type === 'link') {
      this.handleLinkUseCommentCallBack(props.commentProps);
    }

    this.setState({
        currComment: props.comment,
    }, function() {
      this.handleChangeCommentTabIndexAndValue({tabValue: 0, tabIndex: 1});
    });
  }

  // props: {commentProps: {mappingId: mapping.id}}
  handleDirectDeleteComment = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    props = props.argument !== undefined ? props.argument : props;
    // commentProps: {mappingId: <Text>, linkId: <Text>}
    const commentProps = props.commentProps;
    const commentToBeDeleted = props.comment;
    let currComments = [];
    // Retrieving the current comments according to the commentProps
    // Case: Link
    if(commentProps.linkId !== undefined && commentProps.linkId !== -1) {
      currComments = this.state.mappings[commentProps.mappingId].links[commentProps.linkId].comments !== undefined ?
                     this.state.mappings[commentProps.mappingId].links[commentProps.linkId].comments.slice() :
                     [];

    }
    // Case: Mapping (Domain)
    else if(commentProps.mappingId !== undefined && commentProps.mappingId !== -1) {
      currComments = this.state.mappings[commentProps.mappingId].comments !== undefined ?
                     this.state.mappings[commentProps.mappingId].comments.slice() :
                     [];
    }

    const index = currComments.findIndex(obj => obj.id === commentToBeDeleted.id);
    currComments.splice(index, 1);

    this.setState({
      currComments: currComments,
      currCommentProps: commentProps,
    }, () => {
      this.handleUpdateCommentsCallBack();
    });
  }

  // Finished with comment related functions

  // props: {showDialog: <Boolean>}
  convertAndsaveX3ml = props => async () => {
    this.props.toggleRootBackDdropCallBack({booleanValue: true, message: 'Producing the X3ML, please wait ...'});
    await this.convertAndsaveX3mlCallBack({showDialog: true});
    this.props.toggleRootBackDdropCallBack({booleanValue: false});
  }

  // props: {showDialog: <Boolean>}
  convertAndsaveX3mlCallBack = async props => {
    // validationObject: {isValid: <Boolean>, firstErrorId: <ID or -1>, eroneousComponentType: 'mapping' | 'link'};
    let validationObject = await this.validateWholeMappingProject();
    let isValid = validationObject.isValid;

    if(validationObject.firstErrorId !== -1) {
      this.scrollToLinkOrMappingByIdCallBack({id: validationObject.firstErrorId});
    }

    if(isValid) {
      let model = { id: this.state.currMappingProject.id };

      try {
        //let response = await mappingTableViewService.convertAndsaveX3ml(model);
        let response = await mappingTableViewService.convertAndsaveX3mlByMappingProjectId(model);
        if(response.data.succeed) {
          // Show the X3ML if requested
          if(props.showDialog) {
            var format = require('xml-formatter');
            var xml = response.data.x3ml;
            var options = {indentation: '  ', stripComments: true, collapseContent: true};

            var formattedXml = format(xml, options);
            this.setState({
              xmlDialogOpen: true,
              xmlDialogInput: formattedXml,
              xmlDownloadable: true,
              downloadFileType: 'x3mlOutput',
              xmlCodeMirrorTitle: 'Produced X3ML',
              isCodeMirrorEditable: true,
              codeMirorInputState: 'xmlDialogInput',
              currMappingProject: {
                ...this.state.currMappingProject,
                x3mlOutputRelativePath: 'x3ml-output/' + this.state.currMappingProject.id + '/3m-x3ml-output.x3ml',
              },
            });
            // NOTE that downloadFileType must become 'x3mlOutput' before opening the dialog
          }
          this.showSuccessSnackBar({msg: response.data.msg});
        }
        else {
          this.props.showErrorSnackBar({msg: response.data.msg, iconSize: 'large'});
          console.error(response.data.msg);
        }
        return response;
      } catch (e) {
        this.props.showErrorSnackBar({msg: 'Some error occured while producing the X3ML model!'});
        console.error('Some error occured while producing the X3ML model!');
        if(e.response !== undefined) {
          console.error(e.response.status);
          return e.response;
        }
      }
    }
    else {
      this.props.showErrorSnackBar({msg: 'The X3ML engine could not be executed due to errors in the project. Please resolve any issue and then try again', iconSize: 'large'});
    }
  }

  transformWithX3mlEngine = async (props) => {
    // Show dialog when there is not any variable to controll that
    let showDialog = props !== undefined ? (props.showDialog !== undefined ? props.showDialog : true) : true;
    let outputFormat = props !== undefined ? (props.outputFormat !== undefined ? props.outputFormat : undefined) : 'rdf';
    let model = { id: this.state.currMappingProject.id, outputFormat: outputFormat };
    try {
      let response = await mappingTableViewService.transformWithX3mlEngineByMappingProjectId(model);
      if(response.data.succeed) {
        // Re-retrieve the currentMappingProject to get the rdfOutputRelativePath
        //let resMappingProject = await mainService.retrieveMappingProjectById({id: this.props.history.location.state.selectedId});
        let resMappingProject = await mainService.retrieveMappingProjectById({id: this.state.currMappingProject.id});
        if(resMappingProject.data !== null) {
          // this.setState({
          //     currMappingProject: resMappingProject.data,
          // });
          this.setState({
            currMappingProject: {
              ...this.state.currMappingProject,
              [outputFormat + 'OutputRelativePath']: 'rdf-output/' + this.state.currMappingProject.id + '/3m-transformed-output.' + outputFormat,
              x3mlOutputRelativePath: resMappingProject.data.x3mlOutputRelativePath,
            },
          });
          // rdfOutputRelativePath
          // ttlOutputRelativePath
          // x3mlOutputRelativePath
        }
        if(showDialog) {
          this.setState({
            xmlDialogOpen: true,
            xmlDialogInput: response.data.output,
            xmlDownloadable: true,
            //downloadFileType: 'rdfOutput',
            downloadFileType: outputFormat + 'Output',
            xmlCodeMirrorTitle: 'Transformed Output (' + (outputFormat !== undefined ? outputFormat.toUpperCase() : 'RDF') + ' file)',
            isCodeMirrorEditable: false,
            codeMirorInputState: undefined,
            currMappingProject: {
              ...this.state.currMappingProject,
              [outputFormat + 'OutputRelativePath']: 'rdf-output/' + this.state.currMappingProject.id + '/3m-transformed-output.' + outputFormat,
            },
          });
        }
        else {
          this.showSuccessSnackBar({msg: 'The ' + (outputFormat !== undefined ? outputFormat.toUpperCase() : 'RDF') + ' file was created successfully!'});
        }

        if(response.data.errorMessage !== undefined) {
          // The transformation was complete, but with errors
          this.setState({
            transformationErrorMessage: response.data.errorMessage,
            transformationErrors: response.data.errors,
          }, function() {
            this.handleSuccessWithErrorsSnackbarShownOpen();
          });
        }
        else {
          this.showSuccessSnackBar({msg: response.data.msg});
        }
      }
      else {
        this.props.showCustomSnackBar({
          msg: response.data.msg + (response.data.errorMessage !== '' ? ('<br/>' + response.data.errorMessage) : '') + '<br/>' + response.data.exceptionMessage,
          hasHtmlContent: true,
          type: 'error',
        });

        console.error(response.data.msg);
      }
      return response;
    } catch (e) {
      this.props.showErrorSnackBar({msg: 'Some error occured while transforming with the X3ML engine!', iconSize: 'large'});
      console.error('Some error occured while transforming with the X3ML engine!');
      if(e.response !== undefined) {
        console.error(e.response.status);
        return e.response;
      }
    }
  }

  convertToX3mlAndTransformWithX3mlEngine = async () => {
    let validationObject = await this.validateWholeMappingProject();
    let isValid = validationObject.isValid;
    if(isValid) {
      try {
        this.props.toggleRootBackDdropCallBack({booleanValue: true, message: 'Generating the RDF, please wait ...'});
        await this.convertAndsaveX3mlCallBack({showDialog: false});
        await this.transformWithX3mlEngine();
        this.props.toggleRootBackDdropCallBack({booleanValue: false});
      }
      catch (e) {
        this.props.toggleRootBackDdropCallBack({booleanValue: false});
        this.props.showErrorSnackBar({msg: 'Some error occured while transforming the RDF file.', iconSize: 'large'});
        console.error('Some error occured while transforming the RDF file.');
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
    else {
      this.props.showErrorSnackBar({msg: 'There are errors in the project and therefore the X3ML cannot be generated', iconSize: 'large'});
    }
  }

  convertToX3mlAndTransformWithX3mlEngineAndVisualise = async () => {
    let validationObject = await this.validateWholeMappingProject();
    let isValid = validationObject.isValid;
    if(isValid) {
      this.props.toggleRootBackDdropCallBack({
        booleanValue: true,
        message: <span>Transforming the RDF & preparing the RDF Visualizer. <br/> Please wait ...</span>
      });
      await this.convertAndsaveX3mlCallBack({showDialog: false});
      // Generate the TTL
      const outputRes = await this.transformWithX3mlEngine({outputFormat: 'ttl', showDialog: false});
      // Retrieve all classes for the output ttl file
      let model = { id: this.state.currMappingProject.id, typeFormat: 'ttl' };
      const outputClassesRes = await reasonerService.retrieveAllOutputClasses(model);
      if(outputClassesRes.data.succeed) {
        // Show the new UI Dialog with all the classes to select subject
        this.setState({
          rdfVisualizerInputDialogOpen: true,
          xmlDialogInput: outputRes.data.output,
          xmlDownloadable: true,
          downloadFileType: 'rdfOutput',
          xmlCodeMirrorTitle: 'Visualize RDF Output',
          isCodeMirrorEditable: false,
          codeMirorInputState: undefined,
          currMappingProject: {
            ...this.state.currMappingProject,
            ttlOutputRelativePath: 'rdf-output/' + this.state.currMappingProject.id + '/3m-transformed-output.ttl',
          },
          outputClasses: outputClassesRes.data.classUris,
          instancesOfSelectedOutputClass: [],
        });
      }
      else {
        this.props.showErrorSnackBar({msg: outputClassesRes.data.message, iconSize: 'large'});
      }
      this.props.toggleRootBackDdropCallBack({booleanValue: false});

    }
    else {
      this.props.showErrorSnackBar({msg: 'There are errors in the project and therefore the X3ML cannot be generated', iconSize: 'large'});
    }
  }

  // NEW approach with confirm not working properly

  handleCloseConfirmproduceOutputWithNotAllSourceFilesSnackbar = props => () => {
    this.props.closeSnackbar(props.id);
  }


  // Called when clicking on the main part of the output split button (i.e. Produce RDF)
  // and may or not show a confirmation dialog, depending on the participant source files
  confirmProduceOutputButtonGroupHandleClick = async () => {
    // if "this.state.fileMetadataObjectList.sourceFiles" contains any fileMetadata that is not participating
    // (participating is false), then tell the user
    if(this.state.fileMetadataObjectList.sourceFiles.filter(obj => obj.participating === false).length > 0) {
      this.showConfirmSnackBar({
        msg: 
          `<span style="display: flex; width:420px">
            Please note that certain source input files will be disregarded during 
            the transformation process as they have been left unchecked in the 
            respective file metadata configuration.
          </span>`,
        iconSize: 'large',
        onConfirmClick: this.produceOutputButtonGroupHandleClick,
        confirmButtonLabel: 'Yes proceed',
        onCancelClick: this.handleCloseConfirmproduceOutputWithNotAllSourceFilesSnackbar,
        cancelButtonLabel: 'Cancel',
      });
    }
    else {
      this.produceOutputButtonGroupHandleClickCallBack();
    }
  }

  // Called to produce output when clicking on the main part of the output split button (i.e. Produce RDF)
  produceOutputButtonGroupHandleClick = props => async () => {
    // Close confirmation snackbar
    this.props.closeSnackbar(props.id);
    // Excecute the function to produce output
    this.produceOutputButtonGroupHandleClickCallBack();
  }

  // Called when clicking on the main part of the output split button (i.e. Produce RDF)
  produceOutputButtonGroupHandleClickCallBack = async () => {
    // console.info(`You clicked ${produceOutputButtonGroupOptions[this.state.produceOutputButtonGroupSelectedIndex].label}`);
  
    let validationObject = await this.validateWholeMappingProject();
    let isValid = validationObject.isValid;
    if(isValid) {
      try {
        this.props.toggleRootBackDdropCallBack({booleanValue: true, message: 'Generating the output, please wait ...'});
        await this.convertAndsaveX3mlCallBack({showDialog: false});
        await this.transformWithX3mlEngine({
          showDialog: true,
          outputFormat: produceOutputButtonGroupOptions[this.state.produceOutputButtonGroupSelectedIndex].value
        });
        this.props.toggleRootBackDdropCallBack({booleanValue: false});
      }
      catch (e) {
        this.props.toggleRootBackDdropCallBack({booleanValue: false});
        this.props.showErrorSnackBar({msg: 'Some error occured while transforming the RDF file.', iconSize: 'large'});
        console.error('Some error occured while transforming the RDF file.');
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
    else {
      this.props.showErrorSnackBar({msg: 'There are errors in the project and therefore the X3ML cannot be generated', iconSize: 'large'});
    }
  };

  produceOutputButtonGroupHandleMenuItemClick = (event, index) => {
    this.setState({
      produceOutputButtonGroupSelectedIndex: index,
      produceOutputButtonGroupAnchorRef: null,
    });
  };

  produceOutputButtonGroupHandleOpen = event => {
    this.setState({
      produceOutputButtonGroupAnchorRef: event.currentTarget,
    });
  };

  // Called when clicking on any option of the "Produce Output" split button
  produceOutputButtonGroupHandleClose = event => {
    this.setState({
      produceOutputButtonGroupAnchorRef: null,
    });
  };

  loadInstancesOfOutputClassCallBack = async (props) => {
    let model = { id: this.state.currMappingProject.id, classUri: props.classUri };
    const res = await reasonerService.retrieveInstancesOfClass(model);
    if(res.data.succeed) {
      this.setState({
        instancesOfSelectedOutputClass: res.data.instanceClassUris,
      });
    }
    else {
      this.props.showErrorSnackBar({msg: res.data.message, iconSize: 'large'});
    }
  }

  // instance: <Text>
  // value is the uri and label is some text
  handleOpenInRdfVisualizer = instance => () => {
    // this.props.rdfVisualizerUrl holds the base url where the RDF Visualizer listens
    // i.e. 'http://139.91.183.126:8083/RDFVisualizer/?resource=' +  instance.value +'&filename=' + this.state.currMappingProject.id + '/3m-transformed-output.ttl',
    window.open(
      this.props.rdfVisualizerUrl + '?resource=' +  instance +'&filename=' + this.state.currMappingProject.id + '/3m-transformed-output.ttl',
      'RDF Visualiser-' + this.state.currMappingProject.id
    );
  }

  // ****************************************************
  // **************** FileMetada Editing ****************
  // ****************************************************

  handleOpenFileMetadataDialog = () => {
    this.setState({
      configDialogOpen: true
    }, () => {
      // Holding copy of the filemetadata list before appling any change on them
      let oldFileMetadataObjectList = Object.assign({}, this.state.fileMetadataObjectList); // Copy
      this.setState({
        oldFileMetadataObjectList: oldFileMetadataObjectList,
      });
    });
  }

  handleOpenX3mlImportDialog = () => {
    this.setState({
      x3mlImportDialogOpen: true
    });
  }

  handleCloseX3mlImportDialog = () => {
    this.setState({
      x3mlImportDialogOpen: false
    });
  }

  handleConfigToggleFullScreen = () => {
    this.setState({
      configDialogFullScreen: !this.state.configDialogFullScreen
    });
  }

  handleX3mlImportToggleFullScreen = () => {
    this.setState({
      x3mlImportDialogFullScreen: !this.state.x3mlImportDialogFullScreen
    });
  }

  // Used to set value to field programmaticaly (i.e set the title out of file name)
  // props: {
  //   fieldName: <NAME_OF_FIELD>,
  //   listType: 'source | target | generatorPolicy',
  //   index: <INTEGER>, value: <TEXT>,
  //   files: <Array>
  // }
  handleSetFileMetadataInput = async props => {
    let returnVal = null
    let array = []; // To hold a copy
    let newFileMetadata = props.newFileMetadata !== undefined ? props.newFileMetadata : undefined;
    if(newFileMetadata === undefined) {

      array = update(this.state.fileMetadataObjectList[props.listType + 'Files'], {
        [props.index]: {
          [props.fieldName]: {$set: props.value},
          files: {$set: props.files !== undefined ? props.files : []},
        }
      });
      newFileMetadata = await this.storeFileMetadata(array[props.index]);
      returnVal = newFileMetadata.id;
      // Setting it back to the state
      this.setState({
        fileMetadataObjectList: {
          ...this.state.fileMetadataObjectList,
          [props.listType + 'Files']: array
        }
      }, async () =>{

        if(props.listType === 'source') {
          await this.executeXpathResolverCallBack({fileMetadataId: newFileMetadata.id, doRetrieve: true, doSave: true});
        }
        else if(props.listType === 'target') {
          console.log();
          await this.executeReasonerCallBack({fileMetadataId: newFileMetadata.id, doRetrieve: true, doSave: true});
        }
        let newProps = Object.assign({}, props); // Copy
        newProps.newFileMetadata = newFileMetadata;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleSetFileMetadataInput',
          argument: newProps
        });
      });
    }
    // Synced by others
    else {
      let newFileMetadataIndex = this.state.fileMetadataObjectList[props.listType + 'Files'].findIndex(
        obj => obj.id === newFileMetadata.id
      );
      if(newFileMetadataIndex !== -1) {
        array = update(this.state.fileMetadataObjectList[props.listType + 'Files'], {
          [newFileMetadataIndex]: {$set: newFileMetadata}
        });
      }
      returnVal = newFileMetadata.id;
      // Setting it back to the state
      this.setState({
        fileMetadataObjectList: {
          ...this.state.fileMetadataObjectList,
          [props.listType + 'Files']: array
        }
      }, async () => {
        if(props.listType === 'source') {
          await this.updateFileMetadataInObjectList({fileMetadataId: newFileMetadata.id, listType: 'source'});
        }
        else if(props.listType === 'target') {
          console.log();
          await this.updateFileMetadataInObjectList({fileMetadataId: newFileMetadata.id, listType: 'target'});
        }
      });
    }
    return returnVal;
  }

  // Handle the input change of any field in the metadata of any file
  // props: {
  //    fieldName: '<NAME_OF_FIELD_THAT_IS_CHANGED>',
  //    listType: 'source | targe',
  //    index: <NUMBER_(INDEX_OF_ITEM_IN_MAIN_LIST>)>,
  //    nameSpaceIndex: <NUMBER_(INDEX_OF_ITEM_IN_NAMESPACE_LIST_LOCATED_IN_ITEM_IN_MAIN_LIST>)>
  // }
  handleFileMetadataInputChange = props => event => {
    // Tracking files for which the namespace field was changed
    // in terms of: {listType, index, nameSpaceIndex}
    //if(props.nameSpaceIndex !== undefined) {
      const inputKey = props.listType + '-' + props.index + '-' + props.nameSpaceIndex;
      const inputProps = props;
      this.setState(function(prevState, props) {
        prevState.fileMetadataInputChangeMap.set(inputKey, inputProps);
        return {
          fileMetadataInputChangeMap: prevState.fileMetadataInputChangeMap
        }
      });
    //}

    let array = []; // To hold a copy
     // fileMetadataObjectList
    if(props.nameSpaceIndex === undefined) {
      array = update(this.state.fileMetadataObjectList[props.listType + 'Files'], {[props.index]: {[props.fieldName]: {$set: event.target.value}}});
    }
    // namespacePrefixList
    else {
      array = update(this.state.fileMetadataObjectList[props.listType + 'Files'], {[props.index]: {namespacePrefixList: {[props.nameSpaceIndex]: {[props.fieldName]: {$set: event.target.value}}}}});
    }
    // Setting it back to the state
    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [props.listType + 'Files']: array
      },
      filesManagementChanged: true,
    });
  }

  // props: {listType: 'source | targe', index: <Integer>}
  // index holds the index of the fileMetadata within the source or taget list
  addNamespaceInFileMetadata = props => () => {
    let fileMetadata = Object.assign({}, this.state.fileMetadataObjectList[props.listType + 'Files'][props.index]); // Copy
    fileMetadata.namespacePrefixList.push({label: '', value: ''});
    let array = this.state.fileMetadataObjectList[props.listType + 'Files'].slice();
    array[props.index] = fileMetadata;
    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [props.listType + 'Files']: array,
      },
      filesManagementChanged: true,
    });
  }

  handleCloseConfirmRemoveFileMetadataNamespaceSnackbar = props => () => {
    this.setState({
      showFileMetadataDialogBackDrop: false,
    }, () => {
      this.props.closeSnackbar(props.id);
    });
  }

  // props: {listType: 'source | targe', index: <Integer>, nameSpaceIndex: <Integer>}
  // index: the index of the fileMetadata within the source or taget list
  // nameSpaceIndex: the index of the namespafileMetadata within the source or taget list
  handleRemoveFileMetadataNamespace = props => () => {
    this.setState({
      showFileMetadataDialogBackDrop: true,
    }, () => {
      this.showConfirmSnackBar({
        msg: 'You are about to remove this namespace. Are you sure you want to proceed?',
        iconSize: 'large',
        onConfirmClick: this.handleConfirmRemoveFileMetadataNamespace,
        confirmButtonLabel: 'Yes proceed',
        onCancelClick: this.handleCloseConfirmRemoveFileMetadataNamespaceSnackbar,
        cancelButtonLabel: 'Cancel',
        argument: props,
      });
    });
  }

  // props: {
  //   argument: {
  //     listType: 'source | targe',
  //     index: <Integer>,
  //     nameSpaceIndex: <Integer>
  //   },
  //   id: <SnackBar's ID>
  // }
  handleConfirmRemoveFileMetadataNamespace = props => () => {
    const argument = props.argument;
    let fileMetadata = Object.assign({}, this.state.fileMetadataObjectList[argument.listType + 'Files'][argument.index]); // Copy
    fileMetadata.namespacePrefixList.splice(argument.nameSpaceIndex, 1);
    let array = this.state.fileMetadataObjectList[argument.listType + 'Files'].slice();
    array[argument.index] = fileMetadata;
    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [argument.listType + 'Files']: array,
      },
      filesManagementChanged: true,
      showFileMetadataDialogBackDrop: false,
    }, () => {
      this.props.closeSnackbar(props.id);
    });
  }

  storeFileMetadata = async (fileMetadata) => {
    try {
      const res = await fileUploadService.storeFileMetadata(fileMetadata);
      if(res.data.succeed) {
        // Update the state with the new generated ID of this FileMetadata
        if(fileMetadata.id === undefined) {
          fileMetadata.id = res.data.fileMetadataId;
        }
        return fileMetadata;
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.message, iconSize: 'large'});
        return null;
      }
    } catch (e) {
      console.error('Some error occured while storing metadata to the database!');
      this.props.showErrorSnackBar({msg: 'Some error occured while storing metadata to the database!', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  // Update the values for the currently loaded models based on the
  // currently loaded mapping project
  updateLoadedModels = async () => {
    try {
      let inputData = {
        id: this.state.currMappingProject.id,
        type: 'target'
      }
      var res = await reasonerService.updateModels(inputData);
      //var initReasonerModelsRes = await mainService.retrieveOptionsTest();
      if(res.data !== false) {
        console.log('Reasoner Models have been successfully updated');
        // Close the project mapping's metadata dialog
        this.setState({
          configDialogOpen: false,
        });
      }
      else {
        console.error('Some error occured while updating the reasoner\'s models!');
      }
    }
    catch (e) {
      console.error('Some error occured while updating the reasoner\'s models!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // Using this.state.fileMetadataInputChangeMap, which contains:
  // {
  //  listType: 'source | targe',
  //  index: <NUMBER_(INDEX_OF_ITEM_IN_MAIN_LIST>)>,
  //  nameSpaceIndex: <NUMBER_(INDEX_OF_ITEM_IN_NAMESPACE_LIST_LOCATED_IN_ITEM_IN_MAIN_LIST>)>
  // }
  handleCloseFileMetadataDialog = async () => {
    // Check for namespace duplicates
    if(this.state.filesManagementChanged || this.state.fileMetadataInputChangeMap.length > 0) {
      if(await this.checkForNamespaceDuplicatesInMetadataFiles() || this.state.fileMetadataInputChangeMap.length > 0) {
        let prefixNamespaceTextTmpList = [];
        for (let obj of this.state.fileMetadataInputChangeMap.values()) {
          if(obj.nameSpaceIndex !== undefined) {
            if(this.state.fileMetadataObjectList[obj.listType + 'Files'][obj.index] !== undefined) {
              let namespacePrefix = this.state.fileMetadataObjectList[obj.listType + 'Files'][obj.index].namespacePrefixList[obj.nameSpaceIndex];
              if(namespacePrefix !== undefined) {
                const prefix = namespacePrefix.label;
                const namespace = namespacePrefix.value;

                // Avoid updating the namespaces if already done for the same <prefix, namespace> tuple
                if(!prefixNamespaceTextTmpList.includes(prefix + '-' + namespace)) {
                  this.handleUpdateNamespaceEveryWhere({prefix: prefix, namespace: namespace, doPersist: false});
                }
                // Holding a string representation of the tuple <prefix, namespace>
                // (to be used for avoiding calling the "handleUpdateNamespaceEveryWhere" function if already called for the tuple)
                prefixNamespaceTextTmpList.push(prefix + '-' + namespace);

                // Updating all the selections for the domain, links, intermediates and additionals
                // Only if the change is not applied on any prefix of a just created file
                // (because these prefixes are not in the namespacePrefixList, and we are sure that
                // the respective classes of the new fie have not been used yet)
                if(this.state.oldFileMetadataObjectList[obj.listType + 'Files'][obj.index] !== undefined) {
                  let oldNamespacePrefix = this.state.oldFileMetadataObjectList[obj.listType + 'Files'][obj.index].namespacePrefixList[obj.nameSpaceIndex];
                  if(oldNamespacePrefix !== undefined) {
                    const oldPrefix = oldNamespacePrefix.label;
                    const oldNamespace = oldNamespacePrefix.value;
                    // const oldPrefix = this.state.oldFileMetadataObjectList[obj.listType + 'Files'][obj.index].namespacePrefixList[obj.nameSpaceIndex].label;
                    // const oldNamespace = this.state.oldFileMetadataObjectList[obj.listType + 'Files'][obj.index].namespacePrefixList[obj.nameSpaceIndex].value;
                    this.handlePrefixAndNamespaceChangedOnSelectionsInMappingProjectCallBack({
                      oldPrefix: oldPrefix,
                      oldNamespace: oldNamespace,
                      newPrefix: prefix,
                      newNamespace: namespace
                    });
                  }
                  else {
                    console.warn("Please note that you have removed some namespace which might be used in the X3ML. If it is used, you will be getting null namespace issues!")
                  }
                }
              } // if(oldNamespacePrefix !== undefined) Closes
            }
          }
        }
        this.handleSaveFileMetadataDialog();
      }
    }
    else {
      this.setState({
        configDialogOpen: false,
      });
    }
  }

  // Toggling expansion of complex message that has short and long description
  // props: {
  //    type: 'source | target', fileMetadataArrayIndex: <Integer>,
  //    namespacePrefixListIndex: <Integer>
  // }
  toggleExpandFileMetadataNamespaceErrorMessage = props => () => {
    let tmpErrorMsg = Object.assign(
      {}, this.state.fileMetadataObjectList[props.type + 'Files'][props.fileMetadataArrayIndex].namespacePrefixList[props.namespacePrefixListIndex].error_label_text
    ); // Copy
    tmpErrorMsg.expanded = !tmpErrorMsg.expanded;

    let array = update(this.state.fileMetadataObjectList[props.type + 'Files'], {
      [props.fileMetadataArrayIndex]: {
        namespacePrefixList: {
          [props.namespacePrefixListIndex]: {
            error_label_text: {
              $set: tmpErrorMsg
            },
          }
        }
      }
    });
    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [props.type + 'Files']: array,
      }
    });
  }

  // Checks whether there is any prefix appearing more than once with different namespace
  // in any of the metadata files (The unique list also includes namespaces of generators)
  // props: {ref: <Reference_Object>}
  checkForNamespaceDuplicatesInMetadataFiles = async () => {
    let errorneousFileMetadataId = null;
    const {classes} = this.props;
    let procceed = true;
    // Gathering all <prefix, namespace> pairs (affects this.state.prefixNamespaceMap)
    await this.gatheringPrefixNamespacePairs();
    // Iterating source file metadata namespace list
    let sourceFileMetadataArray = this.state.fileMetadataObjectList.sourceFiles.slice(); // Create a copy
    let sourceFileMetadataArrayIndex = 0;
    for (let fileMetadata of sourceFileMetadataArray) {
      if(fileMetadata.namespacePrefixList !== null) {
        let namespacePrefixListIndex = 0;
        // To hold all these different namespace URIs (for duplicate prefixes) (it must be just one, if any)
        for (let namespacePrefix of fileMetadata.namespacePrefixList) {
          var sourceFileMetadataPrefix = namespacePrefix.label;
          var sourceFileMetadataNamespace = namespacePrefix.value;
          // Getting the namespace of the file's prefix from the prefixNamespaceMap (in the State)
          var sourceTmpNamespace = this.state.prefixNamespaceMap.get(sourceFileMetadataPrefix);
          // In case that file's namespace for that prefix is different than the one in the map,
          // then this is an issue.
          let errorMsg = '';
          if(sourceFileMetadataNamespace !== sourceTmpNamespace) {
            // for namespacePrefixList in the metadata files, label is the prefix and value is the namespace
            var sourceLongDescr =
              <div>
                <Typography style={{paddingBottom: 5}}>
                  You got this error because there are namespace conflicts bewteen two of your uploaded files.
                  In fact, the used prefix <code className={classes.secondaryCode}>{sourceFileMetadataPrefix}</code>,
                  is already declared with some namespace URI (<code className={classes.secondaryCode}>{sourceTmpNamespace}</code>),
                  which is different than <code className={classes.secondaryCode}>{sourceFileMetadataNamespace}</code>, declared here.
                </Typography>
                <Typography style={{paddingBottom: 5}}>
                  This will certainly cause several issues, since for each declared prefix, one and only one namespace URI must be assigned.
                </Typography>
                <Typography>
                  To fix this error simply rename the prefix to something else or use the same URI as the one already
                  declared <code className={classes.secondaryCode}>{'(' + sourceTmpNamespace + ')'}</code>. If you don't want to deal with
                  that issue now, simply delete any of the conflicting files and you should be able to continue.
                </Typography>
              </div>;
            errorMsg = {
              shortDescr: 'This prefix is already declared with different namespace',
              longDescr: sourceLongDescr,
              expanded: false,
              foundNamespaceURI: sourceTmpNamespace,
            };
            procceed = false;
            errorneousFileMetadataId = fileMetadata.id;
          }
          else {
            errorMsg = undefined;
          }

          let array = update(this.state.fileMetadataObjectList.sourceFiles,
            {
              [sourceFileMetadataArrayIndex]: {
                namespacePrefixList: {
                  [namespacePrefixListIndex]: {
                    error_label_text: {
                      $set: errorMsg
                    },
                  }
                }
              }
            }
          );
          this.setState({
            fileMetadataObjectList: {
              ...this.state.fileMetadataObjectList,
              sourceFiles: array,
            }
          });
          namespacePrefixListIndex = namespacePrefixListIndex + 1;
        }
      }
      sourceFileMetadataArrayIndex = sourceFileMetadataArrayIndex + 1;
    }

    // Iterating target file metadata namespace list
    let targetFileMetadataArray = this.state.fileMetadataObjectList.targetFiles.slice(); // Create a copy
    let targetFileMetadataArrayIndex = 0;
    // To hold all these different namespace URIs (for duplicate prefixes) (it must be just one, if any)
    for (let fileMetadata of targetFileMetadataArray) {
      if(fileMetadata.namespacePrefixList !== null) {
        let namespacePrefixListIndex = 0;
        for (let namespacePrefix of fileMetadata.namespacePrefixList) {
          var targetFileMetadataPrefix = namespacePrefix.label;
          var targetFileMetadataNamespace = namespacePrefix.value;
          // Getting the namespace of the file's prefix from the prefixNamespaceMap (in the State)
          var targetTmpNamespace = this.state.prefixNamespaceMap.get(targetFileMetadataPrefix);
          // In case that file's namespace for that prefix is different than the one in the map,
          // then this is an issue.
          let errorMsg = '';
          if(targetFileMetadataNamespace !== targetTmpNamespace) {
            // for namespacePrefixList in the metadata files, label is the prefix and value is the namespace
            var targetLongDescr =
              <div>
                <Typography style={{paddingBottom: 5}}>
                  You got this error because there are namespace conflicts bewteen two of your uploaded files.
                  In fact, the used prefix <code className={classes.secondaryCode}>{targetFileMetadataPrefix}</code>,
                  is already declared with some namespace URI (<code className={classes.secondaryCode}>{targetTmpNamespace}</code>),
                  which is different than <code className={classes.secondaryCode}>{targetFileMetadataNamespace}</code>, declared here.
                </Typography>
                <Typography style={{paddingBottom: 5}}>
                  This will certainly cause several issues, since for each declared prefix, one and only one namespace URI must be assigned.
                </Typography>
                <Typography>
                  To fix this error simply rename the prefix to something else or use the same URI as the one already
                  declared <code className={classes.secondaryCode}>{'(' + targetTmpNamespace + ')'}</code>. If you don't want to deal with
                  that issue now, simply delete any of the conflicting files and you should be able to continue.
                </Typography>
              </div>;

            errorMsg = {
              shortDescr: 'This prefix is already declared with different namespace',
              longDescr:  targetLongDescr,
              expanded: false,
              foundNamespaceURI: targetTmpNamespace
            };
            procceed = false;
            errorneousFileMetadataId = fileMetadata.id;
          }
          else {
            errorMsg = undefined;
          }
          let array = update(this.state.fileMetadataObjectList.targetFiles,
            {
              [targetFileMetadataArrayIndex]: {
                namespacePrefixList: {
                  [namespacePrefixListIndex]: {
                    error_label_text: {
                      $set: errorMsg
                    },
                  }
                }
              }
            }
          );
          this.setState({
            fileMetadataObjectList: {
              ...this.state.fileMetadataObjectList,
              targetFiles: array,
            }
          });
          namespacePrefixListIndex = namespacePrefixListIndex + 1;
        }
      }
      targetFileMetadataArrayIndex = targetFileMetadataArrayIndex + 1;
    }

    this.setState({
      fileMetadataFormHasErrors: !procceed,
    }, function () {
      if(!procceed) {
        // Pop up error message
        this.props.showErrorSnackBar({
          msg: 'Metadata cannot be saved for one or more files, due to errors in the form. ' +
               'Please make sure that the form is valid and save again (you might need to scroll up or down to find the error).',
          iconSize: 'large'
        });
        // Scroll to error
        //window.scrollTo(0, this.state.fileMetadataRefRecordMap.get(errorneousFileMetadataId).offsetTop);
        this.fileMetadataDialogRef.scrollTo(0, this.state.fileMetadataRefRecordMap.get(errorneousFileMetadataId).offsetTop);
      }
    });

    return procceed;
  }

  handleSaveFileMetadataDialog = async () => {
    this.handleSaveFileMetadataDialogCallBack({});
  }

  // props: {filesManagementChanged*: <Boolean>}
  // Optional argument used when syncing with others, which means that props could be empty
  handleSaveFileMetadataDialogCallBack = async props => {
    let filesManagementChanged = props.filesManagementChanged !== undefined ? props.filesManagementChanged : undefined;
    if(filesManagementChanged === undefined) {
      filesManagementChanged = this.state.filesManagementChanged;
    }
    // Don't apply in case of syncing (doSave: false)
    if(props.doSave !== undefined ? props.doSave : true) {
      // Save updated FileMetadata Objects to the database
      // Note: that forEach doesn't guarantee the storeFileMetadata execution
      // in sequence (as we want)
      for (const fileMetadata of this.state.fileMetadataObjectList.sourceFiles) {
        await this.storeFileMetadata(fileMetadata);
      }
      for (const fileMetadata of this.state.fileMetadataObjectList.targetFiles) {
        await this.storeFileMetadata(fileMetadata);
      }

      // Close the project mapping's metadata dialog
      this.setState({
        configDialogOpen: false,
      }, async function() {
        // Re-run the resolver and reasoner to get the list of classes with the proper prefix
        // if any change has been applied on files' metadata (currently only capturing changes
        // applied on namespaces and prefixes of the files
        if(filesManagementChanged) {
          // Executing the reasoner for the target sources
          await this.executeReasoner();
          // Executing the respective analyzer for the input sources
          await this.executeSimpleXpathResolver();
          // Saving the mapping project
          await mainService.saveMappingProject(this.state.currMappingProject);
          // ***************************************************************************
          // TODO: I also have to replace the prefixes for the so far constructed model.
          // ***************************************************************************
          this.setState({
            filesManagementChanged: false,
          });
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.filesManagementChanged = filesManagementChanged;
          newProps.user = this.props.user;
          newProps.fileMetadataObjectList = Object.assign({}, this.state.fileMetadataObjectList);
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleSaveFileMetadataDialogCallBack',
            argument: newProps
          });
        }
      });
    }
    // Only when others are syncing
    else {
      // Re-run the resolver and reasoner if changes have been applied on fileMetadata
      if(filesManagementChanged) {
        this.setState({
          loadingModalOpen: true,
          sourceLoading: true,
          targetLoading: true,
        }, () => {
          this.props.showInfoSnackBar({
            msg:  '<span>' +
                    'User ' + props.user.firstname + ' ' + props.user.lastname + ', has just updated the source or target files.' +
                  '</span>' +
                  '</br />' +
                  '<span>' +
                    'For that reason the classes and the Xpaths have been re-loaded.' +
                  '</span>',
            hasHtmlContent: true,
          });
          this.setState({
            fileMetadataObjectList: props.fileMetadataObjectList,
          }, async() => {
            // Executing the reasoner for the target sources
            await this.executeReasoner();
            // Executing the respective analyzer for the input sources
            await this.executeSimpleXpathResolver();
          });
        });
        setTimeout( () => {
          this.setState({
            loadingModalOpen: false,
          }, function () {
            this.setState({
              sourceLoading: false,
              targetLoading: false,
            });
          });
        }, 1000);
      }
    }

  }

  // ************** FileAndMetadataUploader Component **************

  // type: 'source | target | generatorPolicy'
  // index: SOME_INTEGER
  handleMarkFileMetadataFieldAsInvalid = props => {
    let array = this.state.fileMetadataObjectList[props.type + 'Files'].slice(); // Create a copy
    array = update(this.state.fileMetadataObjectList[props.type + 'Files'], {
      [props.index]: {
        [props.fieldName + '_error_text']: {$set: 'This field is required'}
      }
    });
    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [props.type + 'Files']: array
      }
    });
  }

  // props: {listType: 'source | target | generatorPolicy', fieldName: <Text>, index: <Integer>}
  handleMarkFileMetadataFieldAsValid = props => {
    let array = this.state.fileMetadataObjectList[props.listType + 'Files'].slice(); // Create a copy
    array = update(this.state.fileMetadataObjectList[props.listType + 'Files'], {
      [props.index]: {
        [props.fieldName + '_error_text']: {$set: ''}
      }
    });
    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [props.type + 'Files']: array
      }
    });
  }

  // props: {type: 'source | target'}
  handleSpeedDialClick = props => () => {
    this.setState(state => ({
      [props.type + 'SpeedDialOpen']: !state[props.type + 'SpeedDialOpen'],
    }));
  };

  // props: {type: 'source | target'}
  handleSpeedDialClose = props => () => {
    this.setState({ [props.type + 'SpeedDialOpen']: false });
  };

  // props: {type: 'source | target'}
  handleSpeedDialOpen = props => () => {
    this.setState({ [props.type + 'SpeedDialOpen']: true });
  };

  // props: {type: 'source | target | generatorPolicy'}
  handleAddFileMetadata = props => async () => {
    this.handleAddFileMetadataCallBack(props);
  }
  // props: {type: 'source | target | generatorPolicy'}
  handleAddFileMetadataCallBack = async (props) => {
    let array = [];
    let newFileMetadata = props.newFileMetadata !== undefined ? props.newFileMetadata : undefined;
    if(newFileMetadata === undefined) {
      newFileMetadata = {
        title: '',
        description: '',
        version: '',
        fileScope: props.type === 'target' ? 'targetSchemaFile' : props.type + 'File',
        username: this.props.currUser.currUsername,
        namespacePrefixList: null,
        //ref: React.createRef(),
      };
      newFileMetadata = await this.storeFileMetadata(newFileMetadata);
    }

    array = [...this.state.fileMetadataObjectList[props.type + 'Files']]; // Create a copy
    array.push(newFileMetadata);

    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [props.type + 'Files']: array
      },
      filesManagementChanged: props.doSave !== undefined ? props.doSave : true,
    }, async function() {
      // Re-constructing the array holding the "FileMetatada" on the "MappingProject"
      // Each "FileMetadata" is held in the form of {value:<Text>, label:<Text>} on the "MappingProject"
      let tuples = [];
      this.state.fileMetadataObjectList[props.type + 'Files'].forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        tuples.push(tuple);
      });

      // Determine what the name of the tuple list is for the mappingProject
      let metadataTupleName = '';
      if(props.type === 'source') {
        metadataTupleName = 'sourceInputs';
      }
      else if(props.type === 'target') {
        metadataTupleName = 'targetSchemata';
      }
      else {//if(props.type === 'generatorPolicy') {
        metadataTupleName = 'generatorPolicy';
      }

      // Associate that tuple list with the MappingProject
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
            [metadataTupleName]: props.type !== 'generatorPolicy' ? tuples : tuples[0],
          },
      }, async function() {
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          // Store the new project mapping to the database
          try {
            await mainService.saveMappingProject(this.state.currMappingProject);
          } catch (e) {
            console.error('Failure!');
            if(e.response !== undefined) {
              console.error(e.response.status);
            }
          }
          // Scroll to that fileMetadata
          this.fileMetadataDialogRef.scrollTo(0, this.state.fileMetadataRefRecordMap.get(newFileMetadata.id).offsetTop);
        }
      });
    });

    // Don't apply in case of syncing (doSave: false)
    if(props.doSave !== undefined ? props.doSave : true) {
      let newProps = Object.assign({}, props); // Copy
      newProps.doSave = false;
      newProps.newFileMetadata = newFileMetadata;
      this.sendJsonObjectToSpecificUsers({
        actionName: 'handleAddFileMetadataCallBack',
        argument: newProps
      });
    }
  }


  // Handling whether this file will be present or not during the RDF transaformation
  // props: {type: 'source | target, fileMetadata: <SOME_FILEMETADATA_OBJECT}
  handleChangeFileParticipation = props => async (event) => {
    props.value=event.target.checked;
    this.handleChangeFileParticipationCallBack(props);
  }

  handleChangeFileParticipationCallBack = async (props) => {
    const type = props.type;
    const value = props.value;
    let fileMetadata = {...props.fileMetadata}; // Copying FileMEtadata
    fileMetadata.participating = value;

    // Find the filemetadata in the list
    let array = [...this.state.fileMetadataObjectList[type + 'Files']]; // Create a copy
    const index = array.findIndex(obj => obj.id === fileMetadata.id);
    array[index] = fileMetadata;
    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [type + 'Files']: array
      },
      // filesManagementChanged: props.doSave !== undefined ? props.doSave : true,
    }, async () => {
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Store the modifiied fileMetadatain the database
        try {
          await this.storeFileMetadata(fileMetadata);
        } catch (e) {
          console.error('Failure!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleChangeFileParticipationCallBack',
          argument: newProps
        });
      }
    });
  }

  



  // props: {type: 'source | target | generatorPolicy', fileMetadata: <SOME_FILEMETADATA_OBJECT}
  handleDeleteFileMetadata = props => async (event) => {
    this.handleDeleteFileMetadataCallBack(props);
  }

  // props: {type: 'source | target | generatorPolicy', fileMetadata: <SOME_FILEMETADATA_OBJECT}
  handleDeleteFileMetadataCallBack = async (props) => {
    // Delete file from server and the respective database entry
    try {
      let deletedFileMetadataId = props.deletedFileMetadataId !== undefined ? props.deletedFileMetadataId : undefined;
      if(deletedFileMetadataId === undefined) {
        const res = await fileUploadService.deleteFileAndMaybeFileMetadata({id: props.fileMetadata.id, deleteFileMetadata: true});
        if(res.data.succeed === true) {
          //console.info(res.data.msg);
          let array = [...this.state.fileMetadataObjectList[props.type + 'Files']]; // Create a copy
          let index = array.findIndex(obj => obj.id === props.fileMetadata.id);
          array.splice(index, 1); // Also used for old list

          this.setState({
            fileMetadataObjectList: {
              ...this.state.fileMetadataObjectList,
              [props.type + 'Files']: array
            },
            // oldFileMetadataObjectList: {
            //   ...this.state.oldFileMetadataObjectList,
            //   [props.type + 'Files']: oldArray
            // },
          }, () => {
            // Re-constructing the array holding the "FileMetatada" on the "MappingProject"
            // Each "FileMetadata" is held in the form of {value:<Text>, label:<Text>} on the "MappingProject"
            let tuples = [];
            this.state.fileMetadataObjectList[props.type + 'Files'].forEach(fileMetadata => {
              let tuple = {value: fileMetadata.id, label: fileMetadata.title}
              tuples.push(tuple);
            });

            // Determine what the name of the tuple list is for the mappingProject
            let metadataTupleName = '';
            if(props.type === 'source') {
              metadataTupleName = 'sourceInputs';
            }
            else if(props.type === 'target') {
              metadataTupleName = 'targetSchemata';
            }
            else {//if(props.type === 'generatorPolicy') {
              metadataTupleName = 'generatorPolicy';
            }
            // Associate that tuple list with the currMappingProject
            this.setState({
              currMappingProject: {
                ...this.state.currMappingProject,
                  [metadataTupleName]: props.type !== 'generatorPolicy' ? tuples : tuples[0],
                },
              filesManagementChanged: true,
            }, async function() {
              // Store the new project mapping to the database
              try {
                // Re-initializing source or target models
                if(props.type === 'source') {
                  await this.executeXpathResolverCallBack({fileMetadataId: undefined, doRetrieve: false, doSave: true});
                }
                else if(props.type === 'target') {
                  await this.executeReasonerCallBack({fileMetadataId: undefined, doRetrieve: false});
                  await mainService.saveMappingProject(this.state.currMappingProject);
                }

              } catch (e) {
                console.error('Failure!');
                if(e.response !== undefined) {
                  console.error(e.response.status);
                }
              }
            });
          });

        }
        else {
          this.props.showErrorSnackBar({msg: res.data.message, iconSize: 'large'});
          console.error(res.data.message);
        }
      }
      else {
        let array = [...this.state.fileMetadataObjectList[props.type + 'Files']]; // Create a copy
        let index = array.findIndex(obj => obj.id === props.deletedFileMetadataId);
        if(index !== -1) {
          array.splice(index, 1);
        }

        this.setState({
          fileMetadataObjectList: {
            ...this.state.fileMetadataObjectList,
            [props.type + 'Files']: array
          },
        });
      }
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        newProps.deletedFileMetadataId = props.fileMetadata.id;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleDeleteFileMetadataCallBack',
          argument: newProps
        });
      }
    } catch (e) {
      console.error('Some error occured while deleting metadata from the database!');
      console.error(e);
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // props: {type: 'source | target | generatorPolicy', predefined: <Boolean>}
  retrievePredefinedFileMetadataListByFileScopeAndEnability = async () => {
    let props = {type: 'target', enabled: true};
    let dataRequest = {};
    // Handle target differently
    if(props.type === 'target') {
      dataRequest = {fileScope: 'targetSchemaFile', predefined: props.predefined, enabled: true}
    }
    else {
      dataRequest = {fileScope: props.type + 'File', predefined: props.predefined, enabled: true}
    }

    try {
      const fileMetadataRes = await fileUploadService.retrievePredefinedFileMetadataListByFileScopeAndEnability(dataRequest);
      if(fileMetadataRes.data !== false) {
        this.setState({
            [props.type + 'PredefinedFileMetadataList']: fileMetadataRes.data
        });
      }
      else {
        //
      }
    } catch (e) {
      console.error('Failure while retrieving File-Metadata List!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // props: {type: 'source | target | generatorPolicy' fileMetadata: '<FileMetadata>', fieldName: <Text>}
  handlePredefinedFileMetadataAutocompleteInputChange = props => event => {
    if(event !== null) {
      props.predefinedFileMetadataId = event.value;
      this.handlePredefinedFileMetadataAutocompleteInputChangeCallBack(props);
    }
  }
  // props: {type: 'source | target | generatorPolicy' fileMetadata: '<FileMetadata>', fieldName: <Text>}
  handlePredefinedFileMetadataAutocompleteInputChangeCallBack = props => {
    let fileMetadataListIndex = -1;
    let newFileMetadata = props.newFileMetadata !== undefined ? props.newFileMetadata : undefined;
    if(newFileMetadata === undefined) {
      fileMetadataListIndex = this.state.fileMetadataObjectList[props.type + 'Files'].findIndex(
        obj => obj.id === props.fileMetadata.id
      );
      let predefinedFileMetadataListIndex = this.state[props.type+'PredefinedFileMetadataList'].findIndex(
        obj => obj.id === props.predefinedFileMetadataId
      );
      newFileMetadata = Object.assign({}, this.state[props.type+'PredefinedFileMetadataList'][predefinedFileMetadataListIndex]);

      // Changing config properties to match a copy of the predefined selection
      newFileMetadata.copyOfId = newFileMetadata.id;
      newFileMetadata.id = props.fileMetadata.id;
      newFileMetadata.predefined = false;
    }
    else {
      fileMetadataListIndex = this.state.fileMetadataObjectList[props.type + 'Files'].findIndex(
        obj => obj.id === props.newFileMetadata.id
      );
    }
    let array = update(this.state.fileMetadataObjectList[props.type + 'Files'], {
      [fileMetadataListIndex]: {
        $set: newFileMetadata
      }
    });

    this.setState({
      fileMetadataObjectList: {
        ...this.state.fileMetadataObjectList,
        [props.type + 'Files']: array
      },

    }, () => {
      // Re-constructing the array holding the "FileMetatada" on the "MappingProject"
      // Each "FileMetadata" is held in the form of {value:<Text>, label:<Text>} on the "MappingProject"
      let tuples = [];
      this.state.fileMetadataObjectList[props.type + 'Files'].forEach(fileMetadata => {
        let tuple = {value: fileMetadata.id, label: fileMetadata.title}
        tuples.push(tuple);
      });

      // Determine what the name of the tuple list is for the mappingProject
      let metadataTupleName = '';
      if(props.type === 'source') {
        metadataTupleName = 'sourceInputs';
      }
      else if(props.type === 'target') {
        metadataTupleName = 'targetSchemata';
      }
      else {//if(props.type === 'generatorPolicy') {
        metadataTupleName = 'generatorPolicy';
      }
      // Associate that tuple list with the currMappingProject
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          [metadataTupleName]: props.type !== 'generatorPolicy' ? tuples : tuples[0],
        },
      }, () => {
        // Store the new project mapping to the database
        try {
          // Don't apply in case of syncing (doSave: false)
          if(props.doSave !== undefined ? props.doSave : true) {
            this.setState({
              filesManagementChanged: true,
            }, async () => {
              // Saving the new loaded FileMetadata (from predefinied)
              await this.storeFileMetadata(newFileMetadata);
              // Re-initializing source or target models
              if(props.type === 'source') {
                await this.executeXpathResolverCallBack({fileMetadataId: undefined, doRetrieve: false});
              }
              else if(props.type === 'target') {
                await this.executeReasonerCallBack({fileMetadataId: undefined, doRetrieve: false});
                // Saving the MappingProject
                await mainService.saveMappingProject(this.state.currMappingProject);
              }
              let newProps = Object.assign({}, props); // Copy
              newProps.doSave = false;
              newProps.newFileMetadata = newFileMetadata;
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handlePredefinedFileMetadataAutocompleteInputChangeCallBack',
                argument: newProps
              });
            });
          }
        } catch (e) {
          console.error('Failure!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      });
    });
  }

  // props: {refRecType: 'Text', ref: <Ref>, fileMetadataId: <Text>}
  // for now type is only fileMetadataRefRecordMap
  handleHoldFileMetadataRefRecord = callBackProps => {
    this.setState(function(prevState, props) {
      prevState[callBackProps.refRecType].set(callBackProps.fileMetadataId, callBackProps.ref);
      return {
        [callBackProps.refRecType]: prevState[callBackProps.refRecType]
      }
    });
  }

  // ***************************************************************
  // **************** Generator Definitions Manager ****************
  // ***************************************************************
  handleCloseGeneratorDefinitionsManagerDialog = () => {
    this.setState({
      generatorDefinitionsManagerDialogOpen: false
    });
  }

  handleOpenGeneratorDefinitionsManagerDialog = async () => {
    this.setState({
      generatorDefinitionsManagerDialogOpen: true,
      selectedDefinitionListForEditMode: [],
      selectedPreloadedDefinitionListForEditMode: [],
      definitionListEditModeEnabled: false,
      currManagerGeneratorDefinition: '',
      selectFromImportViewEnabled: false,
    }, function() {
      // Gathering all <Prefix, Namespace> pairs in a map in session
      this.gatheringPrefixNamespacePairs(); //this.state.prefixNamespaceMap
      // Retrieve pre-loaded definitions
      this.retrieveAllGeneratorDefinitions();
    });
  }

  retrieveAllGeneratorDefinitions = async () => {
    try {
      let res = await generatorService.retrieveAllGeneratorDefinitions();
      this.setState({
        preloadedGeneratorDefinitions: res.data,
      });
    }
    catch (e) {
      console.error('Some error occured while retrieving the pre-loaded generator definitions from the database.');
      this.props.showErrorSnackBar({msg: 'Some error occured while retrieving the pre-loaded generator definitions from the database.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  handleGeneratorDefinitionsManagerToggleFullScreen = () => {
    this.setState({
      generatorDefinitionsManagerDialogFullScreen: !this.state.generatorDefinitionsManagerDialogFullScreen
    });
  }

  // props: {actionType: 'close | goToMain | goToAddNew'}
  handleCheckWhetherToLeaveGeneratorDefinitionsManagerDialog = props => () => {
    this.handleCheckWhetherToLeaveGeneratorDefinitionsManagerDialogCallBack(props);
  }

  // props: {actionType: 'close | goToMain | goToAddNew'}
  handleCheckWhetherToLeaveGeneratorDefinitionsManagerDialogCallBack = props => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.generatorDefinitionsManagerChanged) {
      if(props.actionType === 'close') {
        this.setState({
          confirmCloseGeneratorDefinitionsManagerSnackbarShown : true
        });
      }
      else if(props.actionType === 'goToMain') {
        this.setState({
          confirmGoToMainGeneratorDefinitionsManagerSnackbarShown : true
        });
      }
      else if(props.actionType === 'goToAddNew') {
        this.setState({
          confirmGoToAddNewGeneratorDefinitionsManagerSnackbarShown : true
        });
      }
    }
    else {
      // Close the dialog
      if(props.actionType === 'close') {
        this.handleCloseGeneratorDefinitionsManagerDialog();
      }
      else if(props.actionType === 'goToMain') {
        this.handleConfirmGoToMainGeneratorDefinitionsManagerDialog();
      }
      else if(props.actionType === 'goToAddNew') {
        this.showAddNewManagerGeneratorDefinitionForm();
      }
    }
  }

  // Close confirmation snackbar for closing Generator Definition Manager Dialog
  handleCloseConfirmCloseGeneratorDefinitionsManagerSnackbar = () => {
    this.setState({
      confirmCloseGeneratorDefinitionsManagerSnackbarShown: false
    });
  }

  // Confirm to close Generator Definition Manager Dialog
  handleConfirmCloseGeneratorDefinitionsManagerDialog = () => {
    this.setState({
      confirmCloseGeneratorDefinitionsManagerSnackbarShown: false,
      generatorDefinitionsManagerDialogOpen: false,
      generatorDefinitionsManagerChanged: false,
      tmp_numOfDeclarationsAssociatedToDefinition: 0,
    });
  }
  // Close confirmation snackbar for "going back to main view" of the Generator Definition Manager Dialog
  handleCloseConfirmGoToMainGeneratorDefinitionsManagerSnackbar = () => {
    this.setState({
      confirmGoToMainGeneratorDefinitionsManagerSnackbarShown: false
    });
  }

  // Confirm to go back to the main view of the Generator Definition Manager Dialog
  handleConfirmGoToMainGeneratorDefinitionsManagerDialog = () => {
    this.setState({
      confirmGoToMainGeneratorDefinitionsManagerSnackbarShown: false
    }, function() {
      this.resetManagerGeneratorDefinition();
    });
  }

  // Close confirmation snackbar for "going back to main view" of the Generator Definition Manager Dialog
  handleCloseConfirmGoToAddNewGeneratorDefinitionsManagerSnackbar = () => {
    this.setState({
      confirmGoToAddNewGeneratorDefinitionsManagerSnackbarShown: false
    });
  }

  // Confirm to go back to the main view of the Generator Definition Manager Dialog
  handleConfirmGoToAddNewGeneratorDefinitionsManagerDialog = () => {
    this.setState({
      confirmGoToAddNewGeneratorDefinitionsManagerSnackbarShown: false
    }, function() {
      this.showAddNewManagerGeneratorDefinitionForm();
    });
  }


  // props: {generatorDefinition: <Object>, index: <Integer>}
  // index is the index of this definition in the list of local definitions
  handleCheckWhetherToSelectOtherGeneratorDefinitionManagerDialog = props => () => {
    // Case - Some Change has already occured (show confirm)
    if(this.state.generatorDefinitionsManagerChanged) {
      this.setState({
        confirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbarShown: true
      }, function() {
        // Store temporary variables into the state to be used after
        // confirm is displaied in order to apply the confirmation
        this.selectManagerGeneratorDefinitionCallBack({
          generatorDefinition: props.generatorDefinition,
          index: props.index, // Used in the respective functions for input change and validation
          actionType: 'tmp'
        });
      });
    }
    // Case - No Change has occured yet (apply the action)
    else {
      this.selectManagerGeneratorDefinitionCallBack(props);
    }
  }

  // Close confirmation snackbar for selecting different definition without saving first (Manager Dialog)
  handleCloseConfirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbar = () => {
    this.setState({
      confirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbarShown: false
    });
  }

  // Confirm to select different definition without saving first (Manager Dialog)
  handleConfirmSelectOtherGeneratorDefinitionWithoutSaveManager = () => {
    this.setState({
      confirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbarShown: false,
      generatorDefinitionsManagerChanged: false,
      tmp_numOfDeclarationsAssociatedToDefinition: 0,
    }, function() {
      this.selectManagerGeneratorDefinitionCallBack({
        generatorDefinition: this.state.tmp_currManagerGeneratorDefinition,
        index: this.state.tmp_selectedManagerGeneratorDefinitionIndex,
      });
    });
  }

  // CallBack
  //props: {generatorDefinition: <Object>, index: <Integer>, actionType*: 'tmp'}
  // * Optional parameter denoting that the properties will be saved in the state with the prefix 'tmp'
  selectManagerGeneratorDefinitionCallBack = props => {
    let tmpDefinition = Object.assign({}, props.generatorDefinition); // Copy
    // Add the missing field "namespace" if it doesn't exist
    if(tmpDefinition.namespace === undefined) {
      tmpDefinition.namespace = '';
    }
    // If the actionType is "tmp", use it as prefix for the variables to be stored
    // in the state (i.e.tmp_currManagerGeneratorDefinition')
    // otherwise leave the properties as they are
    let propPref = props.actionType !== undefined ? props.actionType + '_' : '';
    // Set the selected generator as checked
    this.setState({
        [propPref + 'currManagerGeneratorDefinition']: tmpDefinition,
        [propPref + 'selectedManagerGeneratorDefinitionIndex']: props.index,
    });
  }

  // props: {generatorDefinition: <Object>}
  handleCheckWhetherToSelectPredefinedGeneratorDefinitionToAddToLocalListManager = props => () => {
    // Case - Some Change has already occured (show confirm)
    if(this.state.generatorDefinitionsManagerChanged) {
      this.setState({
        confirmSelectPredefinedGeneratorDefinitionWithoutSaveManagerSnackbarShown: true
      }, function() {
        this.selectManagerPredefinedGeneratorDefinitionToAddToLocalListCallBack({
          generatorDefinition: props.generatorDefinition,
          actionType: 'tmp'
        });
      });
    }
    // Case - No Change has occured yet (apply the action)
    else {
      this.selectManagerPredefinedGeneratorDefinitionToAddToLocalListCallBack(props);
    }
  }

  // CallBack
  // props: {generatorDefinition: <Object>, actionType*: 'tmp'}
  // * Optional parameter denoting that the properties will be saved in the state with the prefix 'tmp'
  selectManagerPredefinedGeneratorDefinitionToAddToLocalListCallBack = async props => {
    let tmpDefinition = Object.assign({}, props.generatorDefinition); // Copy
    let proceed = true;
    // Assign new ID
    try {
      var res = await mainService.getNewUUID();
      tmpDefinition.id = res.data;
    }
    catch (e) {
      console.error('Error occured, while trying to get new UUID from the server');
      this.props.showErrorSnackBar({msg: 'Error occured, while trying to get new UUID from the server', iconSize: 'large'});
      proceed = false;
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
    if(proceed) {
      // Add the missing field "namespace" if it doesn't exist
      if(tmpDefinition.namespace === undefined) {
        tmpDefinition.namespace = '';
      }
      // If the actionType is "tmp", use it as prefix for the variables to be stored
      // in the state (i.e.tmp_currManagerGeneratorDefinition')
      // otherwise leave the properties as they are
      let propPref = props.actionType !== undefined ? props.actionType + '_' : '';
      // Set the selected generator as checked
      this.setState({
          [propPref + 'currManagerGeneratorDefinition']: tmpDefinition,
          selectedManagerGeneratorDefinitionIndex: undefined,
      }, function() {
        if(propPref === '') {
          this.validateGeneratorDefinitionForm({generatorDefinition: tmpDefinition});
        }
      });
    }
  }

  // Close confirmation snackbar for selecting different definition without saving first (Manager Dialog)
  handleCloseConfirmSelectPredefinedGeneratorDefinitionWithoutSaveManagerSnackbar = () => {
    this.setState({
      confirmSelectPredefinedGeneratorDefinitionWithoutSaveManagerSnackbarShown: false
    });
  }

  // Confirm to select different definition without saving first (Manager Dialog)
  handleConfirmSelectPredefinedGeneratorDefinitionWithoutSaveManager = () => {
    this.setState({
      confirmSelectPredefinedGeneratorDefinitionWithoutSaveManagerSnackbarShown: false,
      generatorDefinitionsManagerChanged: false,
      tmp_numOfDeclarationsAssociatedToDefinition: 0,
    }, function() {
      this.selectManagerPredefinedGeneratorDefinitionToAddToLocalListCallBack({
        generatorDefinition: this.state.tmp_currManagerGeneratorDefinition,
      });
    });
  }

  resetManagerGeneratorDefinition = () => {
    this.setState({
        currManagerGeneratorDefinition: '',
        selectedManagerGeneratorDefinitionIndex: undefined,
        generatorDefinitionsManagerChanged: false,
        tmp_numOfDeclarationsAssociatedToDefinition: 0,
    });
  }

  //props: {generatorDefinition: <Object>, index: <Integer>}
  showAddNewManagerGeneratorDefinitionForm = () => {
    const newDefinition = {
      new: true,
      name: '',
      pattern: '',
      prefix: '',
      namespace: '',
      type: 'any',
      custom: false,
      generatorClass: '',
    }
    // Set it as current
    this.setState({
        currManagerGeneratorDefinition: newDefinition,
        selectedManagerGeneratorDefinitionIndex: undefined,
        generatorDefinitionsManagerChanged: false,
        tmp_numOfDeclarationsAssociatedToDefinition: 0,
    });
  }

  handleDeleteSelectedManagerGeneratorDefinitions = definitionArray => () => {
    // Counting declarations related to these definitions
    this.setState({
      tmp_numOfDeclarationsAssociatedToDefinition: 0,
    }, async () => {
      for (const definition of definitionArray) {
        await this.updateAllProjectDeclarationsCallBack({
          generatorDefinition: definition,
          actionType: 'count'
        });
      }
      // Confirmation that one or more definitions will be deleted
      if(this.state.tmp_numOfDeclarationsAssociatedToDefinition === 0) {
        const confirmMessage =
          'You are going to erase ' +
          (
            this.state.selectedDefinitionListForEditMode.length > 1 ?
            this.state.selectedDefinitionListForEditMode.length + ' generator definitions.' :
            ' one generator definition.'
          ) +
          ' Are you sure about this?'
        // Show confirmation snackbar to continue
        this.setState({
          confirmDeleteGeneratorDefinitionManagerSnackbarShown: true,
          confirmMessage: confirmMessage,
        });
      }
      // Confirmation that one or more definitions will be deleted and
      // there are associated declarations
      else {
        // Prepare snackbar message
        const confirmMessage =
          'Deleting' +
          (
            this.state.selectedDefinitionListForEditMode.length > 1 ?
            ' these generator definitions' :
            ' this generator definition'
          ) +
          ' will also delete ' +
          this.state.tmp_numOfDeclarationsAssociatedToDefinition +
          (
            this.state.tmp_numOfDeclarationsAssociatedToDefinition > 1 ?
            ' declarations which are already using it.' :
            ' declaration which is already using it.'
          ) +
          '  Are you sure you want to continue?'
        // Show confirmation snackbar to continue
        this.setState({
          confirmDeleteGeneratorDefinitionManagerSnackbarShown: true,
          confirmMessage: confirmMessage,
        });
      }
    });
  }

  // props: {definitionArray: <Array>}
  handleDeleteSelectedManagerGeneratorDefinitionsCallBack = props => {
    const definitionArray = props.definitionArray; // The selected definitions to be deleted
    let finalUpdatedLoadedDefinitions = props.finalUpdatedLoadedDefinitions !== undefined ? props.finalUpdatedLoadedDefinitions : undefined;
    // Don't apply in case of syncing
    if(finalUpdatedLoadedDefinitions === undefined) {
      // Setting loading flag
      this.setState({
        theSystemIsUpdatingGeneratorDeclarations: true,
      }, async function() {
        let array = this.state.currMappingProject.generatorDefinitions.slice(); // Create a copy
        this.setState({
            theSystemIsUpdatingGeneratorDeclarations: true,
        }, async () => {
          for (const definition of definitionArray) {
            var index = array.findIndex(
              obj => obj.id === definition.id
            );
            // Updating all declarations in the project (delete action)
            await this.updateAllProjectDeclarationsCallBack({
              generatorDefinition: definition,
              actionType: 'delete',
              isPartOfMassiveAction: true,
            });
            array.splice(index, 1);
          }

          this.setState({
            currMappingProject: {
              ...this.state.currMappingProject,
              generatorDefinitions: array,
            },
            currManagerGeneratorDefinition: '',
            selectedDefinitionListForEditMode: [],
            definitionListEditModeEnabled: false,
            tmp_numOfDeclarationsAssociatedToDefinition: 0,
            theSystemIsUpdatingGeneratorDeclarations: false,
          }, async () => {
            // Persist in the database
            await mainService.saveMappingProject(this.state.currMappingProject);
            this.showSuccessSnackBar({msg: 'The generator "Definition" list was updated successfully'});
            // Removing loading flag
            this.setState({
              theSystemIsUpdatingGeneratorDeclarations: false,
            });
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.finalUpdatedLoadedDefinitions = this.state.currMappingProject.generatorDefinitions;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeleteSelectedManagerGeneratorDefinitionsCallBack',
              argument: newProps
            });
          });
        });
      });
    }
    // Only applied when synced by others
    else {
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          generatorDefinitions: finalUpdatedLoadedDefinitions,
        },
      });
    }
  }

  // Close confirmation snackbar for "going back to main view" of the Generator Definition Manager Dialog
  handleCloseConfirmDeleteGeneratorDefinitionManagerSnackbar = () => {
    this.setState({
      confirmDeleteGeneratorDefinitionManagerSnackbarShown: false,
      confirmMessage: '',
    });
  }

  // Confirm to go back to the main view of the Generator Definition Manager Dialog
  handleConfirmDeleteGeneratorDefinitionManager = () => {
    this.setState({
      confirmDeleteGeneratorDefinitionManagerSnackbarShown: false,
      confirmMessage: '',
    }, function() {
      this.handleDeleteSelectedManagerGeneratorDefinitionsCallBack({definitionArray: this.state.selectedDefinitionListForEditMode});
    });
  }

  handleDeleteSelectedPreloadedManagerGeneratorDefinitions = definitionArray => () => {
    // Confirmation that one or more pre-loaded definitions will be deleted
    const confirmMessage =
      'You are going to erase ' +
      (
        this.state.selectedPreloadedDefinitionListForEditMode.length > 1 ?
        this.state.selectedPreloadedDefinitionListForEditMode.length + ' pre-loaded generator definitions.' :
        ' one pre-loaded generator definition.'
      ) +
      ' Doing so, ' +
      (
        this.state.selectedPreloadedDefinitionListForEditMode.length > 1 ?
        'will make these definitions no longer available to other users. ' :
        'will make this definition no longer available to other users. '
      )
      + 'Are you sure about this?'
    // Show confirmation snackbar to continue
    this.setState({
      confirmDeletePreloadedGeneratorDefinitionManagerSnackbarShown: true,
      confirmMessage: confirmMessage,
    });
  }

  handleDeleteSelectedPreloadedManagerGeneratorDefinitionsCallBack = async definitionArray => {
    try {
      // Persist in the database
      const res = await generatorService.removeListOfGeneratorDefinitions(definitionArray);
      if(res.data.succeed) {
        let array = this.state.preloadedGeneratorDefinitions.slice(); // Create a copy
        for (const definition of definitionArray) {
          var index = array.findIndex(
            obj => obj.id === definition.id
          );
          // Removing from array
          array.splice(index, 1);
        }
        this.setState({
          preloadedGeneratorDefinitions: array,
          selectedPreloadedDefinitionListForEditMode: [],
          definitionListEditModeEnabled: false,
        }, async function() {
          // Persist in the database
          await generatorService.removeListOfGeneratorDefinitions(definitionArray);
          this.showSuccessSnackBar({msg: res.data.msg});
        });
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg});
      }
    }
    catch (e) {
      console.error('Some error occured while deleting the selected generator definitions from the database.');
      this.props.showErrorSnackBar({msg: 'Some error occured while deleting the selected generator definitions from the database.'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }

  }

  // Close confirmation snackbar for "going back to main view" of the Generator Definition Manager Dialog
  handleCloseConfirmDeletePreloadedGeneratorDefinitionManagerSnackbar = () => {
    this.setState({
      confirmDeletePreloadedGeneratorDefinitionManagerSnackbarShown: false,
      confirmMessage: '',
    });
  }

  // Confirm to go back to the main view of the Generator Definition Manager Dialog
  handleConfirmDeletePreloadedGeneratorDefinitionManager = () => {
    this.setState({
      confirmDeletePreloadedGeneratorDefinitionManagerSnackbarShown: false,
      confirmMessage: '',
    }, function() {
      this.handleDeleteSelectedPreloadedManagerGeneratorDefinitionsCallBack(this.state.selectedPreloadedDefinitionListForEditMode);
    });
  }

  // props: {definition: <Object>, listType: 'selected | selectedPreloaded'}
  selectDefinitionForEditMode = props => () => {
    let array = this.state[props.listType + 'DefinitionListForEditMode'].slice(); // Create a copy
    let index = array.findIndex(
      obj => obj.id === props.definition.id
    );
    if(index === -1) {
      array.push(props.definition);
    }
    else {
      array.splice(index, 1);
    }
    this.setState({
      [props.listType + 'DefinitionListForEditMode']: array,
    });
  }

  handleInputChangeDefinitionListEditMode = event => {
    this.setState({
      definitionListEditModeEnabled: event.target.checked,
      selectedDefinitionListForEditMode: [],
      selectedPreloadedDefinitionListForEditMode: [],
    });
  }

  // props: {fieldName: '<Text>', value: <Text>
  // Using selectedManagerGeneratorDefinitionIndex
  handleManagerGeneratorDefinitionInputChange = props => event => {
    // Decide on some value
    let fieldValue = '';
    // Not CheckBox or Switch cases
    if(props.fieldName !== 'custom' && props.fieldName !== 'preloaded' && props.fieldName !== 'shorten' && props.fieldName !== 'uuid') {
      fieldValue = event !== null ? (event.target !== undefined ? event.target.value : event.value) : '';
    }
    // CheckBox or Switch cases
    else {
      // "shorten" and "uuid" fields accept yes / no (instead of true / false)
      if(props.fieldName === 'shorten' || props.fieldName === 'uuid') {
        fieldValue = event.target.checked ? 'yes' : 'no';
      }
      // "new" or "custom" switch
      else {
        fieldValue = event.target.checked;
      }
    }

    // Decide on some error value (if any)
    let errorValue = fieldValue === '' ? 'This Field is required' : undefined;

    // Search if the name already exists
    let nameAlreadyExists = false;
    if(props.fieldName === 'name') {
      // Search for the name
      var index = this.state.currMappingProject.generatorDefinitions.findIndex(
        obj => obj.name.toLowerCase() === fieldValue.toLowerCase()
      );
      // Case new definition
      if(this.state.currManagerGeneratorDefinition.new !== undefined ? this.state.currManagerGeneratorDefinition.new : false) {
        nameAlreadyExists = (index  !== -1) ? true : false;
      }
      // Case existing definition
      else {
        nameAlreadyExists = (index !== -1 && index !== this.state.selectedManagerGeneratorDefinitionIndex) ? true : false;
      }

      // Decide on some error value (if any)
      errorValue = nameAlreadyExists ?
                   'This name is already in use.' :
                   fieldValue === '' ? 'This Field is required' : undefined;
    }

    // Check if the pattern field is valid
    let patternIsValid = true;
    const patternRegEx = /^([^{}]*{[^"<>?&}]+}[^{}]*)+$/g;
    if(props.fieldName === 'pattern') {
      patternIsValid = patternRegEx.test(fieldValue);
      // Decide on some error value (if any)
      errorValue = patternIsValid ?
                   undefined :
                   (fieldValue === '' ? 'This Field is required' : 'This is not a valid pattern value.');
    }

    // Check if the java class field is valid
    let generatorClassIsValid = true;
    const generatorClassRegEx = /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z0-9_]+)*$/g;
    if(props.fieldName === 'generatorClass') {
      generatorClassIsValid = generatorClassRegEx.test(fieldValue);
      // Decide on some error value (if any)
      errorValue = generatorClassIsValid ?
                   undefined :
                   (fieldValue === '' ? 'This Field is required' : 'This is not a valid java class value.');
    }

    this.setState({
      currManagerGeneratorDefinition: {
        ...this.state.currManagerGeneratorDefinition,
        [props.fieldName]: fieldValue,
        [props.fieldName + '_error_text']: errorValue,
      },
      generatorDefinitionsManagerChanged: true,
    }, function() {
      // Case of select from autocomplete value for type
      // If value is "any" or "instance" then add the prefix, namespace, shorten and uuid fields if they are undefined
      if(props.fieldName === 'type') {
        if(event !== null) {
          if(event.value !== undefined) {
            if(event.value.toLowerCase() === 'any' || event.value.toLowerCase() === 'instance') {
              // Only if prefix is undefined
              if(this.state.currManagerGeneratorDefinition.prefix === undefined) {
                this.setState({
                  currManagerGeneratorDefinition: {
                    ...this.state.currManagerGeneratorDefinition,
                    prefix: '',
                  }
                });
              }
              // Only if namepace is undefined
              if(this.state.currManagerGeneratorDefinition.namespace === undefined) {
                this.setState({
                  currManagerGeneratorDefinition: {
                    ...this.state.currManagerGeneratorDefinition,
                    namespace: '',
                  }
                });
              }
              // Only if shorten is undefined
              if(this.state.currManagerGeneratorDefinition.shorten === undefined) {
                this.setState({
                  currManagerGeneratorDefinition: {
                    ...this.state.currManagerGeneratorDefinition,
                    shorten: 'no',
                  }
                });
              }
              // Only if shorten is undefined
              if(this.state.currManagerGeneratorDefinition.uuid === undefined) {
                this.setState({
                  currManagerGeneratorDefinition: {
                    ...this.state.currManagerGeneratorDefinition,
                    uuid: 'no',
                  }
                });
              }
            }
          }
        }
      }
      // Case of select from autocomplete prefix
      if(props.fieldName === 'prefix') {
        if(event !== null) {
          if(event.value !== undefined) {
            let namespace = this.state.prefixNamespaceMap.get(event.value);
            this.setState({
              currManagerGeneratorDefinition: {
                ...this.state.currManagerGeneratorDefinition,
                namespace: namespace !== undefined ? namespace : '',
                namespace_error_text: namespace !== '' ? undefined : 'This Field is required'
              }
            });
          }
        }
      }
      // Case Custom
      if(props.fieldName === 'custom') {
        if(fieldValue) {
          if(this.state.currManagerGeneratorDefinition.setArgs === undefined) {
            const setArgsArray = [{
              attributes: [{name: 'text'}, {type: ''}],
              elementName: 'set-arg'
            }];
            this.setState({
              currManagerGeneratorDefinition: {
                ...this.state.currManagerGeneratorDefinition,
                setArgs: setArgsArray,
              }
            });
          }
        }
        // Custom Disabled
        else {
          if(this.state.currManagerGeneratorDefinition.setArgs !== undefined) {
            let definition = Object.assign({}, this.state.currManagerGeneratorDefinition); // Copy
            delete definition.setArgs;
            this.setState({
              currManagerGeneratorDefinition: definition,
            });
          }
        }
      }
    });
  }

  // props: {attrName: <Text>, attrIndex: <Integer>, argIndex: <Integer>})}
  // Using selectedManagerGeneratorDefinitionIndex
  handleManagerGeneratorDefinitionArgInputChange = props => event => {
    //let setArgs = this.state.currManagerGeneratorDefinition.setArgs.slice(); // Create a copy
    //let setArgs = [...this.state.currManagerGeneratorDefinition.setArgs]; // Create a copy
    // Deep Cloning (since a nested array within array is changed)
    let setArgs = JSON.parse(JSON.stringify(this.state.currManagerGeneratorDefinition.setArgs));
    let fieldValue = '';
    let errorValue = '';
    // Autocomplete
    if(props.attrName === 'type') {
      fieldValue = event !== null ? event.value : '';
    }
    // Free Text
    else {
      fieldValue = event.target.value;
      errorValue = fieldValue === '' ? 'This field is required' : '';
    }
    setArgs[props.argIndex].attributes[props.attrIndex][props.attrName] = fieldValue;
    //console.log(this.state.currMappingProject.generatorDefinitions[5].setArgs[1].attributes);
    if(errorValue !== '') {
      setArgs[props.argIndex].attributes[props.attrIndex][props.attrName + '_error_text'] = errorValue;
    }
    else {
      delete setArgs[props.argIndex].attributes[props.attrIndex][props.attrName + '_error_text'];
    }
    // let definition = Object.assign({}, this.state.currManagerGeneratorDefinition); // Copy
    // definition.setArgs = setArgs;
    // this.setState({
    //   currManagerGeneratorDefinition: definition,
    // });
    this.setState({
      currManagerGeneratorDefinition: {
        ...this.state.currManagerGeneratorDefinition,
        setArgs: setArgs,
      },
      generatorDefinitionsManagerChanged: true,
    });
  }

  // props: {status:'mouseEnter | mouseLeave', index: <Integer>}
  handleCustomGeneratorDefinitionManagerArgHoverDelete = props => () => {
    var hoveredIndex = -1;
    if(props.status === 'mouseEnter') {
      hoveredIndex = props.index;
    }
    else {
      hoveredIndex = -1;
    }
    this.setState({
      currManagerGeneratorDefinitionArgHoveredIndex: hoveredIndex
    });
  }

  // props: {index: <Integer>}
  handleDeleteCustomGeneratorDefinitionManagerArg = props => () => {
    let setArgs = this.state.currManagerGeneratorDefinition.setArgs.slice(); // Create a copy
    setArgs.splice(props.index, 1);
    this.setState({
      currManagerGeneratorDefinition: {
        ...this.state.currManagerGeneratorDefinition,
        setArgs: setArgs,
      },
      currManagerGeneratorDefinitionArgHoveredIndex: -1,
      generatorDefinitionsManagerChanged: true,
    });
  }

  // props: {definition: <Object>}
  handleAddEmptyCustomArgToGeneratorDefinitionManager = props => () => {
    let setArgsArray = props.definition.setArgs.slice(); // Create a copy
    const arg = {
      attributes: [
        {name: ''}, {type: ''}
      ],
      elementName: 'set-arg'
    };
    setArgsArray.push(arg);
    this.setState({
      currManagerGeneratorDefinition: {
        ...this.state.currManagerGeneratorDefinition,
        setArgs: setArgsArray,
      }
    });
  }

  // Calls service that generates and then downloads the generator definitions used in
  // the current MappingProject)
  handleGenerateAndDownloadGeneratorPolicyFile = () => {
    fileUploadService.constructAndDownloadGeneratorPolicyFile({
      id: this.state.currMappingProject.id,
      fileName: this.state.currMappingProject.title.split(' ').join('_') + '_generator_policy.xml'
    });
  }

  handleOpenConfirmBeforeMakingGeneratorDefinitionPreloadedSnackbar = () => {
    this.setState({
      confirmBeforeMakingGeneratorDefinitionPreloadedSnackbarShown: true
    });
  }

  handleCloseConfirmBeforeMakingGeneratorDefinitionPreloadedSnackbar = () => {
    this.setState({
      confirmBeforeMakingGeneratorDefinitionPreloadedSnackbarShown: false
    });
  }

  doOrUndoGeneratorDefinitionPreloaded = () => {
    // Ask for confirmation in order to make this definition available for all users
    let confirmBeforeMakingGeneratorDefinitionPreloaded =
            this.props.configurationSettings.confirmBeforeMakingGeneratorDefinitionPreloaded !== undefined ?
            this.props.configurationSettings.confirmBeforeMakingGeneratorDefinitionPreloaded :
            true;
    // First check whether the user has the "don't ask again" option enabled
    if(confirmBeforeMakingGeneratorDefinitionPreloaded) {
      this.handleOpenConfirmBeforeMakingGeneratorDefinitionPreloadedSnackbar();
    }
    // Apply the action without asking
    else {
      this.handleConfirmMakingGeneratorDefinitionPreloadedCallBack({actionType: 'insert'});
    }
  }

  handleConfirmMakingGeneratorDefinitionPreloaded = props => () => {
    this.handleConfirmMakingGeneratorDefinitionPreloadedCallBack(props);
  }
  // props: {actionType: 'insert | delete'}
  handleConfirmMakingGeneratorDefinitionPreloadedCallBack = props => {

    const doInsert = props.actionType === 'insert';

    this.setState({
      confirmBeforeMakingGeneratorDefinitionPreloadedSnackbarShown: false,
      currManagerGeneratorDefinition: {
        ...this.state.currManagerGeneratorDefinition,
        preloaded: doInsert,
      },
    }, async function() {
      // Insert to the database (make it available for all users)
      if(doInsert) {
        try {
          let definition = Object.assign({}, this.state.currManagerGeneratorDefinition); // Copy
          // Delete the ID in oredr to construct new one when persisiting it on the database
          delete definition.id;
          var res = await generatorService.saveGeneratorDefinition(definition);
          if(res.data.succeed) {
            // Retrieve the current currManagerGeneratorDefinition from the backend with attached ID (after persisting)
            this.setState({
              currManagerGeneratorDefinition: res.data.generatorDefinition,
            }, async function() {
              // Reload preloaded from the database
              this.retrieveAllGeneratorDefinitions();
              this.showSuccessSnackBar({msg: res.data.msg});
            });
          }
          else {
            this.props.showErrorSnackBar({msg: res.data.msg});
          }
        }
        catch (e) {
          console.error('Some error occured while making this generator definition available for all users.');
          this.props.showErrorSnackBar({msg: 'Some error occured while making this generator definition available for all users.'});
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      }
      // Remove from the databae (make it normal again)
      else {
        try {
          var removeDefRes = await generatorService.removeGeneratorDefinition(this.state.currManagerGeneratorDefinition);
          if(removeDefRes.data.succeed) {
            // Reload preloaded from the database
            this.retrieveAllGeneratorDefinitions();
            this.showSuccessSnackBar({msg: removeDefRes.data.msg});
          }
          else {
            this.props.showErrorSnackBar({msg: removeDefRes.data.msg});
          }
        }
        catch (e) {
          console.error('Some error occured while removing this generator definition from the list of available definitions for all users.');
          this.props.showErrorSnackBar({msg: 'Some error occured while removing this generator definition from the list of available definitions for all users.'});
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      }
    });
  }

  // props: generatorDefinition: <Object>
  validateGeneratorDefinitionForm = props => {

    let isValid = true;
    let definition = Object.assign({}, props.generatorDefinition); // Copy

    // Checking for name duplicates
    let nameAlreadyExists = false;
    let defArray = this.state.currMappingProject.generatorDefinitions.slice(); // Create a copy
    // In case of selected from the list then remove it from the compare list
    if(definition.new === undefined ? true : definition.new) {
      defArray.splice(this.state.selectedManagerGeneratorDefinitionIndex, 1);
    }
    // Now that we are sure that the selected one (if not new) is not included
    // search for duplicate names
    var index = defArray.findIndex(
      obj => obj.name.toLowerCase() === definition.name.toLowerCase()
    );
    nameAlreadyExists = (index  !== -1) ? true : false;

    // Annotating Errors for both pattern based and custom based

    // Name
    definition.name_error_text =
      definition.name === '' ?
      'This Field is required' :
      (
        nameAlreadyExists ?
        'This name is already in use.' :
        undefined
      );

    // Iterating the custom definition setArgs Array
    let setArgsArray = (definition.custom !== undefined ? definition.custom : false) ?
                       (
                         definition.setArgs !== undefined ?
                         definition.setArgs.slice() : // Create a copy
                         []
                       ) :
                       [];
    let setArgInvalid = false;
    let setArgChanged = false;
    for (const setArg of setArgsArray) {
      // Handle the rest of the attributes (mind that name is required)
      // Iterate attributes Array
      for (const attr of setArg.attributes) {
        for (const key of Object.keys(attr)) {
          if(key === 'name') {
            if(attr[key] === '') {
              attr[key + '_error_text'] = 'This field is required';
              setArgInvalid = true;
              setArgChanged = true;
            }
            else {
              delete attr[key + '_error_text'];
              setArgInvalid = false;
              setArgChanged = true;
            }
          }
        }
      }
    }

    if(setArgChanged) {
      this.setState({
        currManagerGeneratorDefinition: {
          ...this.state.currManagerGeneratorDefinition,
          setArgs: setArgsArray,
        }
      });
    }


    // Pattern Based
    if(definition.custom !== undefined ? !definition.custom : true) {
      // Check if the pattern field is valid
      let patternIsValid = true;
      const patternRegEx = /^([^{}]*{[^"<>?&}]+}[^{}]*)+$/g;
      patternIsValid = patternRegEx.test(definition.pattern);
      // Annotating Errors for pattern field
      definition.pattern_error_text = definition.pattern === '' ?
                                   'This Field is required' :
                                   (
                                     patternIsValid ?
                                     undefined :
                                     'This is not a valid pattern value.'
                                   );
      // Annotating Errors
      definition.prefix_error_text = definition.type.toLowerCase() === 'any' || definition.type.toLowerCase() === 'instance' ?
                                     (definition.prefix === '' ? 'This Field is required' : undefined) :
                                     undefined;
      definition.namespace_error_text = definition.type.toLowerCase() === 'any' || definition.type.toLowerCase() === 'instance' ?
                                        definition.namespace === '' ? 'This Field is required' : undefined :
                                        undefined;

      if((props.generatorDefinition.name === '' || nameAlreadyExists) ||
         (props.generatorDefinition.pattern === '' || !patternIsValid) ||
         ((definition.type.toLowerCase() === 'any' || definition.type.toLowerCase() === 'instance') ?
         props.generatorDefinition.prefix === '' || props.generatorDefinition.namespace === '' :
         false)) {
        isValid = false;
      }
      else {
        isValid = true;
      }
    }
    // Java Based (Custom)
    else {
      // Check if the java class field is valid
      let generatorClassIsValid = true;
      const generatorClassRegEx = /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z0-9_]+)*$/g;
      generatorClassIsValid = generatorClassRegEx.test(definition.generatorClass);
      // Annotating Errors for generatorClass field
      definition.generatorClass_error_text = definition.generatorClass === '' ?
                                   'This Field is required' :
                                   (
                                     generatorClassIsValid ?
                                     undefined :
                                     'This is not a valid pattern value.'
                                   );
      // Annotating Errors
      if((props.generatorDefinition.name === '' || nameAlreadyExists) ||
         (props.generatorDefinition.generatorClass === '' || !generatorClassIsValid)) {
        isValid = false;
      }
      else if(setArgInvalid) {
        isValid = false;
      }
      else {
        isValid = true;
      }
    }

    this.setState({
      currManagerGeneratorDefinition: definition,
    });
    return isValid;
  }

  // props: generatorDefinition: <Object>
  handleSaveManagerGeneratorDefinition = props => async () => {
    this.handleSaveGeneratorDefinitionCallBack(props);
  }

  // props: generatorDefinition: <Object>
  handleSaveGeneratorDefinitionCallBack = async props => {
    if(this.validateGeneratorDefinitionForm(props)) {
      this.setState({
        tmp_numOfDeclarationsAssociatedToDefinition: 0,
      }, async function() {
        // Counting declarations related to this definition
        await this.updateAllProjectDeclarationsCallBack({generatorDefinition: props.generatorDefinition, actionType: 'count'});
        if(this.state.tmp_numOfDeclarationsAssociatedToDefinition === 0) {
          this.handleSaveManagerGeneratorDefinitionCallBack(props);
        }
        else {
          // Show confirmation snackbar to continue
          this.setState({
            confirmUpdateGeneratorDefinitionManagerSnackbarShown: true,
          });
        }
      });
    }
    else {
      this.props.showErrorSnackBar({msg: 'The Generator definition cannot be saved, due to errors in the Form.', iconSize: 'large'});
    }
  }

  // props: generatorDefinition: <Object>
  handleSaveManagerGeneratorDefinitionCallBack = async props => {
    let definition = Object.assign({}, props.generatorDefinition); // Copy
    let proceed = true;
    if(definition.new !== undefined) {
      delete definition.new;
      // Appending some UUID to the generator definition
      try {
        var res = await mainService.getNewUUID();
        definition.id = res.data;
      }
      catch (e) {
        console.error('Error occured, while trying to get new UUID from the server');
        this.props.showErrorSnackBar({msg: 'Error occured, while trying to get new UUID from the server', iconSize: 'large'});
        proceed = false;
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
    if(proceed) {
      // Case Custom
      if(definition.custom !== undefined ? definition.custom : false) {
        if(definition.pattern !== undefined) {
          delete definition.pattern;
        }
        if(definition.prefix !== undefined) {
          delete definition.prefix;
        }
        if(definition.namespace !== undefined) {
          delete definition.namespace;
        }
      }
      // Case Pattern
      else {
        if(definition.generatorClass !== undefined) {
          delete definition.generatorClass;
        }
        // If label delete prefix and namespace
        if(definition.type.toLowerCase() === 'label') {
          delete definition.prefix;
          delete definition.prefix_error_text;
          delete definition.namespace;
          delete definition.namespace_error_text;
          delete definition.shorten;
          delete definition.uuid;
        }
      }
      // Find the definition in the list
      // let managerGeneratorDefinitionWithNameIndex = this.state.currMappingProject.generatorDefinitions.findIndex(
      //   obj => obj.name.toLowerCase() === definition.name.toLowerCase()
      // );
      let array = this.state.currMappingProject.generatorDefinitions.slice(); // Create a copy
      // Case new definition (inser new)
      if(this.state.selectedManagerGeneratorDefinitionIndex === undefined) {
        array.push(definition);
      }
      // Case existing definition (update)
      else {
        array[this.state.selectedManagerGeneratorDefinitionIndex] = definition;
      }
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          generatorDefinitions: array,
        },
        currManagerGeneratorDefinition: '',
        selectedManagerGeneratorDefinitionIndex: undefined,
        generatorDefinitionsManagerChanged: false,
        tmp_numOfDeclarationsAssociatedToDefinition: 0,
        theSystemIsUpdatingGeneratorDeclarations: true, // Setting loading flag
      }, async function() {
        // Changing the namespace for all definitions with prefix same as the "just saved" generator definition
        let mappingProjectIsSaved = this.handleUpdateNamespaceEveryWhere({prefix: props.generatorDefinition.prefix, namespace: props.generatorDefinition.namespace});
        // if the generator definition was not persisted by the "handleUpdateNamespaceEveryWhere" function, then do so
        if(!mappingProjectIsSaved) {
          await mainService.saveMappingProject(this.state.currMappingProject);
          // Gathering all <prefix, namespace> pairs (affects this.state.prefixNamespaceMap)
          await this.gatheringPrefixNamespacePairs();
        }
        // Updating all declarations in the project
        await this.updateAllProjectDeclarationsCallBack({generatorDefinition: definition, actionType: 'update'});
        // Removing loading flag
        this.setState({
          theSystemIsUpdatingGeneratorDeclarations: false,
        });
      });
    }
  }

  // Close confirmation snackbar assking whether to update definition associated with one or more declarations
  // without applying the change
  handleCloseConfirmUpdateGeneratorDefinitionManagerSnackbar = () => {
    this.setState({
      confirmUpdateGeneratorDefinitionManagerSnackbarShown: false,
    });
  }

  handleConfirmUpdateGeneratorDefinitionManagerDialog = () => {
    this.setState({
      confirmUpdateGeneratorDefinitionManagerSnackbarShown: false,
      tmp_numOfDeclarationsAssociatedToDefinition: 0,
    }, function() {
      this.handleSaveManagerGeneratorDefinitionCallBack({generatorDefinition: this.state.currManagerGeneratorDefinition});//////////
    });
  }

  // Valdating the uploaded generator policy file
  handleGeneratorPolicyFileValidation = async (file, type) => {
    // boolean to hold whether the file is a valid generator policy file
    let isValid = false;
    try {
      // Reading the file
      const x3mlFileContent = await this.readUploadedFileAsText(file);
      let res = await mappingTableViewService.validateGeneratorDefinitionPolicyFileFromString({generatorPolicyFileString: x3mlFileContent});
      if(res.data.succeed) {
        isValid = true;
        this.showSuccessSnackBar({msg: res.data.msg});
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg, iconSize: 'large'});
      }
    }
    catch (e) {
      console.error('This is either not a generator policy file or it cannot be validated.');
      this.props.showErrorSnackBar({msg: 'This is either not a generator policy file or it cannot be validated.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return false;
    }

    if(isValid) {
      return new Promise((resolve, reject) => {
          resolve('text/xml');
      });
    }
    else {
      return new Promise((resolve, reject) => {
          reject();
      });
    }
  }

  handleAfterGeneratorPolicyImportFileUpload = async (error, file) => {
    // No error
    if(error === null) {
      try {
          let res = await generatorService.constructGeneratorDefinitionsArrayFromFilePath({
            filePathStr: file.serverId,
            mappingProjectId: this.state.currMappingProject.id,
          });
          console.log('Generator Definitions List:');
          console.log(res);

          // Hide the import interface
          this.hideGeneratorPolicyImport();
          this.enableSelectFromImportView();
          this.setState({
            toBeImportedGeneratorDfinitions: res.data.generatorDefinitions,
          });
      }
      catch (e) {
        console.error('The generator definitions could not be loaded from the respective file');
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
        return false;
      }
    }
  }

  showGeneratorPolicyImport = () => {
    this.setState({
      generatorPolicyFileImportShown: true,
    });
  }

  hideGeneratorPolicyImport = () => {
    this.setState({
      generatorPolicyFileImportShown: false,
    });
  }

  enableSelectFromImportView = () => {
    this.setState({
      selectFromImportViewEnabled: true,
    });
  }

  disableSelectFromImportView = () => {
    this.setState({
      selectFromImportViewEnabled: false,
    });
  }

  loadGeneratorDefinitions = newDefinitions => () => {
    this.loadGeneratorDefinitionsCallBack({newDefinitions: newDefinitions});
  }

  // props: {newDefinitions: <Array>, finalUpdatedLoadedDefinitions*: <Array>}
  // Case of syncing
  loadGeneratorDefinitionsCallBack = props => {
    const newDefinitions = props.newDefinitions;
    if(
      this.state.currMappingProject !== undefined ?
      (this.state.currMappingProject.generatorDefinitions !== undefined ? true : false) :
      false
    ) {

      let finalUpdatedLoadedDefinitions = props.finalUpdatedLoadedDefinitions !== undefined ? props.finalUpdatedLoadedDefinitions : undefined;
      // Don't apply in case of syncing (doSave: false)
      if(finalUpdatedLoadedDefinitions === undefined) {
        let loadedDefinitions = this.state.currMappingProject.generatorDefinitions.slice(); // Create a copy
        let updatedLoadedDefinitions = []; // To be used for adding all loaded definitions after changing their names (if necesary)
        // Check each of the new definitions whether it contains name that is alredy used
        for (const definition of newDefinitions) {
          let updatedDefinition = this.renameDuplicateDefinitionCallBack({
            definition: definition,
            loadedDefinitions: loadedDefinitions
          });
          updatedLoadedDefinitions.push(updatedDefinition);
        }
        //let union = [...new Set([...loadedDefinitions, ...newDefinitions])];

        this.setState({
          currMappingProject: {
            ...this.state.currMappingProject,
            generatorDefinitions: [...loadedDefinitions, ...updatedLoadedDefinitions],
          },
        }, async () => {
          this.disableSelectFromImportView();
          this.setState({
            theSystemIsUpdatingGeneratorDeclarations: true,
          }, async () => {
            for (const definition of updatedLoadedDefinitions) {
              await this.updateAllProjectDeclarationsCallBack({
                generatorDefinition: definition,
                actionType: 'update',
                equalityType: 'name',
                isPartOfMassiveAction: true,
              });
            }
            this.setState({
              theSystemIsUpdatingGeneratorDeclarations: false,
            }, async () => {
              // Persisting to the database
              await mainService.saveMappingProject(this.state.currMappingProject);
              this.showSuccessSnackBar({msg: 'The definitions were successfully loaded to the project.'});
            });
          });

          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.finalUpdatedLoadedDefinitions = this.state.currMappingProject.generatorDefinitions;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'loadGeneratorDefinitionsCallBack',
            argument: newProps
          });

        });
      }
      // Only applied when synced by others
      else {
        this.setState({
          currMappingProject: {
            ...this.state.currMappingProject,
            generatorDefinitions: finalUpdatedLoadedDefinitions,
          },
        });
      }
    }
    else {
      this.disableSelectFromImportView();
      this.props.showErrorSnackBar({
        msg: 'The currently loaded definitions seem to be undefined and thus, the system cannot merge them with those loaded from the file.',
        iconSize: 'large'
      });
    }
  }

  // prop: {definition: <Object>, loadedDefinitions: <ArrayOfObjects>, count: <Integer>}
  renameDuplicateDefinitionCallBack = (props) => {
    const definition = props.definition;
    const loadedDefinitions = props.loadedDefinitions;
    let count = props.count === undefined ? 2 : props.count;
    let updatedDefinition = Object.assign({}, definition); // copy

    let defIndex = loadedDefinitions.findIndex(
      obj => obj.name.toLowerCase() === updatedDefinition.name.toLowerCase()
    );
    // If the name is in use, change it
    if(defIndex !== -1) {
      //updatedDefinition.name = definition.name + '_(' + count +')';
      const indexOfSufix = updatedDefinition.name.lastIndexOf('_(' + count +')');
      // Case exists
      if(indexOfSufix !== -1) {
        count = count + 1;
        updatedDefinition.name = updatedDefinition.name.substring(0, indexOfSufix) + '_(' + count +')';
      }
      // Doesn't exist
      else {
        updatedDefinition.name = updatedDefinition.name + '_(' + count +')';
        //count = count + 1;
      }

      return this.renameDuplicateDefinitionCallBack({
        definition: updatedDefinition,
        loadedDefinitions: loadedDefinitions,
        count: count
      });
    }
    else {
      return updatedDefinition;
    }
  }

  // This method simply uses the passed definition and updates the passed declaration.
  // Then it returns back that declaration
  // props: {
  //    generatorDefinition: <Object>,
  //    generatorDeclaration: <Object>,
  // }
  updateDeclarationBasedOnDefinitionHelper = props => {
    let generatorDeclaration = Object.assign({}, props.generatorDeclaration);
    let generatorDefinition = Object.assign({}, props.generatorDefinition);
    // Update it's name
    generatorDeclaration.name = generatorDefinition.name;
    // Note: pattern is handle in the "handleSaveInstanceGeneratorDeclaration" or "handleSaveLabelGeneratorDeclaration" function,
    // and thus I don't need to do anything here
    // Args are more complex
    let argsArray = [];
    // Checking if Pattern Based
    if(generatorDefinition.custom === undefined || generatorDefinition.custom === false) {
      var regEx = /\{(.*?)\}/g; // Capturing text bewteen curl brankets ({...})
      let argName = null;
      let argIndex = 0;
      // Yes condition is expected but this is what we need
      while (argName = regEx.exec(generatorDefinition.pattern)) {
        let newType = generatorDeclaration.args.length > argIndex ? generatorDeclaration.args[argIndex].type : '';
        let newValue = generatorDeclaration.args.length > argIndex ? generatorDeclaration.args[argIndex].value : '';
        const arg = {
          name: argName[1],
          type: newType,
          value: newValue,
          error_type_text: newType === '' ? 'This field is required' : '',
          error_value_text: newType === 'position' || newType === '' ? '' : newValue === '' ? 'This field is required' : '',
        }
        argsArray.push(arg);
        argIndex = argIndex + 1;
      }
      generatorDeclaration.args = argsArray;
    }
    return generatorDeclaration;
  }

  // This method is called when an existing Generator Definition is updated and
  // updates the respective declaration if found
  // props: {
  //    generatorDefinition: <Object>,
  //    generatorDeclaration: <Object>,
  //    type: 'instance | label',
  //    generatorProps: <Object>,                 // props holding where this entity in terms of IDs (stored in state)
  //    labelGeneratorDeclarationArray: <Array>   // All labels of this entity (stored in state)
  //    actionType: 'update | delete'
  // }
  updateDeclarationBasedOnDefinitionCallBack = props => {
    let generatorDeclaration = Object.assign({}, props.generatorDeclaration);
    let generatorDefinition = Object.assign({}, props.generatorDefinition);

    this.setState({
      // generatorProps: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
      currGeneratorProps: props.generatorProps,
      currLabelGeneratorDeclarations: props.labelGeneratorDeclarationArray !== undefined ? props.labelGeneratorDeclarationArray : [],
    }, function() {
      if(props.actionType === 'update') {
        generatorDeclaration = this.updateDeclarationBasedOnDefinitionHelper({
          generatorDefinition: generatorDefinition,
          generatorDeclaration: generatorDeclaration
        });
      }
      // Delete
      else {
        if(props.type === 'instance') {
          // Cannot delete instance generators, but can set it to the default one
          generatorDeclaration = undefined;//{name: 'UUID'};
          /*let uuidDefinitionIndex = this.state.currMappingProject.generatorDefinitions.findIndex(
            obj => obj.name.toLowerCase() === 'uuid'
          );*/
          generatorDefinition =  undefined;//Object.assign({}, this.state.currMappingProject.generatorDefinitions[uuidDefinitionIndex]);
        }
      }

      // All other cases but delete label
      if(!(props.actionType === 'delete' && props.type === 'label')) {
        // props: {instanceGeneratorDeclaration: <Object>, instanceGeneratorDefinition: <Object>}
        const capGeneratorType = props.type.charAt(0).toUpperCase() + props.type.slice(1);
        // Update instance or label generator declaration but don't save
        this['handleSave' + capGeneratorType + 'GeneratorDeclaration']({
          [props.type + 'GeneratorDeclaration']: generatorDeclaration,
          [props.type + 'GeneratorDefinition']: generatorDefinition,
          notify: false,
          doPersist: false,
        });
      }
      else {
        this.handleDeleteLabelGeneratorDeclarations({labelGeneratorDeclarationsToBeDeleted: [generatorDeclaration], doPersist: false});
      }
    });
  }

  // Updates (or uses) all the generator declarations of the projct with respect to some action
  // Action 'update', updates all the declarations' properties according to some definition
  // that might have been updated as well
  // Action 'delete', deletes all the declarations that are based to some definition (propably that definition is also deleted)
  // Action 'count', counts how many declarations will be affected
  // props: {
  //    generatorDefinition: <Object>,
  //    actionType: 'update | delete | count',
  //    equalityType*: 'id | name',               Optional property with default value is 'id'
  //    fixedComparizonValue*: <Text>             Optional property to be used when comparing generator declaration name or id
  //                                              (i.e. fixedComparizonValue: 'CustomURI' --> generatorDeclaration.name === 'CustomURI')
  //    msg*: <Text>                              Optional property that replaces the default message to be displayed through
  //                                              SnackBar at the end of the execution, with a custom one
  //    isPartOfMassiveAction*: <Boolean>         Boolean (true or false), denoting whether this action is been called within a Loop from an external function.
  //                                              When isPartOfMassiveAction is "true", then:
  //                                                the success message will not been displayed (avoiding displayiong too many snackbars).
  //                                                It is a good practise the function calling this one to provide a total message at the end of the loop.
  //                                                Furthermore, the flag 'theSystemIsUpdatingGeneratorDeclarations' will not become false in order for that to
  //                                                be handled by the parrent function when the loop has finished.
  // }
  // *: optional property
  updateAllProjectDeclarationsCallBack = async props => {

    const isPartOfMassiveAction = props.isPartOfMassiveAction;
    // Initializing count of declarations
    // I don't need to shallow copy since it's a Primitive value
    let declarationCount = this.state.tmp_numOfDeclarationsAssociatedToDefinition;
    // Usage of equalies (i.e. declaration.definitionId === definition.id)
    // Definition Equality property can be either 'id' or 'name'
    // Default is id
    const definitionEqProp = props.equalityType !== undefined ? props.equalityType : 'id'
    // Declaration Equality property can be either 'definitionId' or 'name'
    // Default is definitionId
    const declarationEqProp = props.equalityType !== undefined ? (props.equalityType === 'name' ? 'name' : 'definitionId') : 'definitionId'
    // Seccond part of comparison (i.e. instanceGeneratorDeclaration.name === secondPartOfComparison)
    // Default is props.generatorDefinition[definitionEqProp]
    const secondPartOfComparison = props.fixedComparizonValue === undefined ? props.generatorDefinition[definitionEqProp] : props.fixedComparizonValue;

    // Mapping
    for (const mappingId of this.state.mappingIds) {
      const mapping = Object.assign({}, this.state.mappings[mappingId]);
      // {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
      let mappingGeneratorProps = {mappingId: mappingId};
      // Instance
      const instanceGeneratorDeclaration = mapping.domainTargetEntity.instanceGeneratorDeclaration !== undefined ?
                                           mapping.domainTargetEntity.instanceGeneratorDeclaration :
                                           '';
      if(instanceGeneratorDeclaration !== '' ? instanceGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
        if(props.actionType === 'update' || props.actionType === 'delete') {
          await this.updateDeclarationBasedOnDefinitionCallBack({
            generatorDefinition: props.generatorDefinition,
            generatorDeclaration: instanceGeneratorDeclaration,
            type: 'instance',
            generatorProps: mappingGeneratorProps,
            actionType: props.actionType,
          });
        }
        else if(props.actionType === 'count') {
          declarationCount = declarationCount + 1;
        }
      }
      // Label
      const labelGeneratorDeclarationArray = mapping.domainTargetEntity.labelGeneratorDeclarations !== undefined ?
                                             [...mapping.domainTargetEntity.labelGeneratorDeclarations] :
                                             [];
      for (const labelGeneratorDeclaration of labelGeneratorDeclarationArray) {
        if(labelGeneratorDeclaration !== '' ? labelGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
          if(props.actionType === 'update' || props.actionType === 'delete') {
            await this.updateDeclarationBasedOnDefinitionCallBack({
              generatorDefinition: props.generatorDefinition,
              generatorDeclaration: labelGeneratorDeclaration,
              type: 'label',
              generatorProps: mappingGeneratorProps,
              labelGeneratorDeclarationArray: this.state.mappings[mappingId].domainTargetEntity.labelGeneratorDeclarations,
              actionType: props.actionType,
            });
          }
          else if(props.actionType === 'count') {
            declarationCount = declarationCount + 1;
          }
        }
      }

      // Mapping - Additional
      for (const constExprId of mapping.constExprIds) {
        const constExpr = Object.assign({}, mapping.constExprs[constExprId]);
        // {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
        let mappingConstExprGeneratorProps = {mappingId: mappingId, constExprId: constExprId};
        // Instance
        const instanceGeneratorDeclaration = constExpr.entity.instanceGeneratorDeclaration !== undefined ? constExpr.entity.instanceGeneratorDeclaration : '';
        if(instanceGeneratorDeclaration !== '' ? instanceGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
          if(props.actionType === 'update' || props.actionType === 'delete') {
            await this.updateDeclarationBasedOnDefinitionCallBack({
              generatorDefinition: props.generatorDefinition,
              generatorDeclaration: instanceGeneratorDeclaration,
              type: 'instance',
              generatorProps: mappingConstExprGeneratorProps,
              actionType: props.actionType,
            });
          }
          else if(props.actionType === 'count') {
            declarationCount = declarationCount + 1;
          }
        }
        // Label
        const labelGeneratorDeclarationArray = constExpr.entity.labelGeneratorDeclarations !== undefined ?
                                               [...constExpr.entity.labelGeneratorDeclarations] :
                                               [];
        for (const labelGeneratorDeclaration of labelGeneratorDeclarationArray) {
          if(labelGeneratorDeclaration !== '' ? labelGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
            if(props.actionType === 'update' || props.actionType === 'delete') {
              await this.updateDeclarationBasedOnDefinitionCallBack({
                generatorDefinition: props.generatorDefinition,
                generatorDeclaration: labelGeneratorDeclaration,
                type: 'label',
                generatorProps: mappingConstExprGeneratorProps,
                labelGeneratorDeclarationArray: this.state.mappings[mappingId].constExprs[constExprId].entity.labelGeneratorDeclarations,
                actionType: props.actionType,
              });
            }
            else if(props.actionType === 'count') {
              declarationCount = declarationCount + 1;
            }
          }
        }
      }

      // Link
      for (const linkId of mapping.linkIds) {
        const link = Object.assign({}, mapping.links[linkId]);
        // {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
        let linkGeneratorProps = {mappingId: mappingId, linkId: linkId};
        // Instance
        const instanceGeneratorDeclaration = link.targetEntity.instanceGeneratorDeclaration !== undefined ? link.targetEntity.instanceGeneratorDeclaration : '';
        if(instanceGeneratorDeclaration !== '' ? instanceGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
          if(props.actionType === 'update' || props.actionType === 'delete') {
            await this.updateDeclarationBasedOnDefinitionCallBack({
              generatorDefinition: props.generatorDefinition,
              generatorDeclaration: instanceGeneratorDeclaration,
              type: 'instance',
              generatorProps: linkGeneratorProps,
              actionType: props.actionType,
            });
          }
          else if(props.actionType === 'count') {
            declarationCount = declarationCount + 1;
          }
        }
        // Label
        const labelGeneratorDeclarationArray = link.targetEntity.labelGeneratorDeclarations !== undefined ?
                                               [...link.targetEntity.labelGeneratorDeclarations] :
                                               [];
        for (const labelGeneratorDeclaration of labelGeneratorDeclarationArray) {
          if(labelGeneratorDeclaration !== '' ? labelGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
            if(props.actionType === 'update' || props.actionType === 'delete') {
              await this.updateDeclarationBasedOnDefinitionCallBack({
                generatorDefinition: props.generatorDefinition,
                generatorDeclaration: labelGeneratorDeclaration,
                type: 'label',
                generatorProps: linkGeneratorProps,
                labelGeneratorDeclarationArray: this.state.mappings[mappingId].links[linkId].targetEntity.labelGeneratorDeclarations,
                actionType: props.actionType,
              });
            }
            else if(props.actionType === 'count') {
              declarationCount = declarationCount + 1;
            }
          }
        }

        // Link - Additional
        for (const constExprId of link.constExprIds) {
          const constExpr = Object.assign({}, link.constExprs[constExprId]);
          // {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
          let linkConstExprGeneratorProps = {mappingId: mappingId, linkId: linkId, constExprId: constExprId};
          // Instance
          const instanceGeneratorDeclaration = constExpr.entity.instanceGeneratorDeclaration !== undefined ? constExpr.entity.instanceGeneratorDeclaration : '';
          if(instanceGeneratorDeclaration !== '' ? instanceGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
            if(props.actionType === 'update' || props.actionType === 'delete') {
              await this.updateDeclarationBasedOnDefinitionCallBack({
                generatorDefinition: props.generatorDefinition,
                generatorDeclaration: instanceGeneratorDeclaration,
                type: 'instance',
                generatorProps: linkConstExprGeneratorProps,
                actionType: props.actionType,
              });
            }
            else if(props.actionType === 'count') {
              declarationCount = declarationCount + 1;
            }
          }
          // Label
          const labelGeneratorDeclarationArray = constExpr.entity.labelGeneratorDeclarations !== undefined ?
                                                 [...constExpr.entity.labelGeneratorDeclarations] :
                                                 [];
          for (const labelGeneratorDeclaration of labelGeneratorDeclarationArray) {
            if(labelGeneratorDeclaration !== '' ? labelGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
              if(props.actionType === 'update' || props.actionType === 'delete') {
                await this.updateDeclarationBasedOnDefinitionCallBack({
                  generatorDefinition: props.generatorDefinition,
                  generatorDeclaration: labelGeneratorDeclaration,
                  type: 'label',
                  generatorProps: linkConstExprGeneratorProps,
                  labelGeneratorDeclarationArray: this.state.mappings[mappingId].links[linkId].constExprs[constExprId].entity.labelGeneratorDeclarations,
                  actionType: props.actionType,
                });
              }
              else if(props.actionType === 'count') {
                declarationCount = declarationCount + 1;
              }
            }
          }
        }

        // Intermediate
        for (const targetIntermediateId of link.targetIntermediateIds) {
          const targetIntermediate = Object.assign({}, link.targetIntermediates[targetIntermediateId]);
          // {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
          let targetIntermediateGeneratorProps = {mappingId: mappingId, linkId: linkId, intermediateId: targetIntermediateId};
          // Instance
          const instanceGeneratorDeclaration = targetIntermediate.targetEntity.instanceGeneratorDeclaration !== undefined ? targetIntermediate.targetEntity.instanceGeneratorDeclaration : '';
          if(instanceGeneratorDeclaration !== '' ? instanceGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
            if(props.actionType === 'update' || props.actionType === 'delete') {
              await this.updateDeclarationBasedOnDefinitionCallBack({
                generatorDefinition: props.generatorDefinition,
                generatorDeclaration: instanceGeneratorDeclaration,
                type: 'instance',
                generatorProps: targetIntermediateGeneratorProps,
                actionType: props.actionType,
              });
            }
            else if(props.actionType === 'count') {
              declarationCount = declarationCount + 1;
            }
          }
          // Label
          const labelGeneratorDeclarationArray = targetIntermediate.targetEntity.labelGeneratorDeclarations !== undefined ?
                                                 [...targetIntermediate.targetEntity.labelGeneratorDeclarations] :
                                                 [];
          for (const labelGeneratorDeclaration of labelGeneratorDeclarationArray) {
            if(labelGeneratorDeclaration !== '' ? labelGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
              if(props.actionType === 'update' || props.actionType === 'delete') {
                await this.updateDeclarationBasedOnDefinitionCallBack({
                  generatorDefinition: props.generatorDefinition,
                  generatorDeclaration: labelGeneratorDeclaration,
                  type: 'label',
                  generatorProps: targetIntermediateGeneratorProps,
                  labelGeneratorDeclarationArray: this.state.mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetEntity.labelGeneratorDeclarations,
                  actionType: props.actionType,
                });
              }
              else if(props.actionType === 'count') {
                declarationCount = declarationCount + 1;
              }
            }
          }

          // Intermediate - Additional
          for (const constExprId of targetIntermediate.constExprIds) {
            const constExpr = Object.assign({}, targetIntermediate.constExprs[constExprId]);
            // {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId*: <Text>}
            let targetIntermediateConstExprGeneratorProps = {mappingId: mappingId, linkId: linkId, intermediateId: targetIntermediateId, constExprId: constExprId};
            // Instance
            const instanceGeneratorDeclaration = constExpr.entity.instanceGeneratorDeclaration !== undefined ? constExpr.entity.instanceGeneratorDeclaration : '';
            if(instanceGeneratorDeclaration !== '' ? instanceGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
              if(props.actionType === 'update' || props.actionType === 'delete') {
                await this.updateDeclarationBasedOnDefinitionCallBack({
                  generatorDefinition: props.generatorDefinition,
                  generatorDeclaration: instanceGeneratorDeclaration,
                  type: 'instance',
                  generatorProps: targetIntermediateConstExprGeneratorProps,
                  actionType: props.actionType,
                });
              }
              else if(props.actionType === 'count') {
                declarationCount = declarationCount + 1;
              }
            }
            // Label
            const labelGeneratorDeclarationArray = constExpr.entity.labelGeneratorDeclarations !== undefined ?
                                                   [...constExpr.entity.labelGeneratorDeclarations] :
                                                   [];
            for (const labelGeneratorDeclaration of labelGeneratorDeclarationArray) {
              if(labelGeneratorDeclaration !== '' ? labelGeneratorDeclaration[declarationEqProp] === secondPartOfComparison : false) {
                if(props.actionType === 'update' || props.actionType === 'delete') {
                  await this.updateDeclarationBasedOnDefinitionCallBack({
                    generatorDefinition: props.generatorDefinition,
                    generatorDeclaration: labelGeneratorDeclaration,
                    type: 'label',
                    generatorProps: targetIntermediateConstExprGeneratorProps,
                    labelGeneratorDeclarationArray: this.state.mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].entity.labelGeneratorDeclarations,
                    actionType: props.actionType,
                  });
                }
                else if(props.actionType === 'count') {
                  declarationCount = declarationCount + 1;
                }
              }
            }
          }
        }
      }
    }
    if(props.actionType === 'update' || props.actionType === 'delete') {
      // Now that state has been updated, persist changes in the database
      await this.saveMappingTreeModel();
      // Skiping that if this is part of a massive action (performed many times in loop)
      if(isPartOfMassiveAction !== undefined ? !isPartOfMassiveAction : true) {
        const msg = props.msg !== undefined ? props.msg : 'All instance and label generator declarations were updated successfully'
        this.showSuccessSnackBar({msg: msg});
      }
    }
    else if(props.actionType === 'count') {
      // Now that all associated declarations have been counted, persist their count in the state
      this.setState({
        tmp_numOfDeclarationsAssociatedToDefinition: declarationCount,
      });
    }
    // Skiping that if this is part of a massive action (performed many times in loop)
    if(isPartOfMassiveAction !== undefined ? !isPartOfMassiveAction : true) {
      // Removing loading flag (if any)
      this.setState({
        theSystemIsUpdatingGeneratorDeclarations: false,
      });
    }
  }

  // Gathering all <Prefix, Namespace> pairs from either generatorDefinitions
  // or source and target files
  gatheringPrefixNamespacePairs = async () => {

    let prefixNamespaceMap = new Map(); // Map to hold <Prefix, Namespace> pairs
    // Generator Definitions
    console.log('this.state');
    console.log(this.state);
    for (const definition of this.state.currMappingProject.generatorDefinitions) {
      if(definition.prefix !== undefined) {
        prefixNamespaceMap.set(definition.prefix, definition.namespace !== undefined ? definition.namespace : '');
      }
    }

    // Source FileMetadata
    if(this.state.fileMetadataObjectList.sourceFiles !== undefined) {
      for (const file of this.state.fileMetadataObjectList.sourceFiles) {
        if(file.namespacePrefixList !== null) {
          for (const namespacePrefix of file.namespacePrefixList) {
            prefixNamespaceMap.set(namespacePrefix.label, namespacePrefix.value);
          }
        }
      }
    }

    // Target FileMetadata
    if(this.state.fileMetadataObjectList.targetFiles !== undefined) {
      for (const file of this.state.fileMetadataObjectList.targetFiles) {
        if(file.namespacePrefixList !== null) {
          for (const namespacePrefix of file.namespacePrefixList) {
            prefixNamespaceMap.set(namespacePrefix.label, namespacePrefix.value);
          }
        }
      }
    }

    this.setState({
      prefixNamespaceMap: prefixNamespaceMap,
    });

  }

  handleActivateTitleEditing = props => event => {
    this.setState({
      titleIsEditing: true
    }, function() {
      this.textInput.current.focus();
    });
  }

  // props: {fieldName: <Text>, , errorFieldName: <Text>}
  handleDeactivateTitleEditing = props => () => {
    this.handleDeactivateTitleEditingCallBack(props);
  }

  // props: {fieldName: <Text>, , errorFieldName: <Text>, fieldValue* <Text>}
  // * Only in case of syncing. It holds the field's value
  handleDeactivateTitleEditingCallBack = props => {
    let fieldValue = props.fieldValue !== undefined ? props.fieldValue : undefined;
    // Not applied when syncing
    if(fieldValue === undefined) {
      if (this.formMapingProject.checkValidity() === true) {
        // Setting flag for editing state to false
        this.setState({
          [props.fieldName + 'IsEditing']: false
        }, function() {
          // Deleting backup for the fieldName's value
          this.setState({
            [props.fieldName + 'Backup']: undefined
          }, async function() {
            const res = await mainService.saveMappingProject(this.state.currMappingProject);
            if(res.data.succeed) {
              this.setState({
                currMappingProject: res.data.mappingProject
              });
            }
            let newProps = Object.assign({}, props); // Copy
            newProps.fieldValue = this.state.currMappingProject[props.fieldName];
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleDeactivateTitleEditingCallBack',
              argument: newProps
            });
          });
        });
      }
      else {
        // Backing up the error
        this.setState({
          [props.fieldName + 'Backup']: this.state.currMappingProject[props.fieldName]
        });
      }
    }
    // Only applied when syncing
    else {
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          [props.fieldName]: fieldValue,
        }
      });
    }
  }

  // props: {fieldName: <Text>, required: <Boolean>, errorFieldName: <Text>}
  handleMappingProjectFieldInputChange = props => event => {
    if(props.required) {
      if (event.target.value === '') {
        this.setState({
          [props.errorFieldName]: 'This field is required',
        });
      }
      else {
        this.setState({
            [props.errorFieldName]: '',
        });
      }
    }
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        [props.fieldName]: event.target.value,
      }
    });
  }

  // props: {msg: <Text>}
  showSuccessSnackBar = props => {
    this.props.enqueueSnackbar(
      props.msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500
      }
    );
  }

  // Transformation Error Handling

  // SuccessWithErrors Snackbar
  handleSuccessWithErrorsSnackbarShownOpen = () => {
    //this.setState({ successWithErrorsSnackbarShown: true });
    this.showSuccessWithErrorsSnackBar({
      onConfirmClick: this.handleShowTransformationErrorMessage,
      onCancelClick: this.handleCloseSuccessWithErrorsSnackbar,
    })
  }

  // props: {onConfirmClick: <Function>, onCancelClick: <Function>}
  showSuccessWithErrorsSnackBar = props => {
    //event.stopPropagation();
    this.showConfirmSnackBar({
      msg: '<span>The X3ML engine transformation was completed with errors<br/>Do you want to see the error message?</span>',
      iconSize: 'large',
      onConfirmClick: props.onConfirmClick,
      confirmButtonLabel: 'Show Error Message',
      onCancelClick: props.onCancelClick,
      cancelButtonLabel: 'Close',
      argument: props,
    });
  }

  handleCloseSuccessWithErrorsSnackbar = props => () => {
    this.handleCloseSuccessWithErrorsSnackbarCallBack(props);
  }
  handleCloseSuccessWithErrorsSnackbarCallBack = props => {
    this.props.closeSnackbar(props.snackBarId);
  }

  handleShowTransformationErrorMessage = props => () => {
    //console.log(this.state.transformationErrorMessage);
    this.handleCloseSuccessWithErrorsSnackbarCallBack({snackBarId: props.argument.id});
    this.handleShowTransformationErrorDialog();
  }

  handleShowTransformationErrorDialog = () => {
    this.handleShowTransformationErrorDialogCallBack();
  }

  handleShowTransformationErrorDialogCallBack = () => {
    this.setState({
        transformationErrorDialogOpen: true,
        xmlDialogOpen: false,
    });
  }

  handleCloseTransformationErrorDialog = props => () => {
    if(props.actionType === 'close') {
      this.setState({
        transformationErrorDialogOpen: false,
        xmlDialogOpen: true,
      });
    }
  }

  handleTransformationErrorDialogToggleFullScreen = () => {
    this.setState({
      transformationErrorDialogFullScreen: !this.state.transformationErrorDialogFullScreen,
    });
  }

  // RDF Dialog self function handling
  handleCloseRdfDialog = () => {
    if(this.state.wholeX3MLCodeChanged) {
      this.setState({
        confirmCloseWholeX3MLCodeSnackbarShown: true
      });
    }
    else {
      this.setState({
          xmlDialogOpen: false,
      });
    }
  }

  handleRdfDialogToggleFullScreen = () => {
    this.setState({
      xmlDialogFullScreen: !this.state.xmlDialogFullScreen
    });
  }

  // props: {value: <Text>, state: <TEXT>}
  handleCodeMirrorInputChange = props => {
    this.setState({
      [props.state]: props.value,
      wholeX3MLCodeChanged: true,
    });
  }

  // Show snackbar for confirming that changes on the whole X3ML code will be applied
  handleShowConfirmationForApplyingWholeX3MLCodeChanges = () => {
    this.setState({
      confirmImportWholeX3MLCodeSnackbarShown: true
    });
  }

  // Close Confirmation snackbar for the whole x3ML editor
  handleCloseImportWholeX3MLCodeSnackbar = () => {
    this.setState({
      confirmImportWholeX3MLCodeSnackbarShown : false,
    });
  }

  handleApplyWholeX3MLCodeChanges = () => {
    this.setState({
      confirmImportWholeX3MLCodeSnackbarShown: false,
    }, async () => {
      // Checking if there are unused mappings or links
      let everyItemInUse = true;
      let mappingsLinksInUseMsg = '';
      for (let mappingId of this.state.mappingIds) {
        let mapping = this.state.mappings[mappingId];
        // Check if the mapping is in use
        if(mapping.isInUse !== undefined ? !mapping.isInUse : false) {
          everyItemInUse = false;
        }
        else {
          const linksInUse = mapping.linkIds.filter(id => mapping.links[id].isInUse !== undefined && mapping.links[id].isInUse !== null ? mapping.links[id].isInUse : true).length;
          if(linksInUse < mapping.linkIds.length) {
            everyItemInUse = false;
          }
        }
      }
      if(!everyItemInUse) {
        mappingsLinksInUseMsg = 'It appears that you have at least one unselected mapping or link in the project. <br/> Please make sure that all mappings and links are checked before been able to save any change applied from here.'
      }
      console.log();

      if(everyItemInUse) {
        let importRes = await mappingTableViewService.convertX3mlCodeToJson({
          x3mlCode: this.state.xmlDialogInput,
          mappingProjectId: this.state.currMappingProject.id,
        });
        if(importRes.data.succeed) {
          this.setState({
            mappings: importRes.data.x3mlJson.mappings,
            mappingIds: importRes.data.x3mlJson.mappingIds,
            currMappingProject: {
              ...this.state.currMappingProject,
              comments: importRes.data.x3mlJson.comments,
            },
            wholeX3MLCodeChanged: false,
            xmlDialogOpen: false,
          }, async function() {
            // Update the GeneratorDefinitions of the current MappingProject & Persist MappingProject & Tree model to the database
            await this.handleUpdateAndSaveGeneratorDefinitions({namespaces: importRes.data.x3mlJson.namespaces});
            // Retrieve metadata of this "Mapping Project"
            let resMappingProject = await mainService.retrieveMappingProjectById({id: this.state.currMappingProject.id});
            if(resMappingProject.data !== false) {
              this.setState({
                  currMappingProject: resMappingProject.data,
              }, async () => {
                await this.retrieveFileMetadataListByMappingProjectId({id: this.state.currMappingProject.id});
                // Syncing to others
                let newProps = {
                  user: this.props.user,
                };
                this.sendJsonObjectToSpecificUsers({
                  actionName: 'handleApplyWholeX3MLChangesCallBack',
                  argument: newProps
                });
              });
            }
            this.showSuccessSnackBar({msg: importRes.data.msg});
          });
        }
        else {
          this.props.showErrorSnackBar({msg: importRes.data.msg, iconSize: 'large'});
        }
      }
      else {
        this.props.showErrorSnackBar({msg: mappingsLinksInUseMsg, iconSize: 'large', hasHtmlContent: true,});
      }
    });
  }
//
  handleApplyWholeX3MLChangesCallBack = async props => {
    let resMappingProject = await mainService.retrieveMappingProjectById({id: this.state.currMappingProject.id});
    if(resMappingProject.data !== false) {
      this.setState({
          currMappingProject: resMappingProject.data,
          loadingModalOpen: true, // Displaying loading modal
          sourceLoading: true,
          loadingTargetMessage: 'Resolving target model(s) to get the available classes.',
          targetLoading: true,
          loadingSourceMessage: 'Resolving source model(s) to get the available xPaths.',
          isRedndering: false,
      }, async function() {
        // Retrieve the treeModel and load it in the state
        let mappingTreeModelRes = await mappingTableViewService.retrieveMappingTreeModelById({id: this.state.currMappingProject.mappingTreeModelId})
        if(mappingTreeModelRes.data !== false) {
          this.setState({
            mappings: mappingTreeModelRes.data.treeModel.mappings,
            mappingIds: mappingTreeModelRes.data.treeModel.mappingIds,
          }, async () => {
            // Executing the reasoner for the target sources
            await this.executeReasoner();
            let inputParams = {
              id: this.state.currMappingProject.id,
              canEdit: this.state.canEdit,
            }
            await this.retrieveFileMetadataListByMappingProjectId(inputParams);
            // Executing the respective analyzer for the input sources
            await this.executeSimpleXpathResolver();
            // Give some millisecs (1000 ms = 1 sec) for the animations to complete
            setTimeout( () => {
              this.setState({
                loadingModalOpen: false,
              }, () => {
                this.setState({
                  sourceLoading: false,
                  targetLoading: false,
                }, () => {
                  this.props.showInfoSnackBar({
                    msg:  '<span>' +
                            'User ' + props.user.firstname + ' ' + props.user.lastname + ', has just imported X3ML code that affects the whole mappig project.' +
                          '</span>' +
                          '</br />' +
                          '<span>' +
                            'For that reason the classes and the Xpaths have been re-loaded.' +
                          '</span>',
                    hasHtmlContent: true,
                  });
                });
              });
            }, 1000);
          });
        }

      });
    }
  }

  // Close Confirmation snackbar for the whole x3ML editor
  handleCloseConfirmCloseWholeX3MLCodeEditorSnackbar = () => {
    this.setState({
      confirmCloseWholeX3MLCodeSnackbarShown : false,
    });
  }

  handleConfirmCloseWholeX3MLCode = () => {
    this.setState({
      confirmCloseWholeX3MLCodeSnackbarShown : false,
      xmlDialogInput: undefined,
      wholeX3MLCodeChanged: false,
      xmlDialogOpen: false,
    });
  }


  // Generator Dialog self function handling
  handleCloseGeneratorDialog = () => {
    this.setState({
        generatorDialogOpen: false,
        generatorTabValue: 0,
        generatorTabIndex: 0,
        areGeneratorTabsDisabled: false,
    });
  }

  // props: {actionType: '', newTabValue*: <text>}
  // * only available if actionType is changeTab
  handleCheckWhetherToLeaveGeneratorDialog = props => () => {
    this.handleCheckWhetherToLeaveGeneratorDialogCallBack(props);
  }

  handleCheckWhetherToLeaveGeneratorDialogCallBack = props => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.generatorDeclarationChanged || this.state.generatorDefinitionChanged) {
      if(props.actionType === 'close') {
        this.setState({
          confirmCloseGeneratorSnackbarShown: true
        });
      }
      else if(props.actionType === 'goBack') {
        this.setState({
          confirmGoBackGeneratorSnackbarShown: true
        });
      }
      else if(props.actionType === 'changeTab') {
        this.setState({
          confirmChangeTabGeneratorSnackbarShown : true,
          tmpGeneratorTabValue: props.newTabValue
        });
      }
    }
    else {
      // Close the dialog
      if(props.actionType === 'close') {
        this.handleCloseGeneratorDialog();
      }
      else if(props.actionType === 'goBack') {
        this.setState({
          areGeneratorTabsDisabled: false
        }, function() {
          this.generatorGoBackTo({tabIndex: 1});
        });
      }
      else if(props.actionType === 'changeTab') {
        this.handleApplyGeneratorTabChange(props.newTabValue);
      }
    }
  }

  // Confirm close - Generator
  handleCloseConfirmCloseGeneratorSnackbar = () => {
    this.setState({
      confirmCloseGeneratorSnackbarShown: false
    });
  }

  handleConfirmCloseGeneratorDialog = () => {
    this.setState({
      confirmCloseGeneratorSnackbarShown: false,
      generatorDialogOpen: false,
      generatorTabValue: 0,
      generatorTabIndex: 0,
      areGeneratorTabsDisabled: false,
      currLabelGeneratorDeclaration: '',
      currLabelGeneratorDeclarations: [],
      generatorDeclarationChanged: false,
      generatorDefinitionChanged: false,
    });
  }

  // Confirm Go Back - Generator
  handleCloseConfirmGoBackGeneratorSnackbar = () => {
    this.setState({
      confirmGoBackGeneratorSnackbarShown: false
    });
  }

  handleConfirmGoBackGeneratorDialog = () => {
    this.setState({
      confirmGoBackGeneratorSnackbarShown: false,
      areGeneratorTabsDisabled: false,
      currLabelGeneratorDeclaration: '',
      generatorDeclarationChanged: false,
      generatorDefinitionChanged: false,
    }, function() {
      this.generatorGoBackTo({tabIndex: 1});
    });
  }

  // Confirm Change Tab - Generator
  handleCloseConfirmChangeTabGeneratorSnackbar  = () => {
    this.setState({
      confirmChangeTabGeneratorSnackbarShown : false,
      tmpGeneratorTabValue: -1,
    });
  }

  handleConfirmChangeTabGeneratorDialog  = () => {
    this.setState({
      confirmChangeTabGeneratorSnackbarShown : false,
      generatorDeclarationChanged: false,
      generatorDefinitionChanged: false,
    }, function() {
      if(this.state.generatorTabValue === 0) {
        let tmpGeneratorDeclaration = ''; // Temporary Instance Generator Declartion to hold the original value
        let generatorProps = this.state.currGeneratorProps;
        //instanceGeneratorDefinitionSelected
        // Intermediate
        if(generatorProps.intermediateId !== undefined && generatorProps.intermediateId !== -1) {
          if(generatorProps.constExprId !== undefined) {
            // ConstExpr
            tmpGeneratorDeclaration = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].constExprs[generatorProps.constExprId].entity.instanceGeneratorDeclaration
          }
          else {
            // Intermediate
            tmpGeneratorDeclaration = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetIntermediates[generatorProps.intermediateId].targetEntity.instanceGeneratorDeclaration
          }
        }
        // Link
        else if(generatorProps.linkId !== undefined && generatorProps.linkId !== -1) {
          if(generatorProps.constExprId !== undefined) {
            // ConstExpr
            tmpGeneratorDeclaration = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].constExprs[generatorProps.constExprId].entity.instanceGeneratorDeclaration
          }
          else {
            // Link
            tmpGeneratorDeclaration = this.state.mappings[generatorProps.mappingId].links[generatorProps.linkId].targetEntity.instanceGeneratorDeclaration
          }
        }
        // Mapping (Domain)
        else if(generatorProps.mappingId !== undefined && generatorProps.mappingId !== -1) {
          if(generatorProps.constExprId !== undefined) {
            // ConstExpr
            tmpGeneratorDeclaration = this.state.mappings[generatorProps.mappingId].constExprs[generatorProps.constExprId].entity.instanceGeneratorDeclaration
          }
          else {
            // Mapping
            tmpGeneratorDeclaration = this.state.mappings[generatorProps.mappingId].domainTargetEntity.instanceGeneratorDeclaration
          }
        }
        // Reset the old original value of the generator declaration and definition
        this.setState({
          currInstanceGeneratorDeclaration: tmpGeneratorDeclaration,
        }, function() {
          // Set predefined value for the list of definitions wrt the instance generator
          this.setPredefinedGeneratorDefinion({type: 'instance', capType: 'Instance'});
          // Apply the tab change based on some temporary tabValue
          this.handleApplyGeneratorTabChange(this.state.tmpGeneratorTabValue);
        });
      }
    });
  }


  handleGeneratorDialogToggleFullScreen = () => {
    this.setState({
      generatorDialogFullScreen: !this.state.generatorDialogFullScreen
    });
  }

  // props: {generatorDefinition: <Object>, index: <Integer>,
  //         type: 'instance | label', capType: 'Instance | Label'}
  selectDefinitionGenerator = props => () => {
    this.selectDefinitionGeneratorCallBack(props);
  }

  // CallBack
  // props: {generatorDefinition: <Object>, index: <Integer>,
  //         type: 'instance | label', capType: 'Instance | Label'}
  selectDefinitionGeneratorCallBack = props => {

    let tmpDefinition = Object.assign({}, props.generatorDefinition); // Copy
    // Add the missing field "namespace" if it doesn't exist
    if(tmpDefinition.namespace === undefined) {
      tmpDefinition.namespace = '';
    }
    // Set the selected generator as checked
    this.setState({
        ['curr' + props.capType + 'GeneratorDefinitionSelected']: tmpDefinition,
    }, () => {
      // Generic (no uuid nor literal)
      if(tmpDefinition.name.toLowerCase() !== String('uuid') &&
         tmpDefinition.name.toLowerCase() !== String('literal') &&
         tmpDefinition.name.toLowerCase() !== String('preflabel')) {
        // Constructing the args array
        let argsArray = [];
        // Pattern Based
        if(tmpDefinition.custom === undefined || tmpDefinition.custom === false) {
          var regEx = /\{(.*?)\}/g; // Capturing text bewteen curl brankets ({...})
          let argName = null;
          // Yes condition is expected but this is what we need
          while (argName = regEx.exec(tmpDefinition.pattern)) {
            const arg = {
              name: argName[1],
              type: '',
              value: '',
              error_type_text: '',
              error_value_text: ''
            }
            argsArray.push(arg);
          }
          // In case this is a label, the language filed has to be added as well
          if(props.type === 'label') {
            const languageArg = {
              name: 'language',
              type: '',
              value:''
            }
            argsArray.push(languageArg);
          }
        }

        // Custom based (Java)
        else {
          // Iterate setArgs Array
          for (const setArg of tmpDefinition.setArgs) {
            let arg = {
              value: '',
              error_value_text: ''
            }
            // Handle the rest of the attributes (mind that name is required)
            // Iterate attributes Array
            for (const attr of setArg.attributes) {
              for (const key of Object.keys(attr)) {
                arg[key] = attr[key];
                if(key !== 'name') {
                  arg[key + '_error_text'] = '';
                }
              }
            }
            argsArray.push(arg);
          }
        }

        // Construct the new generator declaration
        const  newDeclarationObj = {
          name: tmpDefinition.name,
          args: argsArray,
          id: props.type === 'label' ? this.state['curr' + props.capType + 'GeneratorDeclaration'].id : undefined,
          definitionId: tmpDefinition.id,
        };
        // Set it to the state
        this.setState({
          ['curr' + props.capType + 'GeneratorDeclaration']: newDeclarationObj
        });
      }
      // Literal & preflabel
      else if(tmpDefinition.name.toLowerCase() === String('literal') ||
              tmpDefinition.name.toLowerCase() === String('preflabel')) {
        // Constructing the args array
        let argsArray = [];
        const textArg = {
          name: 'text',
          type: '',
          value:''
        }
        argsArray.push(textArg);
        const languageArg = {
          name: 'language',
          type: '',
          value:''
        }
        argsArray.push(languageArg);

        // Construct the new generator declaration (mind the special way that we handle prefLabel (with capital "L"))
        const  newDeclarationObj = {
          name: tmpDefinition.name.toLowerCase() !== 'preflabel' ?
                tmpDefinition.name.toLowerCase().charAt(0).toUpperCase() + tmpDefinition.name.toLowerCase().slice(1) :
                'prefLabel',
          args: argsArray,
          definitionId: tmpDefinition.id,
          id: props.type === 'label' ? this.state['curr' + props.capType + 'GeneratorDeclaration'].id : undefined,
        };
        // Set it to the state
        this.setState({
          ['curr' + props.capType + 'GeneratorDeclaration']: newDeclarationObj
        });
      }
      // UUID
      else if(tmpDefinition.name.toLowerCase() === String('uuid').toLowerCase()) {
        const  newDeclarationObj = {
          name: 'UUID',
          definitionId: tmpDefinition.id,
        };
        // Set it to the state
        this.setState({
          ['curr' + props.capType + 'GeneratorDeclaration']: newDeclarationObj
        });
      }
    });

  }

  // props: {generatorDefinition: <Object>, generatorType: 'instance | label'}
  handleSetGeneratorDefinitionforDeclarationsOfName = props => async () => {
    this.setState({
      theSystemIsUpdatingGeneratorDeclarations: true, // Setting loading flag
    }, async () => {
      const capGeneratorType = props.generatorType.charAt(0).toUpperCase() + props.generatorType.slice(1);
      await this.updateAllProjectDeclarationsCallBack({
        generatorDefinition: props.generatorDefinition,
        actionType: 'update',
        equalityType: 'name',
        fixedComparizonValue: this.state['curr' + capGeneratorType + 'GeneratorDeclaration'].name,
        msg: 'All generator declarations, previously based on "' +
             ['curr' + props.capGeneratorType + 'GeneratorDeclaration'].name +
             '" definition, have now been succesfuly linked with the "' +
             props.generatorDefinition.name +
             '" Generator Definition'
      });
      // Update Current declaration & set selected definition
      let currDeclaration = Object.assign({}, this.state['curr' + capGeneratorType + 'GeneratorDeclaration']); // Copy
      let generatorDeclaration = this.updateDeclarationBasedOnDefinitionHelper({
        generatorDefinition: props.generatorDefinition,
        generatorDeclaration: currDeclaration,
      });
      generatorDeclaration.pattern = props.generatorDefinition.pattern;
      generatorDeclaration.definitionId = props.generatorDefinition.id;
      this.setState({
        ['curr' + capGeneratorType + 'GeneratorDeclaration']: generatorDeclaration,
        ['curr' + capGeneratorType + 'GeneratorDefinitionSelected']: props.generatorDefinition,
        showFixMissingDefinitionErrorView: false, // back to normal
      });
    });
  }

  // props: {labelGeneratorDeclaration: <Object>, index: <integer>}
  selectLabelGeneratorDeclaration = props => {
    this.setState({
      currLabelGeneratorDeclaration: props.labelGeneratorDeclaration
    }, function() {
      // Set predefined value for the list of definitions wrt the label generator
      this.setPredefinedGeneratorDefinion({type: 'label', capType: 'Label'});
    });
  }

  // Used when showing the form to add new label generator
  resetLabelGeneratorDeclaration = () => {
    // Construct new UI for declaration
    //const declarationObject Object.assign({}, this.state.currLabelGeneratorDeclarations[props.index]); // Copy of the object of objects
    this.setState({
      currLabelGeneratorDeclaration: ''
    }, function() {
      // Set predefined value for the list of definitions wrt the label generator
      // to none (since a new declaration is about to be constructed )
      this.setState({
          currLabelGeneratorDefinitionSelected: '',
      });
    });
  }

  //props: {generatorDeclaration: <Object>, capType: 'Instance | Label'}
  handleValidateDeclarationForm = props => {
    let proceed = true;
    let tmpDeclaration = Object.assign({}, props.generatorDeclaration); // Copy
    // Arguments (args)
    for (const arg of tmpDeclaration.args) {
      // if(!(tmpDeclaration.name.toLowerCase() === 'literal' && arg.name.toLowerCase() === 'language') &&
      //    !(tmpDeclaration.name.toLowerCase() === 'preflabel' && arg.name.toLowerCase() === 'language')) {
      if(
        !(
          (
            tmpDeclaration.name.toLowerCase() === 'literal' ||
            tmpDeclaration.name.toLowerCase() === 'preflabel' ||
            props.capType === 'Label'
          ) &&
          arg.name.toLowerCase() === String('language').toLowerCase()
       )
     ) {
        arg.error_type_text = ''; // Initialize
        arg.error_value_text = ''; // Initialize
        if(arg.type !== undefined) {
          if(arg.type === '') {
            arg.error_type_text = 'This field is required'
          }
          else if(arg.type.toLowerCase() !== 'position') {
            if(arg.value === '') {
              arg.error_value_text = 'This field is required'
            }
          }
        }
        else {
          if(arg.value === '') {
            arg.error_value_text = 'This field is required'
          }
        }
      }
      else {
        if(arg.type !== '' && arg.value === '') {
          arg.error_value_text = 'This field is required'
        }
        else {
          arg.error_value_text = ''
        }
      }
      /*
      else { // Literal - language or PrefLabel -language
        // handled in the server
      }
      */
      if((arg.error_type_text !== '' && arg.error_type_text !== undefined) || (arg.error_value_text !== '' && arg.error_value_text !== undefined)) {
        proceed = false;
      }
    }
    this.setState({
      ['curr' + props.capType + 'GeneratorDeclaration']: tmpDeclaration
    });
    return proceed;
  }

  // props: {fieldName: '<Text>', generatorType: 'instance | label', generatorDefinition: <Object>}
  handleDefinitionFieldChange = props => event => {
    this.handleDefinitionFieldChangeCallBack({
      fieldName: props.fieldName,
      generatorType: props.generatorType,
      generatorDefinition: props.generatorDefinition,
      event: event
    });
  }

  // props: {fieldName: '<Text>', generatorType: 'instance | label', generatorDefinition: <Object>, event*: <Object>}
  // *: Optional
  handleDefinitionFieldChangeCallBack = props => {
    const newVal = props.event !== undefined ? props.event.target.value : '';
    let newDefinition = Object.assign({}, props.generatorDefinition); // copy definition
    newDefinition[props.fieldName] = newVal;
    // newDefinition['error_' + props.fieldName + '_text'] = 
    //   newVal === '' ? 
    //   'This field is required' : 
    //   '';
    newDefinition['error_' + props.fieldName + '_text'] = 
      newVal === '' ? 
      'This field is required' : 
      props.fieldName === 'namespace' ?
      (
        !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(newVal) ? 
        'This field must be a valid URI' : 
        ''
      ) :
      '';

    // Constructing the generator type with the first char be in uppercase
    const capGeneratorType = props.generatorType.charAt(0).toUpperCase() + props.generatorType.slice(1);
    // Setting to state
    this.setState({
      ['curr' + capGeneratorType + 'GeneratorDefinitionSelected']: newDefinition,
      generatorDefinitionChanged: true,
    });
    return newDefinition['error_' + props.fieldName + '_text'] === '';
  }

  // props: {fieldName: <Text>, arg: <Object>, generatorType: 'instance | label', generatorDeclarationName: <Text>}
  handleArgChange = props => event => {
    const capGeneratorType = props.generatorType.charAt(0).toUpperCase() + props.generatorType.slice(1);
    const newVal = event !== null ? (props.fieldName === 'type' ? event.value : event.target.value) : '';
    const index = this.state['curr' + capGeneratorType + 'GeneratorDeclaration'].args.findIndex(obj => obj.name === props.arg.name);
    let array = update(this.state['curr' + capGeneratorType + 'GeneratorDeclaration'].args,
      {
        [index]: {
          [props.fieldName]: {$set: newVal},
          ['error_' + props.fieldName + '_text']: {
            $set: newVal === '' &&
                  !(
                    (
                      props.generatorDeclarationName.toLowerCase() === 'literal' ||
                      props.generatorDeclarationName.toLowerCase() === 'preflabel' ||
                      props.generatorType === 'label'
                    ) &&
                    props.arg.name.toLowerCase() === 'language'
                  ) ?
                  'This field is required' :
                  ''

          },
        }
      }
    );

    console.log('array[index]:');
    console.log(array[index]);

    console.log('array:');
    console.log(array);

    // Again change the same indexed item in the array
    // such that language argument (case of label, literal, or preflabel)
    // will not allow empty value if the type is not empty
    // if(
    //   (
    //     props.generatorDeclarationName.toLowerCase() === 'literal' ||
    //     props.generatorDeclarationName.toLowerCase() === 'preflabel' ||
    //     props.generatorType === 'label'
    //   ) && props.arg.name.toLowerCase() === 'language'
    // ) {
    //   if(props.fieldName === 'value') {
    //     array = update(
    //       array, {[index]: {
    //         error_value_text: {$set: newVal === '' && props.arg.type !== '' ? 'This field is required' : ''},
    //       }}
    //     );
    //   }
    //   else if(props.fieldName === 'type') { // Here newVal holds the value of type
    //     array = update(
    //       array, {[index]: {
    //         value: {$set: newVal === '' ? '' : props.arg.value},
    //         error_value_text: {$set: newVal === '' ? '' : 'This field is required'},
    //       }}
    //     );
    //   }
    // }

    // In case that the selected arg-type is "position", then male the value field empty (it will be disabled anyway)
    if(props.fieldName === 'type' && newVal === 'position') {
      array = update(array, {[index]: {value: {$set: ''}}});
    }
    this.setState({
      ['curr' + capGeneratorType + 'GeneratorDeclaration']: {
        ...this.state['curr' + capGeneratorType + 'GeneratorDeclaration'],
        args: array
      },
      generatorDeclarationChanged: true,
    });

  }

  // Generator Dialog Tabs and index
  // Label Generator Related
  showLabelGeneratorForm = () => {
    this.setState({
      generatorTabIndex: 2,
      areGeneratorTabsDisabled: true,
    });
  }

  handleGeneratorTabChange = (event, newValue) => {
    this.handleCheckWhetherToLeaveGeneratorDialogCallBack({actionType: 'changeTab', newTabValue: newValue});
    /*
    this.setState({
      generatorTabValue: newValue,
      generatorTabIndex: newValue,
    });
    */

  }

  handleApplyGeneratorTabChange = newValue => {
    this.setState({
      generatorTabValue: newValue,
      generatorTabIndex: newValue,
      tmpGeneratorTabValue: -1,
    });
  }

  handleChangeGeneratorTabIndex = tabIndex => {
    this.setState({
      generatorTabIndex: tabIndex,
      areGeneratorTabsDisabled: tabIndex === 2 ? true : false
    });
  };

  handleChangeGeneratorTabIndexAndValue = props => {
    this.setState({
      generatorTabValue: props.tabValue,
      generatorTabIndex: props.tabIndex,
      areGeneratorTabsDisabled: props.tabIndex === 2 ? true : false
    });
  };

  generatorGoBackTo = props => {
    this.setState({
      generatorTabIndex: props.tabIndex,
      areGeneratorTabsDisabled: props.tabIndex === 2 ? true : false
    });
  }

  // props: {declaration*: <Object>,
  //         generatorType: 'instance | label',
  //         type: 'mapping | link | intermediate | constExpr',
  //         generatorProps: {mappingId: mappingId, linkId*: linkId, intermediateId*: intermediateId*, constExprId*: constExprId}
  // * Optional (declaration only exist in case of label and
  //             linkId, intermediateId, constExpr only exist in the respective cases)
  handleGoToGeneratorDeclarationForm = props => () => {
    if(props.type === 'mapping') {
      this.handleMappingUseGeneratorCallBack(props.generatorProps);
    }
    else if(props.type === 'link') {
      this.handleLinkUseGeneratorCallBack(props.generatorProps);
    }
    else if(props.type === 'intermediate') {
      this.handleTargetIntermediateUseGeneratorCallBack(props.generatorProps);
    }
    else if(props.type === 'constExpr') {
      this.handleConstExprUseGeneratorCallBack(props.generatorProps);
    }
    if(props.generatorType === 'label') {
      let expandedDeclaration = {... props.declaration}; // Copy
      // Check if the language field is present and if not, then add it with empty values in the declaration
      if(props.declaration.args.find(arg => arg.name === 'language') === undefined) {
        const emptyLanguageArg = {
          name: 'language',
          type: '',
          value: ''
        }
        let expandedArgs = [... expandedDeclaration.args]; // Copy
        expandedArgs.push(emptyLanguageArg);
        expandedDeclaration.args = expandedArgs;
      }

      // Adding the language argument
      this.setState({
          currLabelGeneratorDeclaration: expandedDeclaration, // remains untouched
      }, function() {
        this.setPredefinedGeneratorDefinion({type: 'label', capType: 'Label'});
        this.handleChangeGeneratorTabIndexAndValue({tabValue: 1, tabIndex: 2});
      });
    }
  }

  // Go to "Fix Missing Generator Definition Error" View
  //props: {generatorDeclaration: <Object>}
  handleToggleFixMissingDefinitionErrorView = props => () => {
    this.setState({
      showFixMissingDefinitionErrorView: props.show,
    });
  }

  handleX3mlImportFileProcessingError = (error) => {
    return 'Error occured while importing X3ML file - ' + error.body;
  }

  handleAfterX3mlImportFileUpload = async (error, file) => {
    this.handleAfterX3mlImportFileUploadCallBack({error: error, file: file});
  }

  handleAfterX3mlImportFileUploadCallBack = async props => {
    let error = props.error;
    let file = props.file;
    // No error
    if(error === null) {
      let importRes = await mappingTableViewService.convertX3mlFileToJson({
        relativePath: file.serverId,
        mappingProjectId: this.state.currMappingProject.id,
      });
      if(importRes.data.succeed) {
        const x3mlJson = importRes.data.x3mlJson;
        let responseMsg = importRes.data.msg;

        this.setState({
          mappings: x3mlJson.mappings,
          mappingIds: x3mlJson.mappingIds,
          currMappingProject: {
            ...this.state.currMappingProject,
            comments: x3mlJson.comments,
          },
        }, async () => {
          // Update the GeneratorDefinitions of the current MappingProject & Persist MappingProject & Tree model to the database
          await this.handleUpdateAndSaveGeneratorDefinitions({namespaces: x3mlJson.namespaces});
          let resMappingProject = await mainService.retrieveMappingProjectById({id: this.state.currMappingProject.id});
          if(resMappingProject.data !== false) {
            this.setState({
                currMappingProject: resMappingProject.data,
            }, async () => {
              await this.retrieveFileMetadataListByMappingProjectId({id: this.state.currMappingProject.id});
              // Syncing to others
              let newProps = {
                user: this.props.user,
              };
              this.sendJsonObjectToSpecificUsers({
                actionName: 'handleApplyWholeX3MLChangesCallBack',
                argument: newProps
              });
            });
          }
          this.handleCloseX3mlImportDialog();
          this.showSuccessSnackBar({msg: responseMsg});
        });

      }
      else {
        this.props.showErrorSnackBar({msg: importRes.data.msg, iconSize: 'large'});
      }
    }

  };

  // Sets the namespace URI of the generator definitions loaded into the system if not set already
  // props: {namespaces: <ArrayOfObjects>}
  handleUpdateAndSaveGeneratorDefinitions = async (props) => {
    // Iterating list of generatorDefinitions from state inside currMappingProject
    //let generatorDefinitionsArray = this.state.currMappingProject.generatorDefinitions.slice(); // Create a copy
    let generatorDefinitionsArray = [...this.state.currMappingProject.generatorDefinitions]; // Create a copy
    let index = 0;
    for (let generatorDefinitionTmp of this.state.currMappingProject.generatorDefinitions) {
      let generatorDefinition = Object.assign({}, generatorDefinitionTmp);
      // Searching the list of namespaces for a namespace of certain prefix
      let namespace = props.namespaces.find(
        item => item.prefix === generatorDefinition.prefix
      );
      if(namespace !== undefined) {
        generatorDefinition.namespace = namespace.uri;
        generatorDefinitionsArray[index] = generatorDefinition;
      }
      index = index + 1;
    }
    console.log('generatorDefinitionsArray');
    console.log(generatorDefinitionsArray);

    // Updating the state
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        generatorDefinitions: generatorDefinitionsArray,
      },
    }, async () => {
      // Persist MappingProject to the database
      await mainService.saveMappingProject(this.state.currMappingProject);
      // Persist TreeModel to the database
      await this.saveMappingTreeModel();
      // Gather all namespaces together
      //this.gatheringPrefixNamespacePairs();
      this.showSuccessSnackBar({msg: 'The generator "definitions" were updated'});

    });
  }

  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem while parsing x3ml input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

  handleX3mlFileValidation = async (file, type) => {
    // Do custom type detection here and return with promise
    // Reading the file

    const x3mlFileContent = await this.readUploadedFileAsText(file);
    //console.log(x3mlFileContent);
    //const x3mlElement = await this.searchForElementInX3mlString(x3mlFileContent, 'x3ml');

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(x3mlFileContent, 'text/xml');
    var isOfX3mlType = xmlDoc.getElementsByTagName("x3ml").length > 0;

    if(isOfX3mlType) {
      return new Promise((resolve, reject) => {
          resolve('text/xml');
      });
    }
    else {
      return new Promise((resolve, reject) => {
          reject();
      });
    }

  }

  handleOpenFileMetadataDialogAndCloseX3mlImportDialog = () => {
    this.setState({
      x3mlImportDialogOpen: false,
      configDialogOpen: true,
      fileMetadataInputChangeMap: new Map(),
    }, () => {
      // Holding copy of the filemetadata list before appling any change on them
      let oldFileMetadataObjectList = {... this.state.fileMetadataObjectList}; // Copy
      this.setState({
        oldFileMetadataObjectList: oldFileMetadataObjectList,
      });
    });
  }

  // Domain CodeMirror

  // props: {mappingId: <Text>, showCode: <Boolean>}
  handleToggleShowDomainCodeCallBack = async props => {
    // Holding buckup of unchanged X3ML fragment Code
    // Showing
    //if(!this.state.mappings[props.mappingId].showCode) {
    if(props.showCode) {
      let isValid = await this.validateDomain({mappingId: props.mappingId});
      if(isValid) {
        await this.handleConstructDomainX3mlByMappingJsonModel({mappingId: props.mappingId});
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              showCode: props.showCode,//!this.state.mappings[props.mappingId].showCode,
              unchangedX3ml: this.state.mappings[props.mappingId].x3ml,
            }
          }
        });
      }
      else {
        this.props.showErrorSnackBar({msg: 'There are errors in the domain and therefore the X3ML fragment code cannot be generated', iconSize: 'large'});
      }
    }
    // Hiding
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            showCode: props.showCode,
          }
        }
      });
    }
  }

  // props: {x3ml: <Text>, mappingId: <TEXT>}
  handleDomainCodeMirrorInputChange = props => {
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          x3ml: props.x3ml,
        }
      }
    });
  }

  // props: {mappingId: <TEXT>}
  importX3mlDomain = async props => {
    let domainJsonObject = props.domainJsonObject !== undefined ? props.domainJsonObject : undefined;
    let responseMsg = undefined;
    if(domainJsonObject === undefined) {
      if(this.state.mappings[props.mappingId].x3ml !== undefined) {
        // Retrieves the namespaces
        await this.gatheringPrefixNamespacePairs();
        let importRes = await mappingTableViewService.convertX3mlFragmentCodeToJson({
          x3ml: this.state.mappings[props.mappingId].x3ml,
          type: 'domain',
          objId: props.mappingId,
          mappingProjectId: this.state.currMappingProject.id,
          prefixNamespaceArray: Array.from(this.state.prefixNamespaceMap, ([name, value]) => ({ prefix: name, namespace: value })),
        });
        responseMsg = importRes.data.msg;
        if(importRes.data.succeed) {
          domainJsonObject = importRes.data.domain;
        }
        else {
          this.props.showErrorSnackBar({msg: responseMsg, iconSize: 'large'});
        }
      }
    }
    if(domainJsonObject !== undefined) {
      // Change the new Domain such that code fragment is shown
      domainJsonObject.showCode = true;
      // Set state
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            conditionListLogicalOperator: domainJsonObject.conditionListLogicalOperator,
            conditionListUseOr: domainJsonObject.conditionListUseOr,
            conditions: domainJsonObject.conditions,
            comments: domainJsonObject.comments,
            constExprIds: domainJsonObject.constExprIds,
            constExprs: domainJsonObject.constExprs,
            domainPanelExpanded: domainJsonObject.domainPanelExpanded,
            domainSourceNode: domainJsonObject.domainSourceNode,
            domainTargetEntity: domainJsonObject.domainTargetEntity,
          }
        }
      }, async function() {
        if(props.doSave !== undefined ? props.doSave : true) {
          // Produce the respective X3ML code fragment for the link
          await this.handleConstructDomainX3mlByMappingJsonModel({mappingId: props.mappingId, showMsg: false});
          // Hide the code
          this.handleToggleShowDomainCodeCallBack({mappingId: props.mappingId, showCode: !this.state.mappings[props.mappingId].showCode});
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: responseMsg});
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.domainJsonObject = domainJsonObject;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'importX3mlDomain',
            argument: newProps
          });
        }
      });
    }
  }

  // props: {mappingId: <TEXT>}
  handleConstructDomainX3mlByMappingJsonModel = async props => {
    try {
      let response = await mappingTableViewService.constructDomainX3mlByMappingJsonModel(this.state.mappings[props.mappingId]);
      if(response.data.succeed) {
        // Formatting
        var format = require('xml-formatter');
        var xml = response.data.x3ml;
        var options = {indentation: '  ', stripComments: true, collapseContent: true};
        var formattedXml = format(xml, options);
        // Persisting in state
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              x3ml: formattedXml,
            }
          }
        }, function() {
          if(props.showMsg === undefined) {
            this.showSuccessSnackBar({msg: response.data.msg});
          }
          else if(props.showMsg === true) {
            this.showSuccessSnackBar({msg: response.data.msg});
          }
        });
      }
      else {
        this.props.showErrorSnackBar({msg: response.data.msg, iconSize: 'large'});
      }
    } catch (e) {
      console.error('Some error occured while producing the X3ML fragment code!');
      if(e.response !== undefined) {
        console.error(e.response.status);
        return e.response;
      }
    }
  }

  // Checks whether the X3ML of the domain is changed after been shown
  //props: {mappingId: <Text>}
  isDomainCodeMirrorChanged = props => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.mappings[props.mappingId].x3ml === this.state.mappings[props.mappingId].unchangedX3ml) {
      return false;
    }
    else {
      return true;
    }
  }

  // Link CodeMirror

  // props: {mappingId: <TEXT>, linkId: <TEXT>, showCode: <Boolean>}
  handleToggleShowLinkCodeCallBack = async props => {
    if(props.showCode) {
      let isValid = await this.validateLink({mappingId: props.mappingId, linkId: props.linkId});
      if(isValid) {
        await this.handleConstructX3mlByLinkJsonModel({mappingId: props.mappingId, linkId: props.linkId});
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  showCode: props.showCode,//!this.state.mappings[props.mappingId].links[props.linkId].showCode,
                  unchangedX3ml: this.state.mappings[props.mappingId].links[props.linkId].x3ml,
                }
              }
            }
          }
        });
      }
      else {
        this.props.showErrorSnackBar({msg: 'There are errors in the link and therefore the X3ML fragment code cannot be generated', iconSize: 'large'});
      }
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                showCode: props.showCode,
              }
            }
          }
        }
      });
    }
  }

  // props: {x3ml: <Text>, mappingId: <TEXT>, linkId: <TEXT>}
  handleLinkCodeMirrorInputChange = props => {
    //this.setState({linkCodeMirrorValue: props.value});
    //console.log('value:' + props.value);
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: {
              ...this.state.mappings[props.mappingId].links[props.linkId],
              x3ml: props.x3ml,
            }
          }
        }
      }
    });
  }

  // props: {x3ml: <Text>, mappingId: <TEXT>, linkId: <TEXT>}
  importX3mlLink = async props => {
    let linkJsonObject = props.linkJsonObject !== undefined ? props.linkJsonObject : undefined;
    let responseMsg = undefined;

    if(linkJsonObject === undefined) {
      let isValid = true;
      if(isValid) {
        if(this.state.mappings[props.mappingId].links[props.linkId].x3ml !== undefined) {
          // Retrieves the namespaces from the source and target files (without it, the target relation is not properly imported)
          await this.gatheringPrefixNamespacePairs();
          let importRes = await mappingTableViewService.convertX3mlFragmentCodeToJson({
            x3ml: this.state.mappings[props.mappingId].links[props.linkId].x3ml,
            type: 'link',
            objId: props.linkId,
            mappingProjectId: this.state.currMappingProject.id,
            prefixNamespaceArray: Array.from(this.state.prefixNamespaceMap, ([name, value]) => ({ prefix: name, namespace: value })),
          });
          responseMsg = importRes.data.msg;
          if(importRes.data.succeed) {
            linkJsonObject = importRes.data.link;
          }
          else {
            this.props.showErrorSnackBar({msg: responseMsg, iconSize: 'large'});
          }
        }
      }
    }
    if(linkJsonObject !== undefined) {
      // Change the new link such that code fragment is shown
      linkJsonObject.showCode = true;
      // Set state
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: linkJsonObject,
            }
          }
        }
      }, async function() {
        if(props.doSave !== undefined ? props.doSave : true) {
          // Produce the respective X3ML code fragment for the link
          await this.handleConstructX3mlByLinkJsonModel({mappingId: props.mappingId, linkId: props.linkId, showMsg: false});
          // Hide the code
          this.handleToggleShowLinkCodeCallBack({mappingId: props.mappingId, linkId: props.linkId});
          // Persist to the database
          await this.saveMappingTreeModel();
          this.showSuccessSnackBar({msg: responseMsg});
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.linkJsonObject = linkJsonObject;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'importX3mlLink',
            argument: newProps
          });
        }


      });
    }
    // else {
    //   this.props.showErrorSnackBar({msg: 'There are errors in the project and therefore the X3ML fragment code cannot be generated', iconSize: 'large'});
    // }
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>}
  handleConstructX3mlByLinkJsonModel = async props => {
    try {
      //let response = await mappingTableViewService.convertAndsaveX3ml(model);
      let response = await mappingTableViewService.constructX3mlByLinkJsonModel(this.state.mappings[props.mappingId].links[props.linkId]);
      if(response.data.succeed) {
        // Formatting
        var format = require('xml-formatter');
        var xml = response.data.x3ml;
        var options = {indentation: '  ', stripComments: true, collapseContent: true};
        var formattedXml = format(xml, options);
        // Persisting in state
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  x3ml: formattedXml,
                }
              }
            }
          }
        }, function() {
          if(props.showMsg === undefined) {
            this.showSuccessSnackBar({msg: response.data.msg});
          }
          else if(props.showMsg === true) {
            this.showSuccessSnackBar({msg: response.data.msg});
          }
        });
      }
      else {
        this.props.showErrorSnackBar({msg: response.data.msg, iconSize: 'large'});
      }
    } catch (e) {
      console.error('Some error occured while producing the X3ML fragment code!');
      this.props.showErrorSnackBar({msg: 'Some error occured while producing the X3ML fragment code!', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
        return e.response;
      }
    }
  }

  // Show snackbar for confirming that changes on the fragment code for the link will be applied
  handleShowConfirmationForApplyingLinkFragmentCodeChanges  = props => {
    this.setState({
      confirmImportLinkFragmentCodeSnackbarShown: true
    });
  }

  // Close Confirmation snackbar for Link
  handleCloseImportLinkFragmentCodeSnackbar  = () => {
    this.setState({
      confirmImportLinkFragmentCodeSnackbarShown : false,
    });
  }

  // Checks whether the X3ML of the link is changed after been shown
  //props: {mappingId: <Text>}
  isLinkCodeMirrorChanged = props => {
    // Check if there are any unsaved changes and show the confirmation Snackbar
    if(this.state.mappings[props.mappingId].links[props.linkId].x3ml === this.state.mappings[props.mappingId].links[props.linkId].unchangedX3ml) {
      return false;
    }
    else {
      return true;
    }
  }

  validateWholeMappingProject = () => {
    let isValid = true;
    var firstErrorId = -1;
    var eroneousComponentType = '';
    var mappings = Object.assign({}, this.state.mappings);

    this.state.mappingIds
      // Only take into account those that are actually in use
      .filter(mappingId => mappings[mappingId].isInUse !== undefined ? mappings[mappingId].isInUse : true) 
      .forEach(mappingId => {

    // this.state.mappingIds.forEach(mappingId => {
      // Validation Code
      if(mappings[mappingId].domainSourceNode.item === '' || mappings[mappingId].domainTargetEntity.item === '') {
        isValid = false;

        if(mappings[mappingId].domainSourceNode.item === '') {
          mappings = update(mappings, {
            [mappingId]: {
              domainSourceNode: {
                error_text: {
                  $set: 'This field is required'
                }
              }
            }
          });
          if(firstErrorId === -1) {
            firstErrorId = mappingId;
            eroneousComponentType = 'mapping';
          }
        }
        if(mappings[mappingId].domainTargetEntity.item === '') {
          mappings = update(mappings, {
            [mappingId]: {
              domainTargetEntity: {
                error_text: {
                  $set: 'This field is required'
                }
              }
            }
          });
          if(firstErrorId === -1) {
            firstErrorId = mappingId;
            eroneousComponentType = 'mapping';
          }
        }
      }

      // Mapping - Additionals
      mappings[mappingId].constExprIds.forEach(constExprId => {
        // Validation Code
        if(mappings[mappingId].constExprs[constExprId].entity.item === '' || mappings[mappingId].constExprs[constExprId].relation.item === '') {
          isValid = false;
          if(mappings[mappingId].constExprs[constExprId].entity.item === '') {
            mappings = update(mappings, {
              [mappingId]: {
                constExprs: {
                  [constExprId]: {
                    entity: {
                      error_text: {
                        $set: 'This field is required'
                      }
                    }
                  }
                }
              }
            });
            if(firstErrorId === -1) {
              firstErrorId = mappingId;
              eroneousComponentType = 'mapping';
            }
          }
          if(mappings[mappingId].constExprs[constExprId].relation.item === '') {
            mappings = update(mappings, {
              [mappingId]: {
                constExprs: {
                  [constExprId]: {
                    relation: {
                      error_text: {
                        $set: 'This field is required'
                      }
                    }
                  }
                }
              }
            });
            if(firstErrorId === -1) {
              firstErrorId = mappingId;
              eroneousComponentType = 'mapping';
            }
          }
        }
      });

      // Mapping - Links
      mappings[mappingId].linkIds
        // Only take into account those that are actually in use
        .filter(linkId => mappings[mappingId].links[linkId].isInUse !== undefined ? mappings[mappingId].links[linkId].isInUse : true) 
        .forEach(linkId => {

        // Validation Code
        if(mappings[mappingId].links[linkId].sourceRelation.item === '' ||
          mappings[mappingId].links[linkId].sourceNode.item === '' ||
          mappings[mappingId].links[linkId].targetRelation.item === '' ||
          mappings[mappingId].links[linkId].targetEntity.item === '') {
          isValid = false;
          if(mappings[mappingId].links[linkId].sourceRelation.item === '') {
            mappings = update(mappings, {
              [mappingId]: {
                links: {
                  [linkId]: {
                    sourceRelation: {
                      error_text: {
                        $set: 'This field is required'
                      }
                    }
                  }
                }
              }
            });
            if(firstErrorId === -1) {
              firstErrorId = linkId;
              eroneousComponentType = 'link';
            }

          }
          if(mappings[mappingId].links[linkId].sourceNode.item === '') {
            mappings = update(mappings, {
              [mappingId]: {
                links: {
                  [linkId]: {
                    sourceNode: {
                      error_text: {
                        $set: 'This field is required'
                      }
                    }
                  }
                }
              }
            });
            if(firstErrorId === -1) {
              firstErrorId = linkId;
              eroneousComponentType = 'link';
            }
          }
          if(mappings[mappingId].links[linkId].targetRelation.item === '') {
            mappings = update(mappings, {
              [mappingId]: {
                links: {
                  [linkId]: {
                    targetRelation: {
                      error_text: {
                        $set: 'This field is required'
                      }
                    }
                  }
                }
              }
            });
            if(firstErrorId === -1) {
              firstErrorId = linkId;
              eroneousComponentType = 'link';
            }
          }
          if(mappings[mappingId].links[linkId].targetEntity.item === '') {
            mappings = update(mappings, {
              [mappingId]: {
                links: {
                  [linkId]: {
                    targetEntity: {
                      error_text: {
                        $set: 'This field is required'
                      }
                    }
                  }
                }
              }
            });
            if(firstErrorId === -1) {
              firstErrorId = linkId;
              eroneousComponentType = 'link';
            }
          }
        }

        // Link - Additionals
        mappings[mappingId].links[linkId].constExprIds.forEach(constExprId => {
          // Validation Code
          if(mappings[mappingId].links[linkId].constExprs[constExprId].entity.item === '' ||
            mappings[mappingId].links[linkId].constExprs[constExprId].relation.item === '') {
            isValid = false;

            if(mappings[mappingId].links[linkId].constExprs[constExprId].entity.item === '') {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      constExprs: {
                        [constExprId]: {
                          entity: {
                            error_text: {
                              $set: 'This field is required'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
              if(firstErrorId === -1) {
                firstErrorId = linkId;
                eroneousComponentType = 'link';
              }
            }
            if(mappings[mappingId].links[linkId].constExprs[constExprId].relation.item === '') {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      constExprs: {
                        [constExprId]: {
                          relation: {
                            error_text: {
                              $set: 'This field is required'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
              if(firstErrorId === -1) {
                firstErrorId = linkId;
                eroneousComponentType = 'link';
              }
            }
          }
        });

        // Link - sourceIntermediates
        mappings[mappingId].links[linkId].sourceIntermediateIds.forEach(sourceIntermediateId => {
          // Validation Code
          if(mappings[mappingId].links[linkId].sourceIntermediates[sourceIntermediateId].sourceNode.item === '' ||
            mappings[mappingId].links[linkId].sourceIntermediates[sourceIntermediateId].sourceRelation.item === '') {
            isValid = false;

            if(mappings[mappingId].links[linkId].sourceIntermediates[sourceIntermediateId].sourceNode.item === '') {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      sourceIntermediates: {
                        [sourceIntermediateId]: {
                          sourceNode: {
                            error_text: {
                              $set: 'This field is required'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
              if(firstErrorId === -1) {
                firstErrorId = linkId;
                eroneousComponentType = 'link';
              }
            }
            if(mappings[mappingId].links[linkId].sourceIntermediates[sourceIntermediateId].sourceRelation.item === '') {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      sourceIntermediates: {
                        [sourceIntermediateId]: {
                          sourceRelation: {
                            error_text: {
                              $set: 'This field is required'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
              if(firstErrorId === -1) {
                firstErrorId = linkId;
                eroneousComponentType = 'link';
              }
            }
          }
        });

        // Link - TargetIntermediates
        mappings[mappingId].links[linkId].targetIntermediateIds.forEach(targetIntermediateId => {
          // Validation Code
          if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetEntity.item === '' ||
            mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetRelation.item === '') {
            isValid = false;

            if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetEntity.item === '') {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      targetIntermediates: {
                        [targetIntermediateId]: {
                          targetEntity: {
                            error_text: {
                              $set: 'This field is required'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
              if(firstErrorId === -1) {
                firstErrorId = linkId;
                eroneousComponentType = 'link';
              }
            }
            if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetRelation.item === '') {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      targetIntermediates: {
                        [targetIntermediateId]: {
                          targetRelation: {
                            error_text: {
                              $set: 'This field is required'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
              if(firstErrorId === -1) {
                firstErrorId = linkId;
                eroneousComponentType = 'link';
              }
            }
          }

          // Link - targetIntermediates - Additionals
          mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprIds.forEach(constExprId => {
            // Validation Code
            if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].entity.item === '' ||
              mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].relation.item === '') {
              isValid = false;
              if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].entity.item === '') {
                mappings = update(mappings, {
                  [mappingId]: {
                    links: {
                      [linkId]: {
                        targetIntermediates: {
                          [targetIntermediateId]: {
                            constExprs: {
                              [constExprId]: {
                                entity: {
                                  error_text: {
                                    $set: 'This field is required'
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                });
                if(firstErrorId === -1) {
                  firstErrorId = linkId;
                  eroneousComponentType = 'link';
                }
              }
              if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].relation.item === '') {
                mappings = update(mappings, {
                  [mappingId]: {
                    links: {
                      [linkId]: {
                        targetIntermediates: {
                          [targetIntermediateId]: {
                            constExprs: {
                              [constExprId]: {
                                relation: {
                                  error_text: {
                                    $set: 'This field is required'
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                });
                if(firstErrorId === -1) {
                  firstErrorId = linkId;
                  eroneousComponentType = 'link';
                }
              }
            }
          });
        });
      });
    });

    this.setState({
      mappings: mappings
    }, () => {
      console.log(this.state.mappings);
    });

    return {isValid: isValid, firstErrorId: firstErrorId, eroneousComponentType: eroneousComponentType};
  }







  // props: {
  //    oldPrefix: <Text>,      The old prefix to be changed in the mappingTree
  //    oldNamespace: <Text>,   The old namespace to be changed in the mappingTree
  //    newPrefix: <Text>,      The new prefix to replace the old one in the mappingTree
  //    newNamespace: <Text>,   The new namespace to replace the old one in the mappingTree
  // }
  handlePrefixAndNamespaceChangedOnSelectionsInMappingProjectCallBack = props => {
    // this.props.showErrorSnackBar({
    //   msg: 'PLEASE NOTE: I only apply changes on the prefix, not the namespace in the selected items. Thus the full property of the item is the old one. Also consider applying some better checking before changing. What will happen if many changes are applied at once?. Finally I don\'t SYNC that action!!!!!'
    // });
    const oldPrefix = props.oldPrefix;
    const oldNamespace = props.oldNamespace;
    const newPrefix = props.newPrefix;
    const newNamespace = props.newNamespace;

    var mappings = Object.assign({}, this.state.mappings);
    this.state.mappingIds.forEach(mappingId => {

      // mappings[mappingId].domainTargetEntity.item (item is an array of objects)
      if(mappings[mappingId].domainTargetEntity.item !== '') {
        var newItemArray = [];
        let hasChanged = false;
        for (let item of mappings[mappingId].domainTargetEntity.item) {
          let newItem = Object.assign({}, item); // Copy
          if(item.value.startsWith(oldPrefix + ':')) {
            newItem.value = newItem.value.replace(oldPrefix + ':', newPrefix + ':');
            hasChanged = true;
          }
          newItemArray.push(newItem);
        }
        if(hasChanged) {
          mappings = update(mappings, {
            [mappingId]: {
              domainTargetEntity: {
                item: {
                  $set: newItemArray
                }
              }
            }
          });
        }
      }

      // Mapping - Additionals
      mappings[mappingId].constExprIds.forEach(constExprId => {
        // mappings[mappingId].constExprs[constExprId].entity.item (item is an array of objects)
        if(mappings[mappingId].constExprs[constExprId].entity.item !== '') {
          var newItemArray = [];
          let hasChanged = false;
          for (let item of mappings[mappingId].constExprs[constExprId].entity.item) {
            let newItem = Object.assign({}, item); // Copy
            if(item.value.startsWith(oldPrefix + ':')) {
              newItem.value = newItem.value.replace(oldPrefix + ':', newPrefix + ':');
              hasChanged = true;
            }
            newItemArray.push(newItem);
          }
          if(hasChanged) {
            mappings = update(mappings, {
              [mappingId]: {
                constExprs: {
                  [constExprId]: {
                    entity: {
                      item: {
                        $set: newItemArray
                      }
                    }
                  }
                }
              }
            });
          }
        }
        if(mappings[mappingId].constExprs[constExprId].relation.item !== '') {
          // mappings[mappingId].constExprs[constExprId].relation.item (item is an object)
          let item = Object.assign({}, mappings[mappingId].constExprs[constExprId].relation.item); // Copy
          let hasChanged = false;
          if(item.value.startsWith(oldPrefix + ':')) {
            item.value = item.value.replace(oldPrefix + ':', newPrefix + ':')
            hasChanged = true;
          }
          if(hasChanged) {
            mappings = update(mappings, {
              [mappingId]: {
                constExprs: {
                  [constExprId]: {
                    relation: {
                      item: {
                        $set: item
                      }
                    }
                  }
                }
              }
            });
          }
        }
      }); // forEach ends

      // Mapping - Links
      mappings[mappingId].linkIds.forEach(linkId => {

        if(mappings[mappingId].links[linkId].targetRelation.item !== '') {
          let item = Object.assign({}, mappings[mappingId].links[linkId].targetRelation.item); // Copy
          let hasChanged = false;
          if(item.value.startsWith(oldPrefix + ':')) {
            item.value = item.value.replace(oldPrefix + ':', newPrefix + ':')
            hasChanged = true;
          }
          if(hasChanged) {
            mappings = update(mappings, {
              [mappingId]: {
                links: {
                  [linkId]: {
                    targetRelation: {
                      item: {
                        $set: item
                      }
                    }
                  }
                }
              }
            });
          }
        }

        if(mappings[mappingId].links[linkId].targetEntity.item !== '') {
          var newItemArray = [];
          let hasChanged = false;
          for (let item of mappings[mappingId].links[linkId].targetEntity.item) {
            let newItem = Object.assign({}, item); // Copy
            if(item.value.startsWith(oldPrefix + ':')) {
              newItem.value = newItem.value.replace(oldPrefix + ':', newPrefix + ':');
              hasChanged = true;
            }
            newItemArray.push(newItem);
          }
          if(hasChanged) {
            mappings = update(mappings, {
              [mappingId]: {
                links: {
                  [linkId]: {
                    targetEntity: {
                      item: {
                        $set: newItemArray
                      }
                    }
                  }
                }
              }
            });
          }
        }

        // Link - Additionals
        mappings[mappingId].links[linkId].constExprIds.forEach(constExprId => {
          if(mappings[mappingId].links[linkId].constExprs[constExprId].entity.item !== '') {
            var newItemArray = [];
            let hasChanged = false;
            for (let item of mappings[mappingId].links[linkId].constExprs[constExprId].entity.item) {
              let newItem = Object.assign({}, item); // Copy
              if(item.value.startsWith(oldPrefix + ':')) {
                newItem.value = newItem.value.replace(oldPrefix + ':', newPrefix + ':');
                hasChanged = true;
              }
              newItemArray.push(newItem);
            }
            if(hasChanged) {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      constExprs: {
                        [constExprId]: {
                          entity: {
                            item: {
                              $set: newItemArray
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }
          if(mappings[mappingId].links[linkId].constExprs[constExprId].relation.item !== '') {
            let item = Object.assign({}, mappings[mappingId].links[linkId].constExprs[constExprId].relation.item); // Copy
            let hasChanged = false;
            if(item.value.startsWith(oldPrefix + ':')) {
              item.value = item.value.replace(oldPrefix + ':', newPrefix + ':')
              hasChanged = true;
            }
            if(hasChanged) {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      constExprs: {
                        [constExprId]: {
                          relation: {
                            item: {
                              $set: item
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }
        });

        // Link - TargetIntermediates
        mappings[mappingId].links[linkId].targetIntermediateIds.forEach(targetIntermediateId => {
          if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetEntity.item !== '') {
            var newItemArray = [];
            let hasChanged = false;
            for (let item of mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetEntity.item) {
              let newItem = Object.assign({}, item); // Copy
              if(item.value.startsWith(oldPrefix + ':')) {
                newItem.value = newItem.value.replace(oldPrefix + ':', newPrefix + ':');
                hasChanged = true;
              }
              newItemArray.push(newItem);
            }
            if(hasChanged) {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      targetIntermediates: {
                        [targetIntermediateId]: {
                          targetEntity: {
                            item: {
                              $set: newItemArray
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }
          if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetRelation.item !== '') {
            let item = Object.assign({}, mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].targetRelation.item); // Copy
            let hasChanged = false;
            if(item.value.startsWith(oldPrefix + ':')) {
              item.value = item.value.replace(oldPrefix + ':', newPrefix + ':')
              hasChanged = true;
            }
            if(hasChanged) {
              mappings = update(mappings, {
                [mappingId]: {
                  links: {
                    [linkId]: {
                      targetIntermediates: {
                        [targetIntermediateId]: {
                          targetRelation: {
                            item: {
                              $set: item
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }

          // Link - targetIntermediates - Additionals
          mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprIds.forEach(constExprId => {
            if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].entity.item === '') {
              var newItemArray = [];
              let hasChanged = false;
              for (let item of mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].entity.item) {
                let newItem = Object.assign({}, item); // Copy
                if(item.value.startsWith(oldPrefix + ':')) {
                  newItem.value = newItem.value.replace(oldPrefix + ':', newPrefix + ':');
                  hasChanged = true;
                }
                newItemArray.push(newItem);
              }
              if(hasChanged) {
                mappings = update(mappings, {
                  [mappingId]: {
                    links: {
                      [linkId]: {
                        targetIntermediates: {
                          [targetIntermediateId]: {
                            constExprs: {
                              [constExprId]: {
                                entity: {
                                  item: {
                                    $set: newItemArray
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
            if(mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].relation.item !== '') {
              let item = Object.assign({}, mappings[mappingId].links[linkId].targetIntermediates[targetIntermediateId].constExprs[constExprId].relation.item); // Copy
              let hasChanged = false;
              if(item.value.startsWith(oldPrefix + ':')) {
                item.value = item.value.replace(oldPrefix + ':', newPrefix + ':')
                hasChanged = true;
              }
              if(hasChanged) {
                mappings = update(mappings, {
                  [mappingId]: {
                    links: {
                      [linkId]: {
                        targetIntermediates: {
                          [targetIntermediateId]: {
                            constExprs: {
                              [constExprId]: {
                                relation: {
                                  item: {
                                    $set: item
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          });
        });
      });

    });

    this.setState({
      mappings: mappings
    }, async () => {
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await this.saveMappingTreeModel();

        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handlePrefixAndNamespaceChangedOnSelectionsInMappingProjectCallBack',
          argument: newProps
        });
      }
    });
  }

  // Toggling the status of this Mapping Project between "In Progress" and "Completed"
  handleToggleStatus = () => {
    this.handleToggleStatusCallBack({})
  }
  // props: {doSave*: <Boolean>}
  // * Optional argument, meaning that props could be empty
  handleToggleStatusCallBack = props => {
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        status: this.state.currMappingProject.status.toLowerCase() === 'In Progress'.toLowerCase() ? 'Completed' : 'In Progress',
      }
    }, async () => {
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await mainService.saveMappingProject(this.state.currMappingProject);
        this.determineWhetherCurrUserCanEdit();
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleToggleStatusCallBack',
          argument: newProps,
        });
      }
    });
  }

  // Toggling Compact View
  handleToggleCompactView = () => {
    this.setState({
      compactViewEnabled: !this.state.compactViewEnabled,
    });
  }

  // Validating Selections

  handleToggleValidateSelections = () => {
    this.setState({
      validateSelections: !this.state.validateSelections,
    });
  }

  stopValidatingSelectedValues = () => {
    this.setState({
      validatingSelectedValuesNow: false,
    });
  }

  toggleSelectedValuesValidationBackDdrop = (props) => {
    this.props.toggleRootBackDdropCallBack({
      booleanValue: true,
      message: <span>Validating the selected options. <br/> Be patient please, as this could take a while ...</span>,
      actionHtml:
        <Button
          color="primary"
          size="small"
          onClick={this.stopValidatingSelectedValues}
          style={{marginTop: 10}}
        >
          Cancel
        </Button>
    });
  }

  // Testing selected values validation
  handleValidateSelectedValuesOfEverything = async () => {
    await this.handleValidateSelectedValuesOfEverythingCallBack({});
  }

  handleValidateSelectedValuesOfEverythingCallBack = async (props) => {
    this.setState({
      validatingSelectedValuesNow: true,
    }, async () => {
      this.toggleSelectedValuesValidationBackDdrop({
        booleanValue: true,
        message: <span>Validating the selected options. <br/> Be patient please, as this could take a while ...</span>
      });

      //this.state.mappingIds.forEach(mappingId => { // Are performed in parallel
      for (const mappingId of this.state.mappingIds) { // Are performed in sequence

        if(this.state.validatingSelectedValuesNow) {
          // Source
          await this.handleValidateSelectedValuesOfMappingDomainSourceNodeCallBack({
            mappingId: mappingId,
          });

          // Target
          //this.state.mappings[mappingId].linkIds.forEach(async (linkId) => { // Are performed in parallel
          await this.handleValidateSelectedValuesOfMappingDomainTargetEntityCallBack({
            mappingId: mappingId,
          });
          // Mapping - Additionals
          for (const constExprId of this.state.mappings[mappingId].constExprIds) { // Are performed in sequence
            if(this.state.validatingSelectedValuesNow) {
              await this.handleValidateSelectedValuesOfAdditionalRelationCallBack({
                mappingId: mappingId,
                constExprId: constExprId,
                applyFullTreeValidation: true,
              });
              await this.handleValidateSelectedValuesOfAdditionalEntityCallBack({
                mappingId: mappingId,
                constExprId: constExprId,
                applyFullTreeValidation: true,
              });
            }
            else {
              break;
            }
          }
          for (const linkId of this.state.mappings[mappingId].linkIds) { // Are performed in sequence
            if(this.state.validatingSelectedValuesNow) {
              // Source
              await this.handleValidateSelectedValuesOfLinkSourceFieldCallBack({
                mappingId: mappingId,
                linkId: linkId,
                fieldType: 'sourceRelation'
              });
              await this.handleValidateSelectedValuesOfLinkSourceFieldCallBack({
                mappingId: mappingId,
                linkId: linkId,
                fieldType: 'sourceNode'
              });

              // Source Intermediates
              for (const intermediateId of this.state.mappings[mappingId].links[linkId].sourceIntermediateIds) { // Are performed in sequence
              //this.state.mappings[mappingId].links[linkId].sourceIntermediateIds.forEach(async (intermediateId) => { // Are performed in parallel
                if(this.state.validatingSelectedValuesNow) {
                  await this.handleValidateSelectedValuesOfIntermediateSourceFieldCallBack({
                    mappingId: mappingId,
                    linkId: linkId,
                    intermediateId: intermediateId,
                    fieldType: 'sourceNode'
                  });
                  await this.handleValidateSelectedValuesOfIntermediateSourceFieldCallBack({
                    mappingId: mappingId,
                    linkId: linkId,
                    intermediateId: intermediateId,
                    fieldType: 'sourceRelation'
                  });
                //});
                }
                else {
                  break;
                }
              }

              // Target
              await this.handleValidateSelectedValuesOfLinkTargetRelationCallBack({
                mappingId: mappingId,
                linkId: linkId,
              });
              await this.handleValidateSelectedValuesOfLinkTargetEntityCallBack({
                mappingId: mappingId,
                linkId: linkId,
              });
              // Link - Additionals
              for (const constExprId of this.state.mappings[mappingId].links[linkId].constExprIds) { // Are performed in sequence
                if(this.state.validatingSelectedValuesNow) {
                  await this.handleValidateSelectedValuesOfAdditionalRelationCallBack({
                    mappingId: mappingId,
                    linkId: linkId,
                    constExprId: constExprId,
                    applyFullTreeValidation: true,
                  });
                  await this.handleValidateSelectedValuesOfAdditionalEntityCallBack({
                    mappingId: mappingId,
                    linkId: linkId,
                    constExprId: constExprId,
                    applyFullTreeValidation: true,
                  });
                }
                else {
                  break;
                }
              }
              // Target Intermediates
              for (const intermediateId of this.state.mappings[mappingId].links[linkId].targetIntermediateIds) { // Are performed in sequence
              //this.state.mappings[mappingId].links[linkId].targetIntermediateIds.forEach(async (intermediateId) => { // Are performed in parallel
                if(this.state.validatingSelectedValuesNow) {
                  await this.handleValidateSelectedValuesOfIntermediateTargetEntityCallBack({
                    mappingId: mappingId,
                    linkId: linkId,
                    intermediateId: intermediateId,
                  });
                  await this.handleValidateSelectedValuesOfIntermediateTargetRelationCallBack({
                    mappingId: mappingId,
                    linkId: linkId,
                    intermediateId: intermediateId,
                  });
                  // Intermediate - Additionals
                  for (const constExprId of this.state.mappings[mappingId].links[linkId].targetIntermediates[intermediateId].constExprIds) { // Are performed in sequence
                    if(this.state.validatingSelectedValuesNow) {
                      await this.handleValidateSelectedValuesOfAdditionalRelationCallBack({
                        mappingId: mappingId,
                        linkId: linkId,
                        intermediateId: intermediateId,
                        constExprId: constExprId,
                        applyFullTreeValidation: true,
                      });
                      await this.handleValidateSelectedValuesOfAdditionalEntityCallBack({
                        mappingId: mappingId,
                        linkId: linkId,
                        intermediateId: intermediateId,
                        constExprId: constExprId,
                        applyFullTreeValidation: true,
                      });
                    }
                    else {
                      break;
                    }
                  }
                }
                else {
                  break;
                }
              //});
              }
            }
            else {
              break;
            }
          }
          //});
        }
        else {
          break;
        }


      }
      this.props.toggleRootBackDdropCallBack({booleanValue: false});

    });



  //});
  }

  // Validate the selected values of the mapping
  // props: {mappingId: <Text>}
  handleValidateSelectedValuesOfMappingDomainTargetEntityCallBack = async (props) => {
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var mapping = Object.assign({}, this.state.mappings[props.mappingId]);
      errorMsg = 'This field is invalid';
      // ########################
      // Target Entity Validation
      // ########################
      // Check if empty (case Array of Items)
      if(mapping.domainTargetEntity.item.length === 0) {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        let options = [];
        // If the already retrieved "allClasses" is not an Array to be
        // transformed (below), then ???
        if(Array.isArray(this.state.allClasses)) {
          // Transforming options to the desired format
          options = [].concat.apply(
            [], this.state.allClasses.map(
              item => item.options.map(
                optItem => (
                  { ...optItem, group: item.label }
                )
              )
            )
          )
        }
        // Checking if the selected value is contained in the results
        // (case Array of Items)
        let count = 0;
        let erroneousValues = [];
        mapping.domainTargetEntity.item.forEach(item => {
          // Only validate the non-custom values
          // (as custom is considered a flag, indicating that the value has been added by the user)
          if(item.custom !== undefined ? !item.custom : true) {
            let foundIndex = options.findIndex(
              obj => obj.value === item.value || obj.full === item.full
            );
            if(foundIndex === -1) {
              isValid = false;
              erroneousValues.push(item.label);
            }
          }
        });

        if(!isValid) {
          // Constructing additional error message
          let additionalErrorMsg = erroneousValues.length > 0 ?
                                   ('. The following value' + (erroneousValues.length > 1 ? 's are' : ' is') + ' not found: ') :
                                   '';
          let errorValueCount = 0;
          for (const value of erroneousValues) {
            additionalErrorMsg = additionalErrorMsg + value + (errorValueCount !== erroneousValues.length-1 ? ', ' : '')
            errorValueCount = errorValueCount + 1;
          }
          errorMsg = 'The value of this field doesn\'t match the available options' + additionalErrorMsg;
        }
      }

      mapping = update(mapping, {
        domainTargetEntity: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: mapping
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfMappingDomainTargetEntityCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            domainTargetEntity: {
              ...this.state.mappings[props.mappingId].domainTargetEntity,
              error_text: errorMsg
            }
          }
        }
      });
    }

    return isValid;
  }

  // Validate the selected values of the link
  // props: {mappingId: <Text>, linkId: <Text>}
  handleValidateSelectedValuesOfLinkTargetRelationCallBack = async (props) => {

    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var link = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId]);
      errorMsg = 'This field is invalid';
      // ##########################
      // Target Relation Validation
      // ##########################
      // Check if empty
      if(link.targetRelation.item === '') {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        // Getting the selected items (case Array of Items)
        let selectedClasses = [];
        if(Array.isArray(this.state.mappings[props.mappingId].domainTargetEntity.item)) {
          this.state.mappings[props.mappingId].domainTargetEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
        }
        // Retrieving options
        let res = await reasonerService.getProperties({
          id: this.state.currMappingProject.id,
          type: 'target',
          request: selectedClasses
        });
        let options = [];
        // If the above service doesn't return an array, to be
        // transformed (below), then some error message has been returned
        if(Array.isArray(res.data.response)) {
          // Transforming options to the desired format
          options = [].concat.apply(
            [], res.data.response.map(
              item => item.options.map(
                optItem => (
                  { ...optItem, group: item.label }
                )
              )
            )
          )
        }
        // Checking if the selected value is contained in the results

        // Only validate the non-custom values
        // (as custom is considered a flag, indicating that the value has been added by the user)
        if(link.targetRelation.item.custom !== undefined ? !link.targetRelation.item.custom : true) {
          let foundIndex = options.findIndex(
            obj => obj.value === link.targetRelation.item.value || obj.full === link.targetRelation.item.full
          );
          if(foundIndex === -1) {
            isValid = false;
            errorMsg = 'The value of this field doesn\'t match the available options';
          }
        }
      }
      link = update(link, {
        targetRelation: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: link,
            }
          }
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfLinkTargetRelationCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetRelation: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetRelation,
                  error_text: errorMsg
                }
              }
            }
          }
        }
      });
    }

    return isValid;
  }

  // Validate the selected values of the link
  // props: {mappingId: <Text>, linkId: <Text>}
  handleValidateSelectedValuesOfLinkTargetEntityCallBack = async (props) => {
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var link = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId]);
      errorMsg = 'This field is invalid';
      // ########################
      // Target Entity Validation
      // ########################
      // Check if empty (case Array of Items)
      if(link.targetEntity.item.length === 0) {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        // Getting the selected relation
        let relationValue = undefined;
        // In case that there are no intermediates
        if(link.targetIntermediateIds.length === 0) {
          relationValue = link.targetRelation;
        }
        else {
          relationValue = link.targetIntermediates[link.targetIntermediateIds[link.targetIntermediateIds.length-1]].targetRelation;
        }

        let options = [];
        // Retrieving options
        if(relationValue !== undefined ? relationValue.item !== undefined && relationValue.item !== '' : false) {
          let res = await reasonerService.getObjects({
            id: this.state.currMappingProject.id,
            type: 'target',
            property: relationValue.item.full !== undefined ? relationValue.item.full : relationValue.item.value
          });
          // If the above service doesn't return an array, to be
          // transformed (below), then some error message has been returned
          if(Array.isArray(res.data.response)) {
            // Transforming options to the desired format
            options = [].concat.apply(
              [], res.data.response.map(
                item => item.options.map(
                  optItem => (
                    { ...optItem, group: item.label }
                  )
                )
              )
            )
          }
        }
        // Checking if the selected value is contained in the results
        // (case Array of Items)
        let count = 0;
        let erroneousValues = [];
        link.targetEntity.item.forEach(item => {
          // Only validate the non-custom values
          // (as custom is considered a flag, indicating that the value has been added by the user)
          if(item.custom !== undefined ? !item.custom : true) {
            // Handling different the first one than the rest
            if(count === 0) {
              // The first one is searched within the retrieved option list
              let foundIndex = options.findIndex(
                obj => obj.value === item.value || obj.full === item.full
              );
              if(foundIndex === -1) {
                isValid = false;
                erroneousValues.push(item.label);
              }
            }
            else {
              // The rest are search within the allClasses options list that has already been retrieved
              // But transform it first
              let transformedAllClases = [];
              if(Array.isArray(this.state.allClasses)) {
                // Transforming options to the desired format
                transformedAllClases = [].concat.apply(
                  [], this.state.allClasses.map(
                    item => item.options.map(
                      optItem => (
                        { ...optItem, group: item.label }
                      )
                    )
                  )
                )
              }

              let foundIndex = transformedAllClases.findIndex(
                obj => obj.value === item.value || obj.full === item.full
              );
              if(foundIndex === -1) {
                isValid = false;
                erroneousValues.push(item.label);
              }
            }
          }
          count = count + 1;
        });

        if(!isValid) {
          // Constructing additional error message
          let additionalErrorMsg = erroneousValues.length > 0 ?
                                   ('. The following value' + (erroneousValues.length > 1 ? 's are' : ' is') + ' not found: ') :
                                   '';
          let errorValueCount = 0;
          for (const value of erroneousValues) {
            additionalErrorMsg = additionalErrorMsg + value + (errorValueCount !== erroneousValues.length-1 ? ', ' : '')
            errorValueCount = errorValueCount + 1;
          }
          errorMsg = 'The value of this field doesn\'t match the available options' + additionalErrorMsg;
        }
      }
      link = update(link, {
        targetEntity: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: link,
            }
          }
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfLinkTargetEntityCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetEntity: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetEntity,
                  error_text: errorMsg
                }
              }
            }
          }
        }
      });
    }

    return isValid;
  }

  // Validate the selected values of the intermediate
  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleValidateSelectedValuesOfIntermediateTargetEntityCallBack = async (props) => {
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var intermediate = Object.assign(
        {}, this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId]
      );
      errorMsg = 'This field is invalid';
      // ########################
      // Target Entity Validation
      // ########################
      // Check if empty (case Array of Items)
      if(intermediate.targetEntity.item.length === 0) {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        // Getting the selected relation
        let relationValue = undefined;
        // Find its index inside the intermediate list
        const intermediateIndex = this.state.mappings[props.mappingId].links[props.linkId].targetIntermediateIds.findIndex(
          id => id === intermediate.id
        );
        // In case that this is the first intermediate
        if(intermediateIndex === 0) {
          relationValue = this.state.mappings[props.mappingId].links[props.linkId].targetRelation;
        }
        // In case that this is not the first intermediate
        else {
          // Find the ID of the previous intermediate
          const prevIntermediateId = this.state.mappings[props.mappingId].
                                      links[props.linkId].
                                      targetIntermediateIds[intermediateIndex-1];
          relationValue = this.state.mappings[props.mappingId].
                            links[props.linkId].
                            targetIntermediates[prevIntermediateId].
                            targetRelation;
        }

        let options = [];
        if(relationValue !== undefined ? relationValue.item !== undefined && relationValue.item !== '' : false) {
          // Retrieving options
          let res = await reasonerService.getObjects({
            id: this.state.currMappingProject.id,
            type: 'target',
            property: relationValue.item.full ? relationValue.item.full : relationValue.item.value
          });
          // If the above service doesn't return an array, to be
          // transformed (below), then some error message has been returned
          if(Array.isArray(res.data.response)) {
            // Transforming options to the desired format
            options = [].concat.apply(
              [], res.data.response.map(
                item => item.options.map(
                  optItem => (
                    { ...optItem, group: item.label }
                  )
                )
              )
            )
          }
        }
        // Checking if the selected value is contained in the results
        // (case Array of Items)
        let count = 0;
        let erroneousValues = [];
        intermediate.targetEntity.item.forEach(item => {
          // Only validate the non-custom values
          // (as custom is considered a flag, indicating that the value has been added by the user)
          if(item.custom !== undefined ? !item.custom : true) {
            // Handling different the first one than the rest
            if(count === 0) {
              // The first one is searched within the retrieved option list
              let foundIndex = options.findIndex(
                obj => obj.value === item.value || obj.full === item.full
              );
              if(foundIndex === -1) {
                isValid = false;
                erroneousValues.push(item.label);
              }
            }
            else {
              // The rest are search within the allClasses options list that has already been retrieved
              // But transform it first
              let transformedAllClases = [];
              if(Array.isArray(this.state.allClasses)) {
                // Transforming options to the desired format
                transformedAllClases = [].concat.apply(
                  [], this.state.allClasses.map(
                    item => item.options.map(
                      optItem => (
                        { ...optItem, group: item.label }
                      )
                    )
                  )
                )
              }
              let foundIndex = transformedAllClases.findIndex(
                obj => obj.value === item.value || obj.full === item.full
              );
              if(foundIndex === -1) {
                isValid = false;
                erroneousValues.push(item.label);
              }
            }
          }
          count = count + 1;
        });

        if(!isValid) {
          // Constructing additional error message
          let additionalErrorMsg = erroneousValues.length > 0 ?
                                   ('. The following value' + (erroneousValues.length > 1 ? 's are' : ' is') + ' not found: ') :
                                   '';
          let errorValueCount = 0;
          for (const value of erroneousValues) {
            additionalErrorMsg = additionalErrorMsg + value + (errorValueCount !== erroneousValues.length-1 ? ', ' : '')
            errorValueCount = errorValueCount + 1;
          }
          errorMsg = 'The value of this field doesn\'t match the available options' + additionalErrorMsg;
        }
      }

      intermediate = update(intermediate, {
        targetEntity: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: intermediate,
                }
              }
            }
          }
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfIntermediateTargetEntityCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                    targetEntity: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetEntity,
                      error_text: errorMsg
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
    return isValid;
  }

  // Validate the selected values of the intermediate
  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>}
  handleValidateSelectedValuesOfIntermediateTargetRelationCallBack = async (props) => {
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var intermediate = Object.assign(
        {}, this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId]
      );
      errorMsg = 'This field is invalid';
      // ########################
      // Target Relation Validation
      // ########################
      // Check if empty
      if(intermediate.targetRelation.item === '') {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        // Retrieving options
        // Getting the selected items (case Array of Items)
        let selectedClasses = [];
        if(Array.isArray(intermediate.targetEntity.item)) {
          intermediate.targetEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
        }
        let res = await reasonerService.getProperties({
          id: this.state.currMappingProject.id,
          type: 'target',
          request: selectedClasses
        });

        let options = [];
        // If the above service doesn't return an array, to be
        // transformed (below), then some error message has been returned
        if(Array.isArray(res.data.response)) {
          // Transforming options to the desired format
          options = [].concat.apply(
            [], res.data.response.map(
              item => item.options.map(
                optItem => (
                  { ...optItem, group: item.label }
                )
              )
            )
          )
        }
        // Only validate the non-custom values
        // (as custom is considered a flag, indicating that the value has been added by the user)
        if(intermediate.targetRelation.item.custom !== undefined ? !intermediate.targetRelation.item.custom : true) {
          // Checking if the selected value is contained in the results
          let foundIndex = options.findIndex(
            obj => obj.value === intermediate.targetRelation.item.value || obj.full === intermediate.targetRelation.item.full
          );
          if(foundIndex === -1) {
            isValid = false;
            errorMsg = 'The value of this field doesn\'t match the available options';
          }
        }
      }
      intermediate = update(intermediate, {
        targetRelation: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: intermediate,
                }
              }
            }
          }
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfIntermediateTargetRelationCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                targetIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                    targetRelation: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].targetRelation,
                      error_text: errorMsg
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    return isValid;
  }

  // Validate the selected values of the additional
  // props: {
  //    mappingId: <Text>,
  //    linkId*: <Text>,
  //    intermediateId*: <Text>,
  //    constExprId: <Text>,
  //    applyFullTreeValidation*: <Boolean>, // If undefind, then false is used
  // }
  // * Optional parameter
  handleValidateSelectedValuesOfAdditionalRelationCallBack = async (props) => {
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    // let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    let errorMsg = undefined;
    let additional = props.additional !== undefined ? props.additional : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      additional = undefined;
      let selectedClasses = [];
      // Case - Additional of intemediate
      if(props.intermediateId !== undefined && props.linkId !== undefined && props.mappingId !== undefined) {
        additional = Object.assign(
          {}, this.state.
              mappings[props.mappingId].
              links[props.linkId].
              targetIntermediates[props.intermediateId].
              constExprs[props.constExprId]
        );
        // Getting the selected entity items of the parent (case Array of Items)
        if(Array.isArray(this.state.
            mappings[props.mappingId].
            links[props.linkId].
            targetIntermediates[props.intermediateId].
            targetEntity.item)) {
          this.state.
          mappings[props.mappingId].
          links[props.linkId].
          targetIntermediates[props.intermediateId].
          targetEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
        }
      }
      // Case - Additional of link
      else if(props.linkId !== undefined && props.mappingId !== undefined) {
        additional = Object.assign(
          {}, this.state.
              mappings[props.mappingId].
              links[props.linkId].
              constExprs[props.constExprId]
        );

        // Getting the selected entity items of the parent (case Array of Items)
        if(Array.isArray(this.state.
            mappings[props.mappingId].
            links[props.linkId].
            targetEntity.item)) {
          this.state.
          mappings[props.mappingId].
          links[props.linkId].
          targetEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
        }
      }
      // Case - Additional of mapping
      else if(props.mappingId !== undefined) {
        additional = Object.assign(
          {}, this.state.
              mappings[props.mappingId].
              constExprs[props.constExprId]
        );

        // Getting the selected entity items of the parent (case Array of Items)
        if(Array.isArray(this.state.
            mappings[props.mappingId].
            domainTargetEntity.item)) {
          this.state.
            mappings[props.mappingId].
            domainTargetEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
        }
      }

      if(additional !== undefined) {
        errorMsg = 'This field is invalid';
        // ########################
        // Target Relation Validation
        // ########################
        // Check if empty
        if(additional.relation.item === '') {
          isValid = false;
          errorMsg = 'This field is required';
        }
        // Check if the value is valid
        else {
          // Retrieving options
          let res = await reasonerService.getProperties({
            id: this.state.currMappingProject.id,
            type: 'target',
            request: selectedClasses
          });

          let options = [];
          // If the above service doesn't return an array, to be
          // transformed (below), then some error message has been returned
          if(Array.isArray(res.data.response)) {
            // Transforming options to the desired format
            options = [].concat.apply(
              [], res.data.response.map(
                item => item.options.map(
                  optItem => (
                    { ...optItem, group: item.label }
                  )
                )
              )
            )
          }
          if(additional.relation.item.custom !== undefined ? !additional.relation.item.custom : true) {
            // Checking if the selected value is contained in the results
            let foundIndex = options.findIndex(
              obj => obj.value === additional.relation.item.value || obj.full === additional.relation.item.full
            );
            if(foundIndex === -1) {
              isValid = false;
              errorMsg = 'The value of this field doesn\'t match the available options';
            }
          }
        }

        additional = update(additional, {
          relation: {
            error_text: {
              $set: !isValid ? errorMsg : undefined
            }
          }
        });

        // Recursive validation Starts
        // Validating the selections of the rest of the tree of native additionals
        if(props.applyFullTreeValidation !== undefined ? props.applyFullTreeValidation : false) {
          // If it has any native additionals
          if(additional.constExprIds !== undefined) {
            let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
            if(fullTreeConstExpr === undefined) {
              fullTreeConstExpr = await this.handleRecursivelyValidateAndUpdateConstExprNativeTreeRelationCallBack({
                constExpr: additional,
              });
            }
            additional = fullTreeConstExpr;
          }
        }
        // Recursive validation Ends

        // Persisting state
        // Case Intermediate Additional
        if(props.intermediateId !== undefined && props.linkId !== undefined && props.mappingId !== undefined) {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                links: {
                  ...this.state.mappings[props.mappingId].links,
                  [props.linkId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                      [props.intermediateId]: {
                        ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                        constExprs: {
                          ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                          [props.constExprId]: additional,
                        }
                      }
                    }
                  }
                }
              }
            }
          }, () => {
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.isValid = isValid;
            // newProps.errorMsg = !isValid ? errorMsg : undefined;
            newProps.additional = additional;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleValidateSelectedValuesOfAdditionalRelationCallBack',
              argument: newProps
            });
          });
        }
        // Case Link Additional
        else if(props.linkId !== undefined && props.mappingId !== undefined) {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                links: {
                  ...this.state.mappings[props.mappingId].links,
                  [props.linkId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId],
                    constExprs: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                      [props.constExprId]: additional,
                    }
                  }
                }
              }
            }
          }, () => {
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.isValid = isValid;
            //newProps.errorMsg = !isValid ? errorMsg : undefined;
            newProps.additional = additional;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleValidateSelectedValuesOfAdditionalRelationCallBack',
              argument: newProps
            });
          });
        }
        // Case Mapping Additional
        else if(props.mappingId !== undefined) {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                constExprs: {
                  ...this.state.mappings[props.mappingId].constExprs,
                  [props.constExprId]: additional,
                }
              }
            }
          }, () => {
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.isValid = isValid;
            //newProps.errorMsg = !isValid ? errorMsg : undefined;
            newProps.additional = additional;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleValidateSelectedValuesOfAdditionalRelationCallBack',
              argument: newProps
            });
          });
        }
      }
    }
    // Syncing
    else {
      // Case Intermediate Additional
      if(props.intermediateId !== undefined && props.linkId !== undefined && props.mappingId !== undefined) {
        // this.setState({
        //   mappings: {
        //     ...this.state.mappings,
        //     [props.mappingId]: {
        //       ...this.state.mappings[props.mappingId],
        //       links: {
        //         ...this.state.mappings[props.mappingId].links,
        //         [props.linkId]: {
        //           ...this.state.mappings[props.mappingId].links[props.linkId],
        //           targetIntermediates: {
        //             ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
        //             [props.intermediateId]: {
        //               ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
        //               constExprs: {
        //                 ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
        //                 [props.constExprId]: {
        //                   ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId],
        //                   relation: {
        //                     ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].relation,
        //                     error_text: errorMsg
        //                   }
        //                 }
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  targetIntermediates: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                    [props.intermediateId]: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                      constExprs: {
                        ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                        [props.constExprId]: additional
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      // Case Link Additional
      else if(props.linkId !== undefined && props.mappingId !== undefined) {
        // this.setState({
        //   mappings: {
        //     ...this.state.mappings,
        //     [props.mappingId]: {
        //       ...this.state.mappings[props.mappingId],
        //       links: {
        //         ...this.state.mappings[props.mappingId].links,
        //         [props.linkId]: {
        //           ...this.state.mappings[props.mappingId].links[props.linkId],
        //           constExprs: {
        //             ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
        //             [props.constExprId]: {
        //               ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId],
        //               relation: {
        //                 ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].relation,
        //                 error_text: errorMsg
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  constExprs: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                    [props.constExprId]: additional
                  }
                }
              }
            }
          }
        });
      }
      // Case Mapping Additional
      else if(props.mappingId !== undefined) {
        // this.setState({
        //   mappings: {
        //     ...this.state.mappings,
        //     [props.mappingId]: {
        //       ...this.state.mappings[props.mappingId],
        //       constExprs: {
        //         ...this.state.mappings[props.mappingId].constExprs,
        //         [props.constExprId]: {
        //           ...this.state.mappings[props.mappingId].constExprs[props.constExprId],
        //           relation: {
        //             ...this.state.mappings[props.mappingId].constExprs[props.constExprId].relation,
        //             error_text: errorMsg
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              constExprs: {
                ...this.state.mappings[props.mappingId].constExprs,
                [props.constExprId]: additional
              }
            }
          }
        });
      }
    }
    return isValid;
  }

  // props: {constExpr: <Object>}
  handleRecursivelyValidateAndUpdateConstExprNativeTreeRelationCallBack = async (props) => {
    let constExpr = {...props.constExpr};
    if(constExpr.constExprIds !== undefined) {
      for (const subConstExprId of constExpr.constExprIds) {
        let subConstExpr = constExpr.constExprs[subConstExprId];
        // Validate Child (subConstExpr)

        // Gathering the list of properties from the parent's entity
        let selectedClasses = [];
        if(Array.isArray(constExpr.entity.item)) {
          constExpr.entity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
        }
        // Retrieving the available options
        let res = await reasonerService.getProperties({
          id: this.state.currMappingProject.id,
          type: 'target',
          request: selectedClasses
        });

        let options = [];
        // If the above service doesn't return an array, to be
        // transformed (below), then some error message has been returned
        if(Array.isArray(res.data.response)) {
          // Transforming options to the desired format
          options = [].concat.apply(
            [], res.data.response.map(
              item => item.options.map(
                optItem => (
                  { ...optItem, group: item.label }
                )
              )
            )
          )
        }

        let isValid = true;
        let errorMsg = undefined;

        // Dont check it at all if this is case of a custom value
        if(subConstExpr.relation.item.custom !== undefined ? !subConstExpr.relation.item.custom : true) {
          // Checking if the selected value is contained in the results
          let foundIndex = options.findIndex(
            obj => obj.value === subConstExpr.relation.item.value || obj.full === subConstExpr.relation.item.full
          );
          if(foundIndex === -1) {
            isValid = false;
            errorMsg = subConstExpr.relation.item !== '' ?
                       'The value of this field doesn\'t match the available options' :
                       'This field is required';
          }
        }
        // Update the child with the error message
        subConstExpr = update(subConstExpr, {
          relation: {
            error_text: {
              $set: !isValid ? errorMsg : undefined
            }
          }
        });

        subConstExpr = await this.handleRecursivelyValidateAndUpdateConstExprNativeTreeRelationCallBack({constExpr: subConstExpr});

        // Update the father
        constExpr = update(constExpr, {
          constExprs: {
            [subConstExprId]: {
              $set: subConstExpr
            }
          }
        });

      } // for loop - closes
    }
    return constExpr
  }

  handleRecursivelyValidateAndUpdateConstExprNativeTreeEntityCallBack = async (props) => {
    let constExpr = {...props.constExpr};
    if(constExpr.constExprIds !== undefined) {
      for (const subConstExprId of constExpr.constExprIds) {
        let subConstExpr = constExpr.constExprs[subConstExprId];
        let isValid = true;
        let errorMsg = undefined;
        if(subConstExpr.entity.item === '') {
          isValid = false;
          errorMsg = 'This field is required';
        }
        else {
          // Validate Child (subConstExpr)
          // Retrieving the available options
          let options = [];
          if(subConstExpr !== undefined ?
              subConstExpr.relation !== undefined ?
                subConstExpr.relation.item !== undefined && subConstExpr.relation.item !== '' :
              false :
             false) {
            let res = await reasonerService.getObjects({
              id: this.state.currMappingProject.id,
              type: 'target',
              property: subConstExpr.relation.item.full ? subConstExpr.relation.item.full : subConstExpr.relation.item.value
            });

            // If the above service doesn't return an array, to be
            // transformed (below), then some error message has been returned
            if(Array.isArray(res.data.response)) {
              // Transforming options to the desired format
              options = [].concat.apply(
                [], res.data.response.map(
                  item => item.options.map(
                    optItem => (
                      { ...optItem, group: item.label }
                    )
                  )
                )
              )
            }
          }

          // Checking if the selected value is contained in the results
          // (case Array of Items)
          let erroneousValues = [];
          subConstExpr.entity.item.forEach(item => {
            // Only validate the non-custom values
            // (as custom is considered a flag, indicating that the value has been added by the user)
            if(item.custom !== undefined ? !item.custom : true) {
              let foundIndex = options.findIndex(
                obj => obj.value === item.value || obj.full === item.full
              );
              if(foundIndex === -1) {
                isValid = false;
                erroneousValues.push(item.label);
              }
            }
          });

          if(!isValid) {
            // Constructing additional error message
            let additionalErrorMsg = erroneousValues.length > 0 ?
                                     ('. The following value' + (erroneousValues.length > 1 ? 's are' : ' is') + ' not found: ') :
                                     '';
            let errorValueCount = 0;
            for (const value of erroneousValues) {
              additionalErrorMsg = additionalErrorMsg + value + (errorValueCount !== erroneousValues.length-1 ? ', ' : '')
              errorValueCount = errorValueCount + 1;
            }
            errorMsg = 'The value of this field doesn\'t match the available options' + additionalErrorMsg;
          }
        }

        subConstExpr = update(subConstExpr, {
          entity: {
            error_text: {
              $set: !isValid ? errorMsg : undefined
            }
          }
        });

        subConstExpr = await this.handleRecursivelyValidateAndUpdateConstExprNativeTreeEntityCallBack({constExpr: subConstExpr});

        // Update the father
        constExpr = update(constExpr, {
          constExprs: {
            [subConstExprId]: {
              $set: subConstExpr
            }
          }
        });

      } // for loop - closes
    }
    return constExpr
  }

  // Validate the selected values of the additional (also validates native)
  // props: {mappingId: <Text>, linkId*: <Text>, intermediateId*: <Text>, constExprId: <Text>}
  handleValidateSelectedValuesOfAdditionalEntityCallBack = async (props) => {
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    //let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    let errorMsg = undefined;
    let additional = props.additional !== undefined ? props.additional : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      additional = undefined;
      let selectedClasses = [];
      // Case - Additional of intemediate
      if(props.intermediateId !== undefined && props.linkId !== undefined && props.mappingId !== undefined) {
        additional = Object.assign(
          {}, this.state.
              mappings[props.mappingId].
              links[props.linkId].
              targetIntermediates[props.intermediateId].
              constExprs[props.constExprId]
        );
      }
      // Case - Additional of link
      else if(props.linkId !== undefined && props.mappingId !== undefined) {
        additional = Object.assign(
          {}, this.state.
              mappings[props.mappingId].
              links[props.linkId].
              constExprs[props.constExprId]
        );
      }
      // Case - Additional of mapping
      else if(props.mappingId !== undefined) {
        additional = Object.assign(
          {}, this.state.
              mappings[props.mappingId].
              constExprs[props.constExprId]
        );
      }

      if(additional !== undefined) {
        errorMsg = 'This field is invalid';
        // ########################
        // Target Relation Validation
        // ########################
        // Check if empty
        if(additional.entity.item === '') {
          isValid = false;
          errorMsg = 'This field is required';
        }
        // Check if the value is valid
        else {
          // Retrieving options
          let options = [];
          if(additional !== undefined ?
              additional.relation !== undefined ?
                additional.relation.item !== undefined && additional.relation.item !== '' :
              false :
             false) {
            let res = await reasonerService.getObjects({
              id: this.state.currMappingProject.id,
              type: 'target',
              property: additional.relation.item.full ? additional.relation.item.full : additional.relation.item.value
            });

            // If the above service doesn't return an array, to be
            // transformed (below), then some error message has been returned
            if(Array.isArray(res.data.response)) {
              // Transforming options to the desired format
              options = [].concat.apply(
                [], res.data.response.map(
                  item => item.options.map(
                    optItem => (
                      { ...optItem, group: item.label }
                    )
                  )
                )
              )
            }
          }
          // Checking if the selected value is contained in the results
          // (case Array of Items)
          let erroneousValues = [];
          additional.entity.item.forEach(item => {
            // Only validate the non-custom values
            // (as custom is considered a flag, indicating that the value has been added by the user)
            if(item.custom !== undefined ? !item.custom : true) {
              let foundIndex = options.findIndex(
                obj => obj.value === item.value || obj.full === item.full
              );
              if(foundIndex === -1) {
                isValid = false;
                erroneousValues.push(item.label);
              }
            }
          });

          if(!isValid) {
            // Constructing additional error message
            let additionalErrorMsg = erroneousValues.length > 0 ?
                                     ('. The following value' + (erroneousValues.length > 1 ? 's are' : ' is') + ' not found: ') :
                                     '';
            let errorValueCount = 0;
            for (const value of erroneousValues) {
              additionalErrorMsg = additionalErrorMsg + value + (errorValueCount !== erroneousValues.length-1 ? ', ' : '')
              errorValueCount = errorValueCount + 1;
            }
            errorMsg = 'The value of this field doesn\'t match the available options' + additionalErrorMsg;
          }
        }

        additional = update(additional, {
          entity: {
            error_text: {
              $set: !isValid ? errorMsg : undefined
            }
          }
        });
        // Recursive validation Starts
        // Validating the selections of the rest of the tree of native additionals
        if(props.applyFullTreeValidation !== undefined ? props.applyFullTreeValidation : false) {
          // If it has any native additionals
          if(additional.constExprIds !== undefined) {
            let fullTreeConstExpr = props.fullTreeConstExpr !== undefined ? props.fullTreeConstExpr : undefined;
            if(fullTreeConstExpr === undefined) {
              fullTreeConstExpr = await this.handleRecursivelyValidateAndUpdateConstExprNativeTreeEntityCallBack({
                constExpr: additional,
              });
            }
            additional = fullTreeConstExpr;
          }
        }
        // Recursive validation Ends

        // Persisting state
        // Case Intermediate Additional
        if(props.intermediateId !== undefined && props.linkId !== undefined && props.mappingId !== undefined) {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                links: {
                  ...this.state.mappings[props.mappingId].links,
                  [props.linkId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId],
                    targetIntermediates: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                      [props.intermediateId]: {
                        ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                        constExprs: {
                          ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                          [props.constExprId]: additional,
                        }
                      }
                    }
                  }
                }
              }
            }
          }, () => {
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.isValid = isValid;
            //newProps.errorMsg = !isValid ? errorMsg : undefined;
            newProps.additional = additional;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleValidateSelectedValuesOfAdditionalEntityCallBack',
              argument: newProps
            });
          });
        }
        // Case Link Additional
        else if(props.linkId !== undefined && props.mappingId !== undefined) {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                links: {
                  ...this.state.mappings[props.mappingId].links,
                  [props.linkId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId],
                    constExprs: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                      [props.constExprId]: additional,
                    }
                  }
                }
              }
            }
          }, () => {
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.isValid = isValid;
            //newProps.errorMsg = !isValid ? errorMsg : undefined;
            newProps.additional = additional;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleValidateSelectedValuesOfAdditionalEntityCallBack',
              argument: newProps
            });
          });
        } /// CHECK THEM
        // Case Mapping Additional
        else if(props.mappingId !== undefined) {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [props.mappingId]: {
                ...this.state.mappings[props.mappingId],
                constExprs: {
                  ...this.state.mappings[props.mappingId].constExprs,
                  [props.constExprId]: additional,
                }
              }
            }
          }, () => {
            // Syncing with others
            let newProps = Object.assign({}, props); // Copy
            newProps.isValid = isValid;
            //newProps.errorMsg = !isValid ? errorMsg : undefined;
            newProps.additional = additional;
            this.sendJsonObjectToSpecificUsers({
              actionName: 'handleValidateSelectedValuesOfAdditionalEntityCallBack',
              argument: newProps
            });
          });
        }
      }
    }
    // Syncing
    else {
      // Case Intermediate Additional
      if(props.intermediateId !== undefined && props.linkId !== undefined && props.mappingId !== undefined) {
        // this.setState({
        //   mappings: {
        //     ...this.state.mappings,
        //     [props.mappingId]: {
        //       ...this.state.mappings[props.mappingId],
        //       links: {
        //         ...this.state.mappings[props.mappingId].links,
        //         [props.linkId]: {
        //           ...this.state.mappings[props.mappingId].links[props.linkId],
        //           targetIntermediates: {
        //             ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
        //             [props.intermediateId]: {
        //               ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
        //               constExprs: {
        //                 ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
        //                 [props.constExprId]: {
        //                   ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId],
        //                   entity: {
        //                     ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs[props.constExprId].entity,
        //                     error_text: errorMsg
        //                   }
        //                 }
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  targetIntermediates: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates,
                    [props.intermediateId]: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId],
                      constExprs: {
                        ...this.state.mappings[props.mappingId].links[props.linkId].targetIntermediates[props.intermediateId].constExprs,
                        [props.constExprId]: additional
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      // Case Link Additional
      else if(props.linkId !== undefined && props.mappingId !== undefined) {
        // this.setState({
        //   mappings: {
        //     ...this.state.mappings,
        //     [props.mappingId]: {
        //       ...this.state.mappings[props.mappingId],
        //       links: {
        //         ...this.state.mappings[props.mappingId].links,
        //         [props.linkId]: {
        //           ...this.state.mappings[props.mappingId].links[props.linkId],
        //           constExprs: {
        //             ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
        //             [props.constExprId]: {
        //               ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId],
        //               entity: {
        //                 ...this.state.mappings[props.mappingId].links[props.linkId].constExprs[props.constExprId].entity,
        //                 error_text: errorMsg
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              links: {
                ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId],
                  constExprs: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].constExprs,
                    [props.constExprId]: additional
                  }
                }
              }
            }
          }
        });
      }
      // Case Mapping Additional
      else if(props.mappingId !== undefined) {
        // this.setState({
        //   mappings: {
        //     ...this.state.mappings,
        //     [props.mappingId]: {
        //       ...this.state.mappings[props.mappingId],
        //       constExprs: {
        //         ...this.state.mappings[props.mappingId].constExprs,
        //         [props.constExprId]: {
        //           ...this.state.mappings[props.mappingId].constExprs[props.constExprId],
        //           entity: {
        //             ...this.state.mappings[props.mappingId].constExprs[props.constExprId].entity,
        //             error_text: errorMsg
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        this.setState({
          mappings: {
            ...this.state.mappings,
            [props.mappingId]: {
              ...this.state.mappings[props.mappingId],
              constExprs: {
                ...this.state.mappings[props.mappingId].constExprs,
                [props.constExprId]: additional
              }
            }
          }
        });
      }
    }
    return isValid;
  }

  // Native Additional Validation

  //TESTINGGGGGGGGGGNativeAdditionalValidation

  // props: {
  //  constExpr: <Object>,
  //  parentConstExpr*: <Object>, // Required only when type is 'relation'
  //  type: 'relation | entity'
  // }
  handleValidateSelectedValuesOfNativeAdditionalCallBack = async (props) => {
    const type = props.type;
    const parentConstExpr = {...props.parentConstExpr};
    const constExpr = {...props.constExpr};
    let validatedConstExpr = {...constExpr};//type === 'entity' ? {...constExpr} : {...parentConstExpr};

    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;

    if(isValid === undefined) {
      isValid = true;
      let selectedClasses = [];

      errorMsg = 'This field is invalid';

      // ########################
      // Relation Validation
      // ########################
      if(type === 'relation') {
        // Check if empty
        if(validatedConstExpr.relation.item === '') {
          isValid = false;
          errorMsg = 'This field is required';
        }
        // Check if the value is valid
        else {
          if(Array.isArray(parentConstExpr.entity.item)) {
            parentConstExpr.entity.item.forEach(element => {
              selectedClasses.push({subject: element.full});
            });
          }
          console.log('selectedClasses:');
          console.log(selectedClasses);
          // Retrieving options
          let res = await reasonerService.getProperties({
            id: this.state.currMappingProject.id,
            type: 'target',
            request: selectedClasses
          });

          let options = [];
          // If the above service doesn't return an array, to be
          // transformed (below), then some error message has been returned
          if(Array.isArray(res.data.response)) {
            // Transforming options to the desired format
            options = [].concat.apply(
              [], res.data.response.map(
                item => item.options.map(
                  optItem => (
                    { ...optItem, group: item.label }
                  )
                )
              )
            )
          }
          if(validatedConstExpr.relation.item.custom !== undefined ? !validatedConstExpr.relation.item.custom : true) {
            // Checking if the selected value is contained in the results
            let foundIndex = options.findIndex(
              obj => obj.value === validatedConstExpr.relation.item.value || obj.full === validatedConstExpr.relation.item.full
            );
            if(foundIndex === -1) {
              isValid = false;
              errorMsg = 'The value of this field doesn\'t match the available options';
            }
          }

          // validatedConstExpr = update(validatedConstExpr, {
          //   relation: {
          //     error_text: {
          //       $set: !isValid ? errorMsg : undefined
          //     }
          //   }
          // });
        }
      }
      // ########################
      // Entity Validation
      // ########################
      else {
        // Check if empty
        if(validatedConstExpr.entity.item === '') {
          isValid = false;
          errorMsg = 'This field is required';
        }
        // Check if the value is valid
        else {
          // Retrieving options
          let options = [];
          if(validatedConstExpr !== undefined ?
              validatedConstExpr.relation !== undefined ?
                validatedConstExpr.relation.item !== undefined && validatedConstExpr.relation.item !== '' :
              false :
             false) {
            let res = await reasonerService.getObjects({
              id: this.state.currMappingProject.id,
              type: 'target',
              property: validatedConstExpr.relation.item.full ? validatedConstExpr.relation.item.full : validatedConstExpr.relation.item.value
            });

            // If the above service doesn't return an array, to be
            // transformed (below), then some error message has been returned
            if(Array.isArray(res.data.response)) {
              // Transforming options to the desired format
              options = [].concat.apply(
                [], res.data.response.map(
                  item => item.options.map(
                    optItem => (
                      { ...optItem, group: item.label }
                    )
                  )
                )
              )
            }
          }
          // Checking if the selected value is contained in the results
          // (case Array of Items)
          let erroneousValues = [];
          validatedConstExpr.entity.item.forEach(item => {
            // Only validate the non-custom values
            // (as custom is considered a flag, indicating that the value has been added by the user)
            if(item.custom !== undefined ? !item.custom : true) {
              let foundIndex = options.findIndex(
                obj => obj.value === item.value || obj.full === item.full
              );
              if(foundIndex === -1) {
                isValid = false;
                erroneousValues.push(item.label);
              }
            }
          });

          if(!isValid) {
            // Constructing additional error message
            let additionalErrorMsg = erroneousValues.length > 0 ?
                                     ('. The following value' + (erroneousValues.length > 1 ? 's are' : ' is') + ' not found: ') :
                                     '';
            let errorValueCount = 0;
            for (const value of erroneousValues) {
              additionalErrorMsg = additionalErrorMsg + value + (errorValueCount !== erroneousValues.length-1 ? ', ' : '')
              errorValueCount = errorValueCount + 1;
            }
            errorMsg = 'The value of this field doesn\'t match the available options' + additionalErrorMsg;
          }

          // validatedConstExpr = update(validatedConstExpr, {
          //   entity: {
          //     error_text: {
          //       $set: !isValid ? errorMsg : undefined
          //     }
          //   }
          // });
        }
      }
    }
    //return validatedConstExpr;
    return !isValid ? errorMsg : undefined
  }




  // Validate the selected source values of the mapping
  // props: {mappingId: <Text>}
  handleValidateSelectedValuesOfMappingDomainSourceNodeCallBack = async (props) => {
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var mapping = Object.assign({}, this.state.mappings[props.mappingId]);
      errorMsg = 'This field is invalid';
      // ########################
      // Target Entity Validation
      // ########################
      // Check if empty
      if(mapping.domainSourceNode.item === '') {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        let options = [];
        // If the already retrieved "allClasses" is not an Array to be
        // transformed (below), then ???
        console.log('this.state.allXpaths');
        console.log(this.state.allXpaths);
        if(Array.isArray(this.state.allXpaths)) {
          // Transforming options to the desired format
          options = [].concat.apply(
            [], this.state.allXpaths.map(
              item => (
                { ...item, group: item.label }
              )
            )
          )
        }
        // Checking if the selected value is contained in the results
        // (case Array of Items)
        let erroneousValue = '';
        // Only validate the non-custom values
        // (as custom is considered a flag, indicating that the value has been added by the user)
        if(mapping.domainSourceNode.item.custom !== undefined ? !mapping.domainSourceNode.item.custom : true) {
          let foundIndex = options.findIndex(
            obj => obj.value === mapping.domainSourceNode.item.value
          );
          if(foundIndex === -1) {
            isValid = false;
            erroneousValue = mapping.domainSourceNode.item.label;
          }
        }

        if(!isValid) {
          // Constructing additional error message
          let errorMsg = 'The value ' + erroneousValue + ', doesn\'t match the available options';
        }
      }

      mapping = update(mapping, {
        domainSourceNode: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: mapping
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfMappingDomainSourceNodeCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            domainSourceNode: {
              ...this.state.mappings[props.mappingId].domainSourceNode,
              error_text: errorMsg
            }
          }
        }
      });
    }

    return isValid;
  }

  // Validate the source selected values of the link
  // props: {mappingId: <Text>, linkId: <Text>, fieldType: 'SourceRelation | SourceNode'}
  handleValidateSelectedValuesOfLinkSourceFieldCallBack = async (props) => {
    const fieldType = props.fieldType;
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var link = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId]);
      errorMsg = 'This field is invalid';
      // ##########################
      // Source Relation Validation
      // ##########################
      // Check if empty
      if(link[fieldType].item === '') {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        // Retrieving options
        let res = await xpathService.getXpaths({
          id: this.state.currMappingProject.id,
          type: 'source',
          xpath: this.state.mappings[props.mappingId].domainSourceNode !== '' ? this.state.mappings[props.mappingId].domainSourceNode.item.value : ''
        });
        let options = [];
        // If the above service doesn't return an array, to be
        // transformed (below), then some error message has been returned
        if(Array.isArray(res.data.response)) {
          // Transforming options to the desired format
          options = [].concat.apply(
            [], res.data.response.map(
              item => (
                { ...item, group: item.label }
              )
            )
          )
        }
        // Checking if the selected value is contained in the results

        // Only validate the non-custom values
        // (as custom is considered a flag, indicating that the value has been added by the user)
        if(link[fieldType].item.custom !== undefined ? !link[fieldType].item.custom : true) {
          let foundIndex = options.findIndex(
            obj => obj.value === link[fieldType].item.value
          );
          if(foundIndex === -1) {
            isValid = false;
            errorMsg = 'The value of this field doesn\'t match the available options';
          }
        }
      }

      link = update(link, {
        [fieldType]: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: link,
            }
          }
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfLinkSourceFieldCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                [fieldType]: {
                  ...this.state.mappings[props.mappingId].links[props.linkId][fieldType],
                  error_text: errorMsg
                }
              }
            }
          }
        }
      });
    }
    return isValid;
  }

  // Validate the source selected values of the Intermediate
  // props: {mappingId: <Text>, linkId: <Text>, intermediateId: <Text>, fieldType: 'SourceRelation | SourceNode'}
  handleValidateSelectedValuesOfIntermediateSourceFieldCallBack = async (props) => {
    const fieldType = props.fieldType;
    let isValid = props.isValid !== undefined ? props.isValid : undefined;
    let errorMsg = props.errorMsg !== undefined ? props.errorMsg : undefined;
    // Don't apply in case of syncing (doSave: false)
    if(isValid === undefined) {
      isValid = true;
      var intermediate = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId].sourceIntermediates[props.intermediateId]);
      errorMsg = 'This field is invalid';
      // ##########################
      // Source Relation Validation
      // ##########################
      // Check if empty
      if(intermediate[fieldType].item === '') {
        isValid = false;
        errorMsg = 'This field is required';
      }
      // Check if the value is valid
      else {
        // Retrieving options
        let res = await xpathService.getXpaths({
          id: this.state.currMappingProject.id,
          type: 'source',
          xpath: this.state.mappings[props.mappingId].domainSourceNode !== '' ? this.state.mappings[props.mappingId].domainSourceNode.item.value : ''
        });
        let options = [];
        // If the above service doesn't return an array, to be
        // transformed (below), then some error message has been returned
        if(Array.isArray(res.data.response)) {
          // Transforming options to the desired format
          options = [].concat.apply(
            [], res.data.response.map(
              item => (
                { ...item, group: item.label }
              )
            )
          )
        }
        // Checking if the selected value is contained in the results

        // Only validate the non-custom values
        // (as custom is considered a flag, indicating that the value has been added by the user)
        if(intermediate[fieldType].item.custom !== undefined ? !intermediate[fieldType].item.custom : true) {
          let foundIndex = options.findIndex(
            obj => obj.value === intermediate[fieldType].item.value
          );
          if(foundIndex === -1) {
            isValid = false;
            errorMsg = 'The value of this field doesn\'t match the available options';
          }
        }
      }
      intermediate = update(intermediate, {
        [fieldType]: {
          error_text: {
            $set: !isValid ? errorMsg : undefined
          }
        }
      });

      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
                [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                sourceIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].sourceIntermediates,
                  [props.intermediateId]: intermediate,
                }
              }
            }
          }
        }
      }, () => {
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.isValid = isValid;
        newProps.errorMsg = !isValid ? errorMsg : undefined;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleValidateSelectedValuesOfIntermediateSourceFieldCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [props.mappingId]: {
            ...this.state.mappings[props.mappingId],
            links: {
              ...this.state.mappings[props.mappingId].links,
              [props.linkId]: {
                ...this.state.mappings[props.mappingId].links[props.linkId],
                sourceIntermediates: {
                  ...this.state.mappings[props.mappingId].links[props.linkId].sourceIntermediates,
                  [props.intermediateId]: {
                    ...this.state.mappings[props.mappingId].links[props.linkId].sourceIntermediates[props.intermediateId],
                    [fieldType]: {
                      ...this.state.mappings[props.mappingId].links[props.linkId].sourceIntermediates[props.intermediateId][fieldType],
                      error_text: errorMsg
                    }
                  }
                }
              }
            }
          }
        },
      });
    }
    return isValid;
  }






//////////////////////////////////////////






  // props: {mappingId: <Text>}
  validateDomain = props => {
    let isValid = true;
    var mapping = Object.assign({}, this.state.mappings[props.mappingId]);

    // Validation Code
    if(mapping.domainSourceNode.item === '' || mapping.domainTargetEntity.item === '') {
      isValid = false;

      if(mapping.domainSourceNode.item === '') {
        mapping = update(mapping, {
          domainSourceNode: {
            error_text: {
              $set: 'This field is required'
            }
          }
        });
      }
      if(mapping.domainTargetEntity.item === '') {
        mapping = update(mapping, {
          domainTargetEntity: {
            error_text: {
              $set: 'This field is required'
            }
          }
        });
      }
    }

    // Mapping - Additionals
    mapping.constExprIds.forEach(constExprId => {
      // Validation Code
      if(mapping.constExprs[constExprId].entity.item === '' || mapping.constExprs[constExprId].relation.item === '') {
        isValid = false;
        if(mapping.constExprs[constExprId].entity.item === '') {
          mapping = update(mapping, {
            constExprs: {
              [constExprId]: {
                entity: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
        if(mapping.constExprs[constExprId].relation.item === '') {
          mapping = update(mapping, {
            constExprs: {
              [constExprId]: {
                relation: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
      }
    });
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: mapping,
      },
    });
    return isValid;
  }

  // props: {mappingId: <Text>, linkId: <Text>}
  validateLink = props => {
    let isValid = true;
    var link = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId]);


    // Validation Code
    if(link.sourceRelation.item === '' ||
      link.sourceNode.item === '' ||
      link.targetRelation.item === '' ||
      link.targetEntity.item === '') {
      isValid = false;
      if(link.sourceRelation.item === '') {
        link = update(link, {
          sourceRelation: {
            error_text: {
              $set: 'This field is required'
            }
          }
        });
      }
      if(link.sourceNode.item === '') {
        link = update(link, {
          sourceNode: {
            error_text: {
              $set: 'This field is required'
            }
          }
        });
      }
      if(link.targetRelation.item === '') {
        link = update(link, {
          targetRelation: {
            error_text: {
              $set: 'This field is required'
            }
          }
        });
      }
      if(link.targetEntity.item === '') {
        link = update(link, {
          targetEntity: {
            error_text: {
              $set: 'This field is required'
            }
          }
        });
      }
    }

    // Link - Additionals
    link.constExprIds.forEach(constExprId => {
      // Validation Code
      if(link.constExprs[constExprId].entity.item === '' ||
        link.constExprs[constExprId].relation.item === '') {
        isValid = false;

        if(link.constExprs[constExprId].entity.item === '') {
          link = update(link, {
            constExprs: {
              [constExprId]: {
                entity: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
        if(link.constExprs[constExprId].relation.item === '') {
          link = update(link, {
            constExprs: {
              [constExprId]: {
                relation: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
      }
    });

    // Link - sourceIntermediates
    link.sourceIntermediateIds.forEach(sourceIntermediateId => {
      // Validation Code
      if(link.sourceIntermediates[sourceIntermediateId].sourceNode.item === '' ||
        link.sourceIntermediates[sourceIntermediateId].sourceRelation.item === '') {
        isValid = false;

        if(link.sourceIntermediates[sourceIntermediateId].sourceNode.item === '') {
          link = update(link, {
            sourceIntermediates: {
              [sourceIntermediateId]: {
                sourceNode: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
        if(link.sourceIntermediates[sourceIntermediateId].sourceRelation.item === '') {
          link = update(link, {
            sourceIntermediates: {
              [sourceIntermediateId]: {
                sourceRelation: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
      }
    });

    // Link - TargetIntermediates
    link.targetIntermediateIds.forEach(targetIntermediateId => {
      // Validation Code
      if(link.targetIntermediates[targetIntermediateId].targetEntity.item === '' ||
        link.targetIntermediates[targetIntermediateId].targetRelation.item === '') {
        isValid = false;

        if(link.targetIntermediates[targetIntermediateId].targetEntity.item === '') {
          link = update(link, {
            targetIntermediates: {
              [targetIntermediateId]: {
                targetEntity: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
        if(link.targetIntermediates[targetIntermediateId].targetRelation.item === '') {
          link = update(link, {
            targetIntermediates: {
              [targetIntermediateId]: {
                targetRelation: {
                  error_text: {
                    $set: 'This field is required'
                  }
                }
              }
            }
          });
        }
      }

      // Link - targetIntermediates - Additionals
      link.targetIntermediates[targetIntermediateId].constExprIds.forEach(constExprId => {
        // Validation Code
        if(link.targetIntermediates[targetIntermediateId].constExprs[constExprId].entity.item === '' ||
          link.targetIntermediates[targetIntermediateId].constExprs[constExprId].relation.item === '') {
          isValid = false;
          if(link.targetIntermediates[targetIntermediateId].constExprs[constExprId].entity.item === '') {
            link = update(link, {
              targetIntermediates: {
                [targetIntermediateId]: {
                  constExprs: {
                    [constExprId]: {
                      entity: {
                        error_text: {
                          $set: 'This field is required'
                        }
                      }
                    }
                  }
                }
              }
            });
          }
          if(link.targetIntermediates[targetIntermediateId].constExprs[constExprId].relation.item === '') {
            link = update(link, {
              targetIntermediates: {
                [targetIntermediateId]: {
                  constExprs: {
                    [constExprId]: {
                      relation: {
                        error_text: {
                          $set: 'This field is required'
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        }
      });
    });

    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: {
            ...this.state.mappings[props.mappingId].links,
            [props.linkId]: link,
          }
        }
      }
    });

    return isValid;
  }

  // User presence in mapping project related functions

  handleUserBecomesPresentInMappingProject = async () => {
    this.handleUserBecomesPresentInMappingProjectCallBack({});
  }

  // props: {doSave*: <Boolean>}
  // Optional argument, which means that props could be empty
  handleUserBecomesPresentInMappingProjectCallBack = async props => {
    let onlineUsername = props.onlineUsername !== undefined ? props.onlineUsername : undefined;
    // Don't apply in case of syncing
    if(onlineUsername === undefined) {
      onlineUsername = this.props.currUser.currUsername;
    }
    try {
      let response = await onlineUserPresenceService.userParticipatesMappingProject({
        mappingProjectId: this.state.currMappingProject.id,
        username: onlineUsername,
      });
      if(response.data.succeed) {
        // console.log('usersInAllMappingProjects');
        // console.log(response.data.usersInAllMappingProjects);
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.onlineUsername = onlineUsername;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUserBecomesPresentInMappingProjectCallBack',
            argument: newProps
          });
        }
        else {
          this.cleanUpAndGetUsersInMappingProject();
        }
      }
      else {
        this.props.showErrorSnackBar({msg: 'You failed to join the online participant users of this mapping project.'});
      }
    } catch (e) {
      console.error('Some error occured while making this user online participant of this project!');
      if(e.response !== undefined) {
        console.error(e.response.status);
        this.props.showErrorSnackBar({msg: 'Some error occured while making this user online participant of this project!'});
        return e.response;
      }
    }
  }

  handleUserBecomesAbsentFromMappingProject = async () => {
    this.handleUserBecomesAbsentFromMappingProjectCallBack({});
  }

  // props: {doSave*: <Boolean>}
  // Optional argument, which means that props could be empty
  handleUserBecomesAbsentFromMappingProjectCallBack = async props => {
    let onlineUsername = props.onlineUsername !== undefined ? props.onlineUsername : undefined;
    // Don't apply in case of syncing
    if(onlineUsername === undefined) {
      onlineUsername = this.props.currUser.currUsername;
    }
    try {
      let response = await onlineUserPresenceService.userLeavesMappingProject({
        mappingProjectId: this.state.currMappingProject.id,
        username: onlineUsername,
      });
      if(response.data.succeed) {
        // console.log('usersInAllMappingProjects');
        // console.log(response.data.usersInAllMappingProjects);
        // Don't apply in case of syncing (doSave: false)
        if(props.doSave !== undefined ? props.doSave : true) {
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.onlineUsername = onlineUsername;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleUserBecomesAbsentFromMappingProjectCallBack',
            argument: newProps
          });
        }
        else {
          this.cleanUpAndGetUsersInMappingProject();
        }
      }
      else {
        this.props.showErrorSnackBar({msg: 'Even though you\'ve left this mapping project, you still appear as an online participant for it.'});
      }
    } catch (e) {
      console.error('Some error occured while removing this user frm the list of online participants of this project!');
      if(e.response !== undefined) {
        console.error(e.response.status);
        this.props.showErrorSnackBar({msg: 'Some error occured while removing this user frm the list of online participants of this project!'});
        return e.response;
      }
    }
  }

  cleanUpAndGetUsersInMappingProject = async () => {
    try {
      let response = await onlineUserPresenceService.cleanUpAndGetUsersInMappingProjectById({mappingProjectId: this.state.currMappingProject.id});
      if(response.data.succeed) {
        // console.log('usersInMappingProject');
        // console.log(response.data.usersInMappingProject);
        this.setState({
          onlineParticipantingUsers: response.data.usersInMappingProject
        });
      }
      else {
        this.props.showErrorSnackBar({msg: 'The list of online users, currently participating this mapping project could not be retrieved.'});
      }
    } catch (e) {
      console.error('Some error occured while retrieving the online participating users in this mapping project!');
      if(e.response !== undefined) {
        console.error(e.response.status);
        this.props.showErrorSnackBar({msg: 'Some error occured while retrieving the online participating users in this mapping project!'});
        return e.response;
      }
    }
  }

  // Participating Users Dialog

  handleOpenParticipatingUsersDialog = () => {
    this.setState({
      participatingUsersDialogOpen: true
    }, () => {
      this.cleanUpAndGetUsersInMappingProject();
    });
  }

  handleCloseParticipatingUsersDialog = () => {
    this.setState({
      participatingUsersDialogOpen: false
    });
  }

  handleParticipatingUsersDialogToggleFullScreen = () => {
    this.setState({
      participatingUsersDialogFullScreen: !this.state.participatingUsersDialogFullScreen
    });
  }

  // Adding / removing users from the list of participants (users that can edit)
  handleSelectToParticipate = user => event => {
    this.handleSelectToParticipateCallBack({user: user, checked: event.target.checked})
  }

  // props: {user: <Object>, checked: <Boolean>, users*: <Arry>, retrieveAllUsers**: <boolean>}
  // * Only when syncing
  // ** Only when this action is called due to some user deletion action who happens to be in the member list
  //    NOTE: The "retrieveAllUsers" property is only used when the "users" property is present
  handleSelectToParticipateCallBack = props => {
    let users = props.users !== undefined ? props.users : undefined;
    let retrieveAllUsers = props.retrieveAllUsers !== undefined ? props.retrieveAllUsers : undefined;
    // Don't apply in case of syncing
    if(users === undefined) {
      const user = props.user;
      const checked = props.checked;
      let array = this.state.currMappingProject !== undefined ?
                  (
                    this.state.currMappingProject.users !== undefined && this.state.currMappingProject.users !== null ?
                    this.state.currMappingProject.users.slice() :
                    []
                  ) :
                  [];
      if(checked) {
        array.push(user);
      }
      else {
        let index = array.findIndex(
          obj => obj.id === user.id
        );
        array.splice(index, 1);
      }
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          users: array,
        }
      }, async () => {
        await mainService.saveMappingProject(this.state.currMappingProject);
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.users = this.state.currMappingProject.users;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleSelectToParticipateCallBack',
          argument: newProps
        });
      });
    }
    else {
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          users: users,
        }
      });
      if(retrieveAllUsers !== undefined) {
        this.retrieveAllEnabledUsers();
      }
    }
  }

  // props: {user: <Object>}
  // Used only for syncing whith others when a new one
  // is set due to the deletion of the former one
  handleAuthorCallBack = props => {
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        author: props.user,
      }
    });
  }

  // Confirm deletion of Mapping, Link, Intermediate (source & target) and constExpr
  handleShowConfirmDeleteSnackbar = props => event => {
    event.stopPropagation();
    this.showConfirmSnackBar({
      msg: 'Are you sure you want to delete it?',
      iconSize: 'large',
      //onConfirmClick: this.handleDeleteMappingConstExpr,
      onConfirmClick: props.handleDelete,
      confirmButtonLabel: 'Yes proceed',
      onCancelClick: this.handleCloseConfirmSnackbar,
      cancelButtonLabel: 'Cancel',
      argument: props,
    });
  }

  handleCloseConfirmSnackbar	= props => () => {
    this.props.closeSnackbar(props.snackBarId);
  }

  // Generic way of displaying confirmations

  // props: {
  //    msg: <Text>,
  //    iconSize: 'large | small (default: 'small')'
  //    onConfirmClick: <Function>
  //    confirmButtonLabel: <Text>
  //    onCancelClick: <Function>
  //    cancelButtonLabel: <Text>,
  //    argument: <Object> Holding anything I need to pass to ConfirmSnackMessage component
  // }
  showConfirmSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <ConfirmSnackMessage
            id={key}
            msg={props.msg}
            variant={'warning'}
            iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
            hasHtmlContent={true}
            onConfirmClick={props.onConfirmClick}
            confirmButtonLabel={props.confirmButtonLabel}
            onCancelClick={props.onCancelClick}
            cancelButtonLabel={props.cancelButtonLabel}
            argument={props.argument}
            />
        ),
      }
    );
  }

  // Zip Compression Export

  handleExportToZip = props => async () => {
    const mappingProjectId = props.id;
    // First construct the X3ML and prompt the respective message if errors occured
    let validationObject = await this.validateWholeMappingProject();
    let isValid = validationObject.isValid;
    if(isValid) {
      let x3mlRes = await this.convertAndsaveX3mlCallBack({showDialog: false});
      if(x3mlRes.data.succeed) {
        await fileUploadService.exportMappingProjectToZipById({id: mappingProjectId});
      }
    }
    else {
      this.setState({
        confirmExportWithoutX3MLSnackbarShown: true
      });
    }
  }

  handleCloseConfirmExportWithoutX3MLSnackbar = () => {
    this.setState({
      confirmExportWithoutX3MLSnackbarShown : false,
    });
  }

  handleConfirmExportWithoutX3ML = props => async () => {
    const mappingProjectId = props.id;
    const x3mlRelativePath = props.relativePath;
    let deleteRes = await fileUploadService.deleteFileByRelativePath({relativePath: x3mlRelativePath});
    await fileUploadService.exportMappingProjectToZipById({id: mappingProjectId});
    this.setState({
      confirmExportWithoutX3MLSnackbarShown : false,
    });
  }

  // RDF Visualiser Input
  handleCloserdfVisualizerInputDialog = () => {
    this.setState({
        rdfVisualizerInputDialogOpen: false,
    });
  }

  handleRdfVisualizerInputDialogToggleFullScreen = () => {
    this.setState({
      rdfVisualizerInputFullScreen: !this.state.rdfVisualizerInputFullScreen
    });
  }

  // MApping Project Description Related

  handleToggleMappingProjectDescrptionExpand = () => {
    this.setState({
      mappingProjectDescriptionExpanded: !this.state.mappingProjectDescriptionExpanded,
    });
  };

  handleEnableEditMappingProjectDescription = event => {
    event.stopPropagation();
    this.setState({
      showEditMappingProjectDescription: true
    }, () => {
      this.mappingProjectDescriptionTextInput.current.focus();
    });
  }

  handleMappingProjectTextInputChange = (fieldName) => event => {
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        [fieldName]: event.target.value,
      }
    });
  };

  handleDisableEditMappingProjectDescription = () => {
    this.handleDisableEditMappingProjectDescriptionCallBack({});
  }

  // props: {doSave* <Boolean>}
  // * Optional argument (when syncing), which means that props could be empty
  handleDisableEditMappingProjectDescriptionCallBack = props => {
    this.setState({
      showEditMappingProjectDescription: false
    }, async () => {
      let mappingProjectDescripton = props.mappingProjectDescripton !== undefined ? props.mappingProjectDescripton : undefined;
      if(mappingProjectDescripton === undefined) {
        // Persist to the database
        await mainService.saveMappingProject(this.state.currMappingProject);
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.mappingProjectDescripton = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleDisableEditMappingProjectDescriptionCallBack',
          argument: newProps
        });
      }
      // Only when synced by others
      else {
        this.setState({
          currMappingProject: {
            ...this.state.currMappingProject,
            description: mappingProjectDescripton,
          }
        });
      }
    });
  }

  // Automated Actions Dialog
  handleOpenAutomatedActionsDialog = () => {
    this.setState({
      automatedActionsDialogOpen: true
    }, () => {
      this.props.showWarningSnackBar({msg: 'This functionality is under development', iconSize: 'large'});
    });
  }

  handleCloseAutomatedActionsDialog = () => {
    this.setState({
      automatedActionsDialogOpen: false
    });
  }

  handleAutomatedActionsDialogToggleFullScreen = () => {
    this.setState({
      automatedActionsDialogOpen: !this.state.automatedActionsDialogOpen
    });
  }

  // Source Input Coverage Dialog
  handleOpenSourceInputCoverageDialog = () => {
    this.setState({
      sourceInputCoverageOpen: true,
    }, () => {
      this.retrieveUsedXpathsCallBack();
    });
  }

  handleCloseSourceInputCoverageDialog = () => {
    this.setState({
      sourceInputCoverageOpen: false
    });
  }

  handleSourceInputCoverageOpenDialogToggleFullScreen = () => {
    this.setState({
      sourceInputCoverageDialogFullScreen: !this.state.sourceInputCoverageDialogFullScreen
    });
  }

  // props: {<Empty>}
  // doSave: <Boolean> wight or might not be present inside it
  handleSelectAllToCoverXpaths = event => {
    this.handleSelectAllToCoverXpathsCallBack({allChecked: event.target.checked});
  }

  // props: {allChecked: <Boolean>, doSave*: <Boolean>}
  // *: This is an optional parameter
  handleSelectAllToCoverXpathsCallBack = (props) => {
    const allChecked = props.allChecked;
    let array = allChecked ?
                [...this.state.allXpaths].map(xpath => ({value: xpath.value, selected: true})) :
                (
                  this.state.currentlyUsedXpaths.size > 0 ?
                  [...this.state.allXpaths].map(
                    xpath => ({
                      value: xpath.value,
                      selected: this.state.currentlyUsedXpaths.has(xpath.value) ? true : false
                    })
                  ) :
                  [...this.state.allXpaths].map(
                    (xpath, index) => ({
                      value: xpath.value,
                      selected: this.state.currentlyUsedXpaths.has(xpath.value) ? true : (index === 0 ? true : false)
                    })
                  )
                );

                //Array.from(this.state.currentlyUsedXpaths).map(xpath => ({value: xpath, selected: true}));
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        toCoverXpaths: array,
      }
    }, async () => {
      await this.calcProgressCallBack();
      if(props.doSave !== undefined ? props.doSave : true) {
        await mainService.saveMappingProject(this.state.currMappingProject);
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleSelectAllToCoverXpathsCallBack',
          argument: newProps
        });
      }
    });
  };

  // Discovers the Xpaths that have already been used by the user and then Calculates the source input coverage
  retrieveUsedXpathsCallBack = () => {
    let usedXpaths = new Set();
    //this.state.allXpaths
    // Mapping - Domain
    this.state.mappingIds.forEach(mappingId => {
      let mapping = this.state.mappings[mappingId];
  		if(mapping.domainSourceNode.item !== '') {
        if(mapping.domainSourceNode.error_text === undefined &&
          (mapping.domainSourceNode.item.custom !== undefined ? !mapping.domainSourceNode.item.custom : true)) {
          usedXpaths.add(mapping.domainSourceNode.item.value);
        }
      }
      // Link
      mapping.linkIds.forEach(linkId => {
        let link = mapping.links[linkId];
    		if(link.sourceNode.item !== '') {
          if(link.sourceNode.item.value !== '') {
            if(link.sourceNode.error_text === undefined &&
              (link.sourceNode.item.custom !== undefined ? !link.sourceNode.item.custom : true)) {
                usedXpaths.add(mapping.domainSourceNode.item.value + '/' + link.sourceNode.item.value);
              }
          }
        }
        if(link.sourceRelation.item !== '') {
          if(link.sourceRelation.item.value !== '') {
            if(link.sourceRelation.error_text === undefined &&
              (link.sourceRelation.item.custom !== undefined ? !link.sourceRelation.item.custom : true)) {
                usedXpaths.add(mapping.domainSourceNode.item.value + '/' + link.sourceRelation.item.value);
              }
          }
        }
        // Intermediates
        link.sourceIntermediateIds.forEach(intermediateId => {
          let intermediate = link.sourceIntermediates[intermediateId];
      		if(intermediate.sourceNode.item !== '') {
            if(intermediate.sourceNode.item.value !== '') {
              if(intermediate.sourceNode.error_text === undefined &&
                (intermediate.sourceNode.item.custom !== undefined ? !intermediate.sourceNode.item.custom : true)) {
                  usedXpaths.add(mapping.domainSourceNode.item.value + '/' + intermediate.sourceNode.item.value);
                }
            }
          }
          if(intermediate.sourceRelation.item !== '') {
            if(intermediate.sourceRelation.item.value !== '') {
              if(intermediate.sourceRelation.error_text === undefined &&
                (intermediate.sourceRelation.item.custom !== undefined ? !intermediate.sourceRelation.item.custom : true)) {
                  usedXpaths.add(mapping.domainSourceNode.item.value + '/' + intermediate.sourceRelation.item.value);
                }
            }
          }
      	});
    	});
  	});

    // Calc how many have been used out of every xpath
    // console.log('this.state.allXpaths.length:')
    // console.log(this.state.allXpaths.length);
    // console.log('usedXpaths.length:')
    // console.log(usedXpaths.size);
    //
    // console.log('usedXpaths:');
    // console.log(usedXpaths);

    // console.log('this.state.allXpaths:')
    // console.log(this.state.allXpaths);
    // console.log('this.state.currMappingProject.toCoverXpaths:')
    // console.log(this.state.currMappingProject.toCoverXpaths);
    //
    // // Reconstruct the toCoverXpaths array
    // let updatedToCoverXpaths = this.state.currMappingProject !== undefined ? [...this.state.currMappingProject.toCoverXpaths] : [];
    // //usedXpaths.forEach(usedXpath => {
    // let index = 0;
    // for (const usedXpath of usedXpaths) {
    //   if(!updatedToCoverXpaths.filter(obj => obj.selected).map(obj => obj.value).includes(usedXpath)) {
    //     let toCoverXpathsIndex = this.state.currMappingProject.toCoverXpaths.findIndex(
    //       obj => obj.value === usedXpath
    //     );
    //     if(toCoverXpathsIndex !== -1) {
    //       updatedToCoverXpaths = update(this.state.currMappingProject.toCoverXpaths, {
    //         [index]: {
    //           selected: {$set: true},
    //         },
    //       });
    //     }
    //     else {
    //       updatedToCoverXpaths = update(this.state.currMappingProject.toCoverXpaths, {
    //         [index]: {
    //           selected: {$set: false},
    //         },
    //       });
    //     }
    //   }
    //   index = index + 1;
    // //});
    // }

    let updatedToCoverXpaths = this.state.currMappingProject !== undefined ? [...this.state.currMappingProject.toCoverXpaths] : [];
    usedXpaths.forEach(usedXpath => {
      if(!updatedToCoverXpaths.filter(obj => obj.selected).map(obj => obj.value).includes(usedXpath)) {
        let index = this.state.currMappingProject.toCoverXpaths.findIndex(
          obj => obj.value === usedXpath
        );
        if(index !== -1) {
          updatedToCoverXpaths = update(this.state.currMappingProject.toCoverXpaths, {
            [index]: {
              selected: {$set: true},
            },
          });
        }
      }
    });

    this.setState({
      currentlyUsedXpaths: new Set(usedXpaths),
      currMappingProject: {
        ...this.state.currMappingProject,
        toCoverXpaths: updatedToCoverXpaths,
      }
    }, async () => {
      await this.calcProgressCallBack();
    });
  }

  // Calculates the source input coverage by taking accoun the currently used Xpaths and the available ones
  // As available Xpaths are defined the Xpaths that have been marked as "to cover" by the user out of all the Xpaths
  // Uses this.state.currentlyUsedXpaths & this.state.currMappingProject.toCoverXpaths
  calcProgressCallBack = async () => {

    if(this.state.currentlyUsedXpaths !== undefined && this.state.currMappingProject.toCoverXpaths !== undefined) {
      this.setState({
        currMappingProject: {
          ...this.state.currMappingProject,
          sourceInputCoverageValue: Math.round(
            100*(
              this.state.currentlyUsedXpaths.size /
              this.state.currMappingProject.toCoverXpaths.filter(obj => obj.selected).length
            )
          ),
        }
      });
    }
  }

  // Adding / removing xpaths from the list of active ones (active are considered
  // those that are taken into account when calculating the source input coverage)
  handleToggleCoverageActiveXpath = xpathValue => event => {
    if(this.state.currMappingProject.toCoverXpaths.filter(obj => obj.selected).length > 1) {
      this.handleToggleCoverageActiveXpathCallBack({
        xpathValue: xpathValue,
        checked: event.target.checked
      });
    }
    else {
      if(event.target.checked) {
        this.handleToggleCoverageActiveXpathCallBack({
          xpathValue: xpathValue,
          checked: event.target.checked
        });
      }
      else {
        this.props.showWarningSnackBar({
          msg: 'At least one XPath should be checked in this list.',
        });
      }
    }
  }

  // props: {xpathValue: <Object>, checked: <Boolean>}
  handleToggleCoverageActiveXpathCallBack = props => {
    const xpathValue = props.xpathValue;
    const checked = props.checked;
    let array = [];
    if(this.state.currMappingProject.toCoverXpaths !== undefined && this.state.currMappingProject.toCoverXpaths !== null) {
      let index = this.state.currMappingProject.toCoverXpaths.findIndex(
        obj => obj.value === xpathValue
      );
      if(index !== -1) {
        array = update(this.state.currMappingProject.toCoverXpaths, {
          [index]: {
            selected: {$set: checked},
          },
        });
      }
    }

    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        toCoverXpaths: array,
      },
    }, async () => {
      await this.calcProgressCallBack();
      if(props.doSave !== undefined ? props.doSave : true) {
        await mainService.saveMappingProject(this.state.currMappingProject);
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleToggleCoverageActiveXpathCallBack',
          argument: newProps
        });
      }
    });
  }

  handleToggleShowSourceCoverage = event => {
    this.handleToggleShowSourceCoverageCallBack({
      checked: event.target.checked
    });
  }

  // props: {checked: <Boolean>}
  handleToggleShowSourceCoverageCallBack = props => {
    const checked = props.checked;
    this.setState({
      currMappingProject: {
        ...this.state.currMappingProject,
        showSourceCoverage: checked,
      }
    }, async () => {
      if(props.doSave !== undefined ? props.doSave : true) {
        await mainService.saveMappingProject(this.state.currMappingProject);
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleToggleShowSourceCoverageCallBack',
          argument: newProps
        });
      }
    });
  }

  // Focus on domain or link

  // props: {id: <Text>}
  accordionFocus = props => () => {
    const id = props.id;
    if(this.state.focusedObjectId !== id) {
      this.setState(function(prevState, props) {
        prevState.focusedObjectIdUserMap.set(id, this.props.user);
        return {
          focusedObjectIdUserMap: new Map(prevState.focusedObjectIdUserMap),
          focusedObjectId: id,
        }
      }, async () => {
        let response = await onlineUserPresenceService.userGainsFocusAtObjectInMappingProject({
          mappingProjectId: this.state.currMappingProject.id,
          id: id,
          user: this.props.user,
        });
        // Send request to the server to inform any online in thei mapping project user about the new updated
        // map hlding the object ID where users are now focusing
        this.sendFocusJsonObjectToSpecificUsers({
           argument: {mappingProjectId: this.state.currMappingProject.id}
        });
      });
    }
  }

  // Only used to unfocus the user when the component is unmounted
  // (i.e. the user clicks on browser's back button)
  // props: {id: <Text>}
  accordionBlurOnUnmountCallBack = async props => {
    if(this.state.currMappingProject !== undefined) {
      const id = props.id;
      let response = await onlineUserPresenceService.userLosesFocusFromObjectInMappingProject({
        mappingProjectId: this.state.currMappingProject.id,
        id: id,
        user: this.props.user,
      });
      // Send request to the server to inform any online in thei mapping project user about the new updated
      // map hlding the object ID where users are now focusing
      this.sendFocusJsonObjectToSpecificUsers({
         argument: {mappingProjectId: this.state.currMappingProject.id}
      });
    }
  }

  // props: {id: <Text>}
  accordionBlur = props => () => {
    const id = props.id;
    if(this.state.focusedObjectIdUserMap.get(id) !== undefined) {
      this.setState(function(prevState, props) {
        prevState.focusedObjectIdUserMap.delete(id);
        return {
          focusedObjectIdUserMap: new Map(prevState.focusedObjectIdUserMap),
          focusedObjectId: '',
        }
      }, async () => {
        let response = await onlineUserPresenceService.userLosesFocusFromObjectInMappingProject({
          mappingProjectId: this.state.currMappingProject.id,
          id: id,
          user: this.props.user,
        });
        // Send request to the server to inform any online in thei mapping project user about the new updated
        // map hlding the object ID where users are now focusing
        this.sendFocusJsonObjectToSpecificUsers({
           argument: {mappingProjectId: this.state.currMappingProject.id}
        });
      });
    }
  }

  retrieveFocusOfOthers = () => {
    this.retrieveFocusOfOthersCallBack({});
  }
  // props: {EMPTY}
  // Empty props is used for this function to comply with the general mechanism
  retrieveFocusOfOthersCallBack = async props => {
    if(this.state.currMappingProject.id !== undefined && this.state.currMappingProject.id !== null) {
      let response = await onlineUserPresenceService.retrieveFocusMapForMappingProjectById({
        mappingProjectId: this.state.currMappingProject.id,
      });
      this.setState({
        focusedObjectIdUserMap: new Map(Object.entries(response.data.focusedObjectIdUserMap)),
      });
    }
    else {
      this.props.showErrorSnackBar({msg: 'Something went wrong with syncing your view with others, please refresh this page.'});
    }
  }

  // props: {mappingId: <Text>}
  onLinkDragDrop = props => ({ removedIndex, addedIndex }) => {
    props.removedIndex = removedIndex;
    props.addedIndex = addedIndex;
    this.onLinkDragDropCallBack(props);
  }

  // props: {mappingId: <Text>, removedIndex: <Integer>, addedIndex: <Integer>}
  onLinkDragDropCallBack = props => {
    const mappingId = props.mappingId;
    const removedIndex = props.removedIndex;
    const addedIndex = props.addedIndex;
    let array = arrayMove(this.state.mappings[mappingId].linkIds, removedIndex, addedIndex);
    this.setState({
      mappings: {
        ...this.state.mappings,
        [mappingId]: {
          ...this.state.mappings[mappingId],
          linkIds: array,
        }
      }
    }, async () => {
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await this.saveMappingTreeModel();
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'onLinkDragDropCallBack',
          argument: newProps
        });
      }
    });
  }

  // props: {empty}
  // handleCloneLink = props => event => {
  //   event.stopPropagation();

  // props: {empty}
  onCommentDragDrop = ({ removedIndex, addedIndex }) => {
    let props ={};
    props.removedIndex = removedIndex;
    props.addedIndex = addedIndex;
    this.onCommentDragDropCallBack(props);
  }

  // Using this.state.currComments
  onCommentDragDropCallBack = props => {

    const removedIndex = props.removedIndex;
    const addedIndex = props.addedIndex;

    // Using currCommentProps from state, which holds the mapping and Link's IDs
    // this.state.currCommentProps: {linkId: <Text>, mappingId: <Text>}
    let currCommentProps = props.currCommentProps !== undefined ? props.currCommentProps : undefined;
    if(currCommentProps === undefined) {
      currCommentProps = this.state.currCommentProps;
    }

    const mappingId = currCommentProps.mappingId;
    const linkId = currCommentProps.linkId; // Can be undefined

    let newArray = props.newArray !== undefined ? props.newArray : undefined;
    if(newArray === undefined) {
      newArray = arrayMove(this.state.currComments, removedIndex, addedIndex);
    }

    // Case Link
    if(linkId !== undefined) {
      this.setState({
        currComments: newArray,
        mappings: {
          ...this.state.mappings,
          [mappingId]: {
            ...this.state.mappings[mappingId],
            links: {
              ...this.state.mappings[mappingId].links,
              [linkId]: {
                ...this.state.mappings[mappingId].links[linkId],
                comments: newArray
              }
            }
          }
        }
      }, async () => {

        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.currCommentProps = currCommentProps;
          newProps.newArray = newArray;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'onCommentDragDropCallBack',
            argument: newProps
          });
        }
      });
    }

    // Case Mapping (Domain)
    else {
      this.setState({
        currComments: newArray,
        mappings: {
          ...this.state.mappings,
          [mappingId]: {
            ...this.state.mappings[mappingId],
            comments: newArray
          }
        }
      }, async () => {

        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          newProps.currCommentProps = currCommentProps;
          newProps.newArray = newArray;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'onCommentDragDropCallBack',
            argument: newProps
          });
        }
      });
    }

  }

  handleCopyHyperlink = props => event => {
    event.stopPropagation();

    // Cleaning the location.pathname from any path parameters
    let cleanedLocationPathname = location.pathname;
    // Find the index of HRID as path param if any
    const hridIndex = cleanedLocationPathname.indexOf(this.state.currMappingProject.hrId);
    // If found, then remove everything after that
    cleanedLocationPathname = hridIndex !== -1 ? cleanedLocationPathname.slice(0, hridIndex) : cleanedLocationPathname;
    // Removing '/' if it is the last character
    cleanedLocationPathname = cleanedLocationPathname.endsWith('/') ? cleanedLocationPathname = cleanedLocationPathname.slice(0, -1) : cleanedLocationPathname;
    // Construct the hyperlink
    const hyperlinkToCopy =  `${location.origin}${cleanedLocationPathname}/${this.state.currMappingProject.hrId}/${props.linkId ? props.linkId : props.mappingId}`;

    // navigator.clipboard.writeText(hyperlinkToCopy)
    //   .then(() => {
    //     this.props.showInfoSnackBar({
    //       msg: `<div><div>Hyperlink for the link has been copied in clipboard</div><div>${hyperlinkToCopy}</div></div>`,
    //       hasHtmlContent: true,
    //     });
    //   })
    //   .catch((error) => {
    //     console.error('Error copying text: ', error);
    //   });
    
    // Case there is secured domain https: or localhost
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(hyperlinkToCopy)
        .then(() => {
          this.props.showInfoSnackBar({
            msg: `<div><div>Hyberlink for the link has been copied in clipboard</div><div>${hyperlinkToCopy}</div></div>`,
            hasHtmlContent: true,
          });
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    }
    // There is no secured domain
    else {
      // Fallback method using a temporary textarea
      const textArea = document.createElement("textarea");
      textArea.value = hyperlinkToCopy;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
    
      try {
        document.execCommand('copy');
        this.props.showInfoSnackBar({
          msg: `<div><div>Hyberlink for the link has been copied in clipboard</div><div>${hyperlinkToCopy}</div></div>`,
          hasHtmlContent: true,
        });
      } catch (error) {
        console.error('Fallback: Oops, unable to copy', error);
      }
    
      document.body.removeChild(textArea);
    }
  }

  // Clone / Copy Link - Starts

  // props: {mappingId: <Text>, linkId: <Text>}
  handleCloneLink = props => event => {
    event.stopPropagation();
    this.handleCloneLinkCallBack(props);
  }

  // props: {mappingId: <TEXT>, linkId: <TEXT>, newLink*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleCloneLinkCallBack = props => {
    let newLink = props.newLink !== undefined ? props.newLink : undefined;
    if(newLink === undefined) { // Case of NOT syncing via websocket
      newLink = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId]); // Copy
      newLink.id = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    }

    // Update the object of objects for the respective mapping
    let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links); // Copy
    newObjOfObjects[newLink.id] = newLink;
    let newLinkIds = [...this.state.mappings[props.mappingId].linkIds]; // Create a copy

    // Position to place cloned link
    const originalLinkIndex = newLinkIds.findIndex(
      id => id === props.linkId
    );
    
    newLinkIds.splice(originalLinkIndex + 1, 0, newLink.id);
    //newLinkIds.push(newLink.id);

    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: newObjOfObjects,
          linkIds: newLinkIds,
        }
      }
    }, async () => {
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await this.saveMappingTreeModel();
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.newLink = newLink;
        newProps.newLinkIds = newLinkIds;
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleCloneLinkCallBack',
          argument: newProps
        });
      }
    });
  }

  // props: {mappingId: <Text>, linkId: <Text>}
  handleCopyLink = props => event => {
    event.stopPropagation();
    this.handleCopyLinkCallBack(props);
  }

  // props: {mappingId: <TEXT>, newLink*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleCopyLinkCallBack = props => {
    let newLink = Object.assign({}, this.state.mappings[props.mappingId].links[props.linkId]); // Copy
    newLink.id = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    localStorage.setItem('linkCopy3M', JSON.stringify(newLink));
    this.setState({
      linkIsInClipboard: localStorage.getItem('linkCopy3M') !== null,
    }, () => {
      this.sendJsonObjectToSpecificUsers({
        actionName: 'checkIfLinkIsInClipboardCallBack',
        userId: this.props.user.id,
      });
    });
  }

  // props: {mappingId: <TEXT>, doSave*: <Boolean>}
  handlePasteLink = props => event => {
    event.stopPropagation();
    this.handlePasteLinkCallBack(props);
  }

  // props: {mappingId: <TEXT>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handlePasteLinkCallBack = props => {
    const newLink = JSON.parse(localStorage.getItem('linkCopy3M'));
    localStorage.removeItem('linkCopy3M');
    // Update the object of objects for the respective mapping
    let newObjOfObjects = Object.assign({}, this.state.mappings[props.mappingId].links); // Copy
    newObjOfObjects[newLink.id] = newLink;
    let newLinkIds = [...this.state.mappings[props.mappingId].linkIds]; // Create a copy
    newLinkIds.push(newLink.id);
    this.setState({
      mappings: {
        ...this.state.mappings,
        [props.mappingId]: {
          ...this.state.mappings[props.mappingId],
          links: newObjOfObjects,
          linkIds: newLinkIds,
        }
      },
      linkIsInClipboard: localStorage.getItem('linkCopy3M') !== null,
    }, async () => {
      // Validating the first source field
      await this.handleValidateSelectedValuesOfLinkSourceFieldCallBack({
        mappingId: props.mappingId,
        linkId: newLink.id,
        fieldType: 'sourceRelation'
      });
      // // Validating the first target field
      await this.handleValidateSelectedValuesOfLinkTargetRelationCallBack({
        mappingId: props.mappingId,
        linkId: newLink.id,
      });
      this.showSuccessSnackBar({msg: 'The copied link was succefully added to the mapping'});
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await this.saveMappingTreeModel();
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.newLink = newLink;
        newProps.newLinkIds = newLinkIds;
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleCloneLinkCallBack',
          argument: newProps
        });
        this.sendJsonObjectToSpecificUsers({
          actionName: 'checkIfLinkIsInClipboardCallBack',
          userId: this.props.user.id,
        });
      }
    });
  }

  checkIfLinkIsInClipboardCallBack = () => {
    this.setState({
      linkIsInClipboard: localStorage.getItem('linkCopy3M') !== null,
    });
  }

  // Clone / Copy Link - Ends

  // Clone / Copy Mapping - Starts

  // props: {mappingId: <Text>}
  handleCloneMapping = props => event => {
    event.stopPropagation();
    this.handleCloneMappingCallBack(props);
  }

  // props: {mappingId: <TEXT>, newMapping*: <Object>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleCloneMappingCallBack = props => {
    let newMapping = props.newMapping !== undefined ? props.newMapping : undefined;
    if(newMapping === undefined) { // Case of NOT syncing via websocket
      newMapping = Object.assign({}, this.state.mappings[props.mappingId]); // Copy
      newMapping.id = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    }

    // Update the object of objects for the respective mapping project
    let newObjOfObjects = Object.assign({}, this.state.mappings); // Copy
    newObjOfObjects[newMapping.id] = newMapping;
    let newMappingIds = [...this.state.mappings]; // Create a copy

    // Position to place cloned mapping
    const originalMappingIndex = newMappingIds.findIndex(
      id => id === props.mappingId
    );
    
    newMappingIds.splice(originalMappingIndex + 1, 0, newMapping.id);
    //newMappingIds.push(newMapping.id);

    this.setState({
      mappings: newObjOfObjects,
      mappingIds: newmappingIds,
    }, async () => {
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await this.saveMappingTreeModel();
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.newMapping = newMapping;
        newProps.newMappingIds = newMappingIds;
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleCloneMappingCallBack',
          argument: newProps
        });
      }
    });
  }

  // props: {mappingId: <Text>}
  handleCopyMapping = props => event => {
    event.stopPropagation();
    this.handleCopyMappingCallBack(props);
  }

  // props: {mappingId: <TEXT>, doSave*: <Boolean>}
  // * optional argument (case of syncing)
  handleCopyMappingCallBack = props => {
    let newMapping = {...this.state.mappings[props.mappingId]}; // Copy
    // Setting new ID for mapping
    newMapping.id = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    
    // Set new IDs for each contained links
    let newLinkIds = [];
    let newLinks = {};

    // newMapping.linkIds.forEach(linkId => {
    for (const linkId of newMapping.linkIds) {
      const newLinkId = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
      let newLink = {...newMapping.links[linkId]};
      newLink.id = newLinkId;
      newLinkIds.push(newLinkId);
      newLinks[newLinkId] = newLink;
    }
    // });

    newMapping.linkIds = newLinkIds;
    newMapping.links = newLinks;
    
    localStorage.setItem('mappingCopy3M', JSON.stringify(newMapping));
    this.setState({
      mappingIsInClipboard: localStorage.getItem('mappingCopy3M') !== null,
    }, () => {
      this.sendJsonObjectToSpecificUsers({
        actionName: 'checkIfMappingIsInClipboardCallBack',
        userId: this.props.user.id,
      });
    });
  }

  // props: {doSave*: <Boolean>}
  handlePasteMapping = props => event => {
    event.stopPropagation();
    this.handlePasteMappingCallBack(props);
  }

  // props: {mappingId: <TEXT>}
  // * optional argument (case of syncing)
  handlePasteMappingCallBack = props => {
    const newMapping = JSON.parse(localStorage.getItem('mappingCopy3M'));
    localStorage.removeItem('mappingCopy3M');
    // Update the object of objects for the respective mapping project
    let newObjOfObjects = Object.assign({}, this.state.mappings); // Copy
    newObjOfObjects[newMapping.id] = newMapping;
    let newMappingIds = [...this.state.mappingIds]; // Create a copy
    newMappingIds.push(newMapping.id);
    this.setState({
      mappings: newObjOfObjects,
      mappingIds: newMappingIds,
      mappingIsInClipboard: localStorage.getItem('mappingCopy3M') !== null,
    }, async () => {
      // Validating the first source field
      await this.handleValidateSelectedValuesOfMappingDomainSourceNodeCallBack({
        mappingId: newMapping.id,
      });
      // // Validating the first target field
      await this.handleValidateSelectedValuesOfMappingDomainTargetEntityCallBack({
        mappingId: newMapping.id,
      });
      this.showSuccessSnackBar({msg: 'The copied mapping was succefully added to the mapping'});
      // Don't apply in case of syncing (doSave: false)
      if(props.doSave !== undefined ? props.doSave : true) {
        // Persist to the database
        await this.saveMappingTreeModel();
        // Syncing with others
        let newProps = Object.assign({}, props); // Copy
        newProps.newMapping = newMapping;
        newProps.newMappingIds = newMappingIds;
        newProps.doSave = false;
        this.sendJsonObjectToSpecificUsers({
          actionName: 'handleCloneMappingCallBack',
          argument: newProps
        });
        this.sendJsonObjectToSpecificUsers({
          actionName: 'checkIfMappingIsInClipboardCallBack',
          userId: this.props.user.id,
        });
      }
    });
  }

  checkIfMappingIsInClipboardCallBack = () => {
    this.setState({
      mappingIsInClipboard: localStorage.getItem('mappingCopy3M') !== null,
    });
  }

  // Clone / Copy Mapping - Ends









  // Togles whether this mapping or link is considered to be used or not in the X3ML construction
  // props: {mappingId: <Text>, linkId*: <Text>}
  handleChangeIsInUseStatus = props => event => {
    event.stopPropagation();
    const checked = event.target.checked;
    props.checked = checked;
    this.handleChangeIsInUseStatusCallBack(props);
  }

  // props: {mappingId: <Text>, linkId*: <Text>, checked <Boolean>}
  handleChangeIsInUseStatusCallBack = props => {
    const mappingId = props.mappingId;
    const linkId = props.linkId;
    const checked = props.checked;

    // Case Link
    if(linkId !== undefined) {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [mappingId]: {
            ...this.state.mappings[mappingId],
            links: {
              ...this.state.mappings[mappingId].links,
              [linkId]: {
                ...this.state.mappings[mappingId].links[linkId],
                isInUse: checked,
              }
            }
          }
        }
      }, async () => {
        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleChangeIsInUseStatusCallBack',
            argument: newProps
          });
        }
      });
    }
    // Case Mapping
    else {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [mappingId]: {
            ...this.state.mappings[mappingId],
            isInUse: checked,
          }
        }
      }, async () => {
        /*
        // Check if there are any affected links
        for await (const linkId of this.state.mappings[props.mappingId].linkIds) {
          this.setState({
            mappings: {
              ...this.state.mappings,
              [mappingId]: {
                ...this.state.mappings[mappingId],
                links: {
                  ...this.state.mappings[mappingId].links,
                  [linkId]: {
                    ...this.state.mappings[mappingId].links[linkId],
                    isInUse: checked,
                  }
                }
              }
            }
          });
        }
        */

        if(props.doSave !== undefined ? props.doSave : true) {
          // Persist to the database
          await this.saveMappingTreeModel();
          // Syncing with others
          let newProps = Object.assign({}, props); // Copy
          newProps.doSave = false;
          this.sendJsonObjectToSpecificUsers({
            actionName: 'handleChangeIsInUseStatusCallBack',
            argument: newProps
          });
        }
      });
    }
  }

  // Massively checks or unchecks the links of the given mapping in terms of whether they should be used or not
  // props: {mappingId: <Text>}
  handleChangeIsInUseStatusOfAffectedLinks = props => event => {
    event.stopPropagation();
    const checked = event.target.checked;
    props.checked = checked;
    this.handleChangeIsInUseStatusOfAffectedLinksCallBack(props);
  }

  // props: {mappingId: <Text>, linkId*: <Text>, checked <Boolean>}
  handleChangeIsInUseStatusOfAffectedLinksCallBack = async props => {
    const checked = props.checked;
    const mappingId = props.mappingId;
    for await (const linkId of this.state.mappings[mappingId].linkIds) {
      this.setState({
        mappings: {
          ...this.state.mappings,
          [mappingId]: {
            ...this.state.mappings[mappingId],
            links: {
              ...this.state.mappings[mappingId].links,
              [linkId]: {
                ...this.state.mappings[mappingId].links[linkId],
                isInUse: checked,
              }
            }
          }
        }
      });
    }

    if(props.doSave !== undefined ? props.doSave : true) {
      // Persist to the database
      await this.saveMappingTreeModel();
      // Syncing with others
      let newProps = Object.assign({}, props); // Copy
      newProps.doSave = false;
      this.sendJsonObjectToSpecificUsers({
        actionName: 'handleChangeIsInUseStatusOfAffectedLinksCallBack',
        argument: newProps
      });
    }
  }

  // Development

  printState = () => {
    console.log(this.state);
  }

  // props: {id: <Text>}
  scrollToLinkOrMappingByIdCallBack = props => {
    const id = props.id;
    // Find the mapping
    const mappingId = this.findMappingIdByIdCallBack(props);
    // Check whether this mappig is already expanded
    var newExpandedMappingIdList = [...this.state.expandedMappingIdList,]
    const expandedMappingIndex = newExpandedMappingIdList.findIndex(
      item => item === mappingId
    );
    // Expand it if not already expanded
    if(expandedMappingIndex === -1) {
      newExpandedMappingIdList.push(mappingId);

      this.setState({
        erroneousId: id,
      }, () => {
        this.setState({
          expandedMappingIdList: newExpandedMappingIdList,
        });
      });

    }
    else {
      // Scroll mapping or link into view
      var mergedRefRecordMaps = new Map([...this.state.mappingRefRecordMap, ...this.state.linkRefRecordMap]);
      if(mergedRefRecordMaps.get(id) !== undefined) {
        mergedRefRecordMaps.get(id).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  // props: {mappingId: <Text>}
  mappingExpandOrCollapseFinished = props => (node, done) => {
      if(this.state.erroneousId !== -1) {
        setTimeout(() => {
          const id = this.state.erroneousId;
          this.setState({
            erroneousId: -1,
          }, () => {
            // Scroll to view if the Id is found
            var mergedRefRecordMaps = new Map([...this.state.mappingRefRecordMap, ...this.state.linkRefRecordMap]);
            if(mergedRefRecordMaps.get(id) !== undefined) {
              mergedRefRecordMaps.get(id).scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          });
        }, 1000);
      }
      node.addEventListener('transitionend', done, false);
  }

  // props: {id: <Text>}
  findMappingIdByIdCallBack = props => {
    const id = props.id;
    var finalMappingId = -1; // The ID of the mapping we are looking for
    // First check the IDs of the mappings
    const mappingIndex = this.state.mappingIds.findIndex(
      item => item === id
    );

    if(mappingIndex !== -1) {
      finalMappingId = this.state.mappingIds[mappingIndex];
    }

    // Else searching the links
    else {
      for (const mappingId of this.state.mappingIds) {
        const linkIndex = this.state.mappings[mappingId].linkIds.findIndex(
          item => item === id
        );
        if(linkIndex !== -1) {
          finalMappingId = mappingId;
          break;
        }
      }
    }
    return finalMappingId;
  }

  //props: {linkId: <Text>}
  // callBackProps.refRecType: Can either be mappingRefRecordMap or linkRefRecordMap
  handleHoldRefRecord = callBackProps => {
    this.setState(function(prevState, props) {
      prevState[callBackProps.refRecType].set(callBackProps.id, callBackProps.ref);
      return {
        [callBackProps.refRecType]: prevState[callBackProps.refRecType]
      }
    });
  }

  handleRemoveRefRecord = callBackProps => {
    // linkRefRecordMap
    this.setState(function(prevState, props) {
      prevState[callBackProps.refRecType].delete(callBackProps.id, callBackProps.ref);
      return {
        [callBackProps.refRecType]: prevState[callBackProps.refRecType]
      }
    });
  }

  setCustomState = () => {
    // Testing setstate in huge object and array
    let mappingIdstmp = this.state.mappingIds.slice(); // Create a copy
    //let mappingstmp = JSON.parse(JSON.stringify(this.state.mappings));// Create a deep copy
    console.log('Deep Copy of "mappings" object created');

    this.setState({
      mappingIds: mappingIdstmp,
      mappings: this.state.mappings,//mappingstmp,
    }, function() {
      console.log('setState was applied');
    });
  }

  sendJsonObjectToSpecificUsers = props => {
    // Expnding props
    if(this.state.currMappingProject !== undefined ? this.state.currMappingProject.id !== undefined : false) {
      props.mappingProjectId = this.state.currMappingProject.id
    }
    // Checking connection
    if(this.props.connected) {
      // Send task to all users
      this.props.stompClient.send('/app/sendJsonPackageToAllButTheSender', {}, JSON.stringify(props));
    }
    else {
      if(props.notifyIfNotSync !== undefined ? props.notifyIfNotSync : true) {
        this.props.showWarningSnackBar({
          msg: 'Real time syncing with other users was not possible due to disconnection issues. '
               + 'This means that other users will only find out about the changes applied as soon '
               + 'as they refresh or visit this view.',
          iconSize: 'large'
        });
      }
    }
  }

  // That will actual grap the Map from the server and send it to all users but this
  sendFocusJsonObjectToSpecificUsers = props => {
    // Expnding props
    if(this.state.currMappingProject !== undefined ? this.state.currMappingProject.id !== undefined : false) {
      props.mappingProjectId = this.state.currMappingProject.id
    }
    // Checking connection
    if(this.props.connected) {
      // Send task to all users
      this.props.stompClient.send('/app/sendFocusJsonPackageToAllButTheSender', {}, JSON.stringify(props));
    }
    else {
      if(props.notifyIfNotSync !== undefined ? props.notifyIfNotSync : true) {
        this.props.showWarningSnackBar({
          msg: 'Real time syncing with other users was not possible due to disconnection issues. '
               + 'This means that other users will only find out about the changes applied as soon '
               + 'as they refresh or visit this view.',
          iconSize: 'large'
        });
      }
    }
  }

  // jsonPackage: {actionName: <Text>, mappingProjectId: <Text>, argument: <Object>}
  // i.e. argument: {something: <Text>, somethingElse: <Text>}
  syncSaveWithOthers = jsonPackage => {
    if(this.state.currMappingProject !== undefined) {
      if(jsonPackage.mappingProjectId === this.state.currMappingProject.id) {
        const actionName = jsonPackage.actionName;
        if(actionName === 'handleAddMapping') {
          const newMapping = jsonPackage.argument.newMapping;
          this.setState({
            mappings: {
              ...this.state.mappings,
              [newMapping.id]: newMapping
            },
          }, function() {
            // Update the mappingIds Array
            let array = this.state.mappingIds.slice(); // Create a copy
            array.push(newMapping.id);
            this.setState({
                mappingIds: array,
            });
          });
        }
        // These callback functions have the form:
        // actionName = (argument) => { ... }
        else if(
          actionName === 'handleDeleteMappingCallBack' ||
          actionName === 'handleAddLinkCallBack' || actionName === 'handleDeleteLinkCallBack' ||
          actionName === 'handleAddIntermediateCallBack' || actionName === 'handleDeleteIntermediateCallBack' ||
          actionName === 'handleAddMappingConstExprCallBack' || actionName === 'handleDeleteMappingConstExprCallBack' ||
          actionName === 'handleAddLinkConstExprCallBack' || actionName === 'handleDeleteLinkConstExprCallBack' ||
          actionName === 'handleAddIntermediateConstExprCallBack' || actionName === 'handleDeleteIntermediateConstExprCallBack' ||
          actionName === 'handleSaveNamedgraphCallBack' || actionName === 'handleUpdateCommentsCallBack' ||
          actionName === 'handleUpdateConditionsCallBack' || actionName === 'handleUpdateConditionListAndOrCallBack' ||
          actionName === 'handleSaveInstanceInfoCallBack' || actionName === 'handleSaveVariableCallBack' ||
          actionName === 'handleSaveInstanceGeneratorDeclaration' || actionName === 'handleUpdateLabelGeneratorDeclarationsCallBack' ||
          actionName === 'handleUpdateNamespaceEveryWhere' ||
          actionName === 'handleMappingInputChangeCallBack' || actionName === 'handleLinkInputChangeCallBack' ||
          actionName === 'handleIntermediateInputChangeCallBack' || actionName === 'handleInputChangeMappingConstExprCallBack' ||
          actionName === 'handleInputChangeLinkConstExprCallBack' || actionName === 'handleInputChangeIntermediateConstExprCallBack' ||
          actionName === 'handleValidateSelectedValuesOfMappingDomainTargetEntityCallBack' ||
          actionName === 'handleValidateSelectedValuesOfLinkTargetRelationCallBack' || actionName === 'handleValidateSelectedValuesOfLinkTargetEntityCallBack' ||
          actionName === 'handleValidateSelectedValuesOfIntermediateTargetEntityCallBack' || actionName === 'handleValidateSelectedValuesOfIntermediateTargetRelationCallBack' ||
          actionName === 'handleValidateSelectedValuesOfAdditionalRelationCallBack' || actionName === 'handleValidateSelectedValuesOfAdditionalEntityCallBack' ||

          actionName === 'handleValidateSelectedValuesOfMappingDomainSourceNodeCallBack' || actionName === 'handleValidateSelectedValuesOfLinkSourceFieldCallBack' ||
          actionName === 'handleValidateSelectedValuesOfIntermediateSourceFieldCallBack' || actionName === 'handleToggleCoverageActiveXpathCallBack' ||
          actionName === 'handleSelectAllToCoverXpathsCallBack' || actionName === 'handleToggleShowSourceCoverageCallBack' ||
          actionName === 'handleAddNewNativeConstExprCallBack' ||
          actionName === 'onLinkDragDropCallBack' || actionName === 'handleCloneLinkCallBack' || actionName === 'handleCloneMappingCallBack' ||
          actionName === 'onCommentDragDropCallBack' || actionName === 'handleChangeIsInUseStatusCallBack' ||

          actionName ==='handleApplyWholeX3MLChangesCallBack' || actionName === 'handleChangeIsInUseStatusOfAffectedLinksCallBack' ||

          actionName === 'handleSetFileMetadataInput' || actionName === 'handleAddFileMetadataCallBack' || actionName === 'handleDeleteFileMetadataCallBack' ||
          actionName === 'handlePredefinedFileMetadataAutocompleteInputChangeCallBack' || actionName === 'handleChangeFileParticipationCallBack' || 
          actionName === 'importX3mlDomain' || actionName === 'importX3mlLink' ||
          actionName === 'loadGeneratorDefinitionsCallBack' || actionName === 'handleDeleteSelectedManagerGeneratorDefinitionsCallBack' ||
          actionName === 'handleSelectToParticipateCallBack' || actionName === 'handleDeactivateTitleEditingCallBack' ||
          actionName === 'handleSaveFileMetadataDialogCallBack' || actionName === 'handleToggleStatusCallBack' || actionName === 'handleDisableEditMappingProjectDescriptionCallBack' ||
          actionName === 'handleUserBecomesPresentInMappingProjectCallBack' || actionName === 'handleUserBecomesAbsentFromMappingProjectCallBack' ||
          actionName === 'handleAuthorCallBack' || actionName === 'handlePrefixAndNamespaceChangedOnSelectionsInMappingProjectCallBack'
        ) {
          this[actionName](jsonPackage.argument);
        }
        //actionName === 'retrieveFocusOfOthersCallBack'

        // Called when a focus update has occured
        else if(jsonPackage.type === 'userFocusUpdate') {
          if(jsonPackage.focusedObjectIdUserMap !== undefined && jsonPackage.focusedObjectIdUserMap !== null) {
            this.setState({
              focusedObjectIdUserMap: new Map(Object.entries(jsonPackage.focusedObjectIdUserMap)),
            });
          }
        }
      }
      // Here we are not interested in the ID of the MappingProject, but rather the ID of the user
      // The same user can open two different mapping in separate tabs and start copy pasting links.
      if(jsonPackage.userId === this.props.user.id) {
        const actionName = jsonPackage.actionName;
        if(actionName === 'checkIfLinkIsInClipboardCallBack' || actionName === 'checkIfMappingIsInClipboardCallBack') {
          this[actionName](jsonPackage.argument);
        }
      }
    }
    // Called when any use becomes offline
    if(jsonPackage.type === 'onlineUserStatus') {
      if(!jsonPackage.isOnline) {
        const participantUserIndex = this.state.onlineParticipantingUsers.findIndex(
          obj => obj.username === this.props.currUser.currUsername
        );
        // If the user that become offline is found in the list of the online participants, then
        // Some clean up is performed
        if(participantUserIndex !== -1) {
          this.cleanUpAndGetUsersInMappingProject();
        }
      }
    }
    else if(jsonPackage.actionName === 'retrieveFocusOfOthersCallBack') {
      this.retrieveFocusOfOthersCallBack(jsonPackage.argument);
    }
  }

  // props: {mappingId: <TEXT>, linkId*: <TEXT>}
  // * optional argument
  navigateToFirstComment = props => event => {
    event.stopPropagation();
    console.log('handleMappingUseComment');
    // Decide of what comment this is and opening the comment dialog
    // Link
    if(props.linkId !== undefined) {
      this.setState({
        commentDialogOpen: true,
        currCommentProps: props,
        currComments: this.state.mappings[props.mappingId].links[props.linkId].comments !== undefined ?
                      this.state.mappings[props.mappingId].links[props.linkId].comments :
                      [],
        currComment: this.state.mappings[props.mappingId].links[props.linkId].comments[0],
        commentTabIndex: 1
      });
    }
    // Domain
    else {
      this.setState({
        commentDialogOpen: true,
        currCommentProps: props,
        currComments: this.state.mappings[props.mappingId].comments !== undefined ?
                      this.state.mappings[props.mappingId].comments :
                      [],
        currComment: this.state.mappings[props.mappingId].comments[0],
        commentTabIndex: 1
      });
    }
  }

  // Basic Explorer Tree Related

  handleToggleBasicExplorerNode = props => event => {
    event.stopPropagation();
    this.handleToggleBasicExplorerNodeCallBack(props);
  };

  handleToggleBasicExplorerNodeCallBack = props => {
    let newBasicExplorerNodesExpanded = [... this.state.basicExplorerNodesExpanded];
    const indexOfNode = newBasicExplorerNodesExpanded.findIndex(
      nodeId => nodeId === props.nodeId
    );
    // Expand
    if(indexOfNode === -1)
      newBasicExplorerNodesExpanded.push(props.nodeId);
    // Collapse
    else
      newBasicExplorerNodesExpanded.splice(indexOfNode, 1);
    this.setState({
      basicExplorerNodesExpanded: newBasicExplorerNodesExpanded,
    });
  };

  handleSelectBasicExplorerNode = (event, nodeId) => {
    this.setState({
      selectedBasicExplorerNode: nodeId,
      mappingOrLinkToSpotAndHighlight: nodeId,
    }, () => {
      this.scrollToLinkOrMappingByIdCallBack({id: nodeId});
    });
  };

  // Getting XPaths for the options of the autocomplete for the value of the type arg of the generator declaration
  // props: this.state.currGeneratorProps
    getAvailableXpathsForGeneratorDeclaration = async (props) => {
    console.log('#################################### getAvailableXpathsForGeneratorDeclaration #############################');
    console.log(this.state.currGeneratorProps);
    let inputData={};
    // // Case Domain
    // if(this.state.currGeneratorProps.linkId === undefined) {
    //   inputData = {
    //     id: this.state.currMappingProject.id,
    //     type: 'source'
    //   }
    // }
    // // Case link or intermediate (source)
    // else {
    //   inputData = {
    //     id: this.state.currMappingProject.id,
    //     type: 'source',
    //     xpath: 
    //       this.state.mappings[this.state.currGeneratorProps.mappingId].domainSourceNode !== '' ? 
    //       this.state.mappings[this.state.currGeneratorProps.mappingId].domainSourceNode.item.value : 
    //       ''

    //   }
    // Case Domain
    if(props.linkId === undefined || props.linkId === -1) {
      inputData = {
        id: this.state.currMappingProject.id,
        type: 'source',
        xpath: 
          this.state.mappings[props.mappingId].domainSourceNode !== '' ? 
          this.state.mappings[props.mappingId].domainSourceNode.item.value : 
          ''
      }
    }
    // Case link or intermediate (source)
    else {
      inputData = {
        id: this.state.currMappingProject.id,
        type: 'source',
        xpath: 
          this.state.mappings[props.mappingId].domainSourceNode.item.value + '/' + this.state.mappings[props.mappingId].links[props.linkId].sourceNode !== '' ? 
          this.state.mappings[props.mappingId].domainSourceNode.item.value + '/' + this.state.mappings[props.mappingId].links[props.linkId].sourceNode.item.value : 
          ''
      }
    }
    let res = await xpathService.getXpaths(inputData);
    const xpathOptions = res.data.response.map(xpath => ({
      value: `${xpath.value}/text()`,
      label: `${xpath.value}/text()`,
    }));
    return xpathOptions
  }

  // Async / Await Article:
  // https://hackernoon.com/async-await-essentials-for-production-loops-control-flows-limits-23eb40f171bd

  render() {
    const { classes } = this.props;
    const { currMappingProject, isRedndering, titleIsEditing, doRender, } = this.state;
    const { expandedMappingIdList, expandedLinkId } = this.state;


    // This is each mapping
    let mappingList = this.state.mappingIds.map((mappingId, mappingIndex) => {
      let mapping = this.state.mappings[mappingId];

      return (
        // This is the Mapping
        <CSSTransition
          timeout={400}
          classNames="hideShowLink"
          unmountOnExit
          key={'mappingComponent_' + mappingId}
        >
          <MappingComponent
            currMappingProjectId={currMappingProject ? currMappingProject.id : ''}
            mappingIndex={mappingIndex}
            mapping={mapping}
            mappingId={mappingId}
            deletingLinkId={this.state.deletingLinkId}
            isMappingExpanded={expandedMappingIdList.includes(mappingId)}
            expandedLinkId={expandedLinkId}
            canEdit={this.state.canEdit}
            isDomainExpanded={this.state.canEdit ? expandedLinkId === mappingId : false}
            handleExpandChange={this.handleExpandChange}
            mappingIdsLength={this.state.mappingIds.length}
            handleDeleteMapping={this.handleDeleteMapping}
            handleInputChange={this.handleMappingInputChange}
            handleInputChangeButton={this.handleMappingInputChangeButton}
            handleLinkInputChange={this.handleLinkInputChange}
            handleLinkInputChangeButton={this.handleLinkInputChangeButton}
            allClasses={this.state.allClasses}
            allXpaths={this.state.allXpaths}
            handleAddLink={this.handleAddLink}
            handleDeleteLink={this.handleDeleteLink}
            handleAddIntermediate={this.handleAddIntermediate}
            handleDeleteIntermediate={this.handleDeleteIntermediate}
            handleIntermediateInputChange={this.handleIntermediateInputChange}
            handleIntermediateInputChangeButton={this.handleIntermediateInputChangeButton}
            handleAddConstExpr={this.handleAddMappingConstExpr}
            handleDeleteMappingConstExpr={this.handleDeleteMappingConstExpr}
            handleInputChangeMappingConstExpr={this.handleInputChangeMappingConstExpr}
            handleInputChangeMappingConstExprButton={this.handleInputChangeMappingConstExprButton}
            handleAddLinkConstExpr={this.handleAddLinkConstExpr}
            handleDeleteLinkConstExpr={this.handleDeleteLinkConstExpr}
            handleInputChangeLinkConstExpr={this.handleInputChangeLinkConstExpr}
            handleInputChangeLinkConstExprButton={this.handleInputChangeLinkConstExprButton}
            handleAddIntermediateConstExpr={this.handleAddIntermediateConstExpr}
            handleDeleteIntermediateConstExpr={this.handleDeleteIntermediateConstExpr}
            handleInputChangeIntermediateConstExpr={this.handleInputChangeIntermediateConstExpr}
            handleInputChangeIntermediateConstExprButton={this.handleInputChangeIntermediateConstExprButton}
            handleUseGenerator={this.handleMappingUseGenerator}
            handleLinkUseGenerator={this.handleLinkUseGenerator}
            handleTargetIntermediateUseGenerator={this.handleTargetIntermediateUseGenerator}
            handleConstExprUseGenerator={this.handleConstExprUseGenerator}
            handleGoToGeneratorDeclarationForm={this.handleGoToGeneratorDeclarationForm}
            mappingBackgroundColors={this.props.configurationSettings.mappingBackgroundColors}
            patternTypeColors={this.props.configurationSettings.patternTypeColors}
            displayFirstCommentOnHeader={this.props.configurationSettings.displayFirstCommentOnHeader}
            compactViewMode={this.props.configurationSettings.compactViewMode}
            handleUseVariable={this.handleMappingUseVariable}
            handleDirectDeleteVariable={this.handleDirectDeleteVariable}
            handleLinkUseVariable={this.handleLinkUseVariable}
            handleTargetIntermediateUseVariable={this.handleTargetIntermediateUseVariable}
            handleConstExprUseVariable={this.handleConstExprUseVariable}
            handleUseNamedgraph={this.handleMappingUseNamedgraph}
            handleUseDomainNamedgraph={this.handleDomainUseNamedgraph}
            handleLinkUseNamedgraph={this.handleLinkUseNamedgraph}
            handleUseCondition={this.handleMappingUseCondition}
            handleLinkUseCondition={this.handleLinkUseCondition}
            handleGoToConditionForm={this.handleGoToConditionForm}
            handleDirectDeleteCondition={this.handleDirectDeleteCondition}
            handleToggleShowCodeCallBack={this.handleToggleShowDomainCodeCallBack}
            isFragmentCodeChanged={this.isDomainCodeMirrorChanged}
            handleCodeMirrorInputChange={this.handleDomainCodeMirrorInputChange}
            importX3ml={this.importX3mlDomain}
            handleToggleShowLinkCodeCallBack={this.handleToggleShowLinkCodeCallBack}
            isLinkCodeMirrorChanged={this.isLinkCodeMirrorChanged}
            handleLinkCodeMirrorInputChange={this.handleLinkCodeMirrorInputChange}
            importX3mlLink={this.importX3mlLink}
            handleShowConfirmDeleteSnackbar={this.handleShowConfirmDeleteSnackbar}
            handleDirectDeleteInstanceGeneratorDeclaration={this.handleDirectDeleteInstanceGeneratorDeclaration}
            handleDirectDeleteLabelGeneratorDeclarations={this.handleDirectDeleteLabelGeneratorDeclarations}
            handleUseComment={this.handleMappingUseComment}
            handleLinkUseComment={this.handleLinkUseComment}
            handleUseInstanceInfo={this.handleMappingUseInstanceInfo}
            handleLinkUseInstanceInfo={this.handleLinkUseInstanceInfo}
            handleTargetIntermediateUseInstanceInfo={this.handleTargetIntermediateUseInstanceInfo}
            handleConstExprUseInstanceInfo={this.handleConstExprUseInstanceInfo}
            handleDirectDeleteInstanceInfo={this.handleDirectDeleteInstanceInfo}
            instanceInfoStyleBasedOnLinesRequired={this.instanceInfoStyleBasedOnLinesRequired}
            compactViewEnabled={this.state.compactViewEnabled}
            accordionFocus={this.accordionFocus}
            accordionBlur={this.accordionBlur}
            focusedObjectIdUserMap={this.state.focusedObjectIdUserMap}
            onDragDrop={this.onLinkDragDrop}
            handleCloneLink={this.handleCloneLink}
            handleCopyHyperlink={this.handleCopyHyperlink}

            // Copying link
            handleCopyLink={this.handleCopyLink}
            handlePasteLink={this.handlePasteLink}
            linkIsInClipboard={this.state.linkIsInClipboard}
            // copying mapping
            handleCopyMapping={this.handleCopyMapping}
            mappingIsInClipboard={this.state.mappingIsInClipboard}

            handleDisplayConstExpr={this.handleDisplayConstExpr}
            navigateToFirstComment={this.navigateToFirstComment}
            handleChangeIsInUseStatus={this.handleChangeIsInUseStatus}
            handleChangeIsInUseStatusOfAffectedLinks={this.handleChangeIsInUseStatusOfAffectedLinks}
            handleHoldRefRecord={this.handleHoldRefRecord}
            handleRemoveRefRecord={this.handleRemoveRefRecord}
            expandOrCollapseFinished={this.mappingExpandOrCollapseFinished}
            mappingOrLinkToSpotAndHighlight={this.state.mappingOrLinkToSpotAndHighlight}
          />
        </CSSTransition>
      );
    });

    // FileMetadata
    let sourcefileMetadataList = [];
    let targetfileMetadataList = [];
    // if(this.state.configDialogOpen && this.state.fileMetadataObjectList.sourceFiles !== undefined) {
    if(this.state.fileMetadataObjectList.sourceFiles !== undefined) {
      sourcefileMetadataList = this.state.fileMetadataObjectList.sourceFiles.map((fileMetadata, index) => {
        return (
          <ProjectFileMetadataManagement
            key={fileMetadata.id}
            currUser={this.props.currUser}
            updateAuthStatusOfCurrUser={this.props.updateAuthStatusOfCurrUser}
            fileMetadata = {fileMetadata}
            index = {index}
            type="source"
            predefinedFileMetadataList={this.state.sourcePredefinedFileMetadataList}
            handleMarkFileMetadataFieldAsInvalid={this.handleMarkFileMetadataFieldAsInvalid}
            handleMarkFileMetadataFieldAsValid={this.handleMarkFileMetadataFieldAsValid}
            handleDeleteFileMetadata={this.handleDeleteFileMetadata}
            handleFileMetadataInputChange={this.handleFileMetadataInputChange}
            handleSetFileMetadataInput={this.handleSetFileMetadataInput}
            storeFileMetadata={this.storeFileMetadata}
            handlePredefinedInputChange={this.handlePredefinedFileMetadataAutocompleteInputChange}
            toggleExpandFileMetadataNamespaceErrorMessage={this.toggleExpandFileMetadataNamespaceErrorMessage}
            handleHoldFileMetadataRefRecord={this.handleHoldFileMetadataRefRecord}
            filesManagementChanged={this.state.filesManagementChanged}
            addNamespaceInFileMetadata={this.addNamespaceInFileMetadata}
            handleRemoveFileMetadataNamespace={this.handleRemoveFileMetadataNamespace}
            handleChangeFileParticipation={this.handleChangeFileParticipation}
          />
        );
      });

      targetfileMetadataList = this.state.fileMetadataObjectList.targetFiles.map((fileMetadata, index) => {
        return (

          <ProjectFileMetadataManagement
            key={fileMetadata.id}
            currUser={this.props.currUser}
            updateAuthStatusOfCurrUser={this.props.updateAuthStatusOfCurrUser}
            fileMetadata = {fileMetadata}
            index = {index}
            type="target"
            predefinedFileMetadataList={this.state.targetPredefinedFileMetadataList}
            handleMarkFileMetadataFieldAsInvalid={this.handleMarkFileMetadataFieldAsInvalid}
            handleMarkFileMetadataFieldAsValid={this.handleMarkFileMetadataFieldAsValid}
            handleDeleteFileMetadata={this.handleDeleteFileMetadata}
            handleFileMetadataInputChange={this.handleFileMetadataInputChange}
            handleSetFileMetadataInput={this.handleSetFileMetadataInput}
            storeFileMetadata={this.storeFileMetadata}
            handlePredefinedInputChange={this.handlePredefinedFileMetadataAutocompleteInputChange}
            toggleExpandFileMetadataNamespaceErrorMessage={this.toggleExpandFileMetadataNamespaceErrorMessage}
            handleHoldFileMetadataRefRecord={this.handleHoldFileMetadataRefRecord}
            addNamespaceInFileMetadata={this.addNamespaceInFileMetadata}
            handleRemoveFileMetadataNamespace={this.handleRemoveFileMetadataNamespace}
          />
        );
      });

    }

    // Left side Panel's tree nodes
    const basicExplorerNodes = this.state.mappingIds.map((mappingId, mappingindex) => {
      
      const mapping = {... this.state.mappings[mappingId]};
      const domainSourceNodeLabel = mapping.domainSourceNode.item.label;
      const domainTargetEntityLabel = 
        mapping.domainTargetEntity.item !== '' && mapping.domainTargetEntity.item !== undefined && mapping.domainTargetEntity.item !== undefined ?
        mapping.domainTargetEntity.item.map(obj => obj.label).join(', ') :
        '';
      
      const mappingLabel = 
        <div>
          <div style={{fontWeight: 'bold'}}>
            {`Mapping #${mappingindex+1} - `}
          </div>
          <Tooltip 
            title={
              domainSourceNodeLabel !== '' && domainSourceNodeLabel !== undefined && domainSourceNodeLabel !== null ?
              domainSourceNodeLabel :
              ''
            }
            placement="right"
            enterDelay={1000}
            leaveDelay={200}
          >
            <div className={classNames(classes.treeItemLabel, classes.treeItemMappingSourceLabel,)}>
              {
                domainSourceNodeLabel !== '' && domainSourceNodeLabel !== undefined && domainSourceNodeLabel !== null ?
                domainSourceNodeLabel :
                ''
              }
            </div>
          </Tooltip>
          
          <div style={{display: 'flex', paddingLeft: 8}}>
            {
              domainTargetEntityLabel !== '' && domainTargetEntityLabel !== undefined && domainTargetEntityLabel !== null 
              || 
              domainSourceNodeLabel !== '' && domainSourceNodeLabel !== undefined && domainSourceNodeLabel !== null ?
              <ArrowRightAltIcon /> :
              ''
            }
            <Tooltip 
              title={
                mapping.domainTargetEntity.item !== '' && mapping.domainTargetEntity.item !== undefined && mapping.domainTargetEntity.item !== undefined ?
                mapping.domainTargetEntity.item.map(obj => obj.label).join(', ') :
                ''
              }
              placement="right"
              enterDelay={1000}
              leaveDelay={200}
            >
              <div className={classNames(classes.treeItemLabel, classes.treeItemMappingTargetLabel,)}>
                {
                  domainTargetEntityLabel !== '' && domainTargetEntityLabel !== undefined && domainTargetEntityLabel !== null ?
                  domainTargetEntityLabel :
                  ''
                }
              </div>
            </Tooltip>
          </div>
          <Divider />
        </div>;

      // const mappingLabel = `Mapping #${mappingindex+1} - ${domainSourceNodeLabel} --> ${domainTargetEntityLabel}`;

      return (
        <TreeItem 
          key={`basicExplorerMappingNode-${mappingId}`} 
          nodeId={mappingId} 
          label={mappingLabel}
          onIconClick={this.handleToggleBasicExplorerNode({nodeId: mappingId})}
        >
          {
            this.state.mappings[mappingId].linkIds.map((linkId, linkindex) => {

              const link = {... this.state.mappings[mappingId].links[linkId]};
              const sourceRelationLabel = link.sourceRelation.item.label;
              const targetRelationLabel = link.targetRelation.item.label;
              
              const linkLabel = 
                <div>
                  <div style={{fontWeight: 'bold'}}>
                    {`Link #${mappingindex+1}.${linkindex+1} - `}
                  </div>
                  <Tooltip 
                    title={
                      sourceRelationLabel !== '' && sourceRelationLabel !== undefined && sourceRelationLabel !== null ?
                      sourceRelationLabel :
                      ''
                    }
                    placement="right"
                    enterDelay={1000}
                    leaveDelay={200}
                  >
                    <div className={classNames(classes.treeItemLabel, classes.treeItemLinkSourceLabel,)}>
                      {
                        sourceRelationLabel !== '' && sourceRelationLabel !== undefined && sourceRelationLabel !== null ?
                        sourceRelationLabel :
                        ''
                      }
                    </div>
                  </Tooltip>
                  <div style={{display: 'flex', paddingLeft: 16,}}>
                    {
                      sourceRelationLabel !== '' && sourceRelationLabel !== undefined && sourceRelationLabel !== null 
                      || 
                      targetRelationLabel !== '' && targetRelationLabel !== undefined && targetRelationLabel !== null ?
                      <ArrowRightAltIcon /> :
                      ''
                    }
                    <Tooltip 
                      title={
                        targetRelationLabel !== '' && targetRelationLabel !== undefined && targetRelationLabel !== null ?
                        targetRelationLabel :
                        ''
                      }
                      placement="right"
                      enterDelay={1000}
                      leaveDelay={200}
                    >
                      <div className={classNames(classes.treeItemLabel, classes.treeItemLinkTargetLabel,)}>
                        {
                          targetRelationLabel !== '' && targetRelationLabel !== undefined && targetRelationLabel !== null ?
                          targetRelationLabel :
                          ''
                        }
                      </div>
                    </Tooltip>
                  </div>
                  <Divider />
                </div>;

                // const linkLabel = `Link #${mappingindex+1}.${linkindex+1}`

              return (
                <TreeItem 
                  key={`basicExplorerLinkNode-${linkId}`} 
                  nodeId={linkId} 
                  label={linkLabel}
                  onIconClick={this.handleToggleBasicExplorerNode({nodeId: linkId})}
                />
              )
            })  
          }
        </TreeItem>
      )
    })

    return (
      <div>
        {
        doRender ?
      <div>
        <Fade in={!isRedndering} timeout={{enter: 400, exit: 400}}>
          <div>
            {/* Header tools*/}
            <div style={{paddingBottom: 40, paddingTop: 20, width: '100%'}}>
              <div
                className={
                  classNames(
                    classes.statusStyle,
                    this.state.currMappingProject !== undefined && this.state.currMappingProject !== null ?
                      this.state.currMappingProject.status === 'Completed' ?
                      classes.secondaryColor :
                      '' :
                    ''
                  )
                }
              >
                {
                this.state.currMappingProject !== undefined && this.state.currMappingProject !== null ? 
                this.state.currMappingProject.status : 
                ''}
              </div>
              <div className={classes.projectTitle} style={{float: 'left', marginTop: -8}}>
                {
                  this.state.canEdit ?
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <Typography style={{fontStyle:'italic'}} variant="h6">
                        <Tooltip 
                          title="Human Readable ID (HRID)"
                          placement="bottom"
                          enterDelay={1000}
                          leaveDelay={200}
                        >
                          <span>
                            <span style={{marginRight: 5}}>HRID:</span>
                            {
                              currMappingProject ?
                              currMappingProject.hrId === null ? 'Unset' : currMappingProject.hrId :
                              ''
                            }
                            </span>
                        </Tooltip>
                      </Typography>
                      <div>
                        <Fade in={!titleIsEditing} style={{position: 'absolute'}}>
                          <Typography style={{marginLeft: 10, fontWeight: 'bold'}} variant="h6">
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <span>
                                    Mapping Project Title:
                                  </span>
                                  <br />
                                  {currMappingProject ? currMappingProject.title : 'None'}
                                </React.Fragment>
                              }
                              placement="bottom"
                              enterDelay={1000}
                              leaveDelay={200}
                            >
                              <span
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  maxWidth: 300,
                                  whiteSpace: 'nowrap',
                                  display: 'inline-block',
                                }}
                              >
                                {currMappingProject? currMappingProject.title : ''}
                              </span>
                            </Tooltip>
                            <Tooltip
                              title="Edit Project Title"
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <IconButton
                                aria-label="edit-project-title"
                                style={{padding: 5, marginTop: -19, marginLeft: 5}}
                                className={classes.margin}
                                onClick={this.handleActivateTitleEditing({errorFieldName: 'mappingProjectTitle_error_text'})}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip 
                              title="Add New Comment"
                              placement="right"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <IconButton aria-label="Comment"
                                          color="primary"
                                          onClick={this.handleUseComment}
                                          style={{marginTop: -19, marginLeft: 10}}
                                          size="small"
                              >
                                <Badge
                                  badgeContent={
                                    this.state.currMappingProject.comments !== undefined && this.state.currMappingProject.comments !== null ?
                                    this.state.currMappingProject.comments.length :
                                    0
                                  }
                                  color="secondary"
                                  classes={{
                                    badge: classes.niceBadgeStyle,
                                  }}
                                >
                                  <MessageIcon style={{fontSize: 24}}/>
                                </Badge>
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Fade>
                        <Fade in={titleIsEditing} style={{position: 'absolute'}}>
                          <div style={{marginTop: -6}}>
                            <form ref={form => this.formMapingProject = form}
                                  autoComplete="off"
                                  noValidate
                                  className={classes.formRoot}>
                              <TextField
                                value={currMappingProject? currMappingProject.title : ''}
                                required
                                style={{marginTop: 5, marginLeft: 10, fontWeight:'bold'}}
                                InputProps={{
                                  classes: {
                                    input: classes.titleTextFieldInput,
                                  },
                                }}
                                onChange={this.handleMappingProjectFieldInputChange({fieldName: 'title', required: true, errorFieldName: 'mappingProjectTitle_error_text'})}
                                onBlur={this.handleDeactivateTitleEditing({fieldName: 'title', errorFieldName: 'mappingProjectTitle_error_text'})}
                                inputRef={this.textInput}
                                error={this.state.mappingProjectTitle_error_text !== '' && this.state.mappingProjectTitle_error_text !== undefined}
                                helperText={this.state.mappingProjectTitle_error_text}
                              />
                              </form>
                          </div>
                        </Fade>
                      </div>
                    </div>
                  </div> :
                  <div
                    className={classes.projectTitle}
                    style={{
                      float: 'left',
                      marginLeft:-8,
                      marginTop: -8,
                      display: 'flex'
                    }}
                  >
                    <Typography style={{fontStyle:'italic'}} variant="h6">
                      <Tooltip title="Human Readable ID (HRID)"
                               placement="bottom"
                               enterDelay={1000}
                               leaveDelay={200}>
                        <span>
                          <span style={{marginRight: 5}}>HRID:</span>
                          {
                            currMappingProject ?
                            currMappingProject.hrId === null ? 'Unset' : currMappingProject.hrId :
                            ''
                          }
                          </span>
                      </Tooltip>
                    </Typography>
                    <Typography style={{marginLeft: 10, fontWeight: 'bold'}} variant="h6">
                      <Tooltip title="Mapping Project Title"
                               placement="bottom"
                               enterDelay={1000}
                               leaveDelay={200}>
                        <span>{currMappingProject ? currMappingProject.title : ''}</span>
                      </Tooltip>
                    </Typography>
                  </div>
                }
              </div>

              {/* Buttons,switches & dates*/}

              <div style={{padding: '8px 0px 8px 0px', float: 'right'}}>
                <div style={{display: 'flex', marginTop: -10}}>
                  {
                    this.state.canEdit ?
                    <div style={{display: 'flex', marginRight: 10}}>
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.validateSelections}
                              onChange={this.handleToggleValidateSelections}
                            />
                          }
                          label={
                            <Typography variant="body1" noWrap={true}>Validate Paths</Typography>
                          }
                        />
                      </div>
                      <div>
                        <Tooltip
                          title={
                            <React.Fragment>
                              {'When "Compact view" is enabled, information regarding variables, generators and instance infos might be hidden'}
                              <br/><br/>
                              <span style={{fontStyle:'italic'}}>
                                {'You can set what to be hidden, from your configuration settings --> "Compact View Mode"'}
                              </span>
                            </React.Fragment>
                          }
                          placement="bottom"
                          enterDelay={500}
                          leaveDelay={200}
                        >
                          <FormControlLabel
                            control={<Switch
                            checked={this.state.compactViewEnabled}
                            onChange={this.handleToggleCompactView} />}
                            label={
                              <Typography variant="body1" noWrap={true}>Compact View</Typography>
                            }
                          />
                        </Tooltip>
                      </div>
                      <div hidden={this.state.currMappingProject.author.username !== this.props.currUser.currUsername}>
                        <Tooltip
                          title={'When a project is \"Completed\", it cannot be edited by other participants anymore'}
                          placement="bottom"
                          enterDelay={500}
                          leaveDelay={200}
                        >
                          <FormControlLabel
                            control={<Switch
                            checked={this.state.currMappingProject.status.toLowerCase() === 'Completed'.toLowerCase()}
                            onChange={this.handleToggleStatus} />}
                            label={
                              <Typography variant="body1" noWrap={true}>Completed</Typography>
                            }
                          />
                        </Tooltip>
                      </div>
                      <div style={{width: 270, marginTop:-4}}>
                        <ScrollMenu
                          LeftArrow={<div style={{margin: 'auto'}}><LeftArrow /></div>}
                          RightArrow={<div style={{margin: 'auto'}}><RightArrow /></div>}
                        >
                          <ItemCard
                            itemId='sourceInputCoverage'
                            item={
                              <div>
                                <Tooltip
                                  title={
                                    this.state.currMappingProject !== undefined ?
                                    (
                                      this.state.currMappingProject.showSourceCoverage ?
                                      <span>
                                        <span>Source Input Coverage</span>
                                        <br />
                                        <span>(shown in the project list for this mapping)</span>
                                       </span> :
                                       <span>
                                         <span>Source Input Coverage</span>
                                         <br />
                                         <span>(not shown in the project list for this mapping yet)</span>
                                        </span>
                                    ) :
                                    <span>
                                      <span>Source Input Coverage</span>
                                      <br />
                                      <span>(not shown in the project list for this mapping yet)</span>
                                     </span>
                                  }
                                  placement="bottom"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <IconButton
                                    aria-label="source-input-coverage"
                                    style={{padding: 6}}
                                    onClick={this.handleOpenSourceInputCoverageDialog}
                                  >
                                    <div style={{height: 32}}>
                                      <DonutChartProgress
                                        value={
                                          this.state.currMappingProject !== undefined ?
                                          (
                                            this.state.currMappingProject.sourceInputCoverageValue !== undefined ?
                                            this.state.currMappingProject.sourceInputCoverageValue :
                                            0
                                          ) :
                                          0
                                        }
                                        size={32}
                                        thickness={4}
                                        variant="caption"
                                        fillInternalCircle={
                                          this.state.currMappingProject !== undefined ?
                                            (
                                              this.state.currMappingProject.showSourceCoverage !== undefined ?
                                              this.state.currMappingProject.showSourceCoverage :
                                              false
                                            ) :
                                            false
                                        }
                                        inputProps={{
                                          classes: {
                                            internalCircle: classes.internalCircle,
                                            circularWrapper: classes.circularWrapper,
                                            completeCoverage: classes.fullSourceInputCoverage,
                                          },
                                        }}
                                      />
                                    </div>
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                          <ItemCard
                            itemId='importFromX3mlFile'
                            item={
                              <div>
                                <Tooltip
                                  title="Import from X3ML File"
                                  placement="bottom"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <IconButton aria-label="import-from-x3ml-file"
                                              className={classes.margin}
                                              style={{padding: 6}}
                                              onClick={this.handleOpenX3mlImportDialog}>
                                    <Icon className={classNames(classes.titleToolBarAwesomeIcon, 'fa fa-file-import')}/>
                                    {/*fa fa-upload*/}
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                          <ItemCard
                            itemId='exportToZipFile'
                            item={
                              <div>
                                <Tooltip
                                  title="Export all files to Zip"
                                  placement="bottom"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <IconButton aria-label="export-to-zip-file"
                                              className={classes.margin}
                                              style={{padding: 6}}
                                              onClick={this.handleExportToZip({id: this.state.currMappingProject.id})}>
                                    <Icon className={classNames(classes.titleToolBarAwesomeIcon, 'fa fa-download')}/>
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                          <ItemCard
                            itemId='generatorDefinitionManager'
                            item={
                              <div>
                                <Tooltip
                                  title="Open Generator Definitions Manager"
                                  placement="bottom"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <IconButton aria-label="open-generator-definition-manager"
                                              className={classes.margin}
                                              style={{padding: 6}}
                                              onClick={this.handleOpenGeneratorDefinitionsManagerDialog}>
                                    <span style={{width: 32, height: 32}}>
                                      <Icon className={classNames(classes.generatorIcon, 'fas fa-link')}
                                            style={{width: 32, height: 32, marginLeft: -2, marginTop: 3, position: 'absolute'}}
                                      />
                                      <SettingsIcon style={{width: 16, height: 16, marginLeft: 14, marginBottom: -7}}/>
                                    </span>
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                          <ItemCard
                            itemId='fileMetadataConfiguration'
                            item={
                              <div>
                                <Tooltip
                                  title="Open file metadata configuration"
                                  placement="bottom"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <IconButton
                                    aria-label="open-file-metadata-configuration"
                                    className={classes.margin}
                                    style={{padding: 6}}
                                    onClick={this.handleOpenFileMetadataDialog}
                                  >
                                    <span style={{width: 32, height: 32}}>
                                      <InsertDriveFileOutlinedIcon
                                        style={{
                                          width: 32,
                                          height: 32,
                                          marginLeft: -8,
                                          marginTop: 1,
                                          position: 'absolute'
                                        }}
                                      />
                                      <SettingsIcon style={{width: 16, height: 16}}/>
                                    </span>
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                          <ItemCard
                            itemId='participantUsers'
                            item={
                              <div>
                                <Tooltip
                                  title="Define participating users"
                                  placement="bottom"
                                  enterDelay={500}
                                  leaveDelay={200}
                                >
                                  <IconButton
                                    aria-label="define-participating-users"
                                    className={classes.margin}
                                    style={{padding: 6}}
                                    onClick={this.handleOpenParticipatingUsersDialog}
                                  >
                                    <span style={{height: 32}}>
                                      <Icon className={classNames(classes.participatingUsersIcon, 'fas fa-users')}/>
                                    </span>
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                        </ScrollMenu>
                      </div>
                    </div> :
                    <div style={{ height: 56, marginTop:-6}} />
                  }
                  <div style={{display:'flex', flexDirection:'column', marginTop: -2, textAlign: 'right'}}>
                    <Typography variant="body2" style={{fontStyle:'italic'}}>
                      Created on: {
                        currMappingProject ?
                        new Date(currMappingProject.creationDate).toLocaleDateString()  +
                        ' ' +
                        new Date(currMappingProject.creationDate).toLocaleTimeString()
                        : ''}
                    </Typography>
                    <Typography variant="body2" style={{fontStyle:'italic'}}>
                      Modified on: {
                        currMappingProject ?
                        new Date(currMappingProject.modificationDate).toLocaleDateString() +
                        ' ' +
                        new Date(currMappingProject.modificationDate).toLocaleTimeString()
                        : ''}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div style={{width: '100%', overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 240px)'}}>
              {/* Description of the Mapping Project*/}
              <div 
                style={{display: 'flex'}}
                className={
                  clsx(
                    { 
                      [classes.descriptionPanelShiftedRight]: (this.state.leftSidePanelIsOpen && this.state.mappingProjectDescriptionExpanded),
                      [classes.descriptionPanelNotShiftedRight]: !(this.state.leftSidePanelIsOpen && this.state.mappingProjectDescriptionExpanded),
                    }
                  )
                }
              >
                <div 
                  style={{display: 'flex', width: '100%', marginTop:-14, flexGrow: 1}}
                >
                  <div style={{overflow: 'hidden', marginRight: -6, marginLeft: -6, minWidth: 340}}>
                    <Accordion
                      expanded={this.state.mappingProjectDescriptionExpanded}
                      onChange={this.handleToggleMappingProjectDescrptionExpand}
                      TransitionProps={{ unmountOnExit: true }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{content: classes.accordionContentNotOverflow}}
                      >
                        <Fade in={!this.state.mappingProjectDescriptionExpanded}>
                          <div style={{overflow: 'hidden'}}>
                            <Typography
                              noWrap={true}
                              style={{
                                marginRight: 10,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {
                                currMappingProject ?
                                (
                                  currMappingProject.description !== undefined ?
                                  (
                                    currMappingProject.description.toString().length === 0 ?
                                    <i>There is not any description entered for this mapping project yet</i> :
                                    currMappingProject.description
                                  ) :
                                  <i>There is not any description entered for this mapping project yet</i>
                                ) :
                                ''
                              }
                            </Typography>
                          </div>
                        </Fade>
                        <Fade in={this.state.mappingProjectDescriptionExpanded}>
                          <div style={{position: 'absolute', marginTop:4}}>{/*marginTop:-8 */}
                            <Typography
                              noWrap={true}
                              style={{
                                marginRight: 10,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                fontWeight: 'bold'
                              }}
                            >
                              Mapping Project's Description:
                            </Typography>
                          </div>
                        </Fade>
                        <Grow
                          in={this.state.mappingProjectDescriptionExpanded && this.state.canEdit}
                          timeout={{
                            appear: 1000,
                            enter: 1000,
                            exit: 300,
                          }}
                        >
                          <div style={{position: 'absolute',left: 262}}>{/*marginTop:-12,  */}
                            <Tooltip
                              title="Edit Mapping Project's Description"
                              placement="right"
                            >
                              <IconButton
                                color="secondary"
                                size="small"
                                onClick={this.handleEnableEditMappingProjectDescription}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Grow>
                      </AccordionSummary>
                      <AccordionDetails style={{marginTop: -24}}>
                        {
                          !this.state.showEditMappingProjectDescription ?
                            currMappingProject ?
                            (
                              currMappingProject.description !== undefined ?
                              (
                                currMappingProject.description.toString().length === 0 ?
                                <i>There is not any description entered for this mapping project yet</i> :
                                <TextField
                                  value={currMappingProject.description}
                                  variant="outlined"
                                  multiline
                                  maxRows="6"
                                  disabled={true}
                                  inputProps={{
                                    className: classes.typographyLikeInputReadOnly,
                                    readOnly: true,
                                  }}
                                  InputProps={{
                                    classes: {
                                      disabled: classes.textAreaReadOnlyInputDisabled,
                                    }
                                  }}
                                  style={{
                                    width: '100%',
                                    color: 'rgba(0, 0, 0, 0.87)'
                                  }}
                                />
                              ) :
                              <i>There is not any description entered for this mapping project yet</i>
                            ) :
                            '' :
                          ''
                        }

                        {
                          this.state.showEditMappingProjectDescription ?
                          <TextField
                            label="Plese enter or edit the description below"
                            value={currMappingProject ? currMappingProject.description : ''}
                            variant="outlined"
                            multiline
                            maxRows="6"
                            inputProps={{className: classes.typographyLikeInput}}
                            style={{
                              width: '100%',
                            }}
                            onChange={this.handleMappingProjectTextInputChange('description')}
                            onBlur={this.handleDisableEditMappingProjectDescription}
                            inputRef={this.mappingProjectDescriptionTextInput}
                          /> :
                          ''
                        }
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div  style={{width: '100%', display: 'flex', marginTop: 3}}>
                <div style={{position: 'relative'}}>
                <div
                  className={
                    clsx(
                      classes.leftSidePanelStyle, 
                      { 
                        [classes.leftSidePanelStyleClosed]: !this.state.leftSidePanelIsOpen ,
                      }
                    )
                  }
                  variant="persistent"
                  anchor="left"
                  open={this.state.leftSidePanelIsOpen}
                >
                  <div>
                    <Paper className={classes.leftSidePanelContent}>
                      <Fade in={this.state.leftSidePanelIsOpen}>
                        <div>
                          <div className={classes.leftSidePanelHeader}>
                            <Typography style={{flexGrow: 1, fontWeight: 'bold'}}>Basic Explorer</Typography>
                            <IconButton size="small" onClick={() => this.setState({leftSidePanelIsOpen: false})}>
                              {this.state.leftSidePanelIsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                          </div>
                          <Divider />
                          <div style={{overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 359px)'}}>
                            <TreeView
                              style={{
                                flexGrow: 1,
                                maxWidth: leftSidePanelWidth,
                              }}
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              defaultExpandIcon={<ChevronRightIcon />}
                              expanded={this.state.basicExplorerNodesExpanded}
                              selected={this.state.selectedBasicExplorerNode}
                              // onNodeToggle={this.handleToggleBasicExplorerNode}
                              onNodeSelect={this.handleSelectBasicExplorerNode}
                            >
                              {basicExplorerNodes}
                            </TreeView>
                          </div>
                        </div>
                      </Fade>
                    </Paper>
                  </div>
                </div>
                </div>
                <Paper 
                  // className={classes.mainWorkBenchContent}
                  className={
                    clsx(
                      classes.mainWorkBenchContent, 
                      { 
                        [classes.mainWorkBenchContentShiftedRight]: this.state.leftSidePanelIsOpen,
                        [classes.mainWorkBenchContentNotShiftedRight]: !this.state.leftSidePanelIsOpen,
                      }
                    )
                  }
                >
                  <div style={{display: 'flex'}}>
                    <div className={clsx(classes.hideShowButtonStyle, { [classes.hideShowButtonStyleHidden]: this.state.leftSidePanelIsOpen })}>
                      <Fade in={!this.state.leftSidePanelIsOpen}>
                        <Tooltip
                          title="Show basic explorer"
                          placement="top-start"
                          enterDelay={500}
                          leaveDelay={200}
                        >
                          <div>
                            <IconButton 
                              size="small" 
                              onClick={() => this.setState({leftSidePanelIsOpen: true})} 
                              color="primary" 
                              aria-label="show-left-side-panel" 
                              component="span"
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </Fade>
                    </div>
                    <div style={{paddingLeft: 15}}>
                      <FormControlLabel
                        value="Expand all mappings"
                        control={
                          <Checkbox
                            checked={this.state.mappingIds.length === this.state.expandedMappingIdList.length}
                            onChange={this.handleChangeExpandAllMappings}
                            indeterminate={
                              this.state.expandedMappingIdList.length > 0 ?
                              this.state.expandedMappingIdList.length < this.state.mappingIds.length :
                              false
                            }
                            color="primary"
                          />
                        }
                        label="Expand all mappings"
                        labelPlacement="end"
                      />
                    </div>

                    <div style={{paddingLeft: 5, margin: 'auto', marginLeft: 'unset', marginRight: 'unset'}}>
                      <Tooltip
                        title={'Paste copied mapping'}
                        placement="top-start"
                        enterDelay={500}
                        leaveDelay={200}
                      >
                        <div>
                          <IconButton
                            style={{ padding: 5, top: -2, width: 30, height: 30 }} // Adjust padding and set a fixed width and height for circular shape
                            disabled={!this.state.mappingIsInClipboard}
                            color="primary"
                            aria-label="paste-copied-mapping"
                            onClick={this.handlePasteMapping({})}
                          >
                            <MappingCopyPasteComplexIcon/>
                            {/* <Icon className={classNames(classes.fontAwesomeIcon, 'fas fa-paste')} style={{fontSize: 16}}/> */}
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <div ref={this.mappingListRef}>
                    <form ref={form => this.formEl = form}
                          autoComplete="off"
                          noValidate>
                      <List style={{paddingTop: 0, paddingBottom: 0}}>
                        <TransitionGroup>
                          {mappingList}
                        </TransitionGroup>
                      </List>
                    </form>
                  </div>
                </Paper>
              </div>
            </div>
            
            <div>
              <AppBar position="static" color="transparent" style={{marginTop: 10}}>
                <Toolbar>
                  <Tooltip
                    title="Validates paths set in the relations, nodes, and entities of the source and target parts (unselected mappings or links are also validated)"
                    placement="top-start"
                  >
                    <Button
                      color="primary"
                      size="small"
                      className={classes.button}
                      onClick={this.handleValidateSelectedValuesOfEverything}
                    >
                      Validate Paths
                    </Button>
                  </Tooltip>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={this.convertAndsaveX3ml({showDialog: true})}
                  >
                    Produce X3ML
                  </Button>
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                  >
                    <Button onClick={this.confirmProduceOutputButtonGroupHandleClick}>
                      {'Produce ' + produceOutputButtonGroupOptions[this.state.produceOutputButtonGroupSelectedIndex].label}
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      onClick={this.produceOutputButtonGroupHandleOpen}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    style={{width: 184}}
                    open={Boolean(this.state.produceOutputButtonGroupAnchorRef)}
                    anchorEl={
                      this.state.produceOutputButtonGroupAnchorRef
                    }
                    role={undefined}
                    transition
                    disablePortal
                    placement="bottom-end"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.produceOutputButtonGroupHandleClose}>
                            <MenuList id="split-button-menu">
                              {produceOutputButtonGroupOptions.map((option, index) => (
                                <Tooltip
                                  key={'Tooltip-' + option.value}
                                  title={
                                    option.value !== 'trig' && this.state.namedgraphHistory.length > 0 ?
                                    option.label + ' is not an available option, when namedgraph is used in the mapping project.' :
                                    ''
                                  }
                                  placement="right"
                                >
                                  <div key={'div-' + option.value}>
                                    <MenuItem
                                      key={option.value}
                                      selected={index === this.state.produceOutputButtonGroupSelectedIndex}
                                      onClick={(event) => this.produceOutputButtonGroupHandleMenuItemClick(event, index)}
                                      disabled={option.value !== 'trig' && this.state.namedgraphHistory.length > 0}
                                    >
                                      {'Produce ' + option.label}
                                    </MenuItem>
                                  </div>
                                </Tooltip>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  {/* View Output in RDF Visualizer */}
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={this.convertToX3mlAndTransformWithX3mlEngineAndVisualise}
                  >
                    View In The RDF Visualiser
                  </Button>
                  
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={this.printState}
                  >
                    printState
                  </Button>

                  <div style={{display: 'flex', justifyContent: 'flex-end', flexGrow: 1, marginRight: -24}}>
                    <div className={classes.footerLogoStyle}>
                      <a href="https://www.ics.forth.gr/isl/centre-cultural-informatics" target="_blank">
                        <img src={organisationLogo} alt="organisation-logo" style={{height: 36}} onLoad={this.onOrganisationLogoLoad}/>
                      </a>
                    </div>
                  </div>
                </Toolbar>
              </AppBar>
            </div>
            {
              this.state.canEdit ?
              <div>
                <Tooltip title="Add New Mapping"
                         placement="top-start"
                         enterDelay={500}
                         leaveDelay={200}>
                  <Fab className={classNames(classes.fab, classes.onTop)} color="primary"
                       onClick={this.handleAddMapping}>
                    <AddIcon/>
                  </Fab>
                </Tooltip>
              </div> :
              ''
            }
          </div>
        </Fade>
        <Dialog open={this.state.loadingModalOpen}
                aria-labelledby="modal"
                TransitionComponent={Fade}
                transitionDuration={{enter: 400, exit: 400}}>

          <DialogContent style={{width: 500}}>

            <Grid container spacing={0} style={{marginTop: 10}}>
              <Grid item style={{width: 420}}>
                <DialogContentText style={{marginBottom: 0}}>
                  Rendering the UI for the constructed mapping tree.
                </DialogContentText>
              </Grid>
              <Grid style={{width: 24}} hidden={isRedndering}>
                <Fade in={!isRedndering} timeout={{enter: 400, exit: 400}}>
                  <DialogContentText style={{marginBottom: 0}}>
                    <CheckCircleIcon style={{color: '#3fbd00', marginBottom: -7}}/>
                    <br/>
                    </DialogContentText>
                </Fade>
              </Grid>
              <Grid item style={{width: 420, minHeight:0}} hidden={isRedndering}>
                <Fade in={!isRedndering} timeout={{enter: 400, exit: 400}}>
                  <DialogContentText style={{marginBottom: 0}}>
                    {this.state.loadingSourceMessage}
                  </DialogContentText>
                </Fade>
              </Grid>
              <Grid style={{width: 24, minHeight:0}} hidden={isRedndering}>
                <Fade in={!this.state.sourceLoading && !isRedndering} timeout={{enter: 400, exit: 400}}>
                  <DialogContentText style={{marginBottom: 0}}>
                    {
                      currMappingProject !== undefined && currMappingProject !== null ?
                      (
                        currMappingProject.sourceInputs !== null ?
                        (
                          currMappingProject.sourceInputs.length > 0 ?
                          <CheckCircleIcon style={{color: '#3fbd00', marginBottom: -7}}/> :
                          <CancelIcon style={{color: '#f50057', marginBottom: -7}}/>
                        ) :
                        ''
                      ) :
                      ''
                    }
                    <br/>
                    </DialogContentText>
                </Fade>
              </Grid>
              <Grid item style={{width: 420, minHeight:0}} hidden={isRedndering}>
                <Fade in={!isRedndering} timeout={{enter: 400, exit: 400}}>
                  <DialogContentText style={{marginBottom: 0}}>
                    {this.state.loadingTargetMessage}
                  </DialogContentText>
                </Fade>
              </Grid>
              <Grid style={{width: 24, minHeight:0}}>
                <Fade in={!this.state.targetLoading && !isRedndering} timeout={{enter: 400, exit: 400}}>
                  <DialogContentText>
                    {
                      currMappingProject !== undefined && currMappingProject !== null ?
                      (
                        currMappingProject.targetSchemata !== null ?
                        (
                          currMappingProject.targetSchemata.length > 0 ?
                          <CheckCircleIcon style={{color: '#3fbd00', marginBottom: -7}}/> :
                          <CancelIcon style={{color: '#f50057', marginBottom: -7}}/>
                        ) :
                        ''
                      ) :
                      ''
                    }
                    <br/>
                  </DialogContentText>
                </Fade>
              </Grid>
              <Grid item xs={12}>
                <Fade in={this.state.sourceLoading || this.state.targetLoading || isRedndering} timeout={{enter: 400, exit: 400}}>
                  <DialogContentText style={{position: 'absolute'}}>Please wait ...</DialogContentText>
                </Fade>
                <Fade in={!this.state.sourceLoading && !this.state.targetLoading && !isRedndering} timeout={{enter: 400, exit: 400}}>
                  <DialogContentText style={{position: 'absolute'}}>Models have been successfully loaded</DialogContentText>
                </Fade>
                <div style={{textAlign: 'center'}}>
                  <Fade in={this.state.sourceLoading || this.state.targetLoading || isRedndering} timeout={{enter: 300, exit: 300}}>
                    <CircularProgress />
                  </Fade>
                </div>
              </Grid>
            </Grid>

          </DialogContent>
        </Dialog>
        {/*
        Files' Metadata Dialog
        */}
        <Dialog
          open={!this.state.loadingModalOpen && this.state.configDialogOpen}
          aria-labelledby="modal"
          TransitionComponent={Fade}
          transitionDuration={{enter: 400, exit: 400}}
          maxWidth="xl"
          fullScreen={this.state.configDialogFullScreen}
          PaperProps={{
            classes: {
              root: classes.dialogCustomPaper,
            }
          }}
          onKeyUp={(e) => {
            const ENTER = 13;
            if (e.keyCode === ENTER) {
              this.handleCloseFileMetadataDialog();
            }
          }}
        >
          <DialogTitle style={{paddingBottom: 0}}>
            Files' Metadata
            <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
              <Tooltip title={this.state.configDialogFullScreen ? 'Minimize' : 'Maximize'}
                       placement="bottom"
                       enterDelay={500}
                       leaveDelay={200}>
                <IconButton className={classes.button}
                            aria-label="maximize"
                            onClick={this.handleConfigToggleFullScreen}
                            style={{marginRight: -10}}>
                  <FilterNoneIcon hidden={!this.state.configDialogFullScreen} style={{width: 20}}/>
                  <CropSquareIcon hidden={this.state.configDialogFullScreen}/>
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Save & Close"
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  className={classes.button}
                  aria-label="close"
                  onClick={this.handleCloseFileMetadataDialog}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Divider style={{marginTop: 15, marginLeft: -24, marginRight: -24}}/>

          </DialogTitle>
          <div>
            <LinearProgress hidden={!this.state.sourceLoading && !this.state.targetLoading} style={{marginTop: -4}}/>
          </div>
          {/*Like Backdrop*/}
          <div hidden={!this.state.sourceLoading && !this.state.targetLoading && !this.state.showFileMetadataDialogBackDrop}
               style={{zIndex: 11,
                       backgroundColor: 'rgba(0, 0, 0, 0.5)',
                       width: '100%',
                       height: '100%',
                       position: 'absolute',
                       marginTop: 68}}
          />
          <DialogContent ref={fileMetadataDialogRef => this.fileMetadataDialogRef = fileMetadataDialogRef} style={{scrollBehavior: 'smooth'}}>
            <Typography hidden={sourcefileMetadataList.length > 0 || targetfileMetadataList.length > 0}
                        style={{fontStyle:'italic', color: '#f50057', paddingBottom: 20}}
                        variant="body1">
              Please note that there are no "Source" or "Target Schema" files loaded.
            </Typography>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex', flexDirection: 'column', width:'100%'}}>
                <DialogTitle style={{padding: 'unset', marginLeft: 20}}>Source Input Files</DialogTitle>
                {sourcefileMetadataList}
                <Typography hidden={sourcefileMetadataList.length > 0}
                            variant="body1"
                            style={{fontStyle:'italic', paddingTop: 20, paddingLeft:10, height: 110}}>
                  Please add a "Source" file
                </Typography>
                <div style={{height: 10}}/>
                <div style={{zIndex: 10, paddingRight: 56}} align="right">
                  <Tooltip title="Add new source file"
                           placement="bottom"
                           enterDelay={500}
                           leaveDelay={200}>
                    <Fab style={{position: 'absolute', bottom: 48}}
                         color="primary"
                         aria-label="handle-add-file-metadata-source"
                         onClick={this.handleAddFileMetadata({type: 'source'})}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', width:'100%'}}>
                <DialogTitle style={{padding: 'unset', marginLeft: 20}}>Target Schema Files</DialogTitle>
                {targetfileMetadataList}
                <Typography hidden={targetfileMetadataList.length > 0}
                            variant="body1"
                            style={{fontStyle:'italic', paddingTop: 20, paddingLeft:10, height: 110}}>
                  Please add a "Target Schema" file
                </Typography>
                <div style={{height: 10}}/>
                <div style={{zIndex: 10, paddingRight: 56}} align="right">
                  <Tooltip title="Add new target file"
                           placement="bottom"
                           enterDelay={500}
                           leaveDelay={200}>
                    <Fab style={{position: 'absolute', bottom: 48}}
                         color="primary"
                         aria-label="handle-add-file-metadata-target"
                         onClick={this.handleAddFileMetadata({type: 'target'})}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{display: 'unset', textAlign: 'right', paddingRight: 15}}>
            <Button
              onClick={this.handleCloseFileMetadataDialog}
              color={!this.state.fileMetadataFormHasErrors ? 'primary' : 'secondary'}
            >
              Save & Close
            </Button>
          </DialogActions>
        </Dialog>

        <GeneratorDefinitionsManagerDialog
          user={this.props.user}
          dialogOpen={this.state.generatorDefinitionsManagerDialogOpen}
          fullScreen={this.state.generatorDefinitionsManagerDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveGeneratorDefinitionsManagerDialog}
          onToggleFullScreen={this.handleGeneratorDefinitionsManagerToggleFullScreen}
          saveNow={this.state.generatorDefinitionsManagerChanged}
          shakeNow={
            this.state.confirmCloseGeneratorDefinitionsManagerSnackbarShown ||
            this.state.confirmGoToMainGeneratorDefinitionsManagerSnackbarShown ||
            this.state.confirmGoToAddNewGeneratorDefinitionsManagerSnackbarShown ||
            this.state.confirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbarShown
          }
          currDefinition={this.state.currManagerGeneratorDefinition}
          generatorDefinitions={
            this.state.currMappingProject !== undefined && currMappingProject !== null ?
            (
              this.state.currMappingProject.generatorDefinitions !== undefined ?
              this.state.currMappingProject.generatorDefinitions :
              []
            ) :
            []
          }
          preloadedGeneratorDefinitions={this.state.preloadedGeneratorDefinitions}
          selectFromImportViewEnabled={this.state.selectFromImportViewEnabled}
          toBeImportedGeneratorDfinitions={this.state.toBeImportedGeneratorDfinitions}
          prefixNamespaceMap={this.state.prefixNamespaceMap}
          definitionListEditModeEnabled={this.state.definitionListEditModeEnabled}
          selectedDefinitionListForEditMode={this.state.selectedDefinitionListForEditMode}
          selectedPreloadedDefinitionListForEditMode={this.state.selectedPreloadedDefinitionListForEditMode}
          generatorPolicyFileImportShown={this.state.generatorPolicyFileImportShown}
          selectDefinition={this.handleCheckWhetherToSelectOtherGeneratorDefinitionManagerDialog}
          handleInputChange={this.handleManagerGeneratorDefinitionInputChange}
          handleArgInputChange={this.handleManagerGeneratorDefinitionArgInputChange}
          handleArgHoverDelete={this.handleCustomGeneratorDefinitionManagerArgHoverDelete}
          handleDeleteArg={this.handleDeleteCustomGeneratorDefinitionManagerArg}
          handleSaveGeneratorDefinition={this.handleSaveManagerGeneratorDefinition}
          handleSaveGeneratorDefinitionCallBack={this.handleSaveGeneratorDefinitionCallBack}
          selectDefinitionForEditMode={this.selectDefinitionForEditMode}
          deleteSelectedGeneratorDefinitions={this.handleDeleteSelectedManagerGeneratorDefinitions}
          deleteSelectedPreloadedGeneratorDefinitions={this.handleDeleteSelectedPreloadedManagerGeneratorDefinitions}
          handleInputChangeDefinitionListEditMode={this.handleInputChangeDefinitionListEditMode}
          theSystemIsUpdatingGeneratorDeclarations={this.state.theSystemIsUpdatingGeneratorDeclarations}
          handleSelectToAddToLocalList={this.handleCheckWhetherToSelectPredefinedGeneratorDefinitionToAddToLocalListManager}
          doOrUndoGeneratorDefinitionPreloaded={this.doOrUndoGeneratorDefinitionPreloaded}
          handleAddEmptyCustomArgToGeneratorDefinition={this.handleAddEmptyCustomArgToGeneratorDefinitionManager}
          argHoveredIndex={this.state.currManagerGeneratorDefinitionArgHoveredIndex}
          exportLocalDefinitions={this.handleGenerateAndDownloadGeneratorPolicyFile}
          handleGeneratorPolicyFileValidation={this.handleGeneratorPolicyFileValidation}
          handleAfterGeneratorPolicyImportFileUpload={this.handleAfterGeneratorPolicyImportFileUpload}
          showGeneratorPolicyImport={this.showGeneratorPolicyImport}
          hideGeneratorPolicyImport={this.hideGeneratorPolicyImport}
          disableSelectFromImportView={this.disableSelectFromImportView}
          loadGeneratorDefinitions={this.loadGeneratorDefinitions}
        />

        {/*
        X3ML Import Dialog
        */}
        <Dialog
          open={this.state.x3mlImportDialogOpen}
          aria-labelledby="modal"
          TransitionComponent={Fade}
          transitionDuration={{enter: 400, exit: 400}}
          maxWidth="xl"
          fullScreen={this.state.x3mlImportDialogFullScreen}
          PaperProps={{
            classes: {
              root: classes.dialogCustomPaper,
            }
          }}
        >
          <DialogTitle style={{paddingBottom: 0}}>
            Import X3ML File
            <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
              <Tooltip
                title={this.state.x3mlImportDialogFullScreen ? 'Minimize' : 'Maximize'}
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  className={classes.button}
                  aria-label="maximize"
                  onClick={this.handleX3mlImportToggleFullScreen}
                  style={{marginRight: -10}}
                >
                  <FilterNoneIcon hidden={!this.state.x3mlImportDialogFullScreen} style={{width: 20}}/>
                  <CropSquareIcon hidden={this.state.x3mlImportDialogFullScreen}/>
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Close"
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  className={classes.button}
                  aria-label="close"
                  onClick={this.handleCloseX3mlImportDialog}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Divider style={{marginTop: 15, marginLeft: -24, marginRight: -24}}/>
          </DialogTitle>
          <DialogContent>
            <Typography
              style={{fontStyle:'italic', color: '#f50057', paddingBottom: 20}}
              variant="body1"
            >
              Please note that the source and target input files should be managed by the
              <Button
                color="primary"
                className={classes.button}
                onClick={this.handleOpenFileMetadataDialogAndCloseX3mlImportDialog}
              >
                File's Metadata
              </Button>
              section.
            </Typography>
            <div style={{minWidth: 300}}>
              <FilePond
                name="file"
                labelIdle={'<div class="filePondCustomLabelStyle">Drag & Drop the X3ML file to import here or <span class="filepond--label-action"> browse for it </div>'}
                ref={ref => this.pond = ref}
                server={
                  {
                    url: (dirRootContextPath === '' ? '.' : '') + dirRootContextPath + '/uploadFilepond/x3mlImported',
                    process: {
                      headers: {
                        Authorization: Cookies.get('3mToken')
                      },
                    },
                    revert: {
                      headers: {
                        Authorization: Cookies.get('3mToken')
                      },
                      method: 'DELETE'
                    }
                  }
                }
                allowMultiple={false}
                allowRevert={false}
                allowReplace={true}
                acceptedFileTypes={['.x3ml', 'text/xml']}
                fileValidateTypeDetectType={this.handleX3mlFileValidation}
                labelFileProcessingError={this.handleX3mlImportFileProcessingError}
                onprocessfile={this.handleAfterX3mlImportFileUpload}
                className={classes.x3mlImportFilePondRoot}
                credits="false"
              />
            </div>
          </DialogContent>
          <DialogActions style={{display: 'unset', textAlign: 'right', paddingRight: 15}}>
            <Button onClick={this.handleCloseX3mlImportDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/*
          CodeMirror
        */}
        <CodemirrorDialog
          dialogOpen={this.state.xmlDialogOpen}
          codeMirrorMode={
            this.state.downloadFileType === 'x3mlOutput' ?
            'application/xml' :
            produceOutputButtonGroupOptions[this.state.produceOutputButtonGroupSelectedIndex].codeMirrorMode
          }
          fullScreen={this.state.xmlDialogFullScreen}
          input={this.state.xmlDialogInput}
          downloadable={this.state.xmlDownloadable}
          onClose={this.handleCloseRdfDialog}
          dialogTitle={this.state.xmlCodeMirrorTitle}
          editable={this.state.isCodeMirrorEditable}
          onToggleFullScreen={this.handleRdfDialogToggleFullScreen}
          inputChange={this.handleCodeMirrorInputChange}
          inputState={this.state.codeMirorInputState}
          handleSave={this.handleShowConfirmationForApplyingWholeX3MLCodeChanges}
          relativePath={
            this.state.currMappingProject !== undefined ?
            (
              this.state.downloadFileType !== undefined ?
              (
                this.state.downloadFileType === 'x3mlOutput' ?
                this.state.currMappingProject.x3mlOutputRelativePath :
                //this.state.currMappingProject.rdfOutputRelativePath
                this.state.currMappingProject[produceOutputButtonGroupOptions[this.state.produceOutputButtonGroupSelectedIndex].value + 'OutputRelativePath']

              ) :
              null
            ) :
            null
          }
          saveNow={this.state.wholeX3MLCodeChanged}
          shakeNow={this.state.confirmCloseWholeX3MLCodeSnackbarShown}
        />

        {/*RDF Visualizer*/}
        <RDFVisualizerInputDialog
          dialogOpen={this.state.rdfVisualizerInputDialogOpen}
          fullScreen={this.state.rdfVisualizerInputFullScreen}
          input={this.state.xmlDialogInput}
          downloadable={this.state.xmlDownloadable}
          onClose={this.handleCloserdfVisualizerInputDialog}
          dialogTitle={this.state.xmlCodeMirrorTitle}
          editable={false}
          onToggleFullScreen={this.handleRdfVisualizerInputDialogToggleFullScreen}
          relativePath={
            this.state.currMappingProject !== undefined && currMappingProject !== null ?
            (
              this.state.currMappingProject.ttlOutputRelativePath !== undefined ?
              this.state.currMappingProject.ttlOutputRelativePath :
              null
            ) :
            null
          }
          loadInstancesOfOutputClassCallBack={this.loadInstancesOfOutputClassCallBack}
          handleOpenInRdfVisualizer={this.handleOpenInRdfVisualizer}
          outputClasses={this.state.outputClasses}
          instancesOfSelectedOutputClass={this.state.instancesOfSelectedOutputClass}
        />

        {/*
        Generator
        */}
        <GeneratorDialog 
          dialogOpen={this.state.generatorDialogOpen}
          areGeneratorTabsDisabled={this.state.areGeneratorTabsDisabled}
          fullScreen={this.state.generatorDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveGeneratorDialog}
          onToggleFullScreen={this.handleGeneratorDialogToggleFullScreen}
          instanceGeneratorDefinitions={this.state.instanceGeneratorDefinitions}
          labelGeneratorDefinitions={this.state.labelGeneratorDefinitions}
          generatorProps={this.state.currGeneratorProps}
          currInstanceGeneratorDeclaration={this.state.currInstanceGeneratorDeclaration}
          labelGeneratorDeclarations={this.state.currLabelGeneratorDeclarations}
          currLabelGeneratorDeclaration={this.state.currLabelGeneratorDeclaration}
          instanceGeneratorDefinitionSelected={this.state.currInstanceGeneratorDefinitionSelected}
          labelGeneratorDefinitionSelected={this.state.currLabelGeneratorDefinitionSelected}
          handleUpdateNamespaceEveryWhere={this.handleUpdateNamespaceEveryWhere}
          handleSaveInstanceGeneratorDeclaration={this.handleSaveInstanceGeneratorDeclaration}
          handleSaveLabelGeneratorDeclaration={this.handleSaveLabelGeneratorDeclaration}
          handleDeleteLabelGeneratorDeclarations={this.handleDeleteLabelGeneratorDeclarations}
          selectDefinitionGenerator={this.selectDefinitionGenerator}
          selectLabelGeneratorDeclaration={this.selectLabelGeneratorDeclaration}
          resetLabelGeneratorDeclaration={this.resetLabelGeneratorDeclaration}
          handleArgChange={this.handleArgChange}
          handleValidateDeclarationForm={this.handleValidateDeclarationForm}
          showErrorSnackBar={this.props.showErrorSnackBar}
          tabValue={this.state.generatorTabValue}
          tabIndex={this.state.generatorTabIndex}
          showLabelGeneratorForm={this.showLabelGeneratorForm}
          handleGeneratorTabChange={this.handleGeneratorTabChange}
          handleChangeGeneratorTabIndex={this.handleChangeGeneratorTabIndex}
          handleDefinitionFieldChange={this.handleDefinitionFieldChange}
          handleDefinitionFieldChangeCallBack={this.handleDefinitionFieldChangeCallBack}
          patternTypeColors={this.props.configurationSettings.patternTypeColors}
          saveNow={this.state.generatorDeclarationChanged || this.state.generatorDefinitionChanged}
          shakeNow={this.state.confirmCloseGeneratorSnackbarShown || this.state.confirmGoBackGeneratorSnackbarShown || this.state.confirmChangeTabGeneratorSnackbarShown}
          showFixMissingDefinitionErrorView={this.state.showFixMissingDefinitionErrorView}
          handleToggleFixMissingDefinitionErrorView={this.handleToggleFixMissingDefinitionErrorView}
          handleSetGeneratorDefinitionforDeclarationsOfName={this.handleSetGeneratorDefinitionforDeclarationsOfName}
          theSystemIsUpdatingGeneratorDeclarations={this.state.theSystemIsUpdatingGeneratorDeclarations}
          xpaths={this.state.generatorDialogXpaths}
          // currMappingProjectId={currMappingProject ? currMappingProject.id : ''}
          // domainSourceNodeItemValue={this.state.mappings[this.state.currGeneratorProps.mappingId].domainSourceNode !== '' ? this.state.mappings[this.state.currGeneratorProps.mappingId].domainSourceNode.item.value : ''}
        />

        {/*
        Namedgraph
        */}
        <NamedgraphDialog
          dialogOpen={this.state.namedgraphDialogOpen}
          fullScreen={this.state.namedgraphDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveNamedgraphDialog}
          onToggleFullScreen={this.handleNamedgraphDialogToggleFullScreen}
          namedgraphProps={this.state.currNamedgraphProps}
          namedgraph={this.state.currNamedgraph}
          handleSaveNamedgraph={this.handleSaveNamedgraph}
          handleSaveNamedgraphCallBack={this.handleSaveNamedgraphCallBack}
          namedgraphHistory={this.state.namedgraphHistory}
          saveNow={this.state.namedgraphChanged}
          shakeNow={this.state.confirmCloseNamedgraphSnackbarShown}
          handleInputSelectChange={this.handleInputNamedgraphSelectChange}
          handleInputChange={this.handleInputNamedgraphChange}
          handleBlur={this.handleBlurNamedgraph}
        />

        {/*
        Native Dialog
        */}
        <NativeConstExprDialog
          dialogOpen={this.state.nativeConstExprDialogOpen}
          fullScreen={this.state.nativeConstExprDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveNativeConstExprDialog}
          onToggleFullScreen={this.onNativeConstExprToggleFullScreen}
          nativeConstExprProps={this.state.nativeConstExprProps}
          nativeConstExpr={this.state.currNativeConstExpr}
          handleSave={this.handleSaveNativeConstExpr}
          saveNow={this.state.nativeConstExprChanged}
          shakeNow={this.state.confirmCloseNativeConstExprSnackbarShown}
          currMappingProjectId={currMappingProject ? currMappingProject.id : ''}
          allClasses={this.state.allClasses}
          patternTypeColors={this.props.configurationSettings.patternTypeColors}
          compactViewMode={this.props.configurationSettings.compactViewMode}
          instanceInfoStyleBasedOnLinesRequired={this.instanceInfoStyleBasedOnLinesRequired}
          handleDeleteConstExpr={this.handleDeleteNativeConstExpr}
          handleInputChange={this.handleInputChangeNativeConstExpr}
          handleInputChangeButton={this.handleInputChangeNativeConstExprButton}
          handleUseGenerator={this.handleConstExprUseGenerator}
          handleUseVariable={this.handleConstExprUseVariable}
          handleDirectDeleteVariable={this.handleDirectDeleteVariable}
          handleGoToGeneratorDeclarationForm={this.handleGoToGeneratorDeclarationForm}
          handleShowConfirmDeleteSnackbar={this.handleShowConfirmDeleteSnackbar}
          handleDirectDeleteInstanceGeneratorDeclaration={this.handleDirectDeleteInstanceGeneratorDeclaration}
          handleDirectDeleteLabelGeneratorDeclarations={this.handleDirectDeleteLabelGeneratorDeclarations}
          handleUseInstanceInfo={this.handleConstExprUseInstanceInfo}
          handleDirectDeleteInstanceInfo={this.handleDirectDeleteInstanceInfo}
          handleDisplayConstExpr={this.handleDisplayConstExpr}
          handleGoBack={this.handleGoToPreviousNativeConstExpr}
          handleAddNewNativeConstExpr={this.handleAddNewNativeConstExpr}
        />

        {/*
        Variable
        */}
        <VariableDialog
          dialogOpen={this.state.variableDialogOpen}
          fullScreen={this.state.variableDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveVariableDialog}
          onToggleFullScreen={this.handleVariableDialogToggleFullScreen}
          variableProps={this.state.currVariableProps}
          variable={this.state.currVariable}
          handleSaveVariable={this.handleSaveVariable}
          handleSaveCallBack={this.handleSaveVariableCallBack}
          variableHistory={this.state.variableHistory}
          saveNow={this.state.variableChanged}
          shakeNow={this.state.confirmCloseVariableSnackbarShown}
          handleInputSelectChange={this.handleInputVariableSelectChange}
          handleInputChange={this.handleInputVariableChange}
          handleBlur={this.handleBlurVariable}
        />

        {/*
        Instance Info
        */}
        <InstanceInfoDialog
          dialogOpen={this.state.instanceInfoDialogOpen}
          fullScreen={this.state.instanceInfoDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveInstanceInfoDialog}
          onToggleFullScreen={this.handleInstanceInfoDialogToggleFullScreen}
          instanceInfobleProps={this.state.currInstanceInfoProps}
          instanceInfo={this.state.currInstanceInfo}
          handleSave={this.handleSaveInstanceInfo}

          saveNow={this.state.instanceInfoChanged}
          shakeNow={this.state.confirmCloseInstanceInfoSnackbarShown}
          handleInputSelectChange={this.handleInputInstanceInfoSelectChange}
          handleInputChange={this.handleInstanceInfoInputChange}
          languages={this.state.languages}
        />
        {/*
          handleSaveCallBack={this.handleSaveInstanceInfoCallBack}
        */}

        {/*
        Condition
        */}
        <ConditionDialog
          dialogOpen={this.state.conditionDialogOpen}
          fullScreen={this.state.conditionDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveConditionDialog}
          onLeaveCallBack={this.handleCheckWhetherToLeaveConditionDialogCallBack}
          onToggleFullScreen={this.handleConditionDialogToggleFullScreen}
          conditions={this.state.currConditions}
          currCondition={this.state.currCondition}
          currConditionListUseOr={this.state.currConditionListUseOr}
          handleValidateConditionForm={this.handleValidateConditionForm}
          handleSaveCondition={this.handleSaveCondition}
          handleDeleteConditions={this.handleDeleteConditions}
          selectCondition={this.selectCondition}
          resetCondition={this.resetCondition}
          handleConditionInputChange={this.handleConditionInputChange}
          handleConditionListAndOrInputChange={this.handleConditionListAndOrInputChange}
          showErrorSnackBar={this.props.showErrorSnackBar}
          tabIndex={this.state.conditionTabIndex}
          showConditionForm={this.showConditionForm}
          handleConditionTabChange={this.handleConditionTabChange}
          handleChangeConditionTabIndex={this.handleChangeConditionTabIndex}
          saveNow={this.state.conditionChanged}
          shakeNow={this.state.confirmCloseConditionSnackbarShown || this.state.confirmGoBackConditionSnackbarShown}
        />

        {/*
        Condition
        */}
        <CommentDialog
          dialogOpen={this.state.commentDialogOpen}
          fullScreen={this.state.commentDialogFullScreen}
          onLeave={this.handleCheckWhetherToLeaveCommentDialog}
          onLeaveCallBack={this.handleCheckWhetherToLeaveCommentDialogCallBack}
          onToggleFullScreen={this.handleCommentDialogToggleFullScreen}
          comments={this.state.currComments}
          currComment={this.state.currComment}
          handleValidateCommentForm={this.handleValidateCommentForm}
          handleSaveComment={this.handleSaveComment}
          handleDeleteComments={this.handleDeleteComments}
          selectComment={this.selectComment}
          resetComment={this.resetComment}
          handleCommentInputChange={this.handleCommentInputChange}
          showErrorSnackBar={this.props.showErrorSnackBar}
          tabIndex={this.state.commentTabIndex}
          showCommentForm={this.showCommentForm}
          handleCommentTabChange={this.handleCommentTabChange}
          handleChangeCommentTabIndex={this.handleChangeCommentTabIndex}
          saveNow={this.state.commentChanged}
          shakeNow={this.state.confirmCloseCommentSnackbarShown || this.state.confirmGoBackCommentSnackbarShown}
          onDragDrop={this.onCommentDragDrop}
        />
        {/*
        Transformation Error Dialog
        */}
        <TransformationErrorDialog
          dialogOpen={this.state.transformationErrorDialogOpen}
          fullScreen={this.state.transformationErrorDialogFullScreen}
          errorMessage={this.state.transformationErrorMessage}
          transformationErrors={this.state.transformationErrors}
          onLeave={this.handleCloseTransformationErrorDialog}
          onToggleFullScreen={this.handleTransformationErrorDialogToggleFullScreen}
        />

        {/*
        Condition
        */}
        <ParticipatingUsersDialog
          dialogOpen={this.state.participatingUsersDialogOpen}
          fullScreen={this.state.participatingUsersDialogFullScreen}
          author={
            this.state.currMappingProject !== undefined && currMappingProject !== null ?
            (
              this.state.currMappingProject.author !== undefined ?
              this.state.currMappingProject.author :
              null
            ) :
            null
          }
          users={this.state.allUsers}
          onlineParticipantingUsers={this.state.onlineParticipantingUsers}
          participatingUsers={
            this.state.currMappingProject !== undefined && currMappingProject !== null ?
            (
              this.state.currMappingProject.users !== undefined && this.state.currMappingProject.users !== null ?
              this.state.currMappingProject.users :
              []
            ) :
            []
          }
          currUser={this.props.currUser}
          dialogClose={this.handleCloseParticipatingUsersDialog}
          onToggleFullScreen={this.handleParticipatingUsersDialogToggleFullScreen}
          handleSelectToParticipate={this.handleSelectToParticipate}
        />

        {/*
        SourceInputCoverage
        */}
        <SourceInputCoverageDialog
          dialogOpen={this.state.sourceInputCoverageOpen}
          fullScreen={this.state.sourceInputCoverageDialogFullScreen}
          xpaths={this.state.allXpaths}
          toCoverXpaths={
            this.state.currMappingProject !== undefined && currMappingProject !== null ?
            (
              this.state.currMappingProject.toCoverXpaths !== undefined ?
              this.state.currMappingProject.toCoverXpaths :
              []
            ) :
            []
          }
          showSourceCoverage= {
            this.state.currMappingProject !== undefined && currMappingProject !== null ?
            (
              this.state.currMappingProject.showSourceCoverage !== undefined ?
              this.state.currMappingProject.showSourceCoverage :
              false
            ) :
            false
          }
          currentlyUsedXpaths={this.state.currentlyUsedXpaths}
          onClose={this.handleCloseSourceInputCoverageDialog}
          onToggleFullScreen={this.handleSourceInputCoverageOpenDialogToggleFullScreen}
          sourceInputCoverageValue={
            this.state.currMappingProject !== undefined && currMappingProject !== null ?
            (
              this.state.currMappingProject.sourceInputCoverageValue !== undefined ?
              this.state.currMappingProject.sourceInputCoverageValue :
              0
            ) :
            0
          }
          handleToggleXpath={this.handleToggleCoverageActiveXpath}
          handleSelectAllXpaths={this.handleSelectAllToCoverXpaths}
          handleToggleShowSourceCoverage={this.handleToggleShowSourceCoverage}
        />

        {/*confirm to Close the Condition Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseConditionSnackbarShown}
          onClose={this.handleCloseConfirmCloseConditionSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Condition" form. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseConditionDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseConditionSnackbar}
        />

        {/*confirm to leave the condition form and go back to list of conditions*/}
        <ConfirmSnackbar
          open={this.state.confirmGoBackConditionSnackbarShown}
          onClose={this.handleCloseConfirmGoBackConditionSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Condition" form. Are you sure you want to leave it?'}
          onConfirmClick={this.handleConfirmGoBackConditionDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmGoBackConditionSnackbar}
        />

        {/*confirm to Close the comment Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseCommentSnackbarShown}
          onClose={this.handleCloseConfirmCloseCommentSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Comment" form. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseCommentDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseCommentSnackbar}
        />

        {/*confirm to leave the comments form and go back to list of comments*/}
        <ConfirmSnackbar
          open={this.state.confirmGoBackCommentSnackbarShown}
          onClose={this.handleCloseConfirmGoBackCommentSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Comment" form. Are you sure you want to leave it?'}
          onConfirmClick={this.handleConfirmGoBackCommentDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmGoBackCommentSnackbar}
        />

        {/*confirm to Close the Generator Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseGeneratorSnackbarShown}
          onClose={this.handleCloseConfirmCloseGeneratorSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Genarator" form. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseGeneratorDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseGeneratorSnackbar}
        />

        {/*confirm to leave the label generator form and go back to list of label generators*/}
        <ConfirmSnackbar
          open={this.state.confirmGoBackGeneratorSnackbarShown}
          onClose={this.handleCloseConfirmGoBackGeneratorSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Genarator" form. Are you sure you want to leave this form?'}
          onConfirmClick={this.handleConfirmGoBackGeneratorDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmGoBackGeneratorSnackbar}
        />

        {/*confirm to change tab in the generator dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmChangeTabGeneratorSnackbarShown}
          onClose={this.handleCloseConfirmChangeTabGeneratorSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Genarator" form. Are you sure you want to leave this form?'}
          onConfirmClick={this.handleConfirmChangeTabGeneratorDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmChangeTabGeneratorSnackbar}
        />

        {/*confirm to Close the variable Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseVariableSnackbarShown}
          onClose={this.handleCloseConfirmCloseVariableSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Variable" form. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseVariableDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseVariableSnackbar}
        />

        {/*confirm to Close the Namedgraph Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseNamedgraphSnackbarShown}
          onClose={this.handleCloseConfirmCloseNamedgraphSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Named Graph" form. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseNamedgraphDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseNamedgraphSnackbar}
        />
        {/*confirm to Close the Generator Definition manager Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseGeneratorDefinitionsManagerSnackbarShown}
          onClose={this.handleCloseConfirmCloseGeneratorDefinitionsManagerSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Generator Definition" form. Are you sure you want to leave without saving?'}
          onConfirmClick={this.handleConfirmCloseGeneratorDefinitionsManagerDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseGeneratorDefinitionsManagerSnackbar}
        />
        {/*confirm to go to "main" view of the Generator Definition manager Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmGoToMainGeneratorDefinitionsManagerSnackbarShown}
          onClose={this.handleCloseConfirmGoToMainGeneratorDefinitionsManagerSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Generator Definition" form. Are you sure you want to leave without saving?'}
          onConfirmClick={this.handleConfirmGoToMainGeneratorDefinitionsManagerDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmGoToMainGeneratorDefinitionsManagerSnackbar}
        />
        {/*confirm to select different definition and discard unsaved changes at the main view of the  Generator Definition manager Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbarShown}
          onClose={this.handleCloseConfirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Generator Definition" form. Are you sure you want to leave without saving?'}
          onConfirmClick={this.handleConfirmSelectOtherGeneratorDefinitionWithoutSaveManager}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmSelectOtherGeneratorDefinitionWithoutSaveManagerSnackbar}
        />
        {/*confirm to select Predefine definition and discard unsaved changes at the main view of the  Generator Definition manager Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmSelectPredefinedGeneratorDefinitionWithoutSaveManagerSnackbarShown}
          onClose={this.handleCloseConfirmSelectPredefinedGeneratorDefinitionWithoutSaveManagerSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Generator Definition" form. Are you sure you want to leave without saving?'}
          onConfirmClick={this.handleConfirmSelectPredefinedGeneratorDefinitionWithoutSaveManager}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmSelectPredefinedGeneratorDefinitionWithoutSaveManagerSnackbar}
        />
        {/*confirm to go to "add new" view of the Generator Definition manager Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmGoToAddNewGeneratorDefinitionsManagerSnackbarShown}
          onClose={this.handleCloseConfirmGoToAddNewGeneratorDefinitionsManagerSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Generator Definition" form. Are you sure you want to leave without saving?'}
          onConfirmClick={this.handleConfirmGoToAddNewGeneratorDefinitionsManagerDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmGoToAddNewGeneratorDefinitionsManagerSnackbar}
        />

        {/*confirm to update some Generator declaration from the manager*/}
        <ConfirmSnackbar
          open={this.state.confirmUpdateGeneratorDefinitionManagerSnackbarShown}
          onClose={this.handleCloseConfirmUpdateGeneratorDefinitionManagerSnackbar}
          type="warning"
          msg={
            'Updating this generator definition will also affect ' +
            this.state.tmp_numOfDeclarationsAssociatedToDefinition +
            (
              this.state.tmp_numOfDeclarationsAssociatedToDefinition > 1 ?
              ' declarations which are already using it.' :
              ' declaration which is already using it.'
            ) +
            '  Are you sure you want to continue?'
          }
          onConfirmClick={this.handleConfirmUpdateGeneratorDefinitionManagerDialog}
          confirmButtonLabel="Yes proceed"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmUpdateGeneratorDefinitionManagerSnackbar}
        />

        {/*confirm to delete selected Generator declaration from the manager*/}
        <ConfirmSnackbar
          open={this.state.confirmDeleteGeneratorDefinitionManagerSnackbarShown}
          onClose={this.handleCloseConfirmDeleteGeneratorDefinitionManagerSnackbar}
          type="warning"
          msg={this.state.confirmMessage}
          onConfirmClick={this.handleConfirmDeleteGeneratorDefinitionManager}
          confirmButtonLabel="Yes proceed"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmDeleteGeneratorDefinitionManagerSnackbar}
        />

        {/*confirm to make some generator definition preloaded*/}
        <ConfirmSnackbar
          open={this.state.confirmBeforeMakingGeneratorDefinitionPreloadedSnackbarShown}
          onClose={this.handleCloseConfirmBeforeMakingGeneratorDefinitionPreloadedSnackbar}
          type="warning"
          msg={
            this.state.currManagerGeneratorDefinition.preloaded !== undefined ?
            (
              !this.state.currManagerGeneratorDefinition.preloaded ?
              'You are about to make this generator definition preloaded in the definition list for all users. Are you sure about this?' :
              'If you applying this action, this generator definition will no longer be available for any user. Are you sure about this?'
            ) :
            'You are about to make this generator definition preloaded in the definition list for all users. Are you sure about this?'
          }
          onConfirmClick={this.handleConfirmMakingGeneratorDefinitionPreloaded({actionType: 'insert'})}
          confirmButtonLabel="Yes I'm sure about this"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmBeforeMakingGeneratorDefinitionPreloadedSnackbar}
        />

        {/*confirm to delete selected preloaded Generator declaration from the manager*/}
        <ConfirmSnackbar
          open={this.state.confirmDeletePreloadedGeneratorDefinitionManagerSnackbarShown}
          onClose={this.handleCloseConfirmDeletePreloadedGeneratorDefinitionManagerSnackbar}
          type="warning"
          msg={this.state.confirmMessage}
          onConfirmClick={this.handleConfirmDeletePreloadedGeneratorDefinitionManager}
          confirmButtonLabel="Yes proceed"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmDeletePreloadedGeneratorDefinitionManagerSnackbar}
        />
        {/*confirm to Close the instance info Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseInstanceInfoSnackbarShown}
          onClose={this.handleCloseConfirmCloseInstanceInfoSnackbar}
          type="warning"
          msg={'There are unsaved fields on the "Instance Info" form. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseInstanceInfoDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseInstanceInfoSnackbar}
        />
        {/*confirm to remove the instance info*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseRemoveInstanceInfoSnackbarShown}
          onClose={this.handleCloseConfirmRemoveInstanceInfoSnackbar}
          type="warning"
          msg={'You are about to remove this "Instance Info". Are you sure you want to proceed?'}
          onConfirmClick={this.handleConfirmRemoveInstanceInfoDialog}
          confirmButtonLabel="Yes proceed"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmRemoveInstanceInfoSnackbar}
        />

        {/*confirm to apply whole X3ML code changes*/}
        <ConfirmSnackbar
          open={this.state.confirmImportWholeX3MLCodeSnackbarShown}
          onClose={this.handleCloseImportWholeX3MLCodeSnackbar}
          type="warning"
          msg={'Are you sure you want to apply the changes performed on the X3ML Code?'}
          onConfirmClick={this.handleApplyWholeX3MLCodeChanges}
          confirmButtonLabel="Yes apply the changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseImportWholeX3MLCodeSnackbar}
        />

        {/*confirm to Close the CodeMirror editor for the whole X3ML*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseWholeX3MLCodeSnackbarShown}
          onClose={this.handleCloseConfirmCloseWholeX3MLCodeEditorSnackbar}
          type="warning"
          msg={'There are changes made in the X3ML code. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseWholeX3MLCode}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseWholeX3MLCodeEditorSnackbar}
        />

        {/*confirm to export to zip without the X3ML file*/}
        <ConfirmSnackbar
          open={this.state.confirmExportWithoutX3MLSnackbarShown}
          onClose={this.handleCloseConfirmExportWithoutX3MLSnackbar}
          type="warning"
          msg={'Due to errors in the mapping, the X3ML cannot be produced. However you can still get the rest of the files (sources, target schemata and the generator policy). Do you still want to download the compressed file?'}
          onConfirmClick={this.handleConfirmExportWithoutX3ML({
            id: this.state.currMappingProject !== undefined && currMappingProject !== null ? this.state.currMappingProject.id : undefined,
            relativePath: this.state.currMappingProject !== undefined && currMappingProject !== null ? this.state.currMappingProject.x3mlOutputRelativePath : undefined,
          })}
          confirmButtonLabel="Yes, get me the compressed file and exclude the X3ML mapping"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmExportWithoutX3MLSnackbar}
        />
        {/*
        <div ref="commentsFather" className="commentsFather" />
        */}
      </div> :
      ''
      }
      </div>
    );
  }
}

MappingTableView.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

//export default withStyles(styles)(MappingTableView);
export default compose(withStyles(styles), withRouter, withSnackbar)(MappingTableView); // import compose from 'recompose/compose';
