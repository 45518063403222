import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import configuration from '../configuration.json';

const rootContextPath = configuration.rootContextPath;

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

class Input extends Component {
    constructor(props){
        super(props)
        this.state = {
            value: props.value? props.value : '',
            className: props.className? props.className : '',
            error: false
        }
    }

    inputChange = (event) => {
      const value = event.target.value//, name = event.target.name

      //switch(name) {
      //  case 'username': this.validate(name, value, /^([a-zA-Z0-9.]{4,})$/, 'invalid username')
      //    break;
      //  case 'password': this.validate(name, value, /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/, 'insecure password')
      //    break;
      //  default:
      //    console.warn(`unknown field ${name}`)
      //}
      this.setState({ value: value })
    }

    render () {
        const {classes, ...opts} = this.props;
        //console.log(opts);
        if(opts.className === 'btn') {
          return (
            <div style={{paddingTop:10}}>
              <div>
                <Button {...opts}
                        variant="contained"
                        color="primary"
                        value={this.state.value}
                        className={classes.button}>
                  Login
                </Button>
                <Button color="secondary"
                        component={Link}
                        to={rootContextPath + '/Registration'}
                        className={classes.button}>
                  Registration
                </Button>
              </div>
              
            </div>
          )
        }

        else {
          return (
            <div>
              <TextField {...opts} value={this.state.value}
                onChange={this.inputChange} className={this.state.className} />
            </div>
          )
        }
    }
}

Input.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Input)
