import React from 'react';
import classNames from 'classnames';
import clsx from 'clsx';
import compose from 'recompose/compose';
import update from 'immutability-helper';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Autocomplete from './Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { MainService } from './services/MainService';
import { GroupService } from './services/GroupService';
import InsertMappingDialog from './projectTableComponents/InsertMappingDialog';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

//import { withRouter } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import Grow from '@material-ui/core/Grow';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningIcon from '@material-ui/icons/Warning';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FilePond, registerPlugin } from 'react-filepond';
import MaskedInput from 'react-text-mask';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import ConfirmSnackMessage from './snackbar/ConfirmSnackMessage';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DonutChartProgress from './mappingTableViewComponents/DonutChartProgress';
import arrayMove from "array-move";
import { Container, Draggable } from "react-smooth-dnd";
import amber from '@material-ui/core/colors/amber';
import { CSSTransition } from 'react-transition-group';
import './css/custom.css';
import configuration from './configuration.json';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: 'all', 
    numeric: false, 
    date: false, 
    avatar: false, 
    disablePadding: false, 
    label: 'All'
  }, {
    id: 'title', 
    numeric: false, 
    date: false, 
    avatar: false, 
    disablePadding: true, 
    useInAllFilter: true, 
    label: 'Title'
  }, {
    id: 'hrId', 
    numeric: false, 
    date: false, 
    avatar: false, 
    disablePadding: true, 
    useInAllFilter: true, 
    label: 'HRID', 
    link: './MappingTableView/<value>',
    tooltip: 'Right Click to open in new tab'
  }, {
    id: 'fullName', 
    numeric: false, 
    date: false, 
    avatar: false, 
    disablePadding: true, 
    useInAllFilter: true, 
    label: 'Author'
  }, {
    id: 'status', 
    numeric: false, 
    date: false, 
    avatar: false, 
    disablePadding: true, 
    useInAllFilter: false, 
    label: 'Status'
  }, {
    id: 'creationDate', 
    numeric: false, 
    date: true, 
    avatar: false, 
    disablePadding: true, 
    useInAllFilter: false, 
    label: 'Date Created'
  }, {
    id: 'modificationDate', 
    numeric: false, 
    date: true, 
    avatar: false, 
    disablePadding: true, 
    useInAllFilter: false, 
    label: 'Date Modified'
  }, {
    id: 'sourceInputCoverage', 
    numeric: false, 
    date: false, 
    avatar: false, 
    disablePadding: true, 
    useInAllFilter: false, 
    label: 'Coverage'
  }, {
    id: 'avatar', 
    numeric: false, 
    date: false, 
    avatar: true, 
    disablePadding: true, 
    useInAllFilter: false, 
    label: 'User',
  },
];

const rootContextPath = configuration.rootContextPath;
const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)
const dirRootContextPath = configuration.dirRootContextPath; // This is different than the regular rootContextPath (/3m) and avatarRootContextPath (3m/)




// Wrapper component used to provide an input component with some reference attached to it
class ReferencedInput extends React.Component {

  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  componentDidMount() {
    this.props.holdRefToMapCallBack({ref: this.inputField, id: this.props.id, mapName: 'groupRefsMap'});
  }

  // To add filter check the video:
  // https://www.youtube.com/watch?v=SX_IL7LqSxM

  render() {
    const { placeholder, onChange, onBlur, value } = this.props;

    return (
      <TextField
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        inputRef={this.inputField}
      />
    );
  }
}

ReferencedInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  holdRefToMapCallBack: PropTypes.func.isRequired,
};

class ProjectTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  // To add filter check the video:
  // https://www.youtube.com/watch?v=SX_IL7LqSxM

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, showSelectAllCheckbox, manageModeActive } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" style={{height: 43, width: 52}}>
            <Fade
              in={showSelectAllCheckbox}
              timeout={{enter: 300, exit: 300}}
            >
              <div>
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={onSelectAllClick}
                  hidden={!showSelectAllCheckbox}
                  color={manageModeActive ? 'primary' : 'secondary'}
                />
              </div>
            </Fade>
          </TableCell>
          {rows.map(row => {
            if(row.id !== 'all') {
              return (
                <TableCell
                  key={row.id}
                  align={row.numeric ? 'right' : 'left'}
                  padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <Tooltip
                    title={`Sort in ${order === 'asc' ? 'descending' : 'ascending'} order`}
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              );
            }
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

ProjectTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  deleteHighlight:
    theme.palette.type === 'light'
    ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
    : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  manageHighlight:
    theme.palette.type === 'light'
    ? {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
      }
    : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  groupNameStyle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200,
  },
  groupSetNameStyle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 232,
    fontWeight: 'bold',
    paddingLeft: 10,
    paddingRight: 10
  },
  codeHeighlighted: {
    color: theme.palette.primary.main,
    backgroundColor: '#f9f2f4',
    borderRadius: 4,
    padding: '2px 4px',
    marginLeft: 4,
    marginRight: 4,
    fontSize: '90%',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  // When used they make the switch look the same whether it is enabled or disabled
  // switchEqualBase: {
  //   color: '#2196f3',
  //   opacity: 1,
  //   '&$checked': {
  //     //color: theme.palette.primary,
  //     color: '#2196f3',
  //   },
  // },
  // trackEqual: {
  //   backgroundColor: '#2196f3',
  // }
});


let ProjectTableToolbar = props => {
  const {
    numSelected, deleteModeActive, handleDeleteSelected, mineOnlyModeActive, participatingModeActive,
    handleMineOnlyModeChange, handleParticipatingOnlyModeChange,
    handleInputChange, handleSelectInputChange, searchText, columnToBeFiltered, classes,
    groupsPanelShown, handleShowGroupsPanel, manageModeActive,
    handleOpenAddGroupMenu, handleCloseAddGroupMenu, addGroupMenuAnchorEl, handleAddMappingProjectToGroup,
    groupSets, fromManagement, forUser, forType, handleGoBack,
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root,{
        [classes.deleteHighlight]: deleteModeActive,
        [classes.manageHighlight]: manageModeActive,
      })}
    >
      <div className={classes.title}>
        {deleteModeActive || manageModeActive ? (
          <Typography color="inherit" variant="body1">
            {numSelected} selected
          </Typography>
        ) : (
          <div style={{display: 'flex'}}>
            <CSSTransition
              in={!groupsPanelShown}
              timeout={200}
              classNames="revealToolbarButtonInOutIniShown"
            >
              <IconButton
                style={{marginLeft:-16, marginRight: 3, padding: 8}}
                onClick={handleShowGroupsPanel}
              >
                <MoreVertIcon />
              </IconButton>
            </CSSTransition>
            <Fab
              hidden={!fromManagement}
              size="small"
              variant="extended"
              color="primary"
              style={{margin: 'auto', marginRight: 10}}
              onClick={handleGoBack}
            >
              <ArrowBackIosIcon className={classes.extendedIcon} style={{fontSize: 16, marginRight: 0}}/>
              Go Back
            </Fab>
            <Typography variant="h6" style={{margin: 'auto'}}>
                Project - Mappings
            </Typography>
            <Typography hidden={!fromManagement} variant="h6" style={{margin: 'auto', paddingLeft: 4}}>
                {
                  forType === 'membership' ?
                  <span>
                    that
                    <code
                      className={classes.codeHeighlighted}
                    >
                      {forUser.firstname + ' ' + forUser.lastname}
                    </code>
                    can edit but is not their author
                  </span> :
                  <span>
                    for which
                    <code
                      className={classes.codeHeighlighted}
                    >
                      {forUser.firstname + ' ' + forUser.lastname}
                    </code>
                    is the author
                  </span>
                }
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {deleteModeActive ? (
          <Tooltip title="Delete" placement="left">
            <div>
              <IconButton aria-label="Delete"
                          onClick={handleDeleteSelected}
                          disabled={numSelected === 0}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : manageModeActive ? (
          <Tooltip title="Add to group" placement="left">
            <div>
              <IconButton
                aria-label="add-to-group"
                onClick={handleOpenAddGroupMenu}
                disabled={numSelected === 0}>
                <MoveToInboxIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <div style={{display: 'flex'}}>
            <div
              hidden={fromManagement}
              style={{
                whiteSpace: 'nowrap',
                marginTop: 4,
                marginRight: 10,
                display: 'flex'
              }}
            >
              <Tooltip title="Only display the projects that I own"
                       placement="bottom"
                       enterDelay={500}
                       leaveDelay={200}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={mineOnlyModeActive}
                      onChange={handleMineOnlyModeChange}
                      value={mineOnlyModeActive}
                      color="primary"
                    />
                  }
                  label="Only display what I own"
                  classes={{label: classes.boldLabel}}
                />
              </Tooltip>
            </div>
            <div
              hidden={fromManagement}
              style={{
                whiteSpace: 'nowrap',
                marginTop: 4,
                marginRight: 10,
                display: 'flex'
              }}
            >
              <Tooltip title="Only display the projects I can edit"
                       placement="bottom"
                       enterDelay={500}
                       leaveDelay={200}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={participatingModeActive}
                      onChange={handleParticipatingOnlyModeChange}
                      value={participatingModeActive}
                      color="primary"
                    />
                  }
                  label="Only display what I can edit"
                  classes={{label: classes.boldLabel}}
                />
              </Tooltip>
            </div>
            <div style={{paddingRight: 10, marginTop: -12, width: 184}}>
              <TextField
                label={'Search' + (columnToBeFiltered.date ? ' (YYYY-MM-DD)' : '')}
                onChange={handleInputChange('searchText')}
                value={searchText}
              />
            </div>
            <div style={{width: 180}}>
              <Autocomplete
                placeholder={'Select Column Filter'}
                options={
                  rows
                  .filter(item => item.id !== 'avatar')
                  .filter(item => mineOnlyModeActive ? item.id !== 'fullName' : item)
                }
                value={columnToBeFiltered}
                onChange={handleSelectInputChange('columnToBeFiltered')}
                async={false}
                isMulti={false}
              />
            </div>
          </div>
        )}
      </div>
      {/* Menu For selecting ation on selected Mapping Projects*/}
      <Menu
        id={'select-group-menu'}
        anchorEl={addGroupMenuAnchorEl}
        keepMounted
        open={Boolean(addGroupMenuAnchorEl)}
        onClose={handleCloseAddGroupMenu}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >

        {
          groupSets.map((groupSet) => (
            <div key={groupSet.id}>
              <Typography
                variant="subtitle1"
                className={classes.groupSetNameStyle}
              >
                {groupSet.name}
              </Typography>
              <Divider />
              {
                groupSet.groups.map((group) => (
                  <MenuItem
                    key={group.id}
                    onClick={handleAddMappingProjectToGroup({group: group})}
                  >
                    <div className={classes.groupNameStyle}>
                      {group.name}
                    </div>
                  </MenuItem>
                ))
              }
            </div>
          ))
        }
      </Menu>
    </Toolbar>
  );
};

ProjectTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

ProjectTableToolbar = withStyles(toolbarStyles)(ProjectTableToolbar);

const drawerWidth = 300;

const styles = theme => ({
  tableWrapperRoot: {
    width: '100%',
    marginTop: theme.spacing(1),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
    maxHeight: 'calc(100vh - 258px)',
  },
  toggleButtonRoot: {
    height: 35,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  snackbarWarning: {
    backgroundColor: amber[700],
    width: 460,
    //display: 'unset',
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  snackbarCloseIconButton: {
    padding: theme.spacing(0.5),
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.light + ' !important',
  },
  fontAwesomeIcon: {
    fontSize: 20,
    marginRight: 10,
  },
  zipImportFilePondRoot: {
    marginBottom: 'unset',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 82,
    maxHeight: 'calc(100vh - 94px)',
    left: 5,
    right: 'unset',
    borderRight: 'unset',
  },
  drawerToolbar: {
    //display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  drawerTitle: {
    //paddingRight: theme.spacing(1),
    paddingLeft: 16,
    paddingRight: 16,
  },
  drawerContent: {
    height: 'calc(100vh - 96px)',
    marginLeft: 5,
    marginRight: 5,
  },
  groupHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  nestedGroup: {
    paddingLeft: theme.spacing(4),
    paddingBottom: 12,
  },
  groupNameStyle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150,
  },
  groupSetListStyle: {
    maxHeight: 'calc(100vh - 214px)',
    overflowY: 'auto',
  },
  tableShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  customGroupListItemContainer: {
    width: '100%',
  },
  groupSetListItemTextStyle: {
    backgroundColor: theme.palette.primary.blueGreyVeryLight,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  internalCircle: {
    backgroundColor: '#f6a2c0',
    borderRadius: 30,
    margin: 3,
  },
  circularWrapper: {
    position: 'relative',
    zIndex: 1,
    width: 32,
    height: 32
  },
  fullSourceInputCoverage: {
    fontSize: '0.56rem',
    fontWeight: 'bold',
  },
  // groupNextToTitle: {
  //   borderRadius: 8,
  //   background: 'DarkSeaGreen',
  //   marginRight: 10,
  //   paddingTop: 4,
  //   paddingBottom: 4,
  //   paddingLeft: 6,
  //   paddingRight: 6,
  // },
});

const mainService = new MainService();
const groupService = new GroupService();

class ProjectTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'calories',
    selected: [],
    data: [
      //createData('Future FAKE DUMMY Project', 'vkrits', 'In Progress', '2035-31-12', '2255-15-06'),
    ],
    page: 0,
    rowsPerPage: 10,
    openInsertMappingDialog: false,
    modeName: 'normal',
    groupsPanelShown: false,
    manageModeActive: false,
    deleteModeActive: false,
    forceAdminModeActive: false,
    mineOnlyModeActive: false,
    participatingModeActive: true,
    wizardShown: false,
    snackbarShown: false,
    searchText: '',
    columnToBeFiltered: rows[0],
    zipImportDialogOpen: false,
    zipImportDialogFullScreen: false,
    groupSets: [],
    /*groupSets: [
      {
        id: 'general',
        name: 'General Projects',
        expanded: false,
        groups: [
          {
            id: 'hercules',
            name: 'Hercules',
          },
          {
            id: 'ariadne',
            name: 'Ariadne',
          }
        ]
      },
      ...
    ],*/
    groupSetMenuAnchorEl: null,
    groupSetId: '',
    groupId: '',
    currentGroupEdited: null,
    currentGroupSetEdited: null,
    groupRefsMap: new Map(), // Array holding the refs of the groups
    idOfHoveredGroup: '',
    selectedGroup: '',
    addGroupMenuAnchorEl: null,
  };

  constructor(props) {
    super(props)
    this.handleOpenMapping = this.handleOpenMapping.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.currUser !== nextProps.currUser) {
      return true;
    }
    if(this.props.user !== nextProps.user) {
      return true;
    }
    if(this.props.history !== nextProps.history) {
      return true;
    }
    else {
      return false;
    }
  }

  // Retrieve data
  componentDidMount()  {

    // If userId is passed through history, then retrieve the mapping projects
    // for this user, based on either membership or authorship
    if(this.props.history.location.state !== undefined) {
      if(this.props.history.location.state.fromManagement !== undefined) {
        this.setState({
            fromManagement: this.props.history.location.state.fromManagement,
            forUser: this.props.history.location.state.user,
            forType: this.props.history.location.state.type
        }, () => {
          this.retrieveMappingProjectsBasedOnParamsFromManagementCallBack({
            type: this.props.history.location.state.type,
            user: this.props.history.location.state.user,
          });
        });
      }
      else {
        this.retrieveMappingProjects();
      }
    }
    // Otherwise retrieve current user's mapping projects
    else {
      this.retrieveMappingProjects();
    }
    this.retrieveUserGroupSetByCurrentUser();
  }

  // props: {type: 'membership | authorship', userId: <Text>}
  retrieveMappingProjectsBasedOnParamsFromManagementCallBack = async props => {
    const type = props.type;
    const user = props.user;
    let res = undefined;
    if(type === 'membership') {
      res = await mainService.retrieveMappingProjectsByMemberId({userId: user.id});
    }
    else if(type === 'authorship') {
      res = await mainService.retrieveMappingProjectsByAuthorId({userId: user.id});
    }
    if(res !== undefined) {
      let extendedMappingProjects = res.data.map(
        obj => {
          return ({
          ...obj,
          hrId: obj.hrId === null ? 'Unset' : obj.hrId,
          fullName: obj.author.firstname + ' ' + obj.author.lastname,
          sourceInputCoverage: obj.showSourceCoverage ? obj.sourceInputCoverageValue : '',
          avatar: obj.aAuthor !== null && obj.author !== undefined ? (obj.author.avatarImageRelativePath) : '',
        })}
      );
      this.setState({
          data: extendedMappingProjects
      });
    }

  }

  // Retrieving the list of mappingProject docs from the database
  retrieveMappingProjects = async () => {
    try {
      let res = [];
      if(this !== null) {
        // No group involved
        if(this.state.selectedGroup === '') {
          if(this.state.mineOnlyModeActive) {
            res = await mainService.getMyMappingProjectsMetadataOnly();
          }
          else if (this.state.participatingModeActive) {
            res = await mainService.getParticipatingMappingProjectsMetadataOnly();
          }
          else {
            res = await mainService.getAllMappingProjectsMetadataOnly();
          }
        }
        // Group Involved
        else {
          const params = {
            groupId: this.state.selectedGroup.id
          }
          if(this.state.mineOnlyModeActive) {
            res = await mainService.getMyMappingProjectsInGroupMetadataOnly(params);
          }
          else if (this.state.participatingModeActive) {
            console.log();
            res = await mainService.getParticipatingMappingProjectsInGroupMetadataOnly(params);
          }
          else {
            res = await mainService.getAllMappingProjectsInGroupMetadataOnly(params);
          }
        }
      }
      else {
        res = await mainService.getMyMappingProjectsMetadataOnly();
      }

      if(res.data !== false) {
        console.log('res');
        console.log(res);
        let extendedMappingProjects = res.data.map(
          obj => {
            return ({
            ...obj,
            hrId: obj.hrId === null ? 'Unset' : obj.hrId,
            fullName: obj.author.firstname + ' ' + obj.author.lastname,
            sourceInputCoverage: obj.showSourceCoverage ? obj.sourceInputCoverageValue : '',
            avatar: obj.aAuthor !== null && obj.author !== undefined ? (obj.author.avatarImageRelativePath) : '',
          })}
        );
        this.setState({
            data: extendedMappingProjects
        });

      }
      else {
        //
      }
    } catch (e) {
      console.error('Failure while retrieving MappingProjects!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }


  // retrieveGroupSets = async () => {
  //   try {
  //     let res = await groupService.retrieveGroupSetsByCurrentUser();
  //     if(res.data !== false) {
  //       this.setState({
  //           groupSets: res.data,
  //       });
  //     }
  //   } catch (e) {
  //     console.error('Some error occured while retrieving the group sets.');
  //     this.props.showErrorSnackBar({msg: 'Some error occured while retrieving the group sets.', iconSize: 'large'});
  //     if(e.response !== undefined) {
  //       console.error(e.response.status);
  //     }
  //     return null;
  //   }
  // }

  /*
  Retrieving user's group sets
  */
  retrieveUserGroupSetByCurrentUser = async () => {
    try {
      let res = await groupService.retrieveUserGroupSetByCurrentUser();
      if(res.data !== false) {
        this.setState({
            userGroupSet: res.data,
            groupSets: res.data.groupSets,
        });
      }
    } catch (e) {
      console.error('Some error occured while retrieving the group sets.');
      this.props.showErrorSnackBar({msg: 'Some error occured while retrieving the group sets.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    this.handleSelectAllClickCallBack(event.target.checked);
  }

  handleSelectAllClickCallBack = checked => {
    if (checked) {
      this.setState(
        state => ({
          selected: state.data.filter(
          mappingProject =>
            this.state.manageModeActive ?
            (
              this.findFirstUsedGroupOfMappingProject({
                mappingProjectId: mappingProject.id,
                mappingProjectGroups: mappingProject.groups
              }) === ''
            ) :
            (
              this.state.deleteModeActive ?
                !this.state.forceAdminModeActive ?
                mappingProject.author.id === this.props.user.id :
                true :
              false
            )
          )
          .map(mappingProject => mappingProject.id)
        })
      );
      return;
    }
    this.setState({
      selected: []
    });
  };

  // Here I can Use a flag for single or multiple selection. Multiple selection will enable deletion and management in groups
  handleClick = (event, rowItem) => {
    const id = rowItem.id;

    // Handle multiple clicks if the edit mode is active
    if(this.state.deleteModeActive || this.state.manageModeActive) {

      let rowSelectable = true;
      // Case of manageModeActive and already grouped MappingProjectMetadata
      if(this.state.manageModeActive) {
        const mappingProjectMetadataIndex = this.state.data.findIndex(
          obj => obj.id === id
        );
        // rowSelectable = this.state.data[mappingProjectMetadataIndex].groups !== null ?
        //                 this.state.data[mappingProjectMetadataIndex].groups.length === 0 :
        //                 true;
        rowSelectable = this.findFirstUsedGroupOfMappingProject({
          mappingProjectId: id,
          mappingProjectGroups: this.state.data[mappingProjectMetadataIndex].groups
        }) === '';
      }
      else if(this.state.deleteModeActive) {
        rowSelectable = !this.state.forceAdminModeActive ?
                        rowItem.author.id === this.props.user.id :
                        true
      }

      if(rowSelectable) {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        this.setState({
          selected: newSelected
        });
      }
    }

    // Handle single selected
    else {
      this.setState({selected: []}, function () {
        let newSelected = [];
        newSelected = newSelected.concat(this.state.selected, id);
        this.setState({ selected: newSelected });
      });

      console.log('Selected MappingProject ID: ' + id);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      page: 0,
    });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleOpenInsertMappingDialog = () => {
    this.setState({ openInsertMappingDialog: true });
  };

  // Togglinbg between normal and manage mode
  handleShowGroupsPanel = event => {
    if(event === undefined) {
      this.setState({
        deleteModeActive: false,
        groupsPanelShown: false,
        selected: [],
      });
    }
    else {
      this.setState({
        deleteModeActive: false,
        groupsPanelShown: !this.state.groupsPanelShown,
        selected: [],
      });
    }
  };

  handleManageModeChange = event => {
    if(event === undefined) {
      this.setState({
        deleteModeActive: false,
        manageModeActive: false,
        forceAdminModeActive: false,
        selected: [],
      });
    }
    else {
      this.setState({
        deleteModeActive: false,
        manageModeActive: !this.state.manageModeActive,
        forceAdminModeActive: false,
        selected: [],
      });
    }
  };

  // Togglinbg between normal and delete mode
  handleDeleteModeChange = event => {
    if(event === undefined) {
      this.setState({
        deleteModeActive: false,
        manageModeActive: false,
        forceAdminModeActive: false,
        selected: [],
      });
    }
    else {
      this.setState({
        deleteModeActive: event.target.checked,
        manageModeActive: false,
        forceAdminModeActive: false,
        selected: [],
      });
    }
  };

  handleForceAdminModeActiveChange = event => {
    if(event === undefined) {
      this.setState({
        forceAdminModeActive: false,
      }, () => {
        this.handleSelectAllClickCallBack(false);
      });
    }
    else {
      this.setState({
        forceAdminModeActive: event.target.checked,
      }, () => {
        // When disabed, check that there are no mapping projects of others checked
        if(!event.target.checked) {
          let newSelected = [];
          this.state.data.forEach(mappingProject => {
            if(this.state.selected.includes(mappingProject.id)) {
              if(mappingProject.author.id === this.props.user.id)
                newSelected.push(mappingProject.id);
            }
          });

          this.setState({
            selected: newSelected,
          });

        }
      });
    }
  };

  handleMineOnlyModeChange = event => {
    if(event === undefined) {
      this.setState({
        mineOnlyModeActive: false,
        page: 0,
      }, () => {
        this.retrieveMappingProjects();
      });
    }
    else {
      this.setState({
        mineOnlyModeActive: event.target.checked,
        participatingModeActive: false,
        page: 0,
      }, () => {
        this.retrieveMappingProjects();
      });
    }
  };

  handleParticipatingOnlyModeChange = event => {
    if(event === undefined) {
      this.setState({
        participatingModeActive: false,
        page: 0,
      }, () => {
        this.retrieveMappingProjects();
      });
    }
    else {
      this.setState({
        participatingModeActive: event.target.checked,
        mineOnlyModeActive: false,
        page: 0,
      }, () => {
        this.retrieveMappingProjects();
      });
    }
  };

  handleOpenMapping = () => {
    //console.log(this.state.selected[0]);
    this.props.history.push({
      pathname: rootContextPath + '/MappingTableView',
      state: {
        selectedId: this.state.selected[0]
      }
    });
  };

  handleCloneMapping = async () => {
    try {
      const res = await mainService.cloneMappingProject({id: this.state.selected[0]});
      if(res.data.succeed) {
        await this.retrieveMappingProjects();
        this.props.showSuccessSnackBar({msg: res.data.msg});
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg, iconSize: 'large'});
      }

    } catch (e) {
      console.error('The Selected Mapping Project could not be cloned');
      this.props.showErrorSnackBar({msg: 'The Selected Mapping Project could not be cloned', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  handleDeleteSelected = () => {
    this.handleSnackbarOpen();
  }

  handleOpenWizardForSelected = () => {
    if(this.state.selected.length > 0) { // Some project is selected
      this.setState({
        selectedMetadataId: this.state.selected[0],
      }, () => {
        this.setState({
          wizardShown: true,
        });
      });
    }
    else {
      this.props.showErrorSnackBar({msg: 'You should selecte some project first.', iconSize: 'large'});
    }
  };

  handleOpenWizard = () => {
    // this.setState({ wizardShown: true });
    this.setState({
      selectedMetadataId: undefined,
    }, () => {
      this.setState({
        wizardShown: true,
      });
    });
  };

  handleCloseWizard = () => {
    this.setState({ wizardShown: false });
  };

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleConfirmDeleteSelectedMappings =async  () => {
    try {
      for (let mappingProjectId of this.state.selected) {
        const res = await mainService.deleteMappingProjectById({id: mappingProjectId, deleteAssociatedFileMetadata: true});
        if(res.data.succeeded) {
          this.props.showSuccessSnackBar({msg: res.data.msg})
          this.setState({
            page: 0,
          });
        }
        else {
          this.props.showErrorSnackBar({msg: res.data.msg, iconSize: 'large'});
        }
      }
    } catch (e) {
      console.error('Failure while retrieving MappingProjects!');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
    for (let mappingProjectId of this.state.selected) {
      await mainService.deleteMappingProjectById({id: mappingProjectId, deleteAssociatedFileMetadata: true});
    }
    await this.retrieveMappingProjects();
    this.setState({
      snackbarShown: false
    }, function() {
      // Return to normal mode
      //this.handleToggleEditMode();
      this.handleDeleteModeChange();
    });
  }

  handleCancelDeleteSelectedMappings = () => {
    this.setState({ snackbarShown: false });
  }

  handleInputChange = fieldName => event => {
    this.setState({
      [fieldName]: event.target.value,
      page: fieldName === 'searchText' ? 0 : this.state.page, // Getting to the first page when searching
    });
  }

  handleSelectInputChange = fieldName => event => {
    this.setState({
      [fieldName]: event !== null ? event : rows[0],
    }, () => {
      // Case of clicking the x button
      if (event === null) {
        // Resetting the value of the search text-field
        this.setState({
          searchText: '',
        });
      }
    });
  }

  // Import from ZIP

  handleOpenZipImportDialog = () => {
    this.setState({
      zipImportDialogOpen: true
    });
  }

  handleCloseZipImportDialog = () => {
    this.setState({
      zipImportDialogOpen: false
    });
  }

  handleZipImportToggleFullScreen = () => {
    this.setState({
      zipImportDialogFullScreen: !this.state.zipImportDialogFullScreen
    });
  }

  handleZipImportFileProcessingError = (error) => {
    return 'Error occured while importing X3ML file - ' + error.body;
  }

  handleAfterZipImportFileUpload = async (error, file) => {
    // No error
    if(error === null) {
      console.log('file.serverId: ' + file.serverId);
      // Calling service to handle the zip file
      let importRes = await mainService.importFromZipFile({
        relativePath: file.serverId,
      });
      // Reloading the Mapping Projects
      await this.retrieveMappingProjects();
      this.handleCloseZipImportDialog();
      if(importRes.data.succeed) {
        if(importRes.data.missingGeneratorPolicyFile) {
          this.props.showWarningSnackBar({msg: importRes.data.msg, hasHtmlContent: true});
        }
        else {
          this.props.showSuccessSnackBar({msg: importRes.data.msg});
        }
      }
      else {
        this.props.showErrorSnackBar({msg: importRes.data.msg, hasHtmlContent: true});
      }
    }
  };

  // GroupsSets & Groups
  // props: {id*: <Text, alwaysOpen**: boolean}
  //  *: GroupSet ID
  // **: Will alwys open the drawer even if it is already oppened
  handleToggleExpandGroupSet = props => () => {
    this.handleToggleExpandGroupSetCallBck(props);
  }

  handleToggleExpandGroupSetCallBck = props => {
    const groupSetIndex = this.state.groupSets.findIndex(
      obj => obj.id === props.id
    );

    let groupSet = Object.assign({}, this.state.groupSets[groupSetIndex]); // Copy
    // If there is parameter alwaysOpen, then open it even if it is already opened
    groupSet.expanded = props.alwaysOpen !== undefined ?
                        (props.alwaysOpen ? true : !groupSet.expanded) :
                        !groupSet.expanded;

    let array = update(this.state.groupSets, {
      [groupSetIndex]: {
        $set: groupSet,
      },
    });
    this.setState({
      groupSets: array,
    }, async () => {
      await this.saveGroupSetCallBack({groupSet: groupSet, notify: false});
    });
  }

  saveUserGroupSetCallBack = async userGroupSet => {
    try {
      let res = await groupService.saveUserGroupSet(userGroupSet);
      if(res.data.succeed) {
        this.props.showSuccessSnackBar({msg: res.data.msg});
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg});
      }
      return res.data.groupSet;
    }
    catch (e) {
      console.error('Some error occured while saving the group set list into the database.');
      this.props.showErrorSnackBar({msg: 'Some error occured while saving the group set list into the database.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  };

  addNewGroupSet = async () => {
    let array = this.state.groupSets.slice(); // Create a copy
    let groupSet = {
      name: '',
      expanded: false,
      groups: [],
      user: this.props.user,
    };
    // Persisting and re-retrieving
    groupSet = await this.saveGroupSetCallBack({groupSet: groupSet});
    array.push(groupSet);
    this.setState({
      userGroupSet: {
        ...this.state.userGroupSet,
        groupSets: array,
      },
      groupSets: array,
    }, async () => {
      // Persisting the userGroupSet
      await this.saveUserGroupSetCallBack(this.state.userGroupSet);
      // Closing the menu & setting this groupSet to edit mode
      this.setState({
        groupSetId: '',
        currentGroupSetEdited:  groupSet,
      }, () => {
        setTimeout(() => {
          this.state.groupRefsMap.get(groupSet.id).current.focus();
        }, 200); // 0,5 secs
      });
    });
  }

  // props: {groupSet: <Object>, notify*: <Boolean>}
  // * Optional with default value true
  saveGroupSetCallBack = async props => {
    const groupSet = props.groupSet;
    const notify = props.notify;
    try {
      let res = await groupService.saveGroupSet(groupSet);
      if(notify !== undefined ? notify : true) {
        if(res.data.succeed) {
          this.props.showSuccessSnackBar({msg: res.data.msg});
        }
        else {
          this.props.showErrorSnackBar({msg: res.data.msg});
        }
      }
      return res.data.groupSet;
    }
    catch (e) {
      console.error('Some error occured while saving the group set into the database.');
      this.props.showErrorSnackBar({msg: 'Some error occured while saving the group set into the database.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  };

  deleteGroupSetCallBack = async groupSet => {
    try {
      let res = await groupService.deleteGroupSet(groupSet);
      if(res.data.succeed) {
        this.props.showSuccessSnackBar({msg: res.data.msg});
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg});
      }
      return res.data.groupSet;
    }
    catch (e) {
      console.error('Some error occured while deleting the group set from the database.');
      this.props.showErrorSnackBar({msg: 'Some error occured while deleting the group set from the database.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  };

  saveGroupCallBack = async group => {
    try {
      let res = await groupService.saveGroup(group);
      if(res.data.succeed) {
        this.props.showSuccessSnackBar({msg: res.data.msg});
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg});
      }
      return res.data.group;
    }
    catch (e) {
      console.error('Some error occured while saving the group into the database.');
      this.props.showErrorSnackBar({msg: 'Some error occured while saving the group into the database.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  };

  deleteGroupCallBack = async group => {
    try {
      let res = await groupService.deleteGroup(group);
      if(res.data.succeed) {
        this.props.showSuccessSnackBar({msg: res.data.msg});
      }
      else {
        this.props.showErrorSnackBar({msg: res.data.msg});
      }
      return res.data.group;
    }
    catch (e) {
      console.error('Some error occured while deleting the group into the database.');
      this.props.showErrorSnackBar({msg: 'Some error occured while deleting the group into the database.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  };

  // makeId = (length) => {
  //   var result           = '';
  //   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   var charactersLength = characters.length;
  //   for ( var i = 0; i < length; i++ ) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // }

  // props: {id: <Text>}
  handleOpenGroupSetMenu = props => event => {
    const id = props.id;
    this.setState({
      // First set the anchor
      groupSetMenuAnchorEl: event.currentTarget,
    }, () => {
      this.setState({
        // Then make the menu appear
        groupSetId: id,
        groupSetHelperId: id,
      });
    });
  }

  handleCloseGroupSetMenu = event => {
    event.stopPropagation();
    this.setState({
      groupSetId: '',
    }, () => {
      this.setState({
        groupSetMenuAnchorEl: null,
      });
    });
  }

  // props: {id: <Text>, groupSetId: <Text>}
  handleOpenGroupMenu = props => event => {
    const id = props.id;
    const groupSetId = props.groupSetId !== undefined ? props.groupSetId : undefined;
    const type = props.type;
    this.setState({
      // First set the anchor
      groupMenuAnchorEl: event.currentTarget,
      groupSetHelperId: groupSetId,
    }, () => {
      this.setState({
        // Then make the menu appear
        groupId: id,
      });
    });
  }

  handleCloseGroupMenu = event => {
    event.stopPropagation();
    this.setState({
      groupId: '',
    }, () => {
      this.setState({
        groupMenuAnchorEl: null,
        groupSetHelperId: '',
      });
    });
  }

  // Whwn selecting a group, it filters the results based on it
  handleClickGroup = group => () => {
    this.handleClickGroupCallBack(group);
  }

  handleClickGroupCallBack = group => {
    this.setState({
      selectedGroup: group,
      page: 0,
    }, () => {
      this.retrieveMappingProjects();
    });
  }

  // GroupSet Actions

  // Using this.state.groupSetHelperId (set when the menu was opened)
  addNewGroupInGroupSet = async () => {
    //event.stopPropagation();
    const groupSetIndex = this.state.groupSets.findIndex(
      obj => obj.id === this.state.groupSetHelperId
    );
    //let groupSet = Object.assign({}, array[groupSetIndex]);
    let groupSet = Object.assign({}, this.state.groupSets[groupSetIndex]);
    let group = {
      name: '',
    };
    // Persisting and re-retrieving the Group
    group = await this.saveGroupCallBack(group);
    // Updating the GroupSet with the new Group
    groupSet.groups.push(group);
    groupSet = await this.saveGroupSetCallBack({groupSet: groupSet});
    // Persisting the GroupSet
    groupSet.expanded = true;
    // Updating the GroupSet in the list of groupSets of this user
    this.setState({
      groupSets: update(this.state.groupSets, {
        [groupSetIndex]: {
          $set: groupSet,
        },
      }),
    }, () => {
      // Closing the menu & setting this group to edit mode
      this.setState({
        groupSetId: '',
        groupSetHelperId: '',
        currentGroupEdited:  group,
      }, () => {
        this.setState({
          groupSetMenuAnchorEl: null,
        }, () => {
          console.log(this.state.groupRefsMap);
          setTimeout(() => {
            this.state.groupRefsMap.get(group.id).current.focus();
          }, 200); // 0,2 secs
        });
      });
    });
  }

  // Using this.state.groupSetId
  handleEditGroupSet = () => {
    //event.stopPropagation();
    const groupSetIndex = this.state.groupSets.findIndex(
      obj => obj.id === this.state.groupSetId
    );
    //let groupSet = Object.assign({}, array[groupSetIndex]);
    let groupSet = Object.assign({}, this.state.groupSets[groupSetIndex]);

    // Close the menu
    this.setState({
      groupSetId: '',
      currentGroupSetEdited: groupSet,
    }, () => {
      this.setState({
        groupSetMenuAnchorEl: null,
      }, ()=> {
        // Setting focus on the groupSet to edit (using input Ref)
        setTimeout(() => {
          this.state.groupRefsMap.get(groupSet.id).current.focus();
        }, 200); // 0,5 secs
      });
    });
  }

  handleDeleteGroupSet = props => event => {
    event.stopPropagation();
    this.setState({
      groupMenuAnchorEl: null,
    }, () => {
      this.showConfirmSnackBar({
        msg: 'Are you sue you want to delete this group set? All groups inside it will be lost!',
        iconSize: 'large',
        onConfirmClick: props.onConfirmClick,
        confirmButtonLabel: 'Yes I\'m sure',
        onCancelClick: props.onCancelClick,
        cancelButtonLabel: 'Close',
        argument: {},
      });
    });
  }

  handleConfirmDeleteGroupSet = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    this.handleConfirmDeleteGroupSetCallBack();
  }

  handleConfirmDeleteGroupSetCallBack = () => {
    if(this.state.groupSetId !== '' && this.state.groupSetId !== undefined) {
      const groupSetIndex = this.state.groupSets.findIndex(
        obj => obj.id === this.state.groupSetId
      );
      let groupSet = Object.assign({}, this.state.groupSets[groupSetIndex]);
      // Close the menu
      this.setState({
        groupSetId: '',
      }, () => {
        this.setState({
          groupSetMenuAnchorEl: null,
        }, () => {
          let updatedGropSets = update(this.state.groupSets, {
            $splice: [[groupSetIndex, 1]]
          });
          this.setState({
            groupSets: updatedGropSets,
            userGroupSet: {
              ...this.state.userGroupSet,
              groupSets: updatedGropSets,
            },
          }, async () => {
            // Delete the groupSet
            await this.deleteGroupSetCallBack(groupSet);
            await this.saveUserGroupSetCallBack(this.state.userGroupSet);
          });
        });
      });
    } // if(this.state.groupSetId !== '' && this.state.groupSetId !== undefined) - Close
  }

  // Groups

  // callBackProps: {ref: <Reference>, id: <Text, mapName: <Text>}
  // Groups' refs are held in groupRefsMap
  holdRefToMapCallBack = (callBackProps) => {
    this.setState((state, props) => {
      state[callBackProps.mapName].set(callBackProps.id, callBackProps.ref);
      return {
        [callBackProps.mapName]: state[callBackProps.mapName]
      }
    });
  }

  // Group Actions
  // Using this.state.groupId & this.state.groupSetHelperId
  // props: {groupId: <Text>, groupSetHelperId: <Text>}
  handleEditGroup = props => () => {
    const groupId = props.groupId;
    const groupSetHelperId = props.groupSetHelperId;
    const groupSetIndex = this.state.groupSets.findIndex(
      obj => obj.id === groupSetHelperId
    );
    //let groupSet = Object.assign({}, array[groupSetIndex]);
    let groupSet = Object.assign({}, this.state.groupSets[groupSetIndex]);

    const groupIndex = groupSet.groups.findIndex(
      obj => obj.id === groupId
    );
    let group = Object.assign({}, groupSet.groups[groupIndex]);
    // Close the menu
    this.setState({
      groupId: '',
      groupSetHelperId: '',
      currentGroupEdited: group,
    }, () => {
      this.setState({
        groupMenuAnchorEl: null,
      }, ()=> {
        // Setting focus on the group to edit (using input Ref)
        //this.state.groupRefsMap.get(group.id).current.focus();
        setTimeout(() => {
          this.state.groupRefsMap.get(group.id).current.focus();
          //console.log(document.querySelector(":focus"))
        }, 200); // 0,2 secs
      });
    });
  }

  // props: {onConfirmClick: <Function>, onCancelClick: <Function>}
  handleDeleteGroup = props => event => {
    event.stopPropagation();
    this.setState({
      groupMenuAnchorEl: null,
    }, () => {
      this.showConfirmSnackBar({
        msg: '<span>Are you sue you want to delete this group?</span>',
        iconSize: 'large',
        onConfirmClick: props.onConfirmClick,
        confirmButtonLabel: 'Yes I\'m sure',
        onCancelClick: props.onCancelClick,
        cancelButtonLabel: 'Close',
        argument: {},
      });
    });
  }

  handleConfirmDeleteGroup = props => () => {
    let snackbarId = props.snackBarId;
    this.props.closeSnackbar(snackbarId);
    this.handleConfirmDeleteGroupCallBack();
  }

  handleConfirmDeleteGroupCallBack = () => {
    if(this.state.groupSetHelperId !== '' && this.state.groupSetHelperId !== undefined) {
      const groupSetIndex = this.state.groupSets.findIndex(
        obj => obj.id === this.state.groupSetHelperId
      );
      let groupSet = Object.assign({}, this.state.groupSets[groupSetIndex]);
      const groupIndex = groupSet.groups.findIndex(
        obj => obj.id === this.state.groupId
      );
      let group = Object.assign({}, groupSet.groups[groupIndex]);
      // Close the menu
      this.setState({
        groupId: '',
        groupSetHelperId: '',
      }, () => {
        this.setState({
          groupMenuAnchorEl: null,
        }, () => {
          let groups = groupSet.groups.slice(); // Copy of array
          // Removing the group from the  group set
          groups.splice(groupIndex, 1);
          groupSet.groups = groups;
          this.setState({
            groupSets: update(this.state.groupSets, {
              [groupSetIndex]: {
                $set: groupSet,
              },
            }),
          }, async () => {
            // Delete the group
            await this.deleteGroupCallBack(group);
            // Persisting the groupSet
            await this.saveGroupSetCallBack({groupSet: groupSet});
          });
        });
      });
    } // if(this.state.groupSetHelperId !== '' && this.state.groupSetHelperId !== undefined) - Close
  }

  onGroupDrop = groupSet => ({ removedIndex, addedIndex }) => {
    //console.log({ removedIndex, addedIndex });
    const groupSetIndex = this.state.groupSets.findIndex(
      obj => obj.id === groupSet.id
    );
    let array = arrayMove(this.state.groupSets[groupSetIndex].groups, removedIndex, addedIndex);
    let updatedGroupSet = Object.assign({}, groupSet); // Copy
    updatedGroupSet.groups = array;

    this.setState({
      groupSets: update(this.state.groupSets, {
        [groupSetIndex]: {
          $set: updatedGroupSet,
        },
      }),
    });
  };

  onGroupSetDrop = ({ removedIndex, addedIndex }) => {
    //console.log({ removedIndex, addedIndex });
    let array = arrayMove(this.state.groupSets, removedIndex, addedIndex);
    this.setState({
      groupSets: array,
      userGroupSet: {
        ...this.state.userGroupSet,
        groupSets: array,
      },
    }, async () => {
      // Persisting the userGroupSet
      await this.saveUserGroupSetCallBack(this.state.userGroupSet);
    });
  };

  // props: {fieldName: <Text>}
  // * Optional parameter denoting that this is case of group (not groupSet)
  handleInputGroupNameChange = fieldName => event => {
    this.setState({
      currentGroupEdited: {
        ...this.state.currentGroupEdited,
        [fieldName]: event.target.value,
      }
    });
  }

  // props: {fieldName: <Text>}
  // * Optional parameter denoting that this is case of group (not groupSet)
  handleInputGroupSetNameChange = fieldName => event => {
    this.setState({
      currentGroupSetEdited: {
        ...this.state.currentGroupSetEdited,
        [fieldName]: event.target.value,
      }
    });
  }

  // props: {groupSetId: <Text>, groupId: <Text>}
  handleDisableEditGroupName = props => () => {
    // Checking if the current group's name is empty and give some name if it is
    this.setState({
      currentGroupEdited: {
        ...this.state.currentGroupEdited,
        name: this.state.currentGroupEdited.name !== '' ?
              this.state.currentGroupEdited.name :
              'New Group',
      }
    }, async () => {
      const groupSetId = props.groupSetId;
      const groupId = props.groupId;
      //let updatedGroup = Object.assign({}, this.state.currentGroupEdited); // Copy
      let updatedGroup = await this.saveGroupCallBack(this.state.currentGroupEdited);
      this.setState({
        currentGroupEdited: null
      }, () => {
        // Update state
        let groupSets = this.state.groupSets.slice(); // Create a copy
        const groupSetIndex = groupSets.findIndex(
          obj => obj.id === groupSetId
        );

        let groups = groupSets[groupSetIndex].groups.slice(); // Create a copy
        const groupIndex = groups.findIndex(
          obj => obj.id === groupId
        );

        let updatedGroups = update(groups, {
          [groupIndex]: {
            $set: updatedGroup,
          },
        });

        let updatedGroupSet = groupSets[groupSetIndex];
        groupSets[groupSetIndex].groups = updatedGroups

        let updatedGroupSets = update(groupSets, {
          [groupSetIndex]: {
            $set: updatedGroupSet,
          },
        });

        this.setState({
          groupSets:  updatedGroupSets,
        });

      });
    });
  }

  // props: {groupSetId: <Text>}
  handleDisableEditGroupSetName = props => () => {
    const groupSetId = props.groupSetId;
    // Checking if the current group set's name is empty
    this.setState({
      currentGroupSetEdited: {
        ...this.state.currentGroupSetEdited,
        name: this.state.currentGroupSetEdited.name !== '' ?
              this.state.currentGroupSetEdited.name :
              'New Group Set',
      }
    }, async () => {
      let updatedGroupSet = await this.saveGroupSetCallBack({groupSet: this.state.currentGroupSetEdited});
      this.setState({
        currentGroupSetEdited: null
      }, () => {
        // Update state
        let groupSets = this.state.groupSets.slice(); // Create a copy
        const groupSetIndex = groupSets.findIndex(
          obj => obj.id === groupSetId
        );
        this.setState({
          groupSets:  update(groupSets, {
            [groupSetIndex]: {
              $set: updatedGroupSet,
            },
          }),
        });
      });
    });
  }

  // Changes CSS when hovering over the delete icon of the Mapping
  // props: {status:'mouseEnter | mouseLeave', id: <Text>}
  handleHoverGroup = props => () => {
    this.setState({
      idOfHoveredGroup: props.status === 'mouseEnter' ? props.id : '',
    });
  }

  // props: {ids: <Array>, group: <Object>}
  // IDs of the MappingProjects to be updated and the group to be added in it's groups list.
  addMappingProjectsInGroup = async props => {
    const ids = props.ids;
    const group = props.group;
    let succeedMappings = [];
    let failedMappings = [];
    try {
      for (const id of ids) {
        let res = await mainService.addMappingProjectsInGroup({id: id, group: group});
        if(res.data.succeed) {
          succeedMappings.push(res.data.mappingProject);
        }
        else {
          failedMappings.push(res.data.mappingProject);
        }
      }
      if(failedMappings.length === 0 && succeedMappings.length > 0) {
        this.props.showSuccessSnackBar({msg: 'All mapping projects were successfully added to the group "' + group.name + '"'});
      }
      else if(failedMappings.length > 0 && succeedMappings.length > 0) {
        let warnMsg = 'Even though some of the mapping projects were successfully added to the group "' + group.name + '",  there were a few ones that were not added. These are the following mapping projects:' +
                      '<p><ul>';
        failedMappings.forEach(mappingProject => {
          warnMsg = warnMsg + '<li>';
          warnMsg = warnMsg + mappingProject.title + ' (HRID: ' + mappingProject.hrId + ')';
          warnMsg = warnMsg + '</li>';
        });
        warnMsg = warnMsg + '</ul></p>';
        this.props.showWarningSnackBar({msg: warnMsg, hasHtmlContent: true});
      }
      else {
        this.props.showErrorSnackBar({msg: 'No mapping project was added to the group "' + group.name + '"'});
      }

    }
    catch (e) {
      console.error('Some error occured while adding the mapping projects in the group.');
      this.props.showErrorSnackBar({msg: 'Some error occured while adding the mapping projects in the group.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  };

  // props: {mappingProjectId: <Text>, group: <Object>}
  removeMappingFromGroup = async props => {
    const id = props.mappingProjectId;
    const group = props.group;
    try {
      // Retriving the full mapping project
      let resMappingProject = await mainService.retrieveMappingProjectById({id: id});
      let mappingProjectToUpdate = resMappingProject.data;
      // Updating its list of groups
      const groupIndex = mappingProjectToUpdate.groups.findIndex(
        obj => obj.id === group.id
      );
      mappingProjectToUpdate.groups.splice(groupIndex, 1);
      // Persisting to the database
      const saveRes = await mainService.saveMappingProject(mappingProjectToUpdate);
      if(saveRes.data.succeed) {
        this.props.showSuccessSnackBar({msg: 'The mapping project was successfuly removed from the group'});
      }
      else {
        this.props.showErrorSnackBar({msg: 'The mapping project could not be removed from the group'});
      }
    }
    catch (e) {
      console.error('Some error occured while removing the mapping project from the group.');
      this.props.showErrorSnackBar({msg: 'Some error occured while removing the mapping project from the group.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  // Generic way of displaying confirmations

  handleCloseConfirmationSnackbar = props => () => {
    // Hide the snackbar
    this.props.closeSnackbar(props.snackBarId);
  }

  // props: {
  //    msg: <Text>,
  //    iconSize: 'large | small (default: 'small')'
  //    onConfirmClick: <Function>
  //    confirmButtonLabel: <Text>
  //    onCancelClick: <Function>
  //    cancelButtonLabel: <Text>,
  //    argument: <Object> Holding anything I need to pass to ConfirmSnackMessage component
  // }
  showConfirmSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <ConfirmSnackMessage
            id={key}
            msg={props.msg}
            variant={'warning'}
            iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
            hasHtmlContent={true}
            onConfirmClick={props.onConfirmClick}
            confirmButtonLabel={props.confirmButtonLabel}
            onCancelClick={props.onCancelClick}
            cancelButtonLabel={props.cancelButtonLabel}
            argument={props.argument}
          />
        ),
      }
    );
  }

  resetGroupSelections = ()  => {
    this.setState({
      selectedGroup: '',
    }, () => {
        this.retrieveMappingProjects();
    });
  }

  // props: {mappingProjectId: <Text>, mappingProjectGroups: <Array>}
  findFirstUsedGroupOfMappingProject = props => {
    const { classes } = this.props;
    const mappingProjectId = props.mappingProjectId;
    const mappingProjectGroups = props.mappingProjectGroups;
    var result = null;
    var tmpGroupSetId = null; // to be used when caling the handleClickGroupChip
    this.state.groupSets !== undefined ?
    (
      this.state.groupSets.some(groupSet => {
        groupSet.groups.some(group => {
          if(mappingProjectGroups !== null) {
            // findIndex returns the index of the first element to be found (if any)
            const groupIndex = mappingProjectGroups.findIndex(
              obj => obj.id === group.id
            );
            if(groupIndex !== -1) {
              result = group;
              tmpGroupSetId = groupSet.id;
              return result;
            }
          } // if(n.groups !== null) - Ends
        });
      })
    ) :
    null;
    // return result !== null ? <span className={classes.groupNextToTitle}>{result.name}</span> : '';
    return result !== null ?
    <Tooltip title={result.name}
             placement="top-start"
             enterDelay={500}
             leaveDelay={200}
    >
      <Chip
        label={result.name}
        onDelete={this.handleRemoveGroupFromMappingProject({mappingProjectId: mappingProjectId, group: result})}
        onClick={
          this.handleClickGroupChip({group: result, groupSetId: tmpGroupSetId})
        }
        size="small"
        color="primary"
        className={classes.groupNameStyle}
        style={{marginRight: 10}}
      />
    </Tooltip> : '';
  }

  // props: {group: <Object>, groupSetId: <Text>}
  handleClickGroupChip = props => () => {
    const group = props.group;
    const groupSetId = props.groupSetId;

    this.setState({
      groupsPanelShown: true,
      page: 0,
    }, () => {
      this.handleToggleExpandGroupSetCallBck({id: groupSetId, alwaysOpen: true})
      this.handleClickGroupCallBack(group);
    });

  }

  // props: {mappingProjectId: <Text>, group: <Text>}
  handleRemoveGroupFromMappingProject = props => () => {
    this.showConfirmSnackBar({
      msg: '<span>Are you sure you want to remove this mapping project from the group?</span>',
      iconSize: 'large',
      onConfirmClick: this.handleConfrmRemoveGroupFromMappingProject,
      confirmButtonLabel: 'Yes remove it',
      onCancelClick: this.handleCloseRemoveGroupFromMappingProjectConfirmationSnackbar,
      cancelButtonLabel: 'Close',
      argument: props,
    });
  }

  // props: {..., argument: {mappingProjectId: <Text>, group: <Object>}}
  handleConfrmRemoveGroupFromMappingProject = props => () => {
    // Hide the snackbar
    this.props.closeSnackbar(props.snackBarId);
    const mappingProjectId = props.argument.mappingProjectId;
    const group = props.argument.group;
    let updatedData = this.state.data.slice(); // Create a copy

    const dataIndex = this.state.data.findIndex(
      obj => obj.id === mappingProjectId
    );
    let mappingProjectMetadata = Object.assign({}, this.state.data[dataIndex]); // Copy
    const groupIndex = mappingProjectMetadata.groups.findIndex(
      obj => obj.id === group.id
    );
    mappingProjectMetadata.groups.splice(groupIndex, 1);

    updatedData = update(updatedData, {
      [dataIndex]: {
        $set: mappingProjectMetadata,
      },
    });
    this.setState({
        data: updatedData,
    }, async () => {
      await this.removeMappingFromGroup(props.argument)
    });
  }

  handleCloseRemoveGroupFromMappingProjectConfirmationSnackbar = props => () => {
    this.props.closeSnackbar(props.snackBarId);
  }

  // Menu for applying actions on the selected Mapping Projects (apart from delete)
  handleOpenAddGroupMenu = event => {
    this.setState({
      addGroupMenuAnchorEl: event.currentTarget
    });
  };

  handleCloseAddGroupMenu = () => {
    this.setState({
      addGroupMenuAnchorEl: null
    });
  };

  // props: {group: <Object>}
  handleAddMappingProjectToGroup = props => () => {
    const group = props.group;
    let updatedData = this.state.data.slice(); // Create a copy
    // Iterating the selected MappingProjectMetadata
    this.state.selected.map((id) => {
      const dataIndex = this.state.data.findIndex(
        obj => obj.id === id
      );
      let mappingProjectMetadata = Object.assign({}, this.state.data[dataIndex]); // Copy
      if(mappingProjectMetadata.groups !== null) {
        mappingProjectMetadata.groups.push(group);
      }
      else {
        mappingProjectMetadata.groups = [];
        mappingProjectMetadata.groups.push(group);
      }
      updatedData = update(updatedData, {
        [dataIndex]: {
          $set: mappingProjectMetadata,
        },
      });
    });
    this.setState({
      data: updatedData,
      addGroupMenuAnchorEl: null,
      manageModeActive: false,
    }, async () => {
      await this.addMappingProjectsInGroup({ids: this.state.selected, group: group});
      this.setState({
        selected: [],
      });
    });
  }

  handleGoBack = async () => {
    this.props.history.goBack();
  }

  printState = () => {
    console.log(this.state);
    //console.log(JSON.stringify(this.state));
  }

  render() {
    const { classes } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, modeName } = this.state;
    var { data } = this.state;
    /*
    data =
      this.state.searchText !== null && this.state.searchText !== '' ?
      (
        this.state.columnToBeFiltered.id === 'all' ?
        data.filter(
          x =>  x['title'].toLowerCase().includes(this.state.searchText.toLowerCase()) ||
                x['hrId'].toLowerCase().includes(this.state.searchText.toLowerCase()) ||
                x['fullName'].toLowerCase().includes(this.state.searchText.toLowerCase())
        ) :
        data.filter(
          x => x[this.state.columnToBeFiltered.id] !== null ?
          x[this.state.columnToBeFiltered.id].toLowerCase().includes(this.state.searchText.toLowerCase()) :
          false
        )
      ) :
      data;
      */
      // Remember that you have added the boolean "useInAllFilter" flag in each of the rows
      data =
        this.state.searchText !== null && this.state.searchText !== '' ?
        (
          this.state.columnToBeFiltered.id === 'all' ?
          data.filter( x => {
            for (var row of rows.filter(row => row.useInAllFilter)) {
              if(x[row.id].toLowerCase().includes(this.state.searchText.toLowerCase()))
                return true;
            }
            return false;
          }) :
          data.filter(
            x => x[this.state.columnToBeFiltered.id] !== null ?
            x[this.state.columnToBeFiltered.id].toLowerCase().includes(this.state.searchText.toLowerCase()) :
            false
          )
        ) :
        data;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    let singleSelectedIndex = selected.length > 0 ? this.state.data.findIndex(x => x.id === selected[0]) : -1;
    const fromManagement = this.state.fromManagement !== undefined ? this.state.fromManagement : false;
    const forUser = this.state.forUser !== undefined ? this.state.forUser : '';
    const forType = this.state.forType !== undefined ? this.state.forType : '';
    return (
      <div>
        <Paper
          className={clsx(classes.tableWrapperRoot, {
            [classes.tableShift]: this.state.groupsPanelShown,
          })}
        >
          <ProjectTableToolbar
              numSelected={selected.length}
              deleteModeActive={this.state.deleteModeActive}
              mineOnlyModeActive={this.state.mineOnlyModeActive}
              participatingModeActive={this.state.participatingModeActive}
              handleMineOnlyModeChange={this.handleMineOnlyModeChange}
              handleParticipatingOnlyModeChange={this.handleParticipatingOnlyModeChange}
              handleDeleteSelected={this.handleDeleteSelected}
              handleInputChange={this.handleInputChange}
              handleSelectInputChange={this.handleSelectInputChange}
              searchText={this.state.searchText}
              columnToBeFiltered={this.state.columnToBeFiltered}
              groupsPanelShown={this.state.groupsPanelShown}
              handleShowGroupsPanel={this.handleShowGroupsPanel}
              manageModeActive={this.state.manageModeActive}
              handleOpenAddGroupMenu={this.handleOpenAddGroupMenu}
              handleCloseAddGroupMenu={this.handleCloseAddGroupMenu}
              addGroupMenuAnchorEl={this.state.addGroupMenuAnchorEl}
              handleAddMappingProjectToGroup={this.handleAddMappingProjectToGroup}
              groupSets={this.state.groupSets}
              fromManagement={fromManagement}
              forUser={forUser}
              forType={forType}
              handleGoBack={this.handleGoBack}
          />
          <div className={classes.tableWrapper}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle">
              <ProjectTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={
                  data.filter(mappingProject =>
                    this.state.manageModeActive ?
                    (
                      this.findFirstUsedGroupOfMappingProject({
                        mappingProjectId: mappingProject.id,
                        mappingProjectGroups: mappingProject.groups
                      }) === ''
                    ) :
                    (
                      this.state.deleteModeActive ?
                        !this.state.forceAdminModeActive ?
                        mappingProject.author.id === this.props.user.id :
                        true :
                      false
                    )
                  )
                  .length
                }
                showSelectAllCheckbox ={this.state.deleteModeActive || this.state.manageModeActive}
                manageModeActive={this.state.manageModeActive}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    const firstUsedGroupOfMappingProject = this.findFirstUsedGroupOfMappingProject({mappingProjectId: n.id, mappingProjectGroups: n.groups});
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, n)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                        classes={!this.state.deleteModeActive ? {selected: classes.selectedRow} : {}}
                        style={{height: 49}}>
                        <TableCell padding="checkbox">
                          <Fade
                            in={
                              (
                                this.state.deleteModeActive ?
                                  !this.state.forceAdminModeActive ?
                                  n.author.id === this.props.user.id :
                                  true :
                                false
                              ) ||
                              (
                                this.state.manageModeActive ?
                                firstUsedGroupOfMappingProject === '' :
                                false
                              )
                            }
                            timeout={{enter: 300, exit: 300}}
                          >
                          <div>
                            <Checkbox
                              checked={isSelected}
                              hidden={!this.state.deleteModeActive && !this.state.manageModeActive}
                              color={this.state.manageModeActive ? 'primary' : 'secondary'}
                            />
                          </div>
                        </Fade>

                        </TableCell>
                        {
                          rows.map((row) => {
                            const key = row.id;
                            const value = n[row.id];

                            // Determining the respective type set at header rows
                            const rowIndex = rows.findIndex(
                              obj => obj.id === key
                            );
                            // Only handle those columns set in this.props.rows array
                            if(rowIndex !== -1) {
                              const isSourceInputCoverage = key === 'sourceInputCoverage';
                              const isAvatar = rows[rowIndex].avatar;
                              const isDate = rows[rowIndex].date;
                              const isTitle = key === 'title';
                              const isLink = rows[rowIndex].link !== undefined;
                              const hasTooltip = rows[rowIndex].tooltip !== undefined;

                              if(key !== 'id' && key !== 'description' && key !== 'all' && value !== null) {
                                return (
                                  <TableCell key={key + "_" + n.id} component="th" scope="row" padding="none">
                                    {
                                      isSourceInputCoverage ?
                                      (
                                        value !== '' ?
                                        <Tooltip
                                          title={'Source Input Coverage'}
                                          placement="left"
                                          enterDelay={500}
                                          leaveDelay={200}
                                        >
                                          <div>
                                            <DonutChartProgress
                                              value={value}
                                              size={32}
                                              thickness={4}
                                              variant="caption"
                                              fillInternalCircle={false}
                                              inputProps={{
                                                classes: {
                                                  root: {},
                                                  internalCircle: classes.internalCircle,
                                                  circularWrapper: classes.circularWrapper,
                                                  completeCoverage: classes.fullSourceInputCoverage,
                                                },
                                              }}
                                              style={{}}
                                            />
                                          </div>
                                        </Tooltip> :
                                        ''
                                      ) :
                                      isAvatar ?
                                      <Tooltip
                                        title={
                                          n.fullName +
                                          (
                                            n.author !== undefined ?
                                            (
                                              n.author.username !== undefined ?
                                              (' (' + n.author.username + ')') :
                                              ''
                                            ) :
                                            ''
                                          )
                                        }
                                        placement="right"
                                        enterDelay={500}
                                        leaveDelay={200}
                                      >
                                        <Avatar alt="user-avatar"
                                                src={value !== undefined ? avatarRootContextPath + value : ''}
                                        />
                                      </Tooltip> :
                                      isDate ?
                                      <span style={{fontSize: 'smaller'}}>
                                        {new Date(value).toDateString()}
                                        {' '}
                                        {new Date(value).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                      </span> :
                                      isTitle ?
                                      <span>
                                        <span>
                                          {firstUsedGroupOfMappingProject}
                                        </span>
                                        <span>
                                          {value !== null && value !== undefined ? value.toString() : ''}
                                        </span>
                                      </span> :
                                      hasTooltip ?
                                      <Tooltip
                                        title={rows[rowIndex].tooltip}
                                        placement="right"
                                        enterDelay={300}
                                      >
                                        {
                                          isLink ?
                                          <Link 
                                            href={rows[rowIndex].link.replace('<value>', value.toString())} 
                                            onClick={(event) => event.preventDefault()}
                                            disabled={// Disabled when draft
                                              n.status !== undefined ? n.status === 'Draft' : false
                                            }
                                          >
                                            {value.toString()}
                                          </Link> :
                                          value.toString()
                                        }
                                      </Tooltip> :
                                      (
                                        isLink ?
                                        <Link 
                                          href={rows[rowIndex].link.replace('<value>', value.toString())} 
                                          onClick={(event) => event.preventDefault()}
                                          disabled={// Disabled when draft
                                            n.status !== undefined ? n.status === 'Draft' : false
                                          }
                                        >
                                          {value.toString()}
                                        </Link> :
                                        value.toString()
                                      )
                                    }
                                  </TableCell>
                                );
                              }
                              else {
                                return null
                              }
                            }
                          })
                        }
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
          <div style={{display:'flex'}}>
            <div style={{paddingBottom: 10}}>
              <Grow in={!this.state.deleteModeActive && !this.state.manageModeActive} timeout={{enter: 300, exit: 300}}>
                <div style={{display:'flex', position: 'absolute'}}>
                  <div style={{paddingBottom:10, paddingLeft:20}}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleOpenMapping}
                      disabled={
                        !(
                          singleSelectedIndex !== -1 ?
                          this.state.data[singleSelectedIndex].status !== 'Draft' :
                          false
                        )
                      }
                    >
                    {/*
                      to={{
                        pathname: rootContextPath + '/MappingTableView',
                        state: {
                          selectedId: this.state.selected[0]
                        }
                      }}
                      component={Link}
                    */}
                      <OpenInNewIcon className={classes.leftIcon} />
                      Open
                    </Button>
                  </div>
                  <div hidden={fromManagement} style={{paddingBottom:10, paddingLeft:20}}>
                    <Button variant="contained"
                            color="primary"
                            onClick={this.handleCloneMapping}
                            disabled={
                              !(
                                singleSelectedIndex !== -1 ?
                                this.state.data[singleSelectedIndex].status !== 'Draft' :
                                false
                              )}>
                      <Icon className={classNames(classes.fontAwesomeIcon, 'fas fa-clone')}/>
                      Clone
                    </Button>
                  </div>
                  <div hidden={fromManagement} style={{paddingBottom:10, paddingLeft:20}}>
                    <Button variant="contained" color="primary" onClick={this.handleOpenWizard}>
                      <AddIcon className={classes.leftIcon}/>
                      Add New
                    </Button>
                  </div>
                  <div hidden={fromManagement} style={{paddingBottom:10, paddingLeft:20}}>
                    <Button variant="contained" color="primary" onClick={this.handleOpenZipImportDialog}>
                      <Icon className={classNames(classes.fontAwesomeIcon, 'fa fa-upload')}/>
                      Import From ZIP
                    </Button>
                  </div>
                  <div hidden={fromManagement} style={{paddingBottom:10, paddingLeft:20}}>
                    <Fade
                      in={
                        singleSelectedIndex !== -1 ?
                        this.state.data[singleSelectedIndex].status === 'Draft' :
                        false
                      }
                      timeout={{enter: 300, exit: 300}}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleOpenWizardForSelected}
                      >
                        Continue with wizard...
                      </Button>
                    </Fade>
                  </div>
                </div>
              </Grow>
            </div>
            <div hidden={fromManagement} style={{paddingBottom:10, paddingLeft:20}}>
              <Grow in={this.state.deleteModeActive} timeout={{enter: 300, exit: 300}}>
                <div style={{paddingBottom:10, paddingLeft:20, position: 'absolute'}}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={this.handleDeleteSelected}
                    disabled={this.state.selected.length === 0}
                  >
                    Delete Selected
                  </Button>
                  {
                    this.props.user !== '' ?
                      this.props.user.roles.includes('administrator') ?
                      <Tooltip
                        title={'When enabled, you can delete mapping projects of others'}
                        placement="bottom"
                        enterDelay={500}
                        leaveDelay={200}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.forceAdminModeActive}
                              onChange={this.handleForceAdminModeActiveChange}
                              value={this.state.forceAdminModeActive}
                              color="secondary"
                            />
                          }
                          label="Use Administrator's Privileges"
                          style={{paddingLeft: 20}}
                          classes={{label: classes.boldLabel}}
                        />
                      </Tooltip> :
                      '' :
                    ''
                  }
                </div>
              </Grow>
            </div>
            <div style={{paddingBottom:10, paddingLeft:20}}>
              <Grow in={this.state.manageModeActive} timeout={{enter: 300, exit: 300}}>
                <div style={{paddingBottom:10, paddingLeft:20, position: 'absolute'}}>
                  <Button variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={this.handleOpenAddGroupMenu}
                          disabled={this.state.selected.length === 0}>
                    Add to Group
                  </Button>
                </div>
              </Grow>
            </div>
            <div style={{marginLeft: 'auto', marginRight: 5, marginTop: -20}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.manageModeActive}
                    onChange={this.handleManageModeChange}
                    value={this.state.manageModeActive}
                    color="secondary"
                    disabled={
                      this.state.groupSets.flatMap(groupSet => groupSet.groups).length === 0
                    }
                  />
                }
                label="Organise Mode"
                style={{paddingTop: 20}}
                classes={{label: classes.boldLabel}}
              />
              <FormControlLabel
                hidden={fromManagement}
                control={
                  <Switch
                    checked={this.state.deleteModeActive}
                    onChange={this.handleDeleteModeChange}
                    value={this.state.deleteModeActive}
                    color="secondary"
                  />
                }
                label="Delete Mode"
                style={{paddingTop: 20}}
                classes={{label: classes.boldLabel}}
              />
            </div>
          </div>
          <InsertMappingDialog
            currUser={this.props.currUser}
            updateAuthStatusOfCurrUser={this.props.updateAuthStatusOfCurrUser}
            retrieveMappingProjects={this.retrieveMappingProjects}
            wizardShown={this.state.wizardShown}
            selectedMetadataId={this.state.selectedMetadataId}
            handleOpenWizard={this.handleOpenWizard}
            handleCloseWizard={this.handleCloseWizard}
            showErrorSnackBar={this.props.showErrorSnackBar}
          />

          {/*
          X3ML Import Dialog
          */}
          <Dialog
            open={this.state.zipImportDialogOpen}
            aria-labelledby="modal"
            TransitionComponent={Fade}
            transitionDuration={{enter: 400, exit: 400}}
            maxWidth="xl"
            fullScreen={this.state.zipImportDialogFullScreen}
            PaperProps={{
              classes: {
                root: classes.dialogCustomPaper,
              }
            }}
          >
            <DialogTitle style={{paddingBottom: 0}}>
              Import From ZIP File
              <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
                <Tooltip
                  title={this.state.zipImportDialogFullScreen ? 'Minimize' : 'Maximize'}
                  placement="bottom"
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <IconButton className={classes.button}
                              aria-label="maximize"
                              onClick={this.handleZipImportToggleFullScreen}
                              style={{marginRight: -10}}>
                    <FilterNoneIcon hidden={!this.state.zipImportDialogFullScreen} style={{width: 20}}/>
                    <CropSquareIcon hidden={this.state.zipImportDialogFullScreen}/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close"
                         placement="bottom"
                         enterDelay={500}
                         leaveDelay={200}>
                  <IconButton className={classes.button}
                              aria-label="close"
                              onClick={this.handleCloseZipImportDialog}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <Divider style={{marginTop: 15, marginLeft: -24, marginRight: -24}}/>

            </DialogTitle>

            <DialogContent>
              <div style={{minWidth: 500}}>
                <FilePond
                  name="file"
                  labelIdle={'<div class="filePondCustomLabelStyle">Drag & Drop the ZIP file to import here or <span class="filepond--label-action"> browse for it </div>'}
                  ref={ref => this.pond = ref}
                  server={
                    {
                      url: (dirRootContextPath === '' ? '.' : '') + dirRootContextPath + '/uploadFilepond/zipImported',
                      process: {
                        headers: {
                          Authorization: Cookies.get('3mToken')
                        },
                      },
                      revert: {
                        headers: {
                          Authorization: Cookies.get('3mToken')
                        },
                        method: 'DELETE'
                      }
                    }
                  }
                  allowMultiple={false}
                  allowRevert={false}
                  allowReplace={true}
                  acceptedFileTypes={['application/zip', 'application/x-zip-compressed']}
                  labelFileProcessingError={this.handleZipImportFileProcessingError}
                  beforeRemoveFile={this.authenticationCheck}
                  onprocessfile={this.handleAfterZipImportFileUpload}
                  beforeAddFile={this.authenticationCheck}
                  className={classes.zipImportFilePondRoot}
                  credits="false"
                />
              </div>
            </DialogContent>
            <DialogActions style={{display: 'unset', textAlign: 'right', paddingRight: 15}}>
              <Button onClick={this.handleCloseZipImportDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.snackbarShown}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: classes.snackbarWarning,
              }
            }}
            message={
              <div className={classes.snackbarMessageContainer}>
                <div className={classes.snackbarMessageText}>
                  <WarningIcon className={classNames(classes.largeSnackbarIcon, classes.snackbarIconVariant)} />
                </div>
                <div className={classes.snackbarMessageText}>
                  {
                     this.state.selected.length > 1 ?
                     'Are you sure you want to delete these ' + this.state.selected.length + ' selected mappings?' :
                     'Are you sure you want to delete this mapping?'
                  }
                </div>
              </div>
            }
            action={[
              <Button key="confirmDeleteSelectedMappings" color="secondary" size="small" onClick={this.handleConfirmDeleteSelectedMappings}>
                 <b>Yes Delete {this.state.selected.length > 1 ?'Them' : 'It'
                 }</b>
              </Button>,
              <Button key="cancelDeleteSelectedMappings" color="secondary" size="small" onClick={this.handleCancelDeleteSelectedMappings}>
                 <b>Cancel</b>
              </Button>,
            ]}
          />
        </Paper>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.state.groupsPanelShown}
          classes={{
            paper: classes.drawerPaper,
          }}
          SlideProps={{
            mountOnEnter: true,
            unmountOnExit: true,
          }}
        >
          <Paper className={classes.drawerContent}>
            <Toolbar className={classes.drawerTitle}>
              <div style={{display: 'flex', width: '100%'}}>
                <Typography variant="h6">
                  Groups
                </Typography>
                <div style={{flexGrow: 1}}/>
                <IconButton
                  size="small"
                  onClick={this.handleShowGroupsPanel}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </div>
            </Toolbar>
            <Typography variant="body1" style={{fontStyle: 'italic', marginBottom: 10, marginLeft: 10, marginRight: 10}}>
              Organize your Mapping Projects in groups.
            </Typography>
            {
            this.state.groupSets.length > 0 ?
            <div style={{width: '100%', marginBottom: 10, display: 'flex'}}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.resetGroupSelections}
                style={{margin: 'auto'}}
              >
                Deselect any Group
              </Button>
            </div> :
            ''
            }
            {/* GroupSets */}
            <List className={classes.groupSetListStyle}>
              <Container
                dragHandleSelector=".drag-handle-group-set"
                lockAxis="y"
                onDrop={this.onGroupSetDrop}
              >
                {
                  this.state.groupSets.length === 0 ?
                  <div style={{marginLeft: 10, marginRight: 10, marginBottom: 10, fontStyle: 'italic'}}>
                    No group set has been constructed yet. <br />
                    You can create group sets by click the button below.
                  </div> :
                  this.state.groupSets.map(groupSet => (
                    <Draggable key={'GroupSet-' + groupSet.id}>
                      <div>
                        <Divider />
                        <div style={{display: 'flex'}} className={classes.groupSetListItemTextStyle}>
                          <div style={{margin: 'auto', marginRight: 0, marginLeft: 10}}>
                            <DragHandleIcon className="drag-handle-group-set" />
                          </div>
                          <ListItem
                            classes={{
                              container: classes.customGroupListItemContainer,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={this.handleToggleExpandGroupSet({id: groupSet.id})}
                              style={{marginRight: 10}}
                            >
                              {groupSet.expanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>

                            <ListItemText
                              primary={
                                <div style={{display: 'flex', marginTop: -10}}>{/*, marginTop: -10*/}
                                  <Fade
                                    in={
                                      this.state.currentGroupSetEdited !== null ?
                                      this.state.currentGroupSetEdited.id !== groupSet.id :
                                      true
                                    }
                                    timeout={{enter: 300, exit: 300}}
                                    style={{position: 'absolute'}}
                                    >
                                    <div className={classes.groupNameStyle}>
                                      {groupSet.name}
                                    </div>
                                  </Fade>
                                  <Fade
                                    in={
                                      this.state.currentGroupSetEdited !== null ?
                                      this.state.currentGroupSetEdited.id === groupSet.id :
                                      false
                                    }
                                    timeout={{enter: 300, exit: 300}}
                                    style={{position: 'absolute'}}
                                  >
                                    <div style={{height: 40, marginTop:-6, marginRight: 60}}>
                                      {/*99999999*/}
                                      <ReferencedInput
                                        placeholder="Group Set Name"
                                        onChange={this.handleInputGroupSetNameChange('name')}
                                        onBlur={this.handleDisableEditGroupSetName({groupSetId: groupSet.id})}
                                        value={
                                          this.state.currentGroupSetEdited !== null ?
                                          this.state.currentGroupSetEdited.name :
                                          ''
                                        }
                                        id={groupSet.id}
                                        holdRefToMapCallBack={this.holdRefToMapCallBack}
                                      />
                                    </div>
                                  </Fade>
                                </div>
                              }
                            />

                            <ListItemSecondaryAction>
                              <IconButton
                                size="small"
                                onClick={this.handleOpenGroupSetMenu({id: groupSet.id, type: 'groupSet'})}
                                aria-owns={this.state.groupSetMenuAnchorEl ? 'group-set-menu' : undefined}
                                aria-haspopup={true}
                                aria-controls={'group-set-menu'}
                              >
                                <MoreVertIcon fontSize="small"/>
                              </IconButton>

                            </ListItemSecondaryAction>
                          </ListItem>
                        </div>
                        <Collapse in={groupSet.expanded} timeout="auto" unmountOnExit>
                          {/* Groups */}
                          <List component="div" disablePadding>
                            <Container
                              dragHandleSelector=".drag-handle"
                              lockAxis="y"
                              onDrop={this.onGroupDrop(groupSet)}
                              style={{paddingBottom: 10, paddingTop: 10}}
                            >
                              {
                                groupSet.groups.length === 0 ?
                                <div style={{marginLeft: 10, marginRight: 10, fontStyle: 'italic'}}>
                                  There are no groups here yet
                                </div> :
                                groupSet.groups.map(group => (
                                  <Draggable key={'Group-' + group.id}>
                                    <div
                                      style={{display: 'flex'}}
                                      onMouseEnter={this.handleHoverGroup({status:'mouseEnter', id: group.id})}
                                      onMouseLeave={this.handleHoverGroup({status:'mouseLeave', id: group.id})}
                                    >
                                      <Fade
                                        in={
                                          this.state.currentGroupEdited !== null ?
                                          this.state.currentGroupEdited.id !== group.id :
                                          true
                                        }
                                        timeout={{enter: 300, exit: 300}}
                                      >
                                        <div style={{margin: 'auto', marginLeft: 20}}>
                                          <DragHandleIcon className="drag-handle" />
                                        </div>
                                      </Fade>
                                      <ListItem
                                        button={
                                          this.state.currentGroupEdited !== null ?
                                          this.state.currentGroupEdited.id !== group.id :
                                          true
                                        }
                                        className={classes.nestedGroup}
                                        onClick={this.handleClickGroup(group)}
                                        classes={{
                                          container: classes.customGroupListItemContainer,
                                        }}
                                        selected={
                                          this.state.selectedGroup !== '' ?
                                          this.state.selectedGroup.id === group.id :
                                          false
                                        }
                                      >
                                        <ListItemText
                                          primary={
                                            <div style={{display: 'flex', marginTop: -10}}>{/*, marginTop: -10*/}
                                              <Fade
                                                in={
                                                  this.state.currentGroupEdited !== null ?
                                                  this.state.currentGroupEdited.id !== group.id :
                                                  true
                                                }
                                                timeout={{enter: 300, exit: 300}}
                                                style={{position: 'absolute'}}
                                                >
                                                <div className={classes.groupNameStyle}>
                                                  {/*<span style={{marginLeft: 5}}>G</span>*/}
                                                  {group.name}
                                                </div>
                                              </Fade>
                                              <Fade
                                                in={
                                                  this.state.currentGroupEdited !== null ?
                                                  this.state.currentGroupEdited.id === group.id :
                                                  false
                                                }
                                                timeout={{enter: 300, exit: 300}}
                                                style={{position: 'absolute'}}
                                              >
                                                <div style={{height: 40, marginTop:-6, marginRight: 60}}>

                                                  <ReferencedInput
                                                    placeholder="Group Name"
                                                    onChange={this.handleInputGroupNameChange('name')}
                                                    onBlur={this.handleDisableEditGroupName({groupSetId: groupSet.id, groupId: group.id})}
                                                    value={
                                                      this.state.currentGroupEdited !== null ?
                                                      this.state.currentGroupEdited.name :
                                                      ''
                                                    }
                                                    id={group.id}
                                                    holdRefToMapCallBack={this.holdRefToMapCallBack}
                                                  />
                                                </div>
                                              </Fade>
                                            </div>
                                          }
                                        />
                                        <ListItemSecondaryAction style={{display: 'flex'}}>
                                          <Fade
                                            in={
                                              this.state.currentGroupEdited !== null ?
                                              (
                                                this.state.currentGroupEdited.id !== group.id ?
                                                this.state.idOfHoveredGroup === group.id :
                                                false
                                              ) :
                                              this.state.idOfHoveredGroup === group.id
                                            }
                                            timeout={{enter: 300, exit: 300}}
                                          >
                                            <div style={{marginTop: -2}}>
                                              <IconButton
                                                size="small"
                                                onClick={this.handleOpenGroupMenu({id: group.id, groupSetId: groupSet.id, type: 'group'})}
                                                aria-owns={this.state.groupMenuAnchorEl ? 'group-menu' : undefined}
                                                aria-haspopup={true}
                                                style={{marginRight: 10}}
                                                aria-controls={'group-menu'}
                                              >
                                                <MoreVertIcon fontSize="small"/>
                                              </IconButton>
                                            </div>
                                          </Fade>
                                        </ListItemSecondaryAction>

                                      </ListItem>
                                    </div>
                                  </Draggable>
                                ))
                              }
                            </Container>
                          </List>
                        </Collapse>
                      </div>
                    </Draggable>
                  ))
                }
                {/*Menu For the GroupSets*/}
                <Menu
                  id={'group-set-menu'}
                  anchorEl={this.state.groupSetMenuAnchorEl}
                  keepMounted
                  open={Boolean(this.state.groupSetId)}
                  onClose={this.handleCloseGroupSetMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  transformOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                  <MenuItem onClick={this.addNewGroupInGroupSet}>Add Group</MenuItem>
                  <MenuItem onClick={this.handleEditGroupSet}>Edit</MenuItem>
                  <MenuItem
                    onClick={
                      this.handleDeleteGroupSet({
                        onConfirmClick: this.handleConfirmDeleteGroupSet,
                        onCancelClick: this.handleCloseConfirmationSnackbar
                      })
                    }
                  >
                    Remove
                  </MenuItem>
                </Menu>

                {/*Menu For the Groups*/}
                <Menu
                  id={'group-menu'}
                  anchorEl={this.state.groupMenuAnchorEl}
                  keepMounted
                  open={Boolean(this.state.groupMenuAnchorEl)}
                  onClose={this.handleCloseGroupMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  transformOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                  <MenuItem
                    onClick={
                      this.handleEditGroup({
                        groupId: this.state.groupId,
                        groupSetHelperId: this.state.groupSetHelperId
                      })
                    }
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={
                      this.handleDeleteGroup({
                        onConfirmClick: this.handleConfirmDeleteGroup,
                        onCancelClick: this.handleCloseConfirmationSnackbar
                      })
                    }
                  >
                    Remove
                  </MenuItem>
                </Menu>
                <Divider />
              </Container>
            </List>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10, marginTop: 4, marginBottom: 8}}>
              <div style={{flexGrow: 1}} />
              <Tooltip title="Add New Group Set" placement="right">
                <Fab
                  variant="extended"
                  color="primary"
                  size="small"
                  aria-label="add-new-groupset"
                  onClick={this.addNewGroupSet}
                  className={classes.extendedIcon}
                >
                <AddIcon className={classes.extendedIcon} />
                <span className={classes.extendedIcon}>Add Group Set</span>
                </Fab>
              </Tooltip>
            </div>
          </Paper>
        </Drawer>
      </div>
    );
  }
}

ProjectTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar)(ProjectTable);
//export default compose(withStyles(styles), withRouter, withSnackbar)(ProjectTable);
