import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
//import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ArrowForward from '@material-ui/icons/ArrowForward';
import LoopIcon from '@material-ui/icons/Loop';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
//import Autocomplete from '.././Autocomplete';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import PatternWithValuesAndColors from './PatternWithValuesAndColors';
import GeneratorInfo from './GeneratorInfo';
import GeneratorDeclarationError from './GeneratorDeclarationError';
import { ReasonerService } from '.././services/ReasonerService';

const reasonerService = new ReasonerService();

const filter = createFilterOptions();

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  shrinkedLinkButton: {
    fontSize: 14,
    height: 21,
    textTransform:'unset',
    whiteSpace: 'nowrap',
    padding: '0px 2px',
    margin: '0px -2px',
    lineHeight: 'unset',
    justifyContent: 'unset',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  nativeAdditionalCompIcon: {
    color: '#ec9d00',
    fontWeight: '800',
    borderColor: '#ec9d00',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 82,
    height: 24,
    textAlign: 'center',
  },
  nativeAdditionalButtonIcon: {
    letterSpacing: 'normal',
    fontFamily: 'inherit',
    color: '#ec9d00',
    fontWeight: '800',
    borderColor: '#ec9d00',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    marginTop: -4,
    width: 82,
    height: 24,
    textAlign: 'center',
    letterSpacing: 'normal',
    fontFamily: 'inherit',
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.14)',
      borderColor: '#2196f3',
      color: '#2196f3',
      boxShadow: 'none',
    }
  },
  variableIcon: {
    color: '#52c41a',
  },
  variableCompIcon: {
    color: '#52c41a',
    fontWeight: '800',
    borderColor: '#52c41a',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    //marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  instanceInfoCompIcon: {
    color: '#e58ee9',
    fontWeight: '800',
    borderColor: '#e58ee9',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    //marginTop: -4,
    width: 24,
    height: 24,
    textAlign: 'center',
  },
  generatorIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  padding5TopBottom: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
  },
  constExprEdit: {
    borderStyle: 'dashed',
    //borderColor: 'rgb(32, 150, 243)',
    //borderColor: '#37a700',
    borderColor: '#ec9d00',
    marginTop: 5,
    marginBottom: 5,
    marginLeft:20,
    padding:5,
    borderLeft:'solid'
  },
  autocompleteFormControl: {
    width: '100%',
    display: 'flex'
  },
  divIconStyleWrapper: {
    paddingTop: 2,
    paddingRight: 15,
  },
  divGreyOutlinedIconStyle: {
    width:24,
    height:18,
    borderStyle:'solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderRadius:4,
    borderWidth:2,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  constExprToDeleteHighlighted: {
    borderColor: '#f50057',
  },
  closeIconWrapper: {
    position: 'relative',
    paddingTop: 5,
    zIndex: 1,
  },
  closeIcon: {
    position: 'absolute',
    padding: 'unset',
    right: 0,
    top: 0,
  },
  // Generator Declaration
  generatorDeclarationError: {
    color: 'red',
  },
  generatorDeclarationErrorneousDeleteIcon: {
    color: '#ff00001a',
    '&&:hover, &&:active': {
      color: 'red',
    },
  },
  generatorDeclarationValidDeleteIcon: {
    color: 'rgb(171 218 255)',
  },
  generatorDeclarationErrorneousChip: {
    color: 'red',
    border: 'none',
    //border: '1px solid red',
    '&&:hover, &&:active, &&:focus': {
      backgroundColor: '#ff00001a',
    },
  },
  generatorDeclarationValidChip: {
    border: 'none',
  },
  generatorDeclarationIsolationBorder: {
    // border: '1px dashed',
    // borderRadius: 10,
    // padding: 7,
    // marginBottom: 5,
  },
  // generatorDeclarationIsolationBorderValid: {
  //   borderColor: theme.palette.primary.main,
  // },
  generatorDeclarationIsolationBorderError: {
    //borderColor: 'red',
  },
  generatorDeclarationIconWrapperStyle: {
    display: 'table-cell',
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 10
  },
  patternWithValuesAndColorsWrapper: {
    fontStyle: 'italic',
    marginLeft: 9,
    wordBreak: 'break-all',
  },
  twoLinesSmallChip: {
    height: 44,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  threeLinesSmallChip: {
    height: 64,
    borderRadius: 8,
    textOverflow: 'ellipsis',
  },
  errorLabelButton: {
    fontSize: 12,
    lineHeight: 'unset',
    color: theme.palette.primary.main,
  },
  errorneousNativeButtonStyle: {
    color: 'red',
    borderColor: 'red',
  },
  errorneousChildrenMessageStyle: {
    color: 'red',
    fontSize: 12,
    width: 200,
    paddingLeft: 5,
    marginTop: -2,
  }
});

//export class TargetIntermediateComponentEdit extends React.PureComponent {
export class ConstExprEdit extends React.Component {

  state = {
    entityIsLoading: false,
    relationIsLoading: false,
    entityOptions: [],
    relationOptions:[],
    //confirmDeleteConstExprSnackbarShown: false,
  };


  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.previousEntity !== nextProps.previousEntity) {
      return true;
    }
    if(this.props.patternTypeColors !== nextProps.patternTypeColors) {
      return true;
    }
    if(this.props.compactViewMode !== nextProps.compactViewMode) {
      return true;
    }
    if(this.props.compactViewEnabled !== nextProps.compactViewEnabled) {
      return true;
    }
    if(this.props.constExpr !== nextProps.constExpr) {
      return true;
    }
    else {
      return false;
    }
  }


  //handleRetrieveOptions = async (paramObj) => {
  handleRetrieveOptions = paramObj => async () => {
    if(paramObj.name === 'relation') {
      // If there is value selected for the respective targetEntity (this is an array of classes)
      if(this.props.previousEntity.item.length > 0) {
        // Notifying for loading data
        this.setState({relationIsLoading: true}, function() {
          // Resetting option list
          this.setState({relationOptions: []});
        });

        // Async Wait
        try {
          // Getting the selected items (case Array of Items)
          let selectedClasses = [];
          this.props.previousEntity.item.forEach(element => {
            selectedClasses.push({subject: element.full});
          });
          let res = await reasonerService.getProperties({
            id: this.props.currMappingProjectId,
            type: 'target',
            request: selectedClasses
          });

          if(res.data.response[0].options !== undefined) {
            // Creating option list
            this.setState({relationOptions: res.data.response}, function () {
              // Notifying that loading data is over
              this.setState({relationIsLoading: false});
            });
          }
          else {
            // Resetting option list
            this.setState({relationOptions: []}, function() {
              // Notifying that loading data is over
              this.setState({relationIsLoading: false});
            });
          }
        }
        catch (e) {
          console.error('Failure while retrieving target relation properties!');
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
          // Resetting option list
          this.setState({relationOptions: []}, function() {
            // Notifying that loading data is over
            this.setState({relationIsLoading: false});
          });
        } // catch - ends

      } // If there is value selected for the respective targetRelation - Ends
      else {
        this.setState({
          relationOptions: []
        });
      }
    } // if(paramObj.name === 'targetRelation') - ends

    else if(paramObj.name === 'entity') {
      // Two caces:
      //  1. load based on "Target Relation" (when this is there is none selected),
      //  2. Load all classes (when there is at least one selected),
      // Case load based on "Target Relation"
      if(this.props.constExpr.entity.item.length === 0) {
        // In the case of the target entity one of the required params of the
        // service to be called is the previously target relation set.
        // This either the target relation of the link itself or the previous intermediate.
        // That's why it is passed from the parent as "previousRelation" parameter
        // and is Used here.

        if(this.props.constExpr.relation.item.value !== undefined) {
          // Notifying for loading data
          this.setState({entityIsLoading: true}, function() {
            // Resetting option list
            this.setState({entityOptions: []});
          });
          // Async Wait
          try {
            let res = await reasonerService.getObjects({
              id: this.props.currMappingProjectId,
              type: 'target',
              //property: this.props.constExpr.relation.item.full
              property: this.props.constExpr.relation.item.full !== undefined ? this.props.constExpr.relation.item.full : this.props.constExpr.relation.item.value
            });

            if(res.data.response[0].options !== undefined) {
              // Creating option list
              this.setState({entityOptions: res.data.response}, function () {
                // Notifying that loading data is over
                this.setState({entityIsLoading: false});
              });
            }
            else {
              // Resetting option list
              this.setState({entityOptions: []}, function() {
                // Notifying that loading data is over
                this.setState({entityIsLoading: false});
              });
            }
          }
          catch (e) {
            console.error('Failure while retrieving target entity properties!');
            if(e.response !== undefined) {
              console.error(e.response.status);
            }
            // Resetting option list
            this.setState({entityOptions: []}, function() {
              // Notifying that loading data is over
              this.setState({entityIsLoading: false});
            });
          } // catch - ends
        } // If there is value selected for the respective targetRelation - Ends
        else {
          this.setState({
            entityOptions: []
          });
        }
      }
      // Case load all classes (there is already one selected)
      else {
        this.setState({entityOptions: this.props.allClasses});
      }
    } // if(paramObj.name === 'targetEntity') - Ends
  };

  // props: {status:'mouseEnter | mouseLeave}
  handleHoverDeleteConstExpr = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({isHovered: hovered});
  }

  // props: {constExpr: <Object>}
  checkIfChildrenHaveErrorsCallBack = props => {
    const constExpr = props.constExpr;
    let childrenHaveErrors = false;
    if(constExpr.constExprIds !== undefined) {
      for (const childConstExprId of constExpr.constExprIds) {
        const childConstExpr = constExpr.constExprs[childConstExprId];
        if(childConstExpr.relation.error_text !== undefined && childConstExpr.relation.error_text !== '') {
          childrenHaveErrors = true;
          break;
        }
        if(childConstExpr.entity.error_text !== undefined && childConstExpr.entity.error_text !== '') {
          childrenHaveErrors = true;
          break;
        }
        if(this.checkIfChildrenHaveErrorsCallBack({constExpr: childConstExpr})) {
          childrenHaveErrors = true;
          break;
        }
      }
    }
    return childrenHaveErrors;
  }

  render() {

    const { classes } = this.props;
    const { entityOptions, relationOptions, entityIsLoading, relationIsLoading } = this.state;
    const { constExpr, mappingId, linkId, intermediateId, constExprId,
            handleInputChange, handleInputChangeButton, handleUseGenerator, handleGoToGeneratorDeclarationForm,
            handleDirectDeleteInstanceGeneratorDeclaration, handleDirectDeleteLabelGeneratorDeclarations,
            patternTypeColors,
            handleUseVariable, handleDirectDeleteVariable,
            handleUseInstanceInfo, handleDirectDeleteInstanceInfo, instanceInfoStyleBasedOnLinesRequired,
            compactViewEnabled, compactViewMode,
            handleDisplayConstExpr,
          } = this.props;

    console.log('ConstExpr render');

    const instanceGeneratorDeclarationErrorMsg =
      constExpr.entity.instanceGeneratorDeclaration !== undefined ?
      (
        constExpr.entity.instanceGeneratorDeclaration.definitionId !== undefined ?
        (
          constExpr.entity.instanceGeneratorDeclaration.definitionId === null ?
          'Missing Generator Definition' :
          ''
        ) :
        ''
      ) :
      '';
    const lastInstanceGeneratorDeclarationArgErrorIndex =
      constExpr.entity.instanceGeneratorDeclaration !== undefined ?
      (
        constExpr.entity.instanceGeneratorDeclaration.args !== undefined &&
        constExpr.entity.instanceGeneratorDeclaration.args.length > 0 ?
        constExpr.entity.instanceGeneratorDeclaration.args.findIndex(
          obj => (
            constExpr.entity.instanceGeneratorDeclaration.name.toLowerCase() !== 'literal' &&
            constExpr.entity.instanceGeneratorDeclaration.name.toLowerCase() !== 'preflabel'
          ) ?
          obj.error_type_text !== '' :
          (
            obj.name.toLowerCase() !== 'language' ?
            obj.error_type_text !== '' :
            false
          )
        ) :
        -1
      ) :
      -1;
    const instanceGeneratorDeclarationArgsErrorMsg =
      lastInstanceGeneratorDeclarationArgErrorIndex !== -1 ?
      constExpr.entity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].name + ': ' +
      constExpr.entity.instanceGeneratorDeclaration.args[lastInstanceGeneratorDeclarationArgErrorIndex].error_type_text :
      '';

    const instanceGeneratorDeclarationErrorStyle =
      instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg !== '' ?
      classes.generatorDeclarationError :
      '';

    const childrenHaveErrors = this.checkIfChildrenHaveErrorsCallBack({constExpr: constExpr});
    // let childrenHaveErrors = false;
    // if(constExpr.constExprIds !== undefined) {
    //   for (const childConstExprId of constExpr.constExprIds) {
    //     const childConstExpr = constExpr.constExprs[childConstExprId];
    //     if(childConstExpr.relation.error_text !== undefined && childConstExpr.relation.error_text !== '') {
    //       childrenHaveErrors = true;
    //       break;
    //     }
    //     if(childConstExpr.entity.error_text !== undefined && childConstExpr.entity.error_text !== '') {
    //       childrenHaveErrors = true;
    //       break;
    //     }
    //   }
    // }


    return (
      <div className={classNames(classes.constExprEdit, this.state.isHovered ? classes.constExprToDeleteHighlighted : '')}
           style={{minWidth: 280, position: 'relative'}}>
        <div className={classes.closeIconWrapper}>
          {/*{'ID: ' +  constExprId}*/}
          <IconButton className={classes.closeIcon}
                      aria-label="Delete"
                      onClick={
                        this.props.handleShowConfirmDeleteSnackbar({
                          handleDelete: this.props.handleDeleteConstExpr,
                          mappingId: mappingId,
                          linkId: linkId,
                          intermediateId: intermediateId,
                          constExprId: constExprId
                        })
                      }
                      onMouseEnter={this.handleHoverDeleteConstExpr({status:'mouseEnter'})}
                      onMouseLeave={this.handleHoverDeleteConstExpr({status:'mouseLeave'})}>
            <CloseIcon />
          </IconButton>
        </div>
        {/* ***************************************************************** */}
        {/* *********************** ConstExpr Entity ************************ */}
        {/* ***************************************************************** */}
        <div className={classes.padding5TopBottom}>
          <div className={classes.divIconStyleWrapper}>
            <ArrowForward/>
          </div>
          <FormControl className={classes.autocompleteFormControl}
                       error={constExpr.relation.error_text !== undefined}>
            <Autocomplete
              options={[].concat.apply([], relationOptions.map(item => item.options.map(optItem => ({ ...optItem, group: item.label }))))}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
              renderOption={(option) => (
                <React.Fragment>
                  <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                </React.Fragment>
              )}
              getOptionSelected={
                (option, value) => {
                  return ((value !== '' || value !== undefined) ? option.value === value.value : false)
                }
              }
              onChange={
                handleInputChange({
                  name: 'relation',
                  mappingId: mappingId,
                  linkId: linkId,
                  intermediateId: intermediateId,
                  constExprId: constExprId,
                  recursionArray: this.props.recursionArray,
                })
              }
              value={constExpr.relation.item !== undefined && constExpr.relation.item !== '' ? constExpr.relation.item : null}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              filterSelectedOptions={false /* if true it will remove selected from the option list */}
              loading={relationIsLoading}
              onOpen={this.handleRetrieveOptions({name: 'relation'})}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Relation"
                  placeholder="Please select relation"
                  FormHelperTextProps={{component: 'div'}}
                  helperText={
                    <div style={{color: 'red'}}>
                      <span style={{paddingRight: 10}}>
                        {constExpr.relation.error_text !== undefined ? constExpr.relation.error_text : ''}
                      </span>
                      {
                        constExpr.relation.item !== '' ?
                        <Button
                          color="secondary"
                          hidden={constExpr.relation.error_text === undefined}
                          size="small"
                          className={classes.errorLabelButton}
                          onClick={
                            handleInputChangeButton({
                              name: 'relation',
                              mappingId: mappingId,
                              linkId: linkId,
                              intermediateId: intermediateId,
                              constExprId: constExprId,
                              item: constExpr.relation.item,
                              recursionArray: this.props.recursionArray,
                            })
                          }
                        >
                          Make Custom
                        </Button> :
                        ''
                      }
                    </div>
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {relationIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <div className={classes.padding5TopBottom}>
          <div className={classes.divIconStyleWrapper}>
            <div className={classes.divGreyOutlinedIconStyle}/>
          </div>
          <FormControl className={classes.autocompleteFormControl}
                       error={constExpr.entity.error_text !== undefined}>
            <Autocomplete
              options={[].concat.apply([], entityOptions.map(item => item.options.map(optItem => ({ ...optItem, group: item.label }))))}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label !== undefined ? option.label : ''}
              renderOption={(option) => (
                <React.Fragment>
                  <span style={{wordBreak: 'break-word'}}>{option.label}</span>
                </React.Fragment>
              )}
              getOptionSelected={
                (option, value) => {
                  return ((value !== '' || value !== undefined) ? option.value === value.value : false)
                }
              }
              onChange={
                handleInputChange({
                  name: 'entity',
                  mappingId: mappingId,
                  linkId: linkId,
                  intermediateId: intermediateId,
                  constExprId: constExprId,
                  recursionArray: this.props.recursionArray,
                })
              }
              value={constExpr.entity.item !== undefined && constExpr.entity.item !== '' ? constExpr.entity.item : []}
              multiple
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              filterSelectedOptions={false /* if true it will remove selected from the option list */}
              loading={entityIsLoading}
              onOpen={this.handleRetrieveOptions({name: 'entity'})}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Entity"
                  placeholder="Please select entity"
                  FormHelperTextProps={{component: 'div'}}
                  helperText={
                    <div style={{color: 'red'}}>
                      <span style={{paddingRight: 10}}>
                        {constExpr.entity.error_text !== undefined ? constExpr.entity.error_text : ''}
                      </span>
                      {
                        constExpr.entity.item !== '' && constExpr.entity.error_text !== 'There is an erroneous native child' ?
                        <Button
                          color="secondary"
                          hidden={constExpr.entity.error_text === undefined}
                          size="small"
                          className={classes.errorLabelButton}
                          onClick={
                            handleInputChangeButton({
                              name: 'entity',
                              mappingId: mappingId,
                              linkId: linkId,
                              intermediateId: intermediateId,
                              constExprId: constExprId,
                              item: constExpr.entity.item,
                              recursionArray: this.props.recursionArray,
                            })
                          }
                        >
                          Make Custom
                        </Button> :
                        ''
                      }
                    </div>
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {entityIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {/*value={constExpr.entity.item !== undefined && constExpr.entity.item !== '' ? constExpr.entity.item : null}*/}
          </FormControl>
        </div>

        {/* Variable / Generator - Expanded panel */}
        <div style={{marginLeft: 45}}>
          {/* Variable / Generator Declaration Instance/Label*/}
          <div>
            {/*Display info indicating the presence of a native aditional*/}
            <div
              hidden={
                constExpr.constExprIds !== undefined ?
                constExpr.constExprIds.length === 0 :
                true
              }
              style={{marginBottom: 10, display: 'flex'}}
            >
              <Tooltip
                title={
                  <React.Fragment>
                  <span>
                    Native additional has been declared inside this additional.
                    <br/>
                    You can see it or change it, only while working with the
                    <br/>
                    X3ML code of this additional element.
                  </span>
                  </React.Fragment>
                }
                placement="left"
                enterDelay={500}
                leaveDelay={200}
              >
                {/*
                <div className={classes.nativeAdditionalCompIcon} style={{display: 'flex'}}>
                  <LoopIcon style={{marginTop: -2}}/>
                  <span></span><span style={{fontSize: 'x-small', margin: 'auto'}}>Additional</span>
                </div>
                */}
                <Button
                  className={classNames(classes.nativeAdditionalButtonIcon, childrenHaveErrors ? classes.errorneousNativeButtonStyle : '')}
                  onClick={
                    handleDisplayConstExpr({
                      mappingId: mappingId,
                      linkId: linkId,
                      intermediateId: intermediateId,
                      constExprId: constExprId,
                      constExpr: constExpr,
                      recursionArray: this.props.recursionArray !== undefined ? this.props.recursionArray.concat([constExprId]) : [constExprId],
                      level: this.props.level !== undefined ? this.props.level + 1 : 0,
                    })
                  }
                >
                  <LoopIcon style={{marginTop: -2}}/>
                  <span></span><span style={{fontSize: 'x-small', margin: 'auto', textTransform: 'none'}}>Additional</span>
                </Button>
              </Tooltip>
              <div hidden={!childrenHaveErrors} className={classes.errorneousChildrenMessageStyle}>
                There is at least one erroneous child
              </div>
            </div>

            {/*Display the Variable*/}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideVariable !== undefined ?
                    compactViewMode.hideVariable :
                    false
                  ) :
                  false
                ) :
                true
              }
            >
              <div style={{display: 'flex', marginBottom: 6}}>
                <div style={{display: 'table-row'}}
                     hidden={
                       constExpr.entity.variable === undefined ?
                       true :
                       (
                         constExpr.entity.variable.value === '' ?
                         true :
                         false
                       )
                     }>
                  <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}>
                    <Tooltip title="Variable"
                             placement="left"
                             enterDelay={500}
                             leaveDelay={200}>
                      <div className={classes.variableCompIcon} style={{display: 'flex'}}>
                        <span>V</span><span style={{fontSize: 'x-small'}}>ar</span>
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{display: 'table-cell', verticalAlign: 'middle', paddingRight: 10, paddingTop: 5}}
                       hidden={
                         constExpr.entity.variable !== undefined ?
                         !constExpr.entity.variable.global :
                         true
                       }
                  >
                    <Tooltip title="Global"
                             placement="left"
                             enterDelay={500}
                             leaveDelay={200}>
                      <Icon className={classNames(classes.variableIcon, 'fas fa-globe-europe')}/>
                    </Tooltip>
                  </div>

                  <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                    <div>
                      {
                        constExpr.entity.variable !== undefined ?
                        <React.Fragment>
                          <Chip
                            classes={{
                              root: classes.generatorDeclarationValidChip,
                              deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                            }}
                            size="small"
                            variant="outlined"
                            label={constExpr.entity.variable.value}
                            onClick={
                              handleUseVariable({
                                mappingId: mappingId,
                                linkId: linkId,
                                intermediateId: intermediateId,
                                constExprId: constExprId,
                                recursionArray: this.props.recursionArray,
                              })
                            }
                            color="primary"
                            onDelete={
                              this.props.handleShowConfirmDeleteSnackbar({
                                handleDelete: handleDirectDeleteVariable,
                                argument: {
                                  variableProps: {
                                    mappingId: mappingId,
                                    linkId: linkId,
                                    intermediateId: intermediateId,
                                    constExprId: constExprId,
                                    recursionArray: this.props.recursionArray,
                                  },
                                }
                              })
                            }
                          />
                        </React.Fragment> :
                        ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>

            {/* Display the instance Info */}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideInstanceInfo !== undefined ?
                    compactViewMode.hideInstanceInfo :
                    false
                  ) :
                  false
                ) :
                true
              }
            >
              <div style={{display: 'flex', marginBottom: 6}}>
                <div style={{display: 'table-row'}}
                     hidden={
                       constExpr.entity.instanceInfo === undefined ?
                       true :
                       (
                         constExpr.entity.instanceInfo.description === '' && constExpr.entity.instanceInfo.language === '' ?
                         true :
                         false
                       )
                     }>
                  <div className={classes.generatorDeclarationIconWrapperStyle} style={{verticalAlign: 'middle'}}>
                    <Tooltip
                      title="Instance Info"
                      placement="left"
                      enterDelay={500}
                      leaveDelay={200}
                    >
                      <div className={classes.instanceInfoCompIcon} style={{display: 'flex'}}>
                        <span>I</span><span style={{fontSize: 'x-small'}}>nf</span>
                      </div>
                    </Tooltip>
                  </div>

                  <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                    <div>
                      {
                        constExpr.entity.instanceInfo !== undefined ?
                        <React.Fragment>
                          <Chip
                            classes={{
                              root: classes.generatorDeclarationValidChip,
                              deleteIconSmall: classes.generatorDeclarationValidDeleteIcon,
                              sizeSmall:  classes[instanceInfoStyleBasedOnLinesRequired(constExpr.entity.instanceInfo)],
                            }}
                            size="small"
                            variant="outlined"
                            label={
                              <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                {
                                  constExpr.entity.instanceInfo.constant !== undefined &&
                                  constExpr.entity.instanceInfo.constant !== '' ?
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      maxWidth: 148,
                                    }}
                                  >
                                    {
                                      constExpr.entity.instanceInfo !== undefined ?
                                      'Constant: ' + constExpr.entity.instanceInfo.constant :
                                      ''
                                    }
                                  </div> :
                                  ''
                                }
                                {
                                  constExpr.entity.instanceInfo.description !== undefined &&
                                  constExpr.entity.instanceInfo.description !== '' ?
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      maxWidth: 148,
                                    }}
                                  >
                                    {
                                      constExpr.entity.instanceInfo !== undefined ?
                                      'Description: ' + constExpr.entity.instanceInfo.description :
                                      ''
                                    }
                                  </div> :
                                  ''
                                }
                                {
                                  constExpr.entity.instanceInfo.language !== undefined ?
                                  (
                                    constExpr.entity.instanceInfo.language.value !== '' ?
                                    <div>
                                      {
                                        constExpr.entity.instanceInfo !== undefined ?
                                        (
                                          constExpr.entity.instanceInfo.language !== undefined ?
                                          'Language: ' +
                                          (
                                            constExpr.entity.instanceInfo.language.label !== constExpr.entity.instanceInfo.language.value ?
                                            constExpr.entity.instanceInfo.language.label + ' (' + constExpr.entity.instanceInfo.language.value + ')' :
                                            constExpr.entity.instanceInfo.language.value
                                          ) :
                                          ''
                                        )
                                         :
                                        ''
                                      }
                                    </div> :
                                    ''
                                  ) :
                                  ''
                                }
                              </div>
                            }
                            onClick={
                              handleUseInstanceInfo({
                                mappingId: mappingId,
                                linkId: linkId,
                                intermediateId: intermediateId,
                                constExprId: constExprId,
                                recursionArray: this.props.recursionArray,
                              })
                            }
                            color="primary"
                            onDelete={
                              this.props.handleShowConfirmDeleteSnackbar({
                                handleDelete: handleDirectDeleteInstanceInfo,
                                argument: {
                                  instanceInfoProps: {
                                    mappingId: mappingId,
                                    linkId: linkId,
                                    intermediateId: intermediateId,
                                    constExprId: constExprId,
                                  },
                                }
                              })
                            }
                          />
                        </React.Fragment> :
                        ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
            {/*Display Generator Declaration Set*/}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideInstanceGenerator !== undefined ?
                    compactViewMode.hideInstanceGenerator :
                    true
                  ) :
                  true
                ) :
                true
              }
            >
              <div className={instanceGeneratorDeclarationErrorStyle}>
                <div style={{display: 'flex', width: 'fit-content', marginBottom: 6}}>
                  <div hidden={constExpr.entity.instanceGeneratorDeclaration === undefined}
                       className={classes.generatorDeclarationIconWrapperStyle}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <GeneratorInfo
                            title="Instance Generator"
                            patternTypeColors={patternTypeColors}
                            />
                        </React.Fragment>
                      }
                      placement="left"
                      enterDelay={500}
                      leaveDelay={200}
                    >
                     <Icon className={classNames(classes.generatorIcon, 'fas fa-link', instanceGeneratorDeclarationErrorStyle)}/>
                    </Tooltip>
                  </div>
                  <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                    <div>
                      {
                        constExpr.entity.instanceGeneratorDeclaration !== undefined ?
                        <React.Fragment>
                          <Chip
                            classes={{
                              root: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                    classes.generatorDeclarationErrorneousChip :
                                    classes.generatorDeclarationValidChip,
                              deleteIconSmall: instanceGeneratorDeclarationErrorMsg !== '' || instanceGeneratorDeclarationArgsErrorMsg ?
                                    classes.generatorDeclarationErrorneousDeleteIcon :
                                    classes.generatorDeclarationValidDeleteIcon,
                            }}
                            size="small"
                            variant="outlined"
                            label={constExpr.entity.instanceGeneratorDeclaration.name}
                            onClick={
                              handleGoToGeneratorDeclarationForm({
                                generatorType: 'instance',
                                type: 'constExpr',
                                generatorProps: {
                                  mappingId: mappingId,
                                  linkId: linkId,
                                  intermediateId: intermediateId,
                                  constExprId: constExprId,
                                  recursionArray: this.props.recursionArray,
                                }
                              })
                            }
                            color="primary"
                            onDelete={
                              this.props.handleShowConfirmDeleteSnackbar({
                                handleDelete: handleDirectDeleteInstanceGeneratorDeclaration,
                                argument: {
                                  generatorType: 'instance',
                                  type: 'constExpr',
                                  generatorProps: {mappingId: mappingId, linkId: linkId, intermediateId: intermediateId, constExprId: constExprId},
                                }
                              })
                            }
                          />
                        </React.Fragment>
                        :
                        ''
                      }
                    </div>
                    <div className={classes.patternWithValuesAndColorsWrapper}>
                      {
                      constExpr.entity.instanceGeneratorDeclaration !== undefined ?
                      <PatternWithValuesAndColors declaration={constExpr.entity.instanceGeneratorDeclaration}
                                                  patternTypeColors={ patternTypeColors}/> :
                      ''
                      }
                    </div>
                  </div>
                  <GeneratorDeclarationError
                    generatorDeclarationErrorStyle={instanceGeneratorDeclarationErrorStyle}
                    errorMessage={
                      instanceGeneratorDeclarationErrorMsg !== '' ?
                       instanceGeneratorDeclarationErrorMsg :
                       (
                         instanceGeneratorDeclarationArgsErrorMsg !== '' ?
                         instanceGeneratorDeclarationArgsErrorMsg :
                         ''
                       )
                     }
                  />
                </div>
              </div>
            </Collapse>

            {/* Display label Generator Declarations */}
            <Collapse
              in={
                compactViewEnabled ?
                !(
                  compactViewMode !== undefined ?
                  (
                    compactViewMode.hideLabelGenerator !== undefined ?
                    compactViewMode.hideLabelGenerator :
                    true
                  ) :
                  true
                ) :
                true
              }
            >
              <div hidden={constExpr.entity.labelGeneratorDeclarations === undefined ? true :
                     (constExpr.entity.labelGeneratorDeclarations.length === 0 ? true : false)}>
                <div style={{display: 'table-row'}}>
                  {
                    constExpr.entity.labelGeneratorDeclarations !== undefined ?
                    constExpr.entity.labelGeneratorDeclarations.map((labelDeclaration) => {
                      const labelGeneratorDeclarationErrorMsg =
                        labelDeclaration.definitionId !== undefined ?
                        (
                          labelDeclaration.definitionId === null ?
                          'Missing Generator Definition' :
                          ''
                        ) :
                        '';
                      const lastLabelGeneratorDeclarationArgErrorIndex = labelDeclaration.args.findIndex(
                        obj => (
                          obj.name.toLowerCase() !== 'language' ?
                          obj.error_type_text !== '' :
                          false
                        )
                      );
                      const labelGeneratorDeclarationArgsErrorMsg =
                        lastLabelGeneratorDeclarationArgErrorIndex !== -1 ?
                        labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].name + ': ' +
                        labelDeclaration.args[lastLabelGeneratorDeclarationArgErrorIndex].error_type_text :
                        '';

                      const labelGeneratorDeclarationErrorStyle =
                        labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                        classes.generatorDeclarationError :
                        '';
                      return (
                        <div
                          key={labelDeclaration.id}
                          className={labelGeneratorDeclarationErrorStyle}
                          style={{display: 'flex', width: 'fit-content'}}
                        >
                          <div className={classes.generatorDeclarationIconWrapperStyle}>
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <GeneratorInfo
                                    title="Label Generator"
                                    patternTypeColors={patternTypeColors}
                                    />
                                </React.Fragment>
                              }
                              placement="left"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <Icon className={classNames(classes.generatorIcon, 'fas fa-tag', labelGeneratorDeclarationErrorStyle)}/>
                            </Tooltip>
                          </div>
                          <div style={{margin: 'auto', marginLeft:0, marginRight: 0, paddingRight: 10}}>
                            <div>
                              <React.Fragment>
                                <Chip
                                  classes={{
                                    root: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                          classes.generatorDeclarationErrorneousChip :
                                          classes.generatorDeclarationValidChip,
                                    deleteIconSmall: labelGeneratorDeclarationErrorMsg !== '' || labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                          classes.generatorDeclarationErrorneousDeleteIcon :
                                          classes.generatorDeclarationValidDeleteIcon,
                                  }}
                                  size="small"
                                  variant="outlined"
                                  label={labelDeclaration.name}
                                  onClick={
                                    handleGoToGeneratorDeclarationForm({
                                      declaration: labelDeclaration,
                                      generatorType: 'label',
                                      type: 'constExpr',
                                      generatorProps: {
                                        mappingId: mappingId,
                                        linkId: linkId,
                                        intermediateId: intermediateId,
                                        constExprId: constExprId,
                                        recursionArray: this.props.recursionArray,
                                      }
                                    })
                                  }
                                  color="primary"
                                  onDelete={
                                    this.props.handleShowConfirmDeleteSnackbar({
                                      handleDelete: handleDirectDeleteLabelGeneratorDeclarations,
                                      argument: {
                                        labelGeneratorDeclarationsToBeDeleted: [labelDeclaration],
                                        generatorProps: {mappingId: mappingId, linkId: linkId, intermediateId: intermediateId, constExprId: constExprId}
                                      }
                                    })
                                  }
                                />
                              </React.Fragment>
                            </div>
                            <div className={classes.patternWithValuesAndColorsWrapper}>
                              {
                              <PatternWithValuesAndColors declaration={labelDeclaration}
                                                          patternTypeColors={ patternTypeColors}/>
                              }
                            </div>
                          </div>
                          <GeneratorDeclarationError
                            generatorDeclarationErrorStyle={labelGeneratorDeclarationErrorStyle}
                            errorMessage={
                              labelGeneratorDeclarationErrorMsg !== '' ?
                               labelGeneratorDeclarationErrorMsg :
                               (
                                 labelGeneratorDeclarationArgsErrorMsg !== '' ?
                                 labelGeneratorDeclarationArgsErrorMsg :
                                 ''
                               )
                             }
                          />
                        </div>
                      )
                    }) :
                    ''
                  }
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={classes.padding5TopBottom}>
          <div style={{width: '100%'}}>
            <Button
              color="primary"
              className={classes.button}
              onClick={
                handleDisplayConstExpr({
                  mappingId: mappingId,
                  linkId: linkId,
                  intermediateId: intermediateId,
                  constExprId: constExprId,
                  constExpr: constExpr,
                  recursionArray: this.props.recursionArray !== undefined ? this.props.recursionArray.concat([constExprId]) : [],
                  level: this.props.level !== undefined ? this.props.level + 1 : 0,
                })
              }
            >
              Additional
            </Button>
            <Button
              color="primary"
              className={classes.button}
              onClick={
                handleUseGenerator({
                  mappingId: mappingId,
                  linkId: linkId,
                  intermediateId: intermediateId,
                  constExprId: constExprId,
                  recursionArray: this.props.recursionArray,
                })
              }
            >
              Generator
            </Button>
            <Button
              color="primary"
              className={classes.button}
              onClick={
                handleUseVariable({
                  mappingId: mappingId,
                  linkId: linkId,
                  intermediateId: intermediateId,
                  constExprId: constExprId,
                  recursionArray: this.props.recursionArray,
                })
              }
            >
              Variable
            </Button>
            <Button
              color="primary"
              className={classes.button}
              style={{whiteSpace: 'nowrap'}}
              onClick={
                handleUseInstanceInfo({
                  mappingId: mappingId,
                  linkId: linkId,
                  intermediateId: intermediateId,
                  constExprId: constExprId,
                  recursionArray: this.props.recursionArray,
                })}
              >
              Instance Info
            </Button>
          </div>
        </div>

      </div>
    );
  }
}

ConstExprEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConstExprEdit);
