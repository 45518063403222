import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import { Controlled as CodeMirror } from 'react-codemirror2'
import Icon from '@material-ui/core/Icon';
import { FileUploadService } from '.././services/FileUploadService';


import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/addon/edit/matchtags');

const styles = theme => ({
  dialogCustomPaper: {
    overflow: 'hidden',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  }
});

const fileUploadService = new FileUploadService();

export class CodemirrorDialog extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.input !== nextProps.input) {
      return true;
    }
    if(this.props.codeMirrorMode !== nextProps.codeMirrorMode) {
      return true;
    }
    if(this.props.dialogTitle !== nextProps.dialogTitle) {
      return true;
    }
    if(this.props.editable !== nextProps.editable) {
      return true;
    }
    if(this.props.relativePath !== nextProps.relativePath) {
      return true;
    }
    if(this.props.inputState !== nextProps.inputState) {
      return true;
    }
    if(this.props.downloadable !== nextProps.downloadable) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleDownloadFile = props => async () => {
    await fileUploadService.downloadRdfFileByRelativePath({relativePath: props.relativePath});
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, input, codeMirrorMode, relativePath, downloadable,
      onClose, onToggleFullScreen, dialogTitle, editable, inputChange, inputState, handleSave,
      saveNow, shakeNow,
    } = this.props;

    /*
    // Beautifying the produced RDF
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(input, 'text/xml');
    var oSerializer = new XMLSerializer();
    var rdfInput = oSerializer.serializeToString(xmlDoc);
    */
    var rdfInput = input;
    return (
      <Dialog open={dialogOpen}
              aria-labelledby="transformed-rdf-output"
              TransitionComponent={Fade}
              transitionDuration={{enter: 400, exit: 400}}
              maxWidth="xl"
              fullScreen={fullScreen}
              PaperProps={{
                classes: {
                  root: classes.dialogCustomPaper,
                }
              }}>
        <DialogTitle style={{paddingBottom: 0}}>
          {dialogTitle}
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onClose}>
                          {/*padding: 'unset'*/}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1" style={{paddingBottom: 10}}>
            Put the cursor on or inside a pair of tags to highlight them.
            Press Ctrl-J to jump to the tag that matches the one under the
            cursor.
            <br/>
            {
              editable ? '' : <span className={classes.secondaryColor}>Please note that you cannot edit this code.</span>
            }
          </Typography>
          <div>
            <CodeMirror
              editorDidMount={editor => editor.setSize(null, "auto")}
              value={rdfInput}
              className="codemirrorCustomStyle"
              options={{
                mode: codeMirrorMode,
                lineWrapping: true,
                theme: 'material',
                lineNumbers: true,
                readOnly: !editable,
                matchTags: {bothTags: true},
                extraKeys: {"Ctrl-J": "toMatchingTag"}
              }}
              onBeforeChange={
                inputState !== undefined ?
                (editor, data, value) => {
                  inputChange({value: value, state: inputState});
                } :
                console.log()
              }
              onChange={(editor, data, value) => {}}
            />

          </div>
        </DialogContent>
        <DialogActions style={{paddingRight: 30, paddingLeft: 16}}>
          <div style={{flexGrow: 1}}>
            <Button
              hidden={!editable}
              onClick={handleSave !== undefined ? handleSave : console.log()}
              color={!saveNow ? 'primary' : 'secondary'}
            >
              <EditIcon />
              <span className={shakeNow ? 'rumble' : ''}>save</span>
            </Button>
          </div>
          <Button hidden={!downloadable}
                  onClick={this.handleDownloadFile({relativePath: relativePath})}
                  color="primary">
            <Icon className={classNames(classes.icon, 'fa fa-download')} fontSize="small"/>
            Download
          </Button>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

CodemirrorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CodemirrorDialog);
