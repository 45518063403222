import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

//import './css/custom.css';
const styles = theme => ({
  snackbarSuccess: {
    backgroundColor: green[600],
  },
  snackbarWarning: {
    backgroundColor: amber[700],
    width: 460,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 40,
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
});

export class ConfirmationSnackbar extends React.Component {


  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.open !== nextProps.open) {
      return true;
    }
    if(this.props.type !== nextProps.type) {
      return true;
    }
    if(this.props.msg !== nextProps.msg) {
      return true;
    }
    if(this.props.confirmButtonLabel !== nextProps.confirmButtonLabel) {
      return true;
    }
    if(this.props.cancelButtonLabel !== nextProps.cancelButtonLabel) {
      return true;
    }
    else {
      return false;
    }
  }

  getIconType = (typeName) => {
    const { classes } = this.props;
    if(typeName === 'warning') {
      return(
        <WarningIcon className={classNames(classes.largeSnackbarIcon, classes.snackbarIconVariant)} />
      )
    }
    else {
      return(
        <CheckIcon className={classNames(classes.largeSnackbarIcon, classes.snackbarIconVariant)} />
      )
    }
  }

  render() {

    const { classes } = this.props;
    const {
      open, onClose, type, msg, onConfirmClick, confirmButtonLabel,
      cancelButtonLabel, onCancelClick
    } = this.props;

    const typeUpperCaseFirstLetter = type.charAt(0).toUpperCase() + type.slice(1);

    return (

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes['snackbar' + typeUpperCaseFirstLetter],
          }
        }}
        message={
          <div className={classes.snackbarMessageContainer}>
            <div className={classes.snackbarMessageText}>
              {this.getIconType('warning')}
            </div>
            <div className={classes.snackbarMessageText}>
                {msg}
            </div>
          </div>
        }
        action={[
          <Button key="onConfirmClick" color="secondary" size="small" onClick={onConfirmClick}>
            <b>{confirmButtonLabel}</b>
          </Button>,
          <Button key="onCancelClick" color="secondary" size="small" onClick={onCancelClick}>
            <b>{cancelButtonLabel}</b>
          </Button>,
        ]}
      />



    );
  }
}

ConfirmationSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmationSnackbar);
