import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import CustomSnackMessage from '.././snackbar/CustomSnackMessage';
import { SecurityService } from '../services/SecurityService';
import logo from '../../images/logo-color-128-w-text.png';
import {strengthColor, strengthIndicator} from './PasswordStrength';
import configuration from '../configuration.json';

const rootContextPath = configuration.rootContextPath;

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    background: 'none',
    padding: 0
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  fab: {
    margin: theme.spacing(1),
  },
  // /red
  redTextFieldUnderline: {
    color: 'red' ,
    '&::after': {
      border: '2px solid red'
    }
  },
  redLabelColor: {
    color: 'red',
  },
  redTexfieldColor: {
    color: 'red',
  },
  // Yellow
  yellowTextFieldUnderline: {
    color: '#faad14' ,
    '&::after': {
      border: '2px solid #faad14'
    }
  },
  yellowLabelColor: {
    color: '#faad14',
  },
  yellowTexfieldColor: {
    color: '#faad14',
  },
  // Orange
  orangeTextFieldUnderline: {
    color: '#fa8414' ,
    '&::after': {
      border: '2px solid #fa8414'
    }
  },
  orangeLabelColor: {
    color: '#fa8414',
  },
  orangeTexfieldColor: {
    color: '#fa8414',
  },
  // Lightgreen
  lightgreenTextFieldUnderline: {
    color: '#03ca03' ,
    '&::after': {
      border: '2px solid #03ca03'
    }
  },
  lightgreenLabelColor: {
    color: '#03ca03',
  },
  lightgreenTexfieldColor: {
    color: '#03ca03',
  },
  // Green
  greenTextFieldUnderline: {
    color: '#019c01' ,
    '&::after': {
      border: '2px solid #019c01'
    }
  },
  greenLabelColor: {
    color: '#019c01',
  },
  greenTexfieldColor: {
    color: '#019c01',
  },
});

const securityService = new SecurityService();

class Registration extends React.Component {
  state = {
    imageLoaded: false,
    confirmPassword:'',
    user: {
      username: '',
      password: '',
      email:'',
      firstname: '',
      lastname: '',
      enabled: '',
      roles:[]
    }
  };

  // Only used for showing the form when the image is loaded
  onImageLoad = () => {
    this.setState({
        imageLoaded: true
    })
  }

  handleSubmit = async () => {
    console.log("Submit");
    if (this.formEl.checkValidity() === false) {
      // Username
      if (this.state.user.username === '') {
        this.setState({
            username_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            username_error_text: '',
        });
      }
      // Password
      if (this.state.user.password === '') {
        this.setState({
            password_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            password_error_text: '',
        });
      }

      // Confirm Password
      if (this.state.user.password !== this.state.confirmPassword) {
        this.setState({
            confirm_password_error_text: 'The confirmed password doesn\'t match',
        });
      }
      else {
        this.setState({
            confirm_password_error_text: '',
        });
      }

      // E-mail
      var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.state.user.email === '') {
        this.setState({
            email_error_text: 'This field is required',
        });
      }
      else if (!emailRegEx.test(this.state.user.email)) {
        this.setState({
            email_error_text: 'This email has not a valid form',
        });
      }
      else {
        this.setState({
            email_error_text: '',
        });
      }
    }

    // The form is valid
    else {
      // Check password strength
      if (strengthIndicator(this.state.user.password) > 3) {
        try {
          const baseUrl = window.location.origin;
          const res = await securityService.registerNewUser({user: this.state.user, baseUrl: baseUrl});
          if (res.data.succeed) {
            this.showSuccessSnackBar({msg: res.data.msg});
            if(res.data.feedbackMsg !== undefined)
              this.showInfoSnackBar({msg: res.data.feedbackMsg, iconSize: 'large', hasHtmlContent: true});
            // Go back to login page
            this.props.history.push({
              pathname: rootContextPath + '/',
            });
          }
          else {
            this.showErrorSnackBar({msg: res.data.msg});
          }
          //console.log('res:');
          //console.log(res);

        } catch (e) {
          console.error('Registration Failure!');
          this.showErrorSnackBar({msg: 'Some error occured while registering the new user. Please contact with the administrator for resolving this issue.'});
          if(e.response !== undefined) {
            console.error(e.response.status);
          }
        }
      }
      else {
        this.showErrorSnackBar({
          msg: 'You should set some stronger password in order to proceed with this registration form. Please try using at least 8 characters and include numbers, special characters and both low and upper case letters.'});
      }

    }
  }


  handleChange = name => event => {
    // Username
    if(name === 'username') {
      if (event.target.value === '') {
        this.setState({
            username_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            username_error_text: '',
        });
      }
    }

    // Password
    if(name === 'password') {
      if (event.target.value === '') {
        this.setState({
            password_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            password_error_text: '',
        });
      }
      if (event.target.value !== this.state.confirmPassword) {
        this.setState({
            confirm_password_error_text: 'The confirmed password doesn\'t match',
        });
      }
      else {
        this.setState({
            confirm_password_error_text: '',
        });
      }

      // Integer indicating strength
      const passwordStrengthValue = strengthIndicator(event.target.value);
      // Color indicating strength
      const passwordStrengthColor = strengthColor(passwordStrengthValue);
      //console.log('passwordStrengthValue: ' + passwordStrengthValue);
      //console.log('passwordStrengthColor: ' + passwordStrengthColor);
      this.setState({
        passwordStrengthColor: passwordStrengthColor,
        passwordStrengthValue: passwordStrengthValue,
      });
    }
    // email
    if(name === 'email') {
      var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (event.target.value === '') {
        this.setState({
            email_error_text: 'This field is required',
        });
      }
      else if (!emailRegEx.test(event.target.value)) {
        this.setState({
            email_error_text: 'This email has not a valid form',
        });
      }
      else {
        this.setState({
            email_error_text: '',
        });
      }
    }

    this.setState({
      user: {
        ...this.state.user,
        [name]: event.target.value,
      }
    });
  };

  handleChangeConfirm = name => event => {
    if (event.target.value !== this.state.user.password) {
      this.setState({
          confirm_password_error_text: 'The confirmed password doesn\'t match',
      });
    }
    else {
      this.setState({
          confirm_password_error_text: '',
      });
    }
    this.setState({
        [name]: event.target.value,
    });
  };

  // props: {msg: <Text>}
  showSuccessSnackBar = props => {
    this.props.enqueueSnackbar(
      props.msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500
      }
    );
  }

  // props: {msg: <Text>, iconSize: 'large | small (default: 'small')'}
  showErrorSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'error'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
                              hasHtmlContent={props.hasHtmlContent !== undefined ? props.hasHtmlContent : false }/>
        ),
      }
    );
  }

  showInfoSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'info'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
                              hasHtmlContent={props.hasHtmlContent !== undefined ? props.hasHtmlContent : false }/>
        ),
      }
    );
  }

  render() {
    const styleImageLoaded = this.state.imageLoaded ? {height: 'calc(100vh - 22px)'} : {visibility: 'hidden', height: 'calc(100vh - 22px)'}
    const { classes } = this.props;

    //noValidate
    return (
      <Card style={styleImageLoaded}>
        <CardContent>
          <div style={{display: 'flex'}}>
            <div>
              <Fab
                size="small"
                color="primary"
                aria-label="ArrowBack"
                className={classes.fab}
                component={Link}
                to={rootContextPath + '/'}
              >
                <ArrowBackIcon />
              </Fab>
            </div>
            <Typography variant="h5" color="textSecondary" style={{marginLeft: 20, marginTop: 10}}>
              <img src={logo} alt="logo-256" style={{paddingRight:'5px', height:'50px'}} onLoad={this.onImageLoad}/>
              <span style={{paddingLeft: 15}}>Registration Form</span>
            </Typography>
          </div>
          <form ref={form => this.formEl = form}
                className={classes.container}
                autoComplete="off"
                noValidate
                style={{marginLeft: 70}}>

            <TextField
              label="First Name"
              className={classes.textField}
              value={this.state.user.firstname}
              onChange={this.handleChange('firstname')}
              margin="normal"
            />
            <TextField
              label="Last Name"
              className={classes.textField}
              value={this.state.user.lastname}
              onChange={this.handleChange('lastname')}
              margin="normal"
            />
            <TextField
              required
              error={this.state.email_error_text !== '' && this.state.email_error_text !== undefined}
              helperText={this.state.email_error_text}
              label="E-mail"
              className={classes.textField}
              value={this.state.user.email}
              onChange={this.handleChange('email')}
              margin="normal"
            />
            <TextField
              required
              error={this.state.username_error_text !== '' && this.state.username_error_text !== undefined}
              helperText={this.state.username_error_text}
              label="User Name"
              className={classes.textField}
              value={this.state.user.username}
              onChange={this.handleChange('username')}
              margin="normal"
            />
            <TextField
              required
              error={this.state.password_error_text !== '' && this.state.password_error_text !== undefined}
              helperText={
                this.state.password_error_text !== '' ?
                this.state.password_error_text :
                (
                  this.state.passwordStrengthColor === 'red' ||
                  this.state.passwordStrengthColor === 'yellow' ||
                  this.state.passwordStrengthColor === 'orange' ?
                  <span className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                    The password set is weak. Please try better!
                  </span> :
                  (
                    this.state.passwordStrengthColor === 'lightgreen' ?
                    <span  className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                      The password set is OK. You can do better if you like though!
                    </span>:
                    (
                      this.state.passwordStrengthColor === 'green' ?
                      <span  className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                        The password set is very strong!
                        </span> :
                      ''
                    )
                  )
                )
              }
              label="Password"
              type="password"
              className={classes.textField}
              value={this.state.user.password}
              onChange={this.handleChange('password')}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes[this.state.passwordStrengthColor + 'LabelColor'],
                }
              }}
              InputProps={{
                classes: {
                  underline: classes[this.state.passwordStrengthColor + 'TextFieldUnderline'],
                  root: classes.redTexfieldColor
                }
              }}
            />
            <TextField
              required
              disabled={this.state.user.password === ''}
              error={this.state.user.password !== '' && this.state.confirmPassword !== this.state.user.password}
              label="Confirm Password"
              type="password"
              className={classes.textField}
              value={this.state.confirmPassword}
              onChange={this.handleChangeConfirm('confirmPassword')}
              margin="normal"
              helperText={this.state.confirm_password_error_text}
            />
            {/*
            <div hidden={this.state.confirmPassword === this.state.user.password}>
              <span style={{color: '#f44336', marginLeft: '8px'}}>The confirmed password doesn't match</span>
            </div>
            */}
            <div style={{marginLeft: 10, marginTop: 15}}>
              <Button variant="contained"
                      color="primary"
                      onClick={this.handleSubmit}
                      disabled={
                        (this.state.username_error_text !== '' && this.state.username_error_text !== undefined) ||
                        (this.state.password_error_text !== '' && this.state.password_error_text !== undefined) ||
                        (this.state.user.password !== '' && this.state.confirmPassword !== this.state.user.password) ||
                        (this.state.email_error_text !== '' && this.state.email_error_text !== undefined)}
              >
                Register
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withSnackbar)(Registration);
