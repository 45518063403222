import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';
//import Paper from '@material-ui/core/Paper';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { FileUploadService } from '.././services/FileUploadService';
import { SecurityService } from '.././services/SecurityService';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Zoom from '@material-ui/core/Zoom';
import configuration from '../configuration.json';

const dirRootContextPath = configuration.dirRootContextPath; // This is different than the regular rootContextPath (/3m) and avatarRootContextPath (3m/)

const styles = theme => ({

  filePondRoot: {
    //height: 157,
    marginBottom: 0,
    marginTop: 15,
  },
  expandPanelHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  closeIconWrapper: {
    position: 'relative',
    paddingTop: 5,
    zIndex: 1,
  },
  closeIcon: {
    position: 'absolute',
    padding: 'unset',
    right: 0,
    top: 0,
  },
  itemToDeleteHighlighted: {
    outline: '3px dashed #f50057',
  },
  downloadButton: {
    marginTop: -40,
    marginRight: 30,
    fontSize: 'smaller',
    zIndex: 6,
  },
  downloadButtonWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  icon: {
    margin: theme.spacing(1),
  },
});


const fileUploadService = new FileUploadService();
const securityService = new SecurityService();

export class FileAndMetadataUploader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      //metadata: {
      //  title: '',
      //  description: '',
    //  },
      uploadErrorLabel: '',
      files: [],
      downloadReady: false,
    };
    this.formMetadata = React.createRef();
    this.handleAfterfileUpload=this.handleAfterfileUpload.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.state !== nextState) {
      return true;
    }
    if(this.props.index !== nextProps.index) {
      return true;
    }
    if(this.props.inactive !== nextProps.inactive) {
      return true;
    }
    if(this.props.fileMetadata !== nextProps.fileMetadata) {
      return true;
    }
    else {
      return false;
    }
  }


  componentDidMount() {

    if(this.props.fileMetadata.files !== undefined && this.props.fileMetadata.fileSize !== null) {
      let mockfiles = []; // Create a copy
      this.props.fileMetadata.files.forEach(fileItem => {
        //fileItem.status= 2;
        let mockFile = {
          source: this.props.fileMetadata.relativePath,
          //allowRevert: false,
          options: {
            type: 'local',
            //status: 'error',
            //fileStatus: PROCESSING_COMPLETE
            file: fileItem
          }
        }
        mockfiles.push(mockFile);
      });

      this.setState({
        files: mockfiles,
        downloadReady: true,
      });
    }

    // Tracking references

    // Only apply if the "trackRefs" flag exists and says so
    // (case when this comes from the fileMetadataManager)
    if(this.props.trackRefs !== undefined ? this.props.trackRefs : false) {
      this.props.handleHoldRefRecord({
        refRecType: 'fileMetadataRefRecordMap',
        ref: this.formMetadata,
        fileMetadataId: this.props.fileMetadata.id
      });
    }
  }

  componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.fileMetadata !== prevProps.fileMetadata) {
    if(this.props.fileMetadata.files !== undefined) {
      console.log('componentDidUpdate');
      let mockfiles = []; // Create a copy
      this.props.fileMetadata.files.forEach(fileItem => {
        //fileItem.status= 2;
        let mockFile = {
          source: this.props.fileMetadata.relativePath,
          //allowRevert: false,
          options: {
            type: 'local',
            //status: 'error',
            //fileStatus: PROCESSING_COMPLETE
            file: fileItem
          }
        }
        mockfiles.push(mockFile);
      });

      this.setState({
        files: mockfiles,
        downloadReady: true
      });
    }
    else {
      this.setState({
        downloadReady: false
      });
    }
  }
}

  // Using this.props: {listType: 'source | target | generatorPolicy', index: <SOME_INTEGER>}
  // file: <THE_FILE_INSTANCE>
  handleBeforeUpload = async (file) => {
    // Calling the respective function on the parent
    // if(this.formMetadata !== undefined) {
    //   if(!this.formMetadata.checkValidity()) {
    //     // Use file's name as title
    //     await this.props.handleSetFileMetadataInput(
    //       // The regEx used, removes the extension of the file no mater how many dots the file has or how long the extension is
    //       {fieldName: 'title', listType: this.props.type, index: this.props.index, value: file.filename.replace(/\.[^/.]+$/, '')}
    //     );
    //     // Make the form look valid again (not done automatically)
    //     this.props.handleMarkFieldAsValid({listType: this.props.type, fieldName: 'title', index: this.props.index});
    //   }
    // }
    return true;
  };

  handleAfterfileUpload = async (error, file) => {
    // No error
    if(error === null) {
      let fileMetadata = this.props.fileMetadata;
      fileMetadata.filename = file.filename;
      fileMetadata.fileExtension = file.fileExtension;
      fileMetadata.fileType = file.fileType;
      fileMetadata.fileSize = file.fileSize;
      fileMetadata.relativePath = file.serverId;
      fileMetadata.isPreDefined = false;
      fileMetadata.isInitialized = false;
      fileMetadata.files = this.state.files;

      // Setting title automatically (when not set) and setting mock files
      let mockFile = {
        //lastModifiedDate: fileMetadata.uploadDate,
        name: fileMetadata.filename,
        size: fileMetadata.fileSize,
        type: fileMetadata.fileType,
      }
      // Use file's name as title
      // The regEx used, removes the extension of the file no mater how many dots the file has or how long the extension is
      const fileMetadataId = await this.props.handleSetFileMetadataInput({
        fieldName: 'title',
        listType: this.props.type,
        index: this.props.index,
        value: (this.props.fileMetadata.title !== undefined ? this.props.fileMetadata.title === '' : true) ?
               file.filename.replace(/\.[^/.]+$/, '') :
               this.props.fileMetadata.title,
        files: [mockFile]
      });
      // Make the form look valid again (not done automatically)
      await this.props.handleMarkFieldAsValid({listType: this.props.type, fieldName: 'title', index: this.props.index});
    }
  };

  handleLFileProcessingError = (error) => {
    return 'Error occured during file upload - ' + error.body;
  }

  // props: {status:'mouseEnter | mouseLeave}
  handleHoverDelete = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({isHovered: hovered});
  }

  handleDownloadFile = props => async () => {
    console.log('Downloading file with id ' + props.id + '...');
    await fileUploadService.downloadFileById({id: props.id});

  }

  printState = () => {
    console.log(this.state);
    console.log(JSON.stringify(this.state));
  }

  render() {

    const {
            classes, fileMetadata, type, index, showFileCounter, inactive,
            handleFileMetadataInputChange, handleDeleteFileMetadata,
          } = this.props;

    console.log('render - FileAndMetadataUploader');

    let filePondLabel = fileMetadata.file_error_text === undefined || fileMetadata.file_error_text === '' ?
                        "<span class=\"filePondCustomLabelStyle\">To add new source, Drag & Drop your file here or <span class=\"filepond--label-action\"> Browse </span>" :
                        "<span style=\"color: red\" class=\"filePondCustomLabelStyle\">To add new source, Drag & Drop your file here or <span class=\"filepond--label-action\"> Browse </span>";

    return (
      <div>
        <Divider style={{margin: '15px 0px 15px 0px', marginLeft: -16, marginRight: -16}}/>
        <div className={this.state.isHovered ? classes.itemToDeleteHighlighted : ''} style={{padding: 5}}>
          <div className={classes.closeIconWrapper} hidden={!showFileCounter}>
            <IconButton
              className={classes.closeIcon}
              aria-label="Delete"
              onClick={handleDeleteFileMetadata({type: type, fileMetadata: fileMetadata})}
              onMouseEnter={this.handleHoverDelete({status:'mouseEnter'})}
              onMouseLeave={this.handleHoverDelete({status:'mouseLeave'})}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{fontWeight: 'bold'}} hidden={!showFileCounter}>File #{index+1}</div>
          <form
            ref={form => this.formMetadata = form}
            autoComplete="off"
            noValidate
            style={{padding: 0, background:'unset'}}
          >
            <TextField
              required
              margin="dense"
              label="Title"
              placeholder="Plese enter the title of this file"
              fullWidth
              error={fileMetadata.title_error_text !== '' && fileMetadata.title_error_text !== undefined}
              helperText={fileMetadata.title_error_text}
              onChange={handleFileMetadataInputChange({fieldName: 'title', listType: type, index: index})}
              value={fileMetadata.title}
              style={{marginTop:0}}
              disabled={inactive !== undefined ? inactive : false}
            />
            <TextField
              margin="dense"
              label="Description"
              placeholder="Please enter some description of this file"
              multiline
              fullWidth
              onChange={handleFileMetadataInputChange({fieldName: 'description', listType: type, index: index})}
              value={fileMetadata.description}
              disabled={inactive !== undefined ? inactive : false}
            />
            <TextField
              margin="dense"
              label="Version"
              placeholder="Please enter the version of this file"
              fullWidth
              onChange={handleFileMetadataInputChange({fieldName: 'version', listType: type, index: index})}
              value={fileMetadata.version !== null ? fileMetadata.version : ''}
              disabled={inactive !== undefined ? inactive : false}
            />
          </form>
          <div>
            <FilePond
              name="file"
              className={classNames(classes.filePondRoot, 'filePondCustomMock')}
              ref={ref => this.pond = ref}
              files={this.state.files}
              onupdatefiles={
                (fileItems) => {
                  if(this.formMetadata !== null) {
                    if(this.formMetadata.checkValidity()) {
                      this.setState({
                        files: fileItems.map(fileItem => fileItem.file)
                      });
                    }
                  }
                }
              }
              server={
                {
                  url: (dirRootContextPath === '' ? '.' : '') + dirRootContextPath + '/uploadFilepond/' + fileMetadata.fileScope,
                  process: {
                    headers: {
                      Authorization: Cookies.get('3mToken')
                    },
                  },
                  revert: {
                    headers: {
                      Authorization: Cookies.get('3mToken')
                    },
                    method: 'DELETE'
                  }
                }
              }
              labelIdle={filePondLabel}
              labelFileProcessingError={this.handleLFileProcessingError}
              allowMultiple={false}
              allowRevert={false}
              onprocessfile={this.handleAfterfileUpload}
              beforeAddFile={this.handleBeforeUpload}
              allowReplace={inactive !== undefined ? !inactive : true}
              credits="false"
            />

          </div>

          <Zoom in={this.state.downloadReady} timeout={{enter: 300, exit: 300}}>
            <div className={classes.downloadButtonWrapper}>
              <Fab variant="extended"
                   size="small"
                   color="primary"
                   aria-label="download"
                   className={classes.downloadButton}
                   onClick={this.handleDownloadFile({id: fileMetadata.id})}>
                <Icon className={classNames(classes.icon, 'fa fa-download')} fontSize="small"/>
                Download
              </Fab>
            </div>
          </Zoom>
        </div>
        {/*
        <Button color="primary"
                className={classes.button}
                onClick={this.printState}>
          Print State
        </Button>
        */}
      </div>
    );
  }
}

FileAndMetadataUploader.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(FileAndMetadataUploader);
