import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '.././Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
    //minHeight: '80vh',
    //maxHeight: '80vh',
  },
  card: {
    margin: 10,
    width: '50%'
  },
  singleCard: {
    margin: 10,
    width: '100%'
  },
  formRoot: {
    padding: 0,
    background: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
});

export class RichMessageDialog extends React.Component {

  state = {
    test: '',
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.dialogMsg !== nextProps.dialogMsg) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  render() {
    const {
      classes, dialogMsg, dialogOpen, fullScreen, onToggleFullScreen, handleCloseDialog,
      handleInputChange, handleSaveVariable, handleInputSelectChange,
    } = this.props;

    console.log('RichMessageDialog Rendered');

    return (
      <Dialog open={dialogOpen}
              aria-labelledby="generator-dialog"
              TransitionComponent={Fade}
              transitionDuration={{enter: 400, exit: 400}}
              maxWidth="sm"
              scroll={'paper'}
              fullScreen={fullScreen}
              PaperProps={{
                classes: {
                  root: fullScreen ? classes.dialogCustomPaper : ''
                }
              }}>
        <DialogTitle style={{paddingBottom: 0}}>
          {dialogMsg.title}
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                {fullScreen ? <FilterNoneIcon style={{width: 20}}/> : <CropSquareIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={handleCloseDialog}>
                          {/*padding: 'unset'*/}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
          {renderHTML(dialogMsg.content)}
        </DialogContent>
        <DialogActions>
          <div style={{paddingRight: 15}}>
            <Button onClick={handleCloseDialog}
                    color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

RichMessageDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RichMessageDialog);
