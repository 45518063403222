import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '.././Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
  },
  card: {
    margin: 10,
    width: '50%'
  },
  singleCard: {
    margin: 10,
    width: '100%'
  },
  formRoot: {
    padding: 0,
    background: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
});




export class NamedgraphDialog extends React.Component {

  state = {
    test: '',
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.namedgraph !== nextProps.namedgraph) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleTest = props => event => {
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, onToggleFullScreen, onLeave, saveNow, shakeNow,
      namedgraph, namedgraphHistory,
      handleInputChange, handleSaveNamedgraph, handleSaveNamedgraphCallBack, handleInputSelectChange, 
      // handleBlur, //handleCreateOption
    } = this.props;

    console.log('GeneratorDialog Rendered');

    const noDuplicatesNamedgraphHistory = namedgraphHistory.filter((thing, index, self) =>
      index === self.findIndex((t) =>
        (t.value === thing.value && t.label === thing.label)
      )
    );

    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="generator-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="xl"
        scroll={'paper'}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : ''
          }
        }}
        onKeyUp={(e) => {
          const ENTER = 13;
          if (e.keyCode === ENTER) {
            handleSaveNamedgraphCallBack({namedgraph: namedgraph});
          }
        }}
      >
        <DialogTitle style={{paddingBottom: 0}}>
          Named Graph
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onLeave({actionType: 'close'})}>
                          {/*padding: 'unset'*/}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Please either fill in some value for the Named Graph, leave it empty, or turn it off.
          </DialogContentText>
      	  <Card>
        		<CardContent>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{textAligned: 'right'}}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={namedgraph.turnedOn}
                        onChange={handleInputChange({fieldName: 'turnedOn'})}
                        value={namedgraph.turnedOn}
                        color="secondary"
                      />
                    }
                    label="Enabled"
                    labelPlacement="start"
                  />
                </div>
                <FormControl className={classes.formControl} error={namedgraph.error_text !== undefined}>
                  <Autocomplete label={'Named Graph'}
                                disabled={!namedgraph.turnedOn}
                                placeholder={'Type some value or leave it empty'}
                                options={noDuplicatesNamedgraphHistory}
                                value={
                                  namedgraph.value !== '' ?
                                  {
                                    label: namedgraph.value,
                                    value: namedgraph.value,
                                  } :
                                  ''
                                }
                                onChange={handleInputSelectChange({fieldName: 'value'})}
                                onInputChange={handleInputChange({fieldName: 'value'})}
                                isMulti={false}
                                async={false}
                                error={namedgraph.error_text !== undefined}
                                errorText={namedgraph.error_text}
                                noOptionsMessage={() => '"' + namedgraph.value + '" will be created when the save button is pressed'}
                  />
                </FormControl>
              </div>
        		</CardContent>
      	  </Card>
        </DialogContent>
        <DialogActions>
          <div style={{paddingRight: 15}}>
            <Button
              onClick={handleSaveNamedgraph({namedgraph: namedgraph})}
              color={!saveNow ? 'primary' : 'secondary'}
            >
              <span className={shakeNow ? 'rumble' : ''}>save</span>
            </Button>
          </div>
          <div style={{paddingRight: 15}}>
            <Button onClick={onLeave({actionType: 'close'})} color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

NamedgraphDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NamedgraphDialog);
