import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ConstExprEdit from './ConstExprEdit';
import ConstExprView from './ConstExprView';

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
    //minHeight: '80vh',
    //maxHeight: '80vh',
  },
  card: {
    margin: 10,
    width: '50%'
  },
  singleCard: {
    margin: 10,
    width: '100%'
  },
  formRoot: {
    padding: 0,
    background: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  constExprNodesWrapper: {
    borderLeft:'solid rgba(0, 0, 0, 0.54)',
    marginLeft: 10,
    //marginTop:5,
    paddingRight:10,
    width: '100%',
  },
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

export class NativeConstExprDialog extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.nativeConstExpr !== nextProps.nativeConstExpr) {
      return true;
    }
    if(this.props.level !== nextProps.level) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleTest = props => event => {
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, onToggleFullScreen, onLeave, saveNow, shakeNow,
      nativeConstExpr, currMappingProjectId, nativeConstExprProps,
      handleInputChange, handleSave, handleInputSelectChange, handleGoBack, handleAddNewNativeConstExpr,
    } = this.props;

    const recursionArray = nativeConstExprProps.recursionArray;
    const level = nativeConstExprProps.level;

    console.log('NativeConstExprDialog Rendered');

    var nativeEditConstExprList =
      nativeConstExpr !== undefined ?
        nativeConstExpr.constExprIds !== undefined ?
        nativeConstExpr.constExprIds.map((constExprId, constExprIndex) => {
          let constExpr = nativeConstExpr.constExprs[constExprId];
          return (
            <Slide
              direction={'left'}
              key={'nativeConstExpr_' + constExprId}
              style={{ transitionDelay: '400ms' }}
            >
               <ConstExprEdit
                 currMappingProjectId={currMappingProjectId}
                 mappingId={nativeConstExprProps.mappingId}
                 linkId={nativeConstExprProps.linkId}
                 intermediateId={nativeConstExprProps.intermediateId}
                 constExprId={constExprId}
                 constExpr={constExpr}
                 allClasses={this.props.allClasses}
                 previousEntity={nativeConstExpr.entity}
                 handleDeleteConstExpr={this.props.handleDeleteConstExpr}
                 handleInputChange={this.props.handleInputChange}
                 handleInputChangeButton={this.props.handleInputChangeButton}
                 handleUseGenerator={this.props.handleUseGenerator}
                 handleUseVariable={this.props.handleUseVariable}
                 handleDirectDeleteVariable={this.props.handleDirectDeleteVariable}
                 handleGoToGeneratorDeclarationForm={this.props.handleGoToGeneratorDeclarationForm}
                 patternTypeColors={this.props.patternTypeColors}
                 compactViewMode={this.props.compactViewMode}
                 handleShowConfirmDeleteSnackbar={this.props.handleShowConfirmDeleteSnackbar}
                 handleDirectDeleteInstanceGeneratorDeclaration={this.props.handleDirectDeleteInstanceGeneratorDeclaration}
                 handleDirectDeleteLabelGeneratorDeclarations={this.props.handleDirectDeleteLabelGeneratorDeclarations}
                 handleUseInstanceInfo={this.props.handleUseInstanceInfo}
                 handleDirectDeleteInstanceInfo={this.props.handleDirectDeleteInstanceInfo}
                 instanceInfoStyleBasedOnLinesRequired={this.props.instanceInfoStyleBasedOnLinesRequired}
                 compactViewEnabled={this.props.compactViewEnabled}
                 handleDisplayConstExpr={this.props.handleDisplayConstExpr}
                 recursionArray={recursionArray}
                 level={level}
               />
            </Slide>
          );
        }) :
        [] :
      [];

    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="native-constant-expression-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="xl"
        scroll={'paper'}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : ''
          }
        }}
      >
        <DialogTitle style={{paddingBottom: 0}}>
          <div style={{display: 'flex'}}>
            <Fade in={recursionArray !== undefined ? recursionArray.length > 1 : false} timeout={{enter: 400, exit: 400}}>
              <div style={{paddingRight: 10, marginTop: 10}}>
                <Tooltip title="Back"
                       placement="bottom-start"
                       enterDelay={500}
                       leaveDelay={200}>
                  <Fab className={classNames(classes.fab)} color="primary"
                       onClick={
                         handleGoBack({
                           mappingId: nativeConstExprProps.mappingId,
                           linkId: nativeConstExprProps.linkId,
                           intermediateId: nativeConstExprProps.intermediateId,
                           constExprId: nativeConstExprProps.constExprId,
                           recursionArray: recursionArray
                         })
                       }
                       size="small">
                    <NavigateBeforeIcon style={{fontSize: 32}}/>
                  </Fab>
                </Tooltip>
              </div>
            </Fade>
            <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', flexGrow: 1}}>
              {'Additional - Native Level: ' + level}
            </div>
            <div style={{marginRight: -20, paddingLeft: 10, marginTop: -12, whiteSpace: 'nowrap'}}>
              <Tooltip
                title={fullScreen ? 'Minimize' : 'Maximize'}
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton className={classes.button}
                  aria-label="maximize"
                  onClick={onToggleFullScreen}
                  style={{marginRight: -10}}
                >
                  <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                  <CropSquareIcon hidden={fullScreen}/>
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Close"
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  aria-label="close"
                  onClick={onLeave({actionType: 'close'})}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Navigating through the native additionals within their parent additional.
          </DialogContentText>
      	  <Card>
        		<CardContent>
              <div style={{display: 'flex'}}>
                <div>
                  <Typography style={{fontSize: '1.1rem'}}>
                    {'Selected Parent Additional (level ' + level + '):'}
                  </Typography>
                  {
                    nativeConstExpr !== undefined && nativeConstExpr !== '' ?
                    <div style={{position: 'relative'}}>
                      <div
                        style={{
                          zIndex: 11,
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                          width: 'calc(100%)',
                          height: '100%',
                          position: 'absolute',
                        }}
                      />
                      <div style={{marginLeft:-20}}>
                        <ConstExprView
                          currMappingProjectId={currMappingProjectId}
                          constExpr={nativeConstExpr}
                          constExprId={nativeConstExpr.id}
                          constExprIndex={nativeConstExprProps.index}
                          patternTypeColors={this.props.patternTypeColors}
                          compactViewMode={this.props.compactViewMode}
                          compactViewEnabled={this.props.compactViewEnabled}
                        />
                      </div>
                    </div> :
                    ''
                  }
                </div>
                <div>
                  <Typography style={{marginLeft: 32, fontSize: '1.1rem'}}>
                    Chldren Additional(s):
                  </Typography>
                  <div
                    className={classes.constExprNodesWrapper}
                    style={{transition: 'all 300ms ease-in-out', display:'flex'}}
                  >
                    <TransitionGroup>
                      {nativeEditConstExprList}
                    </TransitionGroup>
                  </div>

                </div>
                <div>
                  <div style={{position: 'absolute', bottom: 52, right: 34}}>
                    <Tooltip
                      title="Add New Native Additional"
                      placement="right"
                    >
                      <Fab
                        color="primary"
                        aria-label="add-native-additional"
                        onClick={
                          handleAddNewNativeConstExpr({
                            mappingId: nativeConstExprProps.mappingId,
                            linkId: nativeConstExprProps.linkId,
                            intermediateId: nativeConstExprProps.intermediateId,
                            constExprId: nativeConstExprProps.constExprId,
                            constExpr: nativeConstExpr,
                            recursionArray: recursionArray,
                          })
                        }
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                </div>


              </div>
        		</CardContent>
      	  </Card>
        </DialogContent>
        <DialogActions>
          <div style={{paddingRight: 15}}>
            <Button onClick={onLeave({actionType: 'close'})} color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

NativeConstExprDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NativeConstExprDialog);
