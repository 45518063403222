import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();
const rootContextPath = configuration.rootContextPath;

export class MessageService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }

  /*
  Retrieving all the messages from the server
  */
  async retrieveAllMessages() {
     return await axiosInstance(rootContextPath + '/message/retrieveAllMessages', {
      method: 'get',
    });
  }
  // async retrieveAllMessages(view) {
  //    return await axiosInstance('/message/retrieveAllMessages' + (view !== undefined ? ('/' + view) : ''), {
  //     method: 'get',
  //   });
  // }
  /*
  Retrieving all the unread messages (for this user)
  */
  async retrieveUnreadMessages() {
     return await axiosInstance(rootContextPath + '/message/retrieveUnreadMessages', {
      method: 'get',
    });
  }

  /*
  Retrieving all the received messages (for this user)
  */
  async retrieveAllMessagesReceivedByThisUser() {
     return await axiosInstance(rootContextPath + '/message/retrieveReceivedMessages', {
      method: 'get',
    });
  }

  /*
  Retrieving the count of the unread messages (for this user)
  */
  async countUnreadMessages() {
     return await axiosInstance(rootContextPath + '/message/countUnreadMessages', {
      method: 'get',
    });
  }

  /*
  Retrieving the count of the unread messages
  */
 async retrieveAllMessagesSentByThisUser() {
   return await axiosInstance(rootContextPath + '/message/retrieveAllMessagesSentByThisUser', {
     method: 'get',
   });
 }

 async markMessageAsReadByIdForCurrentUser(id) {
    return await axiosInstance(rootContextPath + '/message/markMessageAsReadByIdForCurrentUser', {
     method: 'get',
     params: id,
   });
 }

 // Removes the messages in the list
 async removeListOfMessages(data) {
    return await axiosInstance(rootContextPath + '/message/removeListOfMessages', {
     method: 'post',
     data: data,
   });
 }

}
