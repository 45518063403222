import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DonutChartProgress from './DonutChartProgress';

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
  },
  circularUnderStyle: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  circularOnTopStyle: {
    color: theme.palette.primary.main,
    position: 'absolute',
    left: 0,
  },
  currUsedStyle: {
    color: theme.palette.primary.main,
  },
  internalCircle: {
    backgroundColor: '#f6a2c0',
    borderRadius: 50,
    margin: 30,
  },
});




export class SourceInputCoverageDialog extends React.Component {

  state = {
    test: '',
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.xpaths !== nextProps.xpaths) {
      return true;
    }
    if(this.props.toCoverXpaths !== nextProps.toCoverXpaths) {
      return true;
    }
    if(this.props.showSourceCoverage !== nextProps.showSourceCoverage) {
      return true;
    }
    if(this.props.currentlyUsedXpaths !== nextProps.currentlyUsedXpaths) {
      return true;
    }
    if(this.props.sourceInputCoverageValue !== nextProps.sourceInputCoverageValue) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, xpaths, toCoverXpaths, showSourceCoverage, currentlyUsedXpaths, onToggleFullScreen,
      onClose, sourceInputCoverageValue, handleSave, handleToggleXpath, handleSelectAllXpaths, handleToggleShowSourceCoverage,
    } = this.props;

    console.log('SourceInputCoverageDialog Rendered');

    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="sourceInputCoverageDialog-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="xl"
        scroll={'paper'}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : ''
          }
        }}
        onKeyUp={(e) => {
          const ENTER = 13;
          if (e.keyCode === ENTER) {
            onClose();
          }
        }}
      >
        <DialogTitle style={{paddingBottom: 0}}>
          Source Input Coverage
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip
              title={fullScreen ? 'Minimize' : 'Maximize'}
              placement="bottom"
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton
                className={classes.button}
                aria-label="maximize"
                onClick={onToggleFullScreen}
                style={{marginRight: -10}}
              >
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Close"
              placement="bottom"
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton
                className={classes.button}
                aria-label="close"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
      	  <Card>
        		<CardContent>
              <DonutChartProgress
                value={sourceInputCoverageValue}
                size={170}
                thickness={10}
                variant="h4"
                fillInternalCircle={false}
                inputProps={{
                  classes: {
                    internalCircle: classes.internalCircle,
                  },
                }}
              />
              <Typography>
                The checked XPaths are taken into account for calculating the coverage.
                <br/>
                Blue XPaths have been used at least one time within the mapping.
              </Typography>
              <div style={{textAlign: 'end', paddingRight: 32}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={toCoverXpaths !== null ? toCoverXpaths.filter(obj => obj.selected).length === xpaths.length : false}
                      indeterminate={
                        toCoverXpaths !== null ?
                        (toCoverXpaths.filter(obj => obj.selected).length < xpaths.length && toCoverXpaths.filter(obj => obj.selected).length > 0) :
                        false
                      }
                      onChange={handleSelectAllXpaths}
                      color="primary"
                    />
                  }
                  label="Select All"
                  labelPlacement="start"
                  style={{marginLeft: 4}}
                />
              </div>
              <List dense style={{overflowY: 'auto', maxHeight: 'calc(100vh - 494px)'}}>
                {xpaths.map((xpath) => {
                  const value = xpath.value;
                  return (
                    <ListItem
                      key={`xpath-${value}`}
                      button
                      className={currentlyUsedXpaths.has(value) ? classes.currUsedStyle : ''}
                    >
                      <ListItemText primary={value} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={handleToggleXpath(value)}
                          checked={
                            toCoverXpaths !== null ?
                            toCoverXpaths.filter(obj => obj.selected).map(obj => obj.value).includes(value) :
                            true
                          }
                          disabled={currentlyUsedXpaths.has(value)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>

        		</CardContent>
      	  </Card>
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-between'}}>
          <FormControlLabel
            control={
              <Switch
                checked={showSourceCoverage}
                onChange={handleToggleShowSourceCoverage}
              />
            }
            label={
              <Typography variant="body1" noWrap={true}>Show Source Input Coverage on the Mapping Projects Table</Typography>
            }
            style={{paddingLeft: 15}}
          />
          <div style={{paddingRight: 15}}>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

SourceInputCoverageDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SourceInputCoverageDialog);
