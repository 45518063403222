import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './components/css/antd.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from './serviceWorker';
//import Router from 'react-router-dom/BrowserRouter';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    //primary: blue,
    primary: {
      main: blue[500],
      light: blue[100],
      blueGreyLight: blueGrey[100],
      blueGreyVeryLight: blueGrey[50],
      dark: blue[800],
    },
    //secondary: deepOrange,
    action: {
      hoverOpacity: 0.14 // 0.04
    }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3} style={{width: 532}}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </MuiThemeProvider>
  , document.getElementById('root'));
//registerServiceWorker();
//serviceWorker.unregister();
////serviceWorker.register();
