import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Icon from '@material-ui/core/Icon';
//import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import MenuIcon from '@material-ui/icons/Menu';
import SvgIcon from '@material-ui/core/SvgIcon';
//import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import ConfirmSnackbar from './snackbar/ConfirmationSnackbar';
import axios from 'axios';
import { Link } from 'react-router-dom'
import logo from '../images/logo-white-128-w.png'
import { SketchPicker } from 'react-color';
import configuration from './configuration.json';

function WebSocketIcon(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 240 240'}>
      <path d="M192.440223,144.644612 L224.220111,144.644612 L224.220111,68.3393384 L188.415329,32.5345562 L165.943007,55.0068785 L192.440223,81.5040943 L192.440223,144.644612 L192.440223,144.644612 Z M224.303963,160.576482 L178.017688,160.576482 L113.451687,160.576482 L86.954471,134.079266 L98.1906322,122.843105 L120.075991,144.728464 L165.104487,144.728464 L120.746806,100.286931 L132.06682,88.9669178 L176.4245,133.324599 L176.4245,88.2961022 L154.622994,66.4945955 L165.775303,55.3422863 L110.684573,0 L56.3485097,0 L56.3485097,0 L0,0 L31.6960367,31.6960367 L31.6960367,31.7798886 L31.8637406,31.7798886 L97.4359646,31.7798886 L120.662954,55.0068785 L86.7029152,88.9669178 L63.4759253,65.7399279 L63.4759253,47.7117589 L31.6960367,47.7117589 L31.6960367,78.9046839 L86.7029152,133.911562 L64.3144448,156.300033 L100.119227,192.104815 L154.45529,192.104815 L256,192.104815 L256,192.104815 L224.303963,160.576482 L224.303963,160.576482 Z"></path>
    </SvgIcon>
  );
}

const rootContextPath = configuration.rootContextPath;
const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  badgeMenuButton: {
    top: 10,
    right: 4,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  colorPickerGroopInDialog: {
    display: 'flex',
  },
  colorPickerInDialog: {
    padding: 5,
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    //color: theme.palette.text.secondary,
  },
  listItemIconShorterSpace: {
    minWidth: 40,
  },
  fontAwesomeMenuIcon: {
    fontSize: 18,
  },
  materialMenuIcon: {
    fontSize: 22,
    marginLeft: -3,
  },
  fontAwesomeBarAccountIcon: {
    fontSize: 32,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  largeIcon: {
    fontSize: 56,
  },
});

class MenuAppBar extends React.Component {

  state = {
    anchorEl: null,
    navMenuAnchorEl: null,
    imageLoaded: false,
    projectMappingSettingsDialogOpen: false,
    projectMappingSettingsFullScreen: false,
    configurationExpansionPanels: {
      mappingBackground: false,
      generatorDeclaration: false,
      generatorDefinition: false,
      comments: false,
      compactViewMode: false,
      passwordStrengthRegulation: false,
    },
    confirmCloseConfSettingsSnackbarShown: false,
    confSettingsChanged: false,
  };


  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.configurationSettings !== nextProps.configurationSettings) {
      return true;
    }
    if(this.props.currUser !== nextProps.currUser) {
      return true;
    }
    if(this.props.confirmCloseConfSettingsSnackbarShown !== nextProps.confirmCloseConfSettingsSnackbarShown) {
      return true;
    }
    if(this.props.confSettingsChanged !== nextProps.confSettingsChanged) {
      return true;
    }
    if(this.props.projectMappingSettingsDialogOpen !== nextProps.projectMappingSettingsDialogOpen) {
      return true;
    }

    if(this.props.stompClient !== nextProps.stompClient) {
      return true;
    }
    if(this.props.unreadMessagesCount !== nextProps.unreadMessagesCount) {
      return true;
    }
    if(this.props.isWebSocketConnected !== nextProps.isWebSocketConnected) {
      return true;
    }
    if(this.props.user !== nextProps.user) {
      return true;
    }

    /*if(this.props.isWebSocketConnected !== nextProps.isWebSocketConnected) {
      return true;
    }
    if(this.props.unreadMessagesCount !== nextProps.unreadMessagesCount) {
      return true;
    }*/
    else {
      return false;
    }
  }

  componentDidMount() {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.11.1/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    this.props.handleRetrieveUserByUsernameCallBack(this.props.currUser.currUsername);
  }

  // Only used for showing the form when the image is loaded
  onImageLoad = () => {
    this.setState({
        imageLoaded: true
    })
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleOpenProjectMappingTableSettings = () => {
    this.setState({
      projectMappingSettingsDialogOpen: true,
      anchorEl: null,
    });
  };

  handleClosePojectMappingSettingsDialog = async () => {
    if(!this.state.confSettingsChanged) {
      // Close the dialog
      this.handleConfirmCloseConfSettingsDialog();
    }
    else {
      this.setState({
        confirmCloseConfSettingsSnackbarShown: true
      });
    }
  };

  handlePojectMappingSettingsToggleFullScreen = () => {
    this.setState({
      projectMappingSettingsFullScreen: !this.state.projectMappingSettingsFullScreen
    });
  }

  // props: {type: 'domain | linkOdd | linkEven'}
  // color: Some Example: { hex: '#333', rgb: {r: 51, g: 51, b: 51, a: 1}, hsl: {h: 0, s: 0, l: .20, a: 1}}
  handleSetMappingBackgroundColor = props => (color) => {
    this.setState({
      confSettingsChanged: true,
    }, function() {
      this.props.handleSetMappingBackgroundColorCallBack({colorHex: color.hex, type: props.type});
    });
  };

  resetMappingBackgroundColors = () => {
    this.setState({
      confSettingsChanged: true,
    }, function() {
      this.props.resetMappingBackgroundColorsCallBack();
    });
  };

  // props: {type: 'constant, xpath, position'}
  // color: Some Example: { hex: '#333', rgb: {r: 51, g: 51, b: 51, a: 1}, hsl: {h: 0, s: 0, l: .20, a: 1}}
  handleSetGeneratorDialogTypeColor = props => (color) => {
    this.setState({
      confSettingsChanged: true,
    }, function() {
      this.props.handleSetGeneratorDeclarationTypeColorCallBack({colorHex: color.hex, type: props.type});
    });
  };

  // props {parentName*: <Text>, booleanFieldName: <Text>}
  handleSetBooleanConfigSettingsValue = props => event => {
    let value = event.target.checked;
    this.setState({
      confSettingsChanged: true,
    }, () => {
      this.props.handleSetBooleanConfigSettingsValueCallBack({...props, value: value});
      // this.props.handleSetBooleanConfigSettingsValueCallBack({
      //   booleanFieldName: props.booleanFieldName,
      //   value: value,
      // });
    });
  }

  saveUserConfigurationSettings = async () => {
    this.setState({
      confSettingsChanged: false,
    }, function() {
      this.props.saveUserConfigurationSettingsCallBack();
    });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNavMenuClick = event => {
    this.setState({ navMenuAnchorEl: event.currentTarget });
  };

  handleNavMenuClose = () => {
    this.setState({ navMenuAnchorEl: null });
  };

  handleShowAboutDialog = () => {
    // Close the menu
    this.setState({
      navMenuAnchorEl: null
    }, () => {
      // Show the about dialog
      this.props.openAboutDialog();
    });
  };

  // handleLogout = event => {
  //   event.preventDefault();
  //   // Disconect from the WebSocket
  //   if(this.props.stompClient !== null ? this.props.stompClient.connected : false) {
  //     this.props.stompClient.disconnect();
  //   }
  //   axios.get(rootContextPath + '/logout')
  //   .then((response) => {
  //     console.log("this.props");
  //     console.log(this.props);
  //    })
  //    .catch((error) => {
  //      console.log(error);
  //    })
  //   .then(() => {
  //       // always executed
  //       this.props.onChange();
  //   });
  // }

  handleToggleExpanded = expansionPanelName => () => {
    this.setState({
      configurationExpansionPanels: {
        ...this.state.configurationExpansionPanels,
        [expansionPanelName]: !this.state.configurationExpansionPanels[expansionPanelName],
      },
    });
  };

  handleCloseConfirmCloseConfSettingsSnackbar = () => {
    this.setState({
      confirmCloseConfSettingsSnackbarShown: false,
    });
  }

  handleConfirmCloseConfSettingsDialog = () => {
    this.setState({
      confirmCloseConfSettingsSnackbarShown: false,
      projectMappingSettingsDialogOpen: false,
      confSettingsChanged: false,
    }, async function() {
      // Reset previous settings
      await this.props.retrieveUserConfigurationSettingsCallBack({username: this.props.currUser.currUsername});
    });
  }

  render() {
    const styleImageLoaded = this.state.imageLoaded ? {} : {visibility: 'hidden'}
    const {
      classes, isWebSocketConnected, unreadMessagesCount, configurationSettings, user,
    } = this.props;
    const { anchorEl, navMenuAnchorEl, configurationExpansionPanels } = this.state;
    const open = Boolean(anchorEl);
    //const navMenuOpen = Boolean(navMenuAnchorEl);

    return (
      <div className={classes.root} style={styleImageLoaded}>
        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={this.handleNavMenuClick}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={navMenuAnchorEl}
                  open={Boolean(navMenuAnchorEl)}
                  onClose={this.handleNavMenuClose}
                  style={{marginTop:40}}>
              <MenuItem
                onClick={this.handleNavMenuClose}
                component={Link}
                to={rootContextPath + '/Projects'}
                style={{ textDecoration: 'none' }}
              >
                Projects
              </MenuItem>
              <MenuItem
                hidden={user.id !== undefined ? !user.roles.includes('administrator') : true}
                onClick={this.handleNavMenuClose}
                component={Link}
                to={rootContextPath + '/ManageUsers'}
                style={{ textDecoration: 'none' }}
              >
                User Management
              </MenuItem>
              <MenuItem
                hidden={user.id !== undefined ? !user.roles.includes('administrator') : true}
                onClick={this.handleNavMenuClose}
                component={Link}
                to={rootContextPath + '/ManagePredefinedFileMetadata'}
                style={{ textDecoration: 'none' }}
              >
                Predefined File Management
              </MenuItem>
              <MenuItem
                hidden={user.id !== undefined ? !user.roles.includes('administrator') : true}
                onClick={this.handleNavMenuClose}
                component={Link}
                to={rootContextPath + '/NotificationMessenger'}
                style={{ textDecoration: 'none' }}
              >
                Send User Notifications
              </MenuItem>
              <MenuItem
                onClick={this.handleShowAboutDialog}
                style={{ textDecoration: 'none' }}
              >
                About
              </MenuItem>
            </Menu>

            <Typography variant="h6" color="inherit" className={classes.grow}>
              <img src={logo} alt="logo-256" style={{paddingRight:'5px', height:'50px'}} onLoad={this.onImageLoad}/>
            </Typography>
            {(
              <div>
                <WebSocketIcon
                  disabled={!isWebSocketConnected}
                  style={{
                    marginRight: 8,
                    marginBottom: -10,
                    color: !isWebSocketConnected ? 'rgba(0, 0, 0, 0.26)' : 'unset',
                  }}
                />
                <span style={{padding: 10}}>{user.id !== undefined ? (user.firstname + ' ' + user.lastname) : ''}</span>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit">
                  <div style={{width: 40, height: 40}}>
                    <Fade in={user.avatarImageRelativePath === undefined} unmountOnExit>
                      <div style={{position: 'absolute', top: 4, right: 4}}>
                        <Badge classes={{ badge: classes.badgeMenuButton }}
                               badgeContent={unreadMessagesCount} color="secondary"
                               invisible={unreadMessagesCount <= 0}>
                          <Icon className={classNames(classes.fontAwesomeBarAccountIcon, classes.largeIcon, 'fas fa-user-circle')}/>
                        </Badge>
                      </div>
                    </Fade>
                    <Fade in={user.avatarImageRelativePath !== undefined} unmountOnExit>
                      <div style={{position: 'absolute', top: 4, right: 4}}>
                        <Badge classes={{ badge: classes.badgeMenuButton }}
                               badgeContent={unreadMessagesCount} color="secondary"
                               invisible={unreadMessagesCount <= 0}>
                          <Avatar alt="user-avatar"
                                  src={user.avatarImageRelativePath !== undefined ? avatarRootContextPath + user.avatarImageRelativePath : ''}
                                  className={classes.largeAvatar}
                          />
                        </Badge>
                      </div>
                    </Fade>
                  </div>
                </IconButton>
                <Menu anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={this.handleClose}
                      style={{marginTop:40}}>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={rootContextPath + '/UserProfile'}
                  >
                    <ListItemIcon className={classes.listItemIconShorterSpace}>
                      <Icon className={classNames(classes.fontAwesomeMenuIcon, 'fas fa-user')}/>
                    </ListItemIcon>
                    My Profile
                  </MenuItem>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={rootContextPath + '/Messages'}
                  >
                    <ListItemIcon className={classes.listItemIconShorterSpace}>
                      <NotificationsIcon className={classes.materialMenuIcon}/>
                    </ListItemIcon>
                    Messages
                    <Badge badgeContent={unreadMessagesCount} color="secondary"
                           invisible={unreadMessagesCount <= 0}
                           style={{marginLeft: 26}}
                    />
                  </MenuItem>
                  <MenuItem onClick={this.handleOpenProjectMappingTableSettings}>
                    <ListItemIcon className={classes.listItemIconShorterSpace}>
                      <Icon className={classNames(classes.fontAwesomeMenuIcon, 'fas fa-user-cog')}/>
                    </ListItemIcon>
                    User Configuration Settings
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={this.props.handleLogout}
                        style={{ marginBottom: -8, backgroundColor: '#f50057', color: 'white'}}>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <Dialog open={this.state.projectMappingSettingsDialogOpen}
                aria-labelledby="modal"
                TransitionComponent={Fade}
                transitionDuration={{enter: 400, exit: 400}}
                maxWidth="xl"
                fullScreen={this.state.projectMappingSettingsFullScreen}>
          <DialogTitle style={{paddingBottom: 0}}>
            User Configuration Settings
            <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
              <Tooltip title={this.state.projectMappingSettingsFullScreen ? 'Minimize' : 'Maximize'}
                       placement="bottom"
                       enterDelay={500}
                       leaveDelay={200}>
                <IconButton className={classes.button}
                            aria-label="maximize"
                            onClick={this.handlePojectMappingSettingsToggleFullScreen}
                            style={{marginRight: -10}}>
                  <FilterNoneIcon hidden={!this.state.projectMappingSettingsFullScreen} style={{width: 20}}/>
                  <CropSquareIcon hidden={this.state.projectMappingSettingsFullScreen}/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Close"
                       placement="bottom"
                       enterDelay={500}
                       leaveDelay={200}>
                <IconButton className={classes.button}
                            aria-label="close"
                            onClick={this.handleClosePojectMappingSettingsDialog}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>

          </DialogTitle>

          <DialogContent>
            <Accordion
              expanded={configurationExpansionPanels.mappingBackground}
              onChange={this.handleToggleExpanded('mappingBackground')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Mapping Background Color</Typography>
                <DialogContentText>
                  You can set the colors to be used as background for the domin and link of odd or even index number.
                </DialogContentText>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <div className={classes.colorPickerGroopInDialog}>
                    <div className={classes.colorPickerInDialog}>
                      <Typography variant="subtitle1" gutterBottom>
                        Domain
                      </Typography>
                      <SketchPicker
                        color={
                          configurationSettings.mappingBackgroundColors !== undefined ?
                          configurationSettings.mappingBackgroundColors.domain.color :
                          '#fff'
                        }
                        onChangeComplete={this.handleSetMappingBackgroundColor({type: 'domain'})}
                        width="auto"
                      />
                    </div>
                    <div className={classes.colorPickerInDialog}>
                      <Typography variant="subtitle1" gutterBottom>
                        Link Odd Index Number
                      </Typography>
                      <SketchPicker
                        color={
                          configurationSettings.mappingBackgroundColors !== undefined ?
                          configurationSettings.mappingBackgroundColors.linkOdd.color :
                          '#fff'
                        }
                        onChangeComplete={this.handleSetMappingBackgroundColor({type: 'linkOdd'})}
                        width="auto"
                      />
                    </div>
                    <div className={classes.colorPickerInDialog}>
                      <Typography variant="subtitle1" gutterBottom>
                        Link Even Index Number
                      </Typography>
                      <SketchPicker
                        color={
                          configurationSettings.mappingBackgroundColors !== undefined ?
                          configurationSettings.mappingBackgroundColors.linkEven.color :
                          '#fff'
                        }
                        onChangeComplete={this.handleSetMappingBackgroundColor({type: 'linkEven'})}
                        width="auto"
                      />
                    </div>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={this.resetMappingBackgroundColors} color="primary">
                      Reset Background Colors for mapping
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={configurationExpansionPanels.generatorDeclaration}
              onChange={this.handleToggleExpanded('generatorDeclaration')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Generator Declaration</Typography>
                <DialogContentText>
                  You can set the colors to display the different types of generator declarations.
                </DialogContentText>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.colorPickerGroopInDialog}>
                  <div className={classes.colorPickerInDialog}>
                    <Typography variant="subtitle1" gutterBottom>
                      Constant Type
                    </Typography>
                    <SketchPicker color={configurationSettings.patternTypeColors.constant.color}
                                  onChangeComplete={this.handleSetGeneratorDialogTypeColor({type: 'constant'})}
                                  width="auto"
                    />
                  </div>
                  <div className={classes.colorPickerInDialog}>
                    <Typography variant="subtitle1" gutterBottom>
                      XPath Type
                    </Typography>
                    <SketchPicker color={configurationSettings.patternTypeColors.xpath.color}
                                  onChangeComplete={this.handleSetGeneratorDialogTypeColor({type: 'xpath'})}
                                  width="auto"
                    />
                  </div>
                  <div className={classes.colorPickerInDialog}>
                    <Typography variant="subtitle1" gutterBottom>
                      Position Type
                    </Typography>
                    <SketchPicker color={configurationSettings.patternTypeColors.position.color}
                                  onChangeComplete={this.handleSetGeneratorDialogTypeColor({type: 'position'})}
                                  width="auto"
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={configurationExpansionPanels.generatorDefinition}
              onChange={this.handleToggleExpanded('generatorDefinition')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Generator Definition</Typography>
                <DialogContentText>
                  Always ask to confirm before making some generator definition available for all users.
                </DialogContentText>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        configurationSettings.confirmBeforeMakingGeneratorDefinitionPreloaded !== undefined ?
                        configurationSettings.confirmBeforeMakingGeneratorDefinitionPreloaded :
                        true
                      }
                      onChange={this.handleSetBooleanConfigSettingsValue({booleanFieldName: 'confirmBeforeMakingGeneratorDefinitionPreloaded'})}
                      value={
                        configurationSettings.confirmBeforeMakingGeneratorDefinitionPreloaded !== undefined ?
                        configurationSettings.confirmBeforeMakingGeneratorDefinitionPreloaded :
                        true
                      }
                      color="primary"
                    />
                  }
                  label="Always ask to confirm"
                />
              </AccordionDetails>
            </Accordion>
            {/*Comments*/}
            <Accordion
              expanded={configurationExpansionPanels.comments}
              onChange={this.handleToggleExpanded('comments')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Comments</Typography>
                <DialogContentText>
                  Display the first comment of the domain or link in the header of that mapping or link.
                </DialogContentText>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        configurationSettings.displayFirstCommentOnHeader !== undefined ?
                        configurationSettings.displayFirstCommentOnHeader :
                        true
                      }
                      onChange={this.handleSetBooleanConfigSettingsValue({booleanFieldName: 'displayFirstCommentOnHeader'})}
                      value={
                        configurationSettings.displayFirstCommentOnHeader !== undefined ?
                        configurationSettings.displayFirstCommentOnHeader :
                        true
                      }
                      color="primary"
                    />
                  }
                  label="Display the first comment in the header"
                />
              </AccordionDetails>
            </Accordion>












            {/* Compact View Mode*/}
            <Accordion
              expanded={configurationExpansionPanels.compactViewMode}
              onChange={this.handleToggleExpanded('compactViewMode')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Compact View Mode</Typography>
                <DialogContentText>
                  Select what to hide when in "Compact View" mode:
                </DialogContentText>
              </AccordionSummary>
              <AccordionDetails>
                {/* Hide or no the Variable */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideVariable !== undefined ?
                          configurationSettings.compactViewMode.hideVariable :
                          false
                        ) :
                        false
                      }
                      onChange={
                        this.handleSetBooleanConfigSettingsValue({
                          parentName: 'compactViewMode',
                          booleanFieldName: 'hideVariable'
                        })
                      }
                      value={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideVariable !== undefined ?
                          configurationSettings.compactViewMode.hideVariable :
                          false
                        ) :
                        false
                      }
                      color="primary"
                    />
                  }
                  label="Hide Variable"
                />
                {/* Hide or no the InstanceInfo */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideInstanceInfo !== undefined ?
                          configurationSettings.compactViewMode.hideInstanceInfo :
                          false
                        ) :
                        false
                      }
                      onChange={
                        this.handleSetBooleanConfigSettingsValue({
                          parentName: 'compactViewMode',
                          booleanFieldName: 'hideInstanceInfo'
                        })
                      }
                      value={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideInstanceInfo !== undefined ?
                          configurationSettings.compactViewMode.hideInstanceInfo :
                          false
                        ) :
                        false
                      }
                      color="primary"
                    />
                  }
                  label="Instance Info"
                />
                {/* Hide or no the Instance Generator */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideInstanceGenerator !== undefined ?
                          configurationSettings.compactViewMode.hideInstanceGenerator :
                          true
                        ) :
                        true
                      }
                      onChange={
                        this.handleSetBooleanConfigSettingsValue({
                          parentName: 'compactViewMode',
                          booleanFieldName: 'hideInstanceGenerator'
                        })
                      }
                      value={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideInstanceGenerator !== undefined ?
                          configurationSettings.compactViewMode.hideInstanceGenerator :
                          false
                        ) :
                        false
                      }
                      color="primary"
                    />
                  }
                  label="Instance Generator"
                />
                {/* Hide or no the Label Generator */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideLabelGenerator !== undefined ?
                          configurationSettings.compactViewMode.hideLabelGenerator :
                          true
                        ) :
                        true
                      }
                      onChange={
                        this.handleSetBooleanConfigSettingsValue({
                          parentName: 'compactViewMode',
                          booleanFieldName: 'hideLabelGenerator'
                        })
                      }
                      value={
                        configurationSettings.compactViewMode !== undefined ?
                        (
                          configurationSettings.compactViewMode.hideLabelGenerator !== undefined ?
                          configurationSettings.compactViewMode.hideLabelGenerator :
                          false
                        ) :
                        false
                      }
                      color="primary"
                    />
                  }
                  label="Label Generator"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={configurationExpansionPanels.passwordStrengthRegulation}
              onChange={this.handleToggleExpanded('passwordStrengthRegulation')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Password Strength Regulation</Typography>
                <DialogContentText>
                  Enable password strength regulations when updating your user password,
                  meaning that any new password set, should have at least 8 characters and
                  include at least one special character, one number, one upper case letter
                  and one low case letter.
                </DialogContentText>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        configurationSettings.enablePasswordStrengthRegulations !== undefined ?
                        configurationSettings.enablePasswordStrengthRegulations :
                        true
                      }
                      onChange={this.handleSetBooleanConfigSettingsValue({booleanFieldName: 'enablePasswordStrengthRegulations'})}
                      value={
                        configurationSettings.enablePasswordStrengthRegulations !== undefined ?
                        configurationSettings.enablePasswordStrengthRegulations :
                        true
                      }
                      color="primary"
                    />
                  }
                  label="Enable password strength regulations"
                />
              </AccordionDetails>
            </Accordion>


          </DialogContent>
          <DialogActions style={{display: 'unset', textAlign: 'right', paddingRight: 15}}>
            <Button onClick={this.saveUserConfigurationSettings} color="primary">
              Save
            </Button>
            <Button onClick={this.handleClosePojectMappingSettingsDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/*confirm to Close the configiguration settings Dialog*/}
        <ConfirmSnackbar
          open={this.state.confirmCloseConfSettingsSnackbarShown}
          onClose={this.handleCloseConfirmCloseConfSettingsSnackbar}
          type="warning"
          msg={'There are unsaved changes on user\'s configuration settings form. Are you sure you want to close this dialog?'}
          onConfirmClick={this.handleConfirmCloseConfSettingsDialog}
          confirmButtonLabel="Yes and discard any changes"
          cancelButtonLabel="Cancel"
          onCancelClick={this.handleCloseConfirmCloseConfSettingsSnackbar}
        />

      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuAppBar);
//export default {withStyles, withRouter} (MenuAppBar);

/*
const NavBar = () => {
    return(
        <div>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="title" color="inherit">
                React & Material-UI Sample Application Demo
                </Typography>
            </Toolbar>
        </AppBar>
        </div>
    )
}
export default NavBar;
*/
