import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '.././Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import {strengthColor, strengthIndicator} from './PasswordStrength';
import { CheckDbService } from '../services/CheckDbService';

const checkDbService = new CheckDbService();

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    background: 'none',
    padding: 0
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  // Password's Strength
  redTextFieldUnderline: {
    color: 'red' ,
    '&::after': {
      border: '2px solid red'
    }
  },
  redLabelColor: {
    color: 'red',
  },
  redTexfieldColor: {
    color: 'red',
  },
  // Yellow
  yellowTextFieldUnderline: {
    color: '#faad14' ,
    '&::after': {
      border: '2px solid #faad14'
    }
  },
  yellowLabelColor: {
    color: '#faad14',
  },
  yellowTexfieldColor: {
    color: '#faad14',
  },
  // Orange
  orangeTextFieldUnderline: {
    color: '#fa8414' ,
    '&::after': {
      border: '2px solid #fa8414'
    }
  },
  orangeLabelColor: {
    color: '#fa8414',
  },
  orangeTexfieldColor: {
    color: '#fa8414',
  },
  // Lightgreen
  lightgreenTextFieldUnderline: {
    color: '#03ca03' ,
    '&::after': {
      border: '2px solid #03ca03'
    }
  },
  lightgreenLabelColor: {
    color: '#03ca03',
  },
  lightgreenTexfieldColor: {
    color: '#03ca03',
  },
  // Green
  greenTextFieldUnderline: {
    color: '#019c01' ,
    '&::after': {
      border: '2px solid #019c01'
    }
  },
  greenLabelColor: {
    color: '#019c01',
  },
  greenTexfieldColor: {
    color: '#019c01',
  },
});

export class DefineNewAdministartorUser extends React.Component {

  state = {
    confirmPassword:'',
    user: {
      username: '',
      password: '',
      email:'',
      firstname: '',
      lastname: '',
      enabled: '',
      roles:[]
    },
    loading: false,
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleChange = name => event => {
    // Username
    if(name === 'username') {
      if (event.target.value === '') {
        this.setState({
            username_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            username_error_text: '',
        });
      }
    }

    // Password
    if(name === 'password') {
      if (event.target.value === '') {
        this.setState({
            password_error_text: 'This field is required',
        });
      }
      else {
        this.setState({
            password_error_text: '',
        });
      }
      if (event.target.value !== this.state.confirmPassword) {
        this.setState({
            confirm_password_error_text: 'The confirmed password doesn\'t much',
        });
      }
      else {
        this.setState({
            confirm_password_error_text: '',
        });
      }

      // Integer indicating strength
      const passwordStrengthValue = strengthIndicator(event.target.value);
      // Color indicating strength
      const passwordStrengthColor = strengthColor(passwordStrengthValue);
      //console.log('passwordStrengthValue: ' + passwordStrengthValue);
      //console.log('passwordStrengthColor: ' + passwordStrengthColor);
      this.setState({
        passwordStrengthColor: passwordStrengthColor,
        passwordStrengthValue: passwordStrengthValue,
      });
    }
    // email
    if(name === 'email') {
      var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (event.target.value === '') {
        this.setState({
            email_error_text: 'This field is required',
        });
      }
      else if (!emailRegEx.test(event.target.value)) {
        this.setState({
            email_error_text: 'This email has not a valid form',
        });
      }
      else {
        this.setState({
            email_error_text: '',
        });
      }
    }

    this.setState({
      user: {
        ...this.state.user,
        [name]: event.target.value,
      }
    });
  };

  handleChangeConfirm = name => event => {
    if (event.target.value !== this.state.user.password) {
      this.setState({
          confirm_password_error_text: 'The confirmed password doesn\'t much',
      });
    }
    else {
      this.setState({
          confirm_password_error_text: '',
      });
    }
    this.setState({
        [name]: event.target.value,
    });
  };

  handleSubmit = () => {
    this.setState({
        loading: true,
    }, async () => {
      if (this.formEl.checkValidity() === false) {
        // Username
        if (this.state.user.username === '') {
          this.setState({
              username_error_text: 'This field is required',
          });
        }
        else {
          this.setState({
              username_error_text: '',
          });
        }
        // Password
        if (this.state.user.password === '') {
          this.setState({
              password_error_text: 'This field is required',
          });
        }
        else {
          this.setState({
              password_error_text: '',
          });
        }

        // Confirm Password
        if (this.state.user.password !== this.state.confirmPassword) {
          this.setState({
              confirm_password_error_text: 'The confirmed password doesn\'t much',
          });
        }
        else {
          this.setState({
              confirm_password_error_text: '',
          });
        }

        // E-mail
        var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.user.email === '') {
          this.setState({
              email_error_text: 'This field is required',
          });
        }
        else if (!emailRegEx.test(this.state.user.email)) {
          this.setState({
              email_error_text: 'This email has not a valid form',
          });
        }
        else {
          this.setState({
              email_error_text: '',
          });
        }
      }

      // The form is valid
      else {
        // Check password strength
        if (strengthIndicator(this.state.user.password) > 3) {
          try {
            const res = await checkDbService.constructNewMinimalDb(this.state.user);
            if (res.data.succeed) {
              this.props.showSuccessSnackBar({msg: res.data.msg});
              // Close Dialog
              this.props.handleCloseDialog();
              this.setState({
                  loading: false,
              });
            }
            else {
              this.props.showErrorSnackBar({msg: res.data.msg});
            }

          } catch (e) {
            console.error('Databse Initialization Failure!');
            this.props.showErrorSnackBar({msg: 'Some error occured while initiating a new minimal database. Please contact with the administrator for resolving this issue.'});
            if(e.response !== undefined) {
              console.error(e.response.status);
            }
          }
        }
        else {
          this.props.showErrorSnackBar({
            msg: 'You should set some stronger password in order to proceed with this registration form. Please try using at least 8 characters and include numbers, special characters and both low and upper case letters.'});
        }

      }
    });
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, handleOnToggleFullScreen, handleCloseDialog, handleSave,
    } = this.props;

    console.log('DefineNewAdministartorUser Rendered');

    return (
      <Dialog open={dialogOpen}
              aria-labelledby="define-new-administartorUser-dialog"
              TransitionComponent={Fade}
              transitionDuration={{enter: 400, exit: 400}}
              maxWidth="xl"
              scroll={'paper'}
              fullScreen={fullScreen}
              PaperProps={{
                classes: {
                  root: fullScreen ? classes.dialogCustomPaper : ''
                }
              }}>
        <DialogTitle style={{paddingBottom: 0}}>
          Define New Administrator User
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={handleOnToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton
                className={classes.button}
                aria-label="close"
                onClick={handleCloseDialog}
                disabled={this.state.loading}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>
        <div>
          <LinearProgress hidden={!this.state.loading} style={{marginTop: -4, zIndex: 3,}}/>
        </div>
        {/*Like Backdrop*/}
        <div hidden={!this.state.loading}
             style={{zIndex: 2,
                     backgroundColor: 'rgba(0, 0, 0, 0.5)',
                     width: '100%',
                     height: 'calc(100% - 52px)',
                     position: 'absolute',
                     marginTop: 52}}
        />
        <DialogContent>
          <DialogContentText>
            Please fill in the administrator's form and submit. By doing so, a minimal empty database will be constructed from scratch.
          </DialogContentText>
      	  <Card>
        		<CardContent>
              <form
                ref={form => this.formEl = form}
                className={classes.container}
                autoComplete="off"
                noValidate
              >
                <TextField
                  label="First Name"
                  className={classes.textField}
                  value={this.state.user.firstname}
                  onChange={this.handleChange('firstname')}
                  margin="normal"
                />
                <TextField
                  label="Last Name"
                  className={classes.textField}
                  value={this.state.user.lastname}
                  onChange={this.handleChange('lastname')}
                  margin="normal"
                />
                <TextField
                  required
                  error={this.state.email_error_text !== '' && this.state.email_error_text !== undefined}
                  helperText={this.state.email_error_text}
                  label="E-mail"
                  className={classes.textField}
                  value={this.state.user.email}
                  onChange={this.handleChange('email')}
                  margin="normal"
                />
                <TextField
                  required
                  error={this.state.username_error_text !== '' && this.state.username_error_text !== undefined}
                  helperText={this.state.username_error_text}
                  label="User Name"
                  className={classes.textField}
                  value={this.state.user.username}
                  onChange={this.handleChange('username')}
                  margin="normal"
                />
                <TextField
                  required
                  error={this.state.password_error_text !== '' && this.state.password_error_text !== undefined}
                  helperText={
                    this.state.password_error_text !== '' ?
                    this.state.password_error_text :
                    (
                      this.state.passwordStrengthColor === 'red' ||
                      this.state.passwordStrengthColor === 'yellow' ||
                      this.state.passwordStrengthColor === 'orange' ?
                      <span className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                        The password set is weak. Please try better!
                      </span> :
                      (
                        this.state.passwordStrengthColor === 'lightgreen' ?
                        <span  className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                          The password set is OK. You can do better if you like though!
                        </span>:
                        (
                          this.state.passwordStrengthColor === 'green' ?
                          <span  className={classes[this.state.passwordStrengthColor + 'LabelColor']}>
                            The password set is very strong!
                            </span> :
                          ''
                        )
                      )
                    )
                  }
                  label="Password"
                  type="password"
                  className={classes.textField}
                  value={this.state.user.password}
                  onChange={this.handleChange('password')}
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes[this.state.passwordStrengthColor + 'LabelColor'],
                    }
                  }}
                  InputProps={{
                    classes: {
                      underline: classes[this.state.passwordStrengthColor + 'TextFieldUnderline'],
                      root: classes.redTexfieldColor
                    }
                  }}
                />
                <TextField
                  required
                  disabled={this.state.user.password === ''}
                  error={this.state.user.password !== '' && this.state.confirmPassword !== this.state.user.password}
                  label="Confirm Password"
                  type="password"
                  className={classes.textField}
                  value={this.state.confirmPassword}
                  onChange={this.handleChangeConfirm('confirmPassword')}
                  margin="normal"
                  helperText={this.state.confirm_password_error_text}
                />
              </form>
        		</CardContent>
      	  </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                  disabled={
                    (this.state.username_error_text !== '' && this.state.username_error_text !== undefined) ||
                    (this.state.password_error_text !== '' && this.state.password_error_text !== undefined) ||
                    (this.state.user.password !== '' && this.state.confirmPassword !== this.state.user.password) ||
                    (this.state.email_error_text !== '' && this.state.email_error_text !== undefined)}
          >
            Submit Form
          </Button>
          <div style={{paddingRight: 15}}>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

DefineNewAdministartorUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DefineNewAdministartorUser);
