import React, { Component, useContext  } from 'react';
import {
  Route,
  //NavLink,
  //BrowserRouter,
  Switch,
  Redirect,
  //HashRouter,
} from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Cookies from 'js-cookie';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import NavBar from './NavBar';
import ProjectTable from './ProjectTable';
import MappingTableView from './MappingTableView';
import UserProfile from './UserProfile';
import ManageUsers from './ManageUsers';
import ManagePredefinedFileMetadata from './ManagePredefinedFileMetadata';
import NotificationMessenger from './NotificationMessenger';
import Messages from './Messages';
import CustomSnackMessage from './snackbar/CustomSnackMessage';
import RichMessageDialog from './notificationMessenger/RichMessageDialog';
import { MainService } from './services/MainService'
import { MessageService } from './services/MessageService';
import { SecurityService } from './services/SecurityService';
import { UserProfileService } from './services/UserProfileService';
import configuration from './configuration.json';

//import 'antd/dist/antd.css';  //<<<<<================== NEEDED

const mainService = new MainService();
const messageService = new MessageService();
const securityService = new SecurityService();
const userProfileService = new UserProfileService();

// BUG FIX node_modules
// Note: Fixxing Edge or IE Issue with blank screen. The bug is only the development mode and has to do with the websocket used by webpack in order to recompile and refresh the page when some change happen in the code (not related with the websocket I use below). To fix it locate the file:
// 3MReact/node_modules/react/dev-utils/webpackHotDevClient.js
// open it and go to the line 66 (propably) and add after it (next line) the folowing code:
// slashes: true,
//
// So now it becomes becomes:
// var connection = new WebSocket(
//   url.format({
//     protocol: 'ws',
//     hostname: window.location.hostname,
//     port: window.location.port,
//     // Hardcoded in WebpackDevServer
//     pathname: '/sockjs-node',
//     slashes: true,
//   })
// );

const rootContextPath = configuration.rootContextPath;

// Web Socket Connection Related
const URL = rootContextPath + '/webSocket'
var ws = null;
var stompClient = null;

const styles = theme => ({
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
  },
  backdropMessageWrapperStyle: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 15,
  },
  backdropMessageStyle: {
    marginBottom: 10,
  },
  circularProgressWrapperStyle: {
    display: 'flex',
  },
  circularProgressStyle: {
    margin: 'auto',
  },
});

class RootAuthComp extends Component {

  state = {
    tryToConnectToWebSocket: true,
    isWebSocketConnected: false,
    message: '',
    serverMessages: [],
    unreadMessagesCount: 0,
    unreadMessages: [],
    stompClient: null,
    userConfigurationSettings: {
      configurationSettings: {
        mappingBackgroundColors: {
          domain: {color: '#fff'},
          linkOdd: {color: '#fff'},
          linkEven: {color: '#fff'},
        },
        patternTypeColors: {
          constant: {color: 'black'},
          xpath: {color: 'black'},
          position: {color: 'black'},
        },
        confirmBeforeMakingGeneratorDefinitionPreloaded: true,
        displayFirstCommentOnHeader: true,
        compactViewMode: {
          hideVariable: false,
          hideInstanceInfo: false,
          hideInstanceGenerator: true,
          hideLabelGenerator: true,
        },
        enablePasswordStrengthRegulations: true,
      },
    },
    user: '',
    // NotificationDialog
    notificationDialogOpen: false,
    notificationDialogFullScreen: false,
    notificationDialogMsg: '',
    rdfVisualizerUrl: '',
    showRootBackdrop: false,
    backdropMessage: '',
    actionHtml: '',
  };

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.seconds !== nextProps.seconds) {
      return false;
    }
    else {
      return false;
    }
  }

  async componentDidMount()  {
    // Comment this to disable WebSocket communication
    if(this.state.tryToConnectToWebSocket) {
      this.connectToWebSocket();
    }
    await this.handleRetrieveUserByUsernameCallBack(this.props.currUser.currUsername);
    await this.retrieveRdfVisualizerUrlCallBack(); // general configuration options i.e URL of the RDF Visualiser
    await this.retrieveUserConfigurationSettingsCallBack({username: this.props.currUser.currUsername});
    await this.retrieveUserUnreadMessagesCallBack();
    // Retrieve current user
  }

  /**
   * This function retrieves configuration properties from a JSON file on the server
   */
  retrieveRdfVisualizerUrlCallBack = async () => {
    try {
      let res = await mainService.retrieveRdfVisualizerUrl();
      if(res.data !== false) {
        this.setState({
            rdfVisualizerUrl: res.data.rdfVisualizerUrl,
        });
      }
    } catch (e) {
      console.error('Some error occured while retrieving the configuration properties.');
      this.showErrorSnackBar({msg: 'Some error occured while retrieving the configuration properties.', iconSize: 'large'});
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
      return null;
    }
  }

  // *****************************
  // Web Socket Connection Related
  // *****************************

  /**
   * @function connect
   * This function establishes the connect with the websocket and also
   * ensures constant reconnection if connection closes
   */
  connectToWebSocket = () => {
    if(this.state.tryToConnectToWebSocket) {
      ws = new SockJS(URL);
      //var options = {timeout: 3000,};
      //var protocols = ['xhr-polling', 'xdr-polling',  'xdr-streaming', 'xhr-streaming'];
      //ws = new SockJS(URL, protocols, options);
      //ws = new SockJS(URL, protocols);
      stompClient = Stomp.over(ws);
      //stompClient.connect( {}, this.connectCallBack, this.errorCallback);
      stompClient.connect( {'Authorization': Cookies.get('3mToken')}, this.connectCallBack, this.errorCallback);
    }
  }

  connectCallBack = frame => {
    //console.log('Connected: ' + frame);
    console.log('WebSocket Connected');
    this.setState({
      isWebSocketConnected: true
    });
    stompClient.subscribe('/topic/messages', props =>
        this.handleReceiveWebSocketMessage(JSON.parse(props.body))
    );

    stompClient.subscribe('/user/topic/messagesToSpecificUsers', props =>
        this.handleReceiveWebSocketMessage(JSON.parse(props.body))
    );

    stompClient.subscribe('/user/topic/jsonPackageToSpecificUsers', props =>
        this.handleReceiveJsonPackage(JSON.parse(props.body))
    );
    stompClient.subscribe('/user/topic/jsonPackage', props =>
        this.handleReceiveJsonPackage(JSON.parse(props.body))
    );
  }

  errorCallback = async err => {
    console.log('WebSocket Error:', err);
      try {
        const res = await userProfileService.isUserAuthenticated();
        if(res.data.authenticated === false) {
          // Disconnecting Websocket
          stompClient.disconnect();
        }
        else {
          this.reConnectWebSocket();
        }
      } catch (e) {
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
  }

  reConnectWebSocket = () => {
    // Marking flag disconnected
    this.setState({
      isWebSocketConnected: false
    }, function() {
      // Re-connecting Websocket
      const retryTimeout = 2;
      console.log('Re-connecting websocket after', retryTimeout, 'secs...')
      // Call the websocket connect method
      setTimeout( () => {
        this.connectToWebSocket();
      }, retryTimeout * 1000);
    });
  }

  // *****************************
  // Web Socket handling messages
  // *****************************

  handleReceiveWebSocketMessage = props => {
    // Handling received message
    if(props.message !== undefined) {
      this.setState({
        notificationDialogMsg: props.message,
        unreadMessagesCount: props.unreadMessagesCount,
      }, () => {
        if(props.message.snackBarType) {
          this.showCustomSnackBar({
            msg: props.message.content,
            hasHtmlContent: true,
            type: props.message.alertType,
            onClickOfDissmissButton: this.handleDismissClick,
            fullMessage: props.message,
          });
        }
        else {
          this.openNotificationDialog();
        }
      });
    }
    // Handling feedback to the sender
    else if(props.succeed !== undefined) {
      this.setState({
        webSocketServerFeedback: props,
      });
    }
  }

  handleReceiveJsonPackage = props => {
    if(props.jsonPackage !== undefined && props.jsonPackage !== undefined) {
      // User status related
      if(props.jsonPackage.type === 'onlineUserStatus') {
        this.setState({
          userStatusJsonPackage: props.jsonPackage,
        });
      }
      this.setState({
        jsonPackage: props.jsonPackage,
      });
    }
  }

  // Custom handler for clicking the dismiss button on the custom snackbar (instead of using the one set as default)
  // props: {message: <Object>, snackBarId: <String>}
  handleDismissClick = props => async () => {
    // Marking the message as read
    this.markMessageAsReadCallBack({message: props.message});
    // Retrieve unread messages and update the badge and unread message list
    await this.retrieveUserUnreadMessagesCallBack();
    // Close the snackbar
    this.props.closeSnackbar(props.id);
  }

  // *****************************
  // Web Socket Related - Ends
  // *****************************

  markMessageAsReadCallBack = async props => {
    // Marking the message as read
    if(props.message.id !== undefined) {
      try {
        const response = await messageService.markMessageAsReadByIdForCurrentUser({id: props.message.id});
        if(response.data !== false) {

        }
        else {
        }
      } catch (e) {
        console.error('Some error occured while marking the received message as read');
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
  }

  retrieveUserConfigurationSettingsCallBack = async props => {
    // Retrieve current MappingProject
    try {
      const response = await mainService.retrieveUserConfigurationSettingsByUserName({username: props.username});
      if(response.data.succeed !== false) {
        // Initalize if necessary
        if(response.data.userConfigurationSettings.configurationSettings.mappingBackgroundColors === undefined) {
          const mappingBackgroundColors = Object.assign({}, this.state.userConfigurationSettings.configurationSettings.mappingBackgroundColors); // Copy of the object
          response.data.userConfigurationSettings.configurationSettings.mappingBackgroundColors = mappingBackgroundColors;
        }
        if(response.data.userConfigurationSettings.configurationSettings.displayFirstCommentOnHeader === undefined) {
          response.data.userConfigurationSettings.configurationSettings.displayFirstCommentOnHeader = true;
        }
        this.setState({
          userConfigurationSettings: response.data.userConfigurationSettings,
        });
      }
      else {
        this.showCustomSnackBar({msg: response.data.msg, type: 'error'});
      }
    } catch (e) {
      console.error('Some error occured while retrieving user\'s configuration settings');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  retrieveUserUnreadMessagesCallBack = async () => {
    try {
      const response = await messageService.retrieveUnreadMessages();
      if(response.data !== false) {
        this.setState({
          unreadMessages: response.data,
          unreadMessagesCount: response.data.length,
        });
      }
      else {
        this.showCustomSnackBar({msg: 'There was some error while retrieving the unread messsages', type: 'error'});
      }
    } catch (e) {
      console.error('Some error occured while retrieving the unread messages');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // Saving them alltogether
  saveUserConfigurationSettingsCallBack = async () => {
    // Persist to the database
    try {
      const response = await mainService.saveUserConfigurationSettingsByUserName(this.state.userConfigurationSettings);
      if(response.data.succeed) {
        this.showSuccessSnackBar({msg: response.data.msg});
      }
      else {
        this.showSuccessSnackBar({msg: response.data.msg});
      }
    } catch (e) {
      console.error('Some error occured while saving user\'s configuration settings');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // props: {colorHex: <String>, type: 'type: 'constant, xpath, position'}
  //configurationSettings: {
  //  mappingBackgroundColors: {
  //    domain: {color: '#fff'},
  //    linkOdd: {color: '#fff'},
  //    linkEven: {color: '#fff'},
  //  }
  //}
  handleSetMappingBackgroundColorCallBack = props => {
    this.setState({
      userConfigurationSettings: {
        ...this.state.userConfigurationSettings,
        configurationSettings: {
          ...this.state.userConfigurationSettings.configurationSettings,
          mappingBackgroundColors: {
            ...this.state.userConfigurationSettings.configurationSettings.mappingBackgroundColors,
            [props.type]: {
            ...this.state.userConfigurationSettings.configurationSettings.mappingBackgroundColors[props.type],
              color: props.colorHex,
            }
          }
        }
      }
    });
  }

  resetMappingBackgroundColorsCallBack = async () => {
    try {
      const response = await mainService.retrieveDefaultUserConfigurationSettings();
      if(response.data !== false) {
        // Initalize if necessary
        const mappingBackgroundColors = Object.assign({}, response.data.userConfigurationSettings.configurationSettings.mappingBackgroundColors); // Copy of the object
        this.setState({
          userConfigurationSettings: {
            ...this.state.userConfigurationSettings,
            configurationSettings: {
              ...this.state.userConfigurationSettings.configurationSettings,
              mappingBackgroundColors: mappingBackgroundColors,
            }
          }
        });
      }
      else {
        this.showCustomSnackBar({msg: response.data.msg, type: 'error'});
      }
    } catch (e) {
      console.error('Some error occured while retrieving default user configuration settings');
      if(e.response !== undefined) {
        console.error(e.response.status);
      }
    }
  }

  // props: {colorHex: <String>, type: 'type: 'constant, xpath, position'}
  //configurationSettings: {
  //  patternTypeColors: {
  //    constant: {color: '#5cd440'},
  //    xpath: {color: '#faad14'},
  //    position: {color: '#d318ff'},
  //  }
  //}
  handleSetGeneratorDeclarationTypeColorCallBack = props => {
    this.setState({
      userConfigurationSettings: {
        ...this.state.userConfigurationSettings,
        configurationSettings: {
          ...this.state.userConfigurationSettings.configurationSettings,
          patternTypeColors: {
            ...this.state.userConfigurationSettings.configurationSettings.patternTypeColors,
            [props.type]: {
            ...this.state.userConfigurationSettings.configurationSettings.patternTypeColors[props.type],
              color: props.colorHex,
            }
          }
        }
      }
    });
  }

  // props {parentName*: <Text>, booleanFieldName: <Text>, value: <Boolean>}
  // Optional parameter. When undefined, the booleanFieldName is at the root
  // Example with parentName
  // props: {parentName: 'compactViewMode', booleanFieldName: 'hideVariable', value: false}
  // Example without parentName
  // props: {booleanFieldName: 'confirmBeforeMakingGeneratorDefinitionPreloaded', value: true}
  handleSetBooleanConfigSettingsValueCallBack = props => {
    if(props.parentName !== undefined) {
      this.setState({
        userConfigurationSettings: {
          ...this.state.userConfigurationSettings,
          configurationSettings: {
            ...this.state.userConfigurationSettings.configurationSettings,
            [props.parentName]: {
              ...this.state.userConfigurationSettings.configurationSettings[props.parentName],
              [props.booleanFieldName]: props.value,
            }
          }
        }
      });
    }
    else {
      this.setState({
        userConfigurationSettings: {
          ...this.state.userConfigurationSettings,
          configurationSettings: {
            ...this.state.userConfigurationSettings.configurationSettings,
            [props.booleanFieldName]: props.value,
          }
        }
      });
    }
  }

  /*
  handleSetBooleanConfigSettingsValue = props => event => {
    this.setState({
      userConfigurationSettings: {
        ...this.state.userConfigurationSettings,
        configurationSettings: {
          ...this.state.userConfigurationSettings.configurationSettings,
          [props.booleanFieldName]: event.target.checked,
        }
      }
    });
  }
  */

  handleRetrieveUserByUsernameCallBack = async username => {
    const user = await userProfileService.retrieveUserByUsername({username: this.props.currUser.currUsername});
    this.setState({
        user: user.data,
    });
  }

  // ##################################
  // NotificationDialog Related - Start
  // ##################################

  openNotificationDialog = () => {
    this.setState({
        notificationDialogOpen: true,
    });
  }

  closeNotificationDialog = () => {
    this.setState({
        notificationDialogOpen: false,
    }, async function() {
      await this.markMessageAsReadCallBack({message: this.state.notificationDialogMsg});
      await this.retrieveUserUnreadMessagesCallBack();
    });
  }

  handleNotificationDialogToggleFullScreen = () => {
    this.setState({
      notificationDialogFullScreen: !this.state.notificationDialogFullScreen
    });
  }

  // #################################
  // NotificationDialog Related - Stop
  // #################################

  // About Dialog
  openAboutDialog = async () => {
    const res = await mainService.getAboutInfo();
    const richMessage = {
      title: 'About 3M',
      content: res.data,
      snackBarType: false,
      recipients: [],
      readByUsers: [],
    }
    this.setState({
      notificationDialogMsg: richMessage,
      notificationDialogOpen: true,
    });
  }

  showSuccessSnackBar = props => {
    this.props.enqueueSnackbar(
      props.msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500
      }
    );
  }

  // props: {msg: <Text>, iconSize: 'large | small (default: 'small')'}
  showErrorSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'error'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
                              hasHtmlContent={props.hasHtmlContent !== undefined ? props.hasHtmlContent : false }/>
        ),
      }
    );
  }

  showWarningSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'warning'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
                              hasHtmlContent={props.hasHtmlContent !== undefined ? props.hasHtmlContent : false }/>
        ),
      }
    );
  }

  showInfoSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'info'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
                              hasHtmlContent={props.hasHtmlContent !== undefined ? props.hasHtmlContent : false }/>
        ),
      }
    );
  }

  // props: {
  //    msg: <Text>,
  //    iconSize: 'large | small (default: 'small')',
  //    type: 'error, success, info, warning', hasHtmlContent: <Boolean>,
  //    onClickOfDissmissButton*: <Function>,
  //    fullMessage**: <Object>
  // }
  // * Optional function to be used when clicking the dismiss button
  // ** Optional parameter for the full message, if needed
  showCustomSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={props.type}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
                              hasHtmlContent={props.hasHtmlContent}
                              onClickOfDissmissButton={props.onClickOfDissmissButton}
                              fullMessage={props.fullMessage}/>
        ),
      }
    );
  }

  // props: {booleanValue: <Boolen>, message*: <Text>}
  // *: Optional
  toggleRootBackDdrop = props => () => {
    this.toggleRootBackDdropCallBack(props)
  }
  // props: {booleanValue: <Boolen>, message*: <Text>}
  // *: Optional
  toggleRootBackDdropCallBack = (props) => {
    this.setState({
        showRootBackdrop: props.booleanValue,
        backdropMessage: props.message !== undefined ? props.message : '',
        actionHtml:  props.actionHtml !== undefined ? props.actionHtml : '',
    });
  }

  render() {

    // Transitions and animations
    // const transitions = useTransition(location, location => location.pathname, {
    //   initial: { transform: 'translate3d(0, 0%,0)'},
    //   from: { transform: 'translate3d(0, 80%,0)'},
    //   enter: { transform: 'translate3d(0, 0%,0)'},
    //   leave: { transform: 'translate3d(0, -90%,0)'}
    // });

    //let userConfigurationSettings = this.state.userConfigurationSettings;
    const { classes, currUser, checkIfAuthed, updateAuthStatusOfCurrUser, } = this.props;
    if(!this.state.userConfigurationSettings) {
      return null;
    }/*
    else if(!this.state.unreadMessages) {
      return null;
    }
    else if(!this.state.unreadMessagesCount) {
      return null;
    }*/

    else {
      let configurationSettings = this.state.userConfigurationSettings.configurationSettings;
      //handleSetGeneratorPatternTypeColorsCallBack={this.handleSetGeneratorPatternTypeColorsCallBack}
      return (
        <div style={{padding:10}}>
          <NavBar
            onChange={checkIfAuthed}
            currUser={currUser}
            configurationSettings={configurationSettings}
            retrieveUserConfigurationSettingsCallBack={this.retrieveUserConfigurationSettingsCallBack}
            handleSetMappingBackgroundColorCallBack={this.handleSetMappingBackgroundColorCallBack}
            resetMappingBackgroundColorsCallBack={this.resetMappingBackgroundColorsCallBack}
            handleSetGeneratorDeclarationTypeColorCallBack={this.handleSetGeneratorDeclarationTypeColorCallBack}
            handleSetBooleanConfigSettingsValueCallBack={this.handleSetBooleanConfigSettingsValueCallBack}
            saveUserConfigurationSettingsCallBack={this.saveUserConfigurationSettingsCallBack}
            handleRetrieveUserByUsernameCallBack={this.handleRetrieveUserByUsernameCallBack}
            user={this.state.user}
            isWebSocketConnected={this.state.isWebSocketConnected}
            unreadMessagesCount={this.state.unreadMessagesCount}
            stompClient={stompClient}
            openAboutDialog={this.openAboutDialog}
            handleLogout={this.props.handleLogout}
          />
          <div className="content">
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
            >
              <Route exact={true} path={[(rootContextPath + '/'), (rootContextPath)]}>
                <Redirect to={rootContextPath + '/Projects'} />
              </Route>
              <Route exact={true} path={[(rootContextPath + '/Projects'), (rootContextPath)]}>
                <ProjectTable
                  currUser={currUser}
                  user={this.state.user}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  history={this.props.history}
                  showSuccessSnackBar={this.showSuccessSnackBar}
                  showErrorSnackBar={this.showErrorSnackBar}
                  showWarningSnackBar={this.showWarningSnackBar}
                />
              </Route>
              <Route path={rootContextPath + '/ProjectsForUser'}>
                <ProjectTable
                  currUser={currUser}
                  user={this.state.user}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  history={this.props.history}
                  showSuccessSnackBar={this.showSuccessSnackBar}
                  showErrorSnackBar={this.showErrorSnackBar}
                  showWarningSnackBar={this.showWarningSnackBar}
                />
              </Route>
              {/* <Route path={rootContextPath + '/MappingTableView'}> */}
              {/* <Route path={rootContextPath + '/MappingTableView/:mappingTableHrid?'}> */}
              <Route path={rootContextPath + '/MappingTableView/:mappingTableHrid?/:mappingOrLinkId?'}>
                <MappingTableView
                  currUser={currUser}
                  user={this.state.user}
                  configurationSettings={configurationSettings}
                  rdfVisualizerUrl={this.state.rdfVisualizerUrl}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  history={this.props.history}
                  stompClient={stompClient}
                  connected={this.state.isWebSocketConnected}
                  jsonPackage={this.state.jsonPackage}
                  showCustomSnackBar={this.showCustomSnackBar}
                  toggleRootBackDdropCallBack={this.toggleRootBackDdropCallBack}
                  showWarningSnackBar={this.showWarningSnackBar}
                  showErrorSnackBar={this.showErrorSnackBar}
                  showInfoSnackBar={this.showInfoSnackBar}
                />
              </Route>
              <Route path={rootContextPath + '/UserProfile'}>
                <UserProfile
                  currUser={currUser}
                  handleRetrieveUserByUsernameCallBack={this.handleRetrieveUserByUsernameCallBack}
                  configurationSettings={configurationSettings}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  history={this.props.history}
                />
              </Route>
              <Route path={rootContextPath + '/ManageUsers'}>
                <ManageUsers
                  currUser={currUser}
                  user={this.state.user}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  connected={this.state.isWebSocketConnected}
                  jsonPackage={this.state.userStatusJsonPackage}
                  history={this.props.history}
                  showSuccessSnackBar={this.showSuccessSnackBar}
                  showErrorSnackBar={this.showErrorSnackBar}
                  showWarningSnackBar={this.showWarningSnackBar}
                />
              </Route>
              <Route path={rootContextPath + '/ManagePredefinedFileMetadata'}>
                <ManagePredefinedFileMetadata
                  currUser={currUser}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  connected={this.state.isWebSocketConnected}
                  jsonPackage={this.state.userStatusJsonPackage}
                  history={this.props.history}
                  showSuccessSnackBar={this.showSuccessSnackBar}
                  showErrorSnackBar={this.showErrorSnackBar}
                  showWarningSnackBar={this.showWarningSnackBar}
                />
              </Route>
              <Route path={rootContextPath + '/NotificationMessenger'}>
                <NotificationMessenger
                  currUser={currUser}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  stompClient={stompClient}
                  connected={this.state.isWebSocketConnected}
                  newReceivedMsg={this.state.notificationDialogMsg}
                  webSocketServerFeedback={this.state.webSocketServerFeedback}
                  unreadMessages={this.state.unreadMessages}
                  markMessageAsRead={this.markMessageAsReadCallBack}
                  retrieveUserUnreadMessagesCallBack={this.retrieveUserUnreadMessagesCallBack}
                />
              </Route>
              <Route path={rootContextPath + '/Messages'}>
                <Messages
                  currUser={currUser}
                  updateAuthStatusOfCurrUser={updateAuthStatusOfCurrUser}
                  stompClient={stompClient}
                  connected={this.state.isWebSocketConnected}
                  newReceivedMsg={this.state.notificationDialogMsg}
                  webSocketServerFeedback={this.state.webSocketServerFeedback}
                  unreadMessages={this.state.unreadMessages}
                  markMessageAsRead={this.markMessageAsReadCallBack}
                  retrieveUserUnreadMessagesCallBack={this.retrieveUserUnreadMessagesCallBack}
                  jsonPackage={this.state.jsonPackage}
                  showCustomSnackBar={this.showCustomSnackBar}
                />
              </Route>
            </AnimatedSwitch>
          </div>

          {/* NotificationDialog */}
          <RichMessageDialog
            dialogMsg = {this.state.notificationDialogMsg}
            dialogOpen={this.state.notificationDialogOpen}
            fullScreen={this.state.notificationDialogFullScreen}
            onToggleFullScreen={this.handleNotificationDialogToggleFullScreen}
            handleCloseDialog={this.closeNotificationDialog}
          />

          <Backdrop className={classes.backdropStyle} open={this.state.showRootBackdrop}>
            <div
              hidden={
                this.state.backdropMessage !== undefined ?
                this.state.backdropMessage === '' :
                true
              }
              className={classes.backdropMessageWrapperStyle}
            >
              <Typography className={classes.backdropMessageStyle}>
                {this.state.backdropMessage}
              </Typography>
              <div className={classes.circularProgressWrapperStyle}>
                <CircularProgress color="primary" className={classes.circularProgressStyle}/>
              </div>
              {
                this.state.actionHtml !== undefined ?
                <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                  {this.state.actionHtml}
                </div> :
                ''
              }
            </div>
            {/*
            <div
              hidden={
                this.state.backdropMessage !== undefined ?
                this.state.backdropMessage !== '' :
                false
              }
            >
              <CircularProgress color="primary"/>
            </div>
            */}
          </Backdrop>
          {/*open={this.state.showRootBackdrop}*/}
        </div>
      );
    } // else
  }
}

//export default withRouter(RootAuthComp)
export default compose(withStyles(styles), withRouter, withSnackbar)(RootAuthComp);
