import axios from 'axios';
import configuration from '../configuration.json';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();

const rootContextPath = configuration.rootContextPath;

export class XpathService {

  constructor() {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Asynchronously retrieve the token using `this.getToken()`
        const token = await this.getToken();
  
        // Add the token to the headers
        if (token) {
          config.headers['Authorization'] = token;
          //config.headers['Authorization'] = `Bearer ${token}`;
        }
  
        return config;
      },
      error => {
        if (error.response.status === 401) {
          this.redirectTo(document, rootContextPath + '/');
        }
        return Promise.reject(error);
      }
    );
  }
  
  async getToken() {
    console.log('getToken');
    try {
      const token = Cookies.get('3mToken'); // Assuming you store the token in a cookie
      return token || null; // Return the token or null if it's not found
    } catch (error) {
      console.error('Error while retrieving token:', error);
      return null;
    }
  }

  /*
  List xPaths starting from specific root
  */
  async getXpaths(data) {
     return await axiosInstance(rootContextPath + '/getXPaths', {
      method: 'post',
      data: data,
    });
  }

}
