import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  generatorDeclarationErrorMsg: {
    display: 'flex',
    //verticalAlign: 'middle',
    paddingRight: 10,
    minWidth: 130,
    whiteSpace: 'normal',
    alignItems: 'center',
    //marginTop: -10,
    marginTop: 10, //
  },
  curlyBracket: {
    display: 'table-cell',
    //verticalAlign: 'middle',
    paddingRight: 10,
    fontSize: 50,
    //marginTop: -16,
    paddingLeft: 5,
    margin: 'auto', //
  },
});

export class GeneratorDeclarationError extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.generatorDeclarationErrorStyle !== nextProps.generatorDeclarationErrorStyle) {
      return true;
    }
    if(this.props.errorMessage !== nextProps.errorMessage) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {
    const {
      classes, generatorDeclarationErrorStyle, errorMessage,
    } = this.props;

    console.log('GeneratorDeclarationError - Rendered');
    return (
      <div style={{display: 'flex'}}>
        <div hidden={generatorDeclarationErrorStyle === ''}
             className={classes.curlyBracket}>
             {'{'}
        </div>
        <div hidden={generatorDeclarationErrorStyle === ''}
             className={classes.generatorDeclarationErrorMsg}>
          {errorMessage}
        </div>
      </div>
    );
  }
}

GeneratorDeclarationError.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneratorDeclarationError);
