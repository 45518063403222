import React from 'react';
import classNames from 'classnames';
//import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '.././Autocomplete';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Snackbar from '@material-ui/core/Snackbar';
import amber from '@material-ui/core/colors/amber';
import { lighten } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
    //minHeight: '80vh',
    //maxHeight: '80vh',
  },
  dialogCustomPaper80PercentHeight: {
    display: 'flex',
    overflow: 'hidden',
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  dialogContentTextStyle: {
    margin: '8px 8px 0 8px',
  },
  multiCardWrapper: {
    display: 'flex',
    //overflowY: 'auto',
  },
  card: {
    margin: 10,
    width: '50%'
  },
  singleCard: {
    margin: 10,
    width: '100%',
    minHeight: 300
  },
  formRoot: {
    padding: 0,
    background: 'none',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  argFormBase: {
    minWidth: 265,
  },
  toolBarRoot: {
    margin: -16
  },
  highlight: theme.palette.type === 'light' ?
    {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    } : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
  actions: {
    color: theme.palette.text.secondary,
    //marginRight: 60,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  snackbarWarning: {
    backgroundColor: amber[700],
    width: 460,
  },
  snackbarIconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  largeSnackbarIcon: {
    fontSize: 42,
    marginBottom:-14
  },
  snackbarMessageContainer: {
    display: 'table'
  },
  snackbarMessageText: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
});

const EvenSwitch = withStyles({
  switchBase: {
    color: '#2196f3',
    '&$checked': {
      color: '#2196f3',
    },
    '&$checked + $track': {
      backgroundColor: '#000',
    },
    '&$disabled': {
      color: '#bdbdbd',
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

let EditToolbar = props => {
  const {
    conditionDeleteMode, handleDeleteSelected, numSelected, currConditionListUseOr, conditionsLength,
    handleConditionListAndOrInputChange, classes
  } = props;

  return (
    <Toolbar
      className={classNames(classes.toolBarRoot,{
        [classes.highlight]: conditionDeleteMode,
      })}
    >
      <div>
        {conditionDeleteMode ? (
          <Typography color="inherit" variant="body1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography color="textPrimary">
            List of Conditions
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {conditionDeleteMode ?
          (
            <Tooltip title="Delete Selected">
              <div>
                <IconButton aria-label="delete-condition(s)"
                            onClick={handleDeleteSelected}
                            disabled={numSelected === 0}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) :
          <Tooltip title="&quot;And&quot; or &quot;Or&quot; logical operator to be applied between the conditions of list"
                   placement="left">
            <div style={{display: 'flex'}}>

              <FormControlLabel
                control={
                  <EvenSwitch checked={currConditionListUseOr}
                    value={currConditionListUseOr}
                    onChange={handleConditionListAndOrInputChange}
                    disabled={conditionsLength < 2}
                  />
                }
                label="And"
                labelPlacement="start"
              />
              <Typography style={{paddingLeft: 5, marginTop: 7}}
                          variant="body1">
                Or
              </Typography>
            </div>
          </Tooltip>
        }
      </div>
    </Toolbar>
  );
};

EditToolbar = withStyles(styles)(EditToolbar);

export class ConditionDialog extends React.Component {

  state = {
    conditionDeleteMode: false,
    conditionsSelected: [],
    snackbarShown: false
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.tabIndex !== nextProps.tabIndex) {
      return true;
    }
    if(this.props.currCondition !== nextProps.currCondition) {
      return true;
    }
    if(this.props.conditions !== nextProps.conditions) {
      return true;
    }
    if(this.props.currConditionListUseOr !== nextProps.currConditionListUseOr) {
      return true;
    }
    if(this.props.saveNow !== nextProps.saveNow) {
      return true;
    }
    if(this.props.shakeNow !== nextProps.shakeNow) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  handleDeleteConditionModeChange = event => {
    if(event === undefined) {
      this.setState({
        conditionDeleteMode: false,
        conditionsSelected: [],
      });
    }
    else {
      this.setState({
        conditionDeleteMode: event.target.checked,
        conditionsSelected: [],
      });
    }
  };

  isConditionSelected = id =>
    this.state.conditionsSelected.findIndex(
      obj => obj.id === id
    ) !== -1;

  handleConditionCheckBoxChange = condition => event => {
    let array = this.state.conditionsSelected.slice(); // Create a copy
    let index = this.state.conditionsSelected.findIndex(
      obj => obj.id === condition.id
    );
    // Select Action
    if(event.target.checked) {
      if(index === -1) {
        array.push(condition);
        this.setState({
          conditionsSelected: array
        });
      }
    }
    // De-select Action
    else {
      // Remove the de-selected item
      if(index !== -1) {
        array.splice(index, 1);
        this.setState({
          conditionsSelected: array
        });
      }
    }
  };

  handleDeleteSelected = () => {
    this.handleSnackbarOpen();
  }

  handleSnackbarOpen = () => {
    this.setState({ snackbarShown: true });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarShown: false });
  }

  handleConfirmDeleteSelectedConditions = async () => {
    // Callback to delete the selected conditions
    this.props.handleDeleteConditions({conditionsToBeDeleted: this.state.conditionsSelected});
    this.setState({
      snackbarShown: false,
      conditionDeleteMode: false,
      conditionsSelected: [],
    });
  }

  handleCancelDeleteSelectedConditions = () => {
    this.setState({ snackbarShown: false });
  }


  // props: {condition: Object}
  getConditionForm = (props) => {

    const { classes, handleConditionInputChange } = this.props;

    let condition = Object.assign({}, props.condition); // Copy

    if(condition === undefined || condition === '') {
      condition = {
        type: '',
        value: '',
        xpath: '',
        error_type_text: '',
        error_value_text: '',
        error_xpath_text: '',
      }
    }
    const conditionTypeOptions = [
      {label: "Exists", value:'exists'},
      {label: "Equals", value:'equals'},
      {label: "Exact Match", value:'exact_match'},
      {label: "Broader", value:'broader'}
    ];

    //let valueIsRequired = condition.type === 'equals' || condition.type === 'exact_match' || condition.type === 'broader';
    let valueIsRequired = condition.type === 'exact_match' || condition.type === 'broader';
    let valueIsDisabled = condition.type === 'equals' ? false : !valueIsRequired;

    return (
      <form ref={form => this.conditionForm = form}
            autoComplete="off"
            noValidate
            className={classes.formRoot}>
        <div style={{display: 'flex', flexDirection: 'column'}}>

          <FormControl className={classes.formControl}
                       error={condition.error_type_text !== '' && condition.error_type_text !== undefined}>
            <Autocomplete label={'Select Type'}
                          placeholder={'Please select type'}
                          options={conditionTypeOptions}
                          value={
                            condition.type !== '' && condition.type !== undefined ?
                            {
                              label: condition.type.charAt(0).toUpperCase() + condition.type.slice(1),
                              value: condition.type
                            } :
                            ''
                          }
                          error={condition.error_type_text !== '' && condition.error_type_text !== undefined}
                          errorText={condition.error_type_text}
                          isMulti={false}
                          async={false}
                          onChange={handleConditionInputChange({fieldName: 'type'})}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField required={valueIsRequired}
                       disabled={valueIsDisabled}
                       error={valueIsRequired ? (condition.error_value_text !== '' && condition.error_value_text !== undefined) : false}
                       helperText={valueIsRequired ? condition.error_value_text : ''}
                       autoComplete="off"
                       label="Value"
                       placeholder="Please enter some value"
                       margin="dense"
                       value={condition.value}
                       onChange={handleConditionInputChange({fieldName: 'value'})}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField required
                       error={condition.error_xpath_text !== '' && condition.error_xpath_text !== undefined}
                       helperText={condition.error_xpath_text}
                       autoComplete="off"
                       label="XPath"
                       placeholder="Please enter some xPath"
                       margin="dense"
                       value={condition.xpath}
                       onChange={handleConditionInputChange({fieldName: 'xpath'})}
            />
          </FormControl>
        </div>
      </form>
    );
  }

  // Label Generator Related
  showFormToAddNewCondition = () => {
    // Resetting current condition
    this.props.resetCondition();
    // Showing the form with all fields filled in according to the selected condition
    this.props.showConditionForm();
  }

  // props: {condition: <Object>, index: <integer>}
  selectCondition = props => () => {
    // Single select action
    if(!this.state.conditionDeleteMode) {
      // Setting current condition
      this.props.selectCondition({condition: props.condition, index: props.index});
      // Showing the form with all fields been reset
      this.props.showConditionForm();
    }
  }

  // props: {condition: <Object>}
  saveCondition = props => () => {
    this.saveConditionCallBack(props);
  }

  saveConditionCallBack = async props => {
    let condition = Object.assign({}, props.condition); // Copy
    // Flag denoting whether to proceed with submitting based on the form's validation
    let proceed = this.props.handleValidateConditionForm({condition});
    if(proceed) {
      // Save Declaration
      await this.props.handleSaveCondition({
        condition: condition,
      });
      // Go Back to the previous page (list of conditions)
      this.props.handleChangeConditionTabIndex(0);
    }
    else {
      this.props.showErrorSnackBar({
        msg: 'The "Condition Form" is not valid. Please make sure that there are not any fields with errors',
        iconSize: 'large'
      });
    }
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, onToggleFullScreen,
      conditions, currCondition,
      handleChangeConditionTabIndex, onLeave, onLeaveCallBack, tabIndex,
      currConditionListUseOr, handleConditionListAndOrInputChange, handleConditionInputChange,
      saveNow, shakeNow
    } = this.props;

    console.log('GeneratorDialog Rendered');
    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="condition-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="md"
        scroll={'paper'}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : classes.dialogCustomPaper80PercentHeight
          }
        }}
        onKeyUp={(e) => {
          const ENTER = 13;
          if (e.keyCode === ENTER) {
            if(this.props.tabIndex === 0) {
              if (!this.state.conditionDeleteMode) {
                onLeaveCallBack('close');
              }
            }
            else { //tabIndex === 1}
              this.saveConditionCallBack({condition: currCondition});
            }
          }
        }}
      >
        <DialogTitle style={{paddingBottom: 0}}>
          Condition Management
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onLeave('close')}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>

          <SwipeableViews index={tabIndex}
                          onChangeIndex={handleChangeConditionTabIndex}
                          animateHeight={true}>
                          {/*containerStyle={{height: 'unset'}}*/}

            {/* #################### Condition List ####################*/}
            <div>
              <DialogContentText className={classes.dialogContentTextStyle}>
                Please select some condition to edit or press the "Plus" button to add a new one. Don't forget to save before closing this dialog.
              </DialogContentText>
              <div className={classes.multiCardWrapper}>
                <Card className={classes.singleCard}>
                  <CardContent>
                    <EditToolbar conditionDeleteMode={this.state.conditionDeleteMode}
                                 handleDeleteSelected={this.handleDeleteSelected}
                                 conditionsSelected={this.state.conditionsSelected}
                                 numSelected={this.state.conditionsSelected.length}
                                 currConditionListUseOr={currConditionListUseOr}
                                 conditionsLength={conditions.length}
                                 handleConditionListAndOrInputChange={handleConditionListAndOrInputChange}
                    />
                    <List>
                      {conditions.map((condition, index) => {
                        const isSelected = this.isConditionSelected(condition.id);
                        return (
                          <div key={condition.id}>

                            <ListItem role={undefined} dense button={!this.state.conditionDeleteMode}
                                      onClick={this.selectCondition({condition: condition, index: index})}>
                              <ListItemIcon>
                                <Zoom in={this.state.conditionDeleteMode}>
                                  <Checkbox checked={isSelected}
                                           hidden={!this.state.conditionDeleteMode}
                                           onChange={this.handleConditionCheckBoxChange(condition)}/>
                                </Zoom>
                              </ListItemIcon>
                              <ListItemText primary={
                                              <React.Fragment>
                                                <span>
                                                  {condition.negation ? '¬' : ''}
                                                </span>
                                                <span>{condition.type}</span>
                                              </React.Fragment>
                                            }
                                            secondary={
                                              <React.Fragment>
                                                <span hidden={condition.value === undefined || condition.value === ''}
                                                      style={{paddingRight: 5}}>
                                                  Value: {condition.value},
                                                </span>
                                                <span>xPath: {condition.xpath}</span>
                                              </React.Fragment>
                                            }
                              />
                            </ListItem>
                            <Divider />
                          </div>
                        )}
                      )}
                    </List>
                    <Fade in={!this.state.conditionDeleteMode}>
                      <div style={{zIndex: 1, paddingRight: 54}} align="right">
                        <Tooltip title="Add new condition" placement="left-end">
                          <Fab color="primary"
                               aria-label="add-new-condition"
                               onClick={this.showFormToAddNewCondition}
                               className={classes.fab}
                               style={{position: 'absolute', bottom: 24}}>
                            <AddIcon />
                          </Fab>
                        </Tooltip>
                      </div>
                    </Fade>
                    <Fade in={this.state.conditionDeleteMode}>
                      <div style={{zIndex: 1, paddingRight: 54}} align="right">
                        <Tooltip title="Delete Selected" placement="left-end">
                          <span>
                            <Fab color="secondary"
                                 disabled={this.state.conditionsSelected.length === 0}
                                 aria-label="delete-condition(s)"
                                 onClick={this.handleDeleteSelected}
                                 className={classes.fab}
                                 style={{position: 'absolute', bottom: 24}}>
                              <DeleteIcon />
                            </Fab>
                          </span>
                        </Tooltip>
                      </div>
                    </Fade>
                  </CardContent>
                </Card>
              </div>
            </div>

            {/* #################### Condition - Add Or Edit Form ####################*/}
            <div>
              <DialogContentText className={classes.dialogContentTextStyle}>
                Please fill in the form. Don't forget to save before closing this dialog.
              </DialogContentText>
              <div className={classes.multiCardWrapper}>
            	  <Card  className={classes.singleCard}>
              		<CardContent>
                    <div style={{display: 'flex'}}>
                      <div>
                        <Typography color="textPrimary" style={{paddingTop: 12}}>
                          Condition
                        </Typography>
                      </div>

                      <div className={classes.spacer} />
                      <div className={classes.actions}>
                        <Tooltip title="Use negation on this condition"
                                 placement="left">
                          <div style={{display: 'flex'}}>

                            <FormControlLabel
                              control={
                                <Checkbox checked={currCondition.negation}
                                  value={currCondition.negation}
                                  onChange={handleConditionInputChange({fieldName: 'negation'})}
                                />
                              }
                              label="Negation"
                              labelPlacement="start"
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

              		  {this.getConditionForm({condition: currCondition})}
              		</CardContent>
            	  </Card>
              </div>
            </div>

          </SwipeableViews>

        </DialogContent>
        <DialogActions>
          <div style={{display: 'flex', flexGrow: 1}}>
            <Zoom in={tabIndex === 1}>
              <Tooltip title="Go Back to List of Conditions"
                       placement="right"
                       enterDelay={500}
                       leaveDelay={200}>
                <Fab color="secondary"
                     size="small"
                     aria-label="go-back-to-label"
                     onClick={onLeave('goBack')}>
                  <ArrowBackIcon />
                </Fab>
              </Tooltip>
            </Zoom>
            <Zoom in={tabIndex === 0}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.conditionDeleteMode}
                    onChange={this.handleDeleteConditionModeChange}
                    value={this.state.conditionDeleteMode}
                    color="secondary"
                    disabled={conditions.length < 1}
                  />
                }
                label="Delete Mode"
                classes={{label: classes.boldLabel}}
              />
            </Zoom>
          </div>
          <div style={{paddingRight: 15}}>
            <Zoom in={tabIndex === 1}>
              <Button hidden={tabIndex !== 1}
                      disabled={currCondition === undefined || currCondition === ''}
                      onClick={this.saveCondition({condition: currCondition})}
                      color={!saveNow ? 'primary' : 'secondary'}>
                <span className={shakeNow ? 'rumble' : ''}>Save Condition</span>
              </Button>
            </Zoom>
            <Button onClick={onLeave('close')} color="primary">
              Close
            </Button>
          </div>
        </DialogActions>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.snackbarShown}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
              root: classes.snackbarWarning,
            }
          }}
          message={
            <div className={classes.snackbarMessageContainer}>
              <div className={classes.snackbarMessageText}>
                <WarningIcon className={classNames(classes.largeSnackbarIcon, classes.snackbarIconVariant)} />
              </div>
              <div className={classes.snackbarMessageText}>
                {
                  this.state.conditionsSelected.length > 1 ?
                  'Are you sure you want to delete these ' + this.state.conditionsSelected.length + ' conditions' :
                  'Are you sure you want to delete this condition?'
                }
              </div>
            </div>}
          action={[
            <Button key="confirmDeleteSelectedConditions" color="secondary" size="small" onClick={this.handleConfirmDeleteSelectedConditions}>
              <b>Yes Delete {this.state.conditionsSelected.length > 1 ?'Them' : 'It'
              }</b>
            </Button>,
            <Button key="cancelDeleteSelectedConditions" color="secondary" size="small" onClick={this.handleCancelDeleteSelectedConditions}>
              <b>Cancel</b>
            </Button>,
          ]}
        />

      </Dialog>
    )
  }
}

ConditionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConditionDialog);
