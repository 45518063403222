import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    background: 'none',
    padding: 0
  },
  textField: {

  },
});

export class UserInfo extends React.Component {


  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.user !== nextProps.user) {
      return true;
    }
    if(this.props.username_error_text !== nextProps.username_error_text) {
      return true;
    }
    if(this.props.email_error_text !== nextProps.email_error_text) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  render() {
    const {
      classes, user, email_error_text, //username_error_text,
      handleChange,
    } = this.props;

    console.log('UserInfo Rendered');
    return (
      <form ref={form => this.formEl = form}
            className={classes.container}
            autoComplete="off"
            noValidate>
        {/*
        <TextField
          required
          error={username_error_text !== '' && username_error_text !== undefined}
          helperText={username_error_text}
          label="User Name"
          className={classes.textField}
          value={user.username}
          onChange={handleChange('username')}
          margin="normal"
        />
        */}
        <div style={{display: 'flex', paddingTop: 16}}>
          <FormLabel style={{paddingRight: 5}}>Username: </FormLabel>
          <FormLabel>{user.username}</FormLabel>
        </div>
        <TextField
          label="First Name"
          className={classes.textField}
          value={user.firstname}
          onChange={handleChange('firstname')}
          margin="normal"
        />
        <TextField
          label="Last Name"
          className={classes.textField}
          value={user.lastname}
          onChange={handleChange('lastname')}
          margin="normal"
        />
        <TextField
          required
          error={email_error_text !== '' && email_error_text !== undefined}
          helperText={email_error_text}
          label="E-mail"
          className={classes.textField}
          value={user.email}
          onChange={handleChange('email')}
          margin="normal"
        />
      </form>
    )
  }
}

export default withStyles(styles)(UserInfo);
