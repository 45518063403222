import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import FileAndMetadataUploader from '../projectTableComponents/FileAndMetadataUploader';
import Autocomplete from '.././Autocomplete';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';

const styles = theme => ({
  rootCard: {
    padding: 3,
    margin: 10
  },
  closeIconWrapper: {
    position: 'relative',
    //zIndex: 1,
  },
  closeIcon: {
    position: 'absolute',
    padding: 'unset',
    marginTop:-30,
    marginRight:5,
    right: 0,
    top: 0,
  },
  itemToDeleteHighlighted: {
    outline: '3px dashed #f50057',
    padding: 7,
    overflow: 'hidden',
  },
  itemToDelete: {
    padding: 7,
  },
  fileMetadataTitle: {
    backgroundColor: '#f1f0ef',
    margin: -10,
    marginBottom: -15,
    padding: '5px 5px 5px 20px'
  },
  fileMetadataTitleContent: {
    //padding: 8,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
    //marginBottom: 'auto',
    //marginTop: 2,
  },
  shrinkedLinkButton: {
    fontSize: 14,
    height: 21,
    textTransform:'unset',
    whiteSpace: 'nowrap',
    padding: '0px 2px',
    margin: '0px -2px',
    lineHeight: 'unset',
    justifyContent: 'unset',
    minWidth: 'unset'
  },
  expandedError: {
    border: 'dotted',
    borderRadius: 10,
    borderColor: 'red',
    //color: 'red',
    textAlign: 'justify',
    padding: '10px !important',
  },
  errorIconButtonHover: {
    '&:hover': {
      backgroundColor: '#f7eaeb'
    }
  },
});

//export class ProjectFileMetadataManagement extends React.PureComponent {
export class ProjectFileMetadataManagement extends React.Component  {

  state = {
    enablePredefinedSelection: false,
    fileMetadataIsHovered: false,
    fileMetadataDeleteIsHovered: false,
    hoveredNamespaceIndex: -1,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state !== nextState) {
      return true;
    }
    if(this.props.fileMetadata !== nextProps.fileMetadata) {
      return true;
    }
    if(this.props.predefinedFileMetadataList !== nextProps.predefinedFileMetadataList) {
      return true;
    }

    else {
      return false;
    }
  }

  componentDidMount() {
    // Makking sure that no UI for selecting predefined will be available
    // if the list of predefined is empty
    if(this.props.predefinedFileMetadataList.length === 0) {
      this.setState({enablePredefinedSelection: false});
    }
  }

  // props: {status:'mouseEnter | mouseLeave}
  handleHoverDelete = props => () => {
    var hovered = false;
    if(props.status === 'mouseEnter') {
      hovered = true;
    }
    else {
      hovered = false;
    }
    this.setState({fileMetadataDeleteIsHovered: hovered});
  }

  handleChangeSwitchPredefined = event => {
    this.setState({
      enablePredefinedSelection: event.target.checked
    }, function() {
    });
  }

  render() {

    const {
      classes, handleFileMetadataInputChange, handleDeleteFileMetadata, addNamespaceInFileMetadata, handleRemoveFileMetadataNamespace,
      handleMarkFileMetadataFieldAsInvalid, handleMarkFileMetadataFieldAsValid, storeFileMetadata, fileMetadata,
      index, type, predefinedFileMetadataList, handlePredefinedInputChange, toggleExpandFileMetadataNamespaceErrorMessage,
      handleChangeFileParticipation, 
    } = this.props;
    //const { entityOptions, relationOptions, entityIsLoading, relationIsLoading } = this.state;

    let mockfiles = []; // Initial Loaded files set
    if(fileMetadata.filename !== undefined) {
      let mockFile = {
        lastModifiedDate: fileMetadata.uploadDate,
        name: fileMetadata.filename,
        size: fileMetadata.fileSize,
        type: fileMetadata.fileType,
      }
      mockfiles.push(mockFile);
      fileMetadata.files = mockfiles;
    }

    let objectContained = false;
    let indexInPredefinedList = predefinedFileMetadataList.findIndex(obj => obj.title === fileMetadata.title);
    if(indexInPredefinedList !== -1) {
      objectContained = true;
    }

    console.log('ProjectFileMetadataManagement render');

    return (
      <Card 
        key={fileMetadata.id + '_' + type + '_' + index} 
        className={classes.rootCard}
        onMouseEnter={() => this.setState({fileMetadataIsHovered: true})}
        onMouseLeave={() => this.setState({fileMetadataIsHovered: false})}
      >
        <div
          className={this.state.fileMetadataDeleteIsHovered ? classes.itemToDeleteHighlighted : classes.itemToDelete}
          // onMouseEnter={() => this.setState({fileMetadataDeleteIsHovered: true})}
          // onMouseLeave={() => this.setState({fileMetadataDeleteIsHovered: false})}
        >
          <DialogTitle disableTypography={true} component={'span'} className={classes.fileMetadataTitle}>
            {/* File Participation (only applicable for source files) */}
            <div style={{display: 'flex'}}>
              {
                handleChangeFileParticipation ?
                <div>
                  <Tooltip
                    title={'Uncheck this box if you want this file to be ignored when producing the final transformation'}
                    placement="top-start"
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Checkbox
                      size="small"
                      checked={
                        fileMetadata.participating !== undefined ?
                        fileMetadata.participating :
                        true
                      }
                      onClick={handleChangeFileParticipation({type: type, fileMetadata: fileMetadata})}
                      style={{padding: 4}}
                    />
                  </Tooltip>
                </div> :
                ''
              }
              <h3 className={classes.fileMetadataTitleContent}>File #{index+1}</h3>
            </div>
            <div className={classes.closeIconWrapper}>
              <IconButton
                className={classes.closeIcon}
                aria-label="Delete"
                onClick={handleDeleteFileMetadata({type: type, fileMetadata: fileMetadata})}
                onMouseEnter={this.handleHoverDelete({status:'mouseEnter'})}
                onMouseLeave={this.handleHoverDelete({status:'mouseLeave'})}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Grid container spacing={1} direction="column" style={{paddingTop: 20}} hidden={predefinedFileMetadataList.length === 0}>
            <div style={{display: 'flex'}}>
              <Fade in={this.state.enablePredefinedSelection}>
                <div style={{width: '100%'}}>
                  <Autocomplete
                    label={'Preloaded Target Schema'}
                    placeholder={'Please select target schema'}
                    options={
                      predefinedFileMetadataList.map(tmpFileMetadata => ({
                        value: tmpFileMetadata.id,
                        label: tmpFileMetadata.title + (tmpFileMetadata.version !== undefined ? (' (' + tmpFileMetadata.version + ')') : ''),
                      }))
                    }
                    value={
                      objectContained ?
                      {value: fileMetadata.id, label: fileMetadata.title} :
                      ''
                    }
                    onChange={handlePredefinedInputChange({type: type, fileMetadata: fileMetadata})}
                    error={false}
                    isClearable={false}
                    errorText={''}
                    isMulti={false}
                    async={false}
                  />
                </div>
              </Fade>
              <div style={{marginTop: 12, display: 'flex'}}>
                <DialogContentText style={{width: 120, margin: 7}}>Use Predefined: </DialogContentText>
                <Switch
                  checked={this.state.enablePredefinedSelection}
                  onChange={this.handleChangeSwitchPredefined}
                  color="primary"
                  disabled={fileMetadata.files !== undefined}
                />
              </div>
            </div>
          </Grid>
          <Grid container spacing={1} direction="column">
            <FileAndMetadataUploader
              currUser={this.props.currUser}
              updateAuthStatusOfCurrUser={this.props.updateAuthStatusOfCurrUser}
              fileMetadata={fileMetadata}
              fileScope={type === 'target' ? 'targetSchemaFile' : type + 'File'}
              type={type}
              index={index}
              showFileCounter={false}
              inactive={fileMetadata.filename === undefined}
              handleFileMetadataInputChange={this.props.handleFileMetadataInputChange}
              handleSetFileMetadataInput={this.props.handleSetFileMetadataInput}
              handleDeleteFileMetadata={this.props.handleDeleteFileMetadata}
              handleMarkFieldAsInvalid={handleMarkFileMetadataFieldAsInvalid}
              handleMarkFieldAsValid={handleMarkFileMetadataFieldAsValid}
              storeFileMetadata={storeFileMetadata}
              handleHoldRefRecord={this.props.handleHoldFileMetadataRefRecord}
              trackRefs={true}
            />
              {/*inactive={this.state.enablePredefinedSelection}*/}
          </Grid>
          <Collapse in={fileMetadata.namespacePrefixList !== null}>
            <DialogContentText
              hidden={
                fileMetadata.namespacePrefixList !== null ?
                fileMetadata.namespacePrefixList.length === 0 :
                true
              }
              style={{paddingTop: 10}}
            >
              NameSpaces
            </DialogContentText>
          </Collapse>
          <Collapse in={fileMetadata.namespacePrefixList !== null}>
            <div>
              {
                fileMetadata.namespacePrefixList !== null ?
                fileMetadata.namespacePrefixList.map((namespace, nameSpaceIndex) => (
                  <div key={fileMetadata.id + '_namespace_' + nameSpaceIndex}>
                    <Grid container spacing={1}
                      onMouseEnter={() => this.setState({hoveredNamespaceIndex: nameSpaceIndex})}
                      onMouseLeave={() => this.setState({hoveredNamespaceIndex: -1})}
                    >
                      <div style={{display: 'flex', width: '100%', paddingLeft: 20}}>
                        <TextField
                          label="Prefix"
                          value={namespace.label}
                          onChange={handleFileMetadataInputChange({fieldName: 'label', listType: type, index: index, nameSpaceIndex})}
                          margin="normal"
                          style={{width: 80, paddingRight: 10}}
                          error={namespace.error_label_text !== '' && namespace.error_label_text !== undefined}
                        />
                        <TextField
                          label="URI"
                          value={namespace.value}
                          onChange={handleFileMetadataInputChange({fieldName: 'value', listType: type, index: index, nameSpaceIndex})}
                          margin="normal"
                          style={{flexGrow: 1, paddingRight: 10}}
                          error={namespace.error_label_text !== '' && namespace.error_label_text !== undefined}
                        />
                        {/*This throws some waring in red !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DIV BELOW*/}
                        <div style={{margin: 'auto', marginLeft: 0, padding: 10}}>
                          <Zoom in={nameSpaceIndex === this.state.hoveredNamespaceIndex}>
                            <Tooltip
                              title={'Delete Namespace'}
                              placement="top-end"
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <IconButton
                                aria-label="delete-namespace-icon-button"
                                size="small"
                                onClick={handleRemoveFileMetadataNamespace({listType: type, index: index, nameSpaceIndex: nameSpaceIndex})}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </Zoom>
                        </div>
                      </div>
                    </Grid>
                    <Grid hidden= {namespace.error_label_text === undefined} style={{color: 'red'}}>
                      <div style={{display: 'flex'}}>
                        <div>
                          {namespace.error_label_text !== undefined ?
                           (
                             namespace.error_label_text.shortDescr !== undefined ?
                             namespace.error_label_text.shortDescr :
                             ''
                           ) :
                           ''}
                        </div>
                        <div style={{paddingLeft: 5}}>
                          <Tooltip
                            title={'...more about this error'}
                            placement="top"
                            enterDelay={500}
                            leaveDelay={200}
                          >
                            <IconButton
                              aria-label="Expand-More-Error-Message"
                              style={{padding:'unset', float: 'right', color: 'red'}}
                              onClick={
                                toggleExpandFileMetadataNamespaceErrorMessage({
                                  type: type,
                                  fileMetadataArrayIndex: index,
                                  namespacePrefixListIndex: nameSpaceIndex
                                })
                              }
                              className={classes.errorIconButtonHover}
                            >
                              <ExpandMoreIcon  />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                    <Grid>
                      <Collapse
                        in={
                          namespace.error_label_text !== undefined ?
                          (
                            namespace.error_label_text.expanded !== undefined ?
                            namespace.error_label_text.expanded :
                            false
                          ) :
                          false
                        }
                        timeout="auto"
                        unmountOnExit
                        style={{paddingTop: 5}}
                      >
                        <CardContent className={classes.expandedError}>
                        {namespace.error_label_text !== undefined ?
                         (
                           namespace.error_label_text.longDescr !== undefined ?
                           namespace.error_label_text.longDescr :
                           'Error decription is missing.'
                         ) :
                         ''}
                        </CardContent>
                      </Collapse>
                    </Grid>
                  </div>
                )) :
                ''
              }

              <div style={{zIndex: 9, padding: 5}} align="right">
                <Zoom in={this.state.fileMetadataIsHovered}>
                  <Tooltip
                    title="Add new namespace"
                    placement="bottom"
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Fab
                      color="primary"
                      aria-label="handle-add-namespace"
                      size="small"
                      onClick={addNamespaceInFileMetadata({listType: type, index: index})}
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Zoom>
              </div>
            </div>
          </Collapse>
        </div>
      </Card>
    );
  }
}

ProjectFileMetadataManagement.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectFileMetadataManagement);
