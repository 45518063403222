import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Pagination from '@material-ui/lab/Pagination';
import green from '@material-ui/core/colors/green';
import configuration from '../configuration.json';

const avatarRootContextPath = configuration.avatarRootContextPath; // This is different than the regular rootContextPath (/3m)

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
  },
  listItemIconShorterSpace: {
    minWidth: 40,
  },
  fullCard: {
    width: '100%',
  },
  onlineCell: {
    //color: theme.palette.primary.main,
    color: green['A700'],
  },
  offlineCell: {
    color: theme.palette.secondary.main,
  },
  searchRoot: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
  },
  searchPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
});




export class ParticipatingUsersDialog extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.author !== nextProps.author) {
      return true;
    }
    if(this.props.users !== nextProps.users) {
      return true;
    }
    if(this.props.onlineParticipantingUsers !== nextProps.onlineParticipantingUsers) {
      return true;
    }
    if(this.props.participatingUsers !== nextProps.participatingUsers) {
      return true;
    }
    if(this.props.currUser !== nextProps.currUser) {
      return true;
    }

    if(this.props.page !== nextProps.page) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  state = {
    page: 0,
    searchText: '',
    rowsPerPage: 10,
  }

  // props: {fieldName: <Text>}
  handleChangeInput = props => event => {
    this.setState({
      [props.fieldName]: event.target.value,
    });
  }

  handleResetSearchByText = () => {
    this.setState({
        searchText: '',
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page: page-1 });
  };

  render() {
    const {
      classes, dialogOpen, fullScreen, onToggleFullScreen, dialogClose, users, participatingUsers, onlineParticipantingUsers, author,
      handleSelectToParticipate,
    } = this.props;

    console.log('ParticipatingUsersDialog Rendered');

    const userList =
      users
      .filter(
        user =>
          user.firstname.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
          user.lastname.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
          (user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase()).includes(this.state.searchText.toLowerCase()) ||
          (user.lastname.toLowerCase() + ' ' + user.firstname.toLowerCase()).includes(this.state.searchText.toLowerCase()) ||
          user.username.toLowerCase().includes(this.state.searchText.toLowerCase())
      )
      .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
      .map((user, userIndex) => {
      return (
        <div key={'user' + userIndex}>
          <ListItem dense>
            <ListItemIcon className={classes.listItemIconShorterSpace}>
              <Checkbox  edge="start"
                         checked={
                           author.id === user.id ?
                           true :
                           (participatingUsers.findIndex(obj => obj.id === user.id) !== -1 || user.id === author.id)
                         }
                         disabled={user.id === author.id}
                         onClick={handleSelectToParticipate(user)}
              />
            </ListItemIcon>
            <ListItemAvatar>
              <Avatar
                alt="user-avatar"
                src={user.avatarImageRelativePath !== undefined ? avatarRootContextPath + user.avatarImageRelativePath : ''}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <span
                  style={{
                    display: 'inline-block',
                    width: fullScreen ? 'unset' : 300,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {user.firstname + ' ' + user.lastname}
                </span>
              }
              secondary={
                <span style={{display: 'flex', width: fullScreen ? 'unset' : 300}}>
                  <span
                    style={{
                      display: 'inline-block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {user.username}
                  </span>
                  <span style={{paddingLeft: 5}}>{(user.id === author.id ? '(Author)' : '')}</span>
                </span>
              }
            />
            <ListItemIcon className={classes.listItemIconShorterSpace}>
              <Icon
                hidden={onlineParticipantingUsers.findIndex(obj => obj.id === user.id) === -1 || user.id === author.id}
                className={classNames(classes.fontAwesomeIcon, 'fas fa-plug', classes.onlineCell)}
                style={{fontSize: 24}}
              />
            </ListItemIcon>

          </ListItem>
          <Divider hidden={userIndex === users.length-1}/>
        </div>
      );
    })

    return (
      <Dialog
        open={dialogOpen}
        aria-labelledby="generator-dialog"
        TransitionComponent={Fade}
        transitionDuration={{enter: 400, exit: 400}}
        maxWidth="xl"
        scroll={'paper'}
        fullScreen={fullScreen}
        PaperProps={{
          classes: {
            root: fullScreen ? classes.dialogCustomPaper : ''
          }
        }}

      >
        <DialogTitle style={{paddingBottom: 0}}>
          Particpating to this project users
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={dialogClose}>
                          {/*padding: 'unset'*/}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Select any user to allow edditing this Mapping Project
          </DialogContentText>
          <div className={classes.searchRoot}>
            <Paper component="form" className={classes.searchPaper}>
              <InputBase
                className={classes.searchInput}
                placeholder={'Search by name'}
                value={this.state.searchText}
                inputProps={{ 'aria-label': 'search' }}
                onChange={this.handleChangeInput({fieldName: 'searchText'})}
              />
              <IconButton
                size="small"
                aria-label="reset-serach-text"
                onClick={this.handleResetSearchByText}
              >
                <CloseIcon />
              </IconButton>
            </Paper>
          </div>
          <div style={{display: 'flex', height: fullScreen ? 'inherit' : 'calc(100vh - 408px)'}}>
            <Card className={classes.fullCard} style={{overflowY: 'auto'}}>
              <CardContent>
                <div>
                  {userList}
                </div>
              </CardContent>
            </Card>
          </div>
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}}>
        <div style={{paddingBottom: 8}}>
          <Pagination
            count={
              Math.ceil(
                users
                .filter(
                  user =>
                  user.firstname.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
                  user.lastname.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
                  (user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase()).includes(this.state.searchText.toLowerCase()) ||
                  (user.lastname.toLowerCase() + ' ' + user.firstname.toLowerCase()).includes(this.state.searchText.toLowerCase()) ||
                  user.username.toLowerCase().includes(this.state.searchText.toLowerCase())
                )
                .length / this.state.rowsPerPage
              )
            }
            variant="outlined"
            onChange={this.handleChangePage}
          />
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

ParticipatingUsersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ParticipatingUsersDialog);
