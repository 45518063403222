import React from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    zIndex: 10,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class Autocomplete extends React.Component {
  state = {
    autoCompleteValue: null,
  };
  /*
  constructor(props) {
    super(props);
    //this.handleChange=this.handleChange.bind(this);
  }
  */
  render() {
    const { classes, theme } = this.props;


    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
        backgroundColor:'#ffggh'
      }),
    };

    const { hasError } = this.state;

    // Using React-Select
    // https://react-select.com/home

    // Case: Normal Select
    if(!this.props.async && !this.props.creatable) {
      return (
        <div className={classes.root}>
          <NoSsr>
            {/*<CreatableSelect*/}
            <Select
              classes={classes}
              styles={{ menuPortal: styles => ({ ...styles, zIndex: 9999 }) }}
              textFieldProps={{
                error: this.props.error,
                label: this.props.label,
                InputLabelProps: {
                  shrink: true,
                },
                disabled: this.props.disabled !==undefined ? this.props.disabled : false,
              }}
              options={this.props.options}
              components={components}
              inputProps={{error: hasError}}
              menuPortalTarget={document.body}
              value={this.props.value}
              onChange={this.props.onChange}
              onInputChange={this.props.onInputChange}
              placeholder={this.props.placeholder}
              isClearable={this.props.isClearable !== undefined ? this.props.isClearable : true}
              isMulti={this.props.isMulti}
              onFocus={this.props.onFocus}
              isLoading={this.props.isLoading}
              isDisabled={this.props.disabled !==undefined ? this.props.disabled : false}
              onBlur={this.props.onBlur}
              noOptionsMessage={this.props.noOptionsMessage}
            />
            {/*native={true}*/}
            {this.props.error && <FormHelperText>{this.props.errorText}</FormHelperText>}
          </NoSsr>
        </div>
      );
    }

    else if(!this.props.async && this.props.creatable) {
      return (
        <div className={classes.root}>
          <NoSsr>
            {/*<CreatableSelect*/}
            {/*Consider adding some flag to make this autocompelete CreatableSelect and thus been eable to enter your own text*/}
            <CreatableSelect
              classes={classes}
              styles={{ menuPortal: styles => ({ ...styles, zIndex: 9999 }) }}
              textFieldProps={{
                error: this.props.error,
                label: this.props.label,
                InputLabelProps: {
                  shrink: true,
                },
                disabled: this.props.disabled !==undefined ? this.props.disabled : false,
              }}
              options={this.props.options}
              components={components}
              inputProps={{error: hasError}}
              menuPortalTarget={document.body}
              value={this.props.value}
              onChange={this.props.onChange}
              onInputChange={this.props.onInputChange}
              placeholder={this.props.placeholder}
              isClearable={this.props.isClearable !== undefined ? this.props.isClearable : true}
              isMulti={this.props.isMulti}
              onFocus={this.props.onFocus}
              isLoading={this.props.isLoading}
              isDisabled={this.props.disabled !==undefined ? this.props.disabled : false}
              onBlur={this.props.onBlur}
            />
            {/*onCreateOption={this.props.onCreateOption}*/}
            {/*native={true}*/}
            {this.props.error && <FormHelperText>{this.props.errorText}</FormHelperText>}
          </NoSsr>
        </div>
      );
    }

    // Case: Retrieve Async
    else {
      return (
        <div className={classes.root}>
          <NoSsr>
            <AsyncSelect
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                error: this.props.error,
                label: this.props.label,
                InputLabelProps: {
                  shrink: true,
                },
                disabled: this.props.disabled !==undefined ? this.props.disabled : false,
              }}
              loadOptions={this.props.loadOptions}
              cacheOptions
              defaultOptions
              components={components}
              inputProps={{error: hasError}}
              value={this.props.value}
              onChange={this.props.onChange}
              onInputChange={this.props.onInputChange}
              placeholder={this.props.placeholder}
              isClearable={this.props.isClearable !== undefined ? this.props.isClearable : true}
              isMulti={this.props.isMulti}
              onFocus={this.props.onFocus}
              isDisabled={this.props.disabled !==undefined ? this.props.disabled : false}
              onBlur={this.props.onBlur}
            />
            {/*native={true}*/}
            {this.props.error && <FormHelperText>{this.props.errorText}</FormHelperText>}
          </NoSsr>
        </div>
      );
    }
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
