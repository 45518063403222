import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import CustomSnackMessage from '.././snackbar/CustomSnackMessage';
import { SecurityService } from '../services/SecurityService';
import logo from '../../images/logo-color-128-w-text.png';
import {strengthColor, strengthIndicator} from './PasswordStrength';
import configuration from '../configuration.json';

const rootContextPath = configuration.rootContextPath;

let forgotPasswordRequestId = undefined;
if(getUrlParameter('requestId', document.URL)) {
  console.log(document.URL);
  forgotPasswordRequestId = getUrlParameter('requestId', document.URL);
}
// Getting query parameter from the URL
function getUrlParameter (name, url) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(url);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    background: 'none',
    padding: 0
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  fab: {
    margin: theme.spacing(1),
  },
  // /red
  redTextFieldUnderline: {
    color: 'red' ,
    '&::after': {
      border: '2px solid red'
    }
  },
  redLabelColor: {
    color: 'red',
  },
  redTexfieldColor: {
    color: 'red',
  },
  // Yellow
  yellowTextFieldUnderline: {
    color: '#faad14' ,
    '&::after': {
      border: '2px solid #faad14'
    }
  },
  yellowLabelColor: {
    color: '#faad14',
  },
  yellowTexfieldColor: {
    color: '#faad14',
  },
  // Orange
  orangeTextFieldUnderline: {
    color: '#fa8414' ,
    '&::after': {
      border: '2px solid #fa8414'
    }
  },
  orangeLabelColor: {
    color: '#fa8414',
  },
  orangeTexfieldColor: {
    color: '#fa8414',
  },
  // Lightgreen
  lightgreenTextFieldUnderline: {
    color: '#03ca03' ,
    '&::after': {
      border: '2px solid #03ca03'
    }
  },
  lightgreenLabelColor: {
    color: '#03ca03',
  },
  lightgreenTexfieldColor: {
    color: '#03ca03',
  },
  // Green
  greenTextFieldUnderline: {
    color: '#019c01' ,
    '&::after': {
      border: '2px solid #019c01'
    }
  },
  greenLabelColor: {
    color: '#019c01',
  },
  greenTexfieldColor: {
    color: '#019c01',
  },
});

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
const securityService = new SecurityService();

class NewCredentialsGenerator extends React.Component {
  state = {
    imageLoaded: false,
    username: '',
    changePasswordFeedbackMessage: '',
    //changePasswordSucceed: false,
    requestSent: false,
  };

  // Only used for showing the form when the image is loaded
  onImageLoad = () => {
    this.setState({
        imageLoaded: true
    })
  }

  componentDidMount() {
    this.generateAndSendNewCredentials();
  }

  generateAndSendNewCredentials = async () => {
    console.log(forgotPasswordRequestId);
    if(forgotPasswordRequestId !== undefined) {
      try {
        const res = await securityService.sendCredentialsEmail({requestId: forgotPasswordRequestId});
        this.setState({
            changePasswordFeedbackMessage: res.data.msg,
            changePasswordSucceed: res.data.succeed,
        });
      } catch (e) {
        console.error('Generating and sending new password has failed!');
        this.setState({
            changePasswordFeedbackMessage: res.data.msg,
            changePasswordSucceed: res.data.succeed,
        });
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
  }

  sendForgotPasswordRequest = async () => {
    if(this.state.username !== '') {
      try {
        //const baseUrl = 'http://localhost:3000';
        const baseUrl = window.location.origin;
        const res = await securityService.forgotPasswordRequest({username: this.state.username, baseUrl: baseUrl});
        if (res.data.succeed) {
          this.showSuccessSnackBar({msg: res.data.msg});
          this.setState({
            requestSent: true,
          });
        }
        else {
          this.showErrorSnackBar({msg: res.data.msg});
        }
      } catch (e) {
        console.error('Failed to send an email for requesting a new password!');
        this.showErrorSnackBar({msg: 'Some error occured while requesting for new credentils. The email could not be sent.'});
        if(e.response !== undefined) {
          console.error(e.response.status);
        }
      }
    }
    else {
      this.showErrorSnackBar({msg: 'You should fill in the "Username" field first.'});
    }
  }

  handleChange = fieldName => event => {
    this.setState({
      [fieldName]: event.target.value,
    });
  }

  handleGoToLogin = () => {
    forgotPasswordRequestId = undefined;
    this.props.history.push({
      pathname: rootContextPath + '/',
    });
  }

  // props: {msg: <Text>}
  showSuccessSnackBar = props => {
    this.props.enqueueSnackbar(
      props.msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500
      }
    );
  }

  // props: {msg: <Text>, iconSize: 'large | small (default: 'small')'}
  showErrorSnackBar = props => {
    this.props.enqueueSnackbar(
      '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        persist: true,
        autoHideDuration: 2500,
        content: (key) => (
            // variant: 'success | error | warning | info'
            // iconSize: 'large | small'
          <CustomSnackMessage id={key}
                              msg={props.msg}
                              variant={'error'}
                              iconSize={props.iconSize !== undefined ? props.iconSize : 'small'}
                              hasHtmlContent={props.hasHtmlContent !== undefined ? props.hasHtmlContent : false }/>
        ),
      }
    );
  }

  render() {
    const styleImageLoaded = this.state.imageLoaded ? {height: 'calc(100vh - 22px)'} : {visibility: 'hidden', height: 'calc(100vh - 22px)'}
    const { classes } = this.props;

    //noValidate
    return (
      <Card style={styleImageLoaded}>
        <CardContent>
          <div style={{display: 'flex'}}>
            <div>
              <Fab
                size="small"
                color="primary"
                aria-label="ArrowBack"
                className={classes.fab}
                onClick={this.handleGoToLogin}
              >
                <ArrowBackIcon />
              </Fab>
            </div>
            <Typography variant="h5" color="textSecondary" style={{marginLeft: 20, marginTop: 10}}>
              <img src={logo} alt="logo-256" style={{paddingRight:'5px', height:'50px'}} onLoad={this.onImageLoad}/>
              <span style={{paddingLeft: 15}}>Request for new password</span>
            </Typography>
          </div>
          {
            forgotPasswordRequestId !== undefined ?
            <div style={{paddingLeft: 80, paddingTop: 20}}>
              {
                this.state.changePasswordSucceed !== undefined ?
                <div>
                  <div style={{color: this.state.changePasswordSucceed ? 'unset' : 'red'}}>
                    {
                      this.state.changePasswordSucceed ?
                      'Your request has been processed successfully!' :
                      'Your request cannot be proccessed.'
                    }
                  </div>
                  <Typography variant="body1" style={{paddingTop: 20}}>
                    {this.state.changePasswordFeedbackMessage !== '' ? renderHTML(this.state.changePasswordFeedbackMessage) : 'Nothing has happened'}
                  </Typography>
                </div> :
                ''
              }
            </div> :
            <div style={{paddingLeft: 80, paddingTop: 20}}>
              {
                !this.state.requestSent ?
                <div>
                  <Typography variant="body1" style={{paddingBottom: 20}}>
                    You can request for a new password by entering your "username" and clicking the "Request" button.
                    This is an automated process that you can follow on your own. Please note that you will need access
                    to the email addresses associated to the system.
                  </Typography>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{paddingRight: 10}}>
                      <TextField
                        label="Username"
                        className={classes.textField}
                        value={this.state.username}
                        onChange={this.handleChange('username')}
                        variant="outlined"
                      />
                    </div>
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.sendForgotPasswordRequest}
                        size="medium"
                      >
                        Request
                      </Button>
                    </div>
                  </div>
                </div> :
                <div>
                  <Typography variant="body1" style={{paddingBottom: 10}}>
                    An email with a link for receiving new credentials has been sent to your email address, associated to 3M.
                    Please check that email and click on the attached URL.
                  </Typography>
                  <Typography variant="body1" style={{paddingBottom: 20, fontStyle: 'italic'}}>
                    You are seeing this message because you have requested for new credentials.
                  </Typography>
                </div>
              }
            </div>
          }
        </CardContent>
      </Card>
    );
  }
}

NewCredentialsGenerator.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withSnackbar)(NewCredentialsGenerator);
