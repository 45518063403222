import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '.././Autocomplete';

const styles = theme => ({
  dialogCustomPaper: {
    display: 'flex',
    overflow: 'hidden',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

export class TransformationErrorDialog extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {

    if(this.props.dialogOpen !== nextProps.dialogOpen) {
      return true;
    }
    if(this.props.fullScreen !== nextProps.fullScreen) {
      return true;
    }
    if(this.props.errorMessage !== nextProps.errorMessage) {
      return true;
    }
    if(this.props.transformationErrors !== nextProps.transformationErrors) {
      return true;
    }

    if(this.state !== nextState) {
      return true;
    }

    else {
      return false;
    }
  }

  render() {
    const {
      classes, dialogOpen, fullScreen, errorMessage, transformationErrors, onToggleFullScreen, onLeave,
    } = this.props;

    console.log('TransformationErrorDialog Rendered');

    return (
      <Dialog open={dialogOpen}
              aria-labelledby="transformation-error-dialog"
              TransitionComponent={Fade}
              transitionDuration={{enter: 400, exit: 400}}
              maxWidth="xl"
              scroll={'paper'}
              fullScreen={fullScreen}
              PaperProps={{
                classes: {
                  root: fullScreen ? classes.dialogCustomPaper : ''
                }
              }}>
        <DialogTitle style={{paddingBottom: 0}}>
          Errors returned while running the X3ML Transformation Engine
          <div style={{marginLeft: 'auto', marginRight: -15, marginTop: -12, float: 'right'}}>
            <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="maximize"
                          onClick={onToggleFullScreen}
                          style={{marginRight: -10}}>
                <FilterNoneIcon hidden={!fullScreen} style={{width: 20}}/>
                <CropSquareIcon hidden={fullScreen}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close"
                     placement="bottom"
                     enterDelay={500}
                     leaveDelay={200}>
              <IconButton className={classes.button}
                          aria-label="close"
                          onClick={onLeave({actionType: 'close'})}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
          {/*
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
          */}
          <List className={classes.listRoot}>
            {
              transformationErrors.map((errorMsg, index) => {
                return (
                  <div key={'transformationError' + index}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        style={{color: 'red'}}
                        primary={errorMsg}
                      />
                    </ListItem>
                    <Divider />
                  </div>
                )
              })
            }
          </List>
        </DialogContent>
        <DialogActions>
          <div style={{paddingRight: 15}}>
            <Button onClick={onLeave({actionType: 'close'})} color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

TransformationErrorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransformationErrorDialog);
