import axios from 'axios';

export const axiosInstance = axios.create();

const rootContextPath = '/3m';
//const rootContextPath = '';

const baseD4ScieneceURL = 'https://api.d4science.org/rest';

export class D4ScieneceSecurityService {

  async d4ScieneceLogin(data) {
     return await axiosInstance(rootContextPath + '/performD4ScienceLogin', {
      method: 'get',
      params: data,
    });
  }

}
